import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Select, { components } from 'react-select'
import { Colors } from '@deloitte/gel-library'
import { getIndex } from 'utils/arrays'
import styles from './FilterSelectInput.scss'

const CustomIndicatorsContainer = (props) => {
  const { options, getValue } = props

  const index = getIndex(options, x => x.value === getValue()[0].value)

  return (
    <div className={styles.flex}>
      <div className={styles.valueCount}>
        {`${index + 1} of ${options.length}`}
      </div>
      <components.IndicatorsContainer {...props} />
    </div>
  )
}

CustomIndicatorsContainer.propTypes = {
  getValue: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.node,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  })).isRequired,
}

const propTypes = {
  className: PropTypes.string,
  containerStyle: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  hideDropdownDetails: PropTypes.bool,
  hideIndicatorSeparator: PropTypes.bool,
  isDisabled: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.node,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  })),
  placeholder: PropTypes.string,
  value: PropTypes.shape({
    label: PropTypes.node,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  valueStyle: PropTypes.object,
}

const defaultProps = {
  className: null,
  containerStyle: {},
  hideDropdownDetails: false,
  hideIndicatorSeparator: false,
  isDisabled: false,
  options: [],
  placeholder: null,
  valueStyle: {},
}

const FilterSelectInput = (props) => {
  const {
    className,
    containerStyle,
    handleChange,
    hideDropdownDetails,
    hideIndicatorSeparator,
    isDisabled,
    options,
    value,
    placeholder,
    valueStyle,
  } = props

  const selectBorder = `1px solid ${Colors.uiGrey04}`

  const customStyles = {

    input: () => ({
      border: 'none',
      borderColor: 'none',
      boxShadow: 'none',
      fontWeight: 'bold',
    }),

    container: (provided, state) => ({
      ...provided,
      border: state.isFocused || state.isSelected ? selectBorder : selectBorder,
      borderColor: state.isFocused || state.isSelected ? selectBorder : selectBorder,
      boxShadow: 'none',
      ...containerStyle,
    }),

    singleValue: provided => ({
      ...provided,
      color: Colors.textOnLightPrimary,
      paddingLeft: '8px',
      ...valueStyle,
    }),

    control: provided => ({
      ...provided,
      fontWeight: 'bold',
      border: 'none',
      borderColor: 'none',
      boxShadow: 'none',
      '&:hover': {
        border: 'none',
        borderColor: 'none',
        boxShadow: 'none',
      },
    }),

    option: (provided, state) => ({
      ...provided,
      color: Colors.textOnLightPrimary,
      background: state.isSelected ? Colors.uiGrey04 : 'white',
      '&:hover': {
        color: Colors.textOnLightPrimary,
        background: Colors.uiGrey03,
      },
    }),

    indicatorSeparator: provided => ({
      ...provided,
      display: (hideIndicatorSeparator || hideDropdownDetails) ? 'none' : 'block',
    }),

    indicatorsContainer: provided => ({
      ...provided,
      marginLeft: hideIndicatorSeparator ? '-12px' : '0',
    }),

    dropdownIndicator: provided => ({
      ...provided,
      display: hideDropdownDetails ? 'none' : 'flex',
    }),
  }

  return (
    <div className={classnames(styles.container, className)}>
      <Select
        components={!hideDropdownDetails ? { IndicatorsContainer: CustomIndicatorsContainer } : ''}
        isDisabled={isDisabled}
        isSearchable={false}
        onChange={handleChange}
        options={options}
        placeholder={placeholder}
        styles={customStyles}
        value={value}
      />
    </div>
  )
}

FilterSelectInput.propTypes = propTypes
FilterSelectInput.defaultProps = defaultProps

export default FilterSelectInput
