import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from '../../AutoCompleteInput.scss'

const SuggestionItem = (props) => {
  const {
    active,
    handleClick,
    label,
    value,
  } = props

  const classes = {
    [styles.active]: active,
  }

  const _handleClick = () => {
    handleClick({
      label,
      value,
    })
  }

  return (
    <div
      className={classnames(styles.option, classes)}
      onClick={_handleClick}
      onMouseDown={_handleClick}
    >
      <span className={styles.optionText}>
        {label}
      </span>
    </div>
  )
}

SuggestionItem.propTypes = {
  active: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
}

SuggestionItem.defaultProps = {
  active: false,
  label: null,
  value: null,
}

export default SuggestionItem
