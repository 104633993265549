import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Heading, Button } from '@deloitte/gel-library'
import uploadingData from 'images/data-uploading.svg'
import Spinner from 'components/Spinner'
import IconSimpleCheck from 'icons/IconSimpleCheck'
import { Link } from 'react-router-dom'
import DialogSimple from 'components/DialogSimple'
import { getConfig } from 'utils/config'
import { OpenInNew } from '@material-ui/icons'
import styles from './DataProcessingModal.scss'

const propTypes = {
  active: PropTypes.bool.isRequired,
  checkJob: PropTypes.func.isRequired,
  fileCount: PropTypes.number,
  homeAction: PropTypes.func.isRequired,
  isRecipeSelected: PropTypes.bool.isRequired,
  isUploading: PropTypes.bool.isRequired,
}

const defaultProps = {
  fileCount: 0,
}

const DataProcessingModal = ({
  active, checkJob, fileCount, homeAction, isRecipeSelected, isUploading,
}) => {
  const [tickCount, setTickCount] = useState(0)
  const { URL: { FEEDBACK } } = getConfig()

  const implementCheckJob = () => {
    if (!isUploading) {
      if (tickCount > 20) {
        checkJob()
        setTickCount(0)
      } else {
        setTickCount(prevCount => prevCount + 1)
      }
    }
  }

  useEffect(() => {
    const interval = setInterval(implementCheckJob, 1000)

    return () => {
      clearInterval(interval)
    }
  })

  const openInNewTab = () => {
    window.open(FEEDBACK, '_blank')
  }

  return (
    <DialogSimple
      hideFooter
      className={styles.base}
      isOpen={active}
      onDismiss={homeAction}
    >
      <div className={styles.btnBackToHome}>
        <Button mode="flat" onClick={homeAction}>Back to Home</Button>
        {/* {isUploading
          ? <div className={styles.buttonSpacer} />
          : <Button mode="flat" onClick={homeAction}>Back to Home</Button>} */}
      </div>
      <img alt="Cloud with data uploading to it" height={180} src={uploadingData} width={180} />
      <Heading level={6}>Uploading and checking your data</Heading>
      <p>
        {isRecipeSelected
          ? (
            <>
              Validating and processing your chosen data source may take several minutes,
              <br />
              or potentially a few hours with large volumes of data.
            </>
          )
          : 'This process usually takes a few minutes, but might take longer with larger data.'}
        <br />
        {'Taking longer than expected? '}
        <Link to="/contact">Contact us</Link>
        {' for help'}
        <br />
        {isUploading ? 'Please do not close this window.' : 'It is now safe to close this window.'}
      </p>

      <div className={styles.progressSpinners}>
        <div className={styles.circle}>
          {isUploading ? <Spinner className={styles.spinner} colour="blue" /> : <IconSimpleCheck className={styles.tick} />}
        </div>

        <div className={styles.line} />

        <div className={styles.circle}>
          {!isUploading && <Spinner className={styles.spinner} colour="blue" />}
        </div>
      </div>

      <div className={styles.progressText}>

        <p className={classnames({ [styles.active]: isUploading })}>
          {isUploading ? `Uploading ${fileCount} data files…` : `Uploaded ${fileCount > 0 ? fileCount : ''} data files`}
        </p>

        <div className={styles.spacer} />

        <p className={classnames({ [styles.active]: !isUploading })}>
          {!isUploading ? 'Checking data quality issues…' : 'Check data quality issues'}
        </p>
      </div>

      <div className={styles.supportLink}>
        <p>
          As you wait, share your experience with us?
          <br />
          <Button mode="flat" onClick={openInNewTab}>
            {'SHARE FEEDBACK '}
            <OpenInNew className={styles.openNew} />
          </Button>
        </p>
      </div>
    </DialogSimple>
  )
}

DataProcessingModal.propTypes = propTypes
DataProcessingModal.defaultProps = defaultProps

export default DataProcessingModal
