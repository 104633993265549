/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconModifyAccount = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--modifyaccount',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104 104"{...rest}><g fill="none" fillRule="evenodd" transform="translate(2 2)"><path className="path1" d="M30.847 42.465c0-4.74 3.935-8.583 8.785-8.583s8.785 3.843 8.785 8.583-3.935 8.583-8.785 8.583-8.785-3.843-8.785-8.583z"/><path className="path2" stroke="#86BC25" strokeWidth="2.2" d="M30.847 42.465c0-4.74 3.935-8.583 8.785-8.583s8.785 3.843 8.785 8.583-3.935 8.583-8.785 8.583-8.785-3.843-8.785-8.583z" strokeLinecap="round" strokeLinejoin="round"/><path className="path3" d="M39.632 51.048c-8.683 0-15.721 6.88-15.721 15.363h18.24"/><path className="path4" stroke="#86BC25" strokeWidth="2.2" d="M39.632 51.048c-8.683 0-15.721 6.88-15.721 15.363h18.24" strokeLinecap="round" strokeLinejoin="round"/><path className="path5" stroke="#86BC25" strokeWidth="2.2" d="M67.442 49H56.865m10.577 5h-6.735m6.735-10H54.244m-14.612 7.048c8.684 0 15.722 6.88 15.722 15.364H37.113M62.346 64.128h14.185V36.786H53.534" strokeLinecap="round" strokeLinejoin="round"/><circle cx="50" cy="50" r="50" stroke="#86BD24" strokeWidth="2.2"/></g></IconBase>
  )
}

IconModifyAccount.propTypes = {
  className: PropTypes.string,
}

export default IconModifyAccount
/* eslint-enable */
