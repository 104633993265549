import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { setFilters, clearFilters } from 'actions/hostedReports'
import FilterBar from 'views/Home/components/FilterBar'

class ReportFilters extends Component {
  static propTypes = {
    className: PropTypes.string,
    clients: PropTypes.arrayOf(PropTypes.string).isRequired,
    dispatch: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
  }

  static contextTypes = {
    user: PropTypes.object,
  }

  static defaultProps = {
    className: null,
  }

  clearFilters = () => {
    const { dispatch } = this.props
    dispatch(clearFilters())
  }

  filterHandler = (type) => {
    const { dispatch } = this.props
    return (value) => {
      dispatch(setFilters({
        type,
        value,
      }))
    }
  }

  render() {
    const { className, clients, filters } = this.props

    return (
      <FilterBar
        className={className}
        clearFilters={this.clearFilters}
        clients={clients}
        filterHandler={this.filterHandler}
        filters={filters}
      />
    )
  }
}

function mapStateToProps({ hostedReports: { clients, filters } }) {
  return {
    clients,
    filters,
  }
}

export default connect(mapStateToProps)(ReportFilters)
