/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconAppTransact = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--apptransact',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 68"{...rest}><path className="path1" fill="#92E0EA" d="M58.1 68.1H9.9c-5.5 0-9.9-4.4-9.9-9.9V10C0 4.5 4.4.1 9.9.1h48.2C63.6.1 68 4.5 68 10v48.2c0 5.5-4.4 9.9-9.9 9.9"/><path className="path2" fill="#6ACEE5" d="M0 10v48.1C0 63.6 4.4 68 9.9 68h48.2L9.9 0C4.4.1 0 4.5 0 10"/><path className="path3" fill="#00A1DE" d="M46.2 38.1h-8.8c1.9 7.3 8.5 12.8 16.4 12.8v-8.3c-3.3-.1-6.1-1.9-7.6-4.5M53.7 24.8v-8.3c-7.8 0-14.4 5.4-16.4 12.7h8.8c1.6-2.6 4.4-4.4 7.6-4.4"/><path className="path4" fill="#FFF" d="M24.4 38.1c-1.5 2.6-4.3 4.4-7.6 4.4v8.3c7.9 0 14.4-5.4 16.4-12.8l-8.8.1zM16.8 16.5v8.3c3.2 0 6 1.8 7.5 4.4h8.8c-1.9-7.3-8.5-12.7-16.3-12.7"/><path className="path5" fill="#003989" d="M9.3 36h-.9v-4.5H6.9v-.8h3.9v.8H9.3zM15 33.9V36h-.9v-5.2h1.5c.7 0 1.2.1 1.5.4s.5.6.5 1.1c0 .7-.3 1.1-1 1.4L18 36h-1l-1.2-2.1H15zm0-.7h.6c.4 0 .7-.1.9-.2.2-.1.3-.4.3-.7s-.1-.5-.3-.6c-.2-.1-.5-.2-.9-.2H15v1.7zM24.5 36l-.5-1.5h-2l-.5 1.5h-.9l1.9-5.3h.9l1.9 5.3h-.8zm-.7-2.2l-.5-1.4c0-.1-.1-.2-.1-.5-.1-.2-.1-.4-.1-.5-.1.3-.2.6-.3 1l-.5 1.4h1.5zM32.9 36h-1l-2.6-4.2V36h-.8v-5.2h1l2.6 4.2v-.6-3.5h.8V36zM39.8 34.5c0 .5-.2.8-.5 1.1-.3.3-.8.4-1.4.4s-1.1-.1-1.4-.3V35c.2.1.5.2.8.3s.5.1.7.1c.3 0 .6-.1.7-.2.2-.1.2-.3.2-.5s-.1-.4-.2-.5c-.1-.1-.5-.3-.9-.5-.5-.2-.8-.4-1-.7-.2-.2-.3-.5-.3-.9s.2-.8.5-1c.3-.3.7-.4 1.2-.4s1 .1 1.5.3l-.3.7c-.5-.2-.9-.3-1.2-.3s-.5.1-.6.2-.2.3-.2.5c0 .1 0 .3.1.3l.3.3c.1.1.4.2.7.3.4.2.7.3.8.4.2.1.3.3.4.5.1.2.1.4.1.6M46.6 36l-.6-1.5h-2l-.5 1.5h-.9l1.9-5.3h.9l1.9 5.3h-.7zm-.8-2.2l-.5-1.4c0-.1-.1-.2-.1-.5-.1-.2-.1-.4-.1-.5-.1.3-.2.6-.3 1l-.5 1.4h1.5zM52.9 31.4c-.5 0-.9.2-1.2.5-.3.3-.4.8-.4 1.4s.1 1.1.4 1.5c.3.3.7.5 1.2.5.2 0 .4 0 .6-.1.2 0 .4-.1.6-.2v.7c-.4.3-.8.3-1.3.3-.8 0-1.3-.2-1.8-.7-.4-.5-.6-1.1-.6-2 0-.5.1-1 .3-1.4s.5-.7.9-.9.8-.3 1.3-.3 1 .1 1.5.3l-.4.7c-.2-.1-.4-.2-.6-.2-.1-.1-.3-.1-.5-.1M59.6 36h-.9v-4.5h-1.5v-.8h3.9v.8h-1.5z"/></IconBase>
  )
}

IconAppTransact.propTypes = {
  className: PropTypes.string,
}

export default IconAppTransact
/* eslint-enable */
