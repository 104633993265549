import React from 'react'
import { Container } from 'components/layout/Grid/'
import Loading from 'components/Loading'
import Banner from 'components/Banner'
import { getCookie, setCookie } from 'utils/cookies'
import { getConfig } from 'utils/config'
import Landing from 'views/Landing/Landing'

const SigningIn = (props) => {
  const activeCookie = getCookie('active')
  const { API_BASE } = getConfig()

  const signIn = () => {
    setCookie('active', true, 7)
    window.location = `${API_BASE}/account/success`
  }

  if (activeCookie) {
    signIn()

    return (
      <div>
        <Banner name="Welcome" {...props} />
        <Container noMargin topPadding>
          <Loading pageLoading message="SIGNING IN…" />
        </Container>
      </div>
    )
  }

  return <Landing signIn={signIn} />
}

export default SigningIn
