import {
  ADMIN_FETCHING_USERS,
  ADMIN_FETCHED_USERS,
  ADMIN_FETCHING_USER_GROUPS,
  ADMIN_FETCHED_USER_GROUPS,
  ADMIN_FETCHING_ROLES,
  ADMIN_FETCHED_ROLES,
  ADMIN_SELECT_USER,
  ADMIN_SEARCHED_USERS,
  ADMIN_SEARCHING_USERS,
  ADMIN_CLEAR_USER_SEARCH,
  ADMIN_TOGGLE_USER_PERMISSION,
  ADMIN_UPDATING_USER_PERMISSIONS,
  ADMIN_UPDATED_USER_PERMISSIONS,
  ADMIN_FETCHING_SOLUTION_ACCESS,
  ADMIN_FETCHING_DATA_SOURCE_ACCESS,
  ADMIN_FETCHED_SOLUTION_ACCESS,
  ADMIN_FETCHED_DATA_SOURCE_ACCESS,
  ADMIN_FETCHING_SOLUTION_ROLES,
  ADMIN_FETCHING_DATA_SOURCE_ROLES,
  ADMIN_FETCHED_SOLUTION_ROLES,
  ADMIN_FETCHED_DATA_SOURCE_ROLES,
  ADMIN_EDIT_SOLUTION_ACCESS,
  ADMIN_EDIT_DATA_SOURCE_ACCESS,
  ADMIN_TOGGLE_SOLUTION_PERMISSION,
  ADMIN_TOGGLE_DATA_SOURCE_PERMISSION,
  ADMIN_TOGGLE_ANALYSIS_PERMISSION,
  ADMIN_UPDATING_SOLUTION_PERMISSIONS,
  ADMIN_UPDATING_DATA_SOURCE_PERMISSIONS,
  ADMIN_UPDATED_SOLUTION_PERMISSIONS,
  ADMIN_UPDATED_DATA_SOURCE_PERMISSIONS,
  ADMIN_FETCHING_SYSTEM_ACCESS,
  ADMIN_FETCHED_SYSTEM_ACCESS,
  ADMIN_FETCHING_BUSINESS_UNITS,
  ADMIN_FETCHED_BUSINESS_UNITS,
  ADMIN_UPDATING_SYSTEM_ACCESS,
  ADMIN_UPDATED_SYSTEM_ACCESS,
  ADMIN_FETCHED_ACTIVE_JOBS,
  ADMIN_SELECT_BANNER,
  ADMIN_RESET_DEFAULT_BANNER,
  ADMIN_FETCHING_BANNERS,
  ADMIN_FETCHED_BANNERS,
  ADMIN_UPDATING_BANNER,
  ADMIN_UPDATED_BANNER,
  ADMIN_FETCHED_ACTIVE_BANNERS,
  ADMIN_UPDATE_ACTIVE_BANNERS,
  ADMIN_TOGGLED_BANNER,
  ADMIN_DELETED_BANNER,
  ADMIN_RESET_BANNER_CTA,
} from 'actions/admin'
import { sortByFunction } from 'utils/arrays'
import { objectFromArray, objectToArray } from 'utils/objects'

const initialState = {
  userAdmin: {
    _isFetchingUsers: false,
    _isFetchedUsers: false,
    _isFetchingUserGroups: false,
    _isFetchedUserGroups: false,
    _isFetchingRoles: false,
    _isFetchedRoles: false,
    _isSearchingUsers: false,
    _isSearchedUsers: false,
    _isUpdatingPermissions: false,
    _isUpdatedPermissions: false,
    searchResults: [],
    users: {},
    userIds: [],
    userGroups: {},
    roles: {},
    roleIds: [],
    selectedUser: null,
    selectedUserRoles: {},
    selectedUserNotes: '',
  },
  allSolutionsAccess: {
    _isFetching: false,
    _isFetched: false,
    solutions: {},
    solutionNames: [],
  },
  assignableRoles: {
    _isFetching: false,
    _isFetched: false,
    roles: {},
    roleIds: [],
  },
  solutionAccess: {},
  dataSourceAccess: {},
  _isUpdatingSolutionPermissions: false,
  _isUpdatingDataSourcePermissions: false,
  systemAccess: {
    _isFetchingAccess: false,
    _isFetchedAccess: false,
    _isFetchingBus: false,
    _isFetchedBus: false,
    _isUpdating: false,
    access: [],
    businessUnits: {},
    businessUnitIds: [],
  },
  activeJobs: [],
  allDataSourceAccess: {
    _isFetching: false,
    _isFetched: false,
    dataSources: {},
    dataSourceNames: [],
  },
  bannerAccess: {
    _isFetching: false,
    _isFetched: false,
    _isUpdating: false,
    _isUpdated: false,
    banners: {},
    activeBanners: {},
    selectedBanner: {},
  },
}

export default function adminReducer(state = initialState, action) {
  switch (action.type) {
    case ADMIN_FETCHED_ACTIVE_JOBS:
      return {
        ...state,
        activeJobs: action.jobs
          .slice()
          .sort(sortByFunction(j => new Date(j.lastModifiedDate), true)),
      }
    case ADMIN_FETCHING_USERS:
      return {
        ...state,
        userAdmin: {
          ...state.userAdmin,
          _isFetchingUsers: true,
        },
      }

    case ADMIN_FETCHED_USERS:
      return {
        ...state,
        userAdmin: {
          ...state.userAdmin,
          _isFetchingUsers: false,
          _isFetchedUsers: true,
          users: objectFromArray(action.users, 'id'),
          userIds: action.users.map(u => u.id),
        },
      }

    case ADMIN_FETCHING_USER_GROUPS:
      return {
        ...state,
        userAdmin: {
          ...state.userAdmin,
          _isFetchingUserGroups: true,
        },
      }

    case ADMIN_FETCHED_USER_GROUPS:
      return {
        ...state,
        userAdmin: {
          ...state.userAdmin,
          _isFetchingUserGroups: false,
          _isFetchedUserGroups: true,
          userGroups: objectFromArray(action.userGroups, 'id'),
        },
      }

    case ADMIN_FETCHING_ROLES:
      return {
        ...state,
        userAdmin: {
          ...state.userAdmin,
          _isFetchingRoles: true,
        },
      }

    case ADMIN_FETCHED_ROLES:
      return {
        ...state,
        userAdmin: {
          ...state.userAdmin,
          _isFetchingRoles: false,
          _isFetchedRoles: true,
          roles: objectFromArray(action.roles, 'id'),
          roleIds: action.roles.map(a => a.id),
        },
      }

    case ADMIN_SELECT_USER:
      return {
        ...state,
        userAdmin: {
          ...state.userAdmin,
          selectedUser: action.user,
          selectedUserRoles: action.user
            ? objectFromArray(state.userAdmin.roleIds.map(rid => ({
              id: rid,
              originalValue: action.user.roles.indexOf(rid) >= 0,
              modifiedValue: action.user.roles.indexOf(rid) >= 0,
            })), 'id')
            : {},
          _isSearchedUsers: false,
          _isSearchingUsers: false,
          searchResults: [],
          selectedUserNotes: action.user ? action.user.details.userNotes : '',
        },
      }

    case ADMIN_SEARCHING_USERS:
      return {
        ...state,
        userAdmin: {
          ...state.userAdmin,
          _isSearchingUsers: true,
        },
      }

    case ADMIN_SEARCHED_USERS:
      return {
        ...state,
        userAdmin: {
          ...state.userAdmin,
          _isSearchingUsers: false,
          _isSearchedUsers: true,
          searchResults: action.results,
        },
      }

    case ADMIN_CLEAR_USER_SEARCH:
      return {
        ...state,
        userAdmin: {
          ...state.userAdmin,
          _isSearchingUsers: false,
          _isSearchedUsers: false,
          searchResults: [],
        },
      }

    case ADMIN_TOGGLE_USER_PERMISSION:
      return {
        ...state,
        userAdmin: {
          ...state.userAdmin,
          selectedUserRoles: {
            ...state.userAdmin.selectedUserRoles,
            [action.roleId]: {
              ...state.userAdmin.selectedUserRoles[action.roleId],
              modifiedValue: !state.userAdmin.selectedUserRoles[action.roleId].modifiedValue,
            },
          },
        },
      }

    case ADMIN_UPDATING_USER_PERMISSIONS:
      return {
        ...state,
        userAdmin: {
          ...state.userAdmin,
          _isUpdatingPermissions: true,
        },
      }

    case ADMIN_UPDATED_USER_PERMISSIONS:
      return {
        ...state,
        userAdmin: {
          ...state.userAdmin,
          _isUpdatingPermissions: false,
          _isUpdatedPermissions: true,
          users: action.user
            ? {
              ...state.userAdmin.users,
              [action.user.id]: {
                ...action.user,
                rolesUpdatedAt: new Date().toISOString(),
                rolesUpdatedBy: action.email,
                roles: objectToArray(action.selectedRoles).filter(r => r.modifiedValue).map(r => r.id),
                details: {
                  ...action.user.details,
                  userNotes: action.userNotes,
                },
              },
            }
            : state.userAdmin.users,
          userIds: state.userAdmin.userIds.indexOf(action.user ? action.user.id : 'not found') < 0
            ? state.userAdmin.userIds.concat(action.user ? [action.user.id] : [])
            : state.userAdmin.userIds,
          selectedUser: null,
          selectedUserNotes: null,
          selectedUserRoles: {},
        },
      }

    case ADMIN_FETCHING_SOLUTION_ACCESS:
      return {
        ...state,
        allSolutionsAccess: {
          ...state.allSolutionsAccess,
          _isFetching: true,
        },
      }

    case ADMIN_FETCHING_DATA_SOURCE_ACCESS:
      return {
        ...state,
        allDataSourceAccess: {
          ...state.allDataSourceAccess,
          _isFetching: true,
        },
      }

    case ADMIN_FETCHED_SOLUTION_ACCESS:
      return {
        ...state,
        allSolutionsAccess: {
          ...state.allSolutionsAccess,
          _isFetched: true,
          _isFetching: false,
          solutions: objectFromArray(action.solutions, 'name'),
          solutionNames: action.solutions.map(s => s.name),
        },
      }

    case ADMIN_FETCHED_DATA_SOURCE_ACCESS:
      return {
        ...state,
        allDataSourceAccess: {
          ...state.allDataSourceAccess,
          _isFetched: true,
          _isFetching: false,
          dataSources: objectFromArray(action.dataSources, 'name'),
          dataSourceNames: action.dataSources.map(s => s.name),
        },
      }

    case ADMIN_FETCHING_SOLUTION_ROLES: {
      return {
        ...state,
        assignableRoles: {
          ...state.assignableRoles,
          _isFetching: true,
        },
      }
    }

    case ADMIN_FETCHING_DATA_SOURCE_ROLES: {
      return {
        ...state,
        assignableRoles: {
          ...state.assignableRoles,
          _isFetching: true,
        },
      }
    }

    case ADMIN_FETCHED_SOLUTION_ROLES:
      return {
        ...state,
        assignableRoles: {
          ...state.assignableRoles,
          _isFetching: false,
          _isFetched: true,
          roles: objectFromArray(action.roles, 'id'),
          roleIds: action.roles.map(r => r.id),
        },
      }

    case ADMIN_FETCHED_DATA_SOURCE_ROLES:
      return {
        ...state,
        assignableRoles: {
          ...state.assignableRoles,
          _isFetching: false,
          _isFetched: true,
          roles: objectFromArray(action.roles, 'id'),
          roleIds: action.roles.map(r => r.id),
        },
      }

    case ADMIN_EDIT_SOLUTION_ACCESS:
      return {
        ...state,
        solutionAccess: action.solutionName
          ? state.allSolutionsAccess.solutions[action.solutionName]
          : {},
      }

    case ADMIN_EDIT_DATA_SOURCE_ACCESS:
      return {
        ...state,
        dataSourceAccess: action.dataSourceName
          ? state.allDataSourceAccess.dataSources[action.dataSourceName]
          : {},
      }

    case ADMIN_TOGGLE_SOLUTION_PERMISSION:
      return {
        ...state,
        solutionAccess: {
          ...state.solutionAccess,
          roles: action.on && state.solutionAccess.roles.map(r => r.id).indexOf(action.roleId) < 0
            ? state.solutionAccess.roles.concat([state.assignableRoles.roles[action.roleId]])
            : state.solutionAccess.roles.filter(r => r.id !== action.roleId),
          analyses: state.solutionAccess.analyses.map(a => ({
            ...a,
            roles: state.solutionAccess.roles.map(r => r.id).indexOf(action.roleId) >= 0
              ? a.roles.filter(r => r.id !== action.roleId)
              : a.roles.concat([state.assignableRoles.roles[action.roleId]]),
          })),
        },
      }

    case ADMIN_TOGGLE_DATA_SOURCE_PERMISSION:
      return {
        ...state,
        dataSourceAccess: {
          ...state.dataSourceAccess,
          roles: action.on && state.dataSourceAccess.roles.map(r => r.id).indexOf(action.roleId) < 0
            ? state.dataSourceAccess.roles.concat([state.assignableRoles.roles[action.roleId]])
            : state.dataSourceAccess.roles.filter(r => r.id !== action.roleId),
        },
      }

    case ADMIN_TOGGLE_ANALYSIS_PERMISSION:
      return {
        ...state,
        solutionAccess: {
          ...state.solutionAccess,

          roles: action.on && state.solutionAccess.roles.map(r => r.id).indexOf(action.roleId) < 0
            ? state.solutionAccess.roles.concat([state.assignableRoles.roles[action.roleId]])
            : state.solutionAccess.roles,

          analyses: state.solutionAccess.analyses.map((a) => {
            if (a.name === action.analysisName) {
              return {
                ...a,
                roles: action.on
                  ? a.roles.concat([state.assignableRoles.roles[action.roleId]])
                  : a.roles.filter(r => r.id !== action.roleId),
              }
            }
            return a
          }),
        },
      }

    case ADMIN_UPDATING_SOLUTION_PERMISSIONS:
      return {
        ...state,
        _isUpdatingSolutionPermissions: true,
      }

    case ADMIN_UPDATING_DATA_SOURCE_PERMISSIONS:
      return {
        ...state,
        _isUpdatingDataSourcePermissions: true,
      }

    case ADMIN_UPDATED_SOLUTION_PERMISSIONS:
      return {
        ...state,
        _isUpdatingSolutionPermissions: false,
        solutionAccess: {},
        allSolutionsAccess: {
          ...state.allSolutionsAccess,
          solutions: {
            ...state.allSolutionsAccess.solutions,
            [state.solutionAccess.name]: {
              ...state.solutionAccess,
              roles: state.solutionAccess.roles.map(r => ({
                ...r,
                updatedAt: new Date().toISOString(),
                updatedBy: '[USER]',
              })),
            },
          },
        },
      }

    case ADMIN_UPDATED_DATA_SOURCE_PERMISSIONS:
      return {
        ...state,
        _isUpdatingDataSourcePermissions: false,
        solutionAccess: {},
        allDataSourceAccess: {
          ...state.allDataSourceAccess,
          dataSources: {
            ...state.allDataSourceAccess.dataSources,
            [state.dataSourceAccess.name]: {
              ...state.dataSourceAccess,
              roles: state.dataSourceAccess.roles.map(r => ({
                ...r,
                updatedAt: new Date().toISOString(),
                updatedBy: '[USER]',
              })),
            },
          },
        },
      }

    case ADMIN_FETCHING_SYSTEM_ACCESS:
      return {
        ...state,
        systemAccess: {
          ...state.systemAccess,
          _isFetchingAccess: true,
        },
      }

    case ADMIN_FETCHED_SYSTEM_ACCESS:
      return {
        ...state,
        systemAccess: {
          ...state.systemAccess,
          _isFetchingAccess: false,
          _isFetchedAccess: true,
          access: action.access.map(a => (a.businessUnit === '*'
            ? { ...a, businessUnitEnum: -1 }
            : a)),
        },
      }

    case ADMIN_FETCHING_BUSINESS_UNITS:
      return {
        ...state,
        systemAccess: {
          ...state.systemAccess,
          _isFetchingBus: true,
        },
      }

    case ADMIN_FETCHED_BUSINESS_UNITS:
      return {
        ...state,
        systemAccess: {
          ...state.systemAccess,
          _isFetchingBus: false,
          _isFetchedBus: true,
          businessUnits: {
            ...objectFromArray(action.businessUnits, 'id'),
            [-1]: {
              description: 'All business units',
              name: '*',
              id: -1,
            },
          },
          businessUnitIds: action.businessUnits.map(bu => bu.id).concat([-1]),
        },
      }

    case ADMIN_UPDATING_SYSTEM_ACCESS:
      return {
        ...state,
        systemAccess: {
          ...state.systemAccess,
          _isUpdating: true,
        },
      }

    case ADMIN_UPDATED_SYSTEM_ACCESS:
      return {
        ...state,
        systemAccess: {
          ...state.systemAccess,
          _isUpdating: false,
          access: action.access
            ? state.systemAccess.access
              .filter(a => !action.isDelete || !(a.country === action.access.country && a.businessUnit === action.access.businessUnit))
              .concat(action.isDelete ? [] : [action.access])
            : state.systemAccess.access,
        },
      }

    case ADMIN_SELECT_BANNER:
      return {
        ...state,
        bannerAccess: {
          ...state.bannerAccess,
          selectedBanner: {
            ...action.banner,
            audienceCheck: {},
            includesLink: action.banner.linkText.length > 0 || action.banner.linkUrl.length > 0,
          },
        },
      }

    case ADMIN_RESET_DEFAULT_BANNER:
      return {
        ...state,
        bannerAccess: {
          ...state.bannerAccess,
          selectedBanner: {
            name: '',
            message: '',
            mode: 'Info',
            includesLink: false,
            linkText: '',
            linkUrl: '',
            audience: [],
            audienceCheck: {},
            isActive: false,
          },
        },
      }

    case ADMIN_UPDATE_ACTIVE_BANNERS:
      return {
        ...state,
        bannerAccess: {
          ...state.bannerAccess,
          activeBanners: Object.fromEntries(
            Object.entries(state.bannerAccess.banners || {}).filter(([_, banner]) => banner.isActive),
          ),
        },
      }

    case ADMIN_FETCHING_BANNERS:
      return {
        ...state,
        bannerAccess: {
          ...state.bannerAccess,
          _isFetching: true,
        },
      }

    case ADMIN_FETCHED_BANNERS:
      return {
        ...state,
        bannerAccess: {
          ...state.bannerAccess,
          _isFetching: false,
          _isFetched: true,
          banners: objectFromArray(action.banners, 'id'),
          activeBanners: objectFromArray(action.banners.filter(b => b.isActive), 'id'),
        },
      }

    case ADMIN_FETCHED_ACTIVE_BANNERS:
      return {
        ...state,
        bannerAccess: {
          ...state.bannerAccess,
          activeBanners: objectFromArray(action.banners, 'id'),
        },
      }

    case ADMIN_UPDATING_BANNER:
      return {
        ...state,
        bannerAccess: {
          ...state.bannerAccess,
          _isUpdating: true,
        },
      }

    case ADMIN_UPDATED_BANNER:
      return {
        ...state,
        bannerAccess: {
          ...state.bannerAccess,
          _isUpdating: false,
          _isUpdated: true,
          banners: {
            ...state.bannerAccess.banners,
            [action.banner.id]: {
              ...action.banner,
            },
          },
        },
      }

    case ADMIN_TOGGLED_BANNER:
      return {
        ...state,
        bannerAccess: {
          ...state.bannerAccess,
          _isUpdating: false,
          banners: {
            ...state.bannerAccess.banners,
            [action.bannerId]: {
              ...state.bannerAccess.banners[action.bannerId],
              isActive: !state.bannerAccess.banners[action.bannerId].isActive,
              updatedBy: '[USER]',
              updatedDate: new Date().toISOString(),
            },
          },
        },
      }

    case ADMIN_DELETED_BANNER: {
      const bannerCopy = { ...state.bannerAccess.banners }
      delete bannerCopy[action.bannerId]

      return {
        ...state,
        bannerAccess: {
          ...state.bannerAccess,
          _isUpdating: false,
          banners: {
            ...bannerCopy,
          },
        },
      }
    }

    case ADMIN_RESET_BANNER_CTA:
      return {
        ...state,
        bannerAccess: {
          ...state.bannerAccess,
          _isUpdating: false,
          _isUpdated: false,
        },
      }

    default:
      return state
  }
}
