import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import IconPencil from 'icons/IconPencil'
import BlockIcon from '@material-ui/icons/Block'
import styles from './Stepper.scss'

const Stepper = ({
  steps, current, handleLoseData, maximumStepAccess, isCalculated, isCustom,
}) => {
  const maxOrDefault = maximumStepAccess || 0
  const maxStep = current > maxOrDefault ? current : maxOrDefault
  const unitDistance = (maxStep / steps.length) * 100
  const stepStyle = {
    flexBasis: `${unitDistance}%`,
  }

  const underlineStyle = {
    width: `${unitDistance}%`,
  }

  const renderStep = (step) => {
    const disableThisStep = isCustom ? step.skipWithNoAnalyses : (isCalculated && step.skipWithNoInputData) || step.isControlsAdvantage
    const currentStep = steps.find(x => x.id === current)
    const isCurrent = step.id === current
    const isAccessible = step.id <= maxStep

    let stepLink = currentStep.dataLossPossible ? (
      <span className={styles.stepLink} onClick={handleLoseData(step.to)}>
        {step.label}
      </span>
    ) : (
      <Link to={step.to}>
        {step.label}
      </Link>
    )

    if (step.onClick) {
      stepLink = (
        <span className={styles.stepLink} onClick={step.onClick}>
          {step.label}
        </span>
      )
    }

    return (
      <li
        className={classnames(styles.step, {
          [styles.completed]: !disableThisStep ? isAccessible : null,
          [styles.current]: isCurrent,
        })}
        key={step.id}
        style={stepStyle}
      >
        {disableThisStep
          ? (
            <div>
              <span className={styles.circle}>
                <BlockIcon className={styles.disabled} />
              </span>
              <span className={styles.disabledText}>{step.label}</span>
            </div>
          )
          : (
            <div>
              <span className={styles.circle}>
                {isAccessible && !isCurrent ? <IconPencil /> : step.id}
              </span>
              {(isAccessible && !isCurrent) ? stepLink : <span>{step.label}</span>}
            </div>
          )
      }

      </li>
    )
  }

  return (
    <div className={styles.base}>
      <ul>
        {steps.map(renderStep)}
      </ul>
      <div className={styles.underline}>
        <div style={underlineStyle} />
        <div />
      </div>
    </div>
  )
}

Stepper.propTypes = {
  current: PropTypes.number.isRequired,
  handleLoseData: PropTypes.func,
  isCalculated: PropTypes.bool.isRequired,
  isCustom: PropTypes.bool.isRequired,
  maximumStepAccess: PropTypes.number,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      to: PropTypes.string,
    }),
  ),
}

Stepper.defaultProps = {
  handleLoseData: () => {},
  maximumStepAccess: null,
  steps: [],
}

export default Stepper
