export const ERROR = {
  BAD_REQUEST: 'BAD_REQUEST',
  NOT_FOUND: 'NOT_FOUND ',
  UNAUTHORIZED: 'UNAUTHORIZED',
  FORBIDDEN: 'FORBIDDEN',
  SERVICE_UNAVAILABLE: 'SERVICE_UNAVAILABLE',
  INTERNAL_SERVER: 'INTERNAL_SERVER',
  OFFLINE: 'OFFLINE',
  UNKNOWN: 'UNKNOWN',
}
