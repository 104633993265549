import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './Card.scss'

const Card = ({
  children, className, elementType, noPadding, noSidePadding, hoverShadow, noShadow, ...rest
}) => {
  const Element = elementType
  const classes = {
    [styles.noPadding]: noPadding,
    [styles.noSidePadding]: noSidePadding,
    [styles.hoverShadow]: hoverShadow,
    [styles.shadow]: !noShadow && !hoverShadow,
  }

  return (
    <Element
      {...rest}
      className={classnames(styles.card, classes, className)}
    >
      {children}
    </Element>
  )
}

Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  elementType: PropTypes.string,
  hoverShadow: PropTypes.bool,
  noPadding: PropTypes.bool,
  noShadow: PropTypes.bool,
  noSidePadding: PropTypes.bool,
}

Card.defaultProps = {
  children: null,
  className: null,
  elementType: 'div',
  hoverShadow: false,
  noShadow: false,
  noPadding: false,
  noSidePadding: false,
}

export default Card
