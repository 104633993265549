import {
  getRequest, putRequest, postRequest, deleteRequest,
} from 'utils/api'
import { objectFromArrayWithFunctions } from 'utils/objects'

export const postActions = jobId => postRequest(
  `/action/job/${jobId}`,
)

export const getAction = actionSetId => getRequest(`/action/${actionSetId}`)
export const getTags = actionSetId => getRequest(`/action/${actionSetId}/tags`)

export const getTagDetails = (actionSetId, tagId) => getRequest(`/action/${actionSetId}/tags/${tagId}`)

export const getTasks = (actionSetId, filterUrl, page) => getRequest(
  `/action/${actionSetId}/level/0/data?page=${page}&${filterUrl}`,
)

export const getTagTasks = (actionSetId, tagId, filterUrl, page) => getRequest(
  `/action/${actionSetId}/level/0/data?tagId=${tagId}&page=${page}&${filterUrl}`,
)

export const deleteTag = (actionSetId, tagId) => deleteRequest(`/action/${actionSetId}/tags/${tagId}`)
export const deleteTaskComment = (actionSetId, taskId, commentId) => deleteRequest(`/action/${actionSetId}/level/0/data/${taskId}/comments/${commentId}`)
export const removeTagAssociation = (actionSetId, taskIds) => {
  const taskIdsString = taskIds.map(tid => `taskIds=${tid}`).join('&')
  return postRequest(`/action/${actionSetId}/removeTagAssociation/tasks?${taskIdsString}`)
}

export const getTaskMeta = (actionSetId, taskId, allowComments) => Promise.all([
  getRequest(
    `/action/${actionSetId}/level/0/data/${taskId}/meta`,
  ),
  getRequest(
    `/action/${actionSetId}/level/0/data/${taskId}/labels`,
  ),
  getRequest(
    `/action/${actionSetId}/level/0/data/${taskId}`,
  ),
  allowComments ? getRequest(
    `/action/${actionSetId}/level/0/data/${taskId}/comments`,
  ) : null,
]).then(([{ data: metaResponse }, { data: labels }, { data: taskData }, commentResponse]) => {
  return {
    data: {
      ...metaResponse, labels, taskData, comments: commentResponse?.data,
    },
  }
})

export const getTaskData = (actionSetId, taskId, page) => getRequest(
  `/action/${actionSetId}/level/0/data/${taskId}/data?page=${page}`,
)
  .then(({ data }) => {
    const ids = data.items.map(x => `dataIds=${x._id}`)
    const idString = ids.join('&')

    if (data.items.length === 0) {
      return { data }
    }

    return getRequest(
      `/action/${actionSetId}/level/1/reviews?${idString}`,
    )
      .then(({ data: reviewData }) => {
        const stitchedItems = data.items.map((x) => {
          const reviewDatum = reviewData[x._id]
          const labels = objectFromArrayWithFunctions(reviewDatum.outcomeLabels, z => `_${z.name}`, z => z.value)
          return {
            ...x,
            ...labels,
            _commentCount: reviewDatum.commentCount,
            _attachmentCount: reviewDatum.attachmentCount,
          }
        })
        return {
          data: {
            ...data,
            items: stitchedItems,
          },
        }
      })
  })

export const getDataDetails = (actionSetId, taskId, dataId) => Promise.all([
  getRequest(
    `/action/${actionSetId}/level/1/data/${dataId}/labels`,
  ),
  getRequest(
    `/action/${actionSetId}/level/1/data/${dataId}/comments`,
  ),
]).then(([{ data: labels }, { data: comments }]) => {
  return {
    data: { labels, comments },
  }
})

export const putLabels = (actionSetId, dataLevel, id, labels) => putRequest(
  `/action/${actionSetId}/level/${dataLevel}/data/${id}/labels`,
  {
    labels: labels.filter(x => x.value !== null).map(x => ({
      labelId: x.id,
      value: x.value,
    })),
  },
)

export const putLabelsBulkUpdate = (actionSetId, dataLevel, tasksToUpdate) => putRequest(
  `/action/${actionSetId}/level/${dataLevel}/data/labels`,
  tasksToUpdate,
)

export const postTaskComment = (actionSetId, taskId, comment) => postRequest(
  `/action/${actionSetId}/level/0/data/${taskId}/comments`,
  comment,
)

export const putTagLabels = (actionSetId, tagId, labels) => putRequest(
  `/action/${actionSetId}/tags/${tagId}/labels `,
  {
    labels: labels.filter(x => x.value !== null).map(x => ({
      labelId: x.id,
      value: x.value,
    })),

  },
)

export const postDataComment = (actionSetId, dataId, comment) => postRequest(
  `/action/${actionSetId}/level/1/data/${dataId}/comments`,
  comment,
)

export const postTag = (actionSetId, dataIds, name, description) => postRequest(
  `/action/${actionSetId}/tags`,
  {
    name,
    description,
    dataIds,
  },
)

export const postTagComment = (actionSetId, tagId, comment) => postRequest(`/action/${actionSetId}/tags/${tagId}/comments`,
  {
    comment,
  })

export const putTasksAssignee = (actionSetId, taskIds, userId) => putRequest(
  `/action/${actionSetId}/level/0/meta`,
  {
    tasks: taskIds.map(dataId => ({
      dataId, dataLevel: 0, assignedTo: userId,
    })),
  },
)

export const putTag = (actionSetId, dataIds, name, description, tagId, narrative) => putRequest(
  `/action/${actionSetId}/tags/${tagId}`,
  {
    name,
    description,
    dataIds,
    narrative,
  },
)

export const postTaskTag = (actionSetId, taskId, tagId) => postRequest(
  `/action/${actionSetId}/level/0/data/${taskId}/tag/${tagId}`,
)

export const putTasksActionStates = (actionSetId, taskIds, taskActionId) => putRequest(
  `/action/${actionSetId}/level/0/meta`,
  {
    tasks: taskIds.map(dataId => ({
      dataId, dataLevel: 0, taskActionId,
    })),
  },
)

export const putTasksPriority = (actionSetId, taskIds, priorityId) => putRequest(
  `/action/${actionSetId}/level/0/meta`,
  {
    tasks: taskIds.map(dataId => ({
      dataId, dataLevel: 0, priorityId,
    })),
  },
)

export const getGlobalFilters = actionSetId => getRequest(`/action/${actionSetId}/globalfilters`)

export const putTasksStatus = (actionSetId, taskIds, statusId) => putRequest(
  `/action/${actionSetId}/level/0/meta`,
  {
    tasks: taskIds.map(dataId => ({
      dataId, dataLevel: 0, statusId,
    })),
  },
)

export const getConnectedJobCurrentActionSetIds = (jobId, name, type) => getRequest(
  `/action?fromClientOfJobId=${jobId}&filterName=${name}&filterType=${type}`,
)

export const getNoOfEntitiesToOverride = (actionSetId, actionIdsToImport, type, name) => getRequest(
  `/action/${actionSetId}/level/0/commonTasks?otherActionIds=${actionIdsToImport.join('&otherActionIds=')}&type=${type}&name=${name}`,
)

export const putTaskDetails = (actionSetId, taskId, taskDetails) => putRequest(
  `action/${actionSetId}/level/0/data/${taskId}/edit`,
  taskDetails,
)

export const postNewTask = (actionSetId, jobId, taskDetails) => {
  const body = {
    jobId,
    taskDetails,
  }
  return postRequest(
    `action/${actionSetId}/addTask`,
    body,
  )
}
