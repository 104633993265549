/* eslint-disable react/no-array-index-key */

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Tooltip from '@material-ui/core/Tooltip'
import styles from './DynamicTable.scss'

const propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({
    _lineNumber: PropTypes.number,
  })).isRequired,
  headers: PropTypes.arrayOf(PropTypes.shape({
    heading: PropTypes.string,
    key: PropTypes.string,
    subHeading: PropTypes.string,
    warning: PropTypes.string,
  })).isRequired,
  meta: PropTypes.object,
  showLineNumbers: PropTypes.bool,
  showMetaRow: PropTypes.bool,
}

const defaultProps = {
  className: null,
  meta: null,
  showLineNumbers: false,
  showMetaRow: false,
}

const DynamicTable = (props) => {
  const {
    headers, data, meta, showLineNumbers, className, showMetaRow,
  } = props

  if (headers === null && data === null) {
    return (null)
  }

  const headerCells = headers.map((header, idx) => (
    <th key={`header-${idx}`}>
      <div className={styles.header}>{header.heading}</div>
      {header.subHeading && (
        <div className={styles.subHeader}>
          {header.subHeading}
        </div>
      )}
      {header.warning && (
        <div className={styles.warning}>
          {header.warning}
        </div>
      )}
    </th>
  ))

  const dataRows = data.map((dataRow, iRow) => {
    const metaRow = meta
      ? meta[dataRow._lineNumber] || null
      : null

    if (dataRow._fillerMessage) {
      return (
        <tr className={styles.spacerRow} key={dataRow._lineNumber || `data-row-${iRow}`}>
          <td className={styles.lineNumber}>⋯</td>

          <td colSpan={headers.length}>{dataRow._fillerMessage}</td>
        </tr>
      )
    }

    return (

      <tr key={dataRow._lineNumber || `data-row-${iRow}`}>
        {showLineNumbers && (
          <td className={styles.lineNumber}>
            {dataRow._lineNumber}
          </td>
        )}

        {headers.map((header) => {
          const metaCell = metaRow ? metaRow[header.key] || null : null

          return (
            <td className={classnames(metaCell && metaCell.className)} key={header.key}>
              <div className={styles.container}>
                <Tooltip arrow interactive placement="top" title={metaCell ? metaCell.title : ''}>
                  <span>{dataRow[header.key] === '' ? '(blank)' : dataRow[header.key]}</span>
                </Tooltip>
              </div>
            </td>
          )
        })}

      </tr>
    )
  })

  return (
    <div className={classnames(styles.tableContainer, className)}>

      <table className={styles.table}>

        <thead>
          <tr>
            {showLineNumbers && <th className={styles.lineNumber}>Line</th>}
            {headerCells}
          </tr>
        </thead>

        <tbody>
          {showMetaRow && (
            <tr className={styles.meta}>
              {showLineNumbers && <td className={styles.lineNumber} />}
              {headers.map(h => <td key={h.key}>{h.metaHeading}</td>)}
            </tr>
          )}

          {dataRows}
        </tbody>
      </table>
    </div>
  )
}

DynamicTable.propTypes = propTypes
DynamicTable.defaultProps = defaultProps

export default DynamicTable
