/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-string-refs */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import constants from 'constants'
import Suggestions from './components/Suggestions'

import styles from './SearchFilterInput.scss'

class SearchFilterInput extends Component {
  static propTypes = {
    autocomplete: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    autofocus: PropTypes.bool,
    className: PropTypes.string,
    delimeters: PropTypes.arrayOf(PropTypes.number),
    disabled: PropTypes.bool,
    focus: PropTypes.bool,
    handleSubmit: PropTypes.func,
    id: PropTypes.string,
    inline: PropTypes.bool,
    minQueryLength: PropTypes.number,
    mustMatch: PropTypes.bool,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    suggestions: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })),
    tags: PropTypes.arrayOf(PropTypes.string),
  }

  static defaultProps = {
    autocomplete: false,
    autofocus: false,
    className: null,
    delimeters: [13, 9],
    disabled: false,
    id: null,
    focus: false,
    handleSubmit: () => {},
    inline: true,
    minQueryLength: 2,
    mustMatch: false,
    name: null,
    onBlur: null,
    onFocus: null,
    placeholder: 'Search or filter options',
    readOnly: false,
    suggestions: [],
    tags: [],
  }

  constructor(props) {
    super(props)
    const { suggestions } = props
    this.timeout = null
    this.state = {
      suggestions,
      query: '',
      selectedIndex: -1,
      selectionMode: false,
    }
  }

  componentDidMount() {
    const { autofocus, readOnly } = this.props
    if (autofocus && !readOnly) {
      this.refs.input.focus()
    }
  }

  componentDidUpdate(nextProps) {
    if (nextProps.focus) {
      this.refs.input.focus()
    } else {
      this.refs.input.blur()
    }
  }

  static getDerivedStateFromProps(props) {
    return {
      suggestions: props.suggestions,
    }
  }

  _filteredSuggestions = (query, suggestions) => {
    return suggestions.filter((item) => {
      // filter suggestions that are already in the tags array
      const { tags } = this.props
      let keep = true
      tags.forEach((tag) => {
        if (tag.value === item.value) {
          keep = false
        }
      })
      return keep
    }).filter((item) => {
      // filter remaining suggestions by current query
      return item.label.toLowerCase().indexOf(query.toLowerCase()) >= 0
    })
  }

  _addTagByQuery = (query) => {
    const { suggestions } = this.props
    const { selectionMode, selectedIndex } = this.state

    let q = query
    if (selectionMode) {
      const newSuggestions = this._filteredSuggestions(query, suggestions)
      q = newSuggestions[selectedIndex]
    } else if (query !== '') {
      if (typeof q !== 'object') { q = { label: q } }
    }
    if (q !== '') { this._addTag(q) }
  }

  // Handlers
  _handleFocus = () => {
    const { onFocus, name } = this.props

    if (onFocus) { onFocus(name) }
    // this.setState(() => ({ hasFocus: true }))
  }

  _handleBlur = () => {
    const { onBlur, name } = this.props

    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      if (onBlur) { onBlur(name) }
      this.setState(() => ({
        selectedIndex: -1,
        selectionMode: false,
        suggestions: [],
        query: '',
      }))
      this.refs.input.value = ''
      // this.setState(() => ({ hasFocus: false }))
    }, 200)
  }

  _handleChange = (e) => {
    const { suggestions } = this.props

    const query = e.target.value.trim()
    const newSuggestions = this._filteredSuggestions(query, suggestions)

    this.setState(() => ({
      query,
      suggestions: newSuggestions,
    }))
  }

  _handleKeyDown = (e) => {
    const { delimeters } = this.props
    const { query, suggestions, selectedIndex } = this.state

    // Hide suggestions menu on escape
    if (e.keyCode === constants.KEY_CODES.ESC) {
      e.preventDefault()
      this.setState(() => ({
        selectedIndex: -1,
        selectionMode: false,
        suggestions: [],
      }))
    }

    // When one of the terminating keys is pressed, add current query to the tags.
    // If no text is typed in so far, ignore the action - so we don't end up with a terminating
    // character typed in.
    if (delimeters.indexOf(e.keyCode) !== -1) {
      if (e.keyCode !== constants.KEY_CODES.TAB) {
        e.preventDefault()
      }
      this._addTagByQuery(query)
    }

    // Up Arrow
    if (e.keyCode === constants.KEY_CODES.UP_ARROW) {
      e.preventDefault()
      const si = selectedIndex

      // On Last item go to the top
      if (si <= 0) {
        this.setState((prevState, props) => ({
          selectedIndex: props.suggestions.length - 1,
          selectionMode: true,
        }))
      } else {
        this.setState(() => ({
          selectedIndex: si - 1,
          selectionMode: true,
        }))
      }
    }

    // Down Arrow
    if (e.keyCode === constants.KEY_CODES.DOWN_ARROW) {
      e.preventDefault()
      this.setState(prevState => ({
        selectedIndex: (prevState.selectedIndex + 1) % suggestions.length,
        selectionMode: true,
      }))
    }
  }

  _handleClick = (tag) => {
    clearTimeout(this.timeout)
    this.setState(() => ({
      selectedIndex: -1,
      selectionMode: false,
    }))
    this._addTag(tag, true)
  }

  _addTag = (tag, viaClick = false) => {
    const {
      autocomplete, suggestions, mustMatch, handleSubmit,
    } = this.props
    const { query, selectionMode } = this.state
    const { input } = this.refs

    let newTag = tag
    let hasMatch = false

    if (autocomplete && query !== '') {
      const possibleMatches = this._filteredSuggestions(tag.label, suggestions)

      if (autocomplete && possibleMatches.length) {
        hasMatch = true
        newTag = possibleMatches[0]
      }
    }

    // Call method to add
    if (
      !mustMatch
      || selectionMode
      || viaClick
      || (mustMatch && hasMatch)
    ) {
      handleSubmit(newTag.label)
    }

    // Reset the state
    this.setState(() => ({
      query: '',
      selectionMode: false,
      selectedIndex: -1,
    }))

    input.value = ''
    input.blur()

    // focus back on the input box
    clearTimeout(this.timeout)
    // this.setState(() => ({ hasFocus: true }))
  }

  _input = () => {
    const { minQueryLength, focus } = this.props
    const { query } = this.state

    // get the suggestions for the given query
    const trimmedQuery = query.trim()
    const { selectedIndex } = this.state
    const { suggestions } = this.props
    const { disabled, placeholder, id } = this.props

    return (
      <div
        className={styles.input}
        id={`${id}-input`}
      >
        <input
          aria-label={placeholder}
          autoComplete="off"
          disabled={disabled}
          onBlur={this._handleBlur}
          onChange={this._handleChange}
          onFocus={this._handleFocus}
          onKeyDown={this._handleKeyDown}
          placeholder={placeholder}
          ref="input"
          type="text"
        />
        <Suggestions
          handleClick={this._handleClick}
          minQueryLength={minQueryLength}
          query={trimmedQuery}
          selectedIndex={selectedIndex}
          show={focus}
          suggestions={this._filteredSuggestions(query, suggestions)}
        />
      </div>
    )
  }

  render() {
    const {
      className,
      disabled,
      focus,
      inline,
      tags,
    } = this.props
    const { query } = this.state

    const classes = {
      [styles.isFocused]: focus,
      [styles.hasValue]: tags.length || query.length,
      [styles.disabled]: disabled,
    }

    return (
      <div className={classnames(styles.base, classes, className, focus ? styles.enabled : styles.disabled)}>
        <div className={styles.triangle} />
        <div className={styles.tags}>
          <div className={styles.selected}>
            {inline && this._input()}
          </div>
          {!inline && this._input()}
        </div>
        <span className={styles.bar} />
      </div>
    )
  }
}

export default SearchFilterInput
