/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconDocumentBlue = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--documentblue',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 74 90"{...rest}><path className="path1" fill="#00A1DE" d="M71.1 16.4L57.7 2.9C56 1.2 53.1 0 50.8 0h-45C2.8 0 0 2.5 0 5.5v79c0 3 2.8 5.5 5.8 5.5h62.7c3 0 5.5-2.4 5.5-5.5V23.2c0-2.2-1.2-5.2-2.9-6.8zM42.6 56H20.2c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h22.4c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5zm11.6-10h-34c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h34c.8 0 1.5.7 1.5 1.5S55 46 54.2 46zm0-10h-34c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h34c.8 0 1.5.7 1.5 1.5S55 36 54.2 36zM56 18V8.6l9.5 9.4H56z"/></IconBase>
  )
}

IconDocumentBlue.propTypes = {
  className: PropTypes.string,
}

export default IconDocumentBlue
/* eslint-enable */
