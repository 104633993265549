import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { notify } from 'actions/app'
import { fetchClientPackage } from 'actions/packages'
import SolutionView from 'views/Solution/SolutionView'
import Loading from 'components/Loading'
import { sendDemoRequestForm } from 'actions/support'

class Package extends Component {
  static propTypes = {
    _isContactFormSending: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.shape({
      goBack: PropTypes.func,
    }).isRequired,
    isSolutionFetched: PropTypes.bool.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        packageId: PropTypes.string,
      }),
    }).isRequired,
    solution: PropTypes.object,
  }

  static defaultProps = {
    solution: null,
  }

  componentDidMount() {
    const { dispatch, solution, match: { params: { packageId } } } = this.props

    if (solution === null || !solution.catalogue) {
      dispatch(fetchClientPackage(packageId))
    }
  }

  componentDidUpdate() {
    const { dispatch, isSolutionFetched, solution } = this.props

    if (isSolutionFetched && solution && solution.catalogue === null) {
      dispatch(notify('Unable to load solution catalogue, please try again later', 'warning'))
      dispatch(push('/home'))
    }
  }

  render() {
    const {
      solution,
      dispatch,
      history,
      _isContactFormSending,
    } = this.props

    if (solution && solution.catalogue) {
      return (
        <SolutionView
          dispatch={dispatch}
          goBack={history.goBack}
          isDemoRequestSending={_isContactFormSending}
          onSubmitDemoRequest={(selections, text) => dispatch(sendDemoRequestForm(solution.meta.displayName, selections, text))}
          solution={solution}
        />
      )
    }

    return <Loading pageLoading />
  }
}

const mapStateToProps = (
  {
    app: { user },
    packages: { packages, isPackageFetched },
    support: { _isContactFormSending },
  },
  {
    match: { params: { packageId } },
  },
) => {
  return {
    user,
    solution: packages[packageId],
    isSolutionFetched: isPackageFetched,
    _isContactFormSending,
  }
}

export default connect(mapStateToProps)(Package)
