import React from 'react'
import PropTypes from 'prop-types'
import Container from 'components/layout/Grid/Container'
import Stepper from 'components/Stepper'
import ManageTeam from 'components/ManageTeam'
import useJobCalculated from 'hooks/useJobCalculated'
import useJobCustom from 'hooks/useJobCustom'
import styles from './ProgressHeader.scss'

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  currentStep: PropTypes.number.isRequired,
  handleLoseData: PropTypes.func,
  isExternal: PropTypes.bool,
  job: PropTypes.shape({
    isClientJob: PropTypes.bool.isRequired,
    jobId: PropTypes.string,
    name: PropTypes.string,
    packageId: PropTypes.string,
    packageName: PropTypes.string,
    step: PropTypes.number,
  }),
  maximumStepAccess: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
}

const defaultProps = {
  children: null,
  isExternal: false,
  job: null,
  handleLoseData: () => {},
}

function ProgressHeader({
  children, handleLoseData, steps, currentStep, job, maximumStepAccess, isExternal,
}) {
  const { isCalculated } = useJobCalculated(job?.jobId)
  const { isCustom } = useJobCustom(job?.jobId)

  return (
    <Container bottomMargin>
      {job && (
        <div className={styles.container}>

          <div className={styles.flex}>
            <div className={styles.jobDetails}>
              {` ${job.name} | ${job.packageName}`}
            </div>
          </div>

          <div className={styles.flex}>
            { !isExternal
            && (
            <ManageTeam />
            ) }

          </div>
        </div>
      )}
      <Stepper
        current={currentStep}
        handleLoseData={handleLoseData}
        isCalculated={isCalculated}
        isCustom={isCustom}
        maximumStepAccess={maximumStepAccess}
        steps={steps}
      />
      {children}
    </Container>
  )
}

ProgressHeader.propTypes = propTypes
ProgressHeader.defaultProps = defaultProps

export default ProgressHeader
