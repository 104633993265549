import React from 'react'
import PropTypes from 'prop-types'
import DialogSimple from 'components/DialogSimple'
import WarningIcon from '@material-ui/icons/Warning'
import { Heading } from '@deloitte/gel-library'
import styles from './RunAnalysisWarningDialog.scss'

function RunAnalysisWarningDialog({
  isOpen, onAction, hasCurrentActions, onDismiss, disabled,
}) {
  return (
    <DialogSimple
      actionLabel="Run analysis"
      className={styles.main}
      disabled={disabled}
      dismissLabel="Cancel"
      icon={<WarningIcon className={styles.icon} fontSize="large" />}
      isOpen={isOpen}
      onAction={onAction}
      onDismiss={onDismiss}
    >
      <Heading level={7}>
        Are you sure you want to re-run your data?
      </Heading>
      <p>
        Your insight reports will be overwritten and updated if
        you re-process with new data.
        {' '}
        {hasCurrentActions && (
          <>
            Any documented actions will
            be archived and you will not be able to make any further changes.
          </>
        )}
      </p>
    </DialogSimple>
  )
}

RunAnalysisWarningDialog.propTypes = {
  disabled: PropTypes.bool.isRequired,
  hasCurrentActions: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onAction: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
}

RunAnalysisWarningDialog.defaultProps = {
  hasCurrentActions: false,
}

export default RunAnalysisWarningDialog

