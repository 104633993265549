import { getRequest } from 'utils/api'
import { push } from 'connected-react-router'

export const ACTIVITY_FETCHED = 'ACTIVITY_FETCHED'
export const ACTIVITY_FETCHING = 'ACTIVITY_FETCHING'

export const fetching = () => ({
  type: ACTIVITY_FETCHING,
})

export const fetched = data => ({
  type: ACTIVITY_FETCHED,
  data,
})

export function fetch() {
  return (dispatch, getState) => {
    const { router } = getState()
    dispatch(fetching())
    getRequest('/activity/getusageactivity')
      .then(({ data }) => {
        const dataObject = {
          businessUnit: data.businessUnit,
          clients: data.totalNumberOfClients,
          engagements: data.totalNumberOfEngagements,
          dataProcessed: data.totalNumberOfTransactions,
          testran: data.totalNumberOfTests,
          savings: data.totalCostSavings,
          locations: data.locations,
          totalNumberOfUsers: data.totalNumberOfUsers,
          users: data.users,
        }

        dispatch(fetched(dataObject))
      })
      .catch(() => {
        // Redirect to home page if we're not already trying to get there
        if (router.location.pathname !== '/home') {
          dispatch(push('/home'))
        }
      })
  }
}
