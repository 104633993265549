import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import constants from 'constants'
import Banner from 'components/Banner'
import Container from 'components/layout/Grid/Container'
import { withRouter } from 'react-router-dom'
import Loading from 'components/Loading'
import { connect } from 'react-redux'
import HostedReportGroupView from 'views/HostedReportGroupList'
import { fetchHostedReports } from 'actions/hostedReports'
import { hot } from 'react-hot-loader/root'
import { push } from 'connected-react-router'
import { modalShow } from 'actions/modals'

const propTypes = {
  _isFetched: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  engagements: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string,
  })).isRequired,
  filters: PropTypes.shape({
    client: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  hostedReports: PropTypes.object.isRequired,
  reportGroupIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  reportGroups: PropTypes.object.isRequired,
  user: PropTypes.shape({
    isAuOffice: PropTypes.bool,
  }).isRequired,
}

function HostedReportGroupList({
  hostedReports,
  _isFetched,
  dispatch,
  engagements,
  filters,
  reportGroupIds,
  reportGroups,
  user,
}) {
  useEffect(() => {
    dispatch(fetchHostedReports())
  }, [dispatch])

  const handleReportClick = (id) => {
    dispatch(push(`/custom/${id}/reports`))
  }

  const createAction = {
    label: 'Add collection',
    onAction: () => dispatch(modalShow(constants.MODAL_CREATE_HOSTED_REPORT)),
  }

  return (
    <div>
      <Banner actions={[createAction]} name="Your custom reports" />
      <Container topPadding>
        {!_isFetched ? (
          <Loading pageLoading />
        ) : (
          <HostedReportGroupView
            dispatch={dispatch}
            engagements={engagements}
            filters={filters}
            onClick={handleReportClick}
            openDialog={() => {}}
            openJobPopup={() => {}}
            reportGroupIds={reportGroupIds}
            reportGroups={reportGroups}
            user={user}
          />
        )}

      </Container>
    </div>
  )
}

HostedReportGroupList.propTypes = propTypes

const mapStateToProps = ({
  hostedReports, app: { user }, forms, modals,
}) => {
  return {
    ...hostedReports,
    user,
    forms,
    modals,
  }
}

export default hot(withRouter(
  connect(
    mapStateToProps,
  )(HostedReportGroupList),
))
