import React from 'react'
import PropTypes from 'prop-types'
import DialogSimple from 'components/DialogSimple'
import InfoIcon from '@material-ui/icons/Info'
import { Heading } from '@deloitte/gel-library'
import styles from './ActionWarningDialog.scss'

function EditDataWarningDialog({
  hasCurrentActions, isOpen, onAction, onDismiss,
}) {
  return (
    <DialogSimple
      actionLabel="Add/Edit data"
      className={styles.main}
      dismissLabel="Cancel"
      icon={<InfoIcon className={styles.icon} fontSize="large" />}
      isOpen={isOpen}
      onAction={onAction}
      onDismiss={onDismiss}
    >
      <Heading level={7}>
        Are you sure you want to add or edit your data?
      </Heading>
      <p>
        Your insight reports will be overwritten and updated if
        you re-process with new data.
        {' '}
        {hasCurrentActions && (
          <>
            Any documented actions will
            be archived and you will not be able to make any further changes.
          </>
        )}
      </p>
    </DialogSimple>
  )
}

EditDataWarningDialog.propTypes = {
  hasCurrentActions: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onAction: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
}

EditDataWarningDialog.defaultProps = {
  hasCurrentActions: false,
}

export default EditDataWarningDialog

