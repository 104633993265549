import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Heading } from '@deloitte/gel-library'
import NumberedList from 'components/NumberedList'
import TickList from 'components/TickList'
import { GenAiTips, GenAiChecklist } from 'features/genAi/data'
import ExpanderCard from 'views/Home/components/Expander/Expander'
import styles from './GenAiInfoPanel.scss'

const propTypes = {
  dataTipsDismissed: PropTypes.bool.isRequired,
  handleExpand: PropTypes.func.isRequired,
}

const GenAiInfoPanel = ({ dataTipsDismissed, handleExpand }) => {
  const tips = useMemo(() => {
    return GenAiTips()
  }, [])

  const checklist = useMemo(() => {
    return GenAiChecklist
  }, [])

  return (
    <ExpanderCard
      classNameContent={styles.expanderContent}
      expandedLabel="Hide"
      headerContent={(
        <div className={styles.tipHeader}>
          <Heading level={7}>Getting started with InsightBox GPTs</Heading>
        </div>
      )}
      hiddenLabel="Show"
      onExpand={handleExpand}
      size="large"
      startsExpanded={!dataTipsDismissed}
    >
      <div className="row">
        <div className="col-md-6">
          <div className={styles.column}>
            <p style={{ margin: 0 }}>
              Empowering business and technical users to save time through automation, reduce information overload and empower your creativity through a common interface.
            </p>
            <Heading level={7}>Have you done the following?</Heading>
            {tips ? <NumberedList highlightNumberOnly highlightColor="#26890d" listItems={tips} /> : null}
          </div>
        </div>
        <div className="col-md-6">
          <div className={styles.column}>
            <div className={styles.highlightBox}>
              <Heading level={7}>Reminders when using GenAI</Heading>
              <TickList listItems={checklist} />
            </div>
          </div>
        </div>
      </div> 
    </ExpanderCard>
  )
}
GenAiInfoPanel.propTypes = propTypes

export default GenAiInfoPanel
