/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconPayment = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--payment',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 252.4 212.2"{...rest}><path className="path1" fill="#7A9DA0" d="M184.8 192.2h-151c-7.6 0-13.8-6.2-13.8-13.8V37.2c0-7.6 6.2-13.8 13.8-13.8h151c7.6 0 13.8 6.2 13.8 13.8v141.1c.1 7.7-6.1 13.9-13.8 13.9z"/><path className="path2" fill="#A7CFD8" d="M187.8 188.8h-151c-7.6 0-13.8-6.2-13.8-13.8V33.8C23 26.2 29.2 20 36.8 20h151c7.6 0 13.8 6.2 13.8 13.8V175c.1 7.6-6.1 13.8-13.8 13.8z"/><path className="path3" fill="#8EB9C1" d="M187.7 188.8h-151c-7.6 0-13.8-7.1-13.8-15.8V35.8c0-8.7 6.2-15.8 13.8-15.8l164.8 153c.1 8.7-6.1 15.8-13.8 15.8z"/><path className="path4" fill="#E7F3F7" d="M28.3 47.4h168v123.3h-168z"/><circle fill="#7A9DA0" cx="35" cy="31.7" r="3.7"/><circle fill="#7A9DA0" cx="47" cy="31.7" r="3.7"/><circle fill="#7A9DA0" cx="58.7" cy="31.7" r="3.7"/><circle fill="#7A9DA0" cx="192" cy="105.3" r="40.4"/><path className="path5" fill="none" stroke="#7A9DA0" strokeWidth="5" strokeLinecap="round" strokeMiterlimit="10" d="M45.7 85.3H107M45.7 97.1h39.7M45.7 108.9h58.7"/><path className="path6" fill="none" stroke="#A7CFD8" strokeWidth="5" strokeLinecap="round" strokeMiterlimit="10" d="M174.7 104.3l12.2 15.2 25.6-26.4"/><path className="path7" fill="#D7EFF7" d="M194.4 171.1H31c-1.2 0-2.1-.5-2.1-1.1v-54.1c0-.6 1-1.1 2.1-1.1l165.6 55.3c-.1.4-1 1-2.2 1z"/><path className="path8" fill="none" stroke="#7A9DA0" strokeWidth="5" strokeLinecap="round" strokeMiterlimit="10" d="M45.7 120.8h49.2"/></IconBase>
  )
}

IconPayment.propTypes = {
  className: PropTypes.string,
}

export default IconPayment
/* eslint-enable */
