import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Card, CheckboxMui } from '@deloitte/gel-library'
import {
  Chip, ListItem, ListItemIcon, ListItemText,
} from '@material-ui/core'
import styles from './TestTable.scss'

class TestTable extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
    })).isRequired,
    disabled: PropTypes.bool,
    hasDataAnalyses: PropTypes.arrayOf(PropTypes.string).isRequired,
    noOfJobExecutions: PropTypes.number.isRequired,
    onRowSelect: PropTypes.func.isRequired,
    selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  }

  static defaultProps = {
    disabled: false,
  }

  handleAnalysisSelect = (analysisName, checked, isIndeterminate) => {
    const { selected, onRowSelect } = this.props
    let newSelection = selected
    if (isIndeterminate) {
      newSelection.push(analysisName)
    } else {
      if (checked) {
        newSelection.push(analysisName)
      } else {
        newSelection = selected.filter(ns => ns !== analysisName)
      }
    }
    onRowSelect(newSelection)
  }

  handleMassSelection = (checked) => {
    const { onRowSelect, data } = this.props
    let newSelection = []
    if (checked) {
      newSelection = data.map(item => item.name)
    }
    onRowSelect(newSelection)
  }

  render() {
    const {
      data,
      disabled,
      hasDataAnalyses,
      selected,
      noOfJobExecutions,
    } = this.props

    const showInactiveAnalysisNote = !disabled
      && noOfJobExecutions
      && data.some(item => selected.indexOf(item.name) === -1 && hasDataAnalyses.indexOf(item.name) !== -1)

    return (
      <>
        <Card noPadding className={styles.base}>
          <div className={styles.borderDiv}>
            <ListItem className={classnames(styles.headingRow, styles.row)}>
              {!disabled && (
                <ListItemIcon>
                  <CheckboxMui
                    checked={selected.length === data.length}
                    color="primary"
                    data-test-id="checkboxInput-all-analyses"
                    disabled={disabled}
                    onChange={(_, value) => this.handleMassSelection(value)}
                  />
                </ListItemIcon>
              )}
              <ListItemText className={styles.analysisName} primary="Analysis name" />
              <ListItemText className={styles.analysisDesc} primary="Description" />
              <ListItemText primary="&nbsp;" />
              {noOfJobExecutions ? <ListItemText className={styles.status} primary="Status" /> : null}
            </ListItem>
          </div>
          <div>
            {data.map((item) => {
              const checked = selected.indexOf(item.name) !== -1
              const hasDataAnalysis = hasDataAnalyses.indexOf(item.name) !== -1
              const indeterminate = !disabled && hasDataAnalysis && !checked

              return (
                <ListItem
                  className={classnames(styles.listItem, styles.row, styles.borderDiv, {
                    [styles.selected]: checked,
                  })}
                  data-id={item.name}
                  key={item.name}
                >
                  {!disabled && (
                  <ListItemIcon>
                    <CheckboxMui
                      checked={indeterminate ? true : checked}
                      color="primary"
                      data-test-id={`checkboxInput-${item.name}`}
                      disabled={disabled}
                      indeterminate={indeterminate}
                      onChange={(_, value) => this.handleAnalysisSelect(item.name, value, indeterminate)}
                    />
                  </ListItemIcon>
                  )}
                  <ListItemText className={styles.analysisName} primary={item.displayName} />
                  <ListItemText className={styles.analysisDesc} primary={item.description} />
                  {item.url ? (
                    <ListItemText>
                      <a
                        className={styles.flatButton}
                        href={item.url}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        MORE INFO
                      </a>
                    </ListItemText>
                  ) : <ListItemText primary="&nbsp;" />}
                  {noOfJobExecutions ? (
                    <ListItemText className={styles.status}>
                      {hasDataAnalysis ? (
                        <Chip
                          color={indeterminate ? 'default' : 'primary'}
                          label={indeterminate ? 'Inactive' : 'Active'}
                        />
                      ) : <div />}
                    </ListItemText>
                  ) : null}
                </ListItem>
              )
            })
        }
          </div>
        </Card>
        {showInactiveAnalysisNote ? (
          <div className={styles.inactiveAnalysisNote}>
            Making an analysis inactive means it will not be included in your results when you next run this job.
            Your engagement will still be charged for any analyses that have been run previously.
          </div>
        ) : null}
      </>
    )
  }
}

export default TestTable
