import React from 'react'
import PropTypes from 'prop-types'
import { DataTableSimple, Heading } from '@deloitte/gel-library'
import Card from 'components/Card'
import { sortByFunction } from 'utils/arrays'
import moment from 'moment'
import IconMultiFiles from 'icons/IconMultiFiles'
import { commaFormatNumber } from 'utils/numbers'
import Container from 'components/layout/Grid/Container'
import styles from './ClientJob.scss'

const propTypes = {
  job: PropTypes.shape({
    tables: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
}

const columnDetails = [
  {
    className: styles.dataName,
    heading: 'Name',
    cellExtractor: x => (
      <div>
        <IconMultiFiles height={24} width={24} />
        {x.tableName}
      </div>
    ),
    key: 'name',
  },
  {
    heading: 'No. of files',
    cellExtractor: x => `${commaFormatNumber(x.rawTableName.split(',').length)} file${x.rawTableName.split(',').length === 1 ? '' : 's'}`,
    key: 'files',
  },
  { heading: 'No. of rows', cellExtractor: x => `${commaFormatNumber(x.rowCount)} rows`, key: 'rows' },
  { heading: 'Upload date', cellExtractor: x => moment(x.dateLastUploaded).format('DD MMM YYYY'), key: 'date' },
]

function ClientData({ job }) {
  return (
    <Container>
      <Heading className={styles.firstHeading} level={8}>Data used</Heading>
      <Card noPadding className={styles.dataCard}>
        <DataTableSimple
          columns={columnDetails}
          data={job.tables}
          keyExtractor={x => x.tableCode}
          sortFunction={sortByFunction(x => x.dateLastUploaded, true)}
          spacing="large"
        />
      </Card>
    </Container>
  )
}

ClientData.propTypes = propTypes

export default ClientData
