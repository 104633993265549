/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconOnboarding = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--onboarding',
    className,
  )

  return (
    <IconBase className={classes} viewBox="0 0 24 21" xmlns="http://www.w3.org/2000/svg"{...rest}><title>Onboarding Icon</title><defs><path className="path1" d="M0 0h23.084v21H0V0z"/></defs><g fill="none" fillRule="evenodd"><path className="path2" d="M14.912 3.215a.341.341 0 0 0-.334-.348h-.452a.341.341 0 0 0-.335.348v.47c0 .193.15.348.335.348h.452a.341.341 0 0 0 .334-.348v-.47zM12.895 3.215a.341.341 0 0 0-.334-.348h-.452a.341.341 0 0 0-.335.348v.47c0 .193.15.348.335.348h.452a.341.341 0 0 0 .334-.348v-.47z" fill="#58595B"/><g><mask fill="#fff"><use xlinkHref="#a"/></mask><path className="path3" d="M21.963 19.723a.109.109 0 0 1-.107.11H6.382a.109.109 0 0 1-.107-.11V7.11c0-.061.048-.11.107-.11h15.474c.06 0 .107.049.107.11v12.612zM1.242 13.767c-.058 0-.121.113-.121.053V1.272c0-.06.063-.105.121-.105h15.517c.057 0 .05.045.05.105v4.561H6.305c-.677 0-1.151.632-1.151 1.337v6.597H1.242zm20.58-7.934H17.93v-4.56c0-.705-.494-1.273-1.17-1.273H1.242C.565 0 0 .568 0 1.272V13.82c0 .705.565 1.114 1.242 1.114h3.913v4.783c0 .705.474 1.283 1.15 1.283h15.517c.677 0 1.262-.578 1.262-1.283V7.17c0-.705-.585-1.337-1.262-1.337z" fill="#58595B" mask="url(#b)"/></g><path className="path4" d="M20.067 9.048a.341.341 0 0 0-.335-.348h-.452a.341.341 0 0 0-.334.348v.47c0 .193.15.349.334.349h.452a.341.341 0 0 0 .335-.348v-.471zM18.05 9.048a.341.341 0 0 0-.335-.348h-.452a.341.341 0 0 0-.334.348v.47c0 .193.15.349.334.349h.452a.341.341 0 0 0 .335-.348v-.471z" fill="#58595B"/></g></IconBase>
  )
}

IconOnboarding.propTypes = {
  className: PropTypes.string,
}

export default IconOnboarding
/* eslint-enable */
