import React from 'react'
import PropTypes from 'prop-types'
import { formatDateFromStringShort } from 'utils/dates'
import { Icons } from '@deloitte/gel-library'
import { jobGetMemberGroups } from 'utils/business/jobs'
import styles from './HostedReportGroupView.scss'

const propTypes = {
  onClick: PropTypes.func.isRequired,
  reportGroups: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
}

function EngagementHostedReportList({ reportGroups, onClick }) {
  const SortByLatestCreationDate = (a, b) => {
    if (a.createdDate < b.createdDate) {
      return 1
    }
    if (a.createdDate > b.createdDate) {
      return -1
    }
    return 0
  }

  const sortedReportGroups = reportGroups.sort(SortByLatestCreationDate)
  return (
    <ul className={styles.engagementListBase}>
      {sortedReportGroups.map((g) => {
        const { externalTeam } = jobGetMemberGroups(g.members)

        return (
          <li key={g.id}>
            <a onClick={() => onClick(g.id)}>
              <div className={styles.nameAndDesc}>
                <div className={styles.name}>
                  {g.name}
                </div>
                <div className={styles.description}>
                  {g.description}
                </div>
              </div>
              <div className={styles.fixedWidth}>
                <div className={styles.createdDate}>
                  {`Created ${formatDateFromStringShort(g.createdDate)}`}
                </div>
                <div className={styles.createdBy}>
                  {g.createdBy}
                </div>
              </div>
              <div className={styles.fixedWidth}>
                {externalTeam.length === 0 ? (
                  <div className={styles.subtitle}>
                    <Icons.IconLock
                      className={styles.lockIcon}
                      height={13}
                      width={13}
                    />
                    Not shared with clients
                  </div>
                ) : (
                  <div className={styles.subtitle}>
                    <Icons.IconRoleApproved
                      className={styles.clientIcon}
                      height={16}
                      width={16}
                    />

                    {`Shared with ${externalTeam.length} clients`}
                  </div>
                )}
              </div>
              <div>
                <div className={styles.noOfReports}>
                  {`${g.reports.length} Report`}
                  {g.reports.length > 1 && 's'}
                </div>
              </div>
            </a>
          </li>
        )
      })}
    </ul>
  )
}

EngagementHostedReportList.propTypes = propTypes

export default EngagementHostedReportList
