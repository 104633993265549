import { deleteRequest, postRequest } from 'utils/api'
import { getConfig } from 'utils/config'

export const postCommitData = (jobId, tableCodeList, isExternal = false) => postRequest(
  `${isExternal ? '/client' : ''}/file/commitdata`, { jobId, tableCodeList },
)

const CHUNK_SIZE = 1000 * 1000 * 2 // 2MB

const createChunkedFormData = (formData, totalChunks) => {
  const chunkedFormData = []
  const file = formData.get('file')
  for (let partIndex = 0; partIndex < totalChunks; partIndex += 1) {
    const start = partIndex * CHUNK_SIZE
    const end = Math.min(start + CHUNK_SIZE, file.size)
    const chunk = file.slice(start, end)
    const chunkedFormDatum = new FormData()
    chunkedFormDatum.set('file', chunk)
    chunkedFormDatum.append('qqtotalparts', totalChunks)
    chunkedFormDatum.append('qqpartindex', partIndex)

    /* eslint-disable no-restricted-syntax */
    for (const key of formData.keys()) {
      if (key !== 'file') {
        const value = formData.get(key)
        chunkedFormDatum.append(key, value)
      }
    }

    chunkedFormData.push(chunkedFormDatum)
  }
  return chunkedFormData
}

async function uploadChunks(chunkedFormData) {
  if (chunkedFormData.length === 0) {
    return
  }
  const [chunkedFormDatum, ...remainingChunks] = chunkedFormData
  await postRequest('file/uploadfile', chunkedFormDatum, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
  await uploadChunks(remainingChunks)
}

async function uploadSupportingChunks(chunkedFormData, isExternal) {
  if (chunkedFormData.length === 0) {
    return
  }
  const [chunkedFormDatum, ...remainingChunks] = chunkedFormData 
  await postRequest(isExternal ? 'client/file/uploadfile' : 'file/uploadfile', chunkedFormDatum, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
  await uploadChunks(remainingChunks, isExternal)
}


export async function uploadFile(formData, jobId) {
  const totalChunks = Math.ceil(formData.get('file').size / CHUNK_SIZE)
  const chunkedFormData = createChunkedFormData(formData, totalChunks)
  formData.append('qqtotalparts', chunkedFormData.length)
  await uploadChunks(chunkedFormData)
  const shortFormData = new FormData()
  /* eslint-disable no-restricted-syntax */
  for (const key of formData.keys()) {
    const value = formData.get(key)
    shortFormData.append(key, value)
  }
  shortFormData.delete('file')
  await postRequest('file/uploadfilecomplete', shortFormData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

export async function uploadSupportingFile(formData, myaId, isExternal) {
  const totalChunks = Math.ceil(formData.get('file').size / CHUNK_SIZE)
  const chunkedFormData = createChunkedFormData(formData, totalChunks)
  formData.append('qqtotalparts', chunkedFormData.length)
  await uploadSupportingChunks(chunkedFormData, isExternal)
  const shortFormData = new FormData()
  for (const key of formData.keys()) {
    const value = formData.get(key)
    shortFormData.append(key, value)
  }
  shortFormData.delete('file')
  await postRequest(isExternal ? 'client/file/uploadfilecomplete' : 'file/uploadfilecomplete', shortFormData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

export async function commitSupportingFile(formData, jobId, isExternal) {
  await postRequest(isExternal ? `client/file/commitsupportingfile/${jobId}` : `file/commitsupportingfile/${jobId}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

export async function deleteSupportingFile(formData, jobId, isExternal) {
  await deleteRequest(isExternal ? `client/file/supportingfile/${jobId}` : `file/supportingfile/${jobId}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

export const getDataRequestUrlByPackage = (packageName, recipeName, isExternal) => {
  const { API_BASE } = getConfig()
  const recipeSuffix = recipeName !== null ? `&recipe=${recipeName}` : ''
  return `${API_BASE}${isExternal ? '/client' : ''}/file/datarequest?package=${packageName}${recipeSuffix}`
}

export const getDataRequestUrlByJob = (jobId, isExternal) => {
  const { API_BASE } = getConfig()
  return `${API_BASE}${isExternal ? '/client' : ''}/file/datarequest?jobId=${jobId}`
}
