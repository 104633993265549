import React from 'react'
import PropTypes from 'prop-types'
import styles from './Label.scss'

function Label({ children }) {
  return <div className={styles.base}>{children}</div>
}

Label.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Label
