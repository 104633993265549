import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button, Heading,
} from '@deloitte/gel-library'
import LabelForm from 'actionHub/views/LabelForm'
import { isConditionallyDisabledFromLabelValues, prepareValueForComparison } from 'actionHub/utils/dependencies'
import { isNullOrWhiteSpace } from 'utils/strings'
import MultiProgressBar from 'components/MultiProgressBar'
import { formatPercent } from 'utils/numbers'
import { formatDateTimeFromStringShort } from 'utils/dates'
import { useSelector } from 'react-redux'
import { MODAL_BULK_ACTION_UPDATES } from 'constants/forms'
import SubmitButton from 'components/SubmitButton'
import Checkbox from 'components/Form/components/Checkbox'
import styles from './CaseTestingConclusion.scss'

const propTypes = {
  disabled: PropTypes.bool.isRequired,
  object: PropTypes.object.isRequired,
  selectedTaskCount: PropTypes.number,
  setLabelValue: PropTypes.func.isRequired,
  submitLabels: PropTypes.func.isRequired,
  updatedUser: PropTypes.object,
  validateLabel: PropTypes.func.isRequired,
}

const defaultProps = {
  selectedTaskCount: 0,
  updatedUser: null,
}

function CaseTestingConclusion({
  validateLabel, setLabelValue, disabled,
  object, selectedTaskCount, submitLabels, updatedUser,
}) {
  const modals = useSelector(state => state.modals)
  const isBulkUpdateModal = MODAL_BULK_ACTION_UPDATES in modals && modals[MODAL_BULK_ACTION_UPDATES].show
  const [checked, setIsChecked] = useState(false)

  if (!object) {
    return null
  }

  function applyValidationValues(obj) {
    if (!obj.labels) { return [] }

    const labelsWithValidationData = obj.labels.map((l) => {
      if (l.dependencies === null) {
        return l
      }

      const newDependencies = l.dependencies.map((d) => {
        if (d.dataColumnName === null && d.labelName === null) {
          return d
        }
        if (d.dataColumnName !== null) {
          return {
            ...d,
            validationValue: obj.taskData && prepareValueForComparison(l.labelType, obj.taskData[d.dataColumnName]),
          }
        }
        return {
          ...d,
          validationValue: obj.taskData && obj.labels.find(label => label.name === d.labelName)?.value,
        }
      })

      return {
        ...l,
        dependencies: newDependencies,
      }
    })

    return labelsWithValidationData
  }

  const labelsWithValidationData = applyValidationValues(object)
    .map(l => ({
      ...l,
      isDisabled: isConditionallyDisabledFromLabelValues(l, object.labels),
      isComplete: !isNullOrWhiteSpace(l.value),
    }))

  const requiredFields = object.labels
    ? labelsWithValidationData.filter(l => l.isRequired && !l.isDisabled)
    : []

  const completeRequiredFields = requiredFields.filter(f => f.isComplete)

  const areLabelsValid = object.labels && object.labels.every(l => !('valid' in l) || l.valid)

  const handleSubmit = () => {
    submitLabels()
  }

  return (
    <>
      <div className={styles.headingBar}>
        <Heading level={7}>Review findings</Heading>
        {!isBulkUpdateModal && requiredFields.length > 0 && (
          <span className={styles.completionText}>
            {formatPercent(completeRequiredFields.length / requiredFields.length, 0)}
            {' complete'}
          </span>
        )}
      </div>

      {!isBulkUpdateModal && requiredFields.length > 0 && (
        <MultiProgressBar
          color="primary"
          denominator={requiredFields.length}
          roundEdges={false}
          size="small"
          value={completeRequiredFields.length}
        />
      )}

      <div className={styles.labelContent}>
        {object.labels && (
          <LabelForm
            disabled={disabled}
            isModified={object._isLabelsModified}
            labels={labelsWithValidationData}
            setLabelValue={setLabelValue}
            validateLabel={validateLabel}
          />
        )}
      </div>

      {!isBulkUpdateModal && (
        <div className={styles.buttonRow}>
          {updatedUser && (
            <p className={styles.updatedLabel}>
              {`Last updated by ${updatedUser.userDetails.firstName} ${updatedUser.userDetails.surname}`}
              {` ${formatDateTimeFromStringShort(object.updatedDate)}`}
            </p>
          )}

          <div className={styles.spacer} />

          {!disabled && (
            <Button
              className={styles.saveButton}
              disabled={!object._isLabelsModified || object._isSubmittingLabels || !areLabelsValid || disabled}
              mode="primary"
              onClick={handleSubmit}
            >
              SAVE
            </Button>
          )}
        </div>
      )}
      {isBulkUpdateModal && (
        <>
          <div className={styles.checkboxDiv}>
            <Checkbox
              checked={checked}
              className={styles.checkboxText}
              handleChange={() => setIsChecked(!checked)}
              label="Confirm that you want to bulk update selected cases. Any fields filled out will override existing values in the cases you have selected."
              name="bulkUpdateInfo"
            />
          </div>
          <div className={styles.bulkUpdateSubmitBtn}>
            <SubmitButton
              disabled={!object._isLabelsModified || object._isSubmittingLabels || !areLabelsValid || disabled || !checked}
              onClick={handleSubmit}
              submittingText="Applying"
            >
              {`UPDATE ${selectedTaskCount} ENTITIES`}
            </SubmitButton>
          </div>
        </>
      )}
    </>
  )
}

CaseTestingConclusion.propTypes = propTypes
CaseTestingConclusion.defaultProps = defaultProps

export default CaseTestingConclusion
