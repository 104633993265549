/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react'
import PropTypes from 'prop-types'
import SubmitButton from 'components/SubmitButton'
import isEqual from 'lodash/isEqual'
import Heading from 'components/Heading'
import Dialog from 'components/Dialog'
import { submitTests, updateJob as update } from 'actions/job'
import { redirect } from 'actions/app'
import { sortByFunction } from 'utils/arrays'
import Messagebar from 'components/Messagebar'
import { Button } from '@deloitte/gel-library'
import { getWorkflowSteps, jobDataIsValidated } from 'utils/business/workflow'
import styles from './SelectTests.scss'
import TestTable from './components/TestTable'

class SelectTests extends React.Component {
  static propTypes = {
    app: PropTypes.shape({
      user: PropTypes.object,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    job: PropTypes.shape({
      _isCompletedJob: PropTypes.bool,
      _isSendingJob: PropTypes.bool,
      analyses: PropTypes.arrayOf(PropTypes.shape({
        hasData: PropTypes.bool,
        id: PropTypes.string,
      })),
      executions: PropTypes.arrayOf(PropTypes.object),
      isCalculated: PropTypes.bool,
      jobId: PropTypes.string,
      noOfRuns: PropTypes.number,
      packageId: PropTypes.string,
      packageName: PropTypes.string,
    }).isRequired,
    packages: PropTypes.shape({
      packages: PropTypes.object,
    }).isRequired,
  }

  constructor(props) {
    super(props)
    const {
      job: { analyses },
    } = props
    const initSelected = analyses.filter(a => a.selected).map(fa => fa.id)
    this.state = {
      initSelected,
      selected: initSelected,
      showDialog: false,
    }
  }

  handleRowSelect = (selected) => {
    this.setState({
      selected: selected.sort(),
    })
  }

  openDialog = () => {
    const { confirmed } = this.state

    if (confirmed) {
      return
    }
    this.setState({
      showDialog: true,
    })
  }

  closeDialog = () => {
    this.setState({
      showDialog: false,
    })
  }

  submitTests = () => {
    const {
      dispatch,
      packages,
      job: { jobId, packageId, analyses },
    } = this.props
    const { selected } = this.state
    const hasDataAnalyses = analyses.filter(a => a.hasData).map(fa => fa.id)

    const tests = packages.packages[packageId].analyses
    dispatch(
      submitTests({
        jobId,
        testList: tests.map((test) => {
          return {
            ...test,
            selected: selected.indexOf(test.name) > -1,
            hasData: hasDataAnalyses.indexOf(test.name) > -1,
          }
        }),
      }),
    )
  }

  undoChanges = () => {
    const {
      job: { analyses },
    } = this.props
    const initSelected = analyses.filter(a => a.selected).map(fa => fa.id)
    this.setState({ selected: initSelected.slice() })
  }

  difference = () => {
    const {
      job: { analyses },
    } = this.props
    const initSelected = analyses.filter(a => a.selected).map(fa => fa.id)
    const { selected: stateSelected } = this.state

    const init = initSelected.sort()
    const selected = stateSelected.sort()
    if (init.length !== selected.length) {
      return true
    }
    return !isEqual(init, selected)
  }

  confirm = () => {
    const { initSelected } = this.state
    const { job } = this.props

    const userHasProgressed = jobDataIsValidated(job) || job.executions.length > 0

    if (this.difference() && initSelected.length > 0 && userHasProgressed) {
      this.openDialog()
    } else {
      this.submitTests()
    }
  }

  render() {
    const {
      showDialog,
      selected,
      initSelected,
    } = this.state

    const {
      job,
      packages,
      dispatch, app: { user: { isExternal } },
    } = this.props

    const STEP_IDS = {
      CALCULATED: 5, // dataquality
      CONTROLSADVANTAGE: 4, // inputdata
      DEFAULT: 2, // selecttests
    }

    const isControlsAdvantage = job.packageName.toLowerCase() === 'ControlsAdvantage'.toLowerCase()
    let nextStepId
    if (job.isCalculated) {
      nextStepId = STEP_IDS.CALCULATED
    } else if (isControlsAdvantage) {
      nextStepId = STEP_IDS.CONTROLSADVANTAGE
    } else {
      nextStepId = STEP_IDS.DEFAULT
    }

    const hasDataAnalyses = job.analyses.filter(a => a.hasData).map(fa => fa.id)

    const tests = packages.packages[job.packageId]?.analyses || []

    const dialogProps = {
      title: 'Are you sure you want to change your analyses?',
      actions: [
        { label: 'cancel', onClick: this.closeDialog },
        {
          label: 'confirm',
          onClick: () => {
            this.closeDialog()
            this.submitTests()
            dispatch(update({ _isTestsChanged: true }))
          },
          primary: true,
        },
      ],
      className: styles.dialog,
      active: showDialog,
      onClose: this.closeDialog,
    }

    let selectButtonLabel
    switch (selected.length) {
      case 0:
        selectButtonLabel = 'Select analyses'
        break
      case 1:
        selectButtonLabel = 'Select 1 analysis'
        break
      default:
        selectButtonLabel = `Select ${selected.length} analyses`
        break
    }

    const submitButtonProps = {
      submitting: job._isSendingJob,
      complete: job._isCompletedJob,
    }

    const steps = getWorkflowSteps(job.jobId, isExternal, isControlsAdvantage)
    return (
      <div className={styles.base}>
        <Heading noPadding className={styles.title} size="h4">
          {isExternal ? 'View analyses' : 'Select analyses'}
        </Heading>
        {!isExternal && (
          <Messagebar type="info">
            {`The analyses listed below are available within the ${
              job.packageName
            } package. Select the preferred analyses to run on your job. Remember, you cannot remove analyses once your job has been processed.`}
          </Messagebar>
        )}
        <div className="row">
          <div className="col-xs-12">
            <div className={styles.testTable}>
              <TestTable
                data={tests.sort(sortByFunction(x => x.displayOrder)).filter(t => !isExternal || selected.includes(t.name))}
                disabled={isExternal}
                hasDataAnalyses={hasDataAnalyses}
                noOfJobExecutions={job.noOfRuns}
                onRowSelect={this.handleRowSelect}
                selected={isExternal ? [] : selected}
              />
            </div>
          </div>
          <Dialog {...dialogProps}>
            <p>
              Changing your analysis selection may require you to obtain additional
              datasets or columns from your client. Upon confirmation, a new
              data request document will be available for download in the next
              step.
            </p>
          </Dialog>
        </div>
        <div
          className={styles.footer}
          ref={(footer) => {
            this.Footer = footer
          }}
        >
          <Button
            className={styles.back}
            mode="flat"
            onClick={() => {
              dispatch(redirect(steps[0].to))
            }}
          >
            Back
          </Button>
          <div>
            {this.difference()
              && initSelected.length > 0 && (
                <Button
                  mode="flat"
                  onClick={this.undoChanges}
                >
                  Undo changes
                </Button>
            )}
            {!isExternal ? (
              <SubmitButton
                {...submitButtonProps}
                className={styles.CTA}
                disabled={selected.length === 0}
                onClick={this.confirm}
                type="primary"
              >
                {selectButtonLabel}
              </SubmitButton>
            ) : (
              <Button
                onClick={() => dispatch(redirect(steps[nextStepId].to))}
              >
                Continue
              </Button>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default SelectTests
