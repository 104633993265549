export function removeByKey(myObj, deleteKey) {
  return Object.keys(myObj)
    .filter(key => key !== deleteKey)
    .reduce((result, current) => {
      // result[current] = myObj[current]
      return {
        ...result,
        [current]: myObj[current],
      }
    }, {})
}

export function objectFromArray(arr, key) {
  const obj = {}
  arr.forEach((item) => {
    obj[item[key]] = item
  })
  return obj
}

export function objectFromArrayWithFunctions(arr, keyFunc, valueFunc) {
  const obj = {}
  arr.forEach((item) => {
    obj[keyFunc(item)] = valueFunc(item)
  })
  return obj
}

export function objectToArray(obj) {
  return Object.keys(obj).map(k => obj[k])
}
