/* eslint-disable react/no-array-index-key */

import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import some from 'lodash/some'
import filter from 'lodash/filter'
import forEach from 'lodash/forEach'
import constants from 'constants'
import Animate from 'components/Animate'
import Button from 'components/Button'
import { Heading } from '@deloitte/gel-library'
import Dialog from 'components/Dialog'
import Messagebar from 'components/Messagebar'
import FileInput from 'react-fine-uploader/file-input'
import Dropzone from 'react-fine-uploader/dropzone'
import { IconFileUpload } from 'icons'
import OverlayModal from 'components/OverlayModal'
import IconLinkOpenInNew from 'icons/IconLinkOpenInNew'
import { SUPPORT_PAGES } from 'constants/supportPages'
import { keepAlive } from 'utils/api/app'
import { getDataRequestUrlByJob } from 'utils/api/file'
import FileCard from './components/FileCard'

const styles = require('./FileUploaderModal.scss')

class FileUploaderModal extends React.Component {
  static propTypes = {
    addFileError: PropTypes.func.isRequired,
    deleteFile: PropTypes.func.isRequired,
    disable: PropTypes.bool,
    handleContinue: PropTypes.func.isRequired,
    handleUploaderModal: PropTypes.func.isRequired,
    isExternal: PropTypes.bool.isRequired,
    job: PropTypes.shape({
      jobId: PropTypes.string,
    }).isRequired,
    modals: PropTypes.object.isRequired,
    removeFileError: PropTypes.func.isRequired,
    resetFileError: PropTypes.func.isRequired,
    updateFile: PropTypes.func.isRequired,
    uploader: PropTypes.shape({
      columns: PropTypes.arrayOf(PropTypes.object),
      errors: PropTypes.arrayOf(PropTypes.object),
      files: PropTypes.object,
      name: PropTypes.string,
    }),
    uploaderUI: PropTypes.shape({
      methods: PropTypes.object,
    }),
  }

  static defaultProps = {
    disable: false,
    uploader: {
      files: {},
      errors: [],
    },
    uploaderUI: null,
  }

  state = {
    cancelledFileId: '',
    showDialog: false,
    showDialogClose: false,
  }

  componentDidMount() {
    keepAlive()
  }

  openDialog = (fileId) => {
    this.setState({
      showDialog: true,
      cancelledFileId: fileId,
    })
  }

  closeDialog = () => {
    this.setState({
      showDialog: false,
      cancelledFileId: '',
    })
  }

  openDialogClose = () => {
    this.setState({
      showDialogClose: true,
    })
  }

  closeDialogClose = () => {
    this.setState({
      showDialogClose: false,
    })
  }

  removeFileFromList = (file) => {
    const { deleteFile, uploaderUI } = this.props
    if (file.status === 'uploading') { this.openDialog(file.fileId) } else {
      deleteFile(file)
      uploaderUI.methods.cancel(file.fileId)
      uploaderUI.methods.removeFileRef(file.fileId)
    }
  }

  handleCancel = () => {
    const { deleteFile } = this.props
    const { cancelledFileId } = this.state

    this.closeDialog()
    deleteFile({ fileId: cancelledFileId })
  }

  handleErrors = ({ name, fileId }, error) => {
    const { addFileError } = this.props

    this.removeFileFromList({ fileId })
    addFileError({ error, name, status: 'error' })
  }

  handleExcelMoreInfo = () => {
    window.open(`/#${SUPPORT_PAGES.EXCEL_TO_CSV}`)
  }

  _reset = () => {
    const { resetFileError } = this.props

    this.setState({
      cancelledFileId: '',
      showDialog: false,
      showDialogClose: false,
    })

    resetFileError()
  }

  _hide = () => {
    const { handleUploaderModal, uploader } = this.props
    const countUpload = filter(uploader.files, ['status', 'parsed']).length
    if (countUpload > 0) { this.openDialogClose() } else {
      this._reset()
      handleUploaderModal('Hide')
    }
  }

  _init = () => {
    const { handleUploaderModal } = this.props
    handleUploaderModal('Create')
  }

  _closeDialogAndHideModal = () => {
    const { handleUploaderModal, uploader } = this.props
    forEach(filter(uploader.files, ['status', 'parsed']), (file) => {
      this.removeFileFromList(file)
    })
    this.closeDialogClose()
    this._reset()
    handleUploaderModal('Hide')
  }

  _savePendingFiles = () => {
    const {
      uploader,
      updateFile,
      handleContinue,
      resetFileError,
    } = this.props

    let hasErrors = false
    forEach(uploader.files, (file) => {
      if (file.error && file.error.invalid) { hasErrors = true }
    })

    if (!hasErrors) {
      forEach(filter(uploader.files, ['status', 'parsed']), ({ fileId }) => {
        updateFile({
          fileId,
          status: 'pending',
        })
      })

      resetFileError()

      handleContinue()
    }
  }

  render() {
    const {
      uploader,
      uploaderUI,
      modals,
      removeFileError,
      job,
      updateFile,
      isExternal,
    } = this.props
    const { showDialog, showDialogClose } = this.state

    const dialogPropsCancel = {
      actions: [
        { label: 'CONTINUE', onClick: this.closeDialog },
        { label: 'CANCEL UPLOAD', onClick: this.handleCancel, primary: true },
      ],
      className: styles.dialog,
      active: showDialog,
      onClose: this.closeDialog,
    }

    const dialogPropsClose = {
      actions: [
        { label: 'LEAVE', onClick: this._closeDialogAndHideModal },
        { label: 'STAY', onClick: this.closeDialogClose, primary: true },
      ],
      className: styles.dialog,
      active: showDialogClose,
      onClose: this.closeDialogClose,
    }

    const disable = filter(uploader.files, { status: 'parsing' }).length > 0
    const filesUploaded = []
    const files = filter(uploader.files, (file) => {
      if (file.status === 'saved') {
        filesUploaded.push(file)
        return false
      }

      return true
    })
    const dataRequest = getDataRequestUrlByJob(job.jobId, isExternal)

    return (
      <OverlayModal
        base={14}
        disable={disable}
        id={constants.MODAL_UPLOADER}
        init={this._init}
        modals={modals}
        onClose={this._hide}
        push={2}
      >
        <Animate name="fade">
          <div className={styles.content}>
            <Heading
              className={styles.title}
              level={5}
            >
              Add data to
              <br />
              {`‘${uploader.name}’`}
            </Heading>
            {filesUploaded.length > 0
              ? (
                <Messagebar type="info">
                  <div className={styles.filelist}>
                    <p>
                      Processing new data will replace all of your previous files.
                    </p>
                  </div>
                </Messagebar>
              )
              : (
                <Messagebar type="info">
                  All Excel files need to be converted into CSV format.
                  {' '}
                  {
                    <a className={styles.messageBarLink} onClick={this.handleExcelMoreInfo}>
                      Learn more
                      <IconLinkOpenInNew height={10} width={10} />
                    </a>
                  }
                </Messagebar>
              )}

            <Heading level={8}>
              Select files
            </Heading>
            <p>
              Ensure your files meet the criteria specified in the
              {' '}
              <a
                href={dataRequest}
                rel="noopener noreferrer"
                target="_blank"
              >
                data request document
              </a>
              .
            </p>
            <div className={styles.dropZone}>
              { uploaderUI && (
                <Dropzone
                  multiple
                  className="dropClassName"
                  dropActiveClassName="dropActiveClassName"
                  uploader={uploaderUI}
                >
                  <div className="drag-text">
                    Drop file to upload
                  </div>
                  <FileInput
                    multiple
                    accept="*"
                    data-test-id={`fileUploaderInput-${uploader.name}`}
                    uploader={uploaderUI}
                  >
                    <div className={styles.icon}>
                      <IconFileUpload width="54" />
                    </div>
                    <div className={styles.fileInfo}>
                      <p className={styles.fileInfoTitle}>
                        Browse files or drop here
                        <span />
                      </p>
                      <p className={styles.fileInfoSubtitle}>
                        {`Only .csv files or pipe, tab or semi-colon delimited text files (.txt, .tsv) are accepted, up to ${constants.UPLOAD_MAX_FILESIZE_DISPLAY} each.`}
                      </p>
                    </div>
                  </FileInput>
                </Dropzone>
              ) }
            </div>
            <Dialog {...dialogPropsCancel}>
              <p>
                Are you sure you want to cancel the upload of the file(s)?
              </p>
            </Dialog>
            {files.length > 0 && (
              <div>
                <p>
                  Only the first 5 rows are shown for your preview.
                </p>
              </div>
            )}
            {uploader.errors.map((error, index) => (
              <FileCard
                file={error}
                index={index}
                key={index}
                removeError={() => removeFileError(index)}
              />
            ))}
            { files.length > 0 && (
              <div className={styles.fileList}>
                {map(files, (file, index) => (
                  <FileCard
                    columns={uploader.columns}
                    file={file}
                    handleErrors={this.handleErrors}
                    index={index}
                    key={index}
                    removeFile={this.removeFileFromList}
                    updateFile={updateFile}
                    uploader={uploaderUI}
                  />
                )).reverse()}
              </div>
            ) }
            <div className={styles.footer}>
              <Button
                disabled={disable || files.length < 1 || some(files, file => file.error && file.error.length > 0) || uploader.errors.length > 0}
                onClick={this._savePendingFiles}
                type="primary"
              >
                SAVE
              </Button>
            </div>
          </div>
          <Dialog {...dialogPropsClose}>
            <p>
              Are you sure you want to leave this page? Any changes made will not be saved.
            </p>
          </Dialog>
        </Animate>
      </OverlayModal>
    )
  }
}

export default FileUploaderModal
