export const GET_DATA_TEXT = {
  DTT_GENERATE_YOUR_DATA_REQUIREMENTS: 'Use this document to get the right data from your client and prevent delays or rework.',
  CLIENT_GENERATE_YOUR_DATA_REQUIREMENTS: 'Use this document to provide the right data and prevent delays or rework.',

  DTT_WHAT_CAN_I_EXPECT_TO_RECIEVE: 'You may send the sample data to your client if it helps them to see an actual example.',
  CLIENT_WHAT_CAN_I_EXPECT_TO_RECIEVE: 'Please refer to the sample data to see specific examples.',
}

export const INPUT_DATA_TEXT = {
  DTT_SUBMIT_CHECKLIST_CONVERT_CSV_1: 'Clients can supply either Excel or text files.',
  CLIENT_SUBMIT_CHECKLIST_CONVERT_CSV_1: 'You can upload either Excel or text files.',

  DTT_SUBMIT_CHECKLIST_CONVERT_CSV_2: 'so if your client has supplied any Excel files',
  CLIENT_SUBMIT_CHECKLIST_CONVERT_CSV_2: 'so if you have any Excel files',

  DTT_TIME_SAVER_TIP_1: 'Client provided all required columns, but the titles',
  CLIENT_TIME_SAVER_TIP_1: 'The columns in your data',

  DTT_TIME_SAVER_TIP_2: 'Client provided',
  CLIENT_TIME_SAVER_TIP_2_AND_3: 'You have',

  DTT_TIME_SAVER_TIP_4: 'Client couldn\'t',
  CLIENT_TIME_SAVER_TIP_4: 'You can\'t',

  DTT_TIME_SAVER_TIP_3: 'Client provided their',

  DTT_TIME_SAVER_TIP_3_SOLN: 'the client has provided you with',

  DTT_TIME_SAVER_TIP_4_SOLN: 'so ensure you collect the necessary data from the client.',

  CLIENT_TIME_SAVER_TIP_4_SOLN: 'so ensure you include the ones you need.',
}
