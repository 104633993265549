import React from 'react'
import PropTypes from 'prop-types'
import constants from 'constants'
import classnames from 'classnames'
import every from 'lodash/every'
import { optionsFromValues } from 'utils/form'
import SearchFilter from 'components/SearchFilter'
import styles from './FilterBar.scss'

const propTypes = {
  className: PropTypes.string,
  clearFilters: PropTypes.func.isRequired,
  clients: PropTypes.arrayOf(PropTypes.string).isRequired,
  filterHandler: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    client: PropTypes.string,
    package: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  packages: PropTypes.arrayOf(PropTypes.string),
  statuses: PropTypes.arrayOf(PropTypes.string),
}

const defaultProps = {
  className: null,
  statuses: null,
  packages: null,
}

const FilterBar = ({
  className,
  clearFilters,
  statuses,
  filterHandler,
  filters,
  clients,
  packages,
}) => {
  const hasNoFilters = every(filters, value => value === '')

  return (
    <div className={classnames(styles.base, className)}>
      <div className={styles.filters}>
        <div className={styles.label}>
          Filter by:
        </div>
        { statuses && (
          <SearchFilter
            className={styles.filter}
            handleSubmit={filterHandler(constants.JOBS_FILTERS.STATUS)}
            id="jobs-status-filter"
            options={optionsFromValues(statuses)}
            text={filters.status || 'Status'}
          />
        )}

        {Object.keys(filters).includes('client') && (
          <SearchFilter
            className={styles.filter}
            handleSubmit={filterHandler(constants.JOBS_FILTERS.CLIENT)}
            id="jobs-client-filter"
            options={optionsFromValues(clients)}
            text={filters.client || 'Client'}
          />
        )}

        {Object.keys(filters).includes('package') && (
          <SearchFilter
            className={styles.filter}
            handleSubmit={filterHandler(constants.JOBS_FILTERS.PACKAGE)}
            id="jobs-package-filter"
            options={optionsFromValues(packages)}
            text={filters.package || 'Solution'}
          />
        )}

        {
          hasNoFilters
            ? null
            : (
              <button
                className={styles.clear}
                onClick={clearFilters}
                type="button"
              >
                Clear all
              </button>
            )
        }
      </div>
    </div>
  )
}

FilterBar.propTypes = propTypes
FilterBar.defaultProps = defaultProps

export default FilterBar
