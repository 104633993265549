import {
  getRequest, postRequest, putRequest, getRequestGenAIPython,
} from '../api'

const baseUrl = '/genai'

export const postGenAiApp = data => postRequest(`${baseUrl}/CreateApp`, data)
export const putGenAiApp = (appId, data) => putRequest(`${baseUrl}/UpdateApp/${appId}`, data)

export const getGenAiAppById = id => getRequest(`${baseUrl}/${id}`)
export const getGenAiApps = () => getRequest(`${baseUrl}/GetApps`)

export const getModels = () => getRequest(`${baseUrl}/Models`)

export const postAiWebsite = data => postRequest('/gen-ai/knowledge/upload/', data)
export const getAiFilesById = appId => getRequestGenAIPython(`/gen-ai/knowledge/upload/files/${appId}`)
export const getAiWebsById = appId => getRequestGenAIPython(`/gen-ai/knowledge/upload/webs/${appId}`)
