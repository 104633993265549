import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ReactSelect from 'react-select'
import classnames from 'classnames'
import formStyles from 'components/Form/Form.scss'
import componentStyles from './SelectInput.scss'

const optionPropType = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
})

const propTypes = {
  className: PropTypes.string,
  clearable: PropTypes.bool,
  components: PropTypes.object,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  floatedLabel: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  menuRenderer: PropTypes.func,
  multi: PropTypes.bool,
  name: PropTypes.string.isRequired,
  note: PropTypes.string,
  onBlur: PropTypes.func,
  openOnFocus: PropTypes.bool,
  options: PropTypes.arrayOf(optionPropType).isRequired,
  placeholder: PropTypes.string,
  searchable: PropTypes.bool,
  styles: PropTypes.object,
  valid: PropTypes.bool,
  value: PropTypes.oneOfType([
    optionPropType,
    PropTypes.string,
    PropTypes.number,
  ]),
}

const defaultProps = {
  className: null,
  clearable: false,
  components: null,
  disabled: false,
  error: '',
  floatedLabel: true,
  label: '',
  menuRenderer: null,
  multi: false,
  note: '',
  onBlur: () => {},
  openOnFocus: false,
  placeholder: '',
  searchable: false,
  styles: {},
  valid: true,
  value: '',
}

const SelectInput = (props) => {
  const {
    className,
    clearable,
    components,
    disabled,
    error,
    floatedLabel,
    handleChange,
    menuRenderer,
    multi,
    name,
    onBlur,
    openOnFocus,
    options,
    placeholder,
    searchable,
    styles,
    value,
  } = props
  const { label, note, valid } = props
  const displayNote = (!valid) ? formStyles.isHidden : ''
  const hasError = error.length ? 'hasError' : ''
  const allowLabel = !floatedLabel ? 'disableFloatedLabel' : ''

  const [isFocussed, setIsFocussed] = useState(false)

  const _handleFocus = () => {
    setIsFocussed(true)
  }

  const _handleBlur = () => {
    setIsFocussed(false)
    onBlur(name)
  }

  const _handleChange = (option) => {
    handleChange(name, multi ? option : option.value)
  }

  return (
    <div className={classnames(
      componentStyles.base,
      { [componentStyles.hasError]: !valid },
      value ? componentStyles.hasValue : null,
      isFocussed ? componentStyles.isFocussed : null,
      className,
    )}
    >
      <ReactSelect
        className="Select"
        classNamePrefix="react-select"
        clearable={clearable}
        components={components}
        isDisabled={disabled}
        isMulti={multi}
        isSearchable={searchable}
        label={label}
        menuRenderer={menuRenderer}
        name={name}
        onBlur={_handleBlur}
        onChange={_handleChange}
        onFocus={_handleFocus}
        openMenuOnFocus={openOnFocus}
        options={options}
        placeholder={placeholder}
        styles={styles}
        value={value}
      />

      <span className={componentStyles.selectBar} />
      {
        label
        && (
        <span className={classnames('selectLabel', hasError, allowLabel)}>
          {label}
        </span>
        )
      }
      {
        note
        && (
        <span className={classnames(formStyles.note, displayNote)}>
          {note}
        </span>
        )
      }
      {
        error
        && (
        <span className={classnames(formStyles.error)}>
          {error}
        </span>
        )
      }
    </div>
  )
}

SelectInput.propTypes = propTypes
SelectInput.defaultProps = defaultProps

export default SelectInput
