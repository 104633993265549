import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { fetchClientPackages } from 'actions/packages'
import { fetchClientJobs, fetchClientCustomReports } from 'actions/client'
import InsightsView from 'views/Insights/InsightsView'
import Loading from 'components/Loading'
import constants from 'constants'

class Insights extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    jobs: PropTypes.arrayOf(PropTypes.object),
    match: PropTypes.shape({
      params: PropTypes.shape({
        packageId: PropTypes.string,
      }),
    }).isRequired,
    reportGroups: PropTypes.arrayOf(PropTypes.object),
    solution: PropTypes.object,
  }

  static defaultProps = {
    solution: null,
    jobs: [],
    reportGroups: [],
  }

  componentDidMount() {
    const { solution, dispatch, match: { params: { packageId } } } = this.props

    if (packageId === constants.CUSTOM_REPORT_PACKAGE) {
      dispatch(fetchClientCustomReports())
    } else {
      dispatch(fetchClientJobs(packageId))

      if (solution === null) {
        dispatch(fetchClientPackages())
      }
    }
  }

  render() {
    const {
      solution, jobs, dispatch, isLoading, reportGroups, match: { params: { packageId } },
    } = this.props

    const isCustomReport = packageId === constants.CUSTOM_REPORT_PACKAGE

    if (isLoading) {
      return (
        <Loading pageLoading />
      )
    }

    // TODO: make a nicer error
    if (solution === null && !isCustomReport) {
      return <div>Error loading package</div>
    }

    return (
      <InsightsView
        dispatch={dispatch}
        isCustomReport={isCustomReport}
        jobs={jobs}
        reportGroups={reportGroups}
        solution={isCustomReport ? constants.CUSTOM_REPORT_PACKAGE_META : solution}
      />
    )
  }
}

const mapStateToProps = (
  {
    app: { user }, client: {
      jobIds, jobs, _fetchedJobs, reportGroups, reportGroupIds, _fetchedCustomReports,
    }, packages: { packages },
  },
  { match: { params: { packageId } } },
) => {
  return {
    user,
    solution: packages[packageId],
    jobs: jobIds[packageId]
      ? jobIds[packageId].map(id => jobs[id])
      : [],
    reportGroups: reportGroupIds.map(id => reportGroups[id]),
    isLoading: packageId === constants.CUSTOM_REPORT_PACKAGE
      ? !_fetchedCustomReports
      : !(_fetchedJobs && packages[packageId]),

  }
}

export default connect(mapStateToProps)(Insights)
