import React, { useEffect, useState } from 'react'
import { updateJobName } from 'actions/job'
import { Heading, TextInput } from '@deloitte/gel-library'
import { useDispatch, useSelector } from 'react-redux'
import { isNullOrWhiteSpace } from 'utils/strings'
import { modalCreate, modalHide } from 'actions/modals'
import { MODAL_EDIT_JOB_NAME } from 'constants/forms'
import DialogSimple from 'components/DialogSimple'

const EditJobNameModal = () => {
  const dispatch = useDispatch()
  const { name, jobId } = useSelector(state => state.job)
  const [editedName, setEditedName] = useState(null)
  const [hasEditedName, setHasEditedName] = useState(false)
  const [nameError, setNameError] = useState(null)
  const modals = useSelector(state => state.modals)
  const show = MODAL_EDIT_JOB_NAME in modals && modals[MODAL_EDIT_JOB_NAME].show

  useEffect(() => {
    dispatch(modalCreate(MODAL_EDIT_JOB_NAME))
  }, [dispatch])

  return (
    <DialogSimple
      actionLabel="confirm"
      disabled={nameError !== null}
      dismissLabel="cancel"
      isOpen={show}
      onAction={() => {
        dispatch(modalHide(MODAL_EDIT_JOB_NAME))

        if (!isNullOrWhiteSpace(editedName)) {
          dispatch(
            updateJobName({
              jobId,
              name: editedName,
            }),
          )
          setEditedName(null)
          setHasEditedName(false)
          setNameError(null)
        }
      }}
      onDismiss={() => dispatch(modalHide(MODAL_EDIT_JOB_NAME))}
    >
      <Heading level={7}>Edit job name</Heading>
      <TextInput
        error={nameError}
        name="EditJobName"
        onChange={(val) => {
          setHasEditedName(true)
          setEditedName(val)
          setNameError(isNullOrWhiteSpace(val) ? 'Job name cannot be empty.' : null)
        }}
        value={hasEditedName ? editedName : name}
      />
    </DialogSimple>
  )
}

export default EditJobNameModal
