import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Icons } from '@deloitte/gel-library'
import Card from 'components/Card'
import PasswordHolder from 'components/PasswordHolder'
import SubmitButton from 'components/SubmitButton'
import GetAppIcon from '@material-ui/icons/GetApp'
import { useDispatch, useSelector } from 'react-redux'
import { downloadStatuses } from 'utils/business/jobs'
import useDownloads from 'hooks/useDownloads'
import InfoIcon from '@material-ui/icons/Info'
import HeightAnimator from 'components/HeightAnimator/HeightAnimator'
import { getOS } from 'utils/browser'
import styles from './Download.scss'

const propTypes = {
  disabled: PropTypes.bool,
  download: PropTypes.object.isRequired,
  jobId: PropTypes.string.isRequired,
}

const defaultProps = {
  disabled: false,
}

function Download({
  jobId,
  disabled,
  download,
}) {
  const {
    displayName, status, name, key,
  } = download

  const { isExternal } = useSelector(state => state.app.user)

  const dispatch = useDispatch()
  const {
    getDownloadUpdate,
    requestJobDownload,
    handleJobDownload,
  } = useDownloads(jobId)

  const [initialStatus] = useState(status)
  const [showComplete, setShowComplete] = useState(false)

  useEffect(() => {
    let interval = null

    if (status === downloadStatuses.downloading) {
      interval = setInterval(() => {
        getDownloadUpdate(name)
      }, 10000)
    }

    return () => {
      clearInterval(interval)
    }
  }, [getDownloadUpdate, status, name])

  useEffect(() => {
    if (initialStatus === downloadStatuses.notStarted && status === downloadStatuses.complete) {
      setShowComplete(true)
      setTimeout(() => {
        setShowComplete(false)
      }, 2000)
    }
  }, [dispatch, jobId, name, status, initialStatus])

  const isComplete = status === downloadStatuses.complete
  const isSumbmitting = status === downloadStatuses.requesting || status === downloadStatuses.downloading
  const handleClick = isComplete
    ? () => handleJobDownload(name)
    : () => requestJobDownload(download.name)

  return (
    <div className={styles.container}>
      <HeightAnimator>
        <Card className={classnames(styles.base,
          {
            [styles.disabled]: disabled,
            [styles.complete]: isComplete,
          })}
        >
          <div className={styles.strip}>
            <div className={classnames(styles.downloadResources, 'no-gutters')}>
              <Icons.IconFile className={styles.fileIcon} extension="ZIP" />
              <div className={styles.details}>
                <p className={styles.fileName} title={displayName}>
                  Get your
                  {' '}
                  {displayName}
                </p>
                <p className={styles.description}>
                  <Icons.IconLock
                    className={styles.lockIcon}
                    height={13}
                    width={13}
                  />
                  Password protected
                </p>
              </div>
              <div className={styles.passwordContainer}>
                {isComplete && <PasswordHolder password={key} />}
              </div>
              <SubmitButton
                className={styles.downloadButton}
                complete={isComplete && showComplete}
                disabled={disabled}
                icon={<GetAppIcon />}
                onClick={handleClick}
                submitting={isSumbmitting}
                submittingText="Processing"
              >
                {isComplete && !showComplete ? 'DOWNLOAD' : 'REQUEST'}
              </SubmitButton>
            </div>
            {getOS() === 'Windows' && isExternal && isComplete && !showComplete && (
              <div className={styles.downloadAlertInfo}>
                <InfoIcon className={styles.InfoIcon} />
                <div>
                  {'To open this file you’ll need to have'}
                  {' '}
                  <a href="https://www.7-zip.org/download.html" rel="noopener noreferrer" target="_blank">7-Zip</a>
                  {' or '}
                  <a href="https://www.winzip.com/en/download/winzip/" rel="noopener noreferrer" target="_blank">WinZip</a>
                  {' '}
                  installed on your computer.
                </div>
              </div>
            )}
          </div>
        </Card>
      </HeightAnimator>
    </div>
  )
}

Download.propTypes = propTypes
Download.defaultProps = defaultProps

export default Download
