import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import IconUsersInCircle from 'icons/IconUsersInCircle'
import IconLockInCircle from 'icons/IconLockInCircle'
import { Button, Heading } from '@deloitte/gel-library'
import Card from 'components/Card'
import styles from './ClientAccess.scss'

const propTypes = {
  externalTeam: PropTypes.arrayOf(PropTypes.object).isRequired,
  goToTeam: PropTypes.func,
  isAuthorizedToAdd: PropTypes.bool,
  openExternalUserModal: PropTypes.func,
}

const defaultProps = {
  goToTeam: null,
  isAuthorizedToAdd: false,
  openExternalUserModal: null,
}

function ClientAccess({
  externalTeam, isAuthorizedToAdd, openExternalUserModal, goToTeam,
}) {
  if (externalTeam.length > 0) {
    return (
      <Fragment>
        <Heading level={8}>Client access</Heading>
        <Card noPadding className={styles.externalAccess}>
          <div className={styles.firstSection}>
            <IconUsersInCircle />
            <div>
              <p className={styles.heading}>
                {externalTeam.length}
                {` client user${externalTeam.length === 1 ? ' has' : 's have'} access to this job`}
              </p>
              {goToTeam && openExternalUserModal && (
                <div>
                  {!isAuthorizedToAdd && <p className={styles.clarification}>Only engagement managers or partners can add client users</p>}
                  <Button mode="flat" onClick={goToTeam}>
                    {isAuthorizedToAdd ? 'Manage access' : 'View client access'}
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div className={styles.explanation}>
            <p className={styles.heading}>What can clients see?</p>
            <p>
              Client users can interact with their insight reports
              and browse our catalogue of analytic solutions.
            </p>
          </div>
        </Card>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Heading level={8}>Client access</Heading>
      <Card noPadding className={styles.externalAccess}>
        <div className={styles.firstSection}>
          <IconLockInCircle />
          <div>
            <p className={styles.heading}>
              This job is not shared with client users
            </p>
            {goToTeam && openExternalUserModal
              && (
                <div>
                  <Button disabled={!isAuthorizedToAdd} mode="flat" onClick={openExternalUserModal}>
                    Share with client
                  </Button>
                  {!isAuthorizedToAdd && <p className={styles.clarification}>Only engagement managers or partners can add client users</p>}
                </div>
              )
            }
          </div>
        </div>
        <div className={styles.explanation}>
          <p className={styles.heading}>Why give clients access?</p>
          <p>
            Give your clients the ability to interact with their insight
            reports and browse our catalogue of analytic solutions.
          </p>
        </div>
      </Card>
    </Fragment>
  )
}

ClientAccess.propTypes = propTypes
ClientAccess.defaultProps = defaultProps

export default ClientAccess
