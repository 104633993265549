/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconLogo = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--logo',
    className,
  )

  return (
    <IconBase className={classes} viewBox="0 0 35 32" xmlns="http://www.w3.org/2000/svg"{...rest}><title>DC logo</title><defs><path className="path1" d="M0 .137h34.805V32H0V.137z"/><path className="path2" d="M0 0h34.805v31.863H0"/></defs><g fill="none" fillRule="evenodd"><mask fill="#fff"><use xlinkHref="#a"/></mask><path className="path3" d="M34.805 32c-8.859 0-16.066-7.147-16.066-15.932 0-8.784 7.207-15.93 16.066-15.93V7.89c-4.411 0-8 3.804-8 8.178 0 4.375 3.589 8.18 8 8.18V32z" fill="#FFF" mask="url(#b)"/><mask fill="#fff"><use xlinkHref="#c"/></mask><path className="path4" d="M0 32v-7.753c4.411 0 8-3.804 8-8.179C8 11.694 4.41 7.89 0 7.89V.137c8.859 0 16.065 7.147 16.065 15.931C16.065 24.853 8.86 32 0 32" fill="#004990" mask="url(#d)"/></g></IconBase>
  )
}

IconLogo.propTypes = {
  className: PropTypes.string,
}

export default IconLogo
/* eslint-enable */
