import React from 'react'
import PropTypes from 'prop-types'
import Avatar from 'components/Avatar'
import { Tooltip } from '@material-ui/core'
import Zoom from '@material-ui/core/Zoom'
import classnames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import styles from './GroupedAvatar.scss'

const MIN_DISPLAY_THRESHOLD = 5
const DISPLAY_THRESHOLD = 4

const propTypes = {
  members: PropTypes.arrayOf(PropTypes.shape({
    userDetails: PropTypes.object,
  })).isRequired,
  onDark: PropTypes.bool,
}

const defaultProps = {
  onDark: false,
}

const useStyles = makeStyles(() => ({
  tooltip: {
    whiteSpace: 'pre',
  },
}))

const WrappedAvatar = ({
  zIndexStyle, title, isAggregated, displayUser, onDark, initials,
}) => {
  const classes = useStyles()
  return (
    <Tooltip
      arrow
      interactive
      TransitionComponent={Zoom}
      classes={isAggregated ? classes : null}
      title={title}
    >
      <span className={classnames(styles.avatarWrapper, onDark ? styles.onDark : null)} style={zIndexStyle}>
        <Avatar
          group
          initials={initials}
          userDetails={displayUser}
        />
      </span>
    </Tooltip>
  )
}

WrappedAvatar.propTypes = {
  displayUser: PropTypes.object,
  initials: PropTypes.string,
  isAggregated: PropTypes.bool,
  onDark: PropTypes.bool,
  title: PropTypes.string.isRequired,
  zIndexStyle: PropTypes.object.isRequired,
}

WrappedAvatar.defaultProps = {
  isAggregated: false,
  onDark: false,
  initials: null,
  displayUser: {},
}

const GroupedAvatar = ({ members, onDark }) => {
  const userDetails = members.map(m => m.userDetails)
  const aggregatedUsersString = userDetails.slice(DISPLAY_THRESHOLD, userDetails.length).map(u => u.displayName).join('\n')

  return (
    <div>
      {userDetails.length > MIN_DISPLAY_THRESHOLD
        ? (
          <div>
            {userDetails.slice(0, DISPLAY_THRESHOLD).map((ud, i) => (
              <WrappedAvatar
                displayUser={ud}
                key={ud.email}
                onDark={onDark}
                title={ud.displayName}
                zIndexStyle={{ zIndex: 10 + ((i + 1) * -1) }}
              />
            ))}
            <WrappedAvatar
              isAggregated
              initials={`+${userDetails.length - userDetails.slice(0, DISPLAY_THRESHOLD).length}`}
              onDark={onDark}
              title={aggregatedUsersString}
              zIndexStyle={{ zIndex: 10 + ((DISPLAY_THRESHOLD + 1) * -1) }}
            />
          </div>
        )
        : (
          <div>
            {userDetails.map((ud, i) => (
              <WrappedAvatar
                displayUser={ud}
                key={ud.email}
                onDark={onDark}
                title={ud.displayName}
                zIndexStyle={{ zIndex: 10 + ((i + 1) * -1) }}
              />
            ))}
          </div>
        )
    }
    </div>
  )
}

GroupedAvatar.propTypes = propTypes
GroupedAvatar.defaultProps = defaultProps

export default GroupedAvatar
