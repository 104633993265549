/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconBankReconcilliation = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--bankreconcilliation',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"{...rest}><title>Fill 1</title><path className="path1" fill="#58595B" d="M23.9 17.3l-4.3-9.5c-.1-.2-.3-.3-.5-.3H19c-2 0-3.9-1.1-5-2.8.1-.3.2-.6.2-.9 0-1.2-1-2.3-2.3-2.3-1.2 0-2.3 1-2.3 2.3 0 .3.1.6.2.9-1.1 1.7-3 2.8-5.1 2.8-.2 0-.4.1-.5.3l-4.3 9.5v.3c0 2.7 2.2 4.9 4.9 4.9s4.9-2.2 4.9-4.9v-.3l-4-8.7c2-.2 3.8-1.3 5-3 .4.3.8.4 1.3.4s.9-.2 1.3-.4c1.2 1.7 3 2.7 5 3l-3.9 8.7v.3c0 2.7 2.2 4.9 4.9 4.9s4.9-2.2 4.9-4.9c-.2-.1-.2-.2-.3-.3zm-19-7.8L8.2 17H1.5l3.4-7.5zm0 11.8c-1.8 0-3.4-1.4-3.6-3.1h7.3c-.4 1.7-1.9 3.1-3.7 3.1zM12 4.9c-.6 0-1.1-.5-1.1-1.1 0-.6.5-1.1 1.1-1.1.6 0 1.1.5 1.1 1.1 0 .6-.5 1.1-1.1 1.1zM22.5 17h-6.8l3.4-7.5 3.4 7.5zm-3.4 4.3c-1.8 0-3.4-1.4-3.6-3.1h7.3c-.3 1.7-1.8 3.1-3.7 3.1z"/></IconBase>
  )
}

IconBankReconcilliation.propTypes = {
  className: PropTypes.string,
}

export default IconBankReconcilliation
/* eslint-enable */
