import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Button, Icons } from '@deloitte/gel-library'
import Card from 'components/Card'
import PasswordHolder from 'components/PasswordHolder'
import Messagebar from 'components/Messagebar'
import { getConfig } from 'utils/config'
import styles from './SummaryDownload.scss'

const propTypes = {
  disabled: PropTypes.bool,
  downloadDisabled: PropTypes.bool.isRequired,
  downloadFunc: PropTypes.func.isRequired,
  filename: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
}

const defaultProps = {
  disabled: false,
}

function SummaryDownload({
  disabled,
  downloadDisabled,
  downloadFunc,
  filename,
  name,
  password,
}) {
  const { SANDBOX, WORDMARK } = getConfig()

  return (
    <Card className={classnames(styles.base, { [styles.disabled]: disabled })}>
      {SANDBOX && (
        <Messagebar className={styles.messageBar} type="info">
          {`As you are using the demo version of ${WORDMARK}, only the first 5
          rows of results will be available for download.`}
        </Messagebar>
      )}

      <div className={styles.strip}>
        <Icons.IconFile className={styles.fileIcon} extension="ZIP" />
        <div className={styles.details}>
          <p className={styles.fileName}>{name}</p>
          <p className={styles.description}>
            <Icons.IconLock
              className={styles.lockIcon}
              height={13}
              width={13}
            />
            {`Password protected | ${filename}`}
          </p>
          <PasswordHolder password={password} />
        </div>

        <Button
          className={styles.downloadButton}
          disabled={downloadDisabled || disabled}
          icon={Icons.IconFileDownload}
          onClick={downloadFunc}
        >
          DOWNLOAD
        </Button>
      </div>
    </Card>
  )
}

SummaryDownload.propTypes = propTypes
SummaryDownload.defaultProps = defaultProps

export default SummaryDownload
