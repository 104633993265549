import React from 'react'
import PropTypes from 'prop-types'
import Avatar from 'components/Avatar'
import IconClose from 'icons/IconClose'
import styles from './UserResult.scss'

const UserResult = ({ user, onClose }) => (
  <div className={styles.userResult}>
    <div className={styles.avatar}>
      <Avatar nameArray={[user.details.firstName, user.details.surname]} size="medium" />
    </div>
    <div className={styles.resultDetails}>
      <div>{`${user.details.firstName} ${user.details.surname}`}</div>
      <div className={styles.userMiniDetails}>{`${user.details.email} | ${user.details.businessUnit.description} | ${user.details.country || 'Unknown country'}`}</div>
    </div>
    {onClose && (
      <div className={styles.close}>
        <IconClose height="14" onClick={onClose} width="14" />
      </div>
    )}
  </div>
)

const userResultPropTypes = {
  onClose: PropTypes.func.isRequired,
  user: PropTypes.shape({
    details: PropTypes.shape({
      businessUnit: PropTypes.shape({
        description: PropTypes.string.isRequired,
      }),
      country: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      surname: PropTypes.string.isRequired,
    }),
  }).isRequired,
}

UserResult.propTypes = userResultPropTypes

export default UserResult
