import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { TextInput } from '@deloitte/gel-library'
import Spinner from 'components/Spinner'
import classnames from 'classnames'
import styles from './SearchInput.scss'

const propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.string,
  hasSearched: PropTypes.bool,
  isSearching: PropTypes.bool,
  label: PropTypes.string,
  noResultsText: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.object || PropTypes.string,
    value: PropTypes.node,
  })),
  placeholder: PropTypes.string,
  selectedClassname: PropTypes.string,
  suggestionClassname: PropTypes.string,
  value: PropTypes.string.isRequired,
}

const defaultProps = {
  disabled: false,
  error: '',
  isSearching: false,
  label: '',
  options: [],
  onBlur: () => {},
  onChange: () => {},
  onSelect: () => {},
  placeholder: '',
  noResultsText: '',
  hasSearched: false,
  suggestionClassname: null,
  selectedClassname: null,
}

const SearchInput = (props) => {
  const {
    disabled,
    error,
    value,
    onBlur,
    onChange,
    options,
    onSelect,
    isSearching,
    label,
    placeholder,
    hasSearched,
    noResultsText,
    suggestionClassname,
    selectedClassname,
  } = props

  const [selectedOption, setSelected] = useState(-1)
  const [mouseOver, setMouseOver] = useState(false)

  useEffect(() => {
    const changeSelected = (event) => {
      if (mouseOver) { return }
      switch (event.keyCode) {
        case 40:
          if (selectedOption + 1 < options.length) {
            setSelected(selectedOption + 1)
          }
          break
        case 38:
          if (selectedOption - 1 >= 0) {
            setSelected(selectedOption - 1)
          }
          break
        case 13:
          onSelect(options[selectedOption])
          setSelected(-1)
          break
        default:
      }
    }
    document.addEventListener('keydown', changeSelected)

    return () => {
      document.removeEventListener('keydown', changeSelected)
    }
  })

  return (
    <div className={styles.base}>

      <TextInput
        disabled={disabled}
        error={error}
        label={label}
        name="search"
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
      />

      {isSearching && <Spinner className={styles.loadingSpinner} />}

      <div className={styles.suggestionContainer}>
        {options.map((o, i) => (
          <div
            className={classnames(
              styles.suggestion,
              i === selectedOption ? classnames(styles.selected, selectedClassname) : null,
              suggestionClassname,
            )}
            key={o.value}
            onBlur={() => {}}
            onFocus={() => {}}
            onMouseDown={() => onSelect(o)}
            onMouseOut={() => setMouseOver(false)}
            onMouseOver={() => {
              setMouseOver(true)
              setSelected(-1)
            }}
          >
            {o.label}
          </div>
        ))}

        {hasSearched && !isSearching && options.length === 0 && noResultsText.length > 0 && (
          <div className={`${styles.suggestion} ${styles.noResults}`}>
            {noResultsText}
          </div>
        )}

      </div>

    </div>
  )
}

SearchInput.propTypes = propTypes
SearchInput.defaultProps = defaultProps

export default SearchInput
