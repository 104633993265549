import {
  FETCHED_CURRENT_APP,
  FETCHING_CURRENT_APP,
  INITIALISE_APP_CREATION,
  RESET_SELECTED_TEMPLATE,
  UPDATE_APP_ADVANCED_SETTINGS_FORM,
  UPDATE_APP_DETAILS_FORM,
  UPDATE_APP_FULL_FORM,
  UPDATE_APP_KNOWLEDGE_BASE_FORM,
  UPDATE_APP_MODELS,
  UPDATE_APP_PROMPTS_FORM,
  UPDATE_SELECTED_TEMPLATE,
  UPLOAD_FILE,
  SUBMITTING_FILE,
  FETCHING_FILES,
  UPDATE_FILES,
  FETCHING_WEBSITES,
  SUBMITTING_WEBSITE,
  UPDATE_WEBSITES,
  UPDATE_PERCENT,
  SHOW_DUPLICATE_DIALOG,
  COMPLETE_FILE_UPLOAD,
  COMPLETE_WEBSITE_UPLOAD,
} from '../actions/actionTypes'

const initialState = {
  selectedTemplate: null,
  modelTypes: [],
  aiApp: {
    appConfig: {
      appName: '',
      description: '',
      collection: '',
      isPublic: false,
    },
    promptConfig: {
      defaultPrompt: '',
      responseFormat: '',
    },
    knowledgeBase: {
    },
    knowledge: {
      websites: {},
      files: {},
      _isFileFetching: false,
      _isWebFetching: false,
      _showDuplicateDialog: false,
    },
    advancedModelSettings: {},
    isDeleted: false,
    createdBy: '',
    createdWhen: '',
    lastUpdatedBy: '',
    lastUpdatedWhen: '',
    _isFetching: false,
    _isCreating: false,
    _isCompleted: false,
  },
}

export default function genAiApp(state = initialState, action) {
  switch (action.type) {
    case SHOW_DUPLICATE_DIALOG:
      return {
        ...state,
        aiApp: {
          ...state.aiApp,
          knowledge: {
            ...state.aiApp.knowledge,
            _showDuplicateDialog: true,
          },
        },
      }

    case SUBMITTING_WEBSITE:
      return {
        ...state,
        aiApp: {
          ...state.aiApp,
          knowledge: {
            ...state.aiApp.knowledge,
            websites: {
              ...state.aiApp.knowledge.websites,
              [action.payload.website]: {
                loading: true,
              },
            },
          },
        },
      }

    case SUBMITTING_FILE:
      return {
        ...state,
        aiApp: {
          ...state.aiApp,
          knowledge: {
            ...state.aiApp.knowledge,
            files: {
              ...state.aiApp.knowledge.files,
              [action.payload.filename]: {
                loading: true,
              },
            },
          },
        },
      }

    case COMPLETE_FILE_UPLOAD:
      return {
        ...state,
        aiApp: {
          ...state.aiApp,
          knowledge: {
            ...state.aiApp.knowledge,
            files: {
              ...state.aiApp.knowledge.files,
              [action.payload.filename]: {
                ...action.payload,
                loading: false,
              },
            },
          },
        },
      }

    case COMPLETE_WEBSITE_UPLOAD:
      return {
        ...state,
        aiApp: {
          ...state.aiApp,
          knowledge: {
            ...state.aiApp.knowledge,
            websites: {
              ...state.aiApp.knowledge.websites,
              [action.payload.website]: {
                ...action.payload,
                loading: false,
              },
            },
          },
        },
      }

    case FETCHING_FILES:
      return {
        ...state,
        aiApp: {
          ...state.aiApp,
          knowledge: {
            ...state.aiApp.knowledge,
            _isFileFetching: action.payload,
          },
        },
      }

    case FETCHING_WEBSITES:
      return {
        ...state,
        aiApp: {
          ...state.aiApp,
          knowledge: {
            ...state.aiApp.knowledge,
            _isWebFetching: action.payload,
          },
        },
      }

    case UPDATE_FILES:
      return {
        ...state,
        aiApp: {
          ...state.aiApp,
          knowledge: {
            ...state.aiApp.knowledge,
            files: action.payload,
          },
        },
      }

    case UPDATE_WEBSITES:
      return {
        ...state,
        aiApp: {
          ...state.aiApp,
          knowledge: {
            ...state.aiApp.knowledge,
            websites: action.payload,
          },
        },
      }

    case UPDATE_PERCENT:
      return {
        ...state,
        aiApp: {
          ...state.aiApp,
          knowledge: {
            ...state.aiApp.knowledge,
            files: state.aiApp.knowledge.files.concat(
              {
                filename: action.payload.filename,
                id: action.payload.id,
                percent: true,
              },
            ),
          },
        },
      }

    case FETCHING_CURRENT_APP:
      return {
        ...state,
        aiApp: {
          ...state.aiApp,
          _isFetching: action.payload,
        },
      }

    case FETCHED_CURRENT_APP:
      return {
        ...state,
        aiApp: {
          ...state.aiApp,
          _isFetching: false,
          _isCompleted: true,
        },
      }

    case UPDATE_SELECTED_TEMPLATE:
      return {
        ...state,
        selectedTemplate: action.payload,
      }

    case RESET_SELECTED_TEMPLATE:
      return {
        ...state,
        selectedTemplate: null,
      }

    case UPDATE_APP_DETAILS_FORM:
      return {
        ...state,
        aiApp: {
          ...state.aiApp,
          appConfig: action.payload,
        },
      }

    case UPDATE_APP_PROMPTS_FORM:
      return {
        ...state,
        aiApp: {
          ...state.aiApp,
          promptConfig: action.payload,
        },
      }

    case UPDATE_APP_KNOWLEDGE_BASE_FORM:
      return {
        ...state,
        aiApp: {
          ...state.aiApp,
          knowledgeBase: {
            ...state.aiApp.knowledgeBase,
          },
        },
      }

    case UPDATE_APP_ADVANCED_SETTINGS_FORM:
      return {
        ...state,
        aiApp: {
          ...state.aiApp,
          advancedModelSettings: action.payload,
        },
      }

    case UPDATE_APP_FULL_FORM:
      return {
        ...state,
        modelTypes: action.payload.modelTypes,
        aiApp: {
          ...state.aiApp,
          ...action.payload.fullAppInfo,
        },
      }

    case UPDATE_APP_MODELS:
      return {
        ...state,
        modelTypes: action.payload,
      }

    case INITIALISE_APP_CREATION:
      return {
        ...state,
        aiApp: {
          ...state.aiApp,
          appConfig: {
            ...state.appConfig,
            description: state.selectedTemplate.description,
          },
        },
      }

    case UPLOAD_FILE:
      return {
        ...state,
        aiApp: {
          ...state.aiApp,
          knowledgeBase: {
            ...state.knowledgeBase,
            files: action.payload,
          },
        },
      }

    default:
      return state
  }
}
