import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { push } from 'connected-react-router'
import HeaderCard from 'components/HeaderCard'
import Card from 'components/Card'
import ReportTable from 'views/Home/components/ReportTable'
import JobFilters from 'views/Home/components/JobFilters'
import { Colors, Heading, Searchbar } from '@deloitte/gel-library'
import { setFilters, adminResetJob, adminRetryJob } from 'actions/jobs'
import { getConfig } from 'utils/config'
import styles from './EngagementView.scss'

class EngagementView extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    jobs: PropTypes.shape({
      _isAdminJobsLoaded: PropTypes.bool,
      engagements: PropTypes.arrayOf(PropTypes.object),
      filters: PropTypes.object,
      jobIds: PropTypes.arrayOf(PropTypes.string),
      jobs: PropTypes.object,
    }).isRequired,
    openDialog: PropTypes.func,
    openJobPopup: PropTypes.func,
    resumeJob: PropTypes.func,
    user: PropTypes.shape({
      isAdmin: PropTypes.bool,
      isAuOffice: PropTypes.bool,
    }).isRequired,
  }

  static defaultProps = {
    openDialog: null,
    openJobPopup: null,
    resumeJob: null,
  }

  handleMenuClick = ({ jobId, lastExecutionId, myaId }, menu) => {
    const { dispatch, openDialog, openJobPopup } = this.props
    const { API_BASE } = getConfig()

    switch (menu) {
      case 'member':
        if (openJobPopup) { openJobPopup({ jobId }) }
        break
      case 'change':
        if (openDialog) { openDialog({ jobId }) }
        break
      case 'upload':
        dispatch(push(`/create/${jobId}/inputdata`))
        break
      case 'resetJob':
        dispatch(adminResetJob({ jobId }))
        break
      case 'retryJob':
        dispatch(adminRetryJob({ jobId }))
        break
      case 'viewExecutionLog':
        window.open(`${API_BASE}/admin/job/${jobId}/execution/${lastExecutionId}/log`)
        break
      case 'viewValidationLog':
        window.open(`${API_BASE}/admin/job/${jobId}/validation/log`)
        break
      case 'viewJobDetails':
        window.open(`${API_BASE}/admin/job/${jobId}`)
        break
      case 'viewErrorSummary':
        window.open(`${API_BASE}/admin/job/${jobId}/validation`)
        break
      case 'getInTouch':
        dispatch(push(`/contact?jobRefNo=${myaId}`))
        break

      default:
        console.error(`Unexpected menu option: ${menu}`)
    }
  }

  render() {
    const {
      jobs: {
        engagements, filters, jobIds, jobs, _isAdminJobsLoaded,
      }, dispatch, resumeJob, user: { isAdmin, isAuOffice },
    } = this.props

    const jobFilters = (job) => {
      if (filters.status && job.progressStatus !== filters.status) {
        return false
      }
      if (filters.client && job.engagement.clientName !== filters.client) {
        return false
      }
      if (filters.package && job.packageName !== filters.package) {
        return false
      }
      if (filters.search
        && (
          !job.name.toLowerCase().includes(filters.search.toLowerCase())
          && !job.engagement.name.toLowerCase().includes(filters.search.toLowerCase())
          && !job.engagement.code.toLowerCase().includes(filters.search.toLowerCase())
          && !job.engagement.clientName.toLowerCase().includes(filters.search.toLowerCase())
          && !job.myaId.toString().includes(filters.search)
        )) {
        return false
      }
      return true
    }

    const menuItems = []
    if (_isAdminJobsLoaded) {
      menuItems.push(
        { key: 'resetJob', name: 'Reset job to data input step' },
        { key: 'retryJob', name: 'Try running the job again' },
        { key: 'viewJobDetails', name: 'View raw job details' },
        { key: 'viewErrorSummary', name: 'View raw error summary info' },
        { key: 'viewValidationLog', name: 'View the last data validation log' },
        { key: 'viewExecutionLog', name: 'View the last execution log' },
      )
    } else {
      menuItems.push(
        { key: 'member', name: 'Edit job members' },
        { key: 'change', name: 'Change benchmarking option' },
        { key: 'upload', name: 'Replace and process data' },
        { key: 'downloadRequest', name: 'View data request' },
      )

      if (isAdmin) {
        menuItems.push(
          { key: 'viewJobDetails', name: 'View raw job details' },
          { key: 'viewErrorSummary', name: 'View raw error summary info' },
          { key: 'viewValidationLog', name: 'View the last data validation log' },
          { key: 'viewExecutionLog', name: 'View the last execution log' },
        )
      }
    }

    const nFilteredJobs = jobIds.map(x => jobs[x]).filter(jobFilters).length

    return (
      <div>
        <Heading className={styles.jobCount} level={8}>
          {`${nFilteredJobs} job${nFilteredJobs === 1 ? '' : 's'}`}
        </Heading>

        <div className={styles.filterArea}>
          <Searchbar
            mini
            className={styles.search}
            onSearch={value => dispatch(setFilters({ type: 'search', value }))}
            placeholder="Search jobs"
            value={filters.search}
          />

          <JobFilters
            dispatch={dispatch}
          />
        </div>

        {nFilteredJobs === 0 && (
          <Card className={styles.noResults}>
            <div>
              <p>No jobs found</p>
              <p>Try adjusting your search or filter to find what you’re looking for.</p>
            </div>
          </Card>
        )}

        {engagements.length === 1 && !isAuOffice
          ? (
            <ReportTable
              className={styles.soloReportTable}
              data={jobIds.map(jobId => jobs[jobId]).filter(jobFilters)}
              handleMenuClick={this.handleMenuClick}
              handleRowClick={resumeJob}
              isAdminView={_isAdminJobsLoaded}
              menuItems={menuItems}
              showHeader={false}
            />
          )
          : engagements.map((e) => {
            const jobList = e.jobs
              .map(jobId => jobs[jobId])
              .filter(jobFilters)

            if (jobList.length === 0) { return null }

            return (
              <HeaderCard
                heading={e.name}
                highlightColor={e.active ? Colors.supportBlue02 : Colors.uiGrey05}
                key={e.code}
                status={e.active ? 'Active' : 'Closed'}
                subHeading={e.clientName}
              >
                <ReportTable
                  data={jobList}
                  handleMenuClick={this.handleMenuClick}
                  handleRowClick={resumeJob}
                  isAdminView={_isAdminJobsLoaded}
                  menuItems={menuItems}
                  showHeader={false}
                />
              </HeaderCard>
            )
          })}
      </div>
    )
  }
}

export default EngagementView
