import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import moment from 'moment'
import Card from 'components/Card'
import { IconMenu, MenuItem } from 'react-toolbox/lib/menu'
import ProgressBar from 'components/ProgressBar'
import { formatDateShort } from 'utils/dates'
import { jobStatuses } from 'utils/business/workflow'
import WarningText from 'components/WarningText'
import Messagebar from 'components/Messagebar'
import { jobHasCriticalErrors } from 'utils/business/jobs'
import { resetCloningState } from 'actions/job'
import { hot } from 'react-hot-loader/root'
import { connect } from 'react-redux'
import { getDataRequestUrlByJob } from 'utils/api/file'
import styles from './ReportTable.scss'

class ReportTable extends Component {
  static propTypes = {
    className: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({
      analyses: PropTypes.arrayOf(PropTypes.object),
      jobId: PropTypes.string,
    })).isRequired,
    handleMenuClick: PropTypes.func.isRequired,
    handleRowClick: PropTypes.func,
    isExternal: PropTypes.bool.isRequired,
    menuItems: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
    })),
    onMenuClicked: PropTypes.func.isRequired,
    showHeader: PropTypes.bool,
  }

  static defaultProps = {
    className: null,
    handleRowClick: null,
    menuItems: [],
    showHeader: true,
  }

  renderMenuItem = (menuItem, item) => {
    const { handleMenuClick, isExternal } = this.props

    switch (menuItem.key) {
      case 'upload':
        return item.runnable && (
          <MenuItem
            caption={menuItem.name}
            key={menuItem.key}
            onClick={() => handleMenuClick(item, menuItem.key)}
          />
        )

      case 'member':
        return item.canEditTeamMembers && (
          <MenuItem
            caption={menuItem.name}
            key={menuItem.key}
            onClick={() => handleMenuClick(item, menuItem.key)}
          />
        )

      case 'downloadRequest': {
        const dataRequest = getDataRequestUrlByJob(item.jobId, isExternal)
        return (
          <MenuItem
            className={item.testsSelected ? '' : styles.disabled}
            key={menuItem.key}
            theme={styles}
          >
            <a
              href={dataRequest}
              onClick={e => (item.testsSelected ? '' : e.preventDefault())}
              rel="noopener noreferrer"
              target="_blank"
            >
              {menuItem.name}
            </a>
          </MenuItem>
        )
      }

      default:
        return (
          <MenuItem
            caption={menuItem.name}
            key={menuItem.key}
            onClick={() => handleMenuClick(item, menuItem.key)}
          />
        )
    }
  }

  render() {
    const {
      className,
      data,
      handleRowClick,
      showHeader,
      menuItems,
      onMenuClicked,
    } = this.props

    const handleClick = ({ jobId }, e) => {
      onMenuClicked()
      if (e) {
        e.preventDefault()
      }
      if (handleRowClick) {
        handleRowClick({ jobId })
      }
    }

    const getInTouchMenuItem = { key: 'getInTouch', name: 'Get in touch' }

    return (
      <div className={className}>
        <Card noPadding>
          <table
            className={classnames(styles.base, { [styles.clickable]: handleRowClick !== null })}
            role="grid"
          >
            {showHeader && (
            <th>
              <td className={styles.jobNameColumn}>Job name</td>
              <td className={styles.packageColumn}>Package</td>
              <td className={styles.lastUpdateColumn}>
                Last updated
              </td>
              <td className={styles.progressColumn}>Status</td>
              <td className={styles.cta}>&nbsp;</td>
            </th>
            )}
            <tbody>
              {data.map(item => (
                <Fragment key={item.jobId}>
                  <tr
                    className={styles.row}
                    onContextMenu={this.contextMenu}
                  >
                    <td
                      className={styles.jobNameColumn}
                      onClick={e => handleClick(item, e)}
                      role="gridcell"
                      title={`Job reference no: ${item.myaId}`}
                    >
                      <div>
                        <div>{item.name}</div>
                        {item.isClientJob && (
                        <div className={styles.subtitle}>
                          Client subscription
                        </div>
                        )}
                      </div>
                    </td>
                    <td
                      className={styles.packageColumn}
                      onClick={e => handleClick(item, e)}
                      role="gridcell"
                    >
                      <section>
                        <div>
                          {item.packageName}
                        </div>
                        <div className={styles.subtitle}>
                          {item.analyses.filter(a => a.hasData).length < 1
                            ? ''
                            : `${item.analyses.filter(a => a.hasData).length} ${
                              item.analyses.filter(a => a.hasData).length > 1 ? 'analyses' : 'analysis'
                            } | `}
                          {`${item.analysisPeriod}`}
                        </div>
                      </section>
                    </td>
                    <td
                      className={styles.lastUpdateColumn}
                      onClick={e => handleClick(item, e)}
                      role="gridcell"
                    >
                      <section>
                        <div title={moment(item.lastModifiedDate, moment.ISO_8601).local().format('YYYY-MM-DD hh:mm A')}>
                          Last updated
                          {' '}
                          {formatDateShort(moment(item.lastModifiedDate, moment.ISO_8601).local())}
                        </div>
                        <div className={styles.subtitle}>
                          {item.lastModifiedUserName}
                        </div>
                      </section>
                    </td>
                    <td
                      className={styles.progressColumn}
                      onClick={e => handleClick(item, e)}
                      role="gridcell"
                    >
                      <section>
                        <ProgressBar
                          completeChildren={jobHasCriticalErrors(item) ? <WarningText text="Critical issues in data" /> : null}
                          completeText={`${item.analyses.filter(a => a.hasData).length} report${item.analyses.filter(a => a.hasData).length > 1 ? 's' : ''} ready`}
                          statusCount={5}
                          statusKey={item.jobStatusDesc}
                          statuses={jobStatuses}
                        />
                      </section>
                    </td>
                    <td className={styles.cta}>
                      <IconMenu
                        menuRipple
                        className={styles.menuRipple}
                        icon="more_vert"
                        position="topRight"
                      >
                        {menuItems.map(menuItem => this.renderMenuItem(menuItem, item))}
                        {item.jobStatusDesc === 'ReportGenerationFailed' && this.renderMenuItem(getInTouchMenuItem, item)}
                      </IconMenu>
                    </td>
                  </tr>
                  {item.errorMessage && (
                    <tr className={styles.errorRow}>
                      <td colSpan={5}>
                        <Messagebar className={styles.error} type="error">
                          <div>{item.errorMessage}</div>
                        </Messagebar>
                      </td>
                    </tr>
                  )}
                </Fragment>
              ))}
            </tbody>
          </table>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = ({ app: { user: { isExternal } } }) => {
  return {
    isExternal,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onMenuClicked: () => {
      dispatch(resetCloningState())
    },
  }
}

export default hot(connect(mapStateToProps, mapDispatchToProps)(ReportTable))
