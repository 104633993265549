import React from 'react'
import PropTypes from 'prop-types'
import styles from './ImportActionsEmptyScreen.scss'

const propTypes = {
  textValue: PropTypes.string.isRequired,
}

const ImportActionsEmptyScreen = ({ textValue }) => {
  return (
    <div className={styles.base}>
      No
      {' '}
      {textValue}
      {' '}
      available
    </div>
  )
}
ImportActionsEmptyScreen.propTypes = propTypes

export default ImportActionsEmptyScreen
