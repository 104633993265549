/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconAppTRIM = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--apptrim',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 68"{...rest}><path className="path1" fill="#137CBE" d="M58.1 68H9.9C4.4 68 0 63.6 0 58.1V9.9C0 4.4 4.4 0 9.9 0h48.2C63.6 0 68 4.4 68 9.9v48.2c0 5.5-4.4 9.9-9.9 9.9"/><path className="path2" fill="#006CB0" d="M0 9.9v48.2C0 63.6 4.4 68 9.9 68h48.3L9.9 0C4.4 0 0 4.4 0 9.9"/><g fill="#FFF"><path className="path3" d="M16.8 41.2h-2.3V28.9h-4.2v-2H21v2h-4.2v12.3zM25.5 35.5v5.7h-2.3V26.9h4c1.8 0 3.2.3 4.1 1 .9.7 1.3 1.7 1.3 3.1 0 1.8-.9 3-2.8 3.8l4 6.3h-2.6l-3.4-5.7h-2.3zm0-1.9h1.6c1.1 0 1.9-.2 2.4-.6.5-.4.7-1 .7-1.8s-.3-1.4-.8-1.7c-.5-.4-1.3-.5-2.4-.5h-1.5v4.6zM35.9 41.2V26.9h2.3v14.2h-2.3zM48.1 41.2L44 29.3h-.1c.1 1.8.2 3.4.2 5v6.9H42V26.9h3.3l3.9 11.3h.1l4.1-11.3h3.3v14.2h-2.2v-7c0-.7 0-1.6.1-2.8 0-1.1.1-1.8.1-2.1h-.1l-4.3 11.9h-2.2z"/></g></IconBase>
  )
}

IconAppTRIM.propTypes = {
  className: PropTypes.string,
}

export default IconAppTRIM
/* eslint-enable */
