import React from 'react'
import PropTypes from 'prop-types'
import Chip from 'components/Chip'
import styles from '../../TagInput.scss'

const Tag = (props) => {
  const {
    tag, readOnly, labelField, onDelete, index, hideAvatar, handleClick,
  } = props

  const _onDelete = () => {
    if (onDelete) { onDelete(index) }
  }

  return (
    <span className={styles.tag}>
      <Chip
        handleClick={handleClick}
        handleDelete={(!readOnly) ? _onDelete : null}
        hideAvatar={hideAvatar}
        text={tag[labelField]}
      />
    </span>
  )
}

Tag.displayName = 'Tag'

Tag.propTypes = {
  handleClick: PropTypes.func,
  hideAvatar: PropTypes.bool,
  index: PropTypes.number || PropTypes.string,
  labelField: PropTypes.string,
  onDelete: PropTypes.func,
  readOnly: PropTypes.bool,
  tag: PropTypes.object.isRequired,
}

Tag.defaultProps = {
  handleClick: null,
  hideAvatar: false,
  index: null,
  labelField: 'label',
  onDelete: null,
  readOnly: false,
}

export default Tag
