import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import MuiSelect from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import ListSubheader from '@material-ui/core/ListSubheader'
import styles from './HeaderSelect.scss'

function HeaderSelect({
  className,
  options,
  optionGroups,
  showNoneOption,
  noneOptionLabel,
  onDark,
  endAdornment,
  ...rest
}) {
  return (
    <MuiSelect
      MenuProps={{
        disableScrollLock: true,
        className: onDark ? styles.menuDark : null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }}
      className={classNames(className, onDark ? styles.rootDark : null)}
      displayEmpty={showNoneOption}
      {...rest}
      input={(
        <Input
          endAdornment={endAdornment && <InputAdornment position="end">{endAdornment}</InputAdornment>}
        />
      )}
    >
      {showNoneOption && (
        <MenuItem
          value=""
        >
          {noneOptionLabel}
        </MenuItem>
      )}
      {optionGroups === null
        ? options.map(o => (
          <MenuItem
            disabled={o.disabled}
            key={o.value}
            value={o.value}
          >
            {o.label}
          </MenuItem>
        ))
        : optionGroups.map(g => (
          [
            <ListSubheader className={styles.groupHeader}>
              {g.name}
            </ListSubheader>,
            g.values.map((v) => {
              const o = options.find(x => x.value === v)
              return (
                <MenuItem
                  disabled={o.disabled}
                  key={o.value}
                  value={o.value}
                >
                  {o.label}
                </MenuItem>
              )
            }),
          ]
        ))}
    </MuiSelect>
  )
}

HeaderSelect.propTypes = {
  className: PropTypes.string,
  endAdornment: PropTypes.node,
  noneOptionLabel: PropTypes.string,
  onDark: PropTypes.bool,
  optionGroups: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.string),
  })),
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.node,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  })).isRequired,
  showNoneOption: PropTypes.bool,
}

HeaderSelect.defaultProps = {
  className: null,
  endAdornment: null,
  noneOptionLabel: 'None',
  onDark: false,
  optionGroups: null,
  showNoneOption: false,
}

export default HeaderSelect

