/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconSuperannuation = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--superannuation',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"{...rest}><title>Fill 1</title><g fill="#58595B"><path className="path1" d="M22.3 9h-1.2c-.3-.6-.7-1.2-1.2-1.7V2.2c0-.2-.1-.4-.4-.5-.2-.1-.5 0-.6.1l-3 3c-1.1-.4-2.2-.6-3.4-.6-4.9 0-8.9 3.3-9.3 7.4-.3-.1-.5-.3-.8-.5-.3-.3-.7-.6-1.2-.4-.5.1-.8.7-1.2 1.9-.1.3.1.6.4.7h.2c.2 0 .5-.2.5-.4.2-.7.4-1 .5-1.1.1 0 .1.1.2.2.3.3.8.6 1.5.8.2 2.2 1.3 4.1 3.1 5.5l-.7 1.9c-.2.7.1 1.4.8 1.6l1.1.4c.1.1.3.1.4.1.2 0 .4 0 .5-.1.3-.1.5-.4.7-.7l.5-1.5c.9.3 1.9.4 2.9.4s2-.1 2.9-.4l.6 1.5c.2.5.7.8 1.2.8.1 0 .3 0 .4-.1l1.1-.4c.7-.2 1-1 .8-1.6l-.7-1.9c1-.8 1.8-1.7 2.3-2.8h1.2c.9 0 1.7-.7 1.7-1.7v-3.2C24 9.8 23.3 9 22.3 9zM8.1 21.2c-.1 0-.1 0 0 0-.1.1-.1.1-.2.1l-1.1-.4c-.1 0-.1-.1-.1-.2l.6-1.7c.4.2.9.5 1.3.6l-.5 1.6zm10.4-.5c0 .1 0 .1-.1.2l-1.1.4c-.1 0-.1 0-.2-.1l-.6-1.5c.5-.2.9-.4 1.3-.7l.7 1.7zm4.4-6.8c0 .3-.2.5-.5.5h-1.5c-.2 0-.4.1-.5.3-1.2 2.7-4.3 4.5-7.7 4.5-4.5 0-8.2-3.1-8.2-6.9 0-3.8 3.7-6.9 8.2-6.9 1.2 0 2.3.2 3.4.6.2.1.5 0 .6-.1L19 3.6v4c0 .1.1.3.2.4.5.6 1 1.2 1.3 1.9.1.2.3.3.5.3h1.5c.3 0 .5.2.5.5v3.2z"/><path className="path2" d="M15.1 7c-.8-.3-1.7-.4-2.6-.4-.9 0-1.7.2-2.6.4-.2.2-.3.5-.2.8.1.3.4.5.7.3.7-.3 1.4-.4 2.2-.4s1.5.1 2.2.4h.2c.2 0 .5-.1.5-.4.1-.2-.1-.5-.4-.7z"/></g></IconBase>
  )
}

IconSuperannuation.propTypes = {
  className: PropTypes.string,
}

export default IconSuperannuation
/* eslint-enable */
