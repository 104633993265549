import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Card from 'components/Card'
import { Heading, Button } from '@deloitte/gel-library'
import { colorFromName } from 'utils/colours'
import SolutionImageIcon from 'components/SolutionImageIcon'
import styles from './SolutionCard.scss'

const propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  solution: PropTypes.shape({
    analyses: PropTypes.arrayOf(PropTypes.object),
    meta: PropTypes.shape({
      color: PropTypes.string,
      displayName: PropTypes.string,
      shortDescription: PropTypes.string,
    }),
    name: PropTypes.string,
  }).isRequired,
}

const defaultProps = {
  className: null,
}

function SolutionCard({ className, solution, onClick }) {
  const borderColor = colorFromName(solution.meta.color)

  return (
    <div className={classnames('col-sm-4 col-md-3', styles.wrapper, className)}>
      <Card
        hoverShadow
        noPadding
        className={styles.base}
        key={solution.name}
      >

        <SolutionImageIcon
          className={styles.imageWrapper}
          solution={solution}
          style={{ borderColor }}
        />

        <Heading className={styles.heading} level={6}>
          {solution.meta.displayName}
        </Heading>
        <p className={styles.description}>{solution.meta.shortDescription}</p>
        <div className={styles.button}>
          <Button mode="flat" onClick={onClick}>
            Learn more
          </Button>
        </div>

      </Card>
    </div>

  )
}

SolutionCard.propTypes = propTypes
SolutionCard.defaultProps = defaultProps

export default SolutionCard
