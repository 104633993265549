import React from 'react'
import PropTypes from 'prop-types'
import DialogSimple from 'components/DialogSimple'
import { Heading } from '@deloitte/gel-library'
import IconGreenTickCircle from 'icons/IconGreenTickCircle'

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
}

function SupportRequestSentDialog({ isOpen, onDismiss }) {
  return (
    <DialogSimple
      dismissLabel="Close"
      icon={<IconGreenTickCircle height={50} width={50} />}
      isOpen={isOpen}
      onDismiss={onDismiss}
    >
      <Heading level={7}>Thanks for your message!</Heading>
      <p>
        Your message has been forwarded to the team at InsightBox
        and we’ll respond shortly.
      </p>
    </DialogSimple>
  )
}

SupportRequestSentDialog.propTypes = propTypes

export default SupportRequestSentDialog
