import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { jobInitialState } from 'reducers/job'
import { updateJob } from 'actions/job'
import usePackages from './usePackages'

export const getPostAnalysisSelectionLink = (jobId, isCalculated, isControlsAdvantage) => {
  if (isControlsAdvantage) {
    return {
      url: `/create/${jobId}/inputdata`,
      name: 'Input data',
    }
  }

  return isCalculated
    ? {
      url: `/create/${jobId}/review`,
      name: 'Edit parameters',
    }
    : {
      url: `/create/${jobId}/getdata`,
      name: 'Get data',
    }
}

export const getEditDataLink = (jobId, isCalculated) => {
  return isCalculated
    ? {
      url: `/create/${jobId}/review`,
      name: 'Edit parameters',
    }
    : {
      url: `/create/${jobId}/inputdata`,
      name: 'Add/Edit data',
    }
}

export default function useJobCalculated(jobId) {
  const isExternal = useSelector(state => state.app.user.isExternal)

  const getBaseJobState = (state) => {
    return isExternal
      ? state.client.jobs[jobId] || jobInitialState
      : state.job
  }
  const dispatch = useDispatch()
  const job = useSelector(state => getBaseJobState(state))
  const solution = usePackages().getSolution(job?.packageId)

  const isCalculated = solution && solution.inputTables.length === 0

  useEffect(() => {
    if (isCalculated) {
      dispatch(updateJob({ isCalculated: true }))
    }
  }, [dispatch, isCalculated])

  const editDataLink = getEditDataLink(job.jobId, isCalculated)

  return {
    job,
    isCalculated,
    editDataLink,
  }
}

