import React from 'react'

function IconPriorityLow() {
  return (
    <svg fill="none" height="18" viewBox="0 0 13 18" width="13" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.69223 10.4368L0.228516 11.8468L6.45709 17.8468L12.6857 11.8468L11.2219 10.4368L6.45709 15.0268L1.69223 10.4368Z" fill="#26890D" />
      <path d="M7.49514 0.15332H5.41895V15.8602H7.49514V0.15332Z" fill="#26890D" />
    </svg>
  )
}

export default IconPriorityLow

