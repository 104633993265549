import axios from 'axios'
import constants from 'constants'
import { devAuthToken } from 'constants/cookies'
import { getConfig } from 'utils/config'
import { getCookie, setCookie } from './cookies'

export const signOut = () => {
  const { API_BASE } = getConfig()
  setCookie('active', false, 0)
  window.location = `${API_BASE}/account/signout`
}

const routesNotToRemember = [
  '#/signin',
  '#/terms-of-use',
]

// -----------------
// API config init
// -----------------
export const init = () => {
  const { API_BASE: baseUrl } = getConfig()
  axios.defaults.baseURL = baseUrl

  const authCookie = getCookie(devAuthToken)

  if (authCookie) {
    const bearer = `Bearer ${authCookie}`
    axios.defaults.headers.common.Authorization = bearer
  }

  // Handling authentication errors
  axios.interceptors.response.use(
    // success
    response => (response),
    // errors
    (error) => {
      if (!error.response) { return Promise.reject(error) }

      const { status, headers } = error.response
      const authHeader = headers['metamo-auth-redirect']

      if (status === 401 && authHeader && !authCookie) {
        if (!routesNotToRemember.includes(window.location.hash)) {
          localStorage.setItem(constants.LOCAL_STORAGE_KEYS.CURRENT_PATH, window.location.hash.replace('#', ''))
        }

        window.location = `${baseUrl}/account/success`
      }
      return Promise.reject(error)
    },
  )
}

export const checkIfSignedIn = () => {
  const standaloneAxios = axios.create()
  return standaloneAxios.get('/account/keepalive')
    .catch((error) => {
      if (error.response?.status === 401) {
        throw error
      }
    })
}

export const getRequest = (api) => {
  const url = `${api}`
  return axios.get(url).then(({ data }) => {
    return {
      data: data.result,
    }
  })
}

export const postRequest = (api, data) => {
  const url = `${api}`
  return axios.post(url, data).then(({ data: fetchedData }) => {
    return {
      data: fetchedData.result,
    }
  })
}

export const postRequestGenAIPython = (api, data) => {
  const { URL_BASE: genAiUrl } = getConfig()
  const aiAxios = axios.create()
  aiAxios.defaults.baseURL = genAiUrl
  const url = `${api}`
  return aiAxios.post(url, data).then(({ data: fetchedData }) => {
    return {
      data: fetchedData,
    }
  })
}

export const getRequestGenAIPython = (api) => {
  const { URL_BASE: genAiUrl } = getConfig()
  const aiAxios = axios.create()
  aiAxios.defaults.baseURL = genAiUrl
  const url = `${api}`
  return aiAxios.get(url).then(({ data }) => {
    return {
      data,
    }
  })
}


export const putRequest = (api, data) => {
  const url = `${api}`
  return axios.put(url, data).then(({ data: fetchedData }) => {
    return {
      data: fetchedData.result,
    }
  })
}

export const patchRequest = (api, data) => {
  const url = `${api}`
  return axios.patch(url, data).then(({ data: fetchedData }) => {
    return {
      data: fetchedData.result,
    }
  })
}

export const deleteRequest = (api, data = {}) => {
  const url = `${api}`
  return axios.delete(url, { data }).then(({ fetchedData }) => {
    return {
      data: fetchedData ? fetchedData.result : {},
    }
  })
}

export const postRequestBinaryFile = (api, data) => {
  const url = `${api}`
  return axios
    .post(url, data, { responseType: 'arraybuffer' })
    .then(({ data: responseData, request }) => {
      const filenamePattern = /attachment; filename="*([^;"]*)/
      const regexResult = filenamePattern.exec(
        request.getResponseHeader('Content-Disposition'),
      )
      const filename = regexResult.length > 1 && regexResult[1]

      return {
        data: responseData,
        filename,
      }
    })
}

export const getRequestBinaryFile = (api) => {
  const url = `${api}`
  return axios
    .get(url, { responseType: 'arraybuffer' })
    .then(({ data: responseData, request }) => {
      const filenamePattern = /attachment; filename="*([^;"]*)/
      const regexResult = filenamePattern.exec(
        request.getResponseHeader('Content-Disposition'),
      )
      const filename = regexResult.length > 1 && regexResult[1]

      return {
        data: responseData,
        filename,
      }
    })
}

export const getRequestUrl = api => `${axios.defaults.baseURL}${api}`

// Generic Error handling
// -----------------
export const parseError = (error) => {
  console.error('API ERROR', error)
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    const {
      data,
      status,
      // headers,
    } = error.response

    const description = data && data.info && data.info.message

    switch (status) {
      case 400:
        return {
          error,
          status: constants.ERROR.BAD_REQUEST,
          description,
        }
      case 401:
        return {
          error,
          status: constants.ERROR.UNAUTHORIZED,
          description,
        }
      case 403:
        return {
          error,
          status: constants.ERROR.FORBIDDEN,
          description,
        }
      case 404:
        return {
          error,
          status: constants.ERROR.NOT_FOUND,
          description,
        }
      case 503:
        return {
          error,
          status: constants.ERROR.SERVICE_UNAVAILABLE,
          description,
        }
      default:
        return {
          error,
          status: constants.ERROR.INTERNAL_SERVER,
          description,
        }
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js

    if (error.message.toLowerCase() === 'network error') {
      return {
        error,
        status: constants.ERROR.SERVICE_UNAVAILABLE,
      }
    }

    return {
      error,
      status: constants.ERROR.OFFLINE,
    }
  } else {
    // Something happened in setting up the request that triggered an Error
    return {
      error,
      status: constants.ERROR.UNKNOWN,
    }
  }
}
