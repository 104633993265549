import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Dialog as ToolboxDialog } from 'react-toolbox/lib/dialog'
import FontIcon from 'react-toolbox/lib/font_icon'
import styles from './Modal.scss'

const Modal = ({
  actions, active, children, className, onClose, title, coverOnboarding, theme,
}) => {
  return (
    <ToolboxDialog
      actions={actions}
      active={active}
      className={classnames(
        className,
        (coverOnboarding ? styles.onBoardingCover : ''),
      )}
      onEscKeyDown={onClose}
      onOverlayClick={onClose}
      theme={theme}
      title={title}
    >
      {onClose && (
        <div
          className={styles.closeButton}
          onClick={onClose}
        >
          <FontIcon value="close" />
        </div>
      )}
      {children}
    </ToolboxDialog>
  )
}

Modal.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
  })),
  active: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  coverOnboarding: PropTypes.bool,
  onClose: PropTypes.func,
  theme: PropTypes.object,
  title: PropTypes.string,
}

Modal.defaultProps = {
  actions: [],
  active: false,
  children: null,
  className: null,
  coverOnboarding: false,
  onClose: null,
  theme: styles,
  title: '',
}

export default Modal
