/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconLinkOpenInNew = ({ className, ...rest }) => {
  const classes = classnames('icon', 'icon--ic_open_in_new', className)

  return (
    <IconBase
      className={classes}
      viewBox="0 0 10 10"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <title>ic_open_in_new_black_24px</title>
      <g fill="none" fillRule="evenodd">
        <path className="path1" d="M-1-1h12v12H-1z" />
        <path
          className="path2 fillpath"
          d="M8.5 8.5h-7v-7H5v-1H1.5a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7c.55 0 1-.45 1-1V5h-1v3.5zM6 .5v1h1.795L2.88 6.415l.705.705L8.5 2.205V4h1V.5H6z"
          fill="#009BD7"
          fillRule="nonzero"
        />
      </g>
    </IconBase>
  )
}

IconLinkOpenInNew.propTypes = {
  className: PropTypes.string,
}

export default IconLinkOpenInNew
/* eslint-enable */
