import { useDispatch, useSelector } from 'react-redux'
import { notify } from 'actions/app'
import { useCallback } from 'react'

export default function useApp() {
  const dispatch = useDispatch()
  const user = useSelector(state => state.app.user)
  const match = useSelector(state => state.match)

  // Wrapped in useCallback because often we'll want to use
  // this function inside useEffect calls and not trigger re-renders
  const showNotification = useCallback((message, error, type, timeout) => {
    if (error) {
      console.error(message, error)
    }

    // if we're logging due to an error, don't send a notification if
    // it's a 401 coz we're about to redirect
    if (!error || !error.response || error.response.status !== 401) {
      dispatch(notify(message, type, timeout))
    }
  }, [dispatch])

  return { user, match, showNotification }
}
