import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { jobInitialState } from 'reducers/job'
import { updateJob } from 'actions/job'
import usePackages from './usePackages'

export const getPostPostJobCreationLink = (jobId, isCustom) => {
  return isCustom
    ? {
      url: `/create/${jobId}/getdata`,
      name: 'Get data',
    }
    : {
      url: `/create/${jobId}/selecttests`,
      name: 'Select analyses',
    }
}

export default function useJobCustom(jobId) {
  const isExternal = useSelector(state => state.app.user.isExternal)

  const getBaseJobState = (state) => {
    return isExternal
      ? state.client.jobs[jobId] || jobInitialState
      : state.job
  }
  const dispatch = useDispatch()
  const job = useSelector(state => getBaseJobState(state))
  const solution = usePackages().getSolution(job?.packageId)

  const isCustom = solution && solution.inputTables.length === 0 && solution.analyses.length === 0

  useEffect(() => {
    if (isCustom) {
      dispatch(updateJob({ isCustom: true }))
    }
  }, [dispatch, isCustom])

  return {
    job,
    isCustom,
  }
}

