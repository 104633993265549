/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconInfoGrey = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--infogrey',
    className,
  )

  return (
    <IconBase className={classes} viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg"{...rest}><title>5D592C32-593A-46FA-8D43-19C486719F52</title><g fill="none" fillRule="evenodd"><path className="path1" d="M-5-5h60v60H-5z"/><path className="path2" d="M22.5 32.5h5v5h-5v-5zm0-20h5v15h-5v-15zM24.975 0C11.175 0 0 11.2 0 25s11.175 25 24.975 25C38.8 50 50 38.8 50 25S38.8 0 24.975 0zM25 45C13.95 45 5 36.05 5 25S13.95 5 25 5s20 8.95 20 20-8.95 20-20 20z" fill="#E0E0E0" fillRule="nonzero"/></g></IconBase>
  )
}

IconInfoGrey.propTypes = {
  className: PropTypes.string,
}

export default IconInfoGrey
/* eslint-enable */
