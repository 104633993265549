import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { StickyContainer, Sticky } from 'react-sticky'
import { Link, Element } from 'react-scroll'
import { group } from 'd3-array'
import Container from 'components/layout/Grid/Container'
import ExpanderCard from 'views/Home/components/Expander/Expander'
import { Heading } from '@deloitte/gel-library'
import ReactMarkdown from 'react-markdown'
import CardList from 'components/CardList'
import styles from './Articles.scss'

const propTypes = {
  articles: PropTypes.arrayOf(PropTypes.shape({
    topic: PropTypes.string,
  })).isRequired,
  goToArticle: PropTypes.func,
  isExternal: PropTypes.bool.isRequired,
}

const defaultProps = {
  goToArticle: null,
}

function Articles({ articles, isExternal, goToArticle }) {
  const groupedFaqs = group(articles, d => d.topic)
  const arrayGroups = Array.from(groupedFaqs, ([key, value]) => ({ key, value }))

  return (
    <Container className={styles.base}>
      <div className="row">
        <div className={classnames('col-md-3', styles.leftContainer)}>
          <StickyContainer>
            <Sticky>
              {({ style }) => (
                <ul className={styles.topicList} style={style}>
                  {arrayGroups.map(a => (
                    <li key={a.key}>
                      <Link
                        smooth
                        spy
                        activeClass={styles.active}
                        duration={400}
                        offset={-72}
                        to={a.key}
                      >
                        {a.key}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </Sticky>
          </StickyContainer>

        </div>
        <div className="col-md-9">
          {arrayGroups.map((t) => {
            return (
              <Element className={styles.topicGroup} id={t.key} key={t.key}>
                <Heading level={7}>{t.key}</Heading>
                {isExternal
                  ? t.value.map(a => (
                    <ExpanderCard
                      className={styles.articleCard}
                      classNameExpanded={styles.expandedCard}
                      headerContent={(
                        <p className={styles.articleTitle}>
                          {a.name}
                        </p>
                    )}
                      key={a.id}
                      size="large"
                    >
                      <ReactMarkdown className={styles.articleContent} source={a.description} />
                    </ExpanderCard>
                  )) : (
                    <CardList
                      contentExtractor={x => <p className={styles.articleLink}>{x.name}</p>}
                      data={t.value}
                      keyExtractor={x => x.id}
                      onClick={goToArticle}
                    />
                  )}
              </Element>
            )
          })}

        </div>
      </div>
    </Container>
  )
}

Articles.propTypes = propTypes
Articles.defaultProps = defaultProps

export default Articles
