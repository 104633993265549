import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Accordion } from '@deloitte/gel-library'
import Parameter from 'views/Create/components/Parameter'
import { groupBy, sortByFunction } from 'utils/arrays'
import { validateWithDependencies } from 'actionHub/utils/dependencies'
import { isNullOrWhiteSpace } from 'utils/strings'
import { useSelector } from 'react-redux'
import { MODAL_BULK_ACTION_UPDATES } from 'constants/forms'
import styles from './LabelForm.scss'

function LabelField({
  field, disabled, isBulkUpdateModal, modifiedFields, setModifiedFields, setValue, validateLabel,
}) {
  const [currentFieldValue, setCurrentFieldValue] = useState('')
  const isFieldModified = useMemo(() => {
    return modifiedFields.some(f => f === field.id)
  }, [field.id, modifiedFields])

  const updateData = useCallback((formValue) => {
    if (isBulkUpdateModal && !isFieldModified) {
      return { ...field.formData, value: '' }
    }
    return formValue
  }, [field.formData, isBulkUpdateModal, isFieldModified])

  const checkIfModified = useCallback((value) => {
    if (field.formData.value !== value) {
      if (!modifiedFields.includes(field.id)) {
        setModifiedFields(currentArray => [...currentArray, field.id])
      }
    } else {
      if (!modifiedFields.includes(field.id)) {
        setModifiedFields(currentArray => [...currentArray, field.id])
      }
      const updatedModifedArray = modifiedFields.filter(f => f.id !== field.id)
      setModifiedFields(updatedModifedArray)
    }
  }, [field.formData.value, field.id, modifiedFields, setModifiedFields])

  const fieldName = useMemo(() => {
    return field.name.replace(/\s+/g, '-')
  }, [field.name])

  return (
    <div className={field.isDisabled ? styles.conditionallyDisabled : null} data-test-id={fieldName}>
      <p className={styles.label}>
        {field.name}
        {field.isRequired && !field.isDisabled && <span className={styles.required}>required</span>}
      </p>
      <Parameter
        liveValidation
        simple
        className={styles[field.field.type]}
        dataTestId={fieldName}
        disabled={disabled || field.isDisabled}
        field={field.field}
        formData={updateData(field.formData)}
        label={field.name}
        onChange={(_, value) => {
          setValue(field.id, value)
          setCurrentFieldValue(value)
          checkIfModified(value)
        }}
        onValidation={() => {
          if (isBulkUpdateModal && currentFieldValue === '' && field.field.type === 'text') {
            setValue(field.id, '')
          }
          validateWithDependencies(field.id, field.formData.value, field.dependencies && field.dependencies.filter(d => d.type === 0), validateLabel)
        }}
      />
    </div>
  )
}

LabelField.propTypes = {
  disabled: PropTypes.bool.isRequired,
  field: PropTypes.shape({
    dependencies: PropTypes.arrayOf(PropTypes.object).isRequired,
    field: PropTypes.object.isRequired,
    formData: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool,
    isRequired: PropTypes.bool,
    name: PropTypes.string.isRequired,
  }).isRequired,
  isBulkUpdateModal: PropTypes.bool,
  modifiedFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  setModifiedFields: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  validateLabel: PropTypes.func.isRequired,
}

LabelField.defaultProps = {
  isBulkUpdateModal: false,
}

function LabelForm({
  className, disabled, labels, setLabelValue, validateLabel, isModified, valueForSort,
}) {
  const modals = useSelector(state => state.modals)
  const isBulkUpdateModal = MODAL_BULK_ACTION_UPDATES in modals && modals[MODAL_BULK_ACTION_UPDATES].show
  const fields = labels.map(l => ({
    id: l.id,
    group: l.labelGroup,
    name: `${l.displayName}`,
    displayOrder: l.displayOrder,
    isRequired: l.isRequired,
    isComplete: l.isComplete,
    isDisabled: l.isDisabled,
    field: {
      type: l.labelType,
      description: l.description,
      formKey: `${l.id}`,
      options: l.options && l.options.map(x => ({ label: x, value: x })),
    },
    formData: {
      // eslint-disable-next-line no-nested-ternary
      value: isBulkUpdateModal
        ? (!isModified ? '' : l.value)
        : (l.type === 'BIT' ? l.value : l.value || ''),
      valid: l.valid || true,
      error: l.error || null,
    },
    dependencies: l.dependencies,
  })).sort(sortByFunction(x => x.displayOrder))

  // using 'group' as the default value for sort, we can provide other values (displayOrder, name etc..) as prt of the 'fields' attribute with this change.
  const groupedFields = groupBy(fields, valueForSort)
  const [expandedIndex, setExpandedIndex] = useState(groupedFields.length === 1 ? 0 : -1)
  const [modifiedFields, setModifiedFields] = useState([])

  const handleAccordionChange = groupIndex => (_, isExpanded) => {
    setExpandedIndex(isExpanded ? groupIndex : null)
  }

  const warnIfDirty = useCallback((event) => {
    if (isModified) {
      // eslint-disable-next-line no-param-reassign
      event.returnValue = 'If you leave without saving, you will lose your changes'
    }
  }, [isModified])

  useEffect(() => {
    window.addEventListener('beforeunload', warnIfDirty)
    return () => {
      window.removeEventListener('beforeunload', warnIfDirty)
    }
  }, [warnIfDirty])

  if (groupedFields.length === 1 && isNullOrWhiteSpace(groupedFields[0].key)) {
    return (
      <div className={classNames(styles.base, className)}>
        {fields.map(f => (
          <LabelField
            disabled={disabled}
            field={f}
            isBulkUpdateModal={isBulkUpdateModal}
            key={f.id}
            modifiedFields={modifiedFields}
            setModifiedFields={setModifiedFields}
            setValue={setLabelValue}
            validateLabel={validateLabel}
          />
        ))}
      </div>
    )
  }

  return (
    <div className={classNames(styles.base, className)}>
      {groupedFields.map(({ key: groupName, values: groupLabels }, index) => {
        const requiredLabels = groupLabels.filter(l => l.isRequired && !l.isDisabled)
        const completeLabels = isBulkUpdateModal ? requiredLabels.filter(l => l.isComplete && modifiedFields.includes(l.id)) : requiredLabels.filter(l => l.isComplete)
        const completeStatus = requiredLabels.length > 0
          ? `${completeLabels.length} of ${requiredLabels.length} required`
          : ''

        return (
          <Accordion
            className={styles.accordion}
            detailsClassName={styles.formValues}
            expanded={expandedIndex === index}
            heading={(
              <p className={styles.groupHeading}>
                {groupName}
                <span className={styles.itemCount}>{`${groupLabels.length} items`}</span>
                <span className={styles.spacer} />
                <span className={styles.complete}>{completeStatus}</span>
              </p>
            )}
            key={groupName}
            onChange={handleAccordionChange(index)}
          >
            {groupLabels.map(f => (
              <LabelField
                disabled={disabled}
                field={f}
                isBulkUpdateModal={isBulkUpdateModal}
                key={f.id}
                modifiedFields={modifiedFields}
                setModifiedFields={setModifiedFields}
                setValue={setLabelValue}
                validateLabel={validateLabel}
              />
            ))}
          </Accordion>
        )
      })}
    </div>
  )
}

LabelForm.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  isModified: PropTypes.bool,
  labels: PropTypes.arrayOf(PropTypes.object).isRequired,
  setLabelValue: PropTypes.func.isRequired,
  validateLabel: PropTypes.func.isRequired,
  valueForSort: PropTypes.string,

}

LabelForm.defaultProps = {
  className: null,
  isModified: false,
  valueForSort: 'group',
}

export default LabelForm

