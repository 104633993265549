import React, { useState } from 'react'
import { Radio } from '@deloitte/gel-library'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import ImportActionsInfoText from 'components/ImportActionsInfoText'
import ImportActionsEmptyScreen from 'components/ImportActionsEmptyScreen'
import { formatDateTimeFromStringShort } from 'utils/dates'
import { sortByFunction } from 'utils/arrays'
import { useSelector } from 'react-redux'
import Loading from 'components/Loading'
import { isNullOrUndefined } from 'utils/utils'
import { formatPercent } from 'utils/numbers'
import styles from './PreviousActions.scss'

const propTypes = {
  isFetchedEntities: PropTypes.bool.isRequired,
  onSelection: PropTypes.func.isRequired,
  previousActions: PropTypes.arrayOf(PropTypes.object).isRequired,
}

const PreviousActions = ({
  previousActions, onSelection, isFetchedEntities,
}) => {
  const [selectedPreActSetId, setSelectedPreActSetId] = useState(null)

  const {
    previousActionsOverrideNos,
  } = useSelector(state => state.job)

  const sortedPreviousActions = previousActions && previousActions.slice().sort(sortByFunction(x => x.actionSet.updatedDate, true))

  const handleSelection = (value, overridingEntitiesCnt) => {
    setSelectedPreActSetId(value)
    onSelection(value, overridingEntitiesCnt)
  }

  const isFetchedAndEmpty = !isNullOrUndefined(sortedPreviousActions) && sortedPreviousActions.length === 0

  return (
    <div className={classNames(styles.flexContainer, styles.flexColDir)}>
      <ImportActionsInfoText />
      <div>
        {((isFetchedEntities && previousActionsOverrideNos) || isFetchedAndEmpty) ? (
          <div>
            {!isFetchedAndEmpty && (Object.keys(previousActionsOverrideNos).length > 0 && sortedPreviousActions.length > 0)
              ? (
                <div>
                  {sortedPreviousActions.map((pa) => {
                    const completed = pa.progress.filter(p => p.description === 'Complete').map(fp => fp.count)
                    const progress = formatPercent((completed / pa.totalActions), 1)
                    return (
                      <div
                        className={classNames(styles.card, {
                          [styles.selected]: selectedPreActSetId === pa.actionSet.id,
                        })}
                        key={pa.actionSet.id}
                        onClick={() => handleSelection(pa.actionSet.id, previousActionsOverrideNos[pa.actionSet.id])}
                      >

                        <div className={classNames(styles.flexContainer, styles.previousItemFlexRow)}>
                          <Radio
                            checked={selectedPreActSetId === pa.actionSet.id}
                            name="radio-buttons"
                            value={pa.actionSet.id}
                          />
                          <div className={classNames(styles.flexContainer, styles.flexColDir, styles.prevActionCardInfo)}>
                            <div>
                              {previousActionsOverrideNos[pa.actionSet.id]}
                              {' '}
                              of
                              {' '}
                              {pa.totalActions}
                              {' '}
                              entities matched
                            </div>
                            <div className={styles.subHeading}>
                              Last updated
                              {' '}
                              {formatDateTimeFromStringShort(pa.actionSet.updatedDate)}
                            </div>
                          </div>
                        </div>
                        <div className={styles.progress}>
                          {`${progress} complete`}
                        </div>
                      </div>
                    )
                  })}
                </div>
              ) : <ImportActionsEmptyScreen textValue="previous actions" />}
          </div>
        ) : <Loading pageLoading className={styles.additionalStyles} />}
      </div>
    </div>
  )
}
PreviousActions.propTypes = propTypes

export default PreviousActions

