import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './GridSelect.scss'

const propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      label: PropTypes.string,
      selected: PropTypes.bool,
      subLabel: PropTypes.string,
    }),
  ),
  rowLength: PropTypes.oneOf([3, 4]),
  value: PropTypes.string.isRequired,
}

const defaultProps = {
  className: null,
  disabled: false,
  options: [],
  rowLength: 4,
}

function GridSelect({
  className,
  disabled,
  options,
  value,
  rowLength,
  onChange,
}) {
  return (
    <div
      className={classnames(styles.base, className, {
        [styles.wide]: rowLength === 3,
        [styles.disabled]: disabled,
      })}
    >
      {options.map(x => (
        <div
          className={classnames(styles.cell, {
            [styles.selected]: value === x.value,
          })}
          data-id={x.value}
          data-test-id={`gridSelect-${x.value}`}
          key={x.value}
          onClick={() => onChange(x.value)}
        >
          <div className={styles.label}>{x.label}</div>
          <div className={styles.subLabel}>{x.subLabel}</div>
          <p>{x.description}</p>
        </div>
      ))}
    </div>
  )
}

GridSelect.propTypes = propTypes
GridSelect.defaultProps = defaultProps

export default GridSelect
