import * as ActionTypes from 'actions/job'
import { decorateJob } from 'utils/business/jobs'
import { jobInitialState } from './job'

const clonedJobInitialState = { ...jobInitialState, idOfTheNewJob: '', _isCloning: false }

const resetStateIfNeeded = (actionJobId, state) => ((actionJobId && state.jobId !== '' && actionJobId !== state.jobId)
  ? {
    ...clonedJobInitialState,
    jobId: actionJobId,
    _fetchingJobDetailsId: state._fetchingJobDetailsId,
    _isFetchingReports: state._isFetchingReports,
  }
  : state)

export default function clonedJobDetails(state = clonedJobInitialState, action) {
  switch (action.type) {
    case ActionTypes.JOB_FETCHED_JOB_TO_BE_CLONED_DETAILS:
      return {
        ...resetStateIfNeeded(action.jobId, state),
        ...decorateJob(action.job),
        _fetchingJobDetailsId: null,
        _fetchedJobDetailsId: action.job.jobId,
        _isCloning: true,
      }

    case ActionTypes.CLONED_JOB_NEW_JOB_ID:
      return {
        ...state,
        idOfTheNewJob: action.jobId,
      }

    case ActionTypes.CLONED_JOB_RESET_CLONING_STATUS:
      return {
        ...state,
        _isCloning: false,
      }

    default:
      return state
  }
}
