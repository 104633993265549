import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { IconSubmit } from 'icons'

import styles from './Spinner.scss'

const Spinner = ({ className, colour, size }) => {
  return (
    <IconSubmit className={classnames(styles.base, className, styles[colour], styles[size])} />
  )
}

Spinner.propTypes = {
  className: PropTypes.string,
  colour: PropTypes.oneOf(['green', 'grey', 'white', 'blue']),
  size: PropTypes.oneOf(['xxSmall', 'xSmall', 'small', 'medium', 'large']),
}

Spinner.defaultProps = {
  className: null,
  colour: 'green',
  size: 'small',
}

export default Spinner
