/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconFileDownload = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--filedownload',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 17"{...rest}><g fill="none" fillRule="evenodd"><path className="path1" fill="#86BD24" fillRule="nonzero" d="M14.672 6h-4V0h-6v6h-4l7 7 7-7zm-14 9v2h14v-2h-14z"/><path className="path2" d="M-4.328-3h24v24h-24z"/></g></IconBase>
  )
}

IconFileDownload.propTypes = {
  className: PropTypes.string,
}

export default IconFileDownload
/* eslint-enable */
