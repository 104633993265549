import { getRequest } from 'utils/api'

export const ENGAGEMENT_CODES_FETCHING = 'ENGAGEMENT_CODES_FETCHING'
export const ENGAGEMENT_CODES_FETCHED = 'ENGAGEMENT_CODES_FETCHED'
export const ENGAGEMENT_CODES_CLEAR = 'ENGAGEMENT_CODES_CLEAR'

export const fetching = () => ({
  type: ENGAGEMENT_CODES_FETCHING,
})

export const fetched = options => ({
  type: ENGAGEMENT_CODES_FETCHED,
  options,
})

export function getEngagementCodeByQuery(val) {
  let value = val
  if (val.includes(' - ')) {
    value = val.split(' - ').shift()
  }
  return (dispatch) => {
    dispatch(fetching())
    getRequest(`/dashboard/getengagements?search=${value}`)
      .then(({ data }) => {
        dispatch(fetched(data.map(e => ({
          value: `${e.code}`,
          label: `${e.code} - ${e.name}`,
          clientName: e.clientName,
        }))))
      })
  }
}

export const clearEngagementCodes = () => ({
  type: ENGAGEMENT_CODES_CLEAR,
})
