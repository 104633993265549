/* eslint-disable react/no-danger */
/* eslint-disable react/no-string-refs */
/* eslint-disable react/forbid-prop-types */

import React, { Component } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import constants from 'constants'
import Spinner from 'components/Spinner'
import Suggestions from './components/Suggestions'

import styles from './AutoCompleteInput.scss'

class AutoCompleteInput extends Component {
  static propTypes = {
    delimeters: PropTypes.arrayOf(PropTypes.number),
    disabled: PropTypes.bool,
    error: PropTypes.string,
    fetching: PropTypes.bool,
    floatedLabel: PropTypes.bool,
    focus: PropTypes.bool,
    handleChange: PropTypes.func,
    hasFetching: PropTypes.bool,
    label: PropTypes.string,
    minQueryLength: PropTypes.number,
    name: PropTypes.string,
    noMatchedLabel: PropTypes.string,
    note: PropTypes.string,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    placeholder: PropTypes.string,
    suggestions: PropTypes.array,
    valid: PropTypes.bool,
    value: PropTypes.string,
  }

  static defaultProps = {
    delimeters: [13, 9],
    disabled: false,
    error: '',
    fetching: false,
    floatedLabel: true,
    focus: false,
    handleChange: () => {},
    hasFetching: false,
    label: null,
    minQueryLength: 2,
    name: null,
    noMatchedLabel: null,
    note: null,
    onBlur: () => {},
    onFocus: () => {},
    placeholder: '',
    suggestions: [],
    valid: true,
    value: null,
  }

  constructor(props) {
    super(props)
    const { suggestions } = this.props
    this.timeout = null
    this.state = {
      suggestions,
      query: '',
      selectedIndex: -1,
      selectionMode: false,
      hasFocus: false,
    }
  }

  static getDerivedStateFromProps(props) {
    return {
      suggestions: props.suggestions,
    }
  }

  _filteredSuggestions = (query, suggestions) => {
    const { minQueryLength, noMatchedLabel } = this.props

    const result = suggestions

    if (
      noMatchedLabel
      && result.length === 0
      && query.length >= minQueryLength
    ) {
      result.push({
        label: noMatchedLabel,
        value: '',
      })
    }

    return result
  }

  _addInputByQuery = (query) => {
    const { suggestions } = this.props
    const { selectionMode, selectedIndex } = this.state

    let q = query
    if (selectionMode) {
      const filteredSuggestions = this._filteredSuggestions(query, suggestions)
      q = filteredSuggestions[selectedIndex]
    } else if (query !== '') {
      if (typeof q !== 'object') {
        q = { label: q, value: q }
      }
    }
    if (q !== '') {
      this._addInput(q)
    }
  }

  // Handlers
  _handleFocus = () => {
    const { onFocus, name } = this.props

    onFocus(name)
    this.setState(() => ({ hasFocus: true }))
  }

  _handleBlur = () => {
    const { onBlur, name } = this.props
    const { query } = this.state

    clearTimeout(this.timeout)
    if (
      this.refs.suggestions
      && document.activeElement === this.refs.suggestions.refs.menu
    ) {
      this._handleFocus()
    } else {
      this.timeout = setTimeout(() => {
        this._addInputByQuery(query)
        onBlur(name)
        this.setState(() => ({ hasFocus: false }))
      }, 200)
    }
  }

  _handleChange = (e) => {
    const { suggestions: propSuggestions, handleChange, name } = this.props

    const query = e.target.value
    const suggestions = this._filteredSuggestions(query, propSuggestions)
    this.setState(() => ({
      query,
      suggestions,
    }))
    handleChange(name, query)
  }

  _handleKeyDown = (e) => {
    const { delimeters, name, handleChange } = this.props
    const { query, suggestions, selectedIndex } = this.state

    // Hide suggestions menu on escape
    if (e.keyCode === constants.KEY_CODES.ESC) {
      e.preventDefault()
      this.setState(() => ({
        selectedIndex: -1,
        selectionMode: false,
        suggestions: [],
      }))
    }

    // When one of the terminating keys is pressed, add current query to the tags.
    // If no text is typed in so far, ignore the action - so we don't end up with a terminating
    // character typed in.
    // if (this.props.delimeters.indexOf(e.keyCode) !== -1) {
    //   if (e.keyCode !== constants.KEY_CODES.TAB) {
    //     e.preventDefault()
    //   }
    //   this._addInputByQuery(query)
    // }

    // Up Arrow
    if (e.keyCode === constants.KEY_CODES.UP_ARROW) {
      e.preventDefault()
      const si = selectedIndex

      // On Last item go to the top
      if (si <= 0) {
        this.setState((prevState, props) => ({
          selectedIndex: props.suggestions.length - 1,
          selectionMode: true,
        }))
      } else {
        this.setState(() => ({
          selectedIndex: si - 1,
          selectionMode: true,
        }))
      }
    }

    // Down Arrow
    if (e.keyCode === constants.KEY_CODES.DOWN_ARROW) {
      e.preventDefault()
      this.setState(prevState => ({
        selectedIndex: (prevState.selectedIndex + 1) % suggestions.length,
        selectionMode: true,
      }))
    }

    // Enter key
    if (e.keyCode === constants.KEY_CODES.ENTER) {
      e.preventDefault()
      const { label, value } = this._filteredSuggestions(query, suggestions)[
        selectedIndex
      ]
      this._handleClick({ label, value })
    }

    // Update Input
    if (
      e.keyCode !== constants.KEY_CODES.DOWN_ARROW
      && e.keyCode !== constants.KEY_CODES.UP_ARROW
      && e.keyCode !== constants.KEY_CODES.ENTER
      && delimeters.indexOf(e.keyCode) === -1
      && e.keyCode !== constants.KEY_CODES.TAB
      && e.keyCode === constants.KEY_CODES.ESC
    ) {
      handleChange(name, query)
    }
  }

  _handleClick = (tag) => {
    clearTimeout(this.timeout)
    // Reset the state
    this.setState(() => ({
      query: tag.value,
      selectedIndex: -1,
      selectionMode: false,
    }))
    this._addInput(tag, true)
    this.setState(() => ({ hasFocus: false }))
  }

  _addInput = (tag, viaClick = false) => {
    const { handleChange, name } = this.props
    const { selectionMode } = this.state
    const { input } = this.refs

    // Call method to add
    if (selectionMode || viaClick) {
      handleChange(name, tag, viaClick)
    }

    this.setState(() => ({
      query: tag.value,
      selectionMode: false,
      selectedIndex: -1,
    }))

    if (selectionMode || viaClick) {
      input.focus()
    }

    // Focus back on the input box
    clearTimeout(this.timeout)
    this.setState(() => ({ hasFocus: true }))
  }

  _input = () => {
    // get the suggestions for the given query
    const { query, hasFocus, selectedIndex } = this.state
    const {
      disabled,
      placeholder,
      value,
      fetching,
      minQueryLength,
      name,
      suggestions,
    } = this.props

    return (
      <div className={styles.input} ref="inputContainer">
        <input
          aria-label={placeholder}
          autoComplete="off"
          data-test-id={`autoCompleteInput-${name}`}
          disabled={disabled}
          name={name}
          onBlur={this._handleBlur}
          onChange={this._handleChange}
          onFocus={this._handleFocus}
          onKeyDown={this._handleKeyDown}
          placeholder={hasFocus ? placeholder : ''}
          ref="input"
          type="text"
          value={value}
        />
        {fetching && <Spinner className={styles.spinner} size="xSmall" />}
        <Suggestions
          handleClick={this._handleClick}
          minQueryLength={minQueryLength}
          query={query}
          ref="suggestions"
          selectedIndex={selectedIndex}
          show={hasFocus}
          suggestions={
            fetching || query.length < minQueryLength
              ? []
              : this._filteredSuggestions(query, suggestions)
          }
        />
      </div>
    )
  }

  _asHTML = (content) => {
    return {
      __html: content,
    }
  }

  render() {
    const {
      floatedLabel,
      value,
      focus,
      hasFetching,
      error,
      label,
      note,
      disabled,
      valid,
    } = this.props
    const { query } = this.state

    const hasError = error.length ? 'hasError' : ''
    const allowLabel = !floatedLabel ? styles.disablFloatedLabel : ''
    const displayNote = !valid ? styles.isHidden : ''
    const classes = {
      [styles.isFocused]: focus,
      [styles.hasValue]: value.length || query.length,
      [styles.disabled]: disabled,
      [styles.hasFetching]: hasFetching,
    }

    return (
      <div className={classnames(styles.base, allowLabel, classes)}>
        <div className={classnames(styles.tags, styles[hasError])}>
          <div className={styles.selected}>{this._input()}</div>
        </div>
        <span className={classnames(styles.bar, styles[hasError])} />
        {label && (
          <span
            className={classnames(styles.label, styles[hasError], allowLabel)}
          >
            {label}
          </span>
        )}
        {error && (
          <span className={classnames(styles.error)}>
            <span dangerouslySetInnerHTML={this._asHTML(error)} />
          </span>
        )}
        {note && (
          <span className={classnames(styles.note, displayNote)}>{note}</span>
        )}
      </div>
    )
  }
}

export default AutoCompleteInput
