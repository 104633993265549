import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { hot } from 'react-hot-loader/root'
import useApp from 'hooks/useApp'
import DetailedViewHeader from 'components/DetailedViewHeader'
import TagDetailsSection from 'components/TagDetailsSection'
import useTag from 'actionHub/hooks/useTag'
import CaseTestingConclusion from 'components/CaseTestingConclusion'
import { Divider } from '@material-ui/core'
import CommentsSection from 'components/CommentsSection'
import { validateLabel } from 'actionHub/utils/validations'
import DataList from 'actionHub/views/DataList/DataList'
import DataPageWrapper from 'actionHub/views/DataPageWrapper/DataPageWrapper'
import TaskList from 'actionHub/views/TaskList'
import { jobPermissions } from 'utils/permissions'
import { useActionPropTypes } from 'actionHub/hooks/useAction'
import { useJobPropTypes } from 'hooks/useJob'
import styles from './Tag.scss'

function Tag({ match: { params: { actionSetId, tagId } }, useAction, useJob }) {
  const [expanded, setExpanded] = useState(false)

  const {
    priorities, selectedTab, setTasksAssigned, setTasksPriority, statuses,
    onTabChange, columns, isFetching, isCurrent, goToTask,
  } = useAction

  const {
    deloitteTeam, userHasJobPermission, job: { hasExpired },
  } = useJob

  const {
    tag, postCurrentTagComment, updateTagData, setLabelValue, submitTagLabels,
    saveTagData, fetchNextPage, tagTasks,
  } = useTag(actionSetId, tagId)

  const canEditActions = userHasJobPermission(jobPermissions.actionHubEdit)

  const disabled = !isCurrent || !canEditActions || hasExpired

  const onNarrativeValueChange = (value) => {
    updateTagData(tagId, null, value, null)
  }

  const onDescriptionValueChange = (value) => {
    updateTagData(tagId, value, null, null)
  }

  const { user } = useApp()

  const tagUpdatedBymemberInfo = deloitteTeam.filter(mem => mem.userDetails.email === tag.updatedBy)

  const onPost = (value) => {
    postCurrentTagComment(value)
  }

  const updateTagName = (name) => {
    updateTagData(tagId, null, null, name)
  }

  const saveTagName = () => {
    saveTagData()
  }

  return (
    <div className={styles.box}>
      <div className={styles.tickMarkHeader}>
        <DetailedViewHeader
          actionSetId={actionSetId}
          disabled={disabled}
          headerType="tickmarkView"
          saveTagName={saveTagName}
          tagName={tag?.name}
          tagUpdatedBy={tagUpdatedBymemberInfo[0]?.userDetails.displayName}
          timestamp={tag?.updatedDate}
          updateTagName={updateTagName}
        />
      </div>
      <DataPageWrapper
        expanded={expanded}
        hasChildData={tag && tag.taskCount > 0}
      >
        {tag?._isDetailsFetched && (
          <div className={styles.leftContainer}>
            <TagDetailsSection
              disabled={disabled}
              onDescriptionValueChange={onDescriptionValueChange}
              onNarrativeValueChange={onNarrativeValueChange}
              onSave={saveTagData}
              tag={tag}
              updateTag={(description, narrative) => updateTagData(tagId, description, narrative, null)}
            />
            <Divider />
            <CommentsSection
              comments={tag?.comments || []}
              disabled={disabled}
              members={deloitteTeam}
              objectName="tag"
              onSubmit={onPost}
              user={user}
            />
          </div>
        )}

        <div className={styles.rightContainer}>
          <CaseTestingConclusion
            disabled={disabled}
            object={tag}
            setLabelValue={setLabelValue}
            submitLabels={submitTagLabels}
            validateLabel={validateLabel}
          />
        </div>
      </DataPageWrapper>
      {tag && tag.tagTasks && (
        <DataList
          data={tagTasks}
          dataCount={tag.taskCount}
          expanded={expanded}
          meta={{
            dataDisplayNamePlural: 'items',
            dataDisplayNameSingular: 'item',
          }}
          onLoadMore={fetchNextPage}
          setExpanded={setExpanded}
        >
          <TaskList
            actionSetId={actionSetId}
            canLoadMore={tag.tagTasks.pagination.hasNext && !isFetching}
            columns={columns}
            disabled={disabled}
            goToTask={goToTask}
            members={deloitteTeam}
            onLoadMore={fetchNextPage}
            onTabChange={onTabChange}
            priorities={priorities}
            selectedFilter={selectedTab}
            setTasksAssigned={setTasksAssigned}
            setTasksPriority={setTasksPriority}
            statuses={statuses}
            tasks={tagTasks}
          />
        </DataList>
      )}
    </div>
  )
}

Tag.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      actionSetId: PropTypes.string,
      tagId: PropTypes.string,
    }),
  }).isRequired,
  useAction: useActionPropTypes.isRequired,
  useJob: useJobPropTypes.isRequired,
}

export default hot(Tag)
