/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconUndo = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--undo',
    className,
  )

  return (
    <IconBase className={classes} viewBox="0 0 21 9" xmlns="http://www.w3.org/2000/svg"{...rest}>
      <title>Undo</title>
      <g fill="none" fillRule="evenodd">
        <path className="path1" d="M-2-7h24v24H-2z"/>
        <path className="fillpath" d="M10.5 1c-2.65 0-5.05.99-6.9 2.6L0 0v9h9L5.38 5.38C6.77 4.22 8.54 3.5 10.5 3.5c3.54 0 6.55 2.31 7.6 5.5l2.37-.78C19.08 4.03 15.15 1 10.5 1z" fill="#BDBDBD"/>
      </g>
    </IconBase>
  )
}

IconUndo.propTypes = {
  className: PropTypes.string,
}

export default IconUndo
/* eslint-enable */
