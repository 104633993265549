import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Heading, Button, DataTableSimple } from '@deloitte/gel-library'
import Card from 'components/Card'
import Avatar from 'components/Avatar'
import {
  jobGetMemberGroups, deloitteTeamSort, isPartnerRole, isManagerRole,
} from 'utils/business/jobs'
import { HashLink as Link } from 'react-router-hash-link'
import { sortByFunction } from 'utils/arrays'
import Container from 'components/layout/Grid/Container'
import styles from './ClientJob.scss'

const propTypes = {
  members: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
  })).isRequired,
}

const TeamMember = ({ user: { userDetails: details } }) => (
  <Fragment>
    <div>{`${details.firstName} ${details.surname}`}</div>
  </Fragment>
)

TeamMember.propTypes = {
  user: PropTypes.shape({
    userDetails: PropTypes.shape({
      firstName: PropTypes.string,
      surname: PropTypes.string,
    }),
  }).isRequired,
}

const columnHeaders = [
  {
    heading: 'Name',
    cellExtractor: user => (
      <div className={styles.avatarContainer}>
        <Avatar nameArray={[user.userDetails.firstName, user.userDetails.surname]} size="medium" />
        <div>
          <div>{`${user.userDetails.firstName} ${user.userDetails.surname}`}</div>
          {isPartnerRole(user.role.name) && <div className={styles.subheading}>Engagement Partner</div>}
          {isManagerRole(user.role.name) && <div className={styles.subheading}>Engagement Manager</div>}
        </div>
      </div>
    ),
    key: 'name',
  },
  { heading: 'Date joined', cellExtractor: x => moment(x.userDetails.joinDate).format('DD MMM YYYY'), key: 'joinDate' },
  { heading: 'Contact no.', cellExtractor: x => x.userDetails.phone, key: 'phone' },
  {
    heading: 'Email address', cellExtractor: user => user.userDetails.email, className: styles.email, key: 'email',
  },
]

function ClientTeam({ members }) {
  if (!members) {
    return null
  }

  const { deloitteTeam, externalTeam } = jobGetMemberGroups(members)

  return (
    <Container>
      <div className="row">
        <div className="col-xs-12 col-sm-9 col-lg-9">
          <Heading className={styles.firstHeading} level={8}>Members in your organisation</Heading>

          <Card noPadding>
            <DataTableSimple
              columns={columnHeaders.filter(x => x.key !== 'phone')}
              data={externalTeam}
              keyExtractor={x => x.userDetails.email}
              sortFunction={sortByFunction(x => x.userDetails.firstName)}
              spacing="large"
            />
          </Card>

          <Heading level={8}>Deloitte team members</Heading>

          <Card noPadding>
            <DataTableSimple
              columns={columnHeaders.filter(x => x.key !== 'joinDate')}
              data={deloitteTeam}
              keyExtractor={x => x.userDetails.email}
              sortFunction={deloitteTeamSort}
              spacing="large"
            />
          </Card>

        </div>

        <div className="col-xs-12 col-sm-3 col-lg-3">
          <Heading className={styles.firstHeading} level={8}>&nbsp;</Heading>
          <Card className={styles.contactCard}>
            <Heading level={7}>Want to add or remove a team member?</Heading>
            <p>
              Please use the link below to send a message to the InsightBox
              support team, or reach out to your Deloitte engagement team directly.
            </p>

            <Link to="/support#contact">
              <Button>
                Contact us
              </Button>
            </Link>
          </Card>
        </div>
      </div>
    </Container>
  )
}

ClientTeam.propTypes = propTypes

export default ClientTeam
