import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import styles from './DataPageWrapper.scss'

function DataPageWrapper({ expanded, children, hasChildData }) {
  return (
    <div className={
      classNames(
        styles.base,
        expanded ? styles.isExpanded : null,
        hasChildData ? null : styles.noChildData,
      )}
    >
      {children}
    </div>
  )
}

DataPageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  expanded: PropTypes.bool.isRequired,
  hasChildData: PropTypes.bool,
}

DataPageWrapper.defaultProps = {
  hasChildData: true,
}

export default DataPageWrapper

