import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, Icons } from '@deloitte/gel-library'
import { getIndex } from 'utils/arrays'
import Wordmark from 'components/Wordmark'
import HeaderSelect from 'components/HeaderSelect'
import { hot } from 'react-hot-loader/root'
import UserAvatarPopover from 'components/UserAvatarPopover'
import { userPropType } from 'utils/propTypes'
import { Link } from 'react-router-dom'
import InsertChartIcon from '@material-ui/icons/InsertChart'
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate'
import Tooltip from '@material-ui/core/Tooltip'
import classNames from 'classnames'
import useJob from 'hooks/useJob'
import ExpiryInfoBanner from 'components/ExpiryInfoBanner'
import { filterBannersByAudience } from 'utils/users'
import InfoBanner from 'components/InfoBanner'
import styles from './ReportHeader.scss'

const propTypes = {
  actionButton: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
  }),
  goBack: PropTypes.func.isRequired,
  isCustom: PropTypes.bool,
  jobId: PropTypes.string.isRequired,
  navigationOptionGroups: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.string),
  })),
  navigationOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    onSelect: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  })),
  navigationValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.oneOf(['report', 'action']),
  user: userPropType.isRequired,
}

const defaultProps = {
  isCustom: false,
  type: 'report',
  actionButton: null,
  navigationOptionGroups: null,
  navigationOptions: null,
  navigationValue: null,
}

function ReportHeader({
  type, user, navigationValue, navigationOptions, navigationOptionGroups,
  actionButton, jobId, goBack, isCustom,
}) {
  const navigationNumber = navigationOptions
    ? getIndex(navigationOptions, x => x.value === navigationValue) + 1
    : null

  const {
    jobName, showExpiryInfoBanner, reachedExecutionLimit, job,
  } = useJob(jobId)

  const { activeBanners } = useSelector(state => state.admin.bannerAccess)
  const banners = activeBanners ? filterBannersByAudience(Object.values(activeBanners), user) : []

  return (
    <>
      {banners.map(b => (
        <InfoBanner
          key={b.name}
          link={b.linkUrl}
          linkText={b.linkText}
          message={b.message}
          mode={b.mode.toLowerCase()}
        />
      ))}

      <div className={classNames(styles.base, (showExpiryInfoBanner) ? styles.expiredJobPadding : null)}>
        <div className={styles.container}>

          <Link className={styles.homeLink} to="/home">
            <Wordmark hideLogo wordmark="InsightBox" />
          </Link>
          <div className={styles.pipe} />
          {
            !user.isExternal ? (
              <Tooltip arrow interactive className={styles.tooltip} title="Back to job">
                <a className={styles.jobLink} onClick={() => goBack()}>
                  {jobName}
                </a>
              </Tooltip>
            )
              : <span className={styles.jobName}>{jobName}</span>
          }

          {type === 'report'
            ? <InsertChartIcon className={styles.contentIcon} />
            : <AssignmentLateIcon className={styles.contentIcon} />}

          {navigationOptions && (
            <HeaderSelect
                onDark
                endAdornment={`${navigationNumber} of ${navigationOptions.length}`}
                onChange={event => navigationOptions.find(x => x.value === event.target.value).onSelect()}
                optionGroups={navigationOptionGroups}
                options={navigationOptions}
                value={navigationOptions.length > 0 ? navigationValue : ''}
            />
          )}

          <div className={styles.spacer} />
          {actionButton && (
            <Button
              mode="dark"
              onClick={actionButton.onClick}
            >
              {actionButton.label}
            </Button>
          )}
          {isCustom && (
          <Link className={styles.homeLink} to={!user.isExternal ? '/custom' : '/insights/custom-reports'}>
            <Button
              className={styles.backToCusRep}
              mode="text"
            >
              Back to Reports
            </Button>
          </Link>
          )
          }
          <UserAvatarPopover user={user} />

          <Link className={styles.supportLink} to="/support">
            <Icons.IconHelp
              className={styles.helpIcon}
              height="24"
              width="24"
            />
            Support
          </Link>
        </div>
        {showExpiryInfoBanner && (
          <ExpiryInfoBanner
            hasExpired={job?.hasExpired}
            reachedExecutionLimit={reachedExecutionLimit}
          />
        )}
      </div>
    </>
  )
}

ReportHeader.propTypes = propTypes
ReportHeader.defaultProps = defaultProps

export default hot(ReportHeader)
