import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './Container.scss'

const Container = ({
  children,
  className,
  topPadding,
  large,
  noMargin,
  bottomMargin,
}) => (
  <div
    className={classnames(
      { [styles.container]: !noMargin },
      className,
      { [styles.topPadding]: topPadding },
      { [styles.large]: large },
      { [styles.bottomMargin]: bottomMargin },
    )}
  >
    {children}
  </div>
)

Container.propTypes = {
  bottomMargin: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  large: PropTypes.bool,
  noMargin: PropTypes.bool,
  topPadding: PropTypes.bool,
}

Container.defaultProps = {
  children: null,
  className: null,
  large: false,
  topPadding: false,
  noMargin: false,
  bottomMargin: false,
}

export default Container
