import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Button'
import Heading from 'components/Heading'
import { IconEmptyState } from 'icons'
import Modal from 'components/Modal'
import SlickSlider from 'components/SlickSlider'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { setCookie } from 'utils/cookies'
import { hasDismissedOnboardingCookieName } from 'constants/cookies'
import { Link } from 'react-router-dom'
import styles from './NoReport.scss'

class NoReport extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    showOnboarding: PropTypes.bool,
    showSandboxModal: PropTypes.bool,
  }

  static defaultProps = {
    showSandboxModal: false,
    showOnboarding: true,
  }

  constructor(props) {
    super(props)

    this.state = {
      showModal: props.showOnboarding && !props.showSandboxModal,
    }
  }

  componentDidUpdate(prevProps) {
    const { showSandboxModal } = this.props

    if (prevProps.showSandboxModal && !showSandboxModal) {
      this.handleToggle()
    }
  }

  handleSetDismissCookie = () => {
    setCookie(hasDismissedOnboardingCookieName, true, 365)
  }

  handleToggle = () => {
    const { showModal } = this.state
    if (showModal) {
      this.handleSetDismissCookie()
    }
    this.setState({ showModal: !showModal })
  }

  handleCreateJob = () => {
    const { dispatch } = this.props
    dispatch(push('/createjob'))
  }

  handleGetStarted = () => {
    this.setState({ showModal: false })
    this.handleSetDismissCookie()
  }

  render() {
    const { showModal } = this.state
    const actions = [{ onClick: this.handleToggle, primary: true }]
    return (
      <div className={styles.base}>
        <div className={styles.Modal}>
          <Modal
            coverOnboarding
            actions={actions}
            active={showModal}
            onClick={this.handleToggle}
            onClose={this.handleToggle}
          >
            <SlickSlider onAction={this.handleGetStarted} />
          </Modal>
        </div>
        <IconEmptyState width="300" />
        <Heading noPadding size="small">
          You have not created any jobs.
        </Heading>
        <p>
          Need some help? Visit our
          {' '}
          <Link
            to="/support"
          >
            support
          </Link>
          {' '}
          page, or get started straight away.
        </p>
        <p className={styles.cta}>
          <Button
            label="create new job"
            onClick={this.handleCreateJob}
            type="primary"
          />
        </p>
      </div>
    )
  }
}

export default connect()(NoReport)
