export function merge(a, b, prop) {
  const reduced = a.filter(
    aitem => !b.find(bitem => aitem[prop] === bitem[prop]),
  )
  return reduced.concat(b)
}

export function sortByFunction(func, descending = false) {
  return (a, b) => {
    if (func(a) < func(b)) {
      return descending ? 1 : -1
    }
    if (func(a) > func(b)) {
      return descending ? -1 : 1
    }
    return 0
  }
}

const distinct = (value, index, self) => {
  return self.indexOf(value) === index
}

export function getDistinct(array) {
  return array.filter(distinct)
}

export function getIndex(array, matchFunction) {
  let index = -1
  array.some((x, i) => {
    if (matchFunction(x)) {
      index = i
      return true
    }
    return false
  })
  return index
}

export function filterByObject(item, filterCollection) {
  return Object.keys(filterCollection)
    .every(key => item[key] === filterCollection[key])
}

export function groupBy(array, key) {
  return array.reduce((rv, x) => {
    const v = key instanceof Function ? key(x) : x[key]
    const el = rv.find(r => r && r.key === v)
    if (el) {
      el.values.push(x)
    } else {
      rv.push({ key: v, values: [x] })
    }
    return rv
  }, [])
}
