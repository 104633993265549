import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  adminFetchBanners,
  adminSelectBanner,
  adminResetDefaultBanner,
  adminFetchUserGroups,
  adminToggleBanner,
  adminDeleteBanner,
} from 'actions/admin'
import { modalCreate, modalShow } from 'actions/modals'
import {
  DataTableSimple, Heading, Searchbar, Button,
} from '@deloitte/gel-library'
import IconAdd from '@material-ui/icons/Add'
import constants from 'constants'
import { hot } from 'react-hot-loader/root'
import { formatDateFromStringShort } from 'utils/dates'
import { sortByFunction } from 'utils/arrays'
import { IconMenu, MenuItem } from 'react-toolbox/lib/menu'
import Card from 'components/Card'
import TagList from 'components/TagList'
import BlockSpinner from 'components/BlockSpinner'
import DialogSimple from 'components/DialogSimple'
import BannerManagementModal from './BannerManagementModal'

import styles from './BannerManagement.scss'

const MAX_ACTIVE_BANNERS = 3
const DEFAULT_DIALOG_STATE = {
  text: '',
  subText: '',
  show: false,
  disableConfirm: false,
  onConfirm: () => {},
}

const BannerManagement = () => {
  const dispatch = useDispatch()
  const { _isFetchedUserGroups } = useSelector(state => state.admin.userAdmin)

  const {
    banners,
    activeBanners,
    _isFetching: _isFetchingBanners,
    _isFetched: _isFetchedBanners,
    _isUpdating: _isUpdatingBanner,
  } = useSelector(state => state.admin.bannerAccess)

  useEffect(() => {
    if (!_isFetchedUserGroups) {
      dispatch(adminFetchUserGroups())
    }
  }, [_isFetchedUserGroups, dispatch])

  useEffect(() => {
    if (!_isFetchedBanners) {
      dispatch(adminFetchBanners())
    }
  }, [_isFetchedBanners, dispatch])

  const [bannerSearch, setBannerSearch] = useState('')
  const [dialogState, setDialogState] = useState(DEFAULT_DIALOG_STATE)

  const modals = useSelector(state => state.modals)
  const userEmail = useSelector(state => state.app.user.email)

  const MODAL_ID = constants.MODAL_BANNER_SETTINGS

  const loaded = !_isFetchingBanners && _isFetchedBanners

  const openExternalLink = (link) => {
    const newWindow = window.open(link)
    newWindow.opener = null
  }

  const openModal = (banner) => {
    if (banner !== null) {
      dispatch(adminSelectBanner(banner))
    } else {
      dispatch(adminResetDefaultBanner())
    }
    if (modals && !modals.modalNames.includes(MODAL_ID)) {
      dispatch(modalCreate(MODAL_ID))
    }
    dispatch(modalShow(MODAL_ID))
  }

  const closeDialog = () => {
    setDialogState(DEFAULT_DIALOG_STATE)
  }

  const openActivationDialog = (banner) => {
    setDialogState({
      text: `${!banner.isActive ? 'Activate' : 'Deactivate'} banner '${banner.name}'?`,
      subText: `There are currently ${Object.keys(activeBanners)?.length ?? 0} of ${MAX_ACTIVE_BANNERS} active banners.`,
      show: true,
      disableConfirm: !banner.isActive && Object.keys(activeBanners).length >= MAX_ACTIVE_BANNERS,
      onConfirm: () => {
        dispatch(adminToggleBanner(banner))
          .then(() => closeDialog())
      },
    })
  }

  const openDeleteDialog = (banner) => {
    setDialogState({
      text: `Delete banner '${banner.name}'?`,
      subText: '',
      show: true,
      disableConfirm: false,
      onConfirm: () => {
        dispatch(adminDeleteBanner(banner))
          .then(() => closeDialog())
      },
    })
  }

  const menuItems = [
    {
      caption: 'Edit',
      key: 'edit',
      onClick: (banner) => {
        openModal(banner)
      },
    },
    {
      caption: 'Delete',
      key: 'delete',
      onClick: (banner) => {
        openDeleteDialog(banner)
      },
    },
  ]

  const columns = [
    {
      heading: 'Name',
      cellExtractor: banner => (
        <div>{banner.name}</div>
      ),
      key: 'name',
      className: styles.medColumn,
    },
    {
      heading: 'Mode',
      cellExtractor: banner => (
        <div>{banner.mode}</div>
      ),
      key: 'mode',
      className: styles.smallColumn,
    },
    {
      heading: 'Message',
      cellExtractor: banner => (
        <>
          <span>{banner.message}</span>
          {banner.linkUrl && (
            <>
              {banner.linkUrl.startsWith('http') ? (
                <span className={styles.link} onClick={() => openExternalLink(banner.linkUrl)}>
                  {banner.linkText}
                </span>
              ) : (
                <Link className={styles.link} to={banner.linkUrl}>
                  {banner.linkText}
                </Link>
              )}
            </>
          )}
        </>
      ),
      key: 'message',
      className: styles.xLargeColumn,
    },
    {
      heading: 'Last updated',
      cellExtractor: banner => (
        <>
          <div>{formatDateFromStringShort(banner.updatedDate)}</div>
          <div className={styles.tableSecondary}>
            {banner.updatedBy === '[USER]' ? userEmail : banner.updatedBy }
          </div>
        </>
      ),
      key: 'updated',
      className: styles.medColumn,
    },
    {
      heading: 'Audience',
      cellExtractor: banner => (
        <TagList tags={banner.audience?.map(a => a.description)} />
      ),
      key: 'audience',
      className: styles.largeColumn,
    },
    {
      heading: 'Active',
      cellExtractor: banner => (
        <div>{banner.isActive ? 'Yes' : 'No'}</div>
      ),
      key: 'active',
      className: styles.xSmallColumn,
    },
    {
      heading: '',
      cellExtractor: banner => (
        <IconMenu
          menuRipple
          className={styles.menuRipple}
          icon="more_vert"
          position="topLeft"
        >
          <MenuItem
            caption={!banner.isActive ? 'Activate' : 'Deactivate'}
            key="toggleActivate"
            onClick={() => openActivationDialog(banner)}
          />
          {menuItems.map(item => (
            <MenuItem
              caption={item.caption}
              key={item.key}
              onClick={() => item.onClick(banner)}
            />
          ))}
        </IconMenu>
      ),
      key: 'actions',
      className: styles.xSmallColumn,
    },
  ]

  const filteredBanners = Object.values(banners).filter(banner => (
    banner.name.toLowerCase().includes(bannerSearch)
    || banner.message.toLowerCase().includes(bannerSearch)
  ))

  return (
    <div className={styles.base}>
      <Heading level={8}>Banner management</Heading>
      {loaded ? (
        <>
          <div className={styles.filterBar}>
            <Searchbar
              mini
              className={styles.search}
              onSearch={value => setBannerSearch(value.toLowerCase())}
              placeholder="Search name or message"
              value={bannerSearch}
            />
            <Button
              className={styles.addBanner}
              icon={<IconAdd />}
              onClick={() => openModal(null)}
            >
              Add new banner
            </Button>
          </div>

          {filteredBanners.length === 0 ? (
            <Card className={styles.noResults}>
              <div>
                <p>No banners found</p>
                <p>Try adjusting your search or filter to find what you’re looking for.</p>
              </div>
            </Card>
          ) : (
            <Card noPadding style={{ marginTop: '3rem' }}>
              <DataTableSimple
                className={styles.table}
                columns={columns}
                data={filteredBanners}
                keyExtractor={b => b.id}
                sortFunction={sortByFunction(x => x.updatedDate, true)}
              />
            </Card>
          )}
        </>
      ) : (
        <BlockSpinner message="Loading banners" />
      )}

      {modals.modalNames.includes(MODAL_ID) && <BannerManagementModal />}

      {dialogState.show && (
        <DialogSimple
          isOpen
          actionLabel="Confirm"
          disabled={dialogState.disableConfirm}
          dismissLabel="Cancel"
          isLoading={_isUpdatingBanner}
          onAction={dialogState.onConfirm}
          onDismiss={closeDialog}
        >
          <Heading level={8}>{dialogState.text}</Heading>
          <p>{dialogState.subText}</p>
        </DialogSimple>
      )}

    </div>
  )
}

export default hot(BannerManagement)
