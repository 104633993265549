/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconStar = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--star',
    className,
  )

  return (
    <IconBase className={classes} viewBox="0 0 19 18" xmlns="http://www.w3.org/2000/svg"{...rest}><title>Star</title><path className="path1" d="M9.38 13.977c.09 0 .18.022.263.065l4.427 2.34-.845-4.957a.573.573 0 0 1 .163-.505l3.582-3.512-4.95-.723a.568.568 0 0 1-.428-.312L9.38 1.86 7.164 6.374a.568.568 0 0 1-.427.312l-4.95.723L5.37 10.92c.133.13.195.32.163.505l-.846 4.958 4.428-2.34a.57.57 0 0 1 .264-.066m5.444 4.02a.566.566 0 0 1-.264-.065l-5.18-2.74-5.182 2.74a.565.565 0 0 1-.6-.043.573.573 0 0 1-.225-.56l.99-5.802-4.19-4.11a.573.573 0 0 1 .313-.974l5.793-.846L8.87.318a.567.567 0 0 1 1.018 0l2.59 5.28 5.794.846a.573.573 0 0 1 .315.974l-4.193 4.11.99 5.803a.573.573 0 0 1-.56.668" fill="#FFF" fillRule="evenodd"/></IconBase>
  )
}

IconStar.propTypes = {
  className: PropTypes.string,
}

export default IconStar
/* eslint-enable */
