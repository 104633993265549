import React from 'react'
import PropTypes from 'prop-types'
import ClientAccess from 'views/Job/components/ClientAccess'
import { jobGetMemberGroups } from 'utils/business/jobs'
import { push } from 'connected-react-router'
import { Heading } from '@deloitte/gel-library'
import CardList from 'components/CardList'
import CustomReport from 'views/CustomReport/CustomReport'
import styles from './HostedReports.scss'

const propTypes = {
  canAddExternalUser: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  job: PropTypes.shape({
    createdBy: PropTypes.string,
    createdDate: PropTypes.string,
    description: PropTypes.string,
    engagement: PropTypes.object,
    members: PropTypes.arrayOf(PropTypes.object),
    name: PropTypes.string,
    reports: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  openExternalUserModal: PropTypes.func.isRequired,
}

function HostedReports({
  canAddExternalUser,
  dispatch,
  job: {
    members, reports,
  },
  openExternalUserModal,
}) {
  const { externalTeam } = jobGetMemberGroups(members)

  return (
    <div className={styles.base}>
      <div className="row">
        <div className="col-xs-12 col-sm-7 col-lg-9">
          <Heading level={8}>
            {`${reports.length} Report${reports.length > 1 ? 's' : ''}`}
          </Heading>
          <CardList
            isCustomReport
            noItemPadding
            className={styles.reportCard}
            contentExtractor={(r) => {
              return (
                <CustomReport report={r} />
              )
            }}
            data={reports}
            itemClassName={styles.reportCardItem}
            keyExtractor={a => a.id}
            onClick={a => dispatch(push(`report/${a.id}`))}
          />
        </div>
        <div className="col-xs-12 col-sm-5 col-lg-3">
          <ClientAccess
            externalTeam={externalTeam}
            goToTeam={() => dispatch(push('team'))}
            isAuthorizedToAdd={canAddExternalUser}
            openExternalUserModal={openExternalUserModal}
          />
        </div>
      </div>
    </div>
  )
}

HostedReports.propTypes = propTypes

export default HostedReports
