const GenAiChecklist = [
  {
    title: 'Review data use rights',
    body: 'Check Generative AI Data Guardrails to assess the rights to use information whether it is client or Deloitte confidential data.',
  },
  {
    title: 'Validate outputs',
    body: 'Be mindful of GenAI hallucinations that produces incorrect answers. Fact checking information against credible sources.',
  },
  {
    title: 'Be transparent and assess risk',
    body: 'Be transparent about the use of GenAI, beware of copyright and intellectual property risks. Consult with your Q&R respentatives.',
  },
]

export default GenAiChecklist
