import React, { Fragment, createRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Heading } from '@deloitte/gel-library'
import JobAnchorBanner from 'components/JobAnchorBanner'
import useDownloads from 'hooks/useDownloads'
import Download from 'views/Job/components/Download'
import ClientReportList from 'components/ClientReportList'
import Container from 'components/layout/Grid/Container'
import CurrentActions from 'views/Job/CurrentActions'
import ReActivateInfoCard from 'components/ReActivateInfoCard'
import { isTacTClient } from 'utils/permissions'
import { jobIsProcessing } from 'utils/business/workflow'
import styles from './ClientReports.scss'
import ClientJobDetailsSideBar from './ClientJobDetailsSideBar'

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  job: PropTypes.shape({
    actions: PropTypes.shape({
      _hasFetchedAllActions: PropTypes.bool,
      currentActions: PropTypes.arrayOf(PropTypes.object),
      potentialActions: PropTypes.arrayOf(PropTypes.object),
      previousActions: PropTypes.arrayOf(PropTypes.object),
    }),
    analyses: PropTypes.arrayOf(PropTypes.object),
    analysisPeriod: PropTypes.string,
    hasExpired: PropTypes.bool,
    jobId: PropTypes.string,
    lastExecutionDate: PropTypes.object,
    name: PropTypes.string,
    packageId: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    packageName: PropTypes.string,
    tables: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  onEditData: PropTypes.func.isRequired,
  uploaders: PropTypes.shape({
    uploaders: PropTypes.object,
  }).isRequired,
  user: PropTypes.shape({
    isExternal: PropTypes.bool,
  }).isRequired,
  validations: PropTypes.shape({
    validations: PropTypes.object,
  }).isRequired,
}

function ClientReports({
  job, dispatch, user, onEditData, uploaders, validations,
}) {
  const { downloads } = useDownloads(job ? job.jobId : null)
  const showCurrentActions = job.actions && job.actions.currentActions && job.actions.currentActions.length > 0

  const reportRef = createRef()
  const actionRef = createRef()
  // We never want to show just one card, and we never show data issues for client-shared
  const types = [
    showCurrentActions ? 'REPORTS' : null,
    showCurrentActions ? 'ACTIONS' : null,
  ]

  const hasDownloads = downloads && downloads.length > 0

  const isProcessing = jobIsProcessing(job)
  const isTactClient = isTacTClient(user.isExternal, job.packageId)

  return (
    <div>
      {!job.hasExpired && (
        <JobAnchorBanner
          actionRef={actionRef}
          dispatch={dispatch}
          hasDownloads={hasDownloads}
          job={job}
          reportRef={reportRef}
          types={types}
          user={user}
        />
      )}
      <Container>
        <div className="row">
          <div className={classnames('col-xs-12 col-sm-12 col-lg-12', styles.Download)}>
            {!isTactClient
            && (downloads && downloads.length > 0 && (
              <Fragment>
                {downloads.map(d => <Download download={d} jobId={job.jobId} key={d.name} />)}
              </Fragment>
            ))}
          </div>
          <div className="col-xs-12 col-sm-8 col-lg-9">
            {(job?.hasExpired && user.isExternal) && (
              <ReActivateInfoCard />
            )}
            <div className={styles.reportSection} ref={reportRef}>
              <Heading className={styles.firstHeading} level={8}>Reports</Heading>
              <ClientReportList job={job} />
            </div>

            {!isTactClient && !isProcessing && !(job.hasExpired && user.isExternal) && (
              (job.hasExpired && user.isExternal) ? (
                <></>
              ) : (
                showCurrentActions && (
                  <div className="row" ref={actionRef}>
                    <div className="col-xs-12">
                      <CurrentActions
                        job={job}
                        onEditData={onEditData}
                        uploaders={uploaders}
                        user={user}
                        validations={validations}
                      />
                    </div>
                  </div>
                )
              )
            )}
          </div>

          <div className="col-xs-12 col-sm-4 col-lg-3">
            <ClientJobDetailsSideBar job={job} />
          </div>
        </div>
      </Container>
    </div>
  )
}

ClientReports.propTypes = propTypes

export default ClientReports
