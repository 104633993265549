import Spinner from 'components/Spinner'
import React from 'react'
import PropTypes from 'prop-types'
import styles from './BlockSpinner.scss'

const propTypes = {
  marginTop: PropTypes.number,
  text: PropTypes.string,
}

const defaultProps = {
  text: null,
  marginTop: 100,
}

const BlockSpinner = ({ text, marginTop }) => {
  return (
    <div className={styles.base}>
      <div className={styles.spinner} style={{ marginTop: `${marginTop}px` }}>
        <Spinner />
        {text && <p>{text}</p>}
      </div>
    </div>
  )
}

BlockSpinner.propTypes = propTypes
BlockSpinner.defaultProps = defaultProps

export default BlockSpinner
