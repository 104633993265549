import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import TagDescription from 'components/TagDescription'
import EditIcon from '@material-ui/icons/Edit'
import TagNarrative from 'components/TagNarrative'
import {
  Button,
} from '@material-ui/core'
import styles from './TagDetailsSection.scss'

const propTypes = {
  disabled: PropTypes.bool.isRequired,
  onDescriptionValueChange: PropTypes.func.isRequired,
  onNarrativeValueChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  tag: PropTypes.shape({
    description: PropTypes.string,
    narrative: PropTypes.string,
  }).isRequired,
  updateTag: PropTypes.func.isRequired,
}

function TagDetailsSection({
  updateTag, onSave, tag, disabled, onDescriptionValueChange, onNarrativeValueChange,
}) {
  const narrativeValue = tag?.narrative ?? ''
  const descriptionValue = tag?.description ?? ''

  const [originalNarrativeValue] = useState(narrativeValue)
  const [originalDecriptionValue] = useState(descriptionValue)

  const [editing, setIsEditing] = useState(false)

  const handleEdit = () => {
    setIsEditing(true)
  }

  const handleSave = () => {
    onSave()
    setIsEditing(false)
  }

  const handleCancel = () => {
    setIsEditing(false)
    updateTag(originalDecriptionValue, originalNarrativeValue)
  }

  const disabledForm = !editing || disabled

  return (
    <div className={classNames(styles.base, styles.flexContainer, styles.flexColDirection)}>
      <div className={classNames(styles.flexContainer, styles.flexRowDirection, styles.flexSpace)}>
        <TagDescription
          descriptionValue={descriptionValue}
          disabled={disabledForm}
          onDescriptionValueChange={onDescriptionValueChange}
        />
        {!disabled && (
          <EditIcon className={classNames(styles.editIcon, !disabledForm && styles.enabledIcon)} onClick={handleEdit} />
        )}
      </div>
      <TagNarrative
        disabled={disabledForm}
        onNarrativeValueChange={onNarrativeValueChange}
        value={narrativeValue}
      />
      <div className={classNames(styles.flexContainer, styles.detailsSaveInfo, styles.flexSpace, styles.flexRowDirection)}>
        <div className={styles.characterLimit}>
          {disabledForm ? '500 Character limit' : `${narrativeValue.length}/500`}
        </div>
        <div className={styles.flexContainer}>
          <Button
            className={styles.cancel}
            color="primary"
            disabled={disabledForm}
            onClick={handleCancel}
          >
            CANCEL
          </Button>
          <Button
            color="primary"
            disabled={disabledForm}
            onClick={handleSave}
            variant="outlined"
          >
            SAVE
          </Button>
        </div>
      </div>
    </div>
  )
}

TagDetailsSection.propTypes = propTypes

export default TagDetailsSection
