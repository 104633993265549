export const UPDATE_SELECTED_TEMPLATE = 'UPDATE_SELECTED_TEMPLATE'
export const RESET_SELECTED_TEMPLATE = 'RESET_SELECTED_TEMPLATE'
export const INITIALISE_APP_CREATION = 'INITIALISE_APP_CREATION'

export const UPDATE_APP_DETAILS_FORM = 'UPDATE_APP_DETAILS_FORM'
export const UPDATE_APP_PROMPTS_FORM = 'UPDATE_APP_PROMPTS_FORM'
export const UPDATE_APP_KNOWLEDGE_BASE_FORM = 'UPDATE_APP_KNOWLEDGE_BASE_FORM'
export const UPDATE_APP_ADVANCED_SETTINGS_FORM = 'UPDATE_APP_ADVANCED_SETTINGS_FORM'
export const UPDATE_APP_FULL_FORM = 'UPDATE_APP_FULL_FORM'

export const UPSERT_APP_DETAILS = 'UPSERT_APP_DETAILS'

export const FETCHING_CURRENT_APP = 'FETCHING_CURRENT_APP'
export const FETCHED_CURRENT_APP = 'FETCHED_CURRENT_APP'
export const UPDATE_APP_MODELS = 'UPDATE_APP_MODELS'

export const FETCHING_APPS = 'FETCHING_APPS'
export const FETCHED_APPS = 'FETCHED_APPS'
// File Upload
export const SUBMITTING_FILE = 'SUBMITTING_FILE'
export const FETCHING_FILES = 'FETCHING_FILES'
export const UPDATE_FILES = 'UPDATE_FILES'
export const UPLOAD_FILE = 'UPLOAD_FILE'
export const FETCHED_FILES = 'FETCHED_FILES'
export const SUBMITTING_WEBSITE = 'SUBMITTING_WEBSITE'
export const FETCHING_WEBSITES = 'FETCHING_WEBSITES'
export const UPDATE_WEBSITES = 'UPDATE_WEBSITES'
export const UPDATE_PERCENT = 'UPDATE_PERCENT'
export const SHOW_DUPLICATE_DIALOG = 'SHOW_DUPLICATE_DIALOG'
export const COMPLETE_FILE_UPLOAD = 'COMPLETE_FILE_UPLOAD'
export const COMPLETE_WEBSITE_UPLOAD = 'COMPLETE_WEBSITE_UPLOAD'
