import React from 'react'
import Card from 'components/Card'
import { Button } from '@deloitte/gel-library'
import { HashLink as Link } from 'react-router-hash-link'
import { CONTACT_OPTIONS } from 'views/ContactForm/ContactForm'
import expiredJobClient from '../../images/expiredJobClient.png'
import styles from './ReActivateInfoCard.scss'

function ReActivateInfoCard() {
  return (
    <Card className={styles.expiredClientJob}>
      <img alt="Expired Client job" src={expiredJobClient} />
      <div className={styles.expiredClientJobText}>
        Want to reopen an expired job?
        <br />
        Contact a Deloitte team member.
      </div>
      <Link className={styles.link} to={`/support?option=${CONTACT_OPTIONS.ExtensionOfAccess}#contact`}>
        <Button className={styles.expiredJobClientButton}>
          CONTACT US
        </Button>
      </Link>
    </Card>

  )
}

export default ReActivateInfoCard
