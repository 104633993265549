import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  adminFetchDataSourcesAccess, adminFetchDataSourceRoles, adminEditDataSourceAccess,
} from 'actions/admin'
import { modalCreate, modalShow } from 'actions/modals'
import {
  DataTableSimple, Heading, Searchbar, Button,
} from '@deloitte/gel-library'
import constants from 'constants'
import { hot } from 'react-hot-loader/root'
import { formatDateFromStringShort } from 'utils/dates'
import Card from 'components/Card'
import TagList from 'components/TagList'
import SearchFilter from 'components/SearchFilter'
import BlockSpinner from 'components/BlockSpinner'
import { deleteDataSourceCache } from 'utils/api/admin'
import styles from './DataSourceSettings.scss'
import DataSourceSettingsModal from './DataSourceSettingsModal'

const getDistinctDataSourceRoleDescriptions = dataSource => (Array.from(new Set(dataSource.roles
  .map(r => r.description)))
)

const ALL_ROLES_FILTER = 'All roles'

const DataSourceSettings = () => {
  const dispatch = useDispatch()

  const {
    dataSources, dataSourceNames, _isFetching: _isFetchingDataSources, _isFetched: _isFetchedDataSources,
  } = useSelector(state => state.admin.allDataSourceAccess)

  useEffect(() => {
    if (!_isFetchedDataSources) {
      dispatch(adminFetchDataSourcesAccess())
      dispatch(adminFetchDataSourceRoles())
      dispatch(modalCreate(constants.MODAL_DATA_SOURCE_SETTINGS))
    }
  }, [_isFetchedDataSources, dispatch])

  const selectedDataSource = useSelector(state => state.admin.dataSourceAccess)

  const {
    roles, roleIds, _isFetched: _isFetchedRoles, _isFetching: _isFetchingRoles,
  } = useSelector(state => state.admin.assignableRoles)

  const userEmail = useSelector(state => state.app.user.email)

  const loaded = _isFetchedDataSources && !_isFetchingDataSources && _isFetchedRoles && !_isFetchingRoles

  const columns = [
    {
      heading: 'Data Source',
      cellExtractor: dataSource => (
        <div>
          <div>{dataSource.meta.displayName}</div>
        </div>
      ),
      key: 'dataSource',
      className: styles.solutionColumn,
    },
    {
      heading: 'Last updated',
      cellExtractor: (dataSource) => {
        const lastUpdatedDataSourceRole = dataSource.roles
          .reduce((prev, current) => (prev.updatedAt > current.updatedAt ? prev : current), { updatedAt: null })

        const lastUpdated = lastUpdatedDataSourceRole

        return (
          <div>
            <div>{formatDateFromStringShort(lastUpdated.updatedAt)}</div>
            <div className={styles.tableSecondary}>{lastUpdated.updatedBy === '[USER]' ? userEmail : lastUpdated.updatedBy }</div>
          </div>
        )
      },
      key: 'updated',
    },
    {
      heading: 'Accessible by',
      cellExtractor: (dataSource) => {
        const allRoles = loaded
          ? getDistinctDataSourceRoleDescriptions(dataSource)
          : []
        return (
          allRoles.length === 0
            ? <p>No access</p>
            : <TagList tags={[...new Set(allRoles)]} />
        )
      },
      key: 'access',
    },
    {
      heading: '',
      cellExtractor: dataSource => (
        <div>
          <Button
              mode="text"
              onClick={() => {
                dispatch(adminEditDataSourceAccess(dataSource.name))
                dispatch(modalShow(constants.MODAL_DATA_SOURCE_SETTINGS))
              }
          }
          >
            Edit
          </Button>
        </div>
      ),
      key: 'edit',
      className: styles.editColumn,
    },
  ]

  const roleOptions = {
    ...roles,
    [-1]: {
      id: -1,
      description: ALL_ROLES_FILTER,
    },
  }

  const [dataSourceSearch, setDataSourceSearch] = useState('')
  const [roleFilter, setRoleFilter] = useState(ALL_ROLES_FILTER)

  const filteredDataSources = dataSourceNames
    .map(n => dataSources[n])
    .filter(s => s.meta.displayName.toLowerCase().includes(dataSourceSearch)
      && ((s.roles && s.roles.length > 0 && getDistinctDataSourceRoleDescriptions(s).indexOf(roleFilter) >= 0) || roleFilter === ALL_ROLES_FILTER))

  return (
    <div className={styles.base}>
      <Heading level={8}>Data source access management</Heading>

      <Button onClick={() => deleteDataSourceCache()}>Clear data source cache</Button>

      {loaded
        ? (
          <div>
            <div className={styles.filterBar}>
              <Searchbar
                mini
                className={styles.search}
                onSearch={value => setDataSourceSearch(value.toLowerCase())}
                placeholder="Search data sources"
                value={dataSourceSearch}
              />
              <div className={styles.filter}>
                <div>Filter by:</div>
                <SearchFilter
                  handleSubmit={o => setRoleFilter(o)}
                  id="role-search-filter"
                  options={[-1].concat(roleIds).map(id => ({ label: roleOptions[id].description, value: `${id}` }))}
                  text={roleFilter}
                />
              </div>
            </div>
            <Card noPadding>
              <DataTableSimple
                className={styles.table}
                columns={columns}
                data={filteredDataSources}
                keyExtractor={s => s.name}
                sortFunction={s => s.name}
              />
            </Card>
          </div>
        ) : (
          <BlockSpinner message="Loading data sources" />
        )}

      {loaded && selectedDataSource.name && <DataSourceSettingsModal />}

    </div>
  )
}

export default hot(DataSourceSettings)
