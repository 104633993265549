/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconFileUploaded = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--fileuploaded',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46 46"{...rest}><g fill="none" fillRule="evenodd"><path className="path1" d="M-4-4h54v54H-4z"/><path className="path2" fill="#86BD24" fillRule="nonzero" d="M23 .5C10.58.5.5 10.58.5 23S10.58 45.5 23 45.5 45.5 35.42 45.5 23 35.42.5 23 .5zm-4.5 33.75L7.25 23l3.172-3.172 8.078 8.055 17.078-17.078L38.75 14 18.5 34.25z"/></g></IconBase>
  )
}

IconFileUploaded.propTypes = {
  className: PropTypes.string,
}

export default IconFileUploaded
/* eslint-enable */
