import React from 'react'
import PropTypes from 'prop-types'
import constants from 'constants'
import { useDispatch, useSelector } from 'react-redux'
import Animate from 'components/Animate'
import OverlayModal from 'components/OverlayModal'
import { modalHide } from 'actions/modals'
import {
  adminEditSolutionAccess, adminToggleAnalysisPermissions, adminToggleSolutionPermissions, adminUpdateSolutionPermissions,
} from 'actions/admin'
import { Heading, ToggleInput } from '@deloitte/gel-library'
import SubmitButton from 'components/SubmitButton'
import ExpanderCard from 'views/Home/components/Expander/Expander'
import TagList from 'components/TagList'
import styles from './SolutionSettingsModal.scss'

const MODAL_ID = constants.MODAL_SOLUTION_SETTINGS

const labelledTextPropTypes = {
  body: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

const LabelledText = ({ label, body }) => {
  return (
    <div className={styles.labelledText}>
      <p className={styles.label}>{label}</p>
      <p className={styles.body}>{body}</p>
    </div>
  )
}

LabelledText.propTypes = labelledTextPropTypes

const SolutionSettingsModal = () => {
  const dispatch = useDispatch()
  const modals = useSelector(state => state.modals)
  const {
    collection,
    meta: {
      displayName,
      description,
    },
    roles: solutionRoles,
    analyses,
  } = useSelector(state => state.admin.solutionAccess)
  const { roles: allRoles, roleIds: allRoleIds } = useSelector(state => state.admin.assignableRoles)
  const updatingPermissions = useSelector(state => state.admin._isUpdatingSolutionPermissions)

  const solutionDetails = [
    {
      label: 'Collection',
      body: collection,
    },
    {
      label: 'Solution name',
      body: displayName,
    },
    {
      label: 'Solution description',
      body: description,
    },
  ]

  return (
    <OverlayModal
      base={10}
      disable={false}
      id={MODAL_ID}
      init={() => null}
      modals={modals}
      onClose={() => {
        dispatch(modalHide(MODAL_ID))
        dispatch(adminEditSolutionAccess(null))
      }}
      push={4}
    >
      <Animate name="fade">
        <div className={styles.base}>
          <Heading className={styles.title} level={5}>{`Edit ${displayName} access`}</Heading>

          <div className={styles.solutionDetails}>
            {solutionDetails.map(d => (
              <LabelledText body={d.body} key={d.label} label={d.label} />
            ))}
          </div>

          <div className={styles.solutionPermissions}>
            {allRoleIds.map(r => allRoles[r]).map(r => (
              <div className={styles.permissionToggleContainer} key={`solution-${r.id}`}>
                <div className={styles.toggleLabel}>{`${r.description} access`}</div>
                <ToggleInput
                  checked={solutionRoles.map(sr => sr.id).indexOf(r.id) >= 0}
                  labelFalse=""
                  labelTrue=""
                  onChange={() => dispatch(adminToggleSolutionPermissions(r.id, solutionRoles.map(sr => sr.id).indexOf(r.id) < 0))}
                />
              </div>
            ))}
          </div>

          <strong>Analysis level access control</strong>

          <div className={styles.analysisPermissions}>
            {analyses.map(a => (
              <ExpanderCard
                className={styles.analysisCard}
                expandedClassName={styles.analysisCardExpanded}
                headerContent={(
                  <div className={styles.analysisCardHeader}>
                    <p>{a.meta.displayName}</p>
                    <TagList tags={a.roles.map(r => r.description)} />
                  </div>
                )}
                key={a.name}
                size="large"
              >
                <div className={styles.analysisCardContent}>
                  <LabelledText body={a.meta.description} label="Analysis description" />
                  <div>
                    {allRoleIds.map(r => allRoles[r]).map(r => (
                      <div className={styles.permissionToggleContainer} key={`${a.name}-${r.id}`}>
                        <div className={styles.toggleLabel}>{r.description}</div>
                        <ToggleInput
                          checked={a.roles.map(ar => ar.id).indexOf(r.id) >= 0}
                          labelFalse=""
                          labelTrue=""
                          onChange={() => dispatch(adminToggleAnalysisPermissions(a.name, r.id, a.roles.map(ar => ar.id).indexOf(r.id) < 0))}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </ExpanderCard>
            ))}
          </div>

          <div className={styles.save}>
            <SubmitButton
              onClick={() => dispatch(adminUpdateSolutionPermissions())}
              submitting={updatingPermissions}
              submittingText="Applying"
            >
              Apply permissions
            </SubmitButton>
          </div>

        </div>
      </Animate>
    </OverlayModal>
  )
}

export default SolutionSettingsModal
