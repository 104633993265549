import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Card from 'components/Card'
import styles from './PanelFilters.scss'

const propTypes = {
  filters: PropTypes.arrayOf(PropTypes.shape({
    count: PropTypes.number,
    filterLevel: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
  })),
  onSelect: PropTypes.func,
  selectedId: PropTypes.number,
}

const defaultProps = {
  filters: [],
  onSelect: () => {},
  selectedId: 0,
}

const PanelFilters = (props) => {
  const { filters, selectedId, onSelect } = props

  if (filters.length === 0) { return null }

  return (
    <div className={styles.base}>
      {filters.map(f => (
        <Card
          className={classnames(styles.filter, {
            [styles.selected]: f.id === selectedId,
            [styles.critical]: f.filterLevel === 'Critical',
            [styles.nonCritical]: f.filterLevel === 'Warning',
            [styles.normal]: f.filterLevel === 'Low',
          })}
          key={f.id}
          onClick={() => onSelect(f)}
        >
          <div>{f.name}</div>
          <div className={styles.count}>{f.count}</div>
        </Card>
      ))}
    </div>
  )
}

PanelFilters.propTypes = propTypes
PanelFilters.defaultProps = defaultProps

export default PanelFilters
