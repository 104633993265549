/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconBusinessAdvisoryAndPlanning = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--businessadvisoryandplanning',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"{...rest}><title>Fill 1</title><g fill="#58595B"><path className="path1" d="M22.7 1.3h-6.4c-.7 0-1.3.6-1.3 1.3v4.6c0 .7.6 1.3 1.3 1.3H19v3.6l-.7-.7c-.2-.2-.5-.2-.7 0-.2.2-.2.5 0 .7l1.6 1.6c.1.1.2.1.3.1.1 0 .2-.1.3-.1l1.6-1.6c.2-.2.2-.5 0-.7s-.5-.2-.7 0l-.7.7V8.5h2.7c.7 0 1.3-.6 1.3-1.3V2.6c0-.7-.6-1.3-1.3-1.3zm.1 5.9c0 .1-.1.1-.1.1h-6.4c-.1 0-.1-.1-.1-.1V2.6c0-.1.1-.1.1-.1h6.4c.1 0 .1.1.1.1v4.6zM22.7 15.5h-6.4c-.7 0-1.3.6-1.3 1.3v4.6c0 .7.6 1.3 1.3 1.3h6.4c.7 0 1.3-.6 1.3-1.3v-4.6c0-.7-.6-1.3-1.3-1.3zm.1 5.9c0 .1-.1.1-.1.1h-6.4c-.1 0-.1-.1-.1-.1v-4.6c0-.1.1-.1.1-.1h6.4c.1 0 .1.1.1.1v4.6zM10.3 6.8c.1.1.2.1.3.1s.2 0 .3-.1l1.6-1.6c.2-.2.2-.5 0-.7L11 3c-.2-.2-.5-.2-.7 0-.2.2-.2.5 0 .7l.7.7H6.4C5.1 4.4 4 5.5 4 6.8v1.4H1.3C.6 8.2 0 8.8 0 9.5v4.6c0 .7.6 1.3 1.3 1.3H4v1.4c0 1.3 1.1 2.4 2.4 2.4h4.7l-.8.8c-.2.2-.2.5 0 .7.1.1.2.1.3.1s.2 0 .3-.1l1.6-1.6c.2-.2.2-.5 0-.7L11 16.9c-.2-.2-.5-.2-.7 0-.2.2-.2.5 0 .7l.7.7H6.4c-.8 0-1.4-.6-1.4-1.4v-1.4h2.7c.7 0 1.3-.6 1.3-1.3V9.5c0-.7-.6-1.3-1.3-1.3H5V6.8c0-.8.6-1.4 1.4-1.4h4.7l-.7.7c-.2.2-.2.5-.1.7zM7.8 9.5v4.6c0 .1-.1.1-.1.1H1.3c-.1 0-.1-.1-.1-.1V9.5c0-.1.1-.1.1-.1h6.4c.1 0 .1.1.1.1z"/><path className="path2" d="M3.1 11.3H6c.2 0 .4-.2.4-.4s-.2-.4-.4-.4H3.1c-.2 0-.4.2-.4.4 0 .3.2.4.4.4zM6 12.4H3.1c-.2 0-.4.2-.4.4s.2.4.4.4H6c.2 0 .4-.2.4-.4s-.2-.4-.4-.4zM20.9 3.7H18c-.2 0-.4.2-.4.4s.2.4.4.4h2.9c.2 0 .4-.2.4-.4 0-.3-.2-.4-.4-.4zM20.9 5.4H18c-.2 0-.4.2-.4.4s.2.4.4.4h2.9c.2 0 .4-.2.4-.4s-.2-.4-.4-.4zM20.9 17.9H18c-.2 0-.4.2-.4.4s.2.4.4.4h2.9c.2 0 .4-.2.4-.4 0-.3-.2-.4-.4-.4zM20.9 19.6H18c-.2 0-.4.2-.4.4s.2.4.4.4h2.9c.2 0 .4-.2.4-.4s-.2-.4-.4-.4z"/></g></IconBase>
  )
}

IconBusinessAdvisoryAndPlanning.propTypes = {
  className: PropTypes.string,
}

export default IconBusinessAdvisoryAndPlanning
/* eslint-enable */
