import React, { Component } from 'react'
import PropTypes from 'prop-types'
import HeaderCard from 'components/HeaderCard'
import Card from 'components/Card'
import {
  Colors, Heading, Searchbar,
} from '@deloitte/gel-library'
import { setFilters } from 'actions/hostedReports'
import ReportFilters from './ReportFilters'
import styles from './HostedReportGroupView.scss'
import EngagementHostedReportList from './EngagementHostedReportList'

class HostedReportGroupView extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    engagements: PropTypes.arrayOf(PropTypes.shape({
      code: PropTypes.string,
    })).isRequired,
    filters: PropTypes.shape({
      client: PropTypes.string,
      search: PropTypes.string,
    }).isRequired,
    onClick: PropTypes.func,
    reportGroupIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    reportGroups: PropTypes.object.isRequired,
    user: PropTypes.shape({
      isAuOffice: PropTypes.bool,
    }).isRequired,
  }

  static defaultProps = {
    onClick: null,
  }

  render() {
    const {
      engagements, filters, reportGroupIds, reportGroups,
      dispatch, onClick, user: { isAuOffice },
    } = this.props

    const jobFilters = (job) => {
      if (filters.client && job.engagement.clientName !== filters.client) {
        return false
      }
      if (filters.search
        && (
          !job.name.toLowerCase().includes(filters.search.toLowerCase())
          && !job.reports?.map(report => report.name.toLowerCase()).some(rn => rn.includes(filters.search.toLowerCase()))
          && !job.engagement.name.toLowerCase().includes(filters.search.toLowerCase())
          && !job.engagement.code.toLowerCase().includes(filters.search.toLowerCase())
          && !job.engagement.clientName.toLowerCase().includes(filters.search.toLowerCase())
        )) {
        return false
      }
      return true
    }

    const nFilteredJobs = reportGroupIds.map(x => reportGroups[x]).filter(jobFilters).length

    return (
      <div className={styles.base}>
        <Heading className={styles.jobCount} level={8}>
          {`${nFilteredJobs} Report collection${nFilteredJobs === 1 ? '' : 's'}`}
        </Heading>

        <div className={styles.filterArea}>
          <Searchbar
            mini
            className={styles.search}
            onSearch={value => dispatch(setFilters({ type: 'search', value }))}
            placeholder="Search report collection"
            value={filters.search}
          />

          <ReportFilters
            dispatch={dispatch}
          />
        </div>

        {nFilteredJobs === 0 && (
          <Card className={styles.noResults}>
            <div>
              <p>No jobs found</p>
              <p>Try adjusting your search or filter to find what you’re looking for.</p>
            </div>
          </Card>
        )}

        {engagements.length === 1 && !isAuOffice
          ? (
            <EngagementHostedReportList
              onReportClick={onClick}
              reportGroups={reportGroupIds.map(id => reportGroups[id]).filter(jobFilters)}
            />
          )
          : engagements.map((e) => {
            const engagementReportGroups = e.reportGroups
              .map(id => reportGroups[id])
              .filter(jobFilters)

            if (engagementReportGroups.length === 0) { return null }

            return (
              <HeaderCard
                heading={e.name}
                highlightColor={e.active ? Colors.supportBlue02 : Colors.uiGrey05}
                key={e.code}
                status={e.active ? 'Active' : 'Closed'}
                subHeading={e.clientName}
              >
                <EngagementHostedReportList
                  onClick={onClick}
                  reportGroups={engagementReportGroups}
                />
              </HeaderCard>
            )
          })}
      </div>
    )
  }
}

export default HostedReportGroupView
