import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './Preloader.scss'

const propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
}

const defaultProps = {
  color: '#86bd24',
  size: 58,
}

const Preloader = ({ size, color }) => {
  const divStyle = {
    width: `${size}px`,
    height: `${size}px`,
  }

  const spinnerlayerStyle = {
    borderColor: color,
  }
  return (
    <div
      className={styles.preloader}
      style={divStyle}
    >
      <div className={styles.preloaderWrapper}>
        <div
          className={styles.spinnerlayer}
          style={spinnerlayerStyle}
        >
          <div className={classnames(styles.circleclipper, styles.left)}>
            <div className={styles.circleleft} />
          </div>
          <div className={styles.gappatch}>
            <div className={styles.circle} />
          </div>
          <div className={classnames(styles.circleclipper, styles.right)}>
            <div className={styles.circleright} />
          </div>
        </div>
      </div>
    </div>
  )
}

Preloader.propTypes = propTypes
Preloader.defaultProps = defaultProps

export default Preloader
