import React from 'react'
import Avatar from 'components/Avatar'
import { getUserDisplayString } from 'utils/users'
import { userPropType } from 'utils/propTypes'
import styles from './User.scss'

function User({ user }) {
  if (!user) {
    return <div />
  }

  return (
    <div className={styles.base}>
      <Avatar className={styles.avatar} size="small" userDetails={user.userDetails} />
      <span className={styles.name}>{getUserDisplayString(user)}</span>
    </div>
  )
}

User.propTypes = {
  user: userPropType,
}

User.defaultProps = {
  user: null,
}

export default User

