import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { commaFormatNumber } from 'utils/numbers'
import styles from './TabFilter.scss'

const propTypes = {
  className: PropTypes.string,
  greyscale: PropTypes.bool,
  onTabChange: PropTypes.func,
  startingTabId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tabs: PropTypes.arrayOf(PropTypes.shape({
    filterFunction: PropTypes.func,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  })).isRequired,
}

const defaultProps = {
  className: null,
  greyscale: false,
  startingTabId: null,
  onTabChange: () => {},
}

function TabFilter({
  greyscale, tabs, className, startingTabId, onTabChange,
}) {
  return (
    <>
      <div>
        <ul className={classNames(
          styles.base,
          greyscale ? styles.greyscale : null,
          className,
        )}
        >
          {tabs.map(t => (
            <li className={t.id === startingTabId ? styles.selected : null} key={t.id}>
              <a onClick={() => onTabChange(t.id)}>
                {t.icon}
                <span className={styles.tabName}>
                  {t.name}
                </span>
                <span className={styles.tabCount}>{commaFormatNumber(t.count)}</span>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}

TabFilter.propTypes = propTypes
TabFilter.defaultProps = defaultProps

export default TabFilter

