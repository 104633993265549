/* eslint-disable react/no-danger */
/* eslint-disable react/no-string-refs */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import constants from 'constants'
import Spinner from 'components/Spinner'
import Tag from './components/Tag'
import Suggestions from './components/Suggestions'

import styles from './TagInput.scss'

class TagInput extends Component {
  static propTypes = {
    allowDeleteFromEmptyInput: PropTypes.bool,
    autofocus: PropTypes.bool,
    autoselect: PropTypes.bool,
    collapseAmount: PropTypes.number,
    delimeters: PropTypes.arrayOf(PropTypes.number),
    disabled: PropTypes.bool,
    error: PropTypes.string,
    fetching: PropTypes.bool,
    floatedLabel: PropTypes.bool,
    focus: PropTypes.bool,
    handleAddition: PropTypes.func,
    handleChange: PropTypes.func,
    handleDelete: PropTypes.func,
    hasFetching: PropTypes.bool,
    hideAvatars: PropTypes.bool,
    inline: PropTypes.bool,
    label: PropTypes.string,
    labelField: PropTypes.string,
    minQueryLength: PropTypes.number,
    name: PropTypes.string,
    note: PropTypes.string,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    suggestions: PropTypes.array,
    tagRenderer: PropTypes.func,
    tags: PropTypes.array,
    valid: PropTypes.bool,
    writeOnly: PropTypes.bool,
  }

  static defaultProps = {
    allowDeleteFromEmptyInput: true,
    autoselect: true,
    autofocus: false,
    delimeters: [13, 9],
    disabled: false,
    error: '',
    fetching: false,
    floatedLabel: true,
    hasFetching: false,
    hideAvatars: false,
    inline: true,
    minQueryLength: 2,
    placeholder: '',
    readOnly: false,
    suggestions: [],
    tags: [],
    valid: true,
    writeOnly: false,
  }

  constructor(props) {
    super(props)
    this.timeout = null
    this.state = {
      query: '',
      selectedIndex: -1,
      selectionMode: false,
      hasFocus: false,
      expand: false,
    }
  }

  componentDidMount() {
    const { autofocus, readOnly } = this.props

    if (autofocus && !readOnly) {
      this.refs.input.focus()
    }
  }

  _addTagByQuery = (query) => {
    const { suggestions } = this.props
    const { selectedIndex, selectionMode } = this.state

    let q = query
    if (selectionMode) {
      q = suggestions[selectedIndex]
    } else if (query !== '') {
      if (typeof q !== 'object') {
        q = { label: q }
      }
    }
    if (q !== '') {
      this._addTag(q)
    }
  }

  _resize = (value, factor = 9.5) => {
    return `${(value.length + 1) * factor}px`
  }

  // Handlers
  _handleFocus = () => {
    const { onFocus, name } = this.props

    onFocus(name)
    this.setState(() => ({ hasFocus: true }))
  }

  _handleBlur = () => {
    const { autoselect, onBlur, name } = this.props
    const { query } = this.state

    clearTimeout(this.timeout)
    if (
      this.refs.suggestions
      && document.activeElement === this.refs.suggestions.refs.menu
    ) {
      this._handleFocus()
    } else {
      this.timeout = setTimeout(() => {
        if (autoselect) {
          this._addTagByQuery(query)
        } else if (this.refs.input) {
          this.refs.input.value = ''
        }

        onBlur(name)
        this.setState(() => ({ hasFocus: false }))
      }, 200)
    }
  }

  _handleChange = (e) => {
    const { handleChange, name } = this.props
    const { input } = this.refs

    const query = e.target.value.trim()

    input.style.width = this._resize(query)
    this.setState(() => ({
      query,
    }))
    if (handleChange) {
      handleChange(name, query)
    }
  }

  _handleKeyDown = (e) => {
    const {
      allowDeleteFromEmptyInput,
      collapseAmount,
      suggestions,
      delimeters,
      tags,
    } = this.props
    const { expand, selectedIndex, query } = this.state

    // Hide suggestions menu on escape
    if (e.keyCode === constants.KEY_CODES.ESC) {
      e.preventDefault()
      this.setState(() => ({
        selectedIndex: -1,
        selectionMode: false,
      }))
    }

    // When one of the terminating keys is pressed, add current query to the tags.
    // If no text is typed in so far, ignore the action - so we don't end up with a terminating
    // character typed in.
    if (delimeters.indexOf(e.keyCode) !== -1) {
      if (e.keyCode !== constants.KEY_CODES.TAB) {
        e.preventDefault()
      }
      this._addTagByQuery(query)
    }

    // When backspace key is pressed and query is blank, delete tag
    if (
      e.keyCode === constants.KEY_CODES.BACKSPACE
      && query === ''
      && allowDeleteFromEmptyInput
    ) {
      if (!expand && collapseAmount) {
        this.setState(() => ({ expand: true }))
      } else {
        this._handleDelete(tags.length - 1)
      }
    }

    // Up Arrow
    if (e.keyCode === constants.KEY_CODES.UP_ARROW) {
      e.preventDefault()
      const si = selectedIndex

      // On Last item go to the top
      if (si <= 0) {
        this.setState((prevState, props) => ({
          selectedIndex: props.suggestions.length - 1,
          selectionMode: true,
        }))
      } else {
        this.setState(() => ({
          selectedIndex: si - 1,
          selectionMode: true,
        }))
      }
    }

    // Down Arrow
    if (e.keyCode === constants.KEY_CODES.DOWN_ARROW) {
      e.preventDefault()
      this.setState(prevState => ({
        selectedIndex: (prevState.selectedIndex + 1) % suggestions.length,
        selectionMode: true,
      }))
    }
  }

  _handleDelete = (index) => {
    const { name, handleDelete } = this.props

    if (index === -1) {
      return
    }
    handleDelete(name, index)
    this.setState(() => ({ query: '' }))
  }

  _handleClick = (tag) => {
    clearTimeout(this.timeout)
    this.setState(() => ({
      selectedIndex: -1,
      selectionMode: false,
    }))
    this._addTag(tag, true)
  }

  _addTag = (tag, viaClick = false) => {
    const {
      handleAddition,
      name,
    } = this.props
    const { selectionMode } = this.state
    const { input } = this.refs

    const newTag = tag

    // Call method to add
    if (
      selectionMode
      || viaClick
      // eslint-disable-next-line no-mixed-operators
    ) {
      handleAddition(name, newTag)
    }

    // Reset the state
    this.setState(() => ({
      query: '',
      selectionMode: false,
      selectedIndex: -1,
    }))

    input.value = ''
    input.focus()

    // focus back on the input box
    clearTimeout(this.timeout)
    this.setState(() => ({ hasFocus: true }))
  }

  _tagItem = (tag, i) => {
    const {
      tagRenderer,
      hideAvatars,
      labelField,
      readOnly,
      writeOnly,
    } = this.props

    if (tagRenderer) {
      return tagRenderer(tag, i, this.props, this._handleDelete)
    }

    return (
      <Tag
        hideAvatar={hideAvatars}
        index={i}
        key={i}
        labelField={labelField}
        onDelete={this._handleDelete}
        readOnly={readOnly || writeOnly}
        tag={tag}
      />
    )
  }

  _expand = () => {
    this.setState(() => ({ expand: true }))
  }

  _collapseTag = (i, amount, total) => {
    const { labelField } = this.props

    const tag = {}
    tag.label = `+${amount} more`
    return (
      <Tag
        hideAvatar
        readOnly
        handleClick={this._expand}
        index={total}
        key={total}
        labelField={labelField}
        tag={tag}
      />
    )
  }

  _input = () => {
    // get the suggestions for the given query
    const {
      disabled,
      placeholder,
      fetching,
      suggestions,
      minQueryLength,
    } = this.props
    const { query, selectedIndex, hasFocus } = this.state

    return (
      <div className={styles.input} ref="inputContainer">
        <input
          aria-label={placeholder}
          autoComplete="off"
          disabled={disabled}
          onBlur={this._handleBlur}
          onChange={this._handleChange}
          onFocus={this._handleFocus}
          onKeyDown={this._handleKeyDown}
          placeholder={placeholder}
          ref="input"
          type="text"
        />
        {fetching && <Spinner className={styles.spinner} size="xSmall" />}
        <Suggestions
          handleClick={this._handleClick}
          minQueryLength={minQueryLength}
          query={query}
          ref="suggestions"
          selectedIndex={selectedIndex}
          show={hasFocus}
          suggestions={
            fetching || query.length < minQueryLength ? [] : suggestions
          }
        />
      </div>
    )
  }

  _asHTML = (content) => {
    return {
      __html: content,
    }
  }

  render() {
    const {
      collapseAmount,
      floatedLabel,
      tags,
      focus,
      error,
      hasFetching,
      label,
      note,
      disabled,
      valid,
      inline,
    } = this.props

    const { expand, query } = this.state

    const hasError = error.length ? 'hasError' : ''
    const allowLabel = !floatedLabel ? styles.disablFloatedLabel : ''
    const displayNote = !valid ? styles.isHidden : ''
    const classes = {
      [styles.isFocused]: focus,
      [styles.hasValue]: tags.length || query.length,
      [styles.disabled]: disabled,
      [styles.hasFetching]: hasFetching,
    }

    return (
      <div className={classnames(styles.base, allowLabel, classes)}>
        <div className={styles.tags}>
          <div className={styles.selected}>
            {tags.map((tag, i) => {
              if (!expand && collapseAmount) {
                if (i > collapseAmount) {
                  return null
                }
                if (i === collapseAmount) {
                  return this._collapseTag(
                    i,
                    tags.length - collapseAmount,
                    tags.length,
                  )
                }
                return this._tagItem(tag, i)
              }
              return this._tagItem(tag, i)
            })}
            {inline && this._input()}
          </div>
          {!inline && this._input()}
        </div>
        <span className={styles.bar} />
        {label && (
          <span className={classnames(styles.label, hasError, allowLabel)}>
            {label}
          </span>
        )}
        {error && (
          <span className={classnames(styles.error)}>
            <span dangerouslySetInnerHTML={this._asHTML(error)} />
          </span>
        )}
        {note && (
          <span className={classnames(styles.note, displayNote)}>
            <span dangerouslySetInnerHTML={this._asHTML(note)} />
          </span>
        )}
      </div>
    )
  }
}

export default TagInput
