import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, Switch } from 'react-router-dom'
import { fetch as fetchJobs } from 'actions/jobs'
import Banner from 'components/Banner'
import Container from 'components/layout/Grid/Container'
import Loading from 'components/Loading'
import RouteWithSubRoutes from 'components/RouteWithSubRoutes'
import { push } from 'connected-react-router'

class Admin extends Component {
  static propTypes = {
    app: PropTypes.shape({
      user: PropTypes.object,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    jobs: PropTypes.shape({
      isFetched: PropTypes.bool,
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    path: PropTypes.string.isRequired,
    routes: PropTypes.arrayOf(PropTypes.shape({
      path: PropTypes.string,
    })).isRequired,
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(fetchJobs(true))
    this.goToFirstRouteFromRoot()
  }

  componentDidUpdate() {
    this.goToFirstRouteFromRoot()
  }

  goToFirstRouteFromRoot = () => {
    const { dispatch, location: { pathname }, routes } = this.props
    if (pathname === '/admin') {
      dispatch(push(routes[0].path))
    }
  }

  render() {
    const { routes, jobs: { isFetched }, app: { user } } = this.props
    const availableRoutes = routes.filter(r => r.hasPermission(user))

    return (
      <div>
        <Banner
          name="Admin settings"
          routes={availableRoutes}
        />
        <Container topPadding>
          {!isFetched
            ? <Loading pageLoading />
            : (
              <Switch>
                {routes.map(route => (
                  <RouteWithSubRoutes
                      key={route.path}
                      {...this.props}
                      {...route}
                      user={user}
                  />
                ))}
              </Switch>
            )}

        </Container>
      </div>
    )
  }
}

const mapStateToProps = ({ jobs, app, activity }) => ({
  jobs,
  app,
  activity,
})

export default withRouter(connect(mapStateToProps)(Admin))
