import { goToInputdata } from 'actions/job'

export const NO_TEST_STATUSES = [
  'Test required',
  'Tests Required',
  'NoTests',
]

export const DATA_READY_STATUSES = [
  'Data Required',
  'NoData',
]

export const JOB_EXECUTING_STATUSES = [
  'Processing Report',
  'ReportRequested',
  'ReportProcessing',
  'ReportGenerationFailed',
]

export const DATA_VALIDATING_STATUSES = [
  'DataValidationRequested',
  'DataValidating',
  'DataValidationFailed',
]

export const DATA_PROCESSED_STATUSES = [
  'DataValidated',
]

export const COMPLETE_STATUSES = [
  'Report Available',
  'ReportGenerated',
]

export const jobStatuses = {
  NoTests: { value: 1, description: 'Select analyses', stepId: 2 },
  NoData: { value: 2, description: 'Input data', stepId: 4 },
  DataValidationFailed: { value: 2, description: 'Input data', stepId: 4 },
  DataUploading: { value: 3, description: 'Data quality check', stepId: 4 },
  DataValidationRequested: { value: 3, description: 'Data quality check', stepId: 4 },
  DataValidating: { value: 3, description: 'Data quality check', stepId: 4 },
  DataValidated: { value: 4, description: 'Final review', stepId: 6 },
  ReportRequested: { value: 4, description: 'Processing data…', stepId: 6 },
  ReportProcessing: { value: 4, description: 'Processing data…', stepId: 6 },
  ReportGenerationFailed: { value: 4, description: 'Processing data…', stepId: 6 },
  ReportGenerated: { value: 5, description: 'Reports ready', stepId: 6 },
  DataPreparation: { value: 3, description: 'Data Preparation', stepId: 3 },
}

export const jobIsFinalised = (job) => {
  return COMPLETE_STATUSES.concat(JOB_EXECUTING_STATUSES).includes(job.jobStatusDesc)
}

export const jobIsProcessing = job => JOB_EXECUTING_STATUSES.includes(job.jobStatusDesc)
export const jobDataIsValidating = job => DATA_VALIDATING_STATUSES.includes(job.jobStatusDesc)
export const jobDataIsValidated = job => DATA_PROCESSED_STATUSES.includes(job.jobStatusDesc)
export const jobExecutionFailed = job => job.jobStatusDesc === 'ReportGenerationFailed'

export const getWorkflowSteps = (jobId, isExternal = false, isControlsAdvantage = false) => {
  const updateJobLabel = isExternal ? 'Job details' : 'Update job'
  const steps = [
    {
      label: jobId ? updateJobLabel : 'Create job',
      to: isExternal ? `/create/${jobId}/details` : `/create/${jobId}/edit`,
      id: 1,
      isControlsAdvantage: false,
      skipWithNoInputData: false,
      skipWithNoAnalyses: false,
      dataLossPossible: false,
    },
    {
      label: isExternal ? 'View analyses' : 'Select analyses',
      to: `/create/${jobId}/selecttests`,
      id: 2,
      isControlsAdvantage: false,
      skipWithNoInputData: false,
      skipWithNoAnalyses: true,
      dataLossPossible: true,
    },
    {
      label: 'Get data',
      to: `/create/${jobId}/getdata`,
      id: 3,
      isControlsAdvantage,
      skipWithNoInputData: true,
      skipWithNoAnalyses: false,
      dataLossPossible: false,
    },
    {
      label: 'Input data',
      to: `/create/${jobId}/inputdata`,
      toAction: () => goToInputdata({ jobId }),
      id: 4,
      isControlsAdvantage: false,
      skipWithNoInputData: true,
      skipWithNoAnalyses: true,
      dataLossPossible: true,
    }, {
      label: 'Data quality',
      to: `/create/${jobId}/dataquality`,
      id: 5,
      isControlsAdvantage: false,
      skipWithNoInputData: true,
      skipWithNoAnalyses: true,
      dataLossPossible: false,
    }, {
      label: 'Final review',
      to: `/create/${jobId}/review`,
      id: 6,
      isControlsAdvantage: false,
      skipWithNoInputData: false,
      skipWithNoAnalyses: true,
      dataLossPossible: true,
    },
  ]

  return steps
}

export const getWorkflowStep = (id, jobId, isExternal = false) => getWorkflowSteps(jobId, isExternal).find(s => s.id === id)
