
import React, { useState, useEffect } from 'react'
import { formatDateTimeFromStringShort } from 'utils/dates'
import { Button, Radio } from '@deloitte/gel-library'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { updateJob } from 'actions/job'
import { useDispatch, useSelector } from 'react-redux'
import Loading from 'components/Loading'
import ImportActionsInfoText from 'components/ImportActionsInfoText'
import ImportActionsEmptyScreen from 'components/ImportActionsEmptyScreen'
import { hot } from 'react-hot-loader/root'
import LinesEllipsis from 'react-lines-ellipsis'
import { sortByFunction } from 'utils/arrays'
import { getNoOfEntitiesToOverride } from 'actionHub/utils/actionHubApi'
import { isNullOrUndefined } from 'utils/utils'
import useImportActions from 'actionHub/hooks/useImportActions'
import styles from './ConnectedJobActions.scss'

const PAGINATION_THRESHOLD = 6
const propTypes = {
  action: PropTypes.object.isRequired,
  onSelection: PropTypes.func.isRequired,
}

const ConnectedJobActions = ({ action, onSelection }) => {
  const {
    connectedJobsOverrideNos, connectedJobsActions, clientName, packageName,
  } = useSelector(state => state.job)
  const { updateActionIds } = useImportActions()

  const [isFetchingConnectedJobs, setIsFetchingConnectedJobs] = useState(false)
  const [isFetchedConnectedJobs, setIsFetchedConnectedJobs] = useState(false)

  const [currentVisibleCards, setCurrentVisibleCards] = useState(PAGINATION_THRESHOLD)
  const [selectedCurrConnJobActSetId, setSelectedCurrConnJobActSetId] = useState(null)
  const dispatch = useDispatch()
  const connectedJobsCurrentActions = connectedJobsActions
    && Object.values(connectedJobsActions)

  const handleSelection = (value, overridingEntitiesCnt) => {
    setSelectedCurrConnJobActSetId(value)
    onSelection(value, overridingEntitiesCnt)
  }

  const sortedConnectedJobsCurrentActions = connectedJobsCurrentActions && connectedJobsCurrentActions
    .slice()
    .sort(sortByFunction(cjca => cjca.actionSet.updatedDate, true))

  useEffect(() => {
    if (!isFetchingConnectedJobs && !isFetchedConnectedJobs && connectedJobsCurrentActions && connectedJobsCurrentActions.length > 0) {
      const type = action.actionSet.analysisName !== null ? 'analysis' : 'action'
      const name = action.actionSet.analysisName !== null ? action.actionSet.analysisName : action.actionSet.actionName

      const actionSetIdsToImport = updateActionIds(action, connectedJobsCurrentActions)

      setIsFetchingConnectedJobs(true)
      getNoOfEntitiesToOverride(action.actionSet.id, actionSetIdsToImport, type, name)
        .then(({ data }) => {
          setIsFetchedConnectedJobs(true)
          setIsFetchingConnectedJobs(false)
          dispatch(
            updateJob({
              connectedJobsOverrideNos: data,
            }),
          )
        })
        .catch((e) => {
          console.error('Encountered following error while fetching number of overriding entities: ', e)
        })
    }
  }, [dispatch, action, connectedJobsActions, isFetchingConnectedJobs, isFetchedConnectedJobs, connectedJobsCurrentActions, updateActionIds])

  const isFetchedAndEmpty = !isNullOrUndefined(connectedJobsActions) && connectedJobsActions.length === 0

  return (
    <div className={classNames(styles.flexContainer, styles.flexColDir)}>
      <div className={classNames(styles.heading, styles.marginBottom)}>
        Import from connected jobs
      </div>
      <div className={classNames(styles.headingDesc, styles.marginBottom)}>
        You can now import current actions from existing jobs, that share the same client name and solution. Jobs that you have permission to access, will appear below.
      </div>
      <ImportActionsInfoText />
      <div className={styles.marginBottom}><b>JOB DETAILS</b></div>
      <div className={classNames('row', styles.marginBottom)}>
        <div className="col-md-4 col-sm-6 col-xs-12">
          <div className={styles.jobDetailsHeadings}>Client</div>
          <div className={styles.jobDetailsValues}>{clientName}</div>
        </div>
        <div className="col-md-4 col-sm-6 col-xs-12">
          <div className={styles.jobDetailsHeadings}>Solution</div>
          <div className={styles.jobDetailsValues}>{packageName}</div>
        </div>
      </div>
      <div>
        {((sortedConnectedJobsCurrentActions && connectedJobsOverrideNos) || isFetchedAndEmpty) ? (
          <div>
            {sortedConnectedJobsCurrentActions.length > 0
              ? (
                <div className="row">
                  {sortedConnectedJobsCurrentActions.slice(0, currentVisibleCards).map((cjca) => {
                    return (
                      <div className="col-md-4 col-sm-6 col-xs-12" key={cjca.actionSet.id}>
                        <div
                          className={classNames(styles.card, {
                            [styles.selected]: selectedCurrConnJobActSetId === cjca.actionSet.id,
                          })}
                          key={cjca.actionSet.id}
                          onClick={() => handleSelection(cjca.actionSet.id, connectedJobsOverrideNos[cjca.actionSet.id])}
                        >

                          <div className={styles.marginBottom}>
                            <Radio
                              checked={selectedCurrConnJobActSetId === cjca.actionSet.id}
                              name="radio-buttons"
                              value={cjca.actionSet.id}
                            />
                          </div>
                          <div className={classNames(styles.marginBottom, styles.cardHeading)}>
                            <LinesEllipsis maxLine={2} text={cjca.jobName} />
                          </div>
                          <div className={classNames(styles.marginBottom, styles.cardSubHeading)}>
                            {packageName}
                            <br />
                            <div className={styles.overrideNo}>
                              {connectedJobsOverrideNos[cjca.actionSet.id]}
                              {' '}
                              of
                              {' '}
                              {cjca.actionSet.taskCount}
                              {' '}
                              entities matched
                            </div>
                          </div>
                          <div className={styles.lastUpdated}>
                            Last updated
                            {' '}
                            {formatDateTimeFromStringShort(cjca.actionSet.updatedDate)}
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              )
              : <ImportActionsEmptyScreen textValue="connected jobs" />}
          </div>
        ) : <Loading pageLoading className={styles.additionalStyles} />}
      </div>
      {sortedConnectedJobsCurrentActions
        && connectedJobsOverrideNos
        && sortedConnectedJobsCurrentActions.length > PAGINATION_THRESHOLD
        && sortedConnectedJobsCurrentActions.length > currentVisibleCards
        && (
          <div className={styles.buttonRow}>
            <Button
              mode="flat"
              onClick={() => setCurrentVisibleCards(currentVisibleCards + PAGINATION_THRESHOLD)}
            >
              Show more
            </Button>
          </div>
        )
      }
    </div>
  )
}
ConnectedJobActions.propTypes = propTypes

export default hot(ConnectedJobActions)

