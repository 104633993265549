import React, { useState, useEffect } from 'react'
import GroupedAvatar from 'components/GroupedAvatar'
import { Heading, Button } from '@deloitte/gel-library'
import Popover from '@material-ui/core/Popover'
import TeamMembers from 'components/TeamMembers'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import ConfirmDeleteDialog from 'components/ConfirmDeleteDialog'
import {
  modifyUserJobrole,
  removeMember,
  updateTeamMembers,
} from 'actions/job'
import DeloitteMemberCard from 'components/DeloitteMemberCard'
import { useDispatch, useSelector } from 'react-redux'
import AddExternalUserModal from 'components/AddExternalUserModal'
import { modalCreate, modalHide, modalShow } from 'actions/modals'
import { MODAL_ADD_EXTERNAL_USER, MODAL_MODIFY_USER_JOB_ROLE } from 'constants/forms'
import { updateExternalUsers } from 'actions/forms/addExternalUsers'
import DialogSimple from 'components/DialogSimple'
import InfoIcon from '@material-ui/icons/Info'
import { Divider } from '@material-ui/core'
import Grow from '@material-ui/core/Grow'
import HeightAnimator from 'components/HeightAnimator/HeightAnimator'
import {
  getJobPartnerAndManagerDetails, isManagerOrPartnerOnJob, isManagerOrPartnerRole,
  isParticipatingManagerOrPartnerRole, jobHasClientMembers,
} from 'utils/business/jobs'
import Group from '@material-ui/icons/Group'
import { hot } from 'react-hot-loader/root'
import { objectToArray } from 'utils/objects'
import styles from './ManageTeam.scss'

const propTypes = {
  onDark: PropTypes.bool,
}

const defaultProps = {
  onDark: false,
}

const ManageTeam = ({ onDark }) => {
  const dispatch = useDispatch()

  const forms = useSelector(state => state.forms)
  const modals = useSelector(state => state.modals)
  const user = useSelector(state => state.app.user)
  const job = useSelector(state => state.job)

  const { members, isClientJob } = job
  const { isExternal, isAdmin, permissions: { inviteClientUsers } } = user

  const canInviteClientsOnJob = isAdmin || (inviteClientUsers && isManagerOrPartnerOnJob(user, members))
  const isCorrectStateToAddClient = job.hasExecuted || (job.isClientJob && job.analyses.length > 0)
  const showAddClient = canInviteClientsOnJob && isCorrectStateToAddClient

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [memberToDelete, setMemberToDelete] = useState(null)
  const [showAddDeloitteMemberPopover, setShowAddDeloitteMemberPopover] = useState(false)
  const [memberToModify, setMemberToModify] = useState({
    member: null,
    targetRole: null,
  })
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : null
  const clientUsers = members.filter(m => m.userDetails.isExternal && m.value.length > 0)
  const deloitteUsers = members.filter(m => !m.userDetails.isExternal && m.value.length > 0)

  const closeDeleteDialog = () => {
    setShowDeleteDialog(false)
    setMemberToDelete(null)
  }
  const confirmDeleteDialog = () => {
    if (!memberToDelete.userDetails.isExternal) {
      const finalMembers = members.filter(m => m.userDetails.email !== memberToDelete.userDetails.email && !m.userDetails.isExternal && m.userDetails.email !== null).map(m => m.userDetails.email)
      dispatch(updateTeamMembers(job.jobId, { userName: finalMembers }))
      closeDeleteDialog()
    } else {
      dispatch(removeMember(job.jobId, memberToDelete.id, `${memberToDelete.userDetails.firstName} ${memberToDelete.userDetails.surname}`, closeDeleteDialog))
    }
  }

  const openDeleteDialog = (member) => {
    setShowDeleteDialog(true)
    setMemberToDelete(member)
    setAnchorEl(null)
  }

  const openPermissionsDialogue = (member, targetRoleId) => {
    if (targetRoleId !== 'Remove') {
      setMemberToModify({
        member,
        targetRole: member.jobAssignableRoles.find(jr => jr.id === targetRoleId),
      })
      dispatch(modalShow(MODAL_MODIFY_USER_JOB_ROLE))
      setAnchorEl(null)
    }
  }

  const handleAddClientUser = () => {
    dispatch(modalShow(MODAL_ADD_EXTERNAL_USER))
    setAnchorEl(null)
  }

  const sortAlphabetically = (teamMembers) => {
    return teamMembers.sort((a, b) => ((a.userDetails.displayName.toUpperCase() > b.userDetails.displayName.toUpperCase()) ? 1 : -1))
  }

  const sortTeamMembers = (teamMembers, isExternalMembers) => {
    return !isExternalMembers
      ? [
        ...objectToArray(getJobPartnerAndManagerDetails(job)),
        ...sortAlphabetically(teamMembers.filter(tm => !isManagerOrPartnerRole(tm.role.name) && !isParticipatingManagerOrPartnerRole(tm.role.name))),
      ].filter(m => m)
      : sortAlphabetically(teamMembers)
  }

  const openPopover = (event) => {
    setShowAddDeloitteMemberPopover(false)
    setAnchorEl(event.currentTarget)
  }

  useEffect(() => {
    dispatch(modalCreate(MODAL_ADD_EXTERNAL_USER))
    dispatch(modalCreate(MODAL_MODIFY_USER_JOB_ROLE))
  }, [dispatch])

  const hasClientMembers = jobHasClientMembers(job)
  const showClientActivated = !isExternal && ((isClientJob && Math.min(job.step, 4) >= 3) || hasClientMembers)
  const clientActivatedText = isClientJob ? 'Client subscription' : 'Client shared'

  return (
    <div className={styles.flex}>
      { showClientActivated && (
      <div
        className={classnames(
          styles.clientPill,
          hasClientMembers ? styles.clientActivated : styles.clientNotAdded,
        )}
      >
        {hasClientMembers && <Group className={styles.icon} fontSize="small" />}
        <div>{hasClientMembers ? clientActivatedText : 'No clients added'}</div>
      </div>
      )}
      <div className={styles.avatars}>
        <GroupedAvatar members={members.filter(m => m.value.length > 0)} onDark={onDark} />
      </div>
      <div>
        <div>
          <Button
            aria-describedby={id}
            mode="secondary"
            onClick={event => openPopover(event)}
            onDark={onDark}
          >
            MANAGE MEMBERS
          </Button>
        </div>
        <div>
          <Popover
            TransitionComponent={Grow}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            className={styles.MuiPopoverpaper}
            id={id}
            onClose={() => setAnchorEl(null)}
            open={open}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <HeightAnimator>
              {showAddDeloitteMemberPopover ? (
                <div>
                  <DeloitteMemberCard
                    closeCard={() => setAnchorEl(null)}
                    currentMembers={members.map(m => m.userDetails).filter(u => !u.isExternal).map(fu => fu.email)}
                    jobId={job.jobId}
                    onCancel={() => setShowAddDeloitteMemberPopover(false)}
                  />
                </div>
              ) : (
                <div>
                  <div className={styles.typography}>
                    {clientUsers.length > 0 && (
                    <div className={styles.inlinePadding}>
                      <div className={styles.roleHeading}>
                        <div className={styles.teamSeperationHeadig}>
                          Client users
                        </div>
                        <div>
                          <TeamMembers members={sortTeamMembers(clientUsers, true)} openDeleteDialog={openDeleteDialog} openPermissionsDialogue={openPermissionsDialogue} />
                        </div>
                      </div>
                    </div>
                    )}
                    <Divider />
                    {deloitteUsers.length > 0 && (
                    <div className={styles.inlinePadding}>
                      <div className={styles.roleHeading}>
                        {clientUsers.length > 0 && (
                        <div className={styles.teamSeperationHeadig}>
                          Deloitte users
                        </div>
                        )}
                        <div>
                          <TeamMembers members={sortTeamMembers(deloitteUsers, false)} openDeleteDialog={openDeleteDialog} />
                        </div>
                      </div>
                    </div>
                    )}
                  </div>
                  <Divider />
                  <div>
                    <div className={styles.inlinePadding}>
                      <div>
                        <Button mode="flat" onClick={() => setShowAddDeloitteMemberPopover(!showAddDeloitteMemberPopover)}>{`ADD ${showAddClient ? 'DELOITTE' : ''} USER`}</Button>
                      </div>
                      {showAddClient && (
                      <div>
                        <Button mode="flat" onClick={() => handleAddClientUser()}>ADD CLIENT USER</Button>
                      </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </HeightAnimator>
          </Popover>
        </div>
        {showDeleteDialog
      && (
      <ConfirmDeleteDialog
        closeDeleteDialog={closeDeleteDialog}
        confirmDeleteDialog={confirmDeleteDialog}
        job={job}
        userToDelete={memberToDelete}
      />
      )
       }
        <AddExternalUserModal
          dispatch={dispatch}
          forms={forms}
          job={job}
          modals={modals}
          onSubmit={() => dispatch(updateExternalUsers(job.jobId))}
        />
        {memberToModify.member && (
        <DialogSimple
          actionLabel="Apply"
          className={styles.modifyMember}
          dismissLabel="Cancel"
          icon={<InfoIcon className={styles.icon} fontSize="large" />}
          isLoading={job._isModifyingUserRole}
          isOpen={modals[MODAL_MODIFY_USER_JOB_ROLE] && modals[MODAL_MODIFY_USER_JOB_ROLE].show}
          onAction={() => dispatch(modifyUserJobrole(job.jobId, memberToModify.member.id, memberToModify.targetRole.id))}
          onDismiss={() => dispatch(modalHide(MODAL_MODIFY_USER_JOB_ROLE))}
        >
          <Heading level={7}>
            {`Are you sure you want to make ${memberToModify.member.userDetails.displayName} a ‘${memberToModify.targetRole?.description}’ on ${job.name}?`}
          </Heading>
          <p>
            {'Modifying a user\'s role will change the permissions they have on this job. '}
            {`As a ‘${memberToModify.targetRole.description}’ ${memberToModify.member.userDetails.firstName} will be able to;`}
          </p>
          <ul>
            {memberToModify.targetRole.permissions.map(p => <li key={p.id}>{p.description}</li>)}
          </ul>
        </DialogSimple>
        )}
      </div>
    </div>
  )
}

ManageTeam.propTypes = propTypes
ManageTeam.defaultProps = defaultProps

export default hot(ManageTeam)
