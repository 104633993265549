/* eslint-disable react/jsx-one-expression-per-line */
import Banner from 'components/Banner'
import Container from 'components/layout/Grid/Container'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
// import Loading from 'components/Loading'
import { getAppById } from '../redux/actions/actionCreators'
import AppAdvancedModelForm from '../views/Forms/AppAdvancedModelForm'
import AppDetailsForm from '../views/Forms/AppDetailsForm'
import AppKnowledgebaseForm from '../views/Forms/AppKnowledgebaseForm'
import AppPromptsForm from '../views/Forms/AppPromptsForm'
import useGenAiHooks from '../hooks/useGenAiHooks'
import styles from './EditApp.scss'

const EditApp = () => {
  const dispatch = useDispatch()
  const { openCustomApp } = useGenAiHooks()
  const { appId } = useParams()
  const selectedApp = useSelector(state => state.genAi.aiApp)
  // const isFetching = useSelector(state => state.genAiApps.isFetching)

  useEffect(() => {
    dispatch(getAppById(appId))
  }, [appId, dispatch])

  const updatedWhen = useMemo(() => {
    if (selectedApp && selectedApp.lastUpdatedWhen) {
      const lastSpaceIndex = selectedApp.lastUpdatedWhen.lastIndexOf(' ')

      const dateString = selectedApp.lastUpdatedWhen.substring(0, lastSpaceIndex - 6)
      const timeString = selectedApp.lastUpdatedWhen.substring(lastSpaceIndex - 6)
      return `${dateString} at ${timeString}`
    }
    return ''
  }, [selectedApp])

  const actionButtons = [{
    icon: <iconMultipleStars height="14" width="14" />,
    label: 'LAUNCH APP',
    onAction: () => {
      openCustomApp(selectedApp.appConfig.appId, selectedApp.appConfig.collection)
    },
  }]

  return (
    // isFetching || selectedApp._isFetching
    //   ? <Loading pageLoading />
    //   : (
    <>
      <Banner actions={actionButtons} name="Edit Custom GPT App" showLeftIcon={false} />
      <Container className={styles.genappContainer}>
        {selectedApp
          ? (
            <>
              <div className={styles.lastUpdatedContainer}>
                Last updated {updatedWhen} by {selectedApp.lastUpdatedBy}
              </div>
              <AppDetailsForm />
              <AppPromptsForm />
              <AppKnowledgebaseForm />
              <AppAdvancedModelForm />
            </>
          ) : null}
      </Container>
    </>
    // )
  )
}

export default EditApp
