import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Heading, Button } from '@deloitte/gel-library'
import { redirect } from 'actions/app'
import {
  executeJob, SaveParameters,
} from 'actions/job'
import EditParameters from 'views/Create/EditParameters'
import Messagebar from 'components/Messagebar'
import { isTacTClient } from 'utils/permissions'
import { commaFormatNumber } from 'utils/numbers'
import { useSelector } from 'react-redux'
import constants from 'constants'
import styles from './InputData.scss'
import RunAnalysisWarningDialog from './components/RunAnalysisWarningDialog'
import JobDetailReview from './components/JobDetailsReview'
import SupportingDocuments from './SupportingDocuments'

const UploadedData = ({ tables }) => {
  const errorCount = tables
    .map(x => x.filesWithErrorsCount)
    .reduce((a, b) => a + b, 0)

  return (
    <div>
      {errorCount > 0 && (
        <Messagebar className={styles.messageBar} type="warn-grey">
          You have unresolved data errors
          <div className={styles.warning}>{`${errorCount} files with warnings`}</div>
        </Messagebar>
      )}
      <ul>
        {tables.map(t => (
          <li key={t.tableCode}>
            {`${t.tableName} (${commaFormatNumber(t.rowCount)} rows)`}
          </li>
        ))}
      </ul>
      <Messagebar type="info">
        Additional data checks will be performed as part of the analysis
      </Messagebar>
    </div>
  )
}

UploadedData.propTypes = {
  tables: PropTypes.arrayOf(PropTypes.shape({
    filesWithErrorsCount: PropTypes.number,
    rowCount: PropTypes.number,
    tableName: PropTypes.string,
  })).isRequired,
}

const propTypes = {
  app: PropTypes.shape({
    user: PropTypes.object,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  packages: PropTypes.object,
  job: PropTypes.shape({
    _isRequestingExecute: PropTypes.bool,
    _isSettingParameters: PropTypes.bool,
    analyses: PropTypes.arrayOf(PropTypes.object),
    analysisPeriod: PropTypes.string,
    benchmarkAllowed: PropTypes.bool,
    canRun: PropTypes.bool,
    customClientCode: PropTypes.string,
    customEngagementCode: PropTypes.string,
    engagementCode: PropTypes.string,
    hasCurrentActions: PropTypes.bool,
    jobId: PropTypes.string,
    name: PropTypes.string,
    noOfRuns: PropTypes.number,
    packageName: PropTypes.string,
    packageParameters: PropTypes.arrayOf(PropTypes.object),
    tables: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
}

const Review = (props) => {
  const [isWarningVisible, setIsWarningVisible] = useState(false)
  const [isFormValid, setIsFormValid] = useState(false)
  const FORM_ID = constants.FORM_SETUP
  const forms = useSelector(state => state.forms)
  const currentForm = forms[FORM_ID]

  const checkValidity = useCallback(() => {
    if (currentForm) {
      // Remove flag fields that aren't form fields from array.
      const filteredArray = Object.fromEntries(
        Object.entries(currentForm).filter(([_, value]) => typeof value !== 'boolean'),
      )
      // Map out only valid and value fields to enable validity check below.
      const validityArray = Object.entries(filteredArray).map(([key, value]) => ({
        key,
        fieldValue: value.value,
        isOptional: value.meta.parameter.isOptional,
        isValid: value.valid,
      }))
      // All fields are currently mandatory, so checking to make sure they all have a value as well as are valid.
      return validityArray.every((field) => {
        if (field.isValid) {
          if (!field.isOptional) {
            return field.fieldValue !== ''
          }
          return true
        }
        return false
      })
    }
    return true
  }, [currentForm])

  useEffect(() => {
    setIsFormValid(checkValidity)
  }, [checkValidity])

  const { job, dispatch, packages, app: { user: { isExternal } } } = props
  const { jobId, noOfRuns, hasCurrentActions } = job

  const jobHasParameters = job.analyses.some(a => a.parameters && a.parameters.length > 0)
    || (job.packageParameters && job.packageParameters.length > 0)

  const startJobExecution = () => {
    dispatch(SaveParameters(() => dispatch(executeJob(jobId))))
  }

  const handleSubmit = () => {
    if (noOfRuns > 0) {
      setIsWarningVisible(true)
    } else {
      startJobExecution()
    }
  }


  const effectivePackageKey = job.packageId ? job.packageId : job.packageValue;
  const additionalFeatures = packages.packages[effectivePackageKey]?.meta?.additionalFeatures ?? {};
  const hasSupportFileUploader = packages.packages[effectivePackageKey]?.meta?.additionalFeatures?.['has_support_file_uploader'] ?? false;

  let analysisNames;
  if ('support_file_tags' in additionalFeatures) {
    analysisNames = additionalFeatures?.['support_file_tags'];
  } else {
    analysisNames = job.analyses.map(a => a.name)
  }

  return (
    <div className={styles.base}>
      <Heading level={4}>Final review</Heading>

      <JobDetailReview isExternal={isExternal} job={job} />

      {jobHasParameters && (
        <EditParameters
          dispatch={dispatch}
        />
      )}

      {hasSupportFileUploader
        ? <SupportingDocuments analysisNames={analysisNames} />
        : <></>
      }

      <div className={styles.footer}>
        <Button
          mode="flat"
          onClick={() => {
            dispatch(redirect(`/create/${job.jobId}/dataquality`))
          }}
        >
          back
        </Button>
        <div>
          <Button
            disabled={
              !job.canRun
              || !isFormValid
              || job._isSettingParameters
              || job._isRequestingExecute
              || isTacTClient(isExternal, job.packageId)
            }
            onClick={handleSubmit}
            type="primary"
          >
            RUN ANALYSIS
          </Button>
        </div>
      </div>

      <RunAnalysisWarningDialog
        disabled={
          !job.canRun
          || !isFormValid
          || job._isSettingParameters
          || job._isRequestingExecute
        }
        hasCurrentActions={hasCurrentActions}
        isOpen={isWarningVisible}
        onAction={startJobExecution}
        onDismiss={() => setIsWarningVisible(false)}
      />
    </div>
  )
}

Review.propTypes = propTypes

export default Review
