import React, { useState, useRef, useEffect } from 'react'
import iconMultipleStars from 'images/iconMultipleStars.svg'
import useAIBot from 'features/aiAssistant/hooks/useAIBot'
import styles from './AiBot.scss'

function AIBot() {
  const [isExpanded, setIsExpanded] = useState(false)
  const [isInputVisible, setIsInputVisible] = useState(true)
  const searchInputRef = useRef(null)

  const {
    inputValue,
    setInputValue,
    apiResult,
    handleSearch: aiHandleSearch,
    loading,
    error,
  } = useAIBot()

  const handleSearch = async () => {
    await aiHandleSearch() // async call
    setIsExpanded(true)
  }

  const toggleInputVisibility = () => {
    setIsInputVisible(!isInputVisible)
  }

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === ' ' && document.activeElement !== searchInputRef.current) {
        e.preventDefault()
        searchInputRef.current.focus()
      }
    }
    document.addEventListener('keydown', handleKeyPress)
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [])

  return (
    <div className={styles.expandableContainer}>
      <div className={styles.expandable}>
        <div className={`${styles.botHeader} ${isInputVisible ? '' : styles.hide}`}>
          <div className={styles.botTitle}>
            <img alt="Star Icon" src={iconMultipleStars} style={{ width: '24px', margin: '0 8px 0 0', verticalAlign: 'sub' }} />
            Got questions? Ask our AI.
          </div>
          <a className={styles.toggleButton} onClick={toggleInputVisibility} type="button">
            {isInputVisible ? 'HIDE' : 'SHOW'}
          </a>
        </div>

        {isInputVisible && (
          <div className={styles.inputContainer}>
            <form onSubmit={(e) => { e.preventDefault(); handleSearch() }} style={{ width: '100%' }}>
              <div className={styles.inputGroup}>
                <input
                  className={styles.searchInput}
                  onChange={e => setInputValue(e.target.value)}
                  placeholder="Where can I change information on my account?"
                  ref={searchInputRef}
                  type="text"
                  value={inputValue}
                />
                {loading ? (
                  <div className={styles.dotContainer}><div className={styles.dotTyping} /></div>
                ) : (
                  <button className={styles.searchButton} type="submit">
                    ⬇
                  </button>
                )}
              </div>
            </form>

            <div className={`${styles.inputContent} ${isExpanded ? styles.expanded : ''}`}>
              {error ? (
                <div className={styles.responseContainer}>
                  <span>{error}</span>
                </div>
              ) : (
                <>
                  <div className={styles.responseContainer}>
                    <span>{apiResult?.response}</span>
                  </div>
                  { apiResult?.sources && <span style={{ fontWeight: '600', marginTop: '40px' }}>Related materials</span> }
                  <div className={styles.relatedMaterialsContainer}>
                    <ul style={{ margin: '0' }}>
                      {apiResult?.sources?.map(source => (
                        <li>
                          <a href="#/support/using-the-app">{source}</a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default AIBot
