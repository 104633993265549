import React from 'react'
import PropTypes from 'prop-types'
import PowerBIReport from 'components/PowerBIReport'

const propTypes = {
  height: PropTypes.number.isRequired,
  report: PropTypes.shape({
    attributes: PropTypes.object,
    heightPixels: PropTypes.number,
    type: PropTypes.string,
    viewUrl: PropTypes.string,
    widthPixels: PropTypes.number,
  }).isRequired,
  width: PropTypes.number.isRequired,
}

function EmbeddedReport({ report, width, height }) {
  switch (report.type) {
    case 'tableau':
    case 'hosted':
      return (
        <iframe
          height={height}
          src={report.viewUrl}
          style={{
            height,
            width,
          }}
          title="Report"
          width={width}
        />
      )
    case 'powerbi': {
      return (
        <PowerBIReport
          accessToken={report.attributes.accessToken}
          embedUrl={report.viewUrl}
          filterPaneEnabled={false}
          height={height}
          reportId={report.attributes.reportId}
          width={width}
        />
      )
    }
    default:
      return null
  }
}

EmbeddedReport.propTypes = propTypes

export default EmbeddedReport
