import { isArray } from 'lodash'

/**
 * Parses JSON table variable data and adds a key for use identifying rows
 *
 * @param {*} tableData
 * @returns
 */
export const processTableVariableData = (tableData) => {
  try {
    const parsedData = JSON.parse(tableData)
    if (!isArray(parsedData)) {
      return []
    }

    return parsedData.map((d, i) => ({
      ...d,
      key: i,
    }))
  } catch (error) {
    return []
  }
}

const FORM_SCHEMA = {
  formFields: [],
  data: {},
}

/**
 * Takes a job and returns parameter information to pass to forms
 *
 * @param {*} job
 * @returns
 */
export const schemaWithTests = (job, category = null, analysis = null) => {
  const formFields = []
  const testData = {}

  const mapParameterToField = (parameter, paramType) => {
    const validators = []

    if (parameter.type !== 'TABLE') {
      // TODO: Remove when we define optional parameters
      validators.push({
        priority: 1,
        check: 'required',
      })
    }

    if (parameter.type === 'INT') {
      validators.push({
        priority: 2,
        check: 'intOnly',
        error: 'Enter a whole or round number',
      })
    }

    if (parameter.type === 'FLOAT') {
      validators.push({
        priority: 2,
        check: 'floatOnly',
        error: 'Enter a numeric value',
      })
    }

    if (parameter.type === 'DATE') {
      validators.push({
        priority: 2,
        check: 'datefield',
        error: 'Enter a valid date',
      })
    }

    if (parameter.type === 'TABLE') {
      validators.push({
        priority: 2,
        check: 'tableCheck',
        error: 'Table contains invalid data',
        options: { schema: parameter.schema },
      })
    }

    if (parameter.max && parameter.min) {
      validators.push({
        priority: 3,
        check: 'numberRange',
        error: `Enter values between ${parameter.min} and ${parameter.max}`,
        options: {
          min: parseFloat(parameter.min),
          max: parseFloat(parameter.max),
        },
      })
    } else {
      if (parameter.min) {
        validators.push({
          priority: 4,
          check: 'numberRange',
          error: `Enter a value greater than or equal to ${parameter.min}`,
          options: {
            min: parseFloat(parameter.min),
          },
        })
      }

      if (parameter.max) {
        validators.push({
          priority: 5,
          check: 'numberRange',
          error: `Enter a value less than or equal to ${parameter.max}`,
          options: {
            max: parseFloat(parameter.max),
          },
        })
      }
    }
    formFields.push({
      label: parameter.name,
      description: parameter.description,
      type: parameter.type,
      options: parameter.options,
      name: `${parameter.id}`,
      meta: { parameter },
      validators,
      parameterType: paramType,
    })

    testData[`${parameter.id}`] = job[`${parameter.id}`] || parameter.type === 'BIT'
      ? parameter.value === '1'
      : parameter.value
  }

  if (category === null) {
    job.packageParameters.forEach(parameter => mapParameterToField(parameter, 'package'))

    job.analyses.forEach((test, index) => {
      if (test.parameters) {
        test.parameters.forEach(parameter => mapParameterToField(parameter, 'analysis'))
      }
    })
  } else {
    if (analysis?.parameters) {
      analysis.parameters.filter(p => p.category === category)
    }
  }
  return {
    formFields,
    data: Object.assign({}, FORM_SCHEMA.data, testData),
  }
}
