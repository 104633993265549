/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconManageSubs = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--managesubs',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104 104"{...rest}><g fill="none" fillRule="evenodd" strokeWidth="2.2" transform="translate(2 2)"><path className="path1" stroke="#86BC25" d="M33.527 38.574c0-1.129.934-2.042 2.086-2.042h29.202c1.153 0 2.086.913 2.086 2.042m-6.257-8.208h-20.86c-1.151 0-2.086.915-2.086 2.042m25.032 0c0-1.127-.934-2.042-2.086-2.042m10.43 16.415v16.332c0 2.246-1.877 4.084-4.173 4.084H33.527c-2.294 0-4.172-1.838-4.172-4.084V46.78c0-2.246 1.878-4.082 4.172-4.082H66.9c2.296 0 4.172 1.836 4.172 4.082z" strokeLinecap="round" strokeLinejoin="round"/><circle cx="50" cy="50" r="50" stroke="#86BD24"/></g></IconBase>
  )
}

IconManageSubs.propTypes = {
  className: PropTypes.string,
}

export default IconManageSubs
/* eslint-enable */
