import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Heading, Card, Accordion, Button,
} from '@deloitte/gel-library'
import some from 'lodash/some'
import constants from 'constants'
import {
  formInit, formUpdate, formDestroy,
} from 'actions/forms'
import { sortByFunction } from 'utils/arrays'
import {
  requiredFields,
  handleError,
  validateField,
  getSerializedFormState,
} from 'utils/validators'
import { schemaWithTests } from 'utils/business/parameters'
import Messagebar from 'components/Messagebar'
import {
  getCurrentDisplayValues,
  isConditionallyDisabledFromParameterDisplay,
  isConditionallyDisabledFromParameterValues,
} from 'actionHub/utils/dependencies'
import {
  SaveParameters, updateJob, SavePackageParameters, updateSolutionParameters,
} from 'actions/job'
import { getAllParameters } from 'utils/business/jobs'
import ErrorOutlinedIcon from '@material-ui/icons/ErrorOutlined'
import { isEqual } from 'lodash'
import styles from './InputData.scss'
import Parameter from './components/Parameter'

const FORM_ID = constants.FORM_SETUP

class EditParameters extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    forms: PropTypes.object.isRequired,
    job: PropTypes.shape({
      _isSettingCategoryParameters: PropTypes.bool,
      _isSettingSolutionParameters: PropTypes.bool,
      analyses: PropTypes.arrayOf(PropTypes.object),
      packageParameters: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
    app: PropTypes.object.isRequired,
  }

  static defaultProps = {
    disabled: false,
  }

  state = {
    expandedKey: '',
    originalAnalysesParameterFields: [],
    originalSolutionParameterFields: [],
    isDirty: false,
    isSolutionParamDirty: {},
  }

  componentDidMount() {
    const { dispatch, job } = this.props
    this.updateJobParametersDisplayStatus(getAllParameters(job))
    dispatch(formInit(FORM_ID, schemaWithTests(job)))
    this.mapAnalysesOriginalFieldAndValue()
  }

  componentWillUnmount() {
    const { dispatch } = this.props
    dispatch(formDestroy(FORM_ID))
  }

  mapAnalysesOriginalFieldAndValue = () => {
    const { job } = this.props
    const originalAnalysesParameterFields = []
    job.analyses.map(a => a.parameters?.map(ap => originalAnalysesParameterFields.push({
      type: ap.type, id: ap.id, category: ap.category, originalValue: ap.value, analysisId: a.id,
    })))
    this.setState({ originalAnalysesParameterFields })
    this.setState({ isDirty: false })

    const originalSolutionParameterFields = []
    job.packageParameters.map(ap => originalSolutionParameterFields.push({
      type: ap.type, id: ap.id, originalValue: ap.value,
    }))
    this.setState({ originalSolutionParameterFields })
    this.setState({ isSolutionParamDirty: [] })
  }

  handleAccordianClick=(key) => {
    const { expandedKey } = this.state
    if (expandedKey === key) {
      this.setState({
        expandedKey: '',
      })
    } else {
      this.setState({
        expandedKey: key,
      })
    }
  }

  getDirtyParameters = (analysis, key) => {
    const { originalAnalysesParameterFields } = this.state
    const { forms } = this.props
    const formEntries = Object.entries(forms[FORM_ID])
    const originalFieldsToCheck = originalAnalysesParameterFields.filter(oapr => oapr.analysisId === analysis.id && oapr.category === key)
    const formFieldsToCheck = formEntries.filter(([formKey]) => !formKey.startsWith('_') && originalFieldsToCheck.map(o => o.id).includes(formKey))
    const updatedFormFielsToCheck = formFieldsToCheck.map(([k, v]) => {
      return { id: k, newValue: v.value }
    })

    return originalFieldsToCheck
      .filter(o => !isEqual(updatedFormFielsToCheck.find(u => u.id === o.id).newValue, o.originalValue))
      .map(fo => ({ ...fo, newValue: updatedFormFielsToCheck.find(uff => uff.id === fo.id).newValue }))
  }

  renderPiller = (test, dictionary, form, formKeys) => {
    const { expandedKey, isDirty } = this.state
    const {
      disabled, job, forms, dispatch,
    } = this.props
    const { analyses, packageParameters } = job
    const selectedAnalyses = analyses.filter(a => a.selected)

    return (
      <div>
        { Object.keys(dictionary).map((key) => {
          const fields = dictionary[key].map(p => ({
            ...p,
            formKey: `${p.id}`,
          }))
            .filter(x => formKeys.includes(x.formKey))
          const showErrorMsg = fields.some(field => !forms[FORM_ID][field.id].valid)
          return (
            <Accordion
              className={styles.accordion}
              expanded={expandedKey === key}
              heading={(
                <div className={styles.accHeading}>
                  <div className={styles.PillerName}>{key}</div>
                  {showErrorMsg && (
                    <div className={styles.errorMsg}>
                      <ErrorOutlinedIcon className={styles.failedReport} />
                      <div className={styles.errorMsgTxt}>Errors found in this pillar</div>
                    </div>
                  )}
                </div>
              )}
              key={key}
              onChange={() => this.handleAccordianClick(key)}
            >
              <div className={styles.withScroll}>
                {fields.map((field) => {
                  if (field.display && !isConditionallyDisabledFromParameterDisplay(field, getCurrentDisplayValues(selectedAnalyses, packageParameters))) {
                    return (
                      <Parameter
                        dataTestId={field.id}
                        disabled={disabled}
                        field={field}
                        formData={form[field.formKey]}
                        index={test.parameters.findIndex(p => p.id === field.id)}
                        key={field.id}
                        onChange={(field.type === 'TABLE'
                          || field.type === 'dropdown'
                          || field.type === 'COMBO BOX'
                          || field.type === 'BIT'
                          || field.type === 'boolean'
                          || field.type === 'date'
                          || field.type === 'DATE') ? (fieldId, value) => this.handleChange(field.type, fieldId, value) : () => { }}
                        onFocus={this.handleFocus}
                        onValidation={(fieldId, value, context) => this.handleValidation(field.type, fieldId, value, context)}
                      />
                    )
                  }
                  return null
                })}
                <div className={styles.saveButton}>
                  <Button
                    className={styles.save}
                    disabled={!isDirty || job._isSettingCategoryParameters || showErrorMsg}
                    mode="secondary"
                    onClick={() => dispatch(SaveParameters(() => this.mapAnalysesOriginalFieldAndValue(), this.getDirtyParameters(test, key), key, test))}
                  >
                    {job._isSettingCategoryParameters ? 'SAVING ...' : 'SAVE'}
                  </Button>
                </div>
              </div>
            </Accordion>
          )
        })}
      </div>
    )
  }

  renderTest = ({ test, index }, form, formKeys) => {
    const { disabled, job } = this.props
    const { analyses, packageParameters } = job
    const selectedAnalyses = analyses.filter(a => a.selected)
    const fieldsWithNoCategory = test.parameters
      .filter(p => p.category === null)
      .sort(sortByFunction(x => x.displayOrder))
      .map(p => ({
        ...p,
        formKey: `${p.id}`,
      }))
      .filter(x => formKeys.includes(x.formKey))

    const fieldsToShow = fieldsWithNoCategory

    const categories = test.parameters.filter(p => p.category !== null).map(p => p.category)
    const uniqueCategories = [...new Set(categories)]
    const dictionary = {}
    uniqueCategories.forEach((uc) => {
      dictionary[uc] = test.parameters.filter(p => p.category === uc).sort(sortByFunction(x => x.displayOrder))
    })

    return (
      <div className={styles.test} key={test.name}>
        <div className="row">
          <div className="col-xs-12 col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8">
            <div className={index > 0 ? styles.testSection : null}>
              <div>
                <Heading className={styles.testHeading} level={7}>
                  {test.name}
                </Heading>
                {fieldsToShow.map((field, i) => {
                  if (field.display && !isConditionallyDisabledFromParameterDisplay(field, getCurrentDisplayValues(selectedAnalyses, packageParameters))) {
                    return (
                      <Parameter
                        dataTestId={field.id}
                        disabled={disabled}
                        field={field}
                        formData={form[field.formKey]}
                        index={i}
                        key={field.id}
                        onChange={(field.type === 'TABLE'
                          || field.type === 'dropdown'
                          || field.type === 'COMBO BOX'
                          || field.type === 'BIT'
                          || field.type === 'boolean'
                          || field.type === 'date'
                          || field.type === 'DATE') ? (fieldId, value) => this.handleChange(field.type, fieldId, value) : () => { }}
                        onFocus={this.handleFocus}
                        onValidation={(fieldId, value, context) => this.handleValidation(field.type, fieldId, value, context)}
                      />
                    )
                  }
                  return null
                })}
                {this.renderPiller(test, dictionary, form, formKeys)}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  handleFocus = (name) => {
    const { dispatch } = this.props

    // Create state value obj
    const stateValue = {
      field: name,
      data: {
        focus: true,
      },
    }

    dispatch(formUpdate(FORM_ID, stateValue))
  }

  checkIsDirtyCategoryAnalysis = (field, value) => {
    const { originalAnalysesParameterFields } = this.state
    const { forms } = this.props
    const formEntries = Object.entries(forms[FORM_ID])
    const originalField = originalAnalysesParameterFields.find(oapr => oapr.id === field)
    const originalFieldsToCheck = originalAnalysesParameterFields.filter(oapr => oapr.analysisId === originalField?.analysisId && oapr.category === originalField?.category)
    const formFieldsToCheck = formEntries.filter(([formKey]) => !formKey.startsWith('_') && originalFieldsToCheck.map(o => o.id).includes(formKey))
    const updatedFormFielsToCheck = formFieldsToCheck.map(([k, v]) => {
      return { id: k, newValue: field === k ? value : v.value }
    })
    this.setState({ isDirty: originalFieldsToCheck.some(o => !isEqual(updatedFormFielsToCheck.find(u => u.id === o.id).newValue, o.originalValue)) })
  }

  checkIsDirtySolutionParams = (field, value) => {
    const { originalSolutionParameterFields, isSolutionParamDirty } = this.state
    const formValues = this.props.forms[FORM_ID]
    const originalField = originalSolutionParameterFields.find(f => f.id === field)
    const isValueChanged = originalField ? !isEqual(value, originalField.originalValue) : false

    if (isValueChanged) {
      const updatedField = {
        id: field,
        originalValue: originalField.originalValue,
        updatedValue: value,
        type: originalField.type,
      }

      const updatedDirtyFields = isSolutionParamDirty.some(f => f.id === field)
        ? isSolutionParamDirty.map(f => (f.id === field ? updatedField : f))
        : [...isSolutionParamDirty, updatedField]

      this.setState({ isSolutionParamDirty: updatedDirtyFields })
    }
  };

  handleChange = (type, field, value) => {
    const { dispatch, forms } = this.props
    const formEntries = Object.entries(forms[FORM_ID])
    const currentParameters = formEntries.filter(([formKey]) => !formKey.startsWith('_'))
    const updatedParameters = currentParameters.map(([, formValue]) => (
      `${formValue.meta.parameter.id}` === field
        ? { ...formValue, value }
        : formValue
    ))
    this.updateJobParametersDisplayStatus(updatedParameters)
    const stateValue = {
      data: {
        value,
        valid: true,
        error: '',
      },
      field,
    }
    dispatch(formUpdate(FORM_ID, stateValue))

    this.checkIsDirtyCategoryAnalysis(field, value)
    this.checkIsDirtySolutionParams(field, value)
  }

  handleValidation = (type, field, value, context) => {
    // remove this condition once all field types' latency issue is targeted and fixed.
    if (type !== 'TABLE'
      && type !== 'dropdown'
      && type !== 'COMBO BOX'
      && type !== 'BIT'
      && type !== 'boolean'
      && type !== 'date'
      && type !== 'DATE') {
      this.handleChange(type, field, value)
    }
    const {
      dispatch, forms, job,
    } = this.props
    const errorObj = validateField(
      requiredFields(schemaWithTests(job).formFields),
      field,
      value,
      forms[FORM_ID],
      context,
    )
    // If there is an error from the validation pass it to the error handler
    if (!errorObj.valid) {
      dispatch(formUpdate(FORM_ID, handleError(errorObj, field)))
    }
  }

  isConditionallyDisabledFromDependencies = (parameterInConcern, currentParameters) => {
    const reducedObjForCurrentParameters = {}
    currentParameters.map((cppv) => {
      const { parameter } = cppv.meta
      const { id } = parameter
      const { value } = cppv
      let sqlRepValue = value
      if (value === true) {
        sqlRepValue = '1'
      }
      if (value === false) { sqlRepValue = '0' }

      reducedObjForCurrentParameters[id] = { value: sqlRepValue, display: parameter.display }
      return (
        <div />
      )
    })
    return isConditionallyDisabledFromParameterValues(parameterInConcern, reducedObjForCurrentParameters)
  }

  updateJobParametersDisplayStatus = (currentParameters) => {
    const { dispatch, job } = this.props
    const { packageParameters, analyses } = job
    const selectedAnalyses = analyses.filter(a => a.selected)

    const newPackageParameters = packageParameters.map(pp => ({ ...pp, display: !this.isConditionallyDisabledFromDependencies(pp, currentParameters) }))
    const newAnalyses = selectedAnalyses.flatMap(a => a.parameters)
      .filter(p => p)
      .map(ap => ({ ...ap, display: !this.isConditionallyDisabledFromDependencies(ap, currentParameters) }))

    const updatedPackageParameters = packageParameters.map(pp => ({
      ...pp,
      display: newPackageParameters.find(npp => npp.id === pp.id).display,
    }))
    const updatedAnalyses = selectedAnalyses.map(a => ({
      ...a,
      parameters: a.parameters?.map(ap => ({ ...ap, display: newAnalyses.find(na => na.id === ap.id).display })),
    }))
    dispatch(updateJob({ packageParameters: updatedPackageParameters, analyses: updatedAnalyses }))
  }

  render() {
    const { job, forms } = this.props
    const { analyses, packageParameters } = job
    const form = forms[FORM_ID]
    if (!form) {
      return <div />
    }
    const formKeys = Object.keys(form)

    const selectedAnalyses = analyses.filter(a => a.selected)

    const currentDisplayValues = getCurrentDisplayValues(selectedAnalyses, packageParameters)

    const showSolutionErrorMsg = packageParameters.some((parameter) => {
      const formKey = `${parameter.id}`
      return form[formKey] && !form[formKey].valid
    })

    return (
      <div className={styles.base}>
        {job.packageParameters.length > 0
          && job.packageParameters.some(parameter => parameter.display && !isConditionallyDisabledFromParameterDisplay(parameter, currentDisplayValues))
          && (
            <div style={{ marginBottom: '15px' }}>
              <Heading className={styles.subtitle} level={4}>
                Adjust solution-wide parameters
              </Heading>
              <Card>
                <div className="row">
                  <div className="col-xs-12 col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8">
                    {job.packageParameters.map((parameter, index) => {
                      const formKey = `${parameter.id}`
                      if (parameter.display && !isConditionallyDisabledFromParameterDisplay(parameter, currentDisplayValues)) {
                        return (
                          <Parameter
                            dataTestId={parameter.id}
                            field={{
                              ...parameter,
                              formKey,
                            }}
                            formData={form[formKey]}
                            index={index}
                            key={formKey}
                            onChange={(parameter.type === 'TABLE'
                              || parameter.type === 'dropdown'
                              || parameter.type === 'COMBO BOX'
                              || parameter.type === 'BIT'
                              || parameter.type === 'boolean'
                              || parameter.type === 'date'
                              || parameter.type === 'DATE') ? (field, value) => this.handleChange(parameter.type, field, value) : () => { }}
                            onFocus={this.handleFocus}
                            onValidation={(field, value, context) => this.handleValidation(parameter.type, field, value, context)}
                          />
                        )
                      }
                      return null
                    })}
                    { job.packageId === 'it-tact' && (
                      <div className={styles.saveButton} style={{ marginBottom: '34px' }}>
                        <Button
                          className={styles.save}
                          disabled={Object.keys(this.state.isSolutionParamDirty).length === 0 || job._isSettingSolutionParameters || showSolutionErrorMsg}
                          mode="secondary"
                          onClick={() => this.props.dispatch(SavePackageParameters(() => this.mapAnalysesOriginalFieldAndValue(), this.state.isSolutionParamDirty, this.props.app.user.isExternal))}
                        >
                          {job._isSettingSolutionParameters ? 'SAVING ...' : 'SAVE'}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </Card>
            </div>
          )}
        {selectedAnalyses.length > 0
          && some(
            selectedAnalyses,
            test => test.parameters && test.parameters.length > 0,
          )
          && selectedAnalyses.some(a => a.parameters?.some(parameter => parameter.display && !isConditionallyDisabledFromParameterDisplay(parameter, currentDisplayValues)))
          && (
            <div>
              <Heading className={styles.subtitle} level={4}>
                Adjust analysis parameters
              </Heading>

              <Card noPadding>
                {selectedAnalyses
                  .sort(sortByFunction(x => x.displayOrder))
                  .map((test, index) => ({ index, test }))
                  .filter(
                    o => o.test.parameters && o.test.parameters.length > 0,
                  )
                  .map(o => this.renderTest(o, form, formKeys))}
              </Card>
            </div>
          )}

        {some(form, x => x.error) && (
          <Messagebar className={styles.errorsInParamsWarning} type="error">
            There are problems with the parameters you have chosen; please review and correct them above.
          </Messagebar>
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    forms: state.forms,
    job: state.job,
    app: state.app,
  }
}

export default connect(mapStateToProps)(EditParameters)

