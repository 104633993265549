/* Generated by ./script/iconify.js */
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import IconBase from './IconBase'

const IconArrowBackward = ({ className, ...rest }) => {
  const classes = classnames('icon', 'icon--arrowbackward', className)

  return (
    <IconBase
      className={classes}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g fill="none" fillRule="evenodd">
        <path className="path1" d="M-6-6h36v36H-6z" />
        <path
          className="path2 fill"
          d="M24 10.5H5.745l8.385-8.385L12 0 0 12l12 12 2.115-2.115-8.37-8.385H24z"
          fill="#FFF"
          fillRule="nonzero"
        />
      </g>
    </IconBase>
  )
}

IconArrowBackward.propTypes = {
  className: PropTypes.string,
}

IconArrowBackward.defaultProps = {
  className: null,
}

export default IconArrowBackward
