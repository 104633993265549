export const DIALOG_CREATE = 'DIALOG_CREATE'
export const DIALOG_SHOW = 'DIALOG_SHOW'
export const DIALOG_HIDE = 'DIALOG_HIDE'

export const dialogCreate = id => ({
  id,
  type: DIALOG_CREATE,
})

export const dialogShow = (id, options) => ({
  id,
  options,
  type: DIALOG_SHOW,
})

export const dialogHide = id => ({
  id,
  type: DIALOG_HIDE,
})
