/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconArrowUp = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--arrowup',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 8"{...rest}><g fill="none" fillRule="evenodd"><path className="path1" fill="#BDBDBD" fillRule="nonzero" d="M1.41 7.41L6 2.83l4.59 4.58L12 6 6 0 0 6z"/><path className="path2" d="M18 16H-6V-8h24z"/></g></IconBase>
  )
}

IconArrowUp.propTypes = {
  className: PropTypes.string,
}

export default IconArrowUp
/* eslint-enable */
