import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './Heading.scss'

const Heading = ({
  elementType, children, noPadding, noBottomPadding, noTopPadding, className, size,
}) => {
  const ElementType = elementType
  const classes = {
    [styles.noPadding]: noPadding,
    [styles.noBottomPadding]: noBottomPadding,
    [styles.noTopPadding]: noTopPadding,
  }

  return (
    <ElementType className={classnames(styles.base, classes, className, styles[size])}>
      {children}
    </ElementType>
  )
}

Heading.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  elementType: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  noBottomPadding: PropTypes.bool,
  noPadding: PropTypes.bool,
  noTopPadding: PropTypes.bool,
  size: PropTypes.oneOf(['joel', 'xSmall', 'small', 'medium', 'large', 'mega', 'massive', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'h7', 'h8']),
}

Heading.defaultProps = {
  children: null,
  className: null,
  elementType: 'h2',
  noBottomPadding: false,
  noPadding: false,
  noTopPadding: false,
  size: 'small',
}

export default Heading
