// Forms
export const FORM_CREATE_JOB = 'FORM_CREATE_JOB'
export const FORM_SETUP = 'FORM_SETUP'
export const FORM_MANAGE_JOB = 'FORM_MANAGE_JOB'
export const FORM_ADD_EXTERNAL_USER = 'FORM_ADD_EXTERNAL_USER'
export const FORM_CREATE_HOSTED_REPORT = 'FORM_CREATE_HOSTED_REPORT'
export const FORM_EDIT_HOSTED_REPORT = 'FORM_EDIT_HOSTED_REPORT'
export const FORM_SUPPORT_CONTACT = 'FORM_SUPPORT_CONTACT'
export const FORM_BANNER_SETTINGS = 'FORM_BANNER_SETTINGS'

// Modals
export const MODAL_UPLOADER = 'MODAL_UPLOADER'
export const MODAL_MANAGE_JOB = 'MODAL_MANAGE_JOB'
export const MODAL_ADD_EXTERNAL_USER = 'MODAL_ADD_EXTERNAL_USER'
export const MODAL_IMPORT_ACTIONS = 'MODAL_IMPORT_ACTIONS'
export const MODAL_CREATE_HOSTED_REPORT = 'MODAL_CREATE_HOSTED_REPORT'
export const MODAL_EDIT_HOSTED_REPORT = 'MODAL_EDIT_HOSTED_REPORT'
export const MODAL_USER_PERMISSIONS = 'MODAL_USER_PERMISSIONS'
export const MODAL_SOLUTION_SETTINGS = 'MODAL_SOLUTION_SETTINGS'
export const MODAL_DATA_SOURCE_SETTINGS = 'MODAL_DATA_SOURCE_SETTINGS'
export const MODAL_BANNER_SETTINGS = 'MODAL_BANNER_SETTINGS'
export const MODAL_MODIFY_USER_JOB_ROLE = 'MODAL_MODIFY_USER_JOB_ROLE'
export const MODAL_EDIT_JOB_NAME = 'MODAL_EDIT_JOB_NAME'
export const RE_ACTIVATE_JOB_MODAL = 'RE_ACTIVATE_JOB_MODAL'
export const MODAL_BULK_ACTION_UPDATES = 'MODAL_BULK_ACTION_UPDATES'
export const MODAL_GEN_AI_TEMPLATE_SELECT = 'MODAL_GEN_AI_TEMPLATE_SELECT'
export const MODAL_EDIT_TASK_DETAILS = 'MODAL_EDIT_TASK_DETAILS'
export const MODAL_ADD_TASK = 'MODAL_ADD_TASK'

// Dialogs
export const DIALOG_LEAVE_INPUTDATA = 'DIALOG_LEAVE_INPUTDATA'
export const DIALOG_REVERT_INPUTDATA = 'DIALOG_REVERT_INPUTDATA'
export const DIALOG_REVERT_ALL_INPUTDATA = 'DIALOG_REVERT_ALL_INPUTDATA'
