/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import constants from 'constants'
import Animate from 'components/Animate'
import Heading from 'components/Heading'
import Loading from 'components/Loading'
import TagInput from 'components/Form/components/TagInput'
import SubmitButton from 'components/SubmitButton'
import {
  requiredFields, handleErrors, validateAll, getSerializedFormState,
} from 'utils/validators'
import {
  formInit, formUpdate, formReset, formAddTag, formDeleteTag, getFormOptions,
} from 'actions/forms'
import { getMemberByQuery } from 'actions/job'
import OverlayModal from 'components/OverlayModal'
import { modalHide, modalCreate } from 'actions/modals'
import {
  isManagerRole, isPartnerRole, isEditableMember, isDefaultEngagement,
} from 'utils/business/jobs'

const styles = require('./ManageJobModal.scss')

const MODAL_ID = constants.MODAL_MANAGE_JOB
const FORM_ID = constants.FORM_MANAGE_JOB
const FORM_SCHEMA = {
  formFields: [
    {
      name: 'partner',
    },
    {
      name: 'manager',
    },
    {
      name: 'members',
      type: 'tagInput',
      requestOptions: true,
      validators: [
        {
          check: 'required',
        },
      ],
      error: 'Select member(s)',
    },
  ],
  data: {},
}

class ManageJobModal extends React.Component {
  static propTypes = {
    disable: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    forms: PropTypes.object.isRequired,
    job: PropTypes.object.isRequired,
    modals: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    userName: PropTypes.string,
  }

  static defaultProps = {
    disable: false,
    userName: null,
  }

  componentDidMount() {
    this.setFormMembers()
  }

  componentDidUpdate(prevProps) {
    const { job } = this.props
    // If there wasn't a job
    if (prevProps.job._isFetchingJob && !job._isFetchingJob) {
      this.setFormMembers()
    }
  }

  setFormMembers = () => {
    const { dispatch, job } = this.props

    FORM_SCHEMA.data = {
      partner: job.engagementPartner,
      manager: job.engagementManager,
      members: job.members.filter(x => isEditableMember(x.role.name)),
    }

    dispatch(formInit(FORM_ID, FORM_SCHEMA))
  }

  handleChange = (field, value) => {
    const stateValue = {
      data: {
        value,
        error: '',
        valid: true,
      },
      field,
    }

    this._handleState(stateValue)
  }

  _addTag = (name, value) => {
    const { dispatch } = this.props
    /*
      Create state value obj
      Reset error handling (Stops error check on focus changes)
    */
    const stateValue = {
      field: name,
      data: {
        value,
        error: '',
        valid: true,
      },
    }

    // Pass state value and handle state
    dispatch(formAddTag(FORM_ID, stateValue))
  }

  _deleteTag = (name, index) => {
    const { dispatch } = this.props
    dispatch(formDeleteTag(FORM_ID, name, index))
  }

  _handleFocus = (name) => {
    // Create state value obj
    const stateValue = {
      field: name,
      data: {
        focus: true,
      },
    }

    this._handleState(stateValue)
  }

  _handleValidation = (name) => {
    const stateValue = {
      field: name,
      data: {
        focus: false,
      },
    }

    this._handleState(stateValue)
  }

  _handleState = (stateValue) => {
    const { dispatch } = this.props

    dispatch(formUpdate(FORM_ID, stateValue))
  }

  _handleTagAutoComplete = (name, value) => {
    this._autoComplete(name, value)
  }

  _autoComplete = (name, value) => {
    const { dispatch, forms } = this.props

    const val = (typeof value === 'object') ? value.label : value
    if (val.length >= 3 && forms[FORM_ID][name].lastQuery !== val) {
      clearTimeout(this.userSearchTimeout)
      this.userSearchTimeout = setTimeout(() => {
        dispatch(getFormOptions(FORM_ID, name, val, getMemberByQuery))
      }, 600)
    }
  }

  handleSave = () => {
    const {
      dispatch, forms, job, userName, onSubmit,
    } = this.props
    const reqfields = requiredFields(FORM_SCHEMA.formFields)
    const errors = validateAll(reqfields, forms[FORM_ID])
    if (errors.length > 0) {
      handleErrors(errors).forEach(errorState => dispatch(formUpdate(FORM_ID, errorState)))
    } else {
      // You can not delete yourself
      const data = getSerializedFormState(forms[FORM_ID])

      const jobOwners = job.members.filter(x => isManagerRole(x.role.name) || isPartnerRole(x.role.name))

      if (
        jobOwners.some(u => u.userDetails.username.toLowerCase() === userName.toLowerCase())
        || data.members.some(m => m.value.toLowerCase() === userName.toLowerCase())
      ) {
        onSubmit(FORM_ID, job.jobId || job.id, data.members)
      } else {
        dispatch(formUpdate(FORM_ID, {
          data: {
            focus: false,
            error: 'Please set yourself as a team member.',
            valid: false,
          },
          field: 'members',
        }))
      }
    }
  }

  render() {
    const {
      disable, modals, job, forms, dispatch,
    } = this.props
    const form = forms[FORM_ID]

    const _hide = () => {
      dispatch(modalHide(MODAL_ID))
      dispatch(formReset(FORM_ID))
    }

    const _init = () => {
      dispatch(modalCreate(MODAL_ID))
    }

    return (
      <OverlayModal
        base={10}
        disable={disable}
        id={MODAL_ID}
        init={_init}
        modals={modals}
        onClose={_hide}
        push={4}
      >
        <Animate name="fade">
          <div className={styles.content}>
            <Heading
              noPadding
              className={styles.title}
              size="large"
            >
              Add/update members
            </Heading>
            { job._isFetchingJob || !form
              ? <Loading pageLoading />
              : (
                <div>
                  <div className={styles.form}>
                    <Heading
                      noPadding
                      elementType="h3"
                      size="small"
                    >
                      Job details
                    </Heading>
                    <div className={styles.jobDetails}>
                      <label>
                        Name
                      </label>
                      <span>
                        {job.name}
                      </span>
                      {job.engagement.clientName && (
                        <>
                          <label>
                            Client
                          </label>
                          <span>
                            {job.engagement.clientName}
                          </span>
                        </>
                      )}
                      {!isDefaultEngagement(job.engagement.code) && (
                        <>
                          <label>
                            Engagement
                          </label>
                          <span>
                            {`${job.engagement.code} - ${job.engagement.name}`}
                          </span>
                        </>
                      )}
                      {job.packageName && (
                        <Fragment>
                          <label>
                            Package
                          </label>
                          <span>
                            {job.packageName}
                          </span>
                        </Fragment>
                      )}
                      {job.engagementPartner && (
                      <Fragment>
                        <label>
                          Engagement partner
                        </label>
                        <span>
                          {job.engagementPartner}
                        </span>
                      </Fragment>
                      )}
                      {job.engagementManager && (
                      <Fragment>
                        <label>
                          Engagement manager
                        </label>
                        <span>
                          {job.engagementManager}
                        </span>
                      </Fragment>
                      )}
                    </div>
                    <Heading
                      noPadding
                      elementType="h3"
                      size="small"
                    >
                      Job members
                    </Heading>
                    <TagInput
                      hasFetching
                      hideAvatars
                      autoselect={false}
                      collapseAmount={6}
                      error={form.members.error}
                      fetching={form.members._isFetching}
                      focus={form.members.focus}
                      handleAddition={this._addTag}
                      handleChange={this._handleTagAutoComplete}
                      handleDelete={this._deleteTag}
                      label="Enter practitioner name"
                      minQueryLength={3}
                      name="members"
                      onBlur={this._handleValidation}
                      onFocus={this._handleFocus}
                      suggestions={form.members.options}
                      tags={form.members.value}
                      valid={form.members.valid}
                    />
                  </div>
                  <SubmitButton
                    complete={form._isComplete}
                    onClick={this.handleSave}
                    submitting={form._isPosting}
                    type="primary"
                  >
                    save changes
                  </SubmitButton>
                </div>
              )}
          </div>
        </Animate>
      </OverlayModal>
    )
  }
}

export default ManageJobModal
