import * as ActionTypes from 'actions/app'

const initialState = {
  _isAcceptingTou: false,
  sandbox: false,
  showSandboxModal: false,
  bootstraping: false,
  showModal: false,
  notify: {
    active: false,
    message: '',
    alertType: 'accept',
    alert: false,
    timeout: 2800,
  },
  errorData: {},
  user: {
    firstName: '',
    surname: '',
    isAdmin: false,
    isAuOffice: false,
    isExternal: false,
    permissions: [],
    _isFetched: false,
  },
  avatars: {},
}

export default function job(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.APP_PROGRESS_MODAL:
      return Object.assign({}, state, {
        showModal: action.showModal,
      })
    case ActionTypes.APP_BOOTSTRAPPED:
      return Object.assign({}, state, {
        bootstraping: false,
      })
    case ActionTypes.APP_NOTIFY_ERROR:
      return Object.assign({}, state, {
        errorData: action.error.data,
      })
    case ActionTypes.APP_NOTIFY:
      return Object.assign({}, state, {
        notify: {
          active: true,
          alertType: action.alertType,
          message: action.message,
          alert: true,
          timeout: action.timeout,
        },
      })
    case ActionTypes.APP_CLEAR_NOTIFICATION:
      return Object.assign({}, state, {
        notify: {
          active: false,
        },
      })
    case ActionTypes.APP_UPDATE_USER:
      return Object.assign({}, state, {
        user: {
          ...state.user,
          ...action.user,
          _isFetched: true,
        },
      })
    case ActionTypes.APP_UPDATE_USER_PERMISSIONS:
      return Object.assign({}, state, {
        user: {
          ...state.user,
          permissions: action.permissions,
        },
      })
    case ActionTypes.APP_HIDE_SANDBOX_MODAL:
      return Object.assign({}, state, {
        showSandboxModal: false,
      })
    case ActionTypes.APP_UPDATE_ERROR:
      return {
        ...state,
        errorData: action.error,
      }
    case ActionTypes.APP_ACCEPTING_TOU:
      return {
        ...state,
        _acceptingTou: true,
      }
    case ActionTypes.APP_ACCEPTED_TOU:
      return {
        ...state,
        _isAcceptingTou: false,
        user: {
          ...state.user,
          hasAcceptedEula: true,
          _isAcceptingTou: true,
        },
      }

    case ActionTypes.APP_FETCHED_AVATAR:
      return {
        ...state,
        avatars: {
          ...state.avatars,
          [action.email]: {
            blobUrl: action.blobUrl,
            fetched: true,
          },
        },
      }
    default:
      return state
  }
}
