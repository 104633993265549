/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconLockInCircle = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--lockincircle',
    className,
  )

  return (
    <IconBase className={classes} viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg"{...rest}><title>Group</title><g transform="translate(1 1)" fill="none" fillRule="evenodd"><circle stroke="#979797" strokeWidth="2" cx="24" cy="24" r="24"/><path className="path1" d="M12 12h24v24H12z"/><path className="path2" d="M30 20h-1v-2c0-2.76-2.24-5-5-5s-5 2.24-5 5v2h-1c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V22c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9h-6.2v-2c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z" fill="#6D6D6D" fillRule="nonzero"/></g></IconBase>
  )
}

IconLockInCircle.propTypes = {
  className: PropTypes.string,
}

export default IconLockInCircle
/* eslint-enable */
