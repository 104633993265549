function fallbackCopyTextToClipboard(text, callback) {
  const textArea = document.createElement('textarea')
  textArea.value = text
  const child = document.body.appendChild(textArea)
  child.setAttribute('style', 'position: fixed; top: 0; left: 0;')
  // textArea.focus()
  textArea.select()

  try {
    const successful = document.execCommand('copy')
    callback(successful)
  } catch (err) {
    callback(false)
    console.error('Fallback: Oops, unable to copy', err)
  }

  document.body.removeChild(textArea)
}

export function copyTextToClipboard(text, callback) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text, callback)
    return
  }
  navigator.clipboard.writeText(text).then(
    // success
    () => {
      callback(true)
    },
    // error
    () => {
      callback(false)
      console.error('Could not copy text')
    },
  )
}
