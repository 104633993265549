import React, { useEffect, useState } from 'react'
import {
  addFileGroup, editFileGroup, fetchTargetSchemas, updatedDataPrepReqStatus,
} from 'actions/job'
import {
  Button,
  Checkbox, Heading,
} from '@deloitte/gel-library'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  TextField,
} from '@material-ui/core'
import IconClose from 'icons/IconClose'
import Messagebar from 'components/Messagebar'
import styles from './FileGroupModal.scss'

const propTypes = {
  existingFileGroupNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  fileGroup: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(['add', 'edit']).isRequired,
  open: PropTypes.bool.isRequired,
}

const defaultProps = {
  fileGroup: null,
}

const MenuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      maxHeight: '220px',
    },
  },
}

const FileGroupModal = ({
  open, handleClose, existingFileGroupNames, mode, fileGroup,
}) => {
  const [selectedTargetSchemas, setSelectedTargetSchemas] = useState([])
  const [fileGroupName, setFileGroupName] = useState('')
  const [fileGroupDesc, setFileGroupDesc] = useState('')
  const [fileGroupNameError, setFileGroupNameError] = useState(false)
  const dispatch = useDispatch()
  const {
    job: {
      isCustom, jobId, availableTargetSchemas, dataPreparationRequest: { id },
    },
  } = useSelector(state => state)

  useEffect(() => {
    if (open) {
      dispatch(fetchTargetSchemas())
      if (mode === 'edit') {
        setFileGroupName(fileGroup.name)
        setFileGroupDesc(fileGroup.description)
        setSelectedTargetSchemas(fileGroup.targetSchemas)
      }
    }
  }, [dispatch, jobId, open, fileGroup, mode])

  const handleChange = (event) => {
    const {
      target: { value },
    } = event
    setSelectedTargetSchemas(
      typeof value === 'string' ? value.split(',') : value,
    )
  }

  const handleFileGroupNameChange = (name) => {
    let usedFileGroupNames = existingFileGroupNames
    if (mode === 'edit') {
      usedFileGroupNames = existingFileGroupNames.filter(efg => efg !== fileGroup.name)
    } setFileGroupNameError(usedFileGroupNames.includes(name))
    setFileGroupName(name)
  }

  const onClose = () => {
    setFileGroupDesc('')
    setSelectedTargetSchemas([])
    setFileGroupName('')
    setFileGroupNameError(false)
    handleClose()
  }

  const onFileGroupAddEdit = () => {
    if (mode === 'add') {
      dispatch(addFileGroup(id, fileGroupDesc, fileGroupName, selectedTargetSchemas))
    } else {
      dispatch(editFileGroup(fileGroup.id, fileGroupDesc, fileGroupName, selectedTargetSchemas))
    }
    dispatch(updatedDataPrepReqStatus('Modified'))
    onClose()
  }

  return (
    <Modal
      open={open}
    >
      <div
        className={styles.fileGroupModal}
      >
        <div
          className={styles.closeIcon}
          onClick={() => onClose()}
        >
          <IconClose
            height="26"
            width="26"
          />
        </div>
        <div
          className={styles.fileGroupCreationInfo}
        >
          <Heading
            level={5}
          >
            {mode === 'add' ? 'Add ' : 'Edit '}
            file group
          </Heading>
          <div
            className={styles.fileGroupInputs}
          >
            <Messagebar type="info">
              File group name must be unique and the file group name and target data schema must be selected. If you are unsure of the target data schema, select &apos;I don&apos;t know&apos; and the Support Team will review.
            </Messagebar>
            <TextField
              fullWidth
              error={fileGroupNameError}
              helperText={fileGroupNameError ? 'This file group name exists' : null}
              label="File group name"
              onChange={event => handleFileGroupNameChange(event.target.value)}
              value={fileGroupName}
            />
            <FormControl
              fullWidth
              className={styles.targetSchemaForm}
            >
              {!isCustom && (
              <div>
                <InputLabel id="demo-simple-select-standard-label">Target data</InputLabel>
                <Select
                  displayEmpty
                  fullWidth
                  multiple
                  MenuProps={MenuProps}
                  labelId="demo-simple-select-standard-label"
                  onChange={handleChange}
                  renderValue={(selected) => {
                    return (
                      <div className={styles.selectedText}>
                        {selected.join(', ')}
                      </div>
                    )
                  }}
                  value={selectedTargetSchemas}
                >
                  {availableTargetSchemas.map(name => (
                    <MenuItem
                      className={styles.targetSchemaOption}
                      key={name}
                      value={name}
                    >
                      <Checkbox
                        checked={selectedTargetSchemas.indexOf(name) > -1}
                        onChange={e => handleChange(e)}
                      />
                      <ListItemText
                        className={styles.listItemText}
                        primary={name}
                      />
                    </MenuItem>
                  ))}
                </Select>
                </div>
              )}
            </FormControl>
            <TextField
              fullWidth
              multiline
              className={styles.fileGroupDesc}
              onChange={event => setFileGroupDesc(event.target.value)}
              placeholder="Description of the file group (optional)"
              rows="4"
              value={fileGroupDesc}
              variant="outlined"
            />
            <Button
              disabled={fileGroupName.length === 0
                || (!isCustom && selectedTargetSchemas.length === 0)
                || fileGroupNameError
                || (mode === 'edit' && fileGroup.name === fileGroupName && fileGroup.description === fileGroupDesc && (!isCustom && fileGroup.targetSchemas === selectedTargetSchemas))}
              onClick={() => onFileGroupAddEdit()}
            >
              {mode === 'add' ? 'ADD' : 'UPDATE'}
            </Button>
          </div>
        </div>

      </div>
    </Modal>
  )
}
FileGroupModal.propTypes = propTypes
FileGroupModal.defaultProps = defaultProps

export default FileGroupModal
