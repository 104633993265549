/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconHelp = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--help',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"{...rest}><g fill="none" fillRule="evenodd"><path className="path1" d="M0 0h24v24H0z"/><path className="path2" fill="#fff" fillRule="nonzero" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z"/></g></IconBase>
  )
}

IconHelp.propTypes = {
  className: PropTypes.string,
}

export default IconHelp
/* eslint-enable */
