import governmentIndustryImg from 'images/industries/government.jpg'
import manufacturingIndustryImg from 'images/industries/manufacturing.jpg'
import realEstateIndustryImg from 'images/industries/real-estate.jpg'
import wasteManagementIndustryImg from 'images/industries/waste-management.jpg'
import defaultIndustryImg from 'images/industries/default.jpg'

export const getIndustryImage = ((industry) => {
  switch (industry) {
    case 'government':
      return governmentIndustryImg
    case 'manufacturing':
      return manufacturingIndustryImg
    case 'waste-management':
      return wasteManagementIndustryImg
    case 'real-estate':
      return realEstateIndustryImg
    default:
      return defaultIndustryImg
  }
})
