import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js'

const reactPlugin = new ReactPlugin()
const ai = new ApplicationInsights({
  config: {
    enableAutoRouteTracking: true,
  },
})

export const initAppInsights = (key) => {
  ai.config.instrumentationKey = key
  ai.loadAppInsights()
}

export const addTelemetryCustomProperties = (properties) => {
  ai.addTelemetryInitializer((envelope) => {
    const telemetryItem = envelope.baseData
    telemetryItem.properties = {
      ...telemetryItem.properties,
      ...properties,
    }
  })
}

export const addUserDetailsTelemetryInitialiser = (user) => {
  addTelemetryCustomProperties({
    userId: user.id,
    country: user.country,
    businessUnit: user.businessUnit,
    department: user.department,
    title: user.title,
    isExternal: user.isExternal,
  })
}

export default Component => withAITracking(reactPlugin, Component)
export const { appInsights } = ai
