/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconReview = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--review',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 74 72"{...rest}><g fill="none" fillRule="evenodd"><path className="path1" d="M47.668 35.78a9.1 9.1 0 1 0 9.1 9.1 9.11 9.11 0 0 0-9.1-9.1zm4.472 6.767l-4.86 6a.749.749 0 0 1-.529.275h-.053a.75.75 0 0 1-.51-.2L43.63 46.25a.75.75 0 1 1 1.02-1.1l1.97 1.826 4.355-5.376a.75.75 0 0 1 1.165.945v.002z"/><circle cx="37" cy="36" r="36" fill="#FFF"/><path className="path2" fill="#86BC25" fillRule="nonzero" d="M37.211 0H37C17.118-.057.954 16.014.897 35.897.839 55.779 16.91 71.942 36.793 72H37c19.882.057 36.046-16.014 36.103-35.897C73.161 16.221 57.09.057 37.207 0h.004zM37 70.5h-.2C17.746 70.445 2.345 54.954 2.4 35.9 2.455 16.846 17.946 1.445 37 1.5h.2c19.054.055 34.455 15.546 34.4 34.6-.055 19.054-15.546 34.455-34.6 34.4z"/><path className="path3" fill="#86BC25" fillRule="nonzero" d="M34.225 48.637H23.973V23.178a.75.75 0 1 0-1.5 0v26.209c0 .414.336.75.75.75h11a.75.75 0 1 0 0-1.5h.002z"/><path className="path4" fill="#86BC25" fillRule="nonzero" d="M27.452 45.909H34.1a.75.75 0 1 0 0-1.5h-5.9V19.7h18.719v11.362a.75.75 0 1 0 1.5 0V18.951a.75.75 0 0 0-.75-.75H27.452a.75.75 0 0 0-.75.75v26.208c0 .414.336.75.75.75z"/><path className="path5" fill="#86BC25" fillRule="nonzero" d="M31.6 26.25a.75.75 0 1 0 0 1.5h11.922a.75.75 0 1 0 0-1.5H31.6zm12.672 5.86a.75.75 0 0 0-.75-.75H31.6a.75.75 0 1 0 0 1.5h11.922a.75.75 0 0 0 .75-.75zm-8.613 4.361H31.6a.75.75 0 1 0 0 1.5h4.061a.75.75 0 1 0 0-1.5h-.002zm12.009-2.191c-5.854 0-10.6 4.746-10.6 10.6 0 5.854 4.746 10.6 10.6 10.6 5.854 0 10.6-4.746 10.6-10.6-.007-5.851-4.749-10.593-10.6-10.6zm0 19.7a9.1 9.1 0 1 1 9.1-9.1 9.11 9.11 0 0 1-9.1 9.1z"/><path className="path6" fill="#86BC25" fillRule="nonzero" d="M50.975 41.6l-4.356 5.376-1.97-1.826a.75.75 0 0 0-1.02 1.1l2.558 2.371c.139.129.32.2.51.2h.053a.749.749 0 0 0 .529-.275l4.86-6a.75.75 0 0 0-1.165-.945l.001-.001z"/></g></IconBase>
  )
}

IconReview.propTypes = {
  className: PropTypes.string,
}

export default IconReview
/* eslint-enable */
