/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconError = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--error',
    className,
  )

  return (
    <IconBase className={classes} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"{...rest}><path className="path1" d="M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zM9.497 6.318v3.358a.68.68 0 0 0 .678.675.675.675 0 0 0 .679-.675V6.318a.68.68 0 0 0-.679-.675.675.675 0 0 0-.678.675zm.678 7.395c.562 0 1.018-.451 1.018-1.008 0-.557-.456-1.01-1.018-1.01s-1.017.453-1.017 1.01c0 .557.455 1.008 1.017 1.008z" fill="#E76C5E" fillRule="evenodd"/></IconBase>
  )
}

IconError.propTypes = {
  className: PropTypes.string,
}

export default IconError
/* eslint-enable */
