import React, { Fragment } from 'react'
import CardsSelect from 'components/CardsSelect'
import PropTypes from 'prop-types'
import { isNullOrUndefined } from 'utils/utils'
import { getConfig } from 'utils/config'
import { Heading, Button, TextField } from '@deloitte/gel-library'
import SubmitButton from 'components/SubmitButton'
import Spinner from 'components/Spinner'
import FilterSelectInput from 'components/FilterSelectInput'
import FileGroupDialog from 'components/FileGroupDialog'
import {
  IconAlert,
} from 'icons'
import DynamicTable from 'components/DynamicTable'
import Card from 'components/Card'
import {
  goToInputdata, getSampleDataJson, updateJob as update, changeSelectedSampleTable, getSampleDataDownload, updateJobRecipe,
  updateDataPrepRequiredChoice,
  updateFileGroups,
  updateDataPrep,
  removeDataPrepRequest,
  fetchAllFileGroups,
  launchWorkbench,
  postDataPreparationEmail,
} from 'actions/job'
import { redirect } from 'actions/app'
import timesaver from 'images/timesaver.svg'
import receivedDataSvg from 'images/receive-data.svg'
import submitDataSvg from 'images/submit-data.svg'
import requestSvg from 'images/data-download-request.svg'
import { getStaticFilePath, staticFiles } from 'utils/business/static'
import GetAppIcon from '@material-ui/icons/GetApp'
import { SUPPORT_PAGES } from 'constants/supportPages'
import { hot } from 'react-hot-loader/root'
import Messagebar from 'components/Messagebar'
import { sortByFunction } from 'utils/arrays'
import { DEFAULT_RECIPE_NAME } from 'utils/business/jobs'
import { GET_DATA_TEXT } from 'constants/text'
import Radio from 'components/Form/components/Radio'
import { Chip } from '@material-ui/core'
import FolderIcon from '@material-ui/icons/Folder'
import FileGroupModal from 'components/FileGroupModal'
import FileGroup from 'components/FileGroup'
import FileGroupDataProcessingModal from 'components/FileGroupDataProcessingModal'
import { uploadFileGroupFiles } from 'actions/file'
import DataInPrepPopUp from 'components/DataInPrepPopUp'
import { getDataRequestUrlByJob } from 'utils/api/file'

import styles from './Getdata.scss'

const standardOptions = [{
  value: DEFAULT_RECIPE_NAME,
  label: 'Standardised template',
  subLabel: 'Default',
}]

const MAX_FILE_NAME_DISPLAY_CHAR = 19

// will be used in for mv2 of client view

// const dataPreparationSteps = () => {
//   return [
//     {
//       title: 'Create file group(s)',
//       body: 'A file group is a container to upload files that will be combined and wrangled as required for the output. Create a file group for each analysis or target data set.',
//     },
//     {
//       title: 'Upload file(s)',
//       body: 'Upload files to appropriate file folder. Only .csv files or pipe, tab or semi-colon delimited text files (.txt, .tsv) are accepted, up to 50GB each.',
//     },
//     {
//       title: 'Submit request',
//       body: 'Once submitted a member of the Support Team will be in touch to discuss the request. Learn more about pricing and scheduling',
//     },
//   ]
// }

class Getdata extends React.Component {
      interval= null

  static propTypes = {
    app: PropTypes.shape({
      user: PropTypes.object,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    job: PropTypes.object.isRequired,
  }

  state = {
    open: false,
    dataPrepDesc: '',
    mode: 'add',
    fileGroupToEdit: null,
    showSubmitDataPrep: false,
    showFileDeletionConfirmation: false,
    showFileGroupDeletionConfirmation: false,
  }

  componentDidMount() {
    const { dispatch, job: { dataPreparationRequest } } = this.props
    this.fetchSampleData()
    if (dataPreparationRequest !== null) {
      this.setState({ dataPrepDesc: dataPreparationRequest.description })
      dispatch(fetchAllFileGroups(dataPreparationRequest.id))
    }
  }

  componentDidUpdate() {
    this.fetchSampleData()
  }

  componentWillUnmount() {
    const { dispatch } = this.props
    dispatch(update({ _isTestsChanged: false }))
  }

  handleSampleTableSelectChange = (selectedOption) => {
    const { dispatch } = this.props
    dispatch(changeSelectedSampleTable(selectedOption.value))
  }

  handleDownloadClick = () => {
    const { job, app: { user: { isExternal } } } = this.props
    const { jobId } = job
    const url = getDataRequestUrlByJob(jobId, isExternal)
    window.open(url, '_blank')
  }

  handleDownloadSampleData = () => {
    const { dispatch, job: { jobId } } = this.props
    dispatch(getSampleDataDownload({ jobId }))
  }

  handleViewServicesClick = () => {
    window.open(`${getConfig().URL_BASE}/#/dataprep`)
  }

  handleGuidelineClick = () => {
    window.open(getStaticFilePath(staticFiles.dataFormatGuidelines))
  }

  getCsvHelpLink = () => {
    return SUPPORT_PAGES.EXCEL_TO_CSV
  }

  handleBackClick = () => {
    const { dispatch, job } = this.props
    const { jobId } = job
    dispatch(redirect(`/create/${jobId}/selecttests`))
  }

  handleContinueClick = () => {
    const {
      dispatch, job: {
        jobId, isDataReady, isCustom, dataPreparationRequest,
      }, app: { user: { permissions: { dataPreparationAccess } } },
    } = this.props
    if (isCustom) {
      dispatch(launchWorkbench(jobId))
    } else if (dataPreparationAccess && !isDataReady && dataPreparationRequest?.status === 'Modified') {
      this.setState({ showSubmitDataPrep: true })
    } else {
      dispatch(goToInputdata({ jobId }))
    }
  }

  handleSelectRecipe = (recipe) => {
    const { dispatch, job } = this.props
    dispatch(updateJobRecipe(recipe))
    dispatch(getSampleDataJson(job.jobId, recipe))
  }

  fetchSampleData = () => {
    const {
      dispatch, job: {
        _isFetchingSampleDataJson, _isFetchedSampleDataJson, jobId,
      },
    } = this.props

    if (!_isFetchedSampleDataJson
      && !_isFetchingSampleDataJson
      && !isNullOrUndefined(jobId)
      && jobId !== '') {
      dispatch(getSampleDataJson(jobId))
    }
  }

  handleDataPrepChoice = (name, value) => {
    const { dispatch } = this.props
    dispatch(updateDataPrepRequiredChoice(value))
  }

  renderSampleDataOptions = () => {
    const {
      job: {
        recipes, selectedSampleTable, sampleDataJson,
      },
    } = this.props

    const sampleDataOptions = sampleDataJson.map((table, idx) => {
      return (
        {
          value: idx,
          label: (
            <span>
              {table.metaData.tableName}
              {table.metaData.recipeName
              && <span className={styles.recipeTag}>{recipes.find(r => r.name === table.metaData.recipeName)?.meta.displayName}</span>}
              {table.metaData.isOptional
                && <span className={styles.optionalChip}>Optional</span>}
            </span>
          ),
        }
      )
    })
    const selectedSampleDataOption = sampleDataOptions.find(o => o.value === selectedSampleTable)

    return (
      <FilterSelectInput
        handleChange={this.handleSampleTableSelectChange}
        options={sampleDataOptions}
        value={selectedSampleDataOption}
      />
    )
  }

  onFileGroupAdd = (fileGroupName, selectedTargetSchemas, fileGroupDesc) => {
    const { dispatch, job: { fileGroups } } = this.props
    dispatch(updateFileGroups([...fileGroups, {
      name: fileGroupName, targetSchemas: selectedTargetSchemas, description: fileGroupDesc, files: [],
    }]))
  }

  handleAddDataPrep = (description) => {
    const { dispatch, job: { jobId, isCustom, dataPreparationRequest } } = this.props
    if (isCustom && dataPreparationRequest === null) {
      dispatch(updateDataPrepRequiredChoice(false))
    }
    dispatch(updateDataPrep({ jobId, description }))
  }

  formatFileSize = (bytes) => {
    const sufixes = ['b', 'kb', 'mb', 'gb', 'tb']
    const i = Math.floor(Math.log(bytes) / Math.log(1024))
    return `${(bytes / (1024 ** i)).toFixed(2)} ${sufixes[i]}`
  }

  continueDataPrep = () => {
    const { dispatch } = this.props
    dispatch(updateDataPrepRequiredChoice(false))
  }

  removeDataPrep = () => {
    const { dispatch, job: { jobId, isDataReady } } = this.props
    dispatch(removeDataPrepRequest(jobId, isDataReady))
    dispatch(updateFileGroups([]))
    this.setState({ dataPrepDesc: '' })
  }

  decorateFileNameText = (fileNameText) => {
    const fileNameAndExtension = fileNameText.split('.')
    return fileNameAndExtension[0].length > MAX_FILE_NAME_DISPLAY_CHAR ? `${fileNameAndExtension[0].substring(0, MAX_FILE_NAME_DISPLAY_CHAR + 1)}....${fileNameAndExtension[1]}` : fileNameText
  }

  handleFileGroupEdit = (fileGroupToEdit) => {
    this.setState({ open: true, mode: 'edit', fileGroupToEdit })
  }

  handleLaunchWorkbench = () => {
    const { dispatch, job: { jobId } } = this.props
    dispatch(launchWorkbench(jobId))
  }

  sendDataPreparationEmail = () => {
    const { dispatch, job: { jobId } } = this.props
    dispatch(postDataPreparationEmail(jobId))
    this.setState({ showSubmitDataPrep: false })
  }

  uploadFiles = () => {
    const { dispatch, job: { jobId, fileGroupFilesUploadStatus } } = this.props
    const filesToUpload = fileGroupFilesUploadStatus.filter(fgu => fgu.status !== 'complete').map((f) => { return { fileGroup: f.fileGroup, file: f.file } })
    const formsData = filesToUpload.map(({ fileGroup, file }) => {
      const formData = new FormData()
      formData.append(
        'file',
        file, file.name,
      )
      formData.append('jobId', `${jobId}`)
      formData.append('tableCode', fileGroup.name)
      formData.append('qqfilename', file.name)
      formData.append('locationRef', 'Workbench')
      return formData
    })
    dispatch(uploadFileGroupFiles(formsData))
  }

  btnSubmit = () => {
    const { job: { isCustom, dataPreparationRequest } } = this.props
    if (isCustom) { return 'Save' }
    if (dataPreparationRequest !== null) {
      return 'UPDATE DATA PREP'
    }
    return 'START DATA PREP'
  }

  render() {
    const { job, app: { user: { isExternal, permissions: { dataPreparationAccess } } } } = this.props
    const {
      selectedSampleTable,
      sampleDataJson,
      _isSendingJob,
      _isFetchingSampleDataJson,
      _isSampleDataError,
      _isLoadingDataRequirements,
      recipes,
      recipeName: selectedRecipe,
      isDataReady,
      fileGroups,
      dataPreparationRequest,
      fileGroupFilesUploadStatus,
      _isUploadingFiles,
      _isLaunchingWorkbench,
      isCustom,
      jobId,
    } = job

    const {
      showSubmitDataPrep, open, dataPrepDesc, mode, fileGroupToEdit, showFileGroupDeletionConfirmation, showFileDeletionConfirmation,
    } = this.state

    const showRecipes = recipes.length !== 0
    const recipeOptions = recipes
      .slice()
      .sort(sortByFunction(x => x.meta.sortName || x.meta.displayName))
      .map((recipe) => {
        return {
          value: recipe.name,
          label: recipe.meta.displayName,
          subLabel: recipe.meta.description,
        }
      })

    return (
      <div className={styles.base}>
        {!isCustom
          && (
          <div>
            <Heading level={4}>Get data</Heading>

            <Heading level={8}>Data source</Heading>
            {showRecipes && (
            <Card style={{ paddingLeft: '36px', paddingTop: '60px' }}>
              <div className="row">
                <div className="col-md-9">
                  <Messagebar className={styles.azureWarning} type="info">
                    Your data template section will determine which files and columns you&apos;ll need to upload.
                    {' '}
                    <a
                      href={`/#${SUPPORT_PAGES.USING_DATA_TEMPLATES}`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Learn more
                    </a>
                  </Messagebar>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <Heading level={7}>Save time by using a supported data input template</Heading>
                  <p>
                    Download data requirements in common extraction formats for your client system. InsightBox™ will do the heavy lifting by transforming your client&apos;s data into our standard format.
                  </p>
                </div>
                <div className="col-md-4">
                  <div>
                    <img alt="Three documents and a clock indicating saving time." className={styles.sideImage} src={timesaver} />
                  </div>
                </div>
                <div className="col-md-8" style={{ marginTop: '-40px' }}>
                  <CardsSelect
                    onChange={this.handleSelectRecipe}
                    options={recipeOptions}
                    rowLength={3}
                    value={selectedRecipe}
                  />
                </div>
              </div>
              <div className="row"><div className="col-md-9"><div className={styles.subSection} /></div></div>
              <div className="row">
                <div className="col-md-8">
                  <p>Can&apos;t find a supported template? Use the default standardised template:</p>
                </div>
                <div className="col-md-8">
                  <CardsSelect
                    onChange={this.handleSelectRecipe}
                    options={standardOptions}
                    value={selectedRecipe}
                  />
                </div>
              </div>
            </Card>
            )}
          </div>
          )}
        {(!showRecipes || selectedRecipe || selectedRecipe === DEFAULT_RECIPE_NAME) && (
          <>
            {!isCustom
              && (
                <div>
                  <Card style={{ padding: '26px 36px' }}>
                    <div style={{ display: 'flex' }}>
                      <div>
                        <img alt="Two documents and an arrow indicating a download" className={styles.headerImage} src={requestSvg} />
                      </div>

                      <div>
                        <Heading>Generate your data requirements</Heading>
                        <Messagebar type="info-grey">You can view the form and save as a PDF if required (only supported in Google Chrome and Edge)</Messagebar>
                        <p>
                          The data request form outlines the data requirements to run your selected analyses.
                          <br />
                          {isExternal ? GET_DATA_TEXT.CLIENT_GENERATE_YOUR_DATA_REQUIREMENTS : GET_DATA_TEXT.DTT_GENERATE_YOUR_DATA_REQUIREMENTS}
                        </p>

                        <Button
                          className={styles.button}
                          disabled={_isSendingJob}
                          onClick={this.handleDownloadClick}
                        >
                          Generate
                        </Button>
                      </div>
                    </div>
                  </Card>
                  <Card noPadding>
                    <section className={styles.section}>

                      <div className="row">
                        <div className="col-md-8">

                          <Heading className={styles.subheading} level={7}>What can I expect to receive?</Heading>

                          <p>
                            The following sample data shows the appropriate input type for each column.
                            {' '}
                            {isExternal ? GET_DATA_TEXT.CLIENT_WHAT_CAN_I_EXPECT_TO_RECIEVE : GET_DATA_TEXT.DTT_WHAT_CAN_I_EXPECT_TO_RECIEVE}
                            {' '}
                            Some data files are labeled as
                            {' '}
                            <b>optional</b>
                            , including these data files will enhance the solution, but aren’t necessary to run the job. However, including these data files will enhance your
                            {' '}
                            {!isExternal && 'client\'s'}
                            {' '}
                            solution.
                          </p>

                          <p>
                            <Button
                              disabled={_isSendingJob}
                              icon={<GetAppIcon />}
                              mode="flat"
                              onClick={this.handleDownloadSampleData}
                            >
                              DOWNLOAD FULL SAMPLE DATA
                            </Button>
                          </p>

                        </div>
                        <div className="col-md-4">
                          <img alt="A document entering an envelope" className={styles.sideImage} src={receivedDataSvg} />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">

                          <div className={styles.sampleContainer}>

                            {_isFetchingSampleDataJson && (
                              <div className={styles.loadingContainer}>
                                <p>
                                  <Spinner />
                                  <br />
                                  <br />
                                  Loading sample preview...
                                </p>
                              </div>
                            )}

                            {!_isFetchingSampleDataJson && _isSampleDataError && (
                              <div className={styles.loadingContainer}>
                                <p>
                                  <IconAlert heigh="40" width="40" />
                                  <br />
                                  Error when loading sample data
                                </p>
                              </div>
                            )}

                            {sampleDataJson != null && !_isFetchingSampleDataJson && (

                              <div>
                                <div className={styles.filter}>
                                  {this.renderSampleDataOptions()}
                                </div>
                                <div className={styles.table}>
                                  <DynamicTable
                                    data={sampleDataJson[selectedSampleTable].data}
                                    headers={sampleDataJson[selectedSampleTable].metaData.fields
                                      .sort((a, b) => a.fieldOrder - b.fieldOrder)
                                      .map(x => ({ key: x.fieldKey, heading: x.fieldName, subHeading: x.fieldType }))}
                                  />
                                </div>
                              </div>
                            )}

                          </div>
                        </div>
                      </div>
                    </section>

                    <section className={styles.section}>
                      <div className="row">
                        <div className="col-md-8">

                          <Fragment>
                            <Heading level={7}>What file formats are accepted?</Heading>

                            <p>
                              We only support CSV and other text file formats (comma, tab, or pipe delimited).
                              All Excel files need to be converted to CSV format. Note that column order does not
                              need to follow the order specified in the requirements.
                            </p>
                          </Fragment>

                        </div>

                        <div className="col-md-4">
                          <img alt="A checklist with some items ticked" className={styles.sideImage} src={submitDataSvg} />
                        </div>

                      </div>
                    </section>

                  </Card>
                </div>
              )}
            {dataPreparationAccess
              && (
              <div>
                <div className={styles.prepareData}>
                  <Heading className={styles.prepareDataHead} level={4}>{isCustom ? 'Define custom solution' : 'Prepare data'}</Heading>
                  <Chip className={styles.prepareDataChip} label="New" />
                </div>
                <Card noPadding>
                  <section className={styles.section}>

                    <div className="row">
                      <div className="col-md-12">
                        {!isCustom && (
                        <Messagebar type="info">
                          The prepare data workflow will allow you to upload your data and submit a request for the Support Team to prepare your data to meet the requirements.
                          <br />
                          {/* <Link>Learn more</Link>
                          {' '}
                          about how how this work and expected processing time. */}
                        </Messagebar>
                        )}
                        <Heading className={styles.subheading} level={7}>{ isCustom ? 'Enter a short description of your custom solution' : 'Does your data meet the data requirements generated?'}</Heading>
                        {!isCustom && (
                        <p>
                          You have all mandatory data files, with required columns, in the acceptable file formats.
                        </p>
                        )}
                        {!isCustom && (
                        <Radio
                          horizontal
                          className={styles.radio}
                          name="DataReady"
                          onChange={(name, value) => this.handleDataPrepChoice(name, value)}
                          options={[
                            { label: 'Yes', value: true },
                            { label: 'No, start data preparation', value: false },
                          ]}
                          value={isDataReady}
                        />
                        )}
                        {((isDataReady !== null && !isDataReady) || (isCustom)) && (
                        <div className={styles.dataParepDescBtn}>
                          <TextField
                            fullWidth
                            multiline
                            className={styles.dataPrepDesc}
                            onInput={event => this.setState({ dataPrepDesc: event.target.value })}
                            placeholder={isCustom ? 'Enter solution description' : 'Add description of data preparation required'}
                            rows="4"
                            value={dataPrepDesc}
                            variant="outlined"
                          />
                          <Button
                            className={styles.dataParepBtn}
                            disabled={dataPrepDesc.length === 0 || dataPrepDesc === dataPreparationRequest?.description}
                            mode="secondary"
                            onClick={() => this.handleAddDataPrep(dataPrepDesc)}
                          >
                            {this.btnSubmit()}
                          </Button>
                        </div>
                        )}
                      </div>
                    </div>
                  </section>

                  {isDataReady !== null && !isDataReady && dataPreparationRequest !== null && (
                  <section className={styles.section}>
                    <div className="row">
                      <div className="col-md-12">
                        <Fragment>
                          <div className={styles.fileGroup}>
                            <div className={styles.fileGroupHeadNText}>
                              <Heading level={7}>Create file group</Heading>
                              <p className={styles.headerText}>
                                A
                                {' '}
                                <b>file group</b>
                                {' '}
                                is a folder to upload and store your files that will be submitted to the Support Team for preparation to meet the data requirements. Create file groups and upload files to the appropriate file group. Only .csv files, tab or semi-colon delimited text (.txt, .tsv) are accepted. File sizes should not exceed 50 GB for each file uploaded.
                              </p>
                            </div>
                            {fileGroups.length > 0 && (
                            <Button
                              className={styles.headerBtn}
                              mode="secondary"
                              onClick={() => this.setState({ open: true, mode: 'add' })}
                            >
                              + ADD FILE GROUP
                            </Button>
                            )}
                          </div>
                          {fileGroups.length === 0 ? (
                            <div className={styles.emptyFileGroup}>
                              <FolderIcon
                                className={styles.folderIcon}
                                fontSize="large"
                              />
                              <div className={styles.noOfFileGroups}>
                                {fileGroups.length}
                                {' '}
                                file groups
                              </div>
                              <div>Create your first file group to get started</div>
                              <div>
                                <Button
                                  className={styles.addFileGroup}
                                  mode="secondary"
                                  onClick={() => this.setState({ open: true, mode: 'add' })}
                                >
                                  + Create file group
                                </Button>
                              </div>
                            </div>
                          )
                            : (
                              <div>
                                {fileGroups.map((fg) => {
                                  return (
                                    <FileGroup
                                      fileGroup={fg}
                                      handleFileGroupEdit={() => this.handleFileGroupEdit(fg)}
                                      key={fg.id}
                                    />
                                  )
                                })}
                                {fileGroups.flatMap(fg => fg.files).length > 0 && (
                                <div className={styles.upload}>
                                  <Button
                                    disabled={fileGroupFilesUploadStatus.length === 0 || !fileGroupFilesUploadStatus.some(fgfus => fgfus.status !== 'complete')}
                                    mode="secondary"
                                    onClick={() => this.uploadFiles()}
                                  >
                                    Upload files
                                  </Button>
                                </div>
                                )}
                              </div>
                            )
                        }
                          <FileGroupModal
                            existingFileGroupNames={job.fileGroups.map(fg => fg.name)}
                            fileGroup={fileGroupToEdit}
                            handleClose={() => this.setState({ open: false })}
                            mode={mode}
                            open={open}
                          />
                        </Fragment>
                      </div>
                    </div>
                  </section>
                  )}
                </Card>
              </div>
              )}
          </>
        )}

        <div className={styles.footer}>
          <Button mode="flat" onClick={this.handleBackClick}>Back</Button>
          <div>
            <SubmitButton
              disabled={dataPreparationAccess && (_isSendingJob
                || isDataReady === null
                || (!isDataReady && dataPrepDesc.length === 0)
                || (!isDataReady && dataPreparationRequest === null)
                || (!isDataReady && dataPreparationRequest !== null && job.fileGroups.length === 0)
                || (!isDataReady && dataPreparationRequest !== null && (job.fileGroups.some(fg => fg.files?.length === 0) || fileGroupFilesUploadStatus.some(fgfus => fgfus.status !== 'complete'))))}
              onClick={this.handleContinueClick}
              submitting={_isLoadingDataRequirements}
              submittingText="CONTINUE"
              type="primary"
            >
              { isCustom ? 'LAUNCH WORKBENCH' : 'CONTINUE'}
            </SubmitButton>
          </div>
        </div>
        {dataPreparationRequest !== null && isDataReady
        && (
        <FileGroupDialog
          actionLabel="CONTINUE"
          dismissLabel="GO BACK"
          heading="Data preparation request and file groups will be deleted"
          onAction={() => this.removeDataPrep()}
          onDismiss={() => this.continueDataPrep()}
          text="If you have uploaded folders or files, these will be deleted if you continue with the flow with no data preparation."
        />
        )}
        {_isUploadingFiles && (
        <FileGroupDataProcessingModal
          heading="Uploading your data"
          spinning={_isUploadingFiles}
          spinningCompleteText="Upload complete"
          spinningText={`Uploading ${fileGroupFilesUploadStatus.filter(fg => fg.status !== 'complete').length} data files…`}
        />
        )}
        {_isLaunchingWorkbench
        && (
        <FileGroupDataProcessingModal
          heading="Launching workbench"
          spinning={_isLaunchingWorkbench}
          spinningCompleteText="Launched workbench"
          spinningText="Launching workbench..."
        />
        )}
        {showSubmitDataPrep && (
        <FileGroupDialog
          actionLabel="SEND"
          dismissLabel="GO BACK"
          heading="Submit data preparation"
          onAction={() => this.sendDataPreparationEmail()}
          onDismiss={() => this.setState({ showSubmitDataPrep: false })}
          text="Do you want to send data preparation request to the Support Team? Once submitted, a member of the Support Team will be in touch to discuss the request."
        />
        )}
        <DataInPrepPopUp />
      </div>
    )
  }
}

export default hot(Getdata)
