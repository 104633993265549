import React, { useEffect, useState } from 'react'
import { Select as MuiSelect, MenuItem } from '@material-ui/core'
import Label from 'components/Label'
import { useDispatch, useSelector } from 'react-redux'
import { putApp, updateAppDetailsForm } from 'features/genAi/redux/actions/actionCreators'
import Expander from 'views/Home/components/Expander'
import { Button, TextInput, ToggleInput } from '@deloitte/gel-library'
import styles from './GenAIAppCreationForms.scss'

const AppDetailsForm = () => {
  const dispatch = useDispatch()
  const initialState = useSelector(state => state.genAi.aiApp.appConfig)
  const [localFormData, setLocalFormData] = useState(initialState)

  useEffect(() => {
    setLocalFormData(initialState)
  }, [initialState])

  const onChange = (name, value) => {
    setLocalFormData(prevState => ({ ...prevState, [name]: value }))
  }

  const handleDropDownChange = (event, name) => {
    setLocalFormData(prevState => ({ ...prevState, [name]: event.target.value }))
  }

  const handleSectionSave = (event) => {
    event.preventDefault()
    event.stopPropagation()
    dispatch(updateAppDetailsForm(localFormData))
    dispatch(putApp())
  }

  return (
    <Expander
      startsExpanded
      className={styles.expander}
      classNameExpanded={styles.expanderExpanded}
      headerContent={(
        <div className={styles.headerBar}>
          <div className={styles.headerTitle}>
            <span className={styles.title}>Customise AI settings</span>
          </div>
          <div className={styles.saveSectionBtn}>
            <Button mode="flat" onClick={handleSectionSave}>Save</Button>
          </div>
        </div>
      )}
      key="appDetails"
    >
      <div className={styles.expandContentContainer}>
        <p>
          Basic app details and settings
        </p>
        <div className={styles.form}>
          <div className={styles.fieldRow}>
            <div className={styles.label}>
              <Label>Define name</Label>
            </div>
            <div className={styles.fieldContainer}>
              <TextInput
                InputProps={styles.underline}
                className={styles.field}
                id="name"
                maxLength={50}
                name="name"
                onChange={value => onChange('name', value)}
                placeholder="Untitled app"
                value={localFormData && localFormData.name ? localFormData.name : ''}
              />
            </div>
          </div>
          <div className={styles.fieldRow}>
            <div className={styles.label}>
              <Label>Add description</Label>
            </div>
            <div className={styles.fieldContainer}>
              <TextInput
                InputProps={styles.underline}
                className={styles.field}
                id="description"
                maxLength={100}
                name="description"
                onChange={value => onChange('description', value)}
                placeholder="Add description"
                value={localFormData && localFormData.description ? localFormData.description : ''}
              />
            </div>
          </div>

          <div className={styles.fieldRow}>
            <div className={styles.label}>
              <Label>Select app collection</Label>
            </div>
            <div className={styles.fieldContainer}>
              <MuiSelect
                autoWidth
                className={styles.dropdown}
                defaultValue="Audit & Accounting"
                id="collection"
                label="Collection"
                labelId="collection"
                onChange={event => handleDropDownChange(event, 'collection')}
                value={localFormData && localFormData.collection ? localFormData.collection : 'Audit & Accounting'}
              >
                <MenuItem key="Administration" value="Administration">Administration</MenuItem>
                <MenuItem key="Audit & Accounting" value="Audit & Accounting">Audit & Accounting</MenuItem>
                <MenuItem key="Deloitte Internal" value="Deloitte Internal">Deloitte Internal</MenuItem>
                <MenuItem key="Operations" value="Operations">Operations</MenuItem>
                <MenuItem key="Research" value="Research">Research</MenuItem>
                <MenuItem key="Technical" value="Technical">Technical</MenuItem>
              </MuiSelect>
            </div>
          </div>

          <div disabled className={styles.fieldRow}>
            <div className={styles.label}>
              <Label>Show in marketplace</Label>
            </div>
            <ToggleInput
              checked={localFormData && localFormData.isPublic ? localFormData.isPublic : false}
              name="IsPublic"
              onChange={() => onChange('isPublic', !localFormData.isPublic)}
            />
          </div>

        </div>
      </div>
    </Expander>
  )
}

export default AppDetailsForm
