import React from 'react'
import PropTypes from 'prop-types'
import { Heading } from '@deloitte/gel-library'
import Card from 'components/Card'
import { formatDateShort } from 'utils/dates'
import styles from './ClientJob.scss'

const propTypes = {
  job: PropTypes.shape({
    analysisPeriod: PropTypes.string,
    lastExecutionDate: PropTypes.object,
    name: PropTypes.string,
    tables: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
}

const ClientJobDetailsSideBar = ({ job }) => {
  return (
    <div>
      <div className={styles.jobDetailsHeader}>
        <Heading className={styles.firstHeading} level={8}>Job Details</Heading>
        <span>
          {'Last run '}
          {formatDateShort(job.lastExecutionDate)}
        </span>
      </div>
      <Card className={styles.detailsCard}>
        <p className={styles.heading}>
          Name
        </p>
        <p>{job.name}</p>
        <p className={styles.heading}>
          Analysis period
        </p>
        <p>{job.analysisPeriod}</p>
        <p className={styles.heading}>
          Data used
        </p>
        <ul>
          {job.tables.map(t => <li key={t.tableCode}>{t.tableName}</li>)}
        </ul>
      </Card>
    </div>
  )
}

ClientJobDetailsSideBar.propTypes = propTypes
export default ClientJobDetailsSideBar
