export const SUPPORT_SECTIONS = {
  APP: '/support/using-the-app',
  DATA: '/support/preparing-data',
}

export const SUPPORT_PAGES = {
  ACTION_HUB: `${SUPPORT_SECTIONS.APP}/what-is-action-hub`,
  AZURE: `${SUPPORT_SECTIONS.APP}/where-is-my-data-hosted`,
  SHARING_SUBSCRIPTION_JOBS: `${SUPPORT_SECTIONS.APP}/sharing-subscription-jobs`,

  EXCEL_TO_CSV: `${SUPPORT_SECTIONS.DATA}/converting-excel-to-csv`,
  DELIMITERS_AND_TEXT_QUALIFIERS: `${SUPPORT_SECTIONS.DATA}/delimiters-and-text-qualifiers`,
  DATA_WRONG_FORMAT: `${SUPPORT_SECTIONS.DATA}/data-in-wrong-format`,
  USING_DATA_TEMPLATES: `${SUPPORT_SECTIONS.DATA}/using-data-templates`,
}

export const GENAI_SUPPORT_PAGES = {
  GENAI_DATA_PRIVACY: 'https://ausdeloitte.sharepoint.com/sites/DOL-c-AU-FirmInnovation/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FDOL%2Dc%2DAU%2DFirmInnovation%2FShared%20Documents%2FGenerative%20AI%2FUpdated%20Guidelines%20for%20use%20of%20Generative%20AI%5FJuly%202023%2Epdf&parent=%2Fsites%2FDOL%2Dc%2DAU%2DFirmInnovation%2FShared%20Documents%2FGenerative%20AI&OR=Teams%2DHL&CT=1692840748884&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiIyOC8yMzA2MDQwMTE3NyIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D',
}
