/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconDeleteBin = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--deletebin',
    className,
  )

  return (
    <IconBase className={classes} viewBox="0 0 14 18" xmlns="http://www.w3.org/2000/svg"{...rest}><title>ic_delete</title><path className="path1" d="M1.001 16.004C1.001 17.102 1.9 18 2.997 18h8.004a2.001 2.001 0 0 0 1.996-1.996V4H1.001v12.003zM14 1.004h-3.502L9.503.003h-5l-1.002 1H0V3h13.999V1.004z" fill="#888" fillRule="evenodd"/></IconBase>
  )
}

IconDeleteBin.propTypes = {
  className: PropTypes.string,
}

export default IconDeleteBin
/* eslint-enable */
