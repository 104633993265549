import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Accordion } from '@deloitte/gel-library'
import InsertChartIcon from '@material-ui/icons/InsertChart'
import DeleteIcon from '@material-ui/icons/Delete'
import ReportForm from 'components/ReportForm'
import classNames from 'classnames'
import ErrorOutlinedIcon from '@material-ui/icons/ErrorOutlined'
import styles from './HostedReportForm.scss'

const HostedReportForm = ({
  openLastAccordion, mode, onDelete, reports, form, onChange, validateUrlOnBlur, validateRequiredFieldOnBlur,
}) => {
  const [expandedIndex, setExpandedIndex] = useState(-1)

  const onDeleteFromAccordion = (e, report) => {
    e.stopPropagation()
    onDelete(report)
  }

  useEffect(() => {
    if (openLastAccordion) {
      setExpandedIndex(reports.length - 1)
    }
  }, [reports, openLastAccordion])

  const handleAccordionChange = groupIndex => (_, isExpanded) => {
    setExpandedIndex(isExpanded ? groupIndex : null)
  }
  return (
    <div>
      {reports.map((report, index) => {
        const reportName = form[report.name]?.value
        const hasError = form[report.name]?.error?.length > 0 || form[report.url]?.error?.length > 0 || form[report.description]?.error?.length > 0
        return (
          <div>
            {mode === 'create' ? (
              <ReportForm
                form={form}
                onChange={onChange}
                onDelete={onDelete}
                report={report}
                showDeleteIcon={reports.length > 1}
                validateRequiredFieldOnBlur={validateRequiredFieldOnBlur}
                validateUrlOnBlur={validateUrlOnBlur}
              />
            ) : mode === 'edit' && (
              <Accordion
                className={classNames(styles.accordion, reports.length === index + 1 ? styles.lastAccordion : null)}
                expanded={expandedIndex === index}
                heading={(
                  <div className={styles.head}>
                    <div className={styles.accordionHeading}>
                      <div className={styles.reportNameAndImg}>
                        <InsertChartIcon className={styles.contentIcon} />
                        <div className={styles.reportNameHeader}>{reportName}</div>
                      </div>
                      {hasError && (
                      <div className={styles.failedReportdiv}>
                        <ErrorOutlinedIcon className={styles.failedReport} />
                        <div className={styles.failedReport}>Errors found in this report</div>
                      </div>
                      )}
                    </div>
                    <div className={classNames(styles.deleteReport, reports.length > 1 ? styles.hoverState : null)} onClick={e => onDeleteFromAccordion(e, report)}>
                      {reports.length > 1 && <DeleteIcon />}
                    </div>
                  </div>
            )}
                key={index}
                onChange={handleAccordionChange(index)}
              >
                <ReportForm
                  form={form}
                  onChange={onChange}
                  onDelete={onDelete}
                  report={report}
                  showDeleteIcon={reports.length > 1}
                  showReportHeading={false}
                  validateRequiredFieldOnBlur={validateRequiredFieldOnBlur}
                  validateUrlOnBlur={validateUrlOnBlur}
                />
              </Accordion>
            )}
          </div>
        )
      })}
    </div>
  )
}

HostedReportForm.propTypes = {
  form: PropTypes.object.isRequired,
  mode: PropTypes.oneOf(['create', 'edit']).isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  openLastAccordion: PropTypes.bool,
  reports: PropTypes.arrayOf(PropTypes.object).isRequired,
  validateRequiredFieldOnBlur: PropTypes.func.isRequired,
  validateUrlOnBlur: PropTypes.func.isRequired,
}

HostedReportForm.defaultProps = {
  openLastAccordion: false,
}

export default HostedReportForm
