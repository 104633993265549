import { uniqBy } from 'lodash'
import { getRequest, parseError } from 'utils/api'
import { getPackages } from 'utils/api/package'
import { objectFromArray } from 'utils/objects'
import { showErrorPage } from './app'

export const PACKAGES_FETCHING = 'PACKAGES_FETCHING'
export const PACKAGES_FETCHED = 'PACKAGES_FETCHED'
export const PACKAGE_FETCHING = 'PACKAGE_FETCHING'
export const PACKAGE_FETCHED = 'PACKAGE_FETCHED'

export const fetching = () => ({
  type: PACKAGES_FETCHING,
})

export const fetched = (collections, ids, packages) => ({
  type: PACKAGES_FETCHED,
  collections,
  ids,
  packages,
})

export function fetchPackages() {
  return (dispatch) => {
    dispatch(fetching())
    // TODO: Support BAU
    getPackages().then(({ data }) => {
      const collections = uniqBy(
        data.map(p => p.collection),
        p => p,
      ).map((c, index) => ({
        name: c,
        displayOrder: index,
        packageIds: data.filter(p => p.collection === c).map(p => p.name),
      }))
      const ids = data.map(p => p.name)
      const packages = {}
      data.forEach((p) => {
        packages[p.name] = p
      })

      dispatch(fetched(collections, ids, packages))
    })
  }
}

export function fetchClientPackages() {
  return (dispatch) => {
    dispatch(fetching())
    getRequest('/client/packages').then(({ data }) => {
      const ids = data.map(x => x.name)
      const packages = objectFromArray(data, 'name')
      dispatch(fetched(null, ids, packages))
    })
  }
}

export const fetchingPackage = packageId => ({
  type: PACKAGE_FETCHING,
  packageId,
})

export const fetchedPackage = (packageId, thePackage) => ({
  type: PACKAGE_FETCHED,
  packageId,
  thePackage,
})

export const fetchClientPackage = (packageId) => {
  return (dispatch) => {
    dispatch(fetchingPackage(packageId))

    getRequest(`/client/packages/${packageId}`)
      .then(({ data }) => {
        dispatch(fetchedPackage(packageId, data))
      })
      .catch((error) => {
        const err = parseError(error)
        dispatch(showErrorPage(err))
      })
  }
}
