/* Generated by ./script/iconify.js */
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Colors } from '@deloitte/gel-library'
import IconBase from './IconBase'

const IconErrorOrange = ({ className, fill, ...rest }) => {
  const classes = classnames('icon', 'icon--errororange', className)

  return (
    <IconBase
      className={classes}
      viewBox="0 0 22 19"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g fill="none" fillRule="evenodd">
        <path className="path1" d="M-1-2h24v24H-1z" />
        <path
          className="path2 fill"
          d="M0 19h22L11 0 0 19zm12-3h-2v-2h2v2zm0-4h-2V8h2v4z"
          fill={fill}
          fillRule="nonzero"
        />
      </g>
    </IconBase>
  )
}

IconErrorOrange.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
}

IconErrorOrange.defaultProps = {
  className: null,
  fill: Colors.supportOrange01,
}

export default IconErrorOrange
