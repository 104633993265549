/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconExitChecklist = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--exitchecklist',
    className,
  )

  return (
    <IconBase className={classes} viewBox="0 0 21 25" xmlns="http://www.w3.org/2000/svg"{...rest}><title>Exit checklist icon</title><defs><path className="path1" d="M0 .222h20.747V25H0V.222z"/><path className="path2" d="M0 .222h20.747V25H0"/></defs><g fill="none" fillRule="evenodd"><path className="path3" d="M17.482 22.322H3.265a.416.416 0 0 1-.415-.417V5.32c0-.23.186-.417.415-.417h2.038v.834H3.679v15.752h13.389V5.736h-1.624v-.833h2.038c.23 0 .415.187.415.417v16.585c0 .23-.186.417-.415.417" fill="#58595B"/><g><mask fill="#fff"><use xlinkHref="#a"/></mask><path className="path4" d="M5.717 6.682h9.313V4.527h-2.107a.416.416 0 0 1-.414-.417v-.907a2.144 2.144 0 0 0-2.135-2.148 2.144 2.144 0 0 0-2.136 2.148v.907c0 .23-.186.417-.415.417H5.717v2.155zm9.727.834H5.303a.416.416 0 0 1-.415-.417V4.11c0-.23.186-.416.415-.416h2.106v-.49c0-1.644 1.33-2.98 2.965-2.98a2.976 2.976 0 0 1 2.964 2.98v.49h2.106c.229 0 .415.187.415.417V7.1c0 .23-.186.416-.415.416z" fill="#58595B" mask="url(#b)"/></g><path className="path5" d="M14.001 11.557H6.746a.416.416 0 0 1 0-.833h7.255a.416.416 0 0 1 0 .833M14.001 14.306H6.746a.416.416 0 0 1 0-.833h7.255a.416.416 0 0 1 0 .833M11.1 17.055H6.745a.416.416 0 0 1 0-.833H11.1a.416.416 0 0 1 0 .833" fill="#58595B"/><g><mask fill="#fff"><use xlinkHref="#c"/></mask><path className="path6" d="M19.232 25H1.515A1.521 1.521 0 0 1 0 23.477V2.955c0-.84.68-1.524 1.515-1.524h6.546v1.39H1.515a.136.136 0 0 0-.134.134v20.522c0 .072.062.134.134.134h17.717a.136.136 0 0 0 .133-.134V2.955a.136.136 0 0 0-.133-.135h-6.546V1.431h6.546c.835 0 1.515.684 1.515 1.524v20.522c0 .84-.68 1.523-1.515 1.523" fill="#58595B" mask="url(#d)"/></g></g></IconBase>
  )
}

IconExitChecklist.propTypes = {
  className: PropTypes.string,
}

export default IconExitChecklist
/* eslint-enable */
