import { useState } from 'react'
import { getGenAIQAEndpoint } from 'features/aiAssistant/utils/'
import { getConfig } from 'utils/config'

const useAIBot = () => {
  const [inputValue, setInputValue] = useState('')
  const [apiResult, setApiResult] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const { URL_BASE: baseUrl } = getConfig()


  const handleSearch = async () => {
    if (inputValue) {
      setLoading(true)
      try {
        const response = await fetch(`${`${baseUrl}/gen-ai/qa`}?${new URLSearchParams({
          prompt: encodeURIComponent(inputValue),
        })}`)
        if (response.ok) {
          const data = await response.json()
          setError(null)
          setApiResult(data)
        } else {
          setError('Sorry, I am offline. Check back later!')
        }
      } catch (err) {
        setError('Sorry, I am offline. Check back later!')
      } finally {
        setLoading(false)
      }
    }
  }

  return {
    inputValue,
    setInputValue,
    apiResult,
    handleSearch,
    loading,
    error,
  }
}
export default useAIBot
