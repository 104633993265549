/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-string-refs */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import SuggestionItem from '../SuggestionItem'
import styles from '../../AutoCompleteInput.scss'

class Suggestions extends Component {
  static propTypes = {
    handleClick: PropTypes.func.isRequired,
    selectedIndex: PropTypes.number.isRequired,
    show: PropTypes.bool,
    suggestions: PropTypes.array.isRequired,
  }

  static defaultProps = {
    show: false,
  }

  _handleClick = (tag) => {
    const { handleClick } = this.props
    handleClick(tag)
  }

  suggestionList = () => {
    const {
      selectedIndex,
      suggestions,
      show,
    } = this.props
    const items = suggestions.map((item, i) => {
      const active = (i === selectedIndex)
      return (
        <SuggestionItem
          active={active}
          handleClick={this._handleClick}
          key={i}
          label={item.label}
          value={item.value}
        />
      )
    })
    // const min = minQueryLength || 2
    if (items.length === 0 || !show) {
      return null
    }

    return items
  }

  render() {
    const { show } = this.props
    const classes = {
      [styles.showing]: show,
    }

    return (
      <div
        className={classnames(styles.suggestions, classes)}
        ref="menu"
      >
        <ul>
          {this.suggestionList()}
        </ul>
      </div>
    )
  }
}

export default Suggestions
