import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Info from '@material-ui/icons/Info'
import Warning from '@material-ui/icons/Warning'
import OpenInNew from '@material-ui/icons/OpenInNew'
import ReactMarkdown from 'react-markdown'
import { Link } from 'react-router-dom'
import styles from './InfoBanner.scss'

const iconMap = {
  warning: <Warning className={styles.icon} />,
  info: <Info className={styles.icon} />,
}

const propTypes = {
  link: PropTypes.string,
  linkText: PropTypes.string,
  message: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(['info', 'warning']),
}

const defaultProps = {
  linkText: null,
  link: null,
  mode: 'info',
}

const InfoBanner = ({
  message, linkText, link, mode,
}) => {
  const icon = mode in iconMap
    ? iconMap[mode]
    : <Info />

  const isExternalLink = link && link.startsWith('http')

  const linkBody = (
    <Fragment>
      <span className={styles.linkText}>{linkText}</span>
      {isExternalLink && <OpenInNew className={styles.openNew} />}
    </Fragment>
  )

  const linkWrapper = isExternalLink
    ? (
      <div
        className={styles.link}
        onClick={() => {
          const newWindow = window.open(link)
          newWindow.opener = null
        }}
      >
        {linkBody}
      </div>
    ) : (
      <Link className={styles.link} to={link}>
        {linkBody}
      </Link>
    )

  return (
    <div className={styles.base}>
      <div className={styles.container}>
        {icon}
        <ReactMarkdown className={styles.message} source={message} />
        {linkText && link && linkWrapper}
      </div>
    </div>
  )
}

InfoBanner.propTypes = propTypes
InfoBanner.defaultProps = defaultProps

export default InfoBanner
