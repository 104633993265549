/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconGreenTick = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--greentick',
    className,
  )

  return (
    <IconBase className={classes} viewBox="0 0 120 120" xmlns="http://www.w3.org/2000/svg"{...rest}><title>C4965B66-8EC3-46A5-958D-27E40CDD4131</title><g fill="none" fillRule="evenodd"><path className="path1" d="M119.835 59.917c0 33.092-26.826 59.918-59.917 59.918C26.825 119.835 0 93.009 0 59.918 0 26.825 26.826 0 59.917 0c33.092 0 59.918 26.826 59.918 59.917" fill="#26890d"/><path className="path2" d="M53.258 76.858a2.998 2.998 0 0 1-2.116-.878L36.675 61.52a3 3 0 1 1 4.238-4.237l12.345 12.345 25.664-25.665A3 3 0 1 1 83.16 48.2L55.41 75.95a2.998 2.998 0 0 1-2.152.908" fill="#FFF"/></g></IconBase>
  )
}

IconGreenTick.propTypes = {
  className: PropTypes.string,
}

export default IconGreenTick
/* eslint-enable */
