/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconMessageSummary = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--messagesummary',
    className,
  )

  return (
    <IconBase className={classes} viewBox="0 0 90 90" xmlns="http://www.w3.org/2000/svg"{...rest}><title>Fill 1</title><path className="path1" d="M52.35 50.163a2.666 2.666 0 0 1-2.438 2.437l-36.636 3.032a1.018 1.018 0 0 1-1.099-.931l-.104-1.253a1.019 1.019 0 0 1 .93-1.1l35.246-2.917c.497-.041.891-.434.932-.931l2.92-35.245a1.019 1.019 0 0 1 1.098-.93l1.253.103c.561.046.978.538.931 1.1L52.35 50.162zm36.018-17.239L57.075 1.632a5.59 5.59 0 0 0-7.894 0L1.632 49.18a5.59 5.59 0 0 0 0 7.894L32.926 88.37A5.568 5.568 0 0 0 36.873 90a5.572 5.572 0 0 0 3.947-1.63l47.548-47.552a5.59 5.59 0 0 0 0-7.894z" fill="#D8D8D8" fillRule="evenodd"/></IconBase>
  )
}

IconMessageSummary.propTypes = {
  className: PropTypes.string,
}

export default IconMessageSummary
/* eslint-enable */
