import React from 'react'
import { Heading, Button } from '@deloitte/gel-library'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './ProductBanner.scss'

const propTypes = {
  backgroundImageUrl: PropTypes.string,
  blurb: PropTypes.node.isRequired,
  className: PropTypes.string,
  ctaText: PropTypes.string,
  imageContent: PropTypes.node,
  metaText: PropTypes.string,
  mode: PropTypes.oneOf(['light', 'dark']),
  name: PropTypes.node.isRequired,
  onCtaClick: PropTypes.func,
  style: PropTypes.object,
  subtitle: PropTypes.string,
}

const defaultProps = {
  backgroundImageUrl: null,
  className: null,
  ctaText: null,
  imageContent: null,
  metaText: null,
  mode: 'dark',
  onCtaClick: null,
  style: {},
  subtitle: null,
}

function ProductBanner({
  name, blurb, ctaText, onCtaClick,
  metaText, className, subtitle, mode, imageContent, style,
  backgroundImageUrl,
}) {
  return (
    <div
      className={classnames(
        styles.container,
        mode === 'light' ? styles.light : null,
        className,
      )}
      style={style}
    >
      <div
        className={styles.base}
        style={{ backgroundImage: `url(${backgroundImageUrl})` }}
      >

        <div className={styles.textContent}>
          {metaText && (
            <p className={styles.metaText}>
              {metaText}
            </p>
          )}

          <Heading level={4}>{name}</Heading>
          {subtitle && <Heading level={6}>{subtitle}</Heading>}
          <p>{blurb}</p>

          {ctaText && (
            <Button
              onClick={onCtaClick}
            >
              {ctaText}
            </Button>
          )}

        </div>

        <div
          className={styles.imageContent}
        >
          {imageContent}
        </div>
      </div>
    </div>
  )
}

ProductBanner.propTypes = propTypes
ProductBanner.defaultProps = defaultProps

export default ProductBanner
