import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Heading, TextInput } from '@deloitte/gel-library'
import { useDispatch, useSelector } from 'react-redux'
import OverlayModal from 'components/OverlayModal'
import { modalHide } from 'actions/modals'
import Animate from 'components/Animate'
import TextArea from 'components/TextArea'
import { sanitizeObject } from 'utils/form'

import styles from './EditDetailsForm.scss'

const propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    displayName: PropTypes.string,
    idColumn: PropTypes.bool,
    name: PropTypes.string,
  })).isRequired,
  isNewForm: PropTypes.bool.isRequired,
  modalId: PropTypes.string.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  task: PropTypes.shape({
    taskData: PropTypes.object,
  }),
}

const defaultProps = {
  task: {},
}

const EditDetailsForm = ({
  columns, modalId, task, onSaveClick, isNewForm,
}) => {
  const dispatch = useDispatch()
  const modals = useSelector(state => state.modals)
  const [editData, setEditData] = useState(task.taskData ?? {})

  const handleInputChange = (name, value) => {
    setEditData(prev => ({ ...prev, [name]: value }))
  }

  const handleOnClose = () => {
    dispatch(modalHide(modalId))
  }

  const handleSaveChanges = (e) => {
    e.preventDefault()
    const sanitisedData = sanitizeObject(editData)
    onSaveClick(sanitisedData)
    dispatch(modalHide(modalId))
  }

  return (
    <OverlayModal
      base={20}
      disable={false}
      id={modalId}
      init={() => { }}
      modals={modals}
      onClose={handleOnClose}
      push={0}
    >
      <Animate name="fade">
        <div className={styles.base}>

          <div className={styles.contentWrapper}>
            <Heading className={styles.titleSection} level={5}>
              {isNewForm ? 'Add new action' : 'Edit action details'}
            </Heading>
            <form onSubmit={handleSaveChanges}>
              {columns.map(column => (
                editData[column.name]?.length > 50 ? (
                  <div className={styles.fieldRowMultiline} key={column.name}>
                    <div className={styles.labelFieldMultiline}>
                      <label htmlFor={`input-${column.name}`}>{column.displayName}</label>
                    </div>
                    <div className={styles.inputFieldMultiline}>
                      <TextArea
                        multiline
                        height="100px"
                        id={`input-${column.name}`}
                        maxLength={4000}
                        onChange={value => handleInputChange(column.name, value)}
                        value={editData ? editData[column.name] : ''}
                      />
                    </div>
                  </div>
                ) : (
                  <div className={styles.fieldRow} key={column.name}>
                    <div className={styles.labelField}>
                      <label htmlFor={`input-${column.name}`}>{column.displayName}</label>
                    </div>
                    <div className={styles.inputField}>
                      <TextInput
                        id={`input-${column.name}`}
                        onChange={value => handleInputChange(column.name, value)}
                        value={editData ? editData[column.name] : ''}
                      />
                    </div>
                  </div>
                )
              ))}
              <div className={styles.buttonContainer}>
                <Button className={styles.saveBtn} type="submit">Save</Button>
              </div>
            </form>
          </div>
        </div>
      </Animate>
    </OverlayModal>
  )
}

EditDetailsForm.propTypes = propTypes
EditDetailsForm.defaultProps = defaultProps

export default EditDetailsForm
