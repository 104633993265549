import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Avatar from 'components/Avatar'
import { formatDateTimeFromStringShort } from 'utils/dates'
import { sortByFunction } from 'utils/arrays'
import { Button } from '@deloitte/gel-library'
import TextArea from 'components/TextArea'
import styles from './Comments.scss'

function Comments({
  comments, disabled, onSubmit, members, isSubmitting, objectName,
}) {
  const [commentText, setCommentText] = useState('')
  const chatViewRef = useRef(null)

  useEffect(() => {
    if (comments && comments.length > 0 && chatViewRef && chatViewRef.current) {
      chatViewRef.current.scrollTop = chatViewRef.current.scrollHeight
    }
  }, [chatViewRef, comments])

  const disable = commentText?.length === 0 || isSubmitting
  const sortedComments = comments?.slice().sort(sortByFunction(x => x.postedAt))

  return (
    <div className={styles.flexBase}>
      <div className={styles.previousComments}>
        {sortedComments && sortedComments.length > 0 ? sortedComments?.map(({
          id, message, userId, postedAt,
        }, index) => {
          const postedByUser = members.find(x => x.id === userId)?.userDetails
          return (
            <div className={styles.previousComment} key={id} ref={chatViewRef}>
              <Avatar className={styles.avatar} size="tiny" userDetails={postedByUser} />
              <div className={styles.commentContent}>

                <div className={styles.header}>
                  <span>{`${postedByUser?.firstName} ${postedByUser?.surname}`}</span>
                  <div className={styles.datePosted}>
                    {formatDateTimeFromStringShort(postedAt)}
                  </div>
                </div>

                <div className={styles.comment}>
                  {message}
                </div>

                {(index !== sortedComments.length - 1) && <div className={styles.seperator} />}
              </div>
            </div>
          )
        }) : (
          <div className={styles.noComments}>
            No comments for this
            {' '}
            {objectName}
            .
          </div>
        )}
      </div>
      {!disabled && (
        <>
          <div className={styles.TextAreaAndButton}>
            <TextArea
              multiline
              disabled={disabled}
              onChange={text => setCommentText(text)}
              placeholder="Write a comment"
              rows={10}
              value={commentText}
            />
            <Button
              className={styles.sendButton}
              disabled={disable || disabled}
              mode="secondary"
              onClick={() => {
                onSubmit(commentText)
                setCommentText('')
              }}
            >
              SEND
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

Comments.propTypes = {
  comments: PropTypes.arrayOf(PropTypes.shape({
    comment: PropTypes.string,
  })).isRequired,
  disabled: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  members: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
  })).isRequired,
  objectName: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    surname: PropTypes.string,
  }).isRequired,
}

Comments.defaultProps = {
  disabled: false,
  objectName: 'task',
  isSubmitting: false,
}

export default Comments

