import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import getElementLockedStatus from 'utils/getElementLockedStatus'
import { IconAdd } from 'icons'
import ripple from 'react-toolbox/lib/ripple'
import styles from './ActionButton.scss'

class ActionButton extends Component {
  state = {
    unlock: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this._updatePosition)
    this._updatePosition()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this._updatePosition)
  }

  _updatePosition = () => {
    const { unlock } = this.state
    const end = document.getElementById('footer').clientHeight
    const unlockStatus = getElementLockedStatus(null, end)

    if (unlock !== unlockStatus) {
      this.setState({ unlock: unlockStatus })
    }
  }

  render() {
    const {
      handleAction, tooltip, className, icon,
    } = this.props
    const { unlock } = this.state

    const classes = {
      [styles.unlock]: unlock,
    }

    return (
      <div className={classnames(styles.base, classes, className)}>
        <a
          className={styles.button}
          onClick={handleAction}
        >
          {this.props.showLeftIcon && icon}
          {' '}
          {tooltip}
        </a>
      </div>
    )
  }
}

ActionButton.propTypes = {
  className: PropTypes.string,
  handleAction: PropTypes.func.isRequired,
  icon: PropTypes.node,
  tooltip: PropTypes.string,
  showLeftIcon: PropTypes.bool,
}

ActionButton.defaultProps = {
  className: null,
  icon: <IconAdd
    className={styles.add}
    height="14"
    width="14"
  />,
  tooltip: '',
}

export default ripple({ spread: 3 })(ActionButton)
