export const permissions = {
  createHostedReport: 'createHostedReport',
  accessActionHub: 'accessActionHub',
  administerSystem: 'administerSystem',
  supportSystem: 'supportSystem',
  inviteClientUsers: 'inviteClientUsers',
  customJobView: 'customJobView',
  customJobCreate: 'customJobCreate',
  customJobExecute: 'customJobExecute',
}

export const jobPermissions = {
  jobView: 'JobView',
  actionHubRead: 'JobActionHubRead',
  actionHubEdit: 'JobActionHubEdit',
}

const checkPermission = (userPermissions, permissionToCheck) => {
  return userPermissions[permissionToCheck]
}

export function canCreateCustomReport(user) {
  return checkPermission(user.permissions, permissions.createHostedReport)
}

export function canAccessActionHub(user) {
  return checkPermission(user.permissions, permissions.accessActionHub)
}

export function canInviteClientUsers(user) {
  return checkPermission(user.permissions, permissions.inviteClientUsers)
}

export function isAdmin(user) {
  return checkPermission(user.permissions, permissions.administerSystem)
}

export function isSupport(user) {
  return checkPermission(user.permissions, permissions.supportSystem)
}

export function isAssetDeveloper(user) {
  return [
    permissions.customJobView,
    permissions.customJobCreate,
    permissions.customJobExecute,
  ].every(permission => checkPermission(user.permissions, permission))
}

export function isTacTClient(isExternal, packageName) {
  return (isExternal && packageName === 'it-tact')
}
