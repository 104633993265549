/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import SuggestionItem from '../SuggestionItem'
import styles from '../../SearchFilterInput.scss'

const Suggestions = (props) => {
  const {
    handleClick,
    // minQueryLength,
    // query,
    selectedIndex,
    show,
    suggestions,
  } = props

  const _handleClick = (tag) => {
    handleClick(tag)
  }

  const suggestionList = () => {
    const items = suggestions.map((item, i) => {
      const active = (i === selectedIndex)
      return (
        <SuggestionItem
          active={active}
          handleClick={_handleClick}
          key={i}
          label={item.label}
          value={item.value}
        />
      )
    })
    // const min = minQueryLength || 2
    if (items.length === 0 || !show) {
      return null
    }

    return items
  }

  const classes = {
    [styles.showing]: show,
  }

  return (
    <div className={classnames(styles.suggestions, classes)}>
      <ul>
        {suggestionList()}
      </ul>
    </div>
  )
}

Suggestions.propTypes = {
  handleClick: PropTypes.func.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  show: PropTypes.bool,
  suggestions: PropTypes.array.isRequired,
}

Suggestions.defaultProps = {
  show: false,
}

export default Suggestions
