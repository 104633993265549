const genAiTips = () => {
  const guidlinesURL = 'https://ausdeloitte.sharepoint.com/sites/DOL-c-AU-FirmInnovation/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FDOL%2Dc%2DAU%2DFirmInnovation%2FShared%20Documents%2FGenerative%20AI%2FUpdated%20Guidelines%20for%20use%20of%20Generative%20AI%5FJuly%202023%2Epdf&parent=%2Fsites%2FDOL%2Dc%2DAU%2DFirmInnovation%2FShared%20Documents%2FGenerative%20AI&OR=Teams%2DHL&CT=1692840748884&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiIyOC8yMzA2MDQwMTE3NyIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D'

  return [
    {
      title: 'Review firm usage guide',
      body: `Consider use of data with any client, stakeholder and community expectations in line with the firm [Generative AI Guidelines (July 2023)](${guidlinesURL}) prepared by QRM.`,
    },
    {
      title: 'Must obtain approval',
      body: 'Consult with your business unit Quality & Risk (Q&R) representative and seek approval from your lead engagement partner and in particular the LCSP for client use-cases (especially the first).',
    },
  ]
}

export default genAiTips
