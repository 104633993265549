import { Button, Heading } from '@deloitte/gel-library'
import React from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { getWorkflowSteps } from 'utils/business/workflow'
import PropTypes from 'prop-types'
import JobDetailsReview from './components/JobDetailsReview'
import styles from './ViewJobDetails.scss'

export const ViewJobDetails = (props) => {
  const dispatch = useDispatch()
  const { job, app: { user: { isExternal } } } = props

  const nextStepUrl = getWorkflowSteps(job.jobId, isExternal)[1].to

  return (
    <div className={styles.base}>
      <Heading level={4}>Job Details</Heading>
      <JobDetailsReview isExternal={isExternal} job={job} />
      <div className={styles.footer}>
        <Button
          onClick={() => dispatch(push(nextStepUrl))}
        >
          Continue
        </Button>
      </div>
    </div>
  )
}

ViewJobDetails.propTypes = {
  app: PropTypes.shape({
    user: PropTypes.shape({
      isExternal: PropTypes.bool,
    }),
  }).isRequired,
  job: PropTypes.object.isRequired,
}

ViewJobDetails.defaultProps = {

}

export default ViewJobDetails
