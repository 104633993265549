import each from 'lodash/each'
import keys from 'lodash/keys'
import intersection from 'lodash/intersection'
import extend from 'lodash/extend'

import * as app from './app'
import * as keyCodes from './keyCodes'
import * as meta from './meta'
import * as ui from './ui'
import * as upload from './upload'
import * as forms from './forms'
import * as error from './error'
import * as localStorageKeys from './localStorageKeys'
import * as domains from './domains'
import * as customReports from './customReports'

const files = {
  app,
  keyCodes,
  meta,
  ui,
  upload,
  forms,
  error,
  localStorageKeys,
  domains,
  customReports,
}

// Loop through the imported constant files and check for duplicates
let constantsMutable = {}
each(keys(files), (filename) => {
  const file = files[filename]
  const duplicates = intersection(keys(constantsMutable), keys(file))
  each(duplicates, (duplicate) => {
    console.warn('Duplicate constant key found. Please fix this immediately.', { filename, duplicate }) // eslint-disable-line no-console
  })
  constantsMutable = extend(constantsMutable, file)
})

const constants = constantsMutable

export default constants
