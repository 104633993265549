import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReportView from 'views/Report'
import { fetchClientPackage } from 'actions/packages'
import { fetchClientReports, fetchClientJob, fetchClientActions } from 'actions/client'
import Loading from 'components/Loading'
import { push } from 'connected-react-router'

class Report extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    job: PropTypes.object,
    match: PropTypes.shape({
      params: PropTypes.shape({
        jobId: PropTypes.string,
        packageId: PropTypes.string,
      }),
    }).isRequired,
    report: PropTypes.object,
    solution: PropTypes.shape({
      analyses: PropTypes.arrayOf(PropTypes.object),
    }),
  }

  static defaultProps = {
    job: null,
    report: null,
    solution: null,
  }

  componentDidMount() {
    const {
      dispatch, solution,
      match: {
        params: { packageId, jobId },
      },
    } = this.props

    dispatch(fetchClientJob(jobId))
    dispatch(fetchClientReports(jobId))
    dispatch(fetchClientActions(jobId))

    if (!solution) {
      dispatch(fetchClientPackage(packageId))
    }
  }

  handleGoToReport = (analysisId) => {
    const { dispatch } = this.props
    dispatch(push(`${analysisId}`))
  }

  render() {
    const { job, report, dispatch } = this.props

    if (!job || !report) {
      return <Loading pageLoading />
    }

    return (
      <ReportView
        goToAction={actionId => dispatch(push(`/action/${actionId}`))}
        goToReport={this.handleGoToReport}
        returnLink={`/insights/${job.packageId}/${job.jobId}/reports`}
        {...this.props}
      />
    )
  }
}

const mapStateToProps = (
  {
    client: { jobs }, reportPage, packages: { packages }, app: { user: { isExternal } },
  },
  {
    match: {
      params: { analysisId, packageId, jobId },
    },
  },
) => {
  const job = jobs[jobId]

  const solution = packages[packageId]

  if (!solution || !job) {
    return { job: null, analysis: null, report: null }
  }

  const analysis = solution.analyses.find(a => a.name === analysisId)

  return {
    job,
    solution,
    analysis: {
      ...analysis,
      name: analysis.displayName,
      id: analysis.name,
    },
    ...reportPage,
    report: job.reports
      ? job.reports.find(r => r.analysisId === analysisId)
      : null,
    isExternal,
  }
}

export default connect(
  mapStateToProps,
)(Report)
