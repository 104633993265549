import React, { useState, useEffect } from 'react'
import constants from 'constants'
import { useDispatch, useSelector } from 'react-redux'
import Animate from 'components/Animate'
import OverlayModal from 'components/OverlayModal'
import Messagebar from 'components/Messagebar'
import Radio from 'components/Form/components/Radio'
import TextInput from 'components/Form/components/TextInput'
import { modalHide } from 'actions/modals'
import { adminResetDefaultBanner, adminSaveBanner } from 'actions/admin'
import { formInit, formUpdate } from 'actions/forms'
import {
  requiredFields,
  handleError,
  handleErrors,
  validateAll,
  validateField,
} from 'utils/validators'
import { Checkbox, Heading, ToggleInput } from '@deloitte/gel-library'
import SubmitButton from 'components/SubmitButton'

import styles from './BannerManagementModal.scss'

const MAX_ACTIVE_BANNERS = 3

const MODAL_ID = constants.MODAL_BANNER_SETTINGS
const FORM_ID = constants.FORM_BANNER_SETTINGS

const FORM_SCHEMA = [
  {
    name: 'name',
    validators: [
      {
        check: 'required',
      },
    ],
    error: 'Enter a name for the banner',
  },
  {
    name: 'message',
    validators: [
      {
        check: 'required',
      },
    ],
    error: 'Enter the message to be included in the banner (max 150 characters)',
  },
  {
    name: 'mode',
    error: 'Select banner type',
  },
  {
    name: 'includesLink',
  },
  {
    name: 'linkText',
    validators: [
      {
        check: 'required',
      },
    ],
    error: 'Enter a text to display the link',
  },
  {
    name: 'linkUrl',
    validators: [
      {
        check: 'isValidURL',
      },
    ],
    error: 'Enter a valid URL',
  },
  {
    name: 'audience',
    error: 'Select at least one user group',
  },
  {
    name: 'isActive',
  },
]

const LINK_FIELDS = ['linkText', 'linkUrl']

const BANNER_MODE_OPTIONS = [
  { label: 'Info', value: 'Info' },
  { label: 'Warning', value: 'Warning' },
]

const BannerManagementModal = () => {
  const dispatch = useDispatch()
  const forms = useSelector(state => state.forms)
  const modals = useSelector(state => state.modals)

  const { userGroups } = useSelector(state => state.admin.userAdmin)

  const {
    _isUpdating,
    _isUpdated,
    activeBanners,
    selectedBanner,
  } = useSelector(state => state.admin.bannerAccess)

  const [formData, setFormData] = useState(selectedBanner)
  const [disableSubmit, setDisableSubmit] = useState(true)

  const form = forms[FORM_ID]

  const emptyAudienceCheck = !Object.values(formData.audienceCheck).some(item => item === true)
  const maxBannersCheck = !selectedBanner.isActive
    && formData.isActive
    && Object.keys(activeBanners).length >= MAX_ACTIVE_BANNERS

  useEffect(() => {
    // Add audience checkbox form object
    const audienceCheck = {}
    Object.keys(userGroups).forEach((id) => {
      audienceCheck[id] = false
    })
    if (selectedBanner.audience) {
      selectedBanner.audience.forEach((audience) => {
        audienceCheck[audience.id] = true
      })
    }

    const data = { ...selectedBanner, audienceCheck }
    setFormData(data)

    dispatch(formInit(FORM_ID, {
      formFields: FORM_SCHEMA,
      data,
    }))
  }, [dispatch, selectedBanner, userGroups])

  useEffect(() => {
    if (FORM_ID in forms) {
      const hasErrors = FORM_SCHEMA.some(field => !forms[FORM_ID][field.name].valid)
        || emptyAudienceCheck
        || maxBannersCheck

      // Compare current form data with source
      const hasEdit = Object.entries(formData).some(([key, val]) => {
        return !['audience', 'audienceCheck'].includes(key) ? val !== selectedBanner[key] : false
      }) || Object.entries(formData.audienceCheck).some(([key, val]) => {
        const audienceInSource = formData.audience.some(a => a.id === parseInt(key, 10))
        return val ? !audienceInSource : audienceInSource
      })

      setDisableSubmit(hasErrors || !hasEdit)
    }
  }, [forms, formData, selectedBanner, emptyAudienceCheck, maxBannersCheck])

  const handleChange = (field, value) => {
    setFormData(curr => ({ ...curr, [field]: value }))
  }

  const handleToggleChange = (field, value) => {
    handleChange(field, value)

    // Reset link fields
    if (field === 'includesLink' && value === false) {
      LINK_FIELDS.forEach((key) => {
        handleChange(key, '')
        dispatch(formUpdate(FORM_ID, {
          field: key,
          data: {
            value: '',
            error: '',
            valid: true,
          },
        }))
      })
    }
  }

  const handleCheckboxChange = (id) => {
    setFormData(curr => ({
      ...curr,
      audienceCheck: {
        ...curr.audienceCheck,
        [id]: !curr.audienceCheck[id],
      },
    }))
  }

  const handleValidation = (field, value) => {
    const errorObj = validateField(
      requiredFields(FORM_SCHEMA),
      field,
      value,
      forms[FORM_ID],
    )

    if (!errorObj.valid) {
      dispatch(formUpdate(FORM_ID, handleError(errorObj, field)))
    } else {
      dispatch(formUpdate(FORM_ID, {
        field,
        data: {
          value,
          error: '',
          valid: true,
        },
      }))
    }
  }

  const handleFocus = (name) => {
    const stateValue = {
      field: name,
      data: {
        focus: true,
      },
    }
    dispatch(formUpdate(FORM_ID, stateValue))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const formFields = formData.includesLink
      ? [...FORM_SCHEMA]
      : [...FORM_SCHEMA].filter(x => !LINK_FIELDS.includes(x.name))

    const errors = validateAll(requiredFields(formFields), form)

    if (errors.length > 0) {
      handleErrors(errors).forEach(errorState => dispatch(formUpdate(FORM_ID, errorState)))
    } else {
      if (selectedBanner.id) {
        dispatch(adminSaveBanner({
          ...formData,
          id: selectedBanner.id,
        }, true))
      } else {
        dispatch(adminSaveBanner(formData, false))
      }
    }
  }

  const handleClose = () => {
    dispatch(modalHide(MODAL_ID))
    dispatch(adminResetDefaultBanner())
  }

  return (
    <OverlayModal
      base={10}
      disable={false}
      id={MODAL_ID}
      init={() => null}
      modals={modals}
      onClose={handleClose}
      push={4}
    >
      <Animate name="fade">
        <div className={styles.base}>
          <Heading className={styles.title} level={5}>
            {`${selectedBanner.id ? 'Edit' : 'Add'} banner`}
          </Heading>
          {form && formData && (
            <form onSubmit={handleSubmit}>
              <TextInput
                error={form.name.error}
                handleChange={handleChange}
                label="Banner name"
                maxLength={50}
                name="name"
                onBlur={handleValidation}
                onFocus={handleFocus}
                text={formData.name}
                type="text"
              />

              <TextInput
                error={form.message.error}
                handleChange={handleChange}
                label="Banner message"
                maxLength={150}
                name="message"
                onBlur={handleValidation}
                onFocus={handleFocus}
                text={formData.message}
                type="text"
              />

              <div className={styles.flexSection}>
                <div>
                  <div className={styles.inputMargin}>Audience</div>
                  {formData.audienceCheck && (
                    Object.entries(userGroups).map(([id, value]) => (
                      <Checkbox
                        checked={formData.audienceCheck[id]}
                        handleChange={() => handleCheckboxChange(id)}
                        key={id}
                        label={value.description}
                        name={value.description}
                      />
                    )))
                  }
                </div>
                <div className={styles.audience}>
                  <Radio
                    className={styles.inputMargin}
                    label="Banner type"
                    name="mode"
                    onChange={handleChange}
                    options={BANNER_MODE_OPTIONS}
                    value={formData.mode}
                  />
                </div>
              </div>

              {emptyAudienceCheck && (
                <Messagebar type="warn">
                  Please pick one or more audience groups
                </Messagebar>
              )}

              <div>Include a link to another page?</div>
              <ToggleInput
                checked={formData.includesLink}
                className={styles.inputMargin}
                name="includesLink"
                onChange={handleToggleChange}
              />

              {formData.includesLink && (
                <div className={styles.inputMargin}>
                  <TextInput
                    error={form.linkText.error}
                    handleChange={handleChange}
                    label="Link text"
                    maxLength={50}
                    name="linkText"
                    onBlur={handleValidation}
                    onFocus={handleFocus}
                    text={formData.linkText}
                    type="text"
                  />
                  <TextInput
                    error={form.linkUrl.error}
                    handleChange={handleChange}
                    label="URL"
                    maxLength={50}
                    name="linkUrl"
                    onBlur={handleValidation}
                    onFocus={handleFocus}
                    text={formData.linkUrl}
                    type="text"
                  />
                  <Messagebar type="info">
                    Begin URL with /... for relative links within InsightBox, and https://... for external links
                  </Messagebar>
                </div>
              )}

              {!selectedBanner.isActive && (
                <div className={styles.inputMargin}>
                  <div>Activate this banner now?</div>
                  <ToggleInput
                    checked={formData.isActive}
                    name="isActive"
                    onChange={handleToggleChange}
                  />
                </div>
              )}

              {maxBannersCheck && !_isUpdated && (
                <Messagebar type="error">
                  {`Reached maximum of ${MAX_ACTIVE_BANNERS} active banners at a time, unable to activate more banners`}
                </Messagebar>
              )}

              <div className={styles.submit}>
                <SubmitButton
                  complete={_isUpdated}
                  completeText="Saved"
                  disabled={disableSubmit}
                  submitting={_isUpdating}
                  submittingText="Saving"
                >
                  {selectedBanner.id ? 'Save banner' : 'Create banner'}
                </SubmitButton>
              </div>
            </form>
          )}

        </div>
      </Animate>
    </OverlayModal>
  )
}

export default BannerManagementModal
