import * as ActionTypes from 'actions/hostedReports'
import { objectFromArray } from 'utils/objects'
import { uniqBy, union } from 'lodash'

const initialState = {
  _isFetched: true,
  _isFetching: false,
  _isCreating: false,
  _isEditing: false,
  filters: {
    client: '',
    search: '',
  },
  clients: [],
  reportGroupIds: [],
  reportGroups: {},
  engagements: [],
}

export default function hostedReports(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.FETCHING_HOSTED_REPORTS:
      return {
        ...state,
        _isFetching: true,
      }
    case ActionTypes.FETCHED_HOSTED_REPORTS:
      return {
        ...state,
        _isFetched: true,
        _isFetching: false,
        reportGroupIds: action.reportGroups.map(x => x.id),
        reportGroups: objectFromArray(action.reportGroups, 'id'),
        engagements: uniqBy(action.reportGroups.map(group => group.engagement), x => x.code)
          .map(e => ({
            ...e,
            reportGroups: action.reportGroups
              .filter(x => x.engagement.code === e.code)
              .map(x => x.id),
          })),
        clients: union(action.reportGroups
          .map(group => group.engagement.clientName))
          .filter(
            name => name,
          ),
      }
    case ActionTypes.FETCHING_HOSTED_REPORT:
      return {
        ...state,
        reportGroups: {
          ...state.reportGroups,
          [action.id]: {
            ...state.reportGroups[action.id],
            _isFetching: true,
          },
        },
      }
    case ActionTypes.FETCHED_HOSTED_REPORT:
      return {
        ...state,
        reportGroups: {
          ...state.reportGroups,
          [action.id]: action.reportGroup,
        },
        reportGroupIds: state.reportGroupIds.includes(action.id)
          ? state.reportGroupIds
          : [...state.reportGroupIds, action.id],
      }
    case ActionTypes.CREATING_HOSTED_REPORT:
      return {
        ...state,
        _isCreating: true,
      }

    case ActionTypes.CREATED_HOSTED_REPORT: {
      const existingEngagement = state.engagements.find(x => x.code === action.report.engagement.code)
      return {
        ...state,
        _isCreating: false,
        reportGroupIds: [...state.reportGroupIds, action.report.id],
        reportGroups: {
          ...state.reportGroups,
          [action.report.id]: action.report,
        },
        engagements: existingEngagement
          ? state.engagements.map(e => (e.code === existingEngagement.code
            ? {
              ...existingEngagement,
              reportGroups: [...existingEngagement.reportGroups, action.report.id],
            }
            : e))
          : [
            ...state.engagements,
            {
              ...action.report.engagement,
              reportGroups: [action.report.id],
            },
          ],
        clients: state.clients.some(x => x === action.report.engagement.clientName)
          ? state.clients
          : [
            ...state.clients,
            action.report.engagement.clientName,
          ],
      }
    }
    case ActionTypes.CREATING_HOSTED_REPORT_ERROR:
      return {
        ...state,
        _isCreating: false,
      }
    case ActionTypes.UPDATING_HOSTED_REPORT:
      return {
        ...state,
        _isEditing: true,
      }
    case ActionTypes.UPDATED_HOSTED_REPORT:
      return {
        ...state,
        _isEditing: false,
        reportGroups: {
          ...state.reportGroups,
          [action.groupId]: {
            ...action.group,
          },
        },
      }
    case ActionTypes.UPDATING_HOSTED_REPORT_ERROR:
      return {
        ...state,
        _isEditing: false,
      }
    case ActionTypes.UPDATE_HOSTED_REPORT_USERS:
      return {
        ...state,
        reportGroups: {
          ...state.reportGroups,
          [action.groupId]: {
            ...state.reportGroups[action.groupId],
            members: [
              ...state.reportGroups[action.groupId].members,
              ...action.users,
            ],
          },
        },
      }
    case ActionTypes.ADD_HOSTED_REPORT_USER:
      return {
        ...state,
        reportGroups: {
          ...state.reportGroups,
          [action.groupId]: {
            ...state.reportGroups[action.groupId],
            members: [
              ...state.reportGroups[action.groupId].members,
              action.user,
            ],
          },
        },
      }
    case ActionTypes.REMOVE_HOSTED_REPORT_USER:
      return {
        ...state,
        reportGroups: {
          ...state.reportGroups,
          [action.groupId]: {
            ...state.reportGroups[action.groupId],
            members: state.reportGroups[action.groupId].members.filter(x => x.id !== action.userId),
          },
        },
      }
    case ActionTypes.HOSTED_REPORT_SET_FILTERS: {
      const { type, value } = action.filters
      return {
        ...state,
        filters: {
          ...state.filters,
          [type]: value,
        },
      }
    }
    case ActionTypes.HOSTED_REPORT_CLEAR_FILTERS:
      return {
        ...state,
        filters: initialState.filters,
      }
    default:
      return state
  }
}
