import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ClientDashboard from 'views/Home/ClientDashboard'
import { connect } from 'react-redux'
import { fetchClientPackages } from 'actions/packages'
import { fetchClientJobSummary } from 'actions/client'

class ClientHome extends Component {
  static propTypes = {
    client: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    isFetched: PropTypes.bool.isRequired,
    packages: PropTypes.arrayOf(PropTypes.object).isRequired,
    user: PropTypes.object.isRequired,
  }

  componentDidMount() {
    const { dispatch } = this.props

    dispatch(fetchClientPackages())
    dispatch(fetchClientJobSummary())
  }

  render() {
    const {
      client, user, packages, dispatch, isFetched,
    } = this.props

    return (
      <ClientDashboard dispatch={dispatch} isFetched={isFetched} packages={packages} user={user} {...client} />
    )
  }
}

const mapStateToProps = ({ app: { user }, client, packages: { isFetched, packages, ids } }) => {
  return {
    user,
    client,
    isFetched: isFetched && client._fetchedJobSummary && client._fetchedReportSummary,
    packages: ids.map(id => packages[id]),
  }
}

export default connect(mapStateToProps)(ClientHome)
