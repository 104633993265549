/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconAppETime = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--appetime',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 68"{...rest}><path className="path1" fill="#A4AFBC" d="M58.1 68H9.9C4.4 68 0 63.6 0 58.1V9.9C0 4.4 4.4 0 9.9 0h48.2C63.6 0 68 4.4 68 9.9v48.2c0 5.5-4.4 9.9-9.9 9.9"/><path className="path2" fill="#77828E" d="M0 9.9v48.2C0 63.6 4.4 68 9.9 68h48.3L9.9 0C4.4 0 0 4.4 0 9.9"/><g fill="#FFF"><path className="path3" d="M14.1 39.8c-1.3 0-2.3-.4-3-1.1-.7-.8-1.1-1.8-1.1-3.1 0-1.4.3-2.4 1-3.2.7-.8 1.6-1.2 2.8-1.2 1.1 0 2 .3 2.6 1 .6.7 1 1.6 1 2.7v1h-5.5c0 .8.2 1.4.7 1.9.4.4 1 .6 1.7.6.5 0 .9 0 1.4-.1.4-.1.9-.2 1.4-.5v1.4c-.4.2-.9.4-1.3.4-.7.1-1.2.2-1.7.2zm-.4-7.3c-.6 0-1 .2-1.3.5-.3.4-.5.9-.6 1.6h3.8c0-.7-.2-1.2-.5-1.6-.4-.3-.8-.5-1.4-.5zM18.6 36.3v-1.5h3.9v1.5h-3.9zM28.2 39.6h-1.8v-9.4h-3.2v-1.5h8.3v1.5h-3.2v9.4zM32.8 29.1c0-.3.1-.6.3-.7.2-.2.4-.3.7-.3.3 0 .6.1.7.3.2.2.3.4.3.7 0 .3-.1.5-.3.7-.2.2-.4.3-.7.3-.3 0-.6-.1-.7-.3-.2-.1-.3-.4-.3-.7zm1.9 10.5h-1.8v-8.3h1.8v8.3zM44.2 39.6h-1.8v-5.1c0-.6-.1-1.1-.4-1.4-.2-.3-.6-.5-1.1-.5-.7 0-1.2.2-1.5.7-.3.4-.5 1.2-.5 2.2v4.1h-1.8v-8.3h1.4l.2 1.1h.3c.2-.4.6-.7 1-.9.4-.2.9-.3 1.4-.3 1.3 0 2.1.4 2.5 1.3h.1c.2-.4.6-.7 1-1 .4-.2 1-.3 1.5-.3 1 0 1.7.3 2.2.8s.7 1.3.7 2.3v5.4h-1.8v-5.1c0-.6-.1-1.1-.4-1.4-.2-.3-.6-.5-1.1-.5-.7 0-1.2.2-1.5.6-.3.4-.5 1.1-.5 2v4.3zM55.6 39.8c-1.3 0-2.3-.4-3-1.1-.7-.8-1.1-1.8-1.1-3.1 0-1.4.3-2.4 1-3.2.7-.8 1.6-1.2 2.8-1.2 1.1 0 2 .3 2.6 1 .6.7 1 1.6 1 2.7v1h-5.5c0 .8.2 1.4.7 1.9.4.4 1 .6 1.7.6.5 0 .9 0 1.4-.1.4-.1.9-.2 1.4-.5v1.4c-.4.2-.9.4-1.3.4-.7.1-1.2.2-1.7.2zm-.3-7.3c-.6 0-1 .2-1.3.5-.3.4-.5.9-.6 1.6h3.8c0-.7-.2-1.2-.5-1.6-.4-.3-.9-.5-1.4-.5z"/></g></IconBase>
  )
}

IconAppETime.propTypes = {
  className: PropTypes.string,
}

export default IconAppETime
/* eslint-enable */
