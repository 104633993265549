import React from 'react'
import classnames from 'classnames'
import Card from 'components/Card'
import Messagebar from 'components/Messagebar'
import { commaFormatNumber } from 'utils/numbers'
import { isDefaultEngagement } from 'utils/business/jobs'
import { formatDateShort } from 'utils/dates'
import IconPencil from 'icons/IconPencil'
import { modalShow } from 'actions/modals'
import { MODAL_EDIT_JOB_NAME } from 'constants/forms'
import { useDispatch } from 'react-redux'
import WarningIcon from '@material-ui/icons/Warning'
import PropTypes from 'prop-types'
import styles from './JobDetailsReview.scss'

const UploadedData = ({ tables }) => {
  const errorCount = tables
    .map(x => x.filesWithErrorsCount)
    .reduce((a, b) => a + b, 0)

  return (
    <div>
      {errorCount > 0 && (
        <Messagebar className={styles.messageBar} type="warn-grey">
          You have unresolved data errors
          <div className={styles.warning}>{`${errorCount} files with warnings`}</div>
        </Messagebar>
      )}
      <ul>
        {tables.map(t => (
          <li key={t.tableCode}>
            {`${t.tableName} (${commaFormatNumber(t.rowCount)} rows)`}
          </li>
        ))}
      </ul>
      <Messagebar type="info">
        Additional data checks will be performed as part of the analysis
      </Messagebar>
    </div>
  )
}

const JobName = ({ dispatch, name }) => {
  return (
    <div className={styles.jobName}>
      {name}
      <a onClick={() => dispatch(modalShow(MODAL_EDIT_JOB_NAME))} title="Edit job name">
        <IconPencil
          height="15px"
          title="Edit job name"
          width="15px"
        />
      </a>
    </div>
  )
}

const JobRuns = ({ runCount, runLimit }) => {
  const hasLimit = runLimit && runLimit >= 0
  const reachedLimit = hasLimit && runCount >= runLimit
  return hasLimit
    ? (
      <div className={reachedLimit ? styles.runWarning : null}>
        {reachedLimit && <WarningIcon fontSize="small" />}
        {' '}
        {`${runCount} (${runLimit - runCount} remaining)`}
      </div>
    ) : (
      <div>{runCount}</div>
    )
}

const ChargeInfo = () => {
  return (
    <div>
      <Messagebar noBottomMargin type="info">
        Your engagement will be charged after you click &quot;Run Analysis&quot; and receive your reports.
      </Messagebar>
    </div>
  )
}

const makeDetail = (heading, value) => ({ heading, value })
const makeDetailConditional = (condition, heading, value) => (condition ? [makeDetail(heading, value)] : [])

const JobDetailsReview = ({ job, isExternal }) => {
  const dispatch = useDispatch()
  const useCustomCodes = isDefaultEngagement(job.engagementCode)

  const details = [
    makeDetail('Job name', <JobName dispatch={dispatch} name={job.name} />),
    ...makeDetailConditional(!isExternal, 'Engagement code', useCustomCodes ? job.customEngagementCode : job.engagementCode),
    ...makeDetailConditional(useCustomCodes, 'Client code', job.customClientCode),
    ...makeDetailConditional(!isExternal, '', <ChargeInfo />),
    makeDetail('Package', job.packageName),
    makeDetail('Period of analysis', job.analysisPeriod),
    ...makeDetailConditional(!isExternal, 'Use data for benchmarking', job.benchmarkAllowed ? 'Yes' : 'No'),
    makeDetail('Completed job runs', (
      <JobRuns runCount={job.noOfRuns} runLimit={job.executionLimit} />
    )),
    ...makeDetailConditional(job.expiryDate, 'Job expires on', formatDateShort(job.expiryDate)),
    makeDetail(`Selected analys${job.analyses.length === 1 ? 'is' : 'es'}`, (
      <ul>
        {job.analyses
          .filter(a => a.selected)
          .map(a => <li key={a.id}>{a.name}</li>)}
      </ul>
    )),
    ...makeDetailConditional(job.tables && job.tables.length > 0, 'Data uploaded', (
      <UploadedData tables={job.tables} />
    )),
  ]

  return (
    <div className={styles.base}>
      <Card noSidePadding className={styles.review}>
        {details.map(d => (
          <div className="row" key={d.heading}>
            <div className={classnames(styles.heading, 'col-xs-6 col-sm-offset-1 col-sm-3 col-md-offset-2 col-md-2')}>
              {d.heading}
            </div>
            <div className="col-xs-6 col-sm-7 col-md-6">
              {d.value}
            </div>
          </div>
        ))}

      </Card>
    </div>
  )
}

UploadedData.propTypes = {
  tables: PropTypes.arrayOf(PropTypes.object).isRequired,
}

JobName.propTypes = {
  dispatch: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
}

JobRuns.propTypes = {
  runCount: PropTypes.number.isRequired,
  runLimit: PropTypes.number.isRequired,
}

JobDetailsReview.propTypes = {
  isExternal: PropTypes.bool.isRequired,
  job: PropTypes.object.isRequired,
}

export default JobDetailsReview
