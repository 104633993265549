/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconUsersInCircle = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--usersincircle',
    className,
  )

  return (
    <IconBase className={classes} viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg"{...rest}><title>Group</title><g transform="translate(1 1)" fill="none" fillRule="evenodd"><circle stroke="#009BD7" strokeWidth="2" cx="24" cy="24" r="24"/><path className="path1" d="M12 12h24v24H12z"/><path className="path2" d="M28 23c1.66 0 2.99-1.34 2.99-3s-1.33-3-2.99-3c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3s-1.33-3-2.99-3c-1.66 0-3 1.34-3 3s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V31h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V31h6v-2.5c0-2.33-4.67-3.5-7-3.5z" fill="#009BD7" fillRule="nonzero"/></g></IconBase>
  )
}

IconUsersInCircle.propTypes = {
  className: PropTypes.string,
}

export default IconUsersInCircle
/* eslint-enable */
