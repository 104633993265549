import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import some from 'lodash/some'
import filter from 'lodash/filter'
import Heading from 'components/Heading'
import Card from 'components/Card'
import CardFooter from 'components/CardFooter'
import { Button } from '@deloitte/gel-library'
import { IconCollection, IconDocument } from 'icons'
import Dialog from 'components/Dialog'
import addDataIcon from 'images/add-data.svg'
import UndoIcon from '@material-ui/icons/Undo'
import { Tooltip } from '@material-ui/core'
import { useSelector } from 'react-redux'
import styles from './FileUploader.scss'

const Pipe = () => {
  // Test comment
  return <div className={styles.pipe} />
}

const FileList = ({ files, keyProperty }) => {
  return (
    <ul>
      {files.map(file => (
        <li className={styles.file} key={file[keyProperty]}>
          <IconDocument className={styles.documentIcon} />
          {file.name}
        </li>
      ))}
    </ul>
  )
}

FileList.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  keyProperty: PropTypes.string.isRequired,
}

class FileUploader extends React.Component {
  static propTypes = {
    cancel: PropTypes.func.isRequired,
    createRevertDialog: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    hideRevertDialog: PropTypes.func.isRequired,
    hideSavedFiles: PropTypes.bool,
    job: PropTypes.shape({
      recipes: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
    openModal: PropTypes.func.isRequired,
    packageInputTableMeta: PropTypes.object.isRequired,
    revertDialog: PropTypes.object,
    revertUploads: PropTypes.func.isRequired,
    showRevertDialog: PropTypes.func.isRequired,
    uploader: PropTypes.object.isRequired,
  }

  static defaultProps = {
    disabled: false,
    revertDialog: null,
    hideSavedFiles: false,
  }

  componentDidMount() {
    const { createRevertDialog } = this.props
    createRevertDialog()
  }

  render() {
    const {
      disabled,
      uploader,
      openModal,
      revertUploads,
      revertDialog,
      showRevertDialog,
      hideRevertDialog,
      hideSavedFiles,
      job,
      packageInputTableMeta,
    } = this.props

    const savedFiles = hideSavedFiles ? [] : filter(uploader.files, ['status', 'saved'])
    const pendingFiles = filter(uploader.files, ['status', 'pending'])
    const { packageId, files } = job
    const isUploaderOptional = files?.find(file => file.tableCode === uploader.id)?.isOptional
    const countSaved = savedFiles.length
    const countUpload = pendingFiles.length

    const isUsingExistingFiles = countSaved > 0 && countUpload === 0
    const isUsingUploadedFiles = countUpload > 0
    const isEmpty = countUpload === 0 && countSaved === 0

    const lastUploadedDate = countSaved > 0 ? uploader.lastUploaded : null

    const recipe = uploader.dataRecipe
      ? job.recipes.find(r => r.name === uploader.dataRecipe)
      : null

    let uploaderStatus = 'upload'
    let errorText = 'Errors found'
    if (Object.keys(uploader.files).length > 0) {
      if (some(uploader.files, ['status', 'uploaded'])) {
        uploaderStatus = 'uploaded'
      }
      if (some(uploader.files, ['status', 'uploading'])) {
        uploaderStatus = 'uploading'
      }
    }

    if (uploader.errors.length > 0) {
      uploaderStatus = 'error'
      errorText = uploader.errors[0].error || errorText
    }

    let addLabel = 'Add'
    if (isUsingExistingFiles) {
      addLabel = 'Replace'
    }
    if (isUsingUploadedFiles) {
      addLabel = 'Edit'
    }

    const pluralize = numElements => (numElements > 1 || numElements === 0 ? 's' : '')
    let uploadText
    if (isUsingExistingFiles) {
      uploadText = (
        <span className={styles.subtitle}>
          <IconCollection className={styles.collectionIcon} />
          <span>
            {countSaved}
            {' file'}
            {pluralize(countSaved)}
            {' '}
            {countSaved > 1 ? 'were' : 'was'}
            {' previously uploaded'}
          </span>
          {uploader.rowCount > 0 && (
            <Fragment>
              <Pipe />
              {`${uploader.rowCount.toLocaleString()} rows`}
            </Fragment>
          )}
        </span>
      )
    } else if (isUsingUploadedFiles) {
      uploadText = (
        <span className={styles.subtitle}>
          <IconCollection className={styles.collectionIcon} />
          {`${countUpload} file${pluralize(countUpload)} pending upload`}
        </span>
      )
    } else {
      uploadText = (
        <a
          data-test-id={`fileUploaderModal-${uploader.name}`}
          onClick={openModal}
        >
          <img alt="Add data" src={addDataIcon} />
          <span>Add data</span>
        </a>
      )
    }

    const dialogProps = {
      actions: [
        {
          label: 'CANCEL',
          onClick: () => {
            hideRevertDialog()
          },
        },
        {
          label: 'REVERT',
          onClick: () => {
            revertUploads()
            hideRevertDialog()
          },
          primary: true,
        },
      ],
      active: revertDialog ? revertDialog.show : false,
      onClose: () => {
        hideRevertDialog()
      },
      title: 'Revert to original?',
    }

    return (
      <Card noPadding>
        <div className={styles.card}>
          <div className={styles.fileInfo}>
            <Heading noPadding className={styles.title} size="xSmall">
              {uploader.name}
              {recipe && <span className={styles.recipe}>{recipe.meta.displayName}</span>}
              {isUploaderOptional
                && (
                  <Tooltip arrow title={packageInputTableMeta?.descriptors['optional_analysis_insights_description']}>
                    <span className={styles.optionalChip}>Optional</span>
                  </Tooltip>
                )
              }
            </Heading>
            <p className={styles.description}>{uploader.description}</p>
          </div>
          <div className={styles.fileOtherInfo}>
            {countUpload > 0
              && countSaved > 0 && (
                <Button
                  className={styles.revertButton}
                  icon={<UndoIcon />}
                  mode="flat"
                  onClick={showRevertDialog}
                >
                  Revert
                </Button>
            )}
          </div>
        </div>

        <div className={styles.fileDetailContainer}>
          <div
            className={classnames(
              styles.fileDetailBox,
              isUsingUploadedFiles ? styles.pending : null,
              isEmpty ? styles.empty : null,
            )}
          >
            {isUsingExistingFiles && (
              <div className={styles.lastRun}>
                {`Uploaded ${lastUploadedDate.format('DD MMM YYYY h:mma')}`}
              </div>
            )}

            {uploaderStatus === 'upload' && uploadText}
            {uploaderStatus === 'error' && (
              <span className={styles.subtitle}>
                <span className={styles.labelRed}>{errorText}</span>
              </span>
            )}

            {isUsingExistingFiles && (
              <FileList files={savedFiles} keyProperty="fileId" />
            )}
            {isUsingUploadedFiles && (
              <FileList files={pendingFiles} keyProperty="fileId" />
            )}
          </div>
        </div>

        {!isEmpty && (
          <CardFooter right>
            <Button
              disabled={disabled || uploaderStatus === 'uploading'}
              mode="flat"
              onClick={openModal}
            >
              {addLabel}
            </Button>
          </CardFooter>
        )}

        <Dialog {...dialogProps}>
          <p>Any new data files will be removed.</p>
        </Dialog>
      </Card>
    )
  }
}

export default FileUploader
