import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Heading } from '@deloitte/gel-library'
import PropTypes, { number } from 'prop-types'
import Animate from 'components/Animate'
import OverlayModal from 'components/OverlayModal'
import { modalHide } from 'actions/modals'
import CaseTestingConclusion from 'components/CaseTestingConclusion'
import useTask from 'actionHub/hooks/useTask'
import { useJobPropTypes } from 'hooks/useJob'
import { jobPermissions } from 'utils/permissions'
import Messagebar from 'components/Messagebar'
import styles from './BulkUpdateModal.scss'

const propTypes = {
  actionSetId: PropTypes.string.isRequired,
  modalId: PropTypes.string.isRequired,
  selectedTasks: PropTypes.arrayOf(number),
  setSelectedTasks: PropTypes.func.isRequired,
  useJob: useJobPropTypes.isRequired,
}

const defaultProps = {
  selectedTasks: [],
}

const BulkUpdateModal = ({
  useJob, actionSetId, modalId, selectedTasks, setSelectedTasks,
}) => {
  const dispatch = useDispatch()
  const modals = useSelector(state => state.modals)

  const {
    data, setLabelValue, isFetched, submitTaskLabelsBulkUpdate, validateLabel,
  } = useTask(actionSetId, selectedTasks[0], selectedTasks)

  const selectedTaskCount = useMemo(() => {
    return selectedTasks.length
  }, [selectedTasks])

  const {
    userHasJobPermission, job: { hasExpired },
  } = useJob

  if (!isFetched) {
    return null
  }

  const canEditActions = userHasJobPermission(jobPermissions.actionHubEdit)
  const disabled = !canEditActions || hasExpired

  const handleOnClose = () => {
    dispatch(modalHide(modalId))
  }

  return (
    <OverlayModal
      base={10}
      disable={false}
      id={modalId}
      init={() => { }}
      modals={modals}
      onClose={handleOnClose}
      push={4}
    >
      <Animate name="fade">
        <div className={styles.base}>

          <Heading className={styles.title} level={5}>
            {`Bulk update ${selectedTasks.length} selected cases`}
          </Heading>
          <Messagebar className={styles.updateWarning} type="info">
            <div>
              <strong>Updates entered will overwrite existing values</strong>
            </div>
          </Messagebar>

          <CaseTestingConclusion
            disabled={disabled}
            handleClose={handleOnClose}
            object={data}
            selectedTaskCount={selectedTaskCount}
            selectedTasks={selectedTasks}
            setLabelValue={setLabelValue}
            setSelectedTasks={setSelectedTasks}
            submitLabels={() => submitTaskLabelsBulkUpdate(modalId)}
            validateLabel={validateLabel}
          />
        </div>

      </Animate>
    </OverlayModal>
  )
}

BulkUpdateModal.propTypes = propTypes
BulkUpdateModal.defaultProps = defaultProps

export default BulkUpdateModal
