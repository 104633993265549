/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconFrineBenefitsTaxReturns = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--frinebenefitstaxreturns',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"{...rest}><title>Fill 1</title><g fill="#58595B"><path className="path1" d="M21.4 4.4L17.8.8c-.5-.5-1.3-.8-1.9-.8h-12c-.8 0-1.4.7-1.4 1.5v21.1c0 .8.7 1.5 1.5 1.5h16.7c.8 0 1.5-.7 1.5-1.5V6.2c-.1-.6-.4-1.4-.8-1.8zm-1.6.3h-2.4V2.3l2.4 2.4zm1 17.8c0 .1-.1.1-.1.1H4c-.1 0-.1-.1-.1-.1v-21c0-.1.1-.1.1-.1h12.1v4c0 .4.3.7.7.7h4V22.5z"/><path className="path2" d="M7.5 10.9h3.6c.4 0 .7-.3.7-.7s-.3-.7-.7-.7H7.5c-.4 0-.7.3-.7.7s.3.7.7.7zM17.7 16.7h-1.2v-1.2c0-.4-.3-.7-.7-.7-.4 0-.7.3-.7.7v1.2H14c-.4 0-.7.3-.7.7s.3.6.7.6h1.2v1.2c0 .4.3.7.7.7.4 0 .7-.3.7-.7V18h1.2c.4 0 .7-.3.7-.7s-.5-.6-.8-.6zM16.9 9.3c-.3-.3-.7-.3-.9 0l-9 8.9c-.3.3-.3.7 0 .9.1.1.3.2.5.2s.3-.1.5-.2l8.9-8.9c.2-.2.2-.6 0-.9z"/></g></IconBase>
  )
}

IconFrineBenefitsTaxReturns.propTypes = {
  className: PropTypes.string,
}

export default IconFrineBenefitsTaxReturns
/* eslint-enable */
