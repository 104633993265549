import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Card from 'components/Card'
import { formatDateShort } from 'utils/dates'
import { Button, Heading } from '@deloitte/gel-library'
import {
  IconLinkOpenInNew,
  IconPencil,
} from 'icons'
import Radio from 'components/Form/components/Radio'
import WarningText from 'components/WarningText'
import { useDispatch, useSelector } from 'react-redux'
import DialogSimple from 'components/DialogSimple'
import { jobIsProcessing } from 'utils/business/workflow'
import {
  cloneJobDetails,
  resetCloningState,
  setBenchmarkFlag,
} from 'actions/job'
import { isDefaultEngagement, isManagerOrPartnerRole } from 'utils/business/jobs'
import { modalShow } from 'actions/modals'
import { MODAL_EDIT_JOB_NAME, RE_ACTIVATE_JOB_MODAL } from 'constants/forms'
import WarningIcon from '@material-ui/icons/Warning'
import { push } from 'connected-react-router'
import useJobCalculated from 'hooks/useJobCalculated'
import useJob from 'hooks/useJob'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import styles from './JobDetailSidebar.scss'
import ReActivateJobModal from '../../components/ReActivateJobModal'

const propTypes = {
  criticalValidations: PropTypes.arrayOf(PropTypes.object),
  job: PropTypes.object.isRequired,
  jobBanner: PropTypes.object,
  supportUrl: PropTypes.string,
}

const defaultProps = {
  criticalValidations: [],
  jobBanner: null,
  supportUrl: null,
}

function JobDetailSidebar({
  job, jobBanner, supportUrl, criticalValidations,
}) {
  const {
    jobId, closed, tables,
    clientName, engagement, dpFrom, dpTo, lastExecutionDate, completedExecutionCount,
    myaId, name, engagementCode, createdBy, createdDate, benchmarkingEnabled,
    customEngagementCode, customClientCode, expiryDate, hasExpired, noOfRuns, executionLimit, hasExecutionLimit, jobStatusDesc,
  } = job
  const reachedExecutionLimit = hasExecutionLimit && noOfRuns >= executionLimit
  const dispatch = useDispatch()
  const isExternal = useSelector(state => state.app.user.isExternal)
  const [stateBenchmarkingEnabled, setStateBenchmarkingEnabled] = useState(benchmarkingEnabled)
  const [showDialog, setShowDialog] = useState(false)
  const { showExpiryInfoBanner } = useJob(job.jobId, isExternal)

  const { isCalculated, editDataLink: { url: editDataUrl, name: editDataName } } = useJobCalculated(jobId)

  let editDataTitle = 'Update data or update parameters'
  if (closed) {
    editDataTitle = 'Cannot re-run job when job is closed'
  }
  if (!engagement.active) {
    editDataTitle = 'Cannot re-run job when engagement is closed'
  }

  const handleAddEditData = () => {
    dispatch(resetCloningState())
    dispatch(push(editDataUrl))
  }

  return (
    <div className={styles.common_information}>
      <div className={styles.jobHeader}>
        <Heading level={8}>Job details</Heading>
        {!isExternal && (
        <span>
          <Button
            className={jobStatusDesc === 'ReportGenerated' ? styles.copyJobButton : null}
            disabled={jobStatusDesc !== 'ReportGenerated'}
            onClick={() => dispatch(cloneJobDetails(jobId))}
            startIcon={<FileCopyIcon />}
            variant="text"
          >
            COPY JOB
          </Button>
        </span>
        )}
      </div>

      <Card className={styles.card}>
        <div>
          {jobBanner && (
          <div
            className={classnames(styles.cardInner, styles.jobBanner)}
          >
            <p>
              <strong>{jobBanner.TITLE}</strong>
              <br />
              {`${jobBanner.BODY} `}
              {jobBanner.SUPPORT_ARTICLE && supportUrl && (
                <a
                  href={`${supportUrl}/${jobBanner.SUPPORT_ARTICLE}`}
                >
                  Learn more
                  <IconLinkOpenInNew className={styles.openInNew} />
                </a>
              )}
            </p>
          </div>
          )}

          <div className={styles.cardInner}>
            <p className={styles.label}>
              Name
              {' '}
              <a onClick={() => dispatch(modalShow(MODAL_EDIT_JOB_NAME))} title="Edit job name">
                <IconPencil
                  height="15px"
                  title="Edit job name"
                  width="15px"
                />
              </a>
            </p>
            <p>{`${name}`}</p>

            <p className={styles.label}>Job reference no.</p>
            <p>{`${myaId}`}</p>

            {!isExternal && (
              <Fragment>
                {clientName && (
                  <>
                    <p className={styles.label}>Client</p>
                    <p>{clientName}</p>
                  </>
                )}
                {isDefaultEngagement(engagement.code)
                  ? (
                    <>

                      <p className={styles.label}>
                        Client code
                      </p>
                      <p>{customClientCode}</p>

                      <p className={styles.label}>
                        Engagement code
                      </p>
                      <p>{customEngagementCode}</p>
                    </>
                  )
                  : (
                    <>
                      <p className={styles.label}>
                        Engagement
                        {engagement.active ? '' : ' (Closed)'}
                      </p>
                      <p>{`${engagementCode} - ${engagement.name}`}</p>
                    </>
                  ) }
              </Fragment>
            )}

            <p className={styles.label}>Analysis period</p>
            <p>{`${formatDateShort(dpFrom)} – ${formatDateShort(dpTo)}`}</p>

            <p className={styles.label}>Created by</p>
            <p>
              {createdBy && `${createdBy.firstName} ${createdBy.surname}`}
              <br />
              {createdDate && createdDate.format('DD MMM YYYY [at] h:mma')}
            </p>

            {expiryDate && (
              <Fragment>
                <p className={styles.label}>Expiry date</p>
                <p className={hasExpired ? styles.runWarning : null}>
                  {hasExpired && <WarningIcon fontSize="small" />}
                  <span>{formatDateShort(expiryDate)}</span>
                </p>
              </Fragment>
            )}

            <p className={styles.label}>Completed job runs</p>
            {hasExecutionLimit
              ? (
                <p className={reachedExecutionLimit ? styles.runWarning : null}>
                  {reachedExecutionLimit && <WarningIcon fontSize="small" />}
                  <span>{`${completedExecutionCount} (${executionLimit - completedExecutionCount} remaining)`}</span>
                </p>
              )
              : <p>{noOfRuns}</p>
            }

            <p className={styles.label}>Last run</p>
            <p>{formatDateShort(lastExecutionDate)}</p>

          </div>
        </div>
        <div>
          <div className={styles.cardInner}>
            {!isCalculated && (
              <>
                <p className={styles.label}>
                  Data uploaded
                  {' '}
                  {criticalValidations.length > 0 && (
                  <span className={styles.warningText}><WarningText text="Critical issues" /></span>
                  )}
                </p>
                <div>
                  <ul>
                    {tables.map(t => (
                      <li key={t.tableCode}>{t.tableName}</li>
                    ))}
                  </ul>
                </div>
              </>
            )}
            {!isExternal && (
              <>
                <p className={styles.label}>
                  Allow data for benchmarking
                  {' '}
                  <a onClick={() => setShowDialog(true)} title="Edit benchmarking option">
                    <IconPencil
                      height="15px"
                      title="Edit benchmarking option"
                      width="15px"
                    />
                  </a>
                </p>
                <p>
                  {benchmarkingEnabled}
                </p>
              </>
            )}
            <div className={styles.cta}>
              {(showExpiryInfoBanner) && isManagerOrPartnerRole(job?.member?.role?.name) ? (
                <Button
                  mode="secondary"
                  onClick={() => dispatch(modalShow(RE_ACTIVATE_JOB_MODAL))}
                >
                  REACTIVATE JOB
                </Button>
              ) : (
                <Button
                  disabled={!job.canExecute || !engagement.active || (job && jobIsProcessing(job)) || closed}
                  mode="secondary"
                  onClick={() => handleAddEditData()}
                  title={editDataTitle}
                >
                  {editDataName}
                </Button>
              )}
            </div>
          </div>
        </div>
      </Card>
      <DialogSimple
        actionLabel="confirm"
        dismissLabel="cancel"
        isOpen={showDialog}
        onAction={() => {
          setShowDialog(false)
          // Set BED request
          dispatch(
            setBenchmarkFlag({
              jobId,
              benchmarkAllowed: stateBenchmarkingEnabled === 'Yes',
            }),
          )
        }}
        onDismiss={() => setShowDialog(false)}
      >
        <Heading level={7}>Allow data for benchmarking</Heading>
        <Radio
          horizontal
          noPadding
          helperText="I have confirmed with the engagement partner that the benchmarking clause has not been removed from the engagement letter."
          label="Would you like to authorise the use of this data for benchmarking purposes?"
          name="benchmarkingEnabled"
          onChange={(_, value) => setStateBenchmarkingEnabled(value)}
          options={[
            { label: 'Yes', value: 'Yes' },
            { label: 'No', value: 'No' },
          ]}
          value={stateBenchmarkingEnabled}
        />
      </DialogSimple>
      <ReActivateJobModal
        originalExpiryDate={expiryDate}
        originalNoOfRuns={executionLimit}
      />

    </div>
  )
}

JobDetailSidebar.propTypes = propTypes
JobDetailSidebar.defaultProps = defaultProps

export default JobDetailSidebar

