/**
 * Animate
 * built using https://facebook.github.io/react/docs/animation.htm
 * and utilising custom classes https://facebook.github.io/react/docs/animation.html#custom-classes
 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import find from 'lodash/find'
import { CSSTransition } from 'react-transition-group'
import { TIMEOUTS } from './animations/animations'

import styles from './Animate.scss'

export default class Animate extends Component {
  static propTypes = {
    appear: PropTypes.bool,
    appearTimeout: PropTypes.number,
    buffer: PropTypes.number,
    children: PropTypes.node,
    enterTimeout: PropTypes.number,
    leaveTimeout: PropTypes.number,
    name: PropTypes.oneOf(['fade', 'shake', 'slideIn', 'slideInFromRight']).isRequired,
    toggle: PropTypes.bool,
  }

  static defaultProps = {
    appear: null,
    appearTimeout: null,
    buffer: 100,
    children: null,
    enterTimeout: null,
    leaveTimeout: null,
    toggle: false,
  }

  // notes: https://facebook.github.io/react/docs/animation.html#animate-initial-mounting
  getTransitionName = () => {
    const { appear, name, toggle } = this.props
    // check if is an appear (on initial mounting) animation
    if (appear) {
      return {
        appear: styles[`${name}Appear`],
        appearActive: styles[`${name}AppearActive`],
      }
    }
    return {
      enter: styles[`${name}Enter`],
      enterActive: styles[`${name}EnterActive`],
      leave: (toggle) ? styles[`${name}ToggleLeave`] : styles[`${name}Leave`],
      leaveActive: (toggle) ? styles[`${name}ToggleLeaveActive`] : styles[`${name}LeaveActive`],
    }
  }

  getTransitionTimeouts = () => {
    // get transition times based on associated animation. this maps the the
    // transition's duration property, or in the instance of appear, the
    // duration + delay + 100ms buffer to allow the animation to end smoothly
    const { buffer, name } = this.props
    const animationTimeouts = find(TIMEOUTS, timeout => timeout.name === name).timeouts
    const { appearTimeout, enterTimeout, leaveTimeout } = animationTimeouts

    // add a render buffer to each transition type
    Object.keys(animationTimeouts).forEach((key) => {
      animationTimeouts[key] += buffer
    })

    return {
      appearTimeout,
      enterTimeout,
      leaveTimeout,
    }
  }

  render() {
    const { props } = this
    const { appear, children } = props
    const { appearTimeout, enterTimeout, leaveTimeout } = this.getTransitionTimeouts()
    const transitionName = this.getTransitionName()
    const transitionProps = {}

    // build the transition name
    transitionProps.classNames = transitionName

    // build the transition timeouts
    if (appear) {
      transitionProps.timeout = {
        enter: props.appearTimeout || appearTimeout,
      }
      transitionProps.appear = true
    } else {
      transitionProps.timeout = {
        enter: props.enterTimeout || enterTimeout,
        exit: props.leaveTimeout || leaveTimeout,
      }
    }

    return (
      <CSSTransition {...transitionProps}>
        {() => children}
      </CSSTransition>
    )
  }
}
