/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconFileUploadError = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--fileuploaderror',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46 46"{...rest}><g fill="none" fillRule="evenodd"><path className="path1" d="M-4-4h54v54H-4z"/><path className="path2" fill="#E76C5E" fillRule="nonzero" d="M23 .5C10.58.5.5 10.58.5 23S10.58 45.5 23 45.5 45.5 35.42 45.5 23 35.42.5 23 .5zm2.25 33.75h-4.5v-4.5h4.5v4.5zm0-9h-4.5v-13.5h4.5v13.5z"/></g></IconBase>
  )
}

IconFileUploadError.propTypes = {
  className: PropTypes.string,
}

export default IconFileUploadError
/* eslint-enable */
