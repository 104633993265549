import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ClickListener from 'components/ClickListener'
import Avatar from 'components/Avatar'
import { signOut } from 'utils/api'
import { Button } from '@deloitte/gel-library'

import classNames from 'classnames'
import styles from './UserAvatarPopover.scss'

function UserAvatarPopover({ user, className, align }) {
  const [showPopover, setShowPopover] = useState(false)

  return (
    <div
      className={classNames(styles.avatar, className)}
      onClick={() => setShowPopover(true)}
    >
      <Avatar
        nameArray={[user.firstName, user.surname]}
        size="medium"
        userDetails={user}
      />
      {showPopover && (
      <ClickListener
        className={classNames(styles.popover, styles[align])}
        onClickOutside={() => setShowPopover(false)}
      >
        <div className={styles.userDetails}>
          <Avatar
            className={styles.popoverAvatar}
            nameArray={[user.firstName, user.surname]}
            size="large"
            userDetails={user}
          />
          <div className={styles.userProfile}>
            <div className={styles.name}>
              {`${user.firstName} ${user.surname}`}
            </div>
            <div className={styles.email} title={user.email}>
              {user.email}
            </div>
          </div>
        </div>
        <div className={styles.buttonRow}>
          <Button mode="flat" onClick={signOut}>Log out</Button>
        </div>
      </ClickListener>
      )}
    </div>
  )
}

UserAvatarPopover.propTypes = {
  align: PropTypes.oneOf(['default', 'right']),
  className: PropTypes.string,
  user: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    surname: PropTypes.string,
  }).isRequired,
}

UserAvatarPopover.defaultProps = {
  align: 'default',
  className: null,
}

export default UserAvatarPopover

