import {
  useCallback, useEffect, useState,
} from 'react'
import usePrevious from './usePrevious'

export default function useInfiniteScroll(parentRef, innerRef, onLoadMore) {
  const [hasReachedBottom, setHasReachedBottom] = useState(false)
  const prevReachedBottom = usePrevious(hasReachedBottom)

  const handleScroll = useCallback(() => {
    const bottomPosition = innerRef?.current.getBoundingClientRect().bottom
    const clientWindowHeight = window.innerHeight
    setHasReachedBottom(clientWindowHeight - bottomPosition > -30)
  }, [innerRef])

  useEffect(() => {
    const currentParentRef = parentRef?.current
    if (currentParentRef) {
      currentParentRef.addEventListener('scroll', handleScroll)
    }
    return () => {
      if (currentParentRef) {
        currentParentRef.removeEventListener('scroll', handleScroll)
      }
    }
  }, [handleScroll, parentRef])

  useEffect(() => {
    if (hasReachedBottom && !prevReachedBottom) {
      onLoadMore()
    }
  }, [hasReachedBottom, prevReachedBottom, onLoadMore])
}
