import { getAllParameters } from 'utils/business/jobs'
import { objectFromArrayWithFunctions } from 'utils/objects'
import { isNullOrWhiteSpace } from 'utils/strings'

export const prepareValueForComparison = (labelType, value) => {
  switch (labelType) {
    case 'date':
      return value ? value.substr(0, 10) : value
    default:
      return value
  }
}

const operatorTypes = {
  Null: 0,
  NotNull: 1,
  Equals: 2,
  NotEquals: 3,
  GreaterThan: 4,
  GreaterThanOrEqual: 5,
  LessThan: 6,
  LessThanOrEqual: 7,
}

export const validateWithDependencies = (labelId, value, dependencyValidations, validationFuntion) => {
  validationFuntion(labelId)
  if (dependencyValidations === null) {
    return
  }

  dependencyValidations.forEach((dependency) => {
    const validationName = dependency.dataColumnName || dependency.labelName
    switch (dependency.operator) {
      case operatorTypes.Equals:
        if (dependency.validationValue !== value) {
          validationFuntion(labelId, `Must be equal to ${validationName} (${dependency.validationValue})`)
        }
        break
      case operatorTypes.NotEquals:
        if (dependency.validationValue === value) {
          validationFuntion(labelId, `Must not be equal to ${validationName} (${dependency.validationValue})`)
        }
        break
      case operatorTypes.GreaterThan:
        if (dependency.validationValue <= value) {
          validationFuntion(labelId, `Must be less than ${validationName} (${dependency.validationValue})`)
        }
        break
      case operatorTypes.GreaterThanOrEqual:
        if (dependency.validationValue < value) {
          validationFuntion(labelId, `Must be less than or equal to ${validationName} (${dependency.validationValue})`)
        }
        break
      case operatorTypes.LessThan:
        if (dependency.validationValue >= value) {
          validationFuntion(labelId, `Must be greater than ${validationName} (${dependency.validationValue})`)
        }
        break
      case operatorTypes.LessThanOrEqual:
        if (dependency.validationValue > value) {
          validationFuntion(labelId, `Must be greater than or equal to ${validationName} (${dependency.validationValue})`)
        }
        break

      default:
        break
    }
  })
}

export const isConditionallyDisabled = (dependency) => {
  switch (dependency.type) {
    case 1: {
      switch (dependency.operator) {
        case operatorTypes.Null:
          if (!isNullOrWhiteSpace(dependency.validationValue)) {
            return true
          }
          break
        case operatorTypes.NotNull:
          if (isNullOrWhiteSpace(dependency.validationValue)) {
            return true
          }
          break
        case operatorTypes.Equals:
          if (dependency.validationValue !== dependency.value) {
            return true
          }
          break
        case operatorTypes.NotEquals:
          if (dependency.validationValue === dependency.value) {
            return true
          }
          break
        case operatorTypes.GreaterThan:
          if (dependency.validationValue <= dependency.value) {
            return true
          }
          break
        case operatorTypes.GreaterThanOrEqual:
          if (dependency.validationValue < dependency.value) {
            return true
          }
          break
        case operatorTypes.LessThan:
          if (dependency.validationValue >= dependency.value) {
            return true
          }
          break
        case operatorTypes.LessThanOrEqual:
          if (dependency.validationValue > dependency.value) {
            return true
          }
          break
        default:
          break
      }
      break
    }
    case 0:
      break
    default:
      break
  }
  return false
}

export const isConditionallyDisabledFromLabelValues = (label, labels) => {
  const dependencies = label.dependencies.map(d => ({
    ...d,
    validationValue: labels.find(l => d.labelName === l.name)?.value,
  }))
  return dependencies.some(d => isConditionallyDisabled(d))
}

export const isConditionallyDisabledFromParameterValues = (parameter, currentParameterValues) => {
  if (parameter.dependencies) {
    const myDependenciesValueCheck = parameter.dependencies.map(d => ({
      ...d,
      validationValue: currentParameterValues[d.parameterName]?.value,
    }))
    return myDependenciesValueCheck.some(d => isConditionallyDisabled(d))
  }
  return false
}

export const isConditionallyDisabledFromParameterDisplay = (parameter, currentParameterValues) => {
  if (parameter.dependencies) {
    const myDependenciesDisplayCheck = parameter.dependencies.map(d => ({
      ...d,
      validationDisplay: currentParameterValues[d.parameterName],
    }))
    return myDependenciesDisplayCheck.some(d => d.validationDisplay === false)
  }
  return false
}

export const getCurrentDisplayValues = (analyses, packageParameters) => {
  const parameters = getAllParameters({ analyses, packageParameters })
  return objectFromArrayWithFunctions(parameters, p => p.id, p => p.display)
}
