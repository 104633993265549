import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Button'
import {
  IconOnboarding01, IconOnboarding02, IconOnboarding03, IconOnboarding04,
} from 'icons'
import { getConfig } from 'utils/config'
import Slider from 'react-slick'
import styles from './SlickSlider.scss'

const propTypes = {
  onAction: PropTypes.func.isRequired,
}

const SimpleSlider = ({ onAction }) => {
  const { WORDMARK } = getConfig()

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  return (
    <div className={styles.container_slick}>
      <Slider
        {...settings}
        className={styles.slider}
        theme={styles}
      >
        <div>
          <h5>
            {`Welcome to ${WORDMARK}`}
          </h5>
          <p className="slick_img_space">
            <IconOnboarding01 width="243" />
          </p>
          <h6>
            Browse our analytic solutions
          </h6>
          <p>
            Explore a range of analytic solutions to gain insights for your client on their risk profile, identify cash uplift opportunities, or detect fraudulent activities.
          </p>
        </div>
        <div>
          <h5>
            {`Welcome to ${WORDMARK}`}
          </h5>
          <p className="slick_img_space">
            <IconOnboarding02 width="243" />
          </p>
          <h6>
            Upload data
          </h6>
          <p>
            Then, upload your client data, set up some input parameters and let the machine do the work.
          </p>
        </div>
        <div>
          <h5>
            {`Welcome to ${WORDMARK}`}
          </h5>
          <p className="slick_img_space">
            <IconOnboarding03 width="243" />
          </p>
          <h6>
            View your reports online
          </h6>
          <p>
            Once your report is available, you can view it any time. We’ll notify you when it’s ready.
          </p>
        </div>
        <div>
          <h5>
            {`Welcome to ${WORDMARK}`}
          </h5>
          <p className="slick_img_space">
            <IconOnboarding04 width="243" />
          </p>
          <h6>
            You’re ready to get started
          </h6>
          <p>
            Still have questions? Please view our
            {' '}
            <a
              href="#/support"
            >
              support articles
            </a>
            {' '}
            or
            {' '}
            <a
              href="#/contact"
            >
              contact our team
            </a>
            .
          </p>

          <p>
            <Button label="Get started" onClick={onAction} />
          </p>
        </div>
      </Slider>
    </div>
  )
}

SimpleSlider.propTypes = propTypes

export default SimpleSlider
