export const UPLOADER_UPDATE_UPLOADERS = 'UPLOADER_UPDATE_UPLOADERS'
export const UPLOADER_SET_CURRENT = 'UPLOADER_SET_CURRENT'
export const UPLOADER_ADD_FILE = 'UPLOADER_ADD_FILE'
export const UPLOADER_UPDATE_FILE = 'UPLOADER_UPDATE_FILE'
export const UPLOADER_DELETE_FILE = 'UPLOADER_DELETE_FILE'
export const UPLOADER_ADD_FILEERROR = 'UPLOADER_ADD_FILEERROR'
export const UPLOADER_RESET_FILEERROR = 'UPLOADER_RESET_FILEERROR'
export const UPLOADER_REMOVE_FILEERROR = 'UPLOADER_REMOVE_FILEERROR'
export const UPLOADER_UPDATE_STATUS = 'UPLOADER_UPDATE_STATUS'

export const updateUploaders = uploaders => ({
  type: UPLOADER_UPDATE_UPLOADERS,
  uploaders,
})

export const addFile = file => ({
  type: UPLOADER_ADD_FILE,
  file,
})

export const updateFile = (file, id) => ({
  type: UPLOADER_ADD_FILE,
  file,
  id,
})

export const updateUploaderFileStatus = (id, fileId, status) => ({
  type: UPLOADER_UPDATE_FILE,
  id,
  fileId,
  status,
})

export const deleteFile = (file, id) => ({
  type: UPLOADER_DELETE_FILE,
  file,
  id,
})

export const addFileError = (error, id) => ({
  type: UPLOADER_ADD_FILEERROR,
  error,
  id,
})

export const resetFileError = () => ({
  type: UPLOADER_RESET_FILEERROR,
})

export const removeFileError = index => ({
  type: UPLOADER_REMOVE_FILEERROR,
  index,
})

export const setCurrentUploader = uploaderIndex => ({
  type: UPLOADER_SET_CURRENT,
  uploaderIndex,
})

export const updateStatus = status => ({
  type: UPLOADER_UPDATE_STATUS,
  status,
})

export function updateUploader(file, id) {
  return (dispatch) => {
    if (typeof file === 'object') {
      if (file.id) {
        dispatch(deleteFile(`T-${file.fileId}`, id))
        dispatch(addFile(file, id))
      } else {
        dispatch(addFile(file, id))
      }
    } else {
      dispatch(deleteFile(`T-${file}`, id))
    }
  }
}
