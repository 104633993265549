import React from 'react'
import { Button, Heading } from '@deloitte/gel-library'
import DialogSimple from 'components/DialogSimple'
import { getConfig } from 'utils/config'
import PropTypes from 'prop-types'
import AddClientImage from 'images/add-client-modal.svg'
import { SUPPORT_PAGES } from 'constants/supportPages'
import LinkRenderer from 'components/LinkRenderer'
import styles from './ClientJobWarningModal.scss'

const ClientJobWarningModal = (props) => {
  const { isOpen, onDismiss } = props
  const { URL_BASE } = getConfig()

  return (
    <DialogSimple
      hideFooter
      dismissLabel="Close"
      isOpen={isOpen}
      onDismiss={onDismiss}
    >
      <div className={styles.container}>
        <img alt="Add client users" src={AddClientImage} />
        <Heading level={6}>Adding client users</Heading>
        <div>
          Your job is now ready to share with clients.
          You can grant access using the
          {' '}
          <strong>‘Manage Members’</strong>
          {' '}
          button.
        </div>

        <div className={styles.support}>
          Need more help? Take a look at
          {' '}
          <LinkRenderer href={`${URL_BASE}#${SUPPORT_PAGES.SHARING_SUBSCRIPTION_JOBS}`}>
            sharing jobs with clients
          </LinkRenderer>
          {' '}
          in our support section.
        </div>

        <div className={styles.footer}>
          <Button mode="flat" onClick={onDismiss}>
            Okay
          </Button>
        </div>
      </div>

    </DialogSimple>
  )
}

ClientJobWarningModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
}

export default ClientJobWarningModal

