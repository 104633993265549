import React from 'react'
import PropTypes from 'prop-types'
import genAiAppTemplates from 'features/genAi/data/GenAiAppTemplates'
import GenAITemplateSelectionCard from 'features/genAi/components/GenAiTemplateSelectionCard/GenAiTemplateSelectionCard'
import styles from './SelectTemplateView.scss'

const propTypes = {
  onClick: PropTypes.func.isRequired,
}

const SelectTemplateView = ({ onClick }) => {
  return (
    <>
      <div className={styles.collectionRow}>
        {genAiAppTemplates.map(item => (
          <GenAITemplateSelectionCard
            key={item.key}
            onClick={() => onClick(item)}
            templateDescription={item.description}
            templateName={item.name}
          />
        ))}
      </div>
    </>
  )
}

SelectTemplateView.propTypes = propTypes

export default SelectTemplateView
