import {
  deleteRequest, getRequest, patchRequest, postRequest, putRequest,
} from '../api'

const bannerPrefix = '/banner'
const adminBannerPrefix = '/admin/banner'

export const getBanners = () => getRequest(bannerPrefix)
export const getActiveBanners = () => getRequest(`${bannerPrefix}/active`)
export const postBanner = bannerData => postRequest(adminBannerPrefix, bannerData)
export const putBanner = (bannerData, bannerId) => putRequest(`${adminBannerPrefix}/${bannerId}`, bannerData)
export const patchToggleBanner = bannerId => patchRequest(`${adminBannerPrefix}/${bannerId}/activate`)
export const deleteBanner = bannerId => deleteRequest(`${adminBannerPrefix}/${bannerId}`)
