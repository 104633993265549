/* eslint-disable jsx-a11y/interactive-supports-focus */

import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import blockEvent from './blockEvent'
import styles from './FileCard.scss'

class Option extends Component {
  static propTypes = {
    className: PropTypes.string, // className (based on mouse position)
    instancePrefix: PropTypes.string, // unique prefix for the ids (used for aria)
    isDisabled: PropTypes.bool.isRequired,
    isFocused: PropTypes.bool, // the option is focused
    label: PropTypes.string.isRequired,
    onFocus: PropTypes.func, // method to handle mouseEnter on option element
    option: PropTypes.object, // object that is base for that option
    optionIndex: PropTypes.number, // index of the option, used to generate unique ids for aria
    options: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    })).isRequired,
    selectOption: PropTypes.func.isRequired, // method to handle click on option element
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
  }

  static defaultProps = {
    className: null, // className (based on mouse position)
    isFocused: false, // the option is focused
    onFocus: () => {}, // method to handle mouseEnter on option element
    optionIndex: null,
    instancePrefix: '',
    option: null,
  }

  onFocus = (event) => {
    const { isFocused, onFocus, option } = this.props

    if (!isFocused) {
      onFocus(option, event)
    }
  }

  handleMouseDown = (event, option) => {
    const { selectOption } = this.props
    event.preventDefault()
    event.stopPropagation()
    selectOption(option)
  }

  handleMouseEnter = (event) => {
    this.onFocus(event)
  }

  handleMouseMove = (event) => {
    this.onFocus(event)
  }

  handleTouchEnd = (event) => {
    // Check if the view is being dragged, In this case
    // we don't want to fire the click event (because the user only wants to scroll)
    if (this.dragging) { return }

    this.handleMouseDown(event)
  }

  handleTouchMove = () => {
    // Set a flag that the view is being dragged
    this.dragging = true
  }

  handleTouchStart = () => {
    // Set a flag that the view is not being dragged
    this.dragging = false
  }

  renderOption = (option) => {
    return (
      <div className={styles.optionWarpper}>
        {option.label}
        {option.value !== '(not mapped)' && (
          <span>{option.isMandatory ? 'Mandatory' : 'Optional'}</span>
        )}
      </div>
    )
  }

  render() {
    const {
      className,
      instancePrefix,
      optionIndex,
      label,
      value,
      isDisabled,
      options,
      // className,
    } = this.props
    const allClassNames = classNames(className, 'react-select__option')

    const selectedOption = options.find(x => x.value === value)

    return isDisabled ? (
      <div
        className={allClassNames}
        onClick={blockEvent}
        onMouseDown={blockEvent}
      >
        {this.renderOption(selectedOption)}
      </div>
    ) : (
      <div
        aria-label={label}
        aria-selected={false}
        className={allClassNames}
        id={`${instancePrefix}-option-${optionIndex}`}
        onMouseDown={event => this.handleMouseDown(event, selectedOption)}
        onMouseEnter={this.handleMouseEnter}
        onMouseMove={this.handleMouseMove}
        onTouchEnd={this.handleTouchEnd}
        onTouchMove={this.handleTouchMove}
        onTouchStart={this.handleTouchStart}
        role="option"
      >
        {this.renderOption(selectedOption)}
      </div>
    )
  }
}

export default Option
