import { validateOrApplyValidation } from 'actionHub/utils/validations'
import { objectFromArray, objectFromArrayWithFunctions } from 'utils/objects'

const ACTION_HUB_FETCHING_TASKS = 'ACTION_HUB_FETCHING_TASKS'
const ACTION_HUB_FETCHING_TAGS = 'ACTION_HUB_FETCHING_TAGS'
const ACTION_HUB_FETCHED_TASKS = 'ACTION_HUB_FETCHED_TASKS'
const ACTION_HUB_FETCHED_TAGS = 'ACTION_HUB_FETCHED_TAGS'
const ACTION_HUB_FETCHING_ACTION = 'ACTION_HUB_FETCHING_ACTION'
const ACTION_HUB_FETCHED_ACTION = 'ACTION_HUB_FETCHED_ACTION'
const ACTION_HUB_FETCHING_TASK_META = 'ACTION_HUB_FETCHING_TASK_META'
const ACTION_HUB_FETCHED_TASK_META = 'ACTION_HUB_FETCHED_TASK_META'
const ACTION_HUB_FETCHING_TASK_DATA = 'ACTION_HUB_FETCHING_TASK_DATA'
const ACTION_HUB_FETCHED_TASK_DATA = 'ACTION_HUB_FETCHED_TASK_DATA'
const ACTION_HUB_FETCHED_TASK_DATA_NO_DATA = 'ACTION_HUB_FETCHED_TASK_DATA_NO_DATA'
const ACTION_HUB_FETCHING_DATA_DETAIL = 'ACTION_HUB_FETCHING_DATA_DETAIL'
const ACTION_HUB_FETCHED_DATA_DETAIL = 'ACTION_HUB_FETCHED_DATA_DETAIL'
const ACTION_HUB_UPDATE_TASK_LABEL_VALUE = 'ACTION_HUB_UPDATE_TASK_LABEL_VALUE'
const ACTION_HUB_UPDATE_TAG_LABEL_VALUE = 'ACTION_HUB_UPDATE_TAG_LABEL_VALUE'
const ACTION_HUB_SUBMITTING_TASK_LABELS = 'ACTION_HUB_SUBMITTING_TASK_LABELS'
export const ACTION_HUB_SUBMITTED_TASK_LABELS = 'ACTION_HUB_SUBMITTED_TASK_LABELS'
const ACTION_HUB_SUBMITTED_TAG_LABELS = 'ACTION_HUB_SUBMITTED_TAG_LABELS'
const ACTION_HUB_VALIDATE_TASK_LABEL = 'ACTION_HUB_VALIDATE_TASK_LABEL'
const ACTION_HUB_VALIDATE_TAG_LABEL = 'ACTION_HUB_VALIDATE_TAG_LABEL'
const ACTION_HUB_SUBMITTED_TASK_ASSIGNEE = 'ACTION_HUB_SET_TASK_ASSIGNEE'
const ACTION_HUB_SUBMITTED_TASK_STATUS = 'ACTION_HUB_SET_TASK_STATUS'
const ACTION_HUB_SUBMITTED_TASK_PRIORITY = 'ACTION_HUB_SET_TASK_PRIORITY'
const ACTION_HUB_IMPORTING_ACTIONS = 'ACTION_HUB_IMPORTING_ACTIONS'
const ACTION_HUB_IMPORTED_ACTIONS = 'ACTION_HUB_IMPORTED_ACTIONS'
const ACTION_HUB_ERRORED_IMPORTING_ACTIONS = 'ACTION_HUB_ERRORED_IMPORTING_ACTIONS'
const ACTION_HUB_SUBMITTING_TASK_COMMENT = 'ACTION_HUB_SUBMITTING_TASK_COMMENT'
const ACTION_HUB_SUBMITTED_TASK_COMMENT = 'ACTION_HUB_SUBMITTED_TASK_COMMENT'
const ACTION_HUB_SUBMITTED_TASK_TAG = 'ACTION_HUB_SUBMITTED_TASK_TAG'
const ACTION_HUB_UPDATE_SELECTED_TASKS = 'ACTION_HUB_UPDATE_SELECTED_TASKS'
const ACTION_HUB_FETCH_SELECTED_TASKS = 'ACTION_HUB_FETCH_SELECTED_TASKS'
const ACTION_HUB_SUBMITTED_TASK_ACTIONSTATE = 'ACTION_HUB_SUBMITTED_TASK_ACTIONSTATE'
const ACTION_HUB_UPDATE_TASK_DETAILS = 'ACTION_HUB_UPDATE_TASK_DETAILS'
const ACTION_HUB_UPDATING_TASK_DETAILS = 'ACTION_HUB_UPDATING_TASK_DETAILS'
const ACTION_HUB_ADDING_TASK = 'ACTION_HUB_ADDING_TASK'

// Tags
const ACTION_HUB_TAG_CREATED = 'ACTION_HUB_TAG_CREATED'
const ACTION_HUB_TAG_COMMENTS_UPDATED = 'ACTION_HUB_TAG_COMMENTS_UPDATED'
const ACTION_HUB_UPDATE_TAG_DATA = 'ACTION_HUB_UPDATE_TAG_DATA'
const ACTION_HUB_FETCHING_TAG_TASKS = 'ACTION_HUB_FETCHING_TAG_TASKS'
const ACTION_HUB_FETCHED_TAG_TASKS = 'ACTION_HUB_FETCHED_TAG_TASKS'

// Data level
const ACTION_HUB_UPDATE_DATA_LABEL_VALUE = 'ACTION_HUB_UPDATE_DATA_LABEL_VALUE'
const ACTION_HUB_VALIDATE_DATA_LABEL = 'ACTION_HUB_VALIDATE_DATA_LABEL'
const ACTION_HUB_SUBMITTING_DATA_LABELS = 'ACTION_HUB_SUBMITTING_DATA_LABELS'
const ACTION_HUB_SUBMITTED_DATA_LABELS = 'ACTION_HUB_SUBMITTED_DATA_LABELS'
const ACTION_HUB_SUBMITTING_DATA_COMMENT = 'ACTION_HUB_SUBMITTING_DATA_COMMENT'
const ACTION_HUB_SUBMITTED_DATA_COMMENT = 'ACTION_HUB_SUBMITTED_DATA_COMMENT'
const ACTION_HUB_SELECTED_TAB_UPDATE = 'ACTION_HUB_SELECTED_TAB_UPDATE'
const ACTION_HUB_FETCHED_TAG_DETAILS = 'ACTION_HUB_FETCHED_TAG_DETAILS'
const ACTION_HUB_UPDATED_TASKS_ADD_TAG = 'ACTION_HUB_UPDATED_TASKS_ADD_TAG'
const ACTION_HUB_FETCHED_UPDATED_TASKS_TOKENS = 'ACTION_HUB_FETCHED_UPDATED_TASKS_TOKENS'
const ACTION_HUB_SUBMITTING_TASK_LABELS_FAILED = 'ACTION_HUB_SUBMITTING_TASK_LABELS_FAILED'
const ACTION_HUB_SUBMITTING_TAG_LABELS = 'ACTION_HUB_SUBMITTING_TAG_LABELS'

const ACTION_HUB_TASKLIST_SCROLL_LOCATION = 'ACTION_HUB_TASKLIST_SCROLL_LOCATION'
const ACTION_HUB_TASKLIST_SEARCH_QUERY = 'ACTION_HUB_TASKLIST_SEARCH_QUERY'

const initialState = {
  selectedTab: null,
  actionSetId: null,
  actionSet: {
    _isFetching: false,
    _isFetched: false,
  },
  tags: {
    tagsList: [],
    _isFetching: false,
    _isFetched: false,
  },
  taskLevel: {
    displayColumns: [],
    displayDetailColumns: [],
    dataIds: [],
  },
  dataLevel: {
    displayColumns: [],
  },
  priorities: [],
  statuses: [],
  actionStates: [],
  tasks: {
    _isFetching: false,
    _isFetched: false,
  },
  selectedTasks: [],
  _isSelectedTasksUpdating: false,
  data: {
    _isFetching: false,
    _isFetched: false,
  },
  filterStatuses: {},
  isCurrent: true,
  displayName: 'Action',
  taskListScrollLocation: 0,
  taskListSearchQuery: '',
  _isActionImported: false,
  _isImportingAction: false,
  _isUpdatingDetails: false,
  _isAddingTask: false,
}

export const actionHubUpdateTaskDetails = (actionSetId, taskId, data) => ({
  type: ACTION_HUB_UPDATE_TASK_DETAILS,
  actionSetId,
  taskId,
  data,
})

export const actionHubAddingTask = isAddingTask => ({
  type: ACTION_HUB_ADDING_TASK,
  isAddingTask,
})

export const actionHubUpdatingTaskDetails = isUpdatingDetails => ({
  type: ACTION_HUB_UPDATING_TASK_DETAILS,
  isUpdatingDetails,
})

export const actionHubFetchSelectedTasks = isFetching => ({
  type: ACTION_HUB_FETCH_SELECTED_TASKS,
  isFetching,
})

export const actionHubFetchedTagDetails = (tagId, details) => ({
  type: ACTION_HUB_FETCHED_TAG_DETAILS,
  tagId,
  details,
})

const revisedStatusCount = (statusObject, oldTasks, newId) => {
  if (statusObject.id === newId) {
    return statusObject.count + oldTasks.length
  }
  return statusObject.count - oldTasks.filter(x => x.statusId === statusObject.id).length
}

const revisedActionStateCount = (actionStateObject, oldTasks, newId) => {
  if (actionStateObject.id === newId) {
    return actionStateObject.count + oldTasks.length
  }
  return actionStateObject.count - oldTasks.filter(x => x.taskActionId === actionStateObject.id).length
}

export const actionHubImportingActions = () => ({
  type: ACTION_HUB_IMPORTING_ACTIONS,
})
export const actionHubImportedActions = () => ({
  type: ACTION_HUB_IMPORTED_ACTIONS,
})
export const actionHubErroredImportingActions = () => ({
  type: ACTION_HUB_ERRORED_IMPORTING_ACTIONS,
})

export const actionHubFetchingTasks = actionSetId => ({
  type: ACTION_HUB_FETCHING_TASKS,
  actionSetId,
})

export const actionHubFetchedTasks = (actionSetId, tasks, filterString, filterStatus) => ({
  type: ACTION_HUB_FETCHED_TASKS,
  actionSetId,
  tasks,
  filterString,
  filterStatus,
})

export const actionHubUpdatedTagTaskAssociation = (dataIds, tagId) => ({
  type: ACTION_HUB_UPDATED_TASKS_ADD_TAG,
  dataIds,
  tagId,
})

export const actionHubFetchingAction = actionSetId => ({
  type: ACTION_HUB_FETCHING_ACTION,
  actionSetId,
})

export const actionHubFetchingTags = actionSetId => ({
  type: ACTION_HUB_FETCHING_TAGS,
  actionSetId,
})

export const actionHubFetchedAction = (actionSetId, data) => ({
  type: ACTION_HUB_FETCHED_ACTION,
  ...data,
})

export const actionHubFetchedTags = (actionSetId, tags) => ({
  type: ACTION_HUB_FETCHED_TAGS,
  tags,
})

export const fetchedUpdatedTasksTokens = data => ({
  type: ACTION_HUB_FETCHED_UPDATED_TASKS_TOKENS,
  data,
})

export const actionHubFetchingTaskData = (actionSetId, taskId) => ({
  type: ACTION_HUB_FETCHING_TASK_DATA,
  actionSetId,
  taskId,
})

export const actionHubFetchedTaskData = (actionSetId, taskId, data) => ({
  type: ACTION_HUB_FETCHED_TASK_DATA,
  actionSetId,
  taskId,
  data,
})

export const actionHubFetchedTaskDataNoData = (actionSetId, taskId) => ({
  type: ACTION_HUB_FETCHED_TASK_DATA_NO_DATA,
  actionSetId,
  taskId,
})

export const actionHubFetchingTaskMeta = (actionSetId, taskId) => ({
  type: ACTION_HUB_FETCHING_TASK_META,
  actionSetId,
  taskId,
})

export const actionHubFetchedTaskMeta = (actionSetId, taskId, data) => ({
  type: ACTION_HUB_FETCHED_TASK_META,
  actionSetId,
  taskId,
  data,
})

export const actionHubFetchingDataDetail = (actionSetId, taskId, dataId) => ({
  type: ACTION_HUB_FETCHING_DATA_DETAIL,
  actionSetId,
  taskId,
  dataId,
})

export const actionHubFetchedDataDetail = (actionSetId, taskId, dataId, data) => ({
  type: ACTION_HUB_FETCHED_DATA_DETAIL,
  actionSetId,
  taskId,
  dataId,
  data,
})

export const actionHubUpdateTaskLabelValue = (actionSetId, taskId, labelId, value) => ({
  type: ACTION_HUB_UPDATE_TASK_LABEL_VALUE,
  actionSetId,
  taskId,
  labelId,
  value,
})

export const actionHubUpdateTagLabelValue = (actionSetId, tagId, labelId, value) => ({
  type: ACTION_HUB_UPDATE_TAG_LABEL_VALUE,
  actionSetId,
  tagId,
  labelId,
  value,
})

export const actionHubUpdateTagDescription = (actionSetId, tagId, description, narrative, name) => ({
  type: ACTION_HUB_UPDATE_TAG_DATA,
  actionSetId,
  tagId,
  description,
  narrative,
  name,
})

export const actionHubFetchingTagTasks = (actionSetId, tagId) => ({
  type: ACTION_HUB_FETCHING_TAG_TASKS,
  actionSetId,
  tagId,
})

export const actionHubFetchedTagTasks = (actionSetId, tagId, data) => ({
  type: ACTION_HUB_FETCHED_TAG_TASKS,
  actionSetId,
  tagId,
  data,
})

export const submittingTaskLabels = (actionSetId, taskId) => ({
  type: ACTION_HUB_SUBMITTING_TASK_LABELS,
  actionSetId,
  taskId,
})

export const submittedTaskLabels = (actionSetId, taskId, jobId, payload) => ({
  type: ACTION_HUB_SUBMITTED_TASK_LABELS,
  actionSetId,
  taskId,
  jobId,
  payload,
})

export const submittingTagLabels = (actionSetId, taskId) => ({
  type: ACTION_HUB_SUBMITTING_TAG_LABELS,
  actionSetId,
  taskId,
})

export const submittingTaskLabelsFailed = (actionSetId, taskId) => ({
  type: ACTION_HUB_SUBMITTING_TASK_LABELS_FAILED,
  actionSetId,
  taskId,
})

export const submittedTagLabels = (actionSetId, tagId, jobId, payload) => ({
  type: ACTION_HUB_SUBMITTED_TAG_LABELS,
  actionSetId,
  tagId,
  jobId,
  payload,
})

export const validateTaskLabel = (actionSetId, taskId, labelId, error = null) => ({
  type: ACTION_HUB_VALIDATE_TASK_LABEL,
  actionSetId,
  taskId,
  labelId,
  error,
})

export const validateTagLabel = (actionSetId, tagId, labelId, error = null) => ({
  type: ACTION_HUB_VALIDATE_TAG_LABEL,
  actionSetId,
  tagId,
  labelId,
  error,
})

export const submittedTaskAssignee = (actionSetId, taskIds, userId) => ({
  type: ACTION_HUB_SUBMITTED_TASK_ASSIGNEE,
  actionSetId,
  taskIds,
  userId,
})

export const submittedTaskTag = (actionSetId, taskId, tagId) => ({
  type: ACTION_HUB_SUBMITTED_TASK_TAG,
  actionSetId,
  taskId,
  tagId,
})

export const tagCreated = (tag, taskIds) => ({
  type: ACTION_HUB_TAG_CREATED,
  tag,
  taskIds,
})

export const tagCommentsUpdated = (tagId, comments) => ({
  type: ACTION_HUB_TAG_COMMENTS_UPDATED,
  tagId,
  comments,
})

export const submittedTaskStatus = (actionSetId, taskIds, statusId) => ({
  type: ACTION_HUB_SUBMITTED_TASK_STATUS,
  actionSetId,
  taskIds,
  statusId,
})

export const submittedTaskActionState = (actionSetId, taskIds, taskActionId) => ({
  type: ACTION_HUB_SUBMITTED_TASK_ACTIONSTATE,
  actionSetId,
  taskIds,
  taskActionId,
})

export const submittedTaskPriority = (actionSetId, taskIds, priorityId) => ({
  type: ACTION_HUB_SUBMITTED_TASK_PRIORITY,
  actionSetId,
  taskIds,
  priorityId,
})

export const actionHubUpdateDataLabelValue = (actionSetId, dataId, labelId, value) => ({
  type: ACTION_HUB_UPDATE_DATA_LABEL_VALUE,
  actionSetId,
  dataId,
  labelId,
  value,
})

export const validateDataLabel = (actionSetId, dataId, labelId, error = null) => ({
  type: ACTION_HUB_VALIDATE_DATA_LABEL,
  actionSetId,
  dataId,
  labelId,
  error,
})

export const submittingDataLabels = (actionSetId, taskId, dataId) => ({
  type: ACTION_HUB_SUBMITTING_DATA_LABELS,
  actionSetId,
  taskId,
  dataId,
})

export const submittedDataLabels = (actionSetId, taskId, dataId, payload) => ({
  type: ACTION_HUB_SUBMITTED_DATA_LABELS,
  actionSetId,
  taskId,
  dataId,
  payload,
})

export const submittingDataComment = (actionSetId, taskId, dataId) => ({
  type: ACTION_HUB_SUBMITTING_DATA_COMMENT,
  actionSetId,
  taskId,
  dataId,
})

export const submittedDataComment = (actionSetId, taskId, dataId, comment) => ({
  type: ACTION_HUB_SUBMITTED_DATA_COMMENT,
  actionSetId,
  taskId,
  dataId,
  comment,
})

export const submittingTaskComment = (actionSetId, taskId) => ({
  type: ACTION_HUB_SUBMITTING_TASK_COMMENT,
  actionSetId,
  taskId,
})

export const submittedTaskComment = (actionSetId, taskId, comment) => ({
  type: ACTION_HUB_SUBMITTED_TASK_COMMENT,
  actionSetId,
  taskId,
  comment,
})

export const actionHubUpdateSelectedTasks = (actionSetId, taskIds, data) => ({
  type: ACTION_HUB_UPDATE_SELECTED_TASKS,
  actionSetId,
  taskIds,
  data,
})

export const selectedTabUpdate = selectedTab => ({
  type: ACTION_HUB_SELECTED_TAB_UPDATE,
  selectedTab,
})

export const updateTaskListScrollLocation = scrollLocation => ({
  type: ACTION_HUB_TASKLIST_SCROLL_LOCATION,
  scrollLocation,
})

export const updateTaskListSearchQuery = searchQuery => ({
  type: ACTION_HUB_TASKLIST_SEARCH_QUERY,
  searchQuery,
})

const convertTaskResponseToTask = (state, x) => ({
  ...state.tasks[x._id],
  _id: x._id,
  taskData: x,
  assignedTo: x._assignedTo,
  priorityId: x._priorityId,
  taskActionId: x._taskActionId,
  statusId: x._statusId,
  tagId: x._tagId,
  metaId: x._metaId,
})

export default function actionHubState(incomingState = initialState, action) {
  // Reset the state if we've moved to a different actionSetId
  // All actionHub actions must have an actionSetId
  const state = 'actionSetId' in action && incomingState.actionSetId !== action.actionSetId
    ? {
      ...initialState,
      actionSetId: action.actionSetId,
    }
    : incomingState

  switch (action.type) {
    case ACTION_HUB_FETCHING_ACTION:
      return {
        ...state,
        actionSet: {
          ...state.actionSet,
          _isFetching: true,
        },
      }
    case ACTION_HUB_FETCHED_TAG_DETAILS:
      return {
        ...state,
        tags: {
          ...state.tags,
          tagsList: state.tags.tagsList.map(t => (t.id === action.tagId
            ? { ...t, ...action.details, _isDetailsFetched: true }
            : t)),
        },
      }
    case ACTION_HUB_FETCHING_TAGS:
      return {
        ...state,
        tags: {
          ...state.tags,
          _isFetching: true,
        },
      }
    case ACTION_HUB_TAG_CREATED:
      return {
        ...state,
        tags: {
          ...state.tags,
          tagsList: [
            ...state.tags.tagsList,
            action.tag,
          ],
        },
      }
    case ACTION_HUB_FETCHED_TAGS:
      return {
        ...state,
        tags: {
          tagsList: action.tags.map((t) => {
            const existingTag = state.tags.tagsList.find(ft => ft.id === t.id)
            return {
              ...existingTag,
              ...t,
              labels: t.labels || existingTag?.labels,
              taskIds: existingTag?.taskIds,
              comments: existingTag?.comments,
            }
          }),
          _isFetching: false,
          _isFetched: true,
        },
      }
    case ACTION_HUB_FETCHED_UPDATED_TASKS_TOKENS:
      return {
        ...state,
        taskLevel: {
          ...state.taskLevel,
          globalFilters: action.data,
        },
      }
    case ACTION_HUB_TAG_COMMENTS_UPDATED:
      return {
        ...state,
        tags: {
          ...state.tags,
          tagsList: state.tags.tagsList.map(t => (t.id === action.tagId
            ? { ...t, comments: action.comments }
            : t)),
        },
      }
    case ACTION_HUB_FETCHED_ACTION:
      return {
        ...state,
        actionSet: {
          ...state.actionSet,
          ...action.actionSet,
          _isFetching: false,
          _isFetched: true,
        },
        actionStates: action.actionStates,
        statuses: action.statuses,
        priorities: action.priorities,
        taskLevel: {
          ...state.taskLevel,
          ...action.taskLevelMeta,
        },
        dataLevel: {
          ...state.dataLevel,
          ...action.dataLevelMeta,
        },
        isCurrent: action.isCurrent,
        displayName: action.displayName,
        allowTags: action.allowTags,
      }
    case ACTION_HUB_FETCHING_TASKS:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          _isFetching: true,
        },
      }
    case ACTION_HUB_IMPORTING_ACTIONS:
      return {
        ...state,
        _isImportingAction: true,
        _isActionImported: false,
      }
    case ACTION_HUB_IMPORTED_ACTIONS:
      return {
        ...state,
        _isImportingAction: false,
        _isActionImported: true,
      }
    case ACTION_HUB_ERRORED_IMPORTING_ACTIONS:
      return {
        ...state,
        _isImportingAction: false,
        _isActionImported: false,
      }
    case ACTION_HUB_UPDATED_TASKS_ADD_TAG:
      for (let i = 0; i < action.dataIds.length; i += 1) {
        const task = state.tasks[action.dataIds[i]]
        if (task) {
          task.tagId = action.tagId
          task.taskData._hasTag = true
        }
      }
      return {
        ...state,
      }
    case ACTION_HUB_FETCHED_TASKS:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          ...objectFromArray(action.tasks.map(x => convertTaskResponseToTask(state, x)), '_id'),
          _isFetching: false,
          _isFetched: false,
        },
        taskLevel: {
          ...state.taskLevel,
          dataIds: [...new Set([
            ...state.taskLevel.dataIds,
            ...action.tasks.map(x => x._id),
          ])],
        },
        filterStatuses: {
          ...state.filterStatuses,
          [action.filterString]: action.filterStatus,
        },
      }
    case ACTION_HUB_FETCHING_TASK_META:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.taskId]: {
            ...state.tasks[action.taskId],
          },
        },
      }
    case ACTION_HUB_FETCHED_TASK_META:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.taskId]: {
            ...state.tasks[action.taskId],
            ...action.data,
            metaId: action.data.id,
          },
        },
      }
    case ACTION_HUB_FETCHING_TASK_DATA:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.taskId]: {
            ...state.tasks[action.taskId],
            _isFetching: true,
            _isFetched: false,
          },
        },
      }
    case ACTION_HUB_FETCHED_TASK_DATA:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.taskId]: {
            ...state.tasks[action.taskId],
            _isFetching: false,
            _isFetched: true,
            _hasChildData: true,
            pagination: {
              page: action.data.page,
              hasNext: action.data.hasNext,
              totalItems: action.data.totalItems,
              nextPageQuery: action.data.nextPageQuery,
            },
            taskData: state.tasks[action.taskId].taskData || {},
            dataIds: [
              ...(state.tasks[action.taskId].dataIds || []),
              ...action.data.items.map(d => d._id),
            ],
          },
        },
        data: {
          ...state.data,
          ...objectFromArray(action.data.items.map(d => ({
            ...state.data[d._id],
            _id: d._id,
            _isFetched: false,
            data: d,
          })), '_id'),
        },

      }
    case ACTION_HUB_FETCHED_TASK_DATA_NO_DATA:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.taskId]: {
            ...state.tasks[action.taskId],
            _isFetching: false,
            _isFetched: true,
            _hasChildData: false,
          },
          taskData: {},
          dataIds: [],
        },
      }

    case ACTION_HUB_FETCHING_TAG_TASKS:
      return {
        ...state,
        tags: {
          ...state.tags,
          tagsList: state.tags.tagsList.map(tag => (tag.id === action.tagId
            ? {
              ...tag,
              tagTasks: {
                ...tag.tagTasks,
                _isFetching: true,
              },
            }
            : tag)),
        },
      }

    case ACTION_HUB_FETCHED_TAG_TASKS: {
      const selectedTag = state.tags.tagsList.find(tag => tag.id === action.tagId)

      const updatedSelectedTag = {
        ...selectedTag,
        tagTasks: {
          ...state.tags[action.tagId],
          _isFetching: false,
          _isFetched: true,
          _hasTasks: true,
          pagination: {
            page: action.data.page,
            hasNext: action.data.hasNext,
            totalItems: action.data.totalItems,
            nextPageQuery: action.data.nextPageQuery,
          },
        },
      }
      return {
        ...state,
        tags: {
          ...state.tags,
          tagsList: state.tags.tagsList.map(tag => (tag.id === action.tagId
            ? updatedSelectedTag
            : tag)),
        },
        tasks: {
          ...state.tasks,
          ...objectFromArray(action.data.items.map(x => convertTaskResponseToTask(state, x)), '_id'),
        },

      }
    }
    case ACTION_HUB_FETCHING_DATA_DETAIL:
      return {
        ...state,
        data: {
          ...state.data,
          [action.dataId]: {
            ...state.data[action.dataId],
            _isFetching: true,
            _isFetched: false,
          },
        },
      }
    case ACTION_HUB_FETCHED_DATA_DETAIL:
      return {
        ...state,
        data: {
          ...state.data,
          [action.dataId]: {
            ...state.data[action.dataId],
            ...action.data,
            _isFetching: false,
            _isFetched: true,
          },
        },
      }
    case ACTION_HUB_UPDATE_TASK_LABEL_VALUE:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.taskId]: {
            ...state.tasks[action.taskId],
            _isLabelsModified: true,
            labels: state.tasks[action.taskId].labels
              .map(l => (l.id === action.labelId
                ? { ...l, value: action.value }
                : l)),
          },
        },
      }
    case ACTION_HUB_UPDATE_TAG_LABEL_VALUE:
      return {
        ...state,
        tags: {
          ...state.tags,
          tagsList: state.tags.tagsList.map(t => (t.id === action.tagId
            ? {
              ...t,
              _isLabelsModified: true,
              labels: t.labels.map(l => ((l.id === action.labelId)
                ? {
                  ...l,
                  value: action.value,
                }
                : l)),
            }
            : t)),
        },
      }
    case ACTION_HUB_SUBMITTING_TASK_LABELS:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.taskId]: {
            ...state.tasks[action.taskId],
            _isSubmittingLabels: true,
          },
        },
      }

    case ACTION_HUB_SUBMITTING_TAG_LABELS:
      return {
        ...state,
        tags: {
          ...state.tags,
          tagsList: state.tags.tagsList.map(t => (t.id === action.tagId
            ? {
              ...t,
              _isSubmittingLabels: true,
            }
            : t)),
        },
      }

    case ACTION_HUB_UPDATE_TAG_DATA: {
      return {
        ...state,
        tags: {
          ...state.tags,
          tagsList: state.tags.tagsList.map(t => (t.id === action.tagId
            ? {
              ...t,
              name: action.name === null ? t.name : action.name,
              description: action.description === null ? t.description : action.description,
              narrative: action.narrative === null ? t.narrative : action.narrative,
            }
            : t)),
        },
      }
    }
    case ACTION_HUB_SUBMITTED_TASK_LABELS:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.taskId]: {
            ...state.tasks[action.taskId],
            _isLabelsModified: false,
            _isSubmittingLabels: false,
          },
        },
      }

    case ACTION_HUB_SUBMITTED_TAG_LABELS:
      return {
        ...state,
        tags: {
          ...state.tags,
          tagsList: state.tags.tagsList.map(t => (t.id === action.tagId
            ? {
              ...t,
              _isLabelsModified: false,
              _isSubmittingLabels: false,
            }
            : t)),
        },
      }

    case ACTION_HUB_SUBMITTING_TASK_LABELS_FAILED:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.taskId]: {
            ...state.tasks[action.taskId],
            _isSubmittingLabels: false,
          },
        },
      }
    case ACTION_HUB_VALIDATE_TASK_LABEL:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.taskId]: {
            ...state.tasks[action.taskId],
            labels: state.tasks[action.taskId].labels
              .map(l => (l.id === action.labelId
                ? validateOrApplyValidation(l, action.error)
                : l)),
          },
        },
      }
    case ACTION_HUB_VALIDATE_TAG_LABEL:
      return {
        ...state,
        tags: {
          ...state.tags,
          tagsList: state.tags.tagsList.map(t => (t.id === action.tagId
            ? {
              ...t,
              labels: t.labels
                .map(l => (l.id === action.labelId
                  ? validateOrApplyValidation(l, action.error)
                  : l)),
            }
            : t)),
        },
      }
    case ACTION_HUB_SUBMITTED_TASK_ASSIGNEE:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          ...objectFromArray(action.taskIds.map(id => ({
            ...state.tasks[id],
            assignedTo: action.userId,
          })), '_id'),
        },
      }
    case ACTION_HUB_SUBMITTED_TASK_ACTIONSTATE:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          ...objectFromArray(action.taskIds.map(id => ({
            ...state.tasks[id],
            taskActionId: action.taskActionId,
          })), '_id'),
        },
        actionStates: state.actionStates.map(s => ({
          ...s,
          count: revisedActionStateCount(s, action.taskIds.map(id => state.tasks[id]), action.taskActionId),
        })),
      }
    case ACTION_HUB_SUBMITTED_TASK_STATUS:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          ...objectFromArray(action.taskIds.map(id => ({
            ...state.tasks[id],
            statusId: action.statusId,
          })), '_id'),
        },
        statuses: state.statuses.map(s => ({
          ...s,
          count: revisedStatusCount(s, action.taskIds.map(id => state.tasks[id]), action.statusId),
        })),
      }
    case ACTION_HUB_SUBMITTED_TASK_PRIORITY:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          ...objectFromArray(action.taskIds.map(id => ({
            ...state.tasks[id],
            priorityId: action.priorityId,
          })), '_id'),
        },
      }
    case ACTION_HUB_SUBMITTED_TASK_TAG:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.taskId]: {
            ...state.tasks[action.taskId],
            tagId: action.tagId,
          },
        },
      }
    case ACTION_HUB_UPDATE_DATA_LABEL_VALUE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.dataId]: {
            ...state.data[action.dataId],
            _isLabelsModified: true,
            labels: state.data[action.dataId].labels
              .map(l => (l.id === action.labelId
                ? { ...l, value: action.value }
                : l)),
          },
        },
      }
    case ACTION_HUB_VALIDATE_DATA_LABEL:
      return {
        ...state,
        data: {
          ...state.data,
          [action.dataId]: {
            ...state.data[action.dataId],
            labels: state.data[action.dataId].labels
              .map(l => (l.id === action.labelId
                ? validateOrApplyValidation(l, action.error)
                : l)),
          },
        },
      }
    case ACTION_HUB_SUBMITTED_DATA_LABELS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.dataId]: {
            ...state.data[action.dataId],
            _isLabelsModified: false,
            data: {
              ...state.data[action.dataId].data,
              ...objectFromArrayWithFunctions(action.payload, x => `_${x.name}`, x => x.value),
            },
          },
        },
      }
    case ACTION_HUB_SUBMITTING_DATA_COMMENT:
      return {
        ...state,
        data: {
          ...state.data,
          [action.dataId]: {
            ...state.data[action.dataId],
            _isCommentSubmitting: true,
          },
        },
      }
    case ACTION_HUB_SUBMITTED_DATA_COMMENT:
      return {
        ...state,
        data: {
          ...state.data,
          [action.dataId]: {
            ...state.data[action.dataId],
            comments: [
              ...state.data[action.dataId].comments,
              action.comment,
            ],
            data: {
              ...state.data[action.dataId].data,
              _commentCount: state.data[action.dataId].data._commentCount + 1,
            },
            _isCommentSubmitting: false,
          },
        },
      }
    case ACTION_HUB_SUBMITTING_TASK_COMMENT:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.taskId]: {
            ...state.tasks[action.taskId],
            _isCommentSubmitting: true,
          },
        },
      }
    case ACTION_HUB_SUBMITTED_TASK_COMMENT:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.taskId]: {
            ...state.tasks[action.taskId],
            comments: [
              ...state.tasks[action.taskId].comments,
              action.comment,
            ],
            _isCommentSubmitting: false,
          },
        },
      }

    case ACTION_HUB_UPDATE_TASK_DETAILS:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.taskId]: {
            ...state.tasks[action.taskId],
            taskData: {
              ...state.tasks[action.taskId].taskData,
              ...action.data,
            },
          },
        },
      }

    case ACTION_HUB_UPDATING_TASK_DETAILS:
      return {
        ...state,
        _isUpdatingDetails: action.isUpdatingDetails,
      }

    case ACTION_HUB_ADDING_TASK:
      return {
        ...state,
        _isAddingTask: action.isAddingTask,
      }

    case ACTION_HUB_SELECTED_TAB_UPDATE:
      return {
        ...state,
        selectedTab: action.selectedTab,
      }
    case ACTION_HUB_UPDATE_SELECTED_TASKS:
      return {
        ...state,
        selectedTasks: action.taskIds.map(id => ({
          ...state.tasks[id],
        })),
      }
    case ACTION_HUB_FETCH_SELECTED_TASKS:
      return {
        ...state,
        _isSelectedTasksUpdating: action.isFetching,
      }
    case ACTION_HUB_TASKLIST_SCROLL_LOCATION:
      return {
        ...state,
        taskListScrollLocation: action.scrollLocation,
      }
    case ACTION_HUB_TASKLIST_SEARCH_QUERY:
      return {
        ...state,
        taskListSearchQuery: action.searchQuery,
      }
    default:
      return state
  }
}
