/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconYoutube = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--youtube',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 10"{...rest}><path className="path1" fill="#888" fillRule="evenodd" d="M5.25 7V2.333c1.556.78 3.101 1.556 4.667 2.34L5.25 7m8.508-5.405C13.587.84 12.978.28 12.245.197 10.509 0 8.75 0 7.002 0 5.252 0 3.495 0 1.76.197 1.027.28.42.84.247 1.595.003 2.673 0 3.85 0 4.958c0 1.11 0 2.286.244 3.363.171.757.78 1.315 1.513 1.398 1.736.198 3.493.199 5.243.198 1.75 0 3.506 0 5.242-.198.733-.083 1.342-.641 1.513-1.398C14 7.244 14 6.068 14 4.958c0-1.109.002-2.285-.242-3.363"/></IconBase>
  )
}

IconYoutube.propTypes = {
  className: PropTypes.string,
}

export default IconYoutube
/* eslint-enable */
