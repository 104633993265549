import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Heading } from '@deloitte/gel-library'
import uploadingData from 'images/data-uploading.svg'
import Spinner from 'components/Spinner'
import IconSimpleCheck from 'icons/IconSimpleCheck'
import DialogSimple from 'components/DialogSimple'
import { connect } from 'react-redux'
import styles from './FileGroupDataProcessingModal.scss'

class FileGroupDataProcessingModal extends Component {
  interval = null

  static propTypes = {
    heading: PropTypes.string.isRequired,
    job: PropTypes.object.isRequired,
    spinning: PropTypes.bool.isRequired,
    spinningCompleteText: PropTypes.string.isRequired,
    spinningText: PropTypes.string.isRequired,
  }

  static defaultProps = {
  }

  state = {
  }

  componentDidMount() {
    this.interval = setInterval(this.checkJob, 1000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render() {
    const {
      job: { isSpinning }, heading, spinning, spinningCompleteText, spinningText,
    } = this.props

    return (
      <DialogSimple
        hideFooter
        className={styles.base}
        isOpen={isSpinning}
        onDismiss={() => {}}
      >
        <img alt="Cloud with data uploading to it" height={180} src={uploadingData} width={180} />
        <Heading level={6}>{heading}</Heading>
        <p>
          This process could take 5 - 10 minutes.
          <br />
          Please do not close your browser.
        </p>

        <div className={styles.progressSpinners}>
          <div className={styles.circle}>
            {spinning ? <Spinner className={styles.spinner} colour="blue" /> : <IconSimpleCheck className={styles.tick} />}
          </div>
        </div>

        <p className={classnames({ [styles.active]: spinning })}>
          {spinning ? spinningText : spinningCompleteText}
        </p>
      </DialogSimple>
    )
  }
}

function mapStateToProps(state) {
  return {
    job: state.job,
  }
}

export default connect(mapStateToProps)(FileGroupDataProcessingModal)
