/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconAttachment = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--attachment',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 116.3 109.6"{...rest}><path className="path1" fill="#8A939D" d="M102 13.9c-5.6-5.6-14.8-5.6-20.5 0L37.2 58.3c-1.8 1.8-2.9 4.3-2.9 6.9s1 5.1 2.9 6.9c3.8 3.8 10 3.8 13.8 0l29.5-29.5c1-1 1-2.6 0-3.5s-2.6-1-3.5 0L47.5 68.6c-1.9 1.9-4.9 1.9-6.8 0-1.9-1.9-1.9-4.9 0-6.8l44.4-44.4c3.7-3.7 9.7-3.7 13.4 0 1.8 1.8 2.8 4.2 2.8 6.7s-1 4.9-2.8 6.7L38.8 90.5c-5.5 5.5-14.5 5.5-20 0-2.7-2.7-4.1-6.2-4.1-10s1.5-7.3 4.1-10l44.8-44.8c1-1 1-2.6 0-3.5s-2.6-1-3.5 0L15.3 67c-3.6 3.6-5.6 8.4-5.6 13.5s2 9.9 5.6 13.5c3.7 3.7 8.6 5.6 13.5 5.6s9.8-1.9 13.5-5.6L102 34.3c2.7-2.7 4.2-6.4 4.2-10.2s-1.4-7.5-4.2-10.2z"/></IconBase>
  )
}

IconAttachment.propTypes = {
  className: PropTypes.string,
}

export default IconAttachment
/* eslint-enable */
