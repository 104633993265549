import React, { useEffect, useState } from 'react'
import Label from 'components/Label'
import { useDispatch, useSelector } from 'react-redux'
import { putApp, updateappPromptsForm } from 'features/genAi/redux/actions/actionCreators'
import Expander from 'views/Home/components/Expander'
import { Chip } from '@material-ui/core'
import TextArea from 'components/TextArea'
import { Button } from '@deloitte/gel-library'
import styles from './GenAIAppCreationForms.scss'

const AppPromptsForm = () => {
  const dispatch = useDispatch()
  const initialState = useSelector(state => state.genAi.aiApp.promptConfig)
  const [localFormData, setLocalFormData] = useState(initialState)

  useEffect(() => {
    setLocalFormData(initialState)
  }, [initialState])

  const onChange = (value, name) => {
    setLocalFormData(prevState => ({ ...prevState, [name]: value }))
  }

  const handleSectionSave = (event) => {
    event.preventDefault()
    event.stopPropagation()
    dispatch(updateappPromptsForm(localFormData))
    dispatch(putApp())
  }

  return (
    <Expander
      className={styles.expander}
      classNameExpanded={styles.expanderExpanded}
      headerContent={(
        <div className={styles.headerBar}>
          <div className={styles.headerTitle}>
            <span className={styles.title}>Set default prompts</span>
            <Chip label="Optional" />
          </div>
          <div className={styles.saveSectionBtn}>
            <Button mode="flat" onClick={handleSectionSave}>Save</Button>
          </div>
        </div>
      )}
      key="appPrompts"
      size="unlimited"
    >
      <div className={styles.expandContentContainer}>
        <p>
          App creator defined inputs for default context and information
        </p>
        <div className={styles.form}>
          <div className={styles.fieldRow}>
            <div className={`${styles.label} ${styles.promptLabel}`}>
              <Label>Add primary default prompt</Label>
            </div>
            <div className={styles.fieldContainer}>
              <TextArea
                multiline
                height="160px"
                id="defaultPrompt"
                maxLength={4000}
                onChange={value => onChange(value, 'defaultPrompt')}
                placeholder="Add default context or instructions including persona"
                value={localFormData && localFormData.defaultPrompt ? localFormData.defaultPrompt : ''}
              />
            </div>
          </div>
          <div className={styles.fieldRow}>
            <div className={`${styles.label} ${styles.promptLabel}`}>
              <Label>Define response format</Label>
            </div>
            <div className={styles.fieldContainer} style={{ marginTop: '1.5rem' }}>
              <TextArea
                multiline
                height="120px"
                id="responseFormat"
                maxLength={4000}
                onChange={value => onChange(value, 'responseFormat')}
                placeholder="Define response format (optional)"
                value={localFormData && localFormData.responseFormat ? localFormData.responseFormat : ''}
              />
            </div>
          </div>
        </div>
      </div>
    </Expander>
  )
}

export default AppPromptsForm
