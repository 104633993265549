import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Heading, Button } from '@deloitte/gel-library'
import Container from 'components/layout/Grid/Container'
import { push } from 'connected-react-router'
import ProductBanner from 'components/ProductBanner'
import Loading from 'components/Loading'
import Carousel from 'components/Carousel/Carousel'
import Card from 'components/Card'
import SolutionCard from 'views/Home/components/SolutionCard'
import clientHomeImage from 'images/client-home.png'
import constants from 'constants'
import styles from './AzureDashboard.scss'

const propTypes = {
  customReportCount: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  isFetched: PropTypes.bool.isRequired,
  jobsByPackage: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string,
      jobCount: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
  packages: PropTypes.arrayOf(PropTypes.shape({
    cPackageName: PropTypes.string,
    id: PropTypes.string,
  })).isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string,
  }).isRequired,
}

const CUSTOM_REPORT_PACKAGE = 'Custom Reports'

const packageSummaryPropTypes = {
  count: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  packageName: PropTypes.string.isRequired,
}

const PackageSummaryCard = ({
  packageName, count, onClick,
}) => {
  return (
    <div className={classnames('col-md-3', styles.insightCardWrapper)}>
      <Card className={classnames(styles.insightCard)}>
        <Heading level={6}>{packageName}</Heading>
        <p>{`${count} job${count > 1 ? 's' : ''}`}</p>
        <Button
          className={styles.insightButton}
          onClick={onClick}
        >
          Launch
        </Button>
      </Card>
    </div>
  )
}

PackageSummaryCard.propTypes = packageSummaryPropTypes

const ClientDashboard = ({
  jobsByPackage, packages, user, dispatch, isFetched, customReportCount,
}) => {
  return (
    <Container noMargin className={styles.base}>
      <ProductBanner
        backgroundImageUrl={clientHomeImage}
        blurb={(
          <Fragment>
            Turn your data into actionable insights and harness the power
            <br />
            of data analytics to grow your business
          </Fragment>
        )}
        className={styles.clientBanner}
        name={(
          <span>
            {`Hi ${user.firstName},`}
            <br />
            welcome to InsightBox™
          </span>
        )}
      />

      {isFetched
        ? (
          <Fragment>
            <section className={styles.bottomBorder}>
              <div className={styles.centerContent}>
                <Heading level={6}>Your solutions</Heading>

                {jobsByPackage.length === 0 && customReportCount === 0 && (
                  <Card className={styles.noInsights}>
                    <p className={styles.heading}>No insights have been shared with you</p>
                    <p>
                      Explore our solutions and speak to your Deloitte representative
                      to do more with your data
                    </p>
                    <p className={styles.small}>
                      Expecting to see insights?
                      {' '}
                      <a href="mailto:insightbox@deloitte.com.au">Contact us</a>
                    </p>
                  </Card>
                )}

                {(jobsByPackage.length > 0 || customReportCount > 0) && (
                  <div className={classnames('row', styles.insightList)}>

                    {jobsByPackage.map((p) => {
                      const metaPackage = packages.find(x => x.name === p.name)

                      if (metaPackage) {
                        return (
                          <PackageSummaryCard
                            count={p.jobCount}
                            key={p.name}
                            onClick={() => dispatch(push(`/insights/${p.name}`))}
                            packageName={metaPackage.meta.displayName}
                          />
                        )
                      }
                      return null
                    })}

                    {customReportCount > 0 && (
                      <PackageSummaryCard
                        count={customReportCount}
                        key={CUSTOM_REPORT_PACKAGE}
                        onClick={() => dispatch(push(`/insights/${constants.CUSTOM_REPORT_PACKAGE}`))}
                        packageName={CUSTOM_REPORT_PACKAGE}
                      />
                    )}

                  </div>
                )}
              </div>
            </section>
            <section>

              <div className={styles.centerContent}>
                <Heading level={6}>Explore our solutions</Heading>

                <div className={classnames('row', styles.insightList)}>
                  <Carousel
                    components={packages.map(p => (
                      <SolutionCard
                        key={p.name}
                        onClick={() => dispatch(push(`/solution/${p.name}`))}
                        solution={p}
                      />
                    ))}
                    numVisible={4}
                  />
                </div>
              </div>

            </section>

            {/* <ProductBanner
              blurb="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc eros massa, tristique eu dui at, auctor rutrum velit. Interdum et malesuada fames ac ante ipsum primis."
              className={styles.featuredSolutionBanner}
              ctaText="Learn more"
              metaText="Featured solution"
              mode="light"
              name="TrueCost"
              subtitle="Detect AP cost leakages and uncover payment process inefficiencies"
            /> */}
          </Fragment>
        )
        : <Loading pageLoading />

      }

    </Container>
  )
}

ClientDashboard.propTypes = propTypes

export default ClientDashboard
