const genAiAppTemplates = [
  {
    key: 'BLANK',
    description: 'Create my own from scratch.',
    name: 'Blank',
    prompt: '',
    response_prompt: '',
  },
  {
    key: 'SUMMARISE',
    description: 'Summarise blocks of text in a structured format to extract the most useful information.',
    name: 'Summarise',
    prompt: '',
    response_prompt: '',
  },
  {
    key: 'GENERATECONTENT',
    description: 'Generate key content based on limited input.',
    name: 'Generate Content',
    prompt: '',
    response_prompt: '',
  },
  {
    key: 'TEXTCAPTURE',
    description: 'Compare key elements between two sets of text information to identify differences.',
    name: 'Text Capture',
    prompt: '',
    response_prompt: '',
  },
]

export default genAiAppTemplates

