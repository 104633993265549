import { deleteRequest, postRequest } from 'utils/api'

export const deleteFile = (fileGroupId, file) => deleteRequest(
  `fileGroup/${fileGroupId}/file`, file,
)

export const deleteFileGroup = fileGroupId => deleteRequest(
  `/fileGroup/${fileGroupId}`,
)

export const postFileToFileGroup = (fileGroupId, file) => postRequest(
  `/fileGroup/${fileGroupId}/file`, file,
)

