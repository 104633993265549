import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'

export default class ClickListener extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    onClickOutside: PropTypes.func.isRequired,
  }

  static defaultProps = {
    children: null,
    className: null,
  }

  constructor(props) {
    super(props)
    this.containerRef = createRef()
  }

  componentDidMount() {
    document.addEventListener('click', this.globalClickHandler)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.globalClickHandler)
  }

  globalClickHandler = (nativeEvent) => {
    const { onClickOutside } = this.props

    if (this.containerRef && !this.containerRef.current.contains(nativeEvent.target)) {
      onClickOutside(nativeEvent)
    }
  }

  render() {
    const { children, className } = this.props

    return (
      <div className={className} ref={this.containerRef}>
        {children}
      </div>
    )
  }
}
