import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './TextArea.scss'

const propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  height: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
}

const defaultProps = {
  className: null,
  placeholder: '',
  maxLength: null,
  onChange: () => {},
  value: '',
  disabled: false,
  height: '80px',
}

function TextArea(props) {
  const {
    className,
    disabled,
    placeholder,
    onChange,
    value,
    maxLength,
    height,
  } = props

  return (
    <>
      <textarea
        className={classNames(styles.textArea, className)}
        disabled={disabled}
        maxLength={maxLength}
        onChange={e => onChange(e.target.value)}
        placeholder={placeholder}
        style={{ height, resize: 'none' }}
        value={value}
      />
      {maxLength && value && (
      <div className={styles.limit}>
        {`${value.length}/${maxLength}`}
      </div>
      )}
    </>
  )
}

TextArea.propTypes = propTypes
TextArea.defaultProps = defaultProps

export default TextArea
