import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Container } from 'components/layout/Grid/'
import Heading from 'components/Heading'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Button } from '@deloitte/gel-library'
import { redirect } from 'actions/app'
import { IconErrorGeneric } from 'icons'
import ImgError403 from 'images/Error403Forbidden.png'
import styles from './ErrorPage.scss'

class ErrorPage extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
  }

  render() {
    const {
      location: { search },
      dispatch,
    } = this.props

    const supportLink = <Link to="/contact">contact us</Link>

    let info

    switch (search.toLowerCase()) {
      case '?maintenance':
        info = {
          title: 'We’re currently down for maintenance',
          message: (
            <p>
              We are undergoing scheduled maintenance and will be back
              shortly.
            </p>
          ),
          icon: <IconErrorGeneric width="600" />,
          actions: (
            <p />
          ),
        }
        break

      case '?forbidden':
        info = {
          title: 'You do not have access to this application',
          message: (
            <p>
              Please
              {' '}
              {supportLink}
              {' for more information.'}
            </p>
          ),
          icon: <img alt="Unauthorised access" src={ImgError403} width="600" />,
          actions: null,
        }
        break

      default:
        info = {
          title: 'Oops… it looks like there was a problem',
          message: (
            <p>
              Please refresh your browser or
              {' '}
              {supportLink}
              {' for more information.'}
            </p>
          ),
          icon: <IconErrorGeneric width="600" />,
          actions: (
            <p>
              <Button
                onClick={() => {
                  dispatch(redirect('/home'))
                }}
              >
                Retry
              </Button>
            </p>
          ),
        }
        break
    }

    return (
      <Container className={styles.base}>
        <div className="row">
          <div className={classnames(styles.description, 'col-xs-12 col-sm-4 col-md-4')}>
            <Heading size="h4">{info.title}</Heading>
            {info.message}
            {info.actions}
          </div>
          <div className="col-xs-12 col-sm-offset-1 col-sm-7 col-md-offset-2 col-md-6">
            {info.icon}
          </div>
        </div>
      </Container>
    )
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ErrorPage),
)
