import Validators from 'utils/validators'

const validResult = {
  error: null,
  valid: true,
}

const combineValidations = (validatorResults) => {
  const invalidResult = validatorResults.find(x => x.valid === false)
  return invalidResult || validResult
}

const applyExternalLabelValidation = (label, error) => {
  return {
    ...label,
    valid: false,
    error,
  }
}

export const validateLabel = (label) => {
  switch (label.labelType) {
    case 'money':
    case 'float':
      return {
        ...label,
        ...combineValidations([
          Validators.floatOnly(label.value, 'Must be a number'),
          Validators.numberRange(label.value, `Number must be between ${label.min} and ${label.max}`, label),
        ]),
      }
    case 'bigint':
    case 'int':
      return {
        ...label,
        ...combineValidations([
          Validators.intOnly(label.value, 'Must be a whole number'),
          Validators.numberRange(label.value, `Number must be between ${label.min} and ${label.max}`, label),
        ]),
      }
    case 'date':
      return {
        ...label,
        ...Validators.datefield(label.value),
      }
    case 'text':
      return {
        ...label,
        ...Validators.stringWithinLength(label.value, label),
      }
    default:
      return {
        ...label,
        ...validResult,
      }
  }
}

export const validateOrApplyValidation = (label, error) => {
  if (error) {
    return applyExternalLabelValidation(label, error)
  }
  return validateLabel(label)
}

const removeExternalLabelValidation = (label) => {
  return {
    ...label,
    valid: true,
    error: null,
  }
}

export const resetValidity = (labels) => {
  return labels.map((fl) => {
    return (
      fl.error
        ? removeExternalLabelValidation(fl)
        : fl
    )
  })
}
