import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import useDimension from 'hooks/useDimension'

function HeightAnimator({ children, className }) {
  const heightRef = useRef()
  const { height } = useDimension(heightRef)

  return (
    <div className={className} style={{ height, overflowY: 'hidden', transition: 'height .2s' }}>
      <div ref={heightRef}>
        {children}
      </div>
    </div>
  )
}

HeightAnimator.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.object,
}

HeightAnimator.defaultProps = {
  className: null,
}

export default HeightAnimator

