import { getRequest, postRequest } from 'utils/api'
import constants from 'constants'
import { notify } from 'actions/app'
import {
  fetchJobMembers, updateJobName,
} from 'actions/job'
import {
  formUpdateArrayMember, formSubmit, formSubmitComplete, formReset, formSubmitClear,
} from 'actions/forms'
import { modalHide } from 'actions/modals'
import { updateHostedReportUsers, editHostedReport } from 'actions/hostedReports'
import { roles } from 'utils/business/jobs'
import { postAddUserToJob } from 'utils/api/job'

export const APP_FETCHING_EXISTING_USER = 'APP_FETCHING_EXISTING_USER'
export const APP_FETCHED_EXISTING_USER = 'APP_FETCHED_EXISTING_USER'
export const APP_CREATE_EXTERNAL_USER = 'APP_CREATE_EXTERNAL_USER'

const fetchingExistingUser = email => ({
  type: APP_FETCHING_EXISTING_USER,
  email,
})

const fetchedExternalUser = (user, found) => ({
  type: APP_FETCHED_EXISTING_USER,
  user,
  found,
})

export const getUser = (email) => {
  return (dispatch) => {
    dispatch(fetchingExistingUser())

    getRequest(`/user/${email}`)
      .then(({ data }) => {
        dispatch(fetchedExternalUser(data, true))
        dispatch(formUpdateArrayMember(
          constants.FORM_ADD_EXTERNAL_USER, 'members',
          {
            id: data.id, ...data.details, fetched: true, exists: true,
          }, 'email',
        ))
      })
      .catch((e) => {
        if (e.response && e.response.status === 404) {
          dispatch(fetchedExternalUser({ email }, false))
          dispatch(formUpdateArrayMember(
            constants.FORM_ADD_EXTERNAL_USER, 'members',
            { email, fetched: true, exists: false }, 'email',
          ))
        } else {
          throw e
        }
      })
  }
}

export const updateExternalUsersOnHostedReport = (groupId) => {
  return (dispatch, getState) => {
    const { forms, hostedReports: { reportGroups } } = getState()
    const FORM_ID = constants.FORM_ADD_EXTERNAL_USER
    const {
      jobName: { value: jobName },
      members: { value: members },
    } = forms[FORM_ID]

    const group = reportGroups[groupId]

    // Update job name
    if (jobName !== group.name) {
      dispatch(editHostedReport(groupId, { name: jobName }))
    }

    const existingMembers = members.filter(x => x.exists)
    const newMembers = members.filter(x => !x.exists)

    if (newMembers.length === 0 && existingMembers.length === 0) {
      return
    }

    dispatch(formSubmit(FORM_ID))

    Promise.all(newMembers.map(user => postRequest(
      `/user?reportGroupId=${groupId}`,
      {
        user: {
          email: user.email.trim(),
          firstName: user.firstName.trim(),
          surname: user.surname.trim(),
          organisation: user.clientName.trim(),
          isExternal: true,
        },
      },
    )
      .then(
        ({ data }) => ({ success: true, user, data }),
      )
      .catch(
        error => ({ success: false, error }),
      )))
      .then((newMembersResult) => {
        if (newMembersResult.some(x => !x.success)) {
          dispatch(notify(
            'Could not create users. Please refresh the page and try again, or contact support.',
            'accept',
          ))
          dispatch(formSubmitClear(FORM_ID))
          return
        }

        // Get user ids from result
        const allUsers = members.map(m => (
          m.id
            ? {
              id: m.id,
              role: roles.HostedReportExternalReader,
              userDetails: m,
            }
            : {
              ...newMembersResult.find(x => x.data.user.userDetails.email === m.email).data.user,
              role: roles.HostedReportExternalReader,
              isNewUser: true,
            }
        ))

        // add to job
        Promise.all(allUsers.map(user => postRequest(
          `/reports/hosted/${groupId}/user/${user.id}${user.isNewUser ? '?sendNotification=false' : ''}`,
        )
          .then(({ data }) => ({ success: true, user, data }))
          .catch(error => ({ success: false, user, error }))))
          .then((results) => {
            // success parse
            const successfulUsers = results.filter(x => x.success)
              .map(x => x.user)

            // dispatch user update
            dispatch(updateHostedReportUsers(groupId, successfulUsers))

            // Message for error users
            let errorMessage = ''
            const someUnsuccessful = successfulUsers.length !== members.length
            const numUnsuccessful = members.length - successfulUsers.length
            if (someUnsuccessful) {
              errorMessage = ` ${numUnsuccessful} user${numUnsuccessful === 1 ? '' : 's'} could not be added; please refresh the page and try again.`
            }

            dispatch(notify(
              `You have added ${successfulUsers.length} client user${successfulUsers.length === 1 ? '' : 's'} to '${group.name}'.${errorMessage}`,
              'accept',
              errorMessage ? 50000 : null,
            ))

            dispatch(formSubmitComplete(FORM_ID))
            dispatch(modalHide(constants.MODAL_ADD_EXTERNAL_USER))
            dispatch(formReset(FORM_ID))
          })
      })
  }
}

export const updateExternalUsers = (jobId) => {
  return (dispatch, getState) => {
    const { forms, job } = getState()
    const FORM_ID = constants.FORM_ADD_EXTERNAL_USER
    const {
      jobName: { value: jobName },
      members: { value: members },
    } = forms[FORM_ID]

    // Update job name
    if (jobName !== job.name) {
      dispatch(updateJobName({ jobId, name: jobName }))
    }

    const existingMembers = members.filter(x => x.exists)
    const newMembers = members.filter(x => !x.exists)

    if (newMembers.length === 0 && existingMembers.length === 0) {
      return
    }

    dispatch(formSubmit(FORM_ID))

    Promise.all(newMembers.map(user => postRequest(
      `/user?jobId=${jobId}`,
      {
        user: {
          email: user.email.trim(),
          firstName: user.firstName.trim(),
          surname: user.surname.trim(),
          organisation: user.clientName.trim(),
          isExternal: true,
        },
      },
    ).then(
      ({ data }) => ({ success: true, user, data }),
    ).catch(
      error => ({ success: false, error }),
    )))
      .then((newMembersResult) => {
        if (newMembersResult.some(x => !x.success)) {
          dispatch(notify(
            'Could not create users. Please refresh the page and try again, or contact support.',
            'accept',
          ))
          dispatch(formSubmitClear(FORM_ID))
          return
        }
        const allUsers = members.map(m => (
          m.id
            ? {
              id: m.id,
              role: roles.JobExternalReader,
              userDetails: m,
            }
            : {
              ...newMembersResult.find(x => x.data.user.userDetails.email === m.email).data.user,
              role: roles.JobExternalReader,
              isNewUser: true,
            }
        ))

        const { job: { hasExecuted } } = getState()

        Promise.all(allUsers.map(user => postAddUserToJob(
          jobId,
          user.id,
          user.isNewUser,
          hasExecuted ? null : 9, // if the job hasn't executed they have to be external editor
        )
          .then(({ data }) => ({ success: true, user, data }))
          .catch(error => ({ success: false, user, error }))))
          .then((results) => {
            // success parse
            const successfulUsers = results.filter(x => x.success)
              .map(x => x.user)

            let errorMessage = ''
            const someUnsuccessful = successfulUsers.length !== members.length
            const numUnsuccessful = members.length - successfulUsers.length
            if (someUnsuccessful) {
              errorMessage = ` ${numUnsuccessful} user${numUnsuccessful === 1 ? '' : 's'} could not be added; please refresh the page and try again.`
            }
            dispatch(fetchJobMembers(job.jobId))
            dispatch(notify(
              `You have added ${successfulUsers.length} client user${successfulUsers.length === 1 ? '' : 's'} to '${job.name}'.${errorMessage}`,
              'accept',
              errorMessage ? 50000 : null,
            ))

            dispatch(formSubmitComplete(FORM_ID))
            dispatch(modalHide(constants.MODAL_ADD_EXTERNAL_USER))
            dispatch(formReset(FORM_ID))
          })
      })
  }
}
