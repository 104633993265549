import React from 'react'
import Avatar from 'components/Avatar'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import PropTypes from 'prop-types'
import { createTheme, MuiThemeProvider, makeStyles } from '@material-ui/core/styles'
import Label from 'components/Label'
import {
  Divider, ListItem, ListItemText, MenuItem, Select, Typography,
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import { roles } from 'utils/business/jobs'
import IconAvatarUnknown from 'icons/IconAvatarUnknown'
import styles from './TeamMembers.scss'

const propTypes = {
  members: PropTypes.arrayOf(PropTypes.shape({
    role: PropTypes.object,
    value: PropTypes.string,
  })).isRequired,
  openDeleteDialog: PropTypes.func.isRequired,
  openPermissionsDialogue: PropTypes.func,
}

const defaultProps = {
  openPermissionsDialogue: null,
}

const useStyles = makeStyles({
  icon: {
    top: '6px',
  },
})

const theme = createTheme({
  overrides: {
    MuiInput: {
      formControl: {
        'label + &': {
          marginTop: '0px',
        },
        fontFamily: 'Open Sans, Verdana, sans-serif;',
        fontSize: 'inherit',
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: '12px',
      },
    },
  },
})

const TeamMembers = ({
  members,
  openDeleteDialog,
  openPermissionsDialogue,
}) => {
  const classes = useStyles()
  const { member } = useSelector(state => state.job)

  return (
    <div className={styles.base}>
      {members.map((m) => {
        const {
          userDetails: {
            firstName,
            surname,
            isExternal,
            email,
            hasAcceptedEula,
          },
        } = m
        const isInvited = isExternal && !hasAcceptedEula
        return (
          <div key={m.id}>
            <div className={styles.flex}>
              <div className={styles.flex}>
                {isInvited ? <IconAvatarUnknown height={36} width={36} /> : <Avatar userDetails={m.userDetails} />}
                <div className={styles.memberName} title={email}>
                  <div className={isInvited ? styles.invitedName : null}>
                    {`${isInvited ? '(Invited)' : ''} ${firstName} ${surname}`}
                  </div>
                  {isExternal
                    ? <div className={styles.email}>{email}</div>
                    : null}
                </div>
              </div>
              <div>
                <FormControl>
                  <MuiThemeProvider theme={theme}>

                    {m.role.name !== roles.JobManager
                    && m.role.name !== roles.JobPartner
                    && m.role.name !== roles.ParticipatingPartner
                    && m.role.name !== roles.ParticipatingManager
                    && m.value !== member.value
                      ? (
                        <div>
                          <InputLabel id={m.role.id} />
                          <Select
                            disableUnderline
                            classes={{
                              icon: classes.icon,
                            }}
                            defaultValue={m.role.id}
                            labelId={`${m.role.id}`}
                            onChange={e => openPermissionsDialogue && openPermissionsDialogue(m, e.target.value)}
                          >
                            {m.jobAssignableRoles?.map((r) => {
                              return (
                                <MenuItem key={r.id} value={r.id}>{r.description}</MenuItem>
                              )
                            })}
                            <Divider />
                            <ListItem button onClick={() => openDeleteDialog(m)} value="Remove">
                              <ListItemText disableTypography primary={<Typography style={{ fontSize: '12px' }}>Remove</Typography>} />
                            </ListItem>
                          </Select>
                        </div>
                      )
                      : (
                        <div className={styles.noDropdown}>
                          <Label>
                            {m.role.name === roles.JobManager && 'Engagement Manager'}
                            {m.role.name === roles.JobPartner && 'Engagement Partner'}
                            {m.role.name === roles.ParticipatingPartner && 'Participating Partner'}
                            {m.role.name === roles.ParticipatingManager && 'Participating Manager'}
                            {m.role.name === roles.JobMember && m.role.description}
                          </Label>
                        </div>
                      )
                    }
                  </MuiThemeProvider>
                </FormControl>
              </div>
            </div>
            <div />
          </div>
        )
      })

      }
    </div>
  )
}

TeamMembers.propTypes = propTypes
TeamMembers.defaultProps = defaultProps

export default TeamMembers
