import { getRequest, parseError, putRequest } from 'utils/api'
import map from 'lodash/map'
import some from 'lodash/some'
import { push } from 'connected-react-router'
import constants from 'constants'
import { addUserDetailsTelemetryInitialiser } from 'utils/appInsights'
import { isAdmin } from 'utils/permissions'
import { getConfig } from 'utils/config'
import { dialogShow } from './dialogs'

export const APP_PROGRESS_MODAL = 'APP_PROGRESS_MODAL'
export const APP_BOOTSTRAPPED = 'APP_BOOTSTRAPPED'
export const APP_NOTIFY = 'APP_NOTIFY'
export const APP_NOTIFY_ERROR = 'APP_NOTIFY_ERROR'
export const APP_CLEAR_NOTIFICATION = 'APP_CLEAR_NOTIFICATION'
export const APP_UPDATE_USER = 'APP_UPDATE_USER'
export const APP_UPDATE_USER_PERMISSIONS = 'APP_UPDATE_USER_PERMISSIONS'
export const APP_HIDE_SANDBOX_MODAL = 'APP_HIDE_SANDBOX_MODAL'
export const APP_UPDATE_ERROR = 'APP_UPDATE_ERROR'
export const APP_ACCEPTING_TOU = 'APP_ACCEPTING_TOU'
export const APP_ACCEPTED_TOU = 'APP_ACCEPTED_TOU'
export const APP_API_ERROR = 'APP_API_ERROR'
export const APP_FETCHED_AVATAR = 'APP_FETCHED_AVATAR'

export const apiError = (error) => {
  return (dispatch) => {
    if (error.response && error.response.status !== 401) {
      // Redirect to home page
      dispatch(push('/home'))
    }
  }
}

export const progressModel = showModal => ({
  type: APP_PROGRESS_MODAL,
  showModal,
})

export const notify = (message, type = 'accept', timeout = 2800) => ({
  type: APP_NOTIFY,
  message,
  timeout,
  alertType: type,
})

export const notifyError = error => ({
  type: APP_NOTIFY_ERROR,
  error,
})

export const clearNotification = () => ({
  type: APP_CLEAR_NOTIFICATION,
})

export const hideSanboxModal = () => ({
  type: APP_HIDE_SANDBOX_MODAL,
})

export const updateErrorInformation = error => ({
  type: APP_UPDATE_ERROR,
  error,
})

export const showErrorPage = (err) => {
  return (dispatch) => {
    dispatch(updateErrorInformation(err))

    let errorPath
    switch (err.status) {
      case constants.ERROR.FORBIDDEN:
        errorPath = '/error?forbidden'
        break
      case constants.ERROR.SERVICE_UNAVAILABLE:
        errorPath = '/error?maintenance'
        break
      case constants.ERROR.UNAUTHORIZED:
        errorPath = '/signin'
        break
      default:
        errorPath = '/error'
    }

    dispatch(
      push(errorPath),
    )
  }
}

const updateUser = user => ({
  type: APP_UPDATE_USER,
  user: {
    ...user,
    isAdmin: isAdmin(user),
  },
})

export const updateUserPermissions = permissions => ({
  type: APP_UPDATE_USER_PERMISSIONS,
  permissions,
})

export const whoami = () => {
  return (dispatch) => {
    getRequest('/user/whoami')
      .then(({ data: user }) => {
        if (user) {
          dispatch(updateUser(user))
          addUserDetailsTelemetryInitialiser(user)
          if (window.location.hash.toLowerCase().indexOf('/error') === 1) {
            dispatch(push('/home'))
          }
        } else {
          console.warn('failed to get userinfo')
        }
      })
      .catch((error) => {
        const err = parseError(error)
        dispatch(showErrorPage(err))
      })
  }
}

export const redirect = (path) => {
  return (dispatch, getState) => {
    const { pathname } = getState().router.location

    // Input data step could have pending files
    if (pathname.indexOf('/inputdata') > -1) {
      // Check if there are pending files in each uploader
      const hasPendingFiles = some(
        map(getState().uploaders.uploaders, uploader => some(uploader.files, file => file.status === 'pending')),
      )

      if (hasPendingFiles) {
        dispatch(dialogShow(constants.DIALOG_LEAVE_INPUTDATA, { path }))
        return
      }
    }

    // TODO: Input data (BAU) and Final Review (AZURE) may have uncommitted parameter changes
    // should check for this, too

    dispatch(push(path))
  }
}

const acceptingTou = () => ({
  type: APP_ACCEPTING_TOU,
})

const acceptedTou = () => ({
  type: APP_ACCEPTED_TOU,
})

export const acceptTou = () => {
  return (dispatch) => {
    dispatch(acceptingTou())

    putRequest('/client/user/license')
      .then(() => {
        dispatch(acceptedTou())
        dispatch(push('/home'))
      })
  }
}

const fetchedAvatar = (email, blobUrl) => ({
  type: APP_FETCHED_AVATAR,
  blobUrl,
  email,
})

export const fetchAvatar = (email) => {
  return (dispatch) => {
    const { API_BASE } = getConfig()
    const xhr = new XMLHttpRequest()
    xhr.onloadstart = () => {
      xhr.responseType = 'blob'
    }
    xhr.open('GET', `${API_BASE}/user/${email}/photo?size=48x48`)
    xhr.send()
    xhr.onload = () => {
      if (xhr.status !== 200) {
        dispatch(fetchedAvatar(email, null))
      } else {
        dispatch(fetchedAvatar(email, window.URL.createObjectURL(xhr.response)))
      }
    }
    xhr.onerror = () => dispatch(fetchedAvatar(email, null))
  }
}
