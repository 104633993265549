export const getUserDisplayString = ({ userDetails }) => {
  return `${userDetails.firstName} ${userDetails.surname}`
}

export const isExternalFromState = state => state.app.user.isExternal

export const filterBannersByAudience = (banners, user) => {
  return banners.filter((b) => {
    return b.audience.some(a => user.isExternal && a.description === 'External')
      || b.audience.some(a => user.isAuOffice && a.description === 'AU')
      || b.audience.some(a => !user.isAuOffice && a.description === 'Non-AU')
  })
}
