/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconGreenTickCircle = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--greentickcircle',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 21"{...rest}><g fill="none" fillRule="evenodd"><path className="path1" d="M-2-1.5h24v24H-2z"/><path className="path2" fill="#26890D" fillRule="nonzero" d="M10 .5C4.48.5 0 4.98 0 10.5s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm-2 15l-5-5 1.41-1.41L8 12.67l7.59-7.59L17 6.5l-9 9z"/></g></IconBase>
  )
}

IconGreenTickCircle.propTypes = {
  className: PropTypes.string,
}

export default IconGreenTickCircle
/* eslint-enable */
