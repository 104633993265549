/* Generated by ./script/iconify.js */
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import IconBase from './IconBase'

const IconCreateJob = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--profile',
    className,
  )

  return (
    <IconBase className={classes} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 14h-3v3h-2v-3H8v-2h3v-3h2v3h3v2zm-3-7V3.5L18.5 9H13z" />
    </IconBase>
  )
}

IconCreateJob.propTypes = {
  className: PropTypes.string,
}

IconCreateJob.defaultProps = {
  className: null,
}

export default IconCreateJob
