import {
  deleteRequest, getRequest, postRequest, putRequest,
} from '../api'

const jobPrefix = '/admin/job'
const statusPrefix = '/admin/status'
const userPrefix = '/admin/user'
const packagePrefix = '/admin/package'
const recipePrefix = '/admin/recipe'
const systemAccessPrefix = '/admin/systemaccess'

export const getElevatedRoles = () => getRequest(`${userPrefix}/roles/elevated`)
export const getElevatedUsers = () => getRequest(`${userPrefix}/elevated`)
export const getUserGroups = () => getRequest(`${userPrefix}/groups`)
export const getExistingUsersSearch = query => getRequest(`${userPrefix}/search?query=${encodeURI(query)}`)
export const getUserPermissions = () => getRequest('/user/userPermissions')

export const postUserRole = (email, roleId) => postRequest(
  `${userPrefix}/${encodeURI(email)}/role/${roleId}`,
)
export const deleteUserRole = (username, roleId) => deleteRequest(`${userPrefix}/${encodeURI(username)}/role/${roleId}`)

export const putUserNotes = (email, userNotes) => putRequest(
  `${userPrefix}/${encodeURI(email)}/notes`,
  {
    userNotes, email,
  },
)

export const getSolutionAccess = () => getRequest(`${packagePrefix}/access`)
export const getDataSourceAccess = () => getRequest(`${recipePrefix}/access`)
export const getSolutionAssignableRoles = () => getRequest(`${packagePrefix}/access/roles`)
export const getDataSourceAssignableRoles = () => getRequest(`${recipePrefix}/access/roles`)

export const postSolutionRoleAccess = (packageName, roleId, analysisNames) => postRequest(`${packagePrefix}/${packageName}/access`, {
  roleId,
  analyses: analysisNames,
})

export const postDataSourceRoleAccess = (dataSourceName, roleId) => postRequest(`${recipePrefix}/${dataSourceName}/access`, {
  roleId,
})

export const deleteSolutionRoleAccess = (packageName, roleId, analysisNames) => deleteRequest(`${packagePrefix}/${packageName}/access`, {
  roleId,
  analyses: analysisNames,
})

export const deleteDataSourceRoleAccess = (dataSourceName, roleId) => deleteRequest(`${recipePrefix}/${dataSourceName}/access`, {
  roleId,
})

export const deleteSolutionCache = () => deleteRequest(`${packagePrefix}/cache`)
export const deleteDataSourceCache = () => deleteRequest(`${recipePrefix}/cache`)

export const getSystemAccess = () => getRequest(systemAccessPrefix)
export const postSystemAccess = (country, businessUnit) => postRequest(systemAccessPrefix, { access: { country, businessUnit } })
export const deleteSystemAccess = (country, businessUnit) => deleteRequest(systemAccessPrefix, { access: { country, businessUnit } })
export const getBusinessUnits = () => getRequest(`${systemAccessPrefix}/bu`)

export const getActiveJobs = () => getRequest(`${statusPrefix}/jobs`)
export const getExecutionLog = (jobId, executionId) => getRequest(`${jobPrefix}/${jobId}/log/${executionId}`)
