/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconPDF = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--pdf',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 102 90.6"{...rest}><g fill="#00A1DE"><path className="path1" d="M38 75h64V42H38v33zm44-24.1c0-1.1 1.5-2.9 2.6-2.9h9.9c1.1 0 2 .9 2 2s-.9 2-2 2H86v5h6.3c1.1 0 2 .9 2 2s-.9 2-2 2H86v7.3c0 1.1-.9 2-2 2s-2-.9-2-2V50.9zm-19 0c0-1.1 1.8-2.9 2.9-2.9H70c4.4 0 7 4.4 7 8.8v5.6c0 4.4-2.6 6.6-7 6.6h-4.1c-1.1 0-2.9.4-2.9-.7V50.9zm-18 0c0-1.1 1.6-2.9 2.7-2.9h5.2c3.7 0 6.8 2.8 6.8 6.5s-3 6.5-6.8 6.5H49v7.3c0 1.1-.9 2-2 2s-2-.9-2-2V50.9z"/><path className="path2" d="M73 62.4v-5.6c0-2.1-.8-4.8-3-4.8h-3v13h3c2.2 0 3-.4 3-2.6zM55.7 54.5c0-1.5-1.2-2.5-2.8-2.5H49v5h3.9c1.6 0 2.8-1 2.8-2.5z"/><path className="path3" d="M36.4 80c-1.4 0-3.4-.2-3.4-1.6V40.5c0-1.4 2.1-3.5 3.4-3.5H74V23.8c0-2.3-1-5.3-2.6-6.9L58.1 3.1C56.4 1.5 53.6 0 51.3 0h-45C3.3 0 0 3 0 6.1v79c0 3 2.9 5.5 5.9 5.5h62.6c3 0 5.4-2.5 5.4-5.5V80H36.4zM56 9.1L66 17H56V9.1zM17.2 30.5c0-.8.7-1.5 1.5-1.5h38.1c.8 0 1.5.7 1.5 1.5s-.6 1.5-1.4 1.5H18.7c-.8 0-1.5-.7-1.5-1.5zM26.8 52h-7.5c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h7.5c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5zm0-10h-7.5c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h7.5c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5z"/></g></IconBase>
  )
}

IconPDF.propTypes = {
  className: PropTypes.string,
}

export default IconPDF
/* eslint-enable */
