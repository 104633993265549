import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@deloitte/gel-library'
import classNames from 'classnames'
import styles from './Tickmark.scss'

function Tickmark({
  tickmark, multiple, active, onRemove, onTickmarkSelected,
}) {
  return (
    <div>
      {active ? (
        <div className={multiple ? styles.back : null}>
          <div className={multiple ? styles.tickmarkFlexContainer : styles.activeTickmark}>
            <div className={multiple ? styles.tickmarkName : styles.activeTickmarkName}>{tickmark.name}</div>
            <div className={multiple ? styles.tickmarkDescription : styles.activeTickmarkDescription}>
              {tickmark.description || 'No description'}
            </div>
            <div className={!multiple ? styles.remove : null}><Button mode="flat" onClick={() => onRemove()}>{ multiple ? 'REMOVE ALL' : 'REMOVE'}</Button></div>
          </div>
        </div>
      ) : (
        <div className={styles.tickmarkFlexContainer} onClick={() => onTickmarkSelected()}>
          <div className={classNames(styles.tickmarkName, styles.singleTagWidth)}>{tickmark.name}</div>
          <div className={styles.tickmarkDescription}>{tickmark.description || 'No description'}</div>
        </div>
      )}
    </div>
  )
}

Tickmark.propTypes = {
  active: PropTypes.bool,
  multiple: PropTypes.bool,
  onRemove: PropTypes.func,
  onTickmarkSelected: PropTypes.func,
  tickmark: PropTypes.object.isRequired,
}

Tickmark.defaultProps = {
  multiple: false,
  active: false,
  onRemove: null,
  onTickmarkSelected: null,
}

export default Tickmark

