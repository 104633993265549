import React from 'react'
import PropTypes from 'prop-types'
import constants from 'constants'
import { useDispatch, useSelector } from 'react-redux'
import Animate from 'components/Animate'
import OverlayModal from 'components/OverlayModal'
import { modalHide } from 'actions/modals'
import {
  adminEditDataSourceAccess, adminToggleDataSourcePermissions, adminUpdateDataSourcePermissions,
} from 'actions/admin'
import { Heading, ToggleInput } from '@deloitte/gel-library'
import SubmitButton from 'components/SubmitButton'

import styles from './DataSourceSettingsModal.scss'

const MODAL_ID = constants.MODAL_DATA_SOURCE_SETTINGS

const labelledTextPropTypes = {
  body: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

const LabelledText = ({ label, body }) => {
  return (
    <div className={styles.labelledText}>
      <p className={styles.label}>{label}</p>
      <p className={styles.body}>{body}</p>
    </div>
  )
}

LabelledText.propTypes = labelledTextPropTypes

const DataSourceSettingsModal = () => {
  const dispatch = useDispatch()
  const modals = useSelector(state => state.modals)
  const {
    meta: {
      displayName,
      description,
    },
    roles: dataSourceRoles,
  } = useSelector(state => state.admin.dataSourceAccess)
  const { roles: allRoles, roleIds: allRoleIds } = useSelector(state => state.admin.assignableRoles)
  const updatingPermissions = useSelector(state => state.admin._isUpdatingDataSourcePermissions)

  const dataSourceDetails = [
    {
      label: 'Data Source name',
      body: displayName,
    },
    {
      label: 'Data Source description',
      body: description,
    },
  ]

  return (
    <OverlayModal
      base={10}
      disable={false}
      id={MODAL_ID}
      init={() => null}
      modals={modals}
      onClose={() => {
        dispatch(modalHide(MODAL_ID))
        dispatch(adminEditDataSourceAccess(null))
      }}
      push={4}
    >
      <Animate name="fade">
        <div className={styles.base}>
          <Heading className={styles.title} level={5}>{`Edit ${displayName} access`}</Heading>

          <div className={styles.solutionDetails}>
            {dataSourceDetails.map(d => (
              <LabelledText body={d.body} key={d.label} label={d.label} />
            ))}
          </div>

          <div className={styles.solutionPermissions}>
            {allRoleIds.map(r => allRoles[r]).map(r => (
              <div className={styles.permissionToggleContainer} key={r.id}>
                <div className={styles.toggleLabel}>{`${r.description} access`}</div>
                <ToggleInput
                  checked={dataSourceRoles.map(sr => sr.id).indexOf(r.id) >= 0}
                  labelFalse=""
                  labelTrue=""
                  onChange={() => dispatch(adminToggleDataSourcePermissions(r.id, dataSourceRoles.map(sr => sr.id).indexOf(r.id) < 0))}
                />
              </div>
            ))}
          </div>

          <div className={styles.save}>
            <SubmitButton
              onClick={() => dispatch(adminUpdateDataSourcePermissions())}
              submitting={updatingPermissions}
              submittingText="Applying"
            >
              Apply permissions
            </SubmitButton>
          </div>

        </div>
      </Animate>
    </OverlayModal>
  )
}

export default DataSourceSettingsModal
