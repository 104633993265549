export const UI_AVATAR_COLOURS = [
  '#26890D',
  '#046A38',
  '#2C5234',
  '#0D8390',
  '#004F59',
  '#007CB0',
  '#005587',
  '#0D8390',
]

export const UI_AVATAR_COLOURS_DICT = {
  'green-1': '#26890D',
  'green-2': '#046A38',
  'green-3': '#2C5234',
  'teal-1': '#0D8390',
  'teal-2': '#004F59',
  'blue-1': '#007CB0',
  'blue-2': '#005587',
  'blue-3': '#0D8390',
}

export const UI_NOTIFICATIONS = {
  VISIBLE_CLIENT: 3,
  VISIBLE_PRACTITIONER: 4,
}

export const UI_CONVERSATION_LIST = {
  VISIBLE: 10,
  INCREMENT: 10,
  POLL_FREQUENCY: 30000,
}

export const UI_CLIENTS_LIST = {
  VISIBLE: 10,
  INCREMENT: 10,
  POLL_FREQUENCY: 30000,
}

export const UI_CONVERSATION_DETAIL = {
  POLL_FREQUENCY: 30000,
}
