import React from 'react'
import Banner from 'components/Banner'
import Container from 'components/layout/Grid/Container'
import Loading from 'components/Loading'

const LoadingView = () => (
  <div>
    <Banner name="" routes={[]} />
    <Container noMargin topPadding>
      <Loading pageLoading />
    </Container>
  </div>
)

export default LoadingView
