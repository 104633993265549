import React, { useRef, useState } from 'react'
import { TextInput } from '@deloitte/gel-library'
import { debounce } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { updateTaskListSearchQuery } from 'actionHub/redux/state'

import styles from './TextSearchFilter.scss'

const TextSearchFilter = () => {
  const dispatch = useDispatch()
  const taskListSearchQuery = useSelector(state => state.actionHub.taskListSearchQuery)
  const [inputValue, setInputValue] = useState(taskListSearchQuery ?? '')
  const debouncedSetSearchQuery = useRef(debounce(searchValue => dispatch(updateTaskListSearchQuery(searchValue)), 300)).current

  const handleSearchChange = (searchValue) => {
    setInputValue(searchValue)
    debouncedSetSearchQuery(searchValue)
  }

  return (
    <>
      <div className={styles.textSearchFieldContainer}>
        <TextInput
          className={styles.field}
          data-test-id="searchInput"
          id="searchInput"
          inputProps={styles.underline}
          maxLength={50}
          name="searchInput"
          onChange={value => handleSearchChange(value)}
          placeholder="Search..."
          value={inputValue}
        />
      </div>
    </>
  )
}

export default TextSearchFilter
