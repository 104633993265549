import React from 'react'
import PropTypes from 'prop-types'
import { Heading, Button } from '@deloitte/gel-library'
import { push } from 'connected-react-router'
import CardList from 'components/CardList'
import CustomReport from 'views/CustomReport/CustomReport'
import Card from 'components/Card'
import { HashLink as Link } from 'react-router-hash-link'
import styles from './ClientJob.scss'

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  reportGroup: PropTypes.shape({
    createdBy: PropTypes.string,
    createdDate: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    reports: PropTypes.arrayOf(PropTypes.object),
    tables: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
}

function ClientCustomReports({ reportGroup, dispatch }) {
  return (
    <div className="row">
      <div className="col-xs-12 col-sm-9 col-lg-9">
        <Heading className={styles.firstHeading} level={8}>
          {`${reportGroup.reports.length} Report${reportGroup.reports.length > 1 && 's'}` }
        </Heading>
        <CardList
          isCustomReport
          noItemPadding
          className={styles.reportCard}
          contentExtractor={(r) => {
            return (
              <CustomReport report={r} />
            )
          }}
          data={reportGroup.reports}
          itemClassName={styles.reportCardItem}
          keyExtractor={r => r.id}
          onClick={r => dispatch(push(`/custom/${reportGroup.id}/report/${r.id}`))}
        />
      </div>
      <div className="col-xs-12 col-sm-3 col-lg-3">
        <Heading className={styles.firstHeading} level={8}>&nbsp;</Heading>
        <Card className={styles.contactCard}>
          <Heading level={7}>
            Want to add more reports?
          </Heading>
          <p>
            Please use the link below to send a message to the InsightBox
            support team, or reach out to your Deloitte engagement team directly.
          </p>

          <Link to="/support#contact">
            <Button>
              Contact us
            </Button>
          </Link>
        </Card>
      </div>
    </div>
  )
}

ClientCustomReports.propTypes = propTypes

export default ClientCustomReports
