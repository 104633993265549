import React from 'react'
import PropTypes from 'prop-types'
import { CardActionArea } from '@material-ui/core'
import Card from 'components/Card'
import styles from './GenAiTemplateSelectionCard.scss'

const propTypes = {
  onClick: PropTypes.func.isRequired,
  templateDescription: PropTypes.string.isRequired,
  templateName: PropTypes.string.isRequired,
}

const GenAITemplateSelectionCard = ({ onClick, templateDescription, templateName }) => {
  return (
    <Card className={styles.genAiCard}>
      <CardActionArea className={styles.actionArea} onClick={onClick}>
        <div className={styles.heading}>{templateName}</div>
        <div className={styles.description}>{templateDescription}</div>
      </CardActionArea>
    </Card>
  )
}

GenAITemplateSelectionCard.propTypes = propTypes

export default GenAITemplateSelectionCard
