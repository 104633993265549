/* Generated by ./script/iconify.js */
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Colors } from '@deloitte/gel-library'
import IconBase from './IconBase'

const IconShareReport = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--sharereport',
    className,
  )

  return (
    <IconBase className={classes} viewBox="0 0 25.246 24" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g data-name="Group 4">
        <path className="path1" d="M0 0h24v24H0z" data-name="Path 7" fill="none" />
        <path className="path2" d="M19 3H5a2.006 2.006 0 0 0-2 2v14a2.006 2.006 0 0 0 2 2h14a2.006 2.006 0 0 0 2-2V5a2.006 2.006 0 0 0-2-2zM9 17H7v-7h2zm4 0h-2V7h2zm4 0h-2v-4h2z" data-name="Path 8" />
        <g data-name="Group 3">
          <path className="path3" d="M16 6.827L20 3l4 3.827h-2v3.827h-4V6.827z" data-name="Path 5" />
          <g data-name="Group 2"><path className="background-fill" d="M16 6.827L20 3l4 3.827h-2v3.826h-4V6.827z" data-name="Path 6" stroke={Colors.brandPrimaryGreen} strokeMiterlimit="8" /></g>
        </g>
      </g>
    </IconBase>
  )
}

IconShareReport.propTypes = {
  className: PropTypes.string,
}

IconShareReport.defaultProps = {
  className: null,
}

export default IconShareReport
