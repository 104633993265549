import merge from 'lodash/merge'

import {
  DIALOG_CREATE,
  DIALOG_HIDE,
  DIALOG_SHOW,
} from 'actions/dialogs'

const initialState = {
  _isShowing: false,
}

export default function dialogs(state = initialState, action) {
  switch (action.type) {
    case DIALOG_CREATE:
      return merge({}, state, {
        [action.id]: {
          show: false,
          options: {},
        },
      })
    case DIALOG_HIDE: {
      let isShowing = -1
      Object.keys(state).forEach((key) => {
        isShowing += (state[key].show) ? 1 : 0
      })
      isShowing = (isShowing > 0)

      return merge({}, state, {
        _isShowing: isShowing,
        [action.id]: {
          show: false,
        },
      })
    }
    case DIALOG_SHOW:
      return merge({}, state, {
        _isShowing: true,
        [action.id]: {
          show: true,
          options: {
            ...state[action.id].options,
            ...action.options,
          },
        },
      })
    default:
      return state
  }
}
