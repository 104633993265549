import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { hot } from 'react-hot-loader/root'
import { fetchHostedReport, removeHostedReportMember, updateHostedReportMembers } from 'actions/hostedReports'
import { connect } from 'react-redux'
import constants from 'constants'
import Banner from 'components/Banner'
import Container from 'components/layout/Grid/Container'
import { Switch } from 'react-router-dom'
import RouteWithSubRoutes from 'components/RouteWithSubRoutes'
import Loading from 'components/Loading'
import ManageJobModal from 'components/ManageJobModal'
import { modalShow } from 'actions/modals'
import { isPartnerRole, isManagerRole, isManagerOrPartnerOnJob } from 'utils/business/jobs'
import AddExternalUserModal from 'components/AddExternalUserModal'
import { updateExternalUsersOnHostedReport } from 'actions/forms/addExternalUsers'
import HostedReportModal from 'components/HostedReportModal'

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  forms: PropTypes.object.isRequired,
  group: PropTypes.shape({
    _isFetching: PropTypes.bool,
    engagement: PropTypes.shape({
      clientName: PropTypes.string,
      code: PropTypes.string,
    }),
    id: PropTypes.string,
    members: PropTypes.arrayOf(PropTypes.object),
    name: PropTypes.string,
  }),
  groupId: PropTypes.string.isRequired,
  modals: PropTypes.object.isRequired,
  routes: PropTypes.arrayOf(PropTypes.shape({
    path: PropTypes.string,
  })).isRequired,
  user: PropTypes.shape({
    isAdmin: PropTypes.bool,
    username: PropTypes.string,
  }).isRequired,
}

const defaultProps = {
  group: null,
}

function HostedReportGroup({
  group, routes, dispatch, forms, modals, user, groupId,
}) {
  useEffect(() => {
    dispatch(fetchHostedReport(groupId))
  }, [dispatch, groupId])

  const openMemberEditPopup = () => {
    dispatch(modalShow(constants.MODAL_MANAGE_JOB))
  }

  if (!group || group._isFetching) {
    return (
      <Loading pageLoading />
    )
  }

  const isManagerOrPartner = isManagerOrPartnerOnJob(user, group.members)

  const partner = group.members.find(x => isPartnerRole(x.role.name))
  const manager = group.members.find(x => isManagerRole(x.role.name))

  const groupForManageJob = {
    ...group,
    engagementPartner: partner && partner.userDetails.displayName,
    engagementManager: manager && manager.userDetails.displayName,
    members: group.members.map(m => ({ ...m, value: m.userDetails.email, label: m.userDetails.displayName })),
  }

  return (
    <div>
      <Banner
        defaultBack="/custom"
        editLabel="Edit collection"
        name={group.name}
        onEdit={() => dispatch(modalShow(constants.MODAL_EDIT_HOSTED_REPORT))}
        routes={routes}
        subtitle={group.engagement.clientName}
      />
      <Container bottomMargin topPadding>
        <Switch>
          {routes.map(route => (
            <RouteWithSubRoutes
              canAddExternalUser={isManagerOrPartner || user.isAdmin}
              dispatch={dispatch}
              job={group}
              key={route.path}
              onRemoveMember={(userId, userDisplayName, callback) => dispatch(removeHostedReportMember(group, userId, userDisplayName, callback))}
              openExternalUserModal={() => dispatch(modalShow(constants.MODAL_ADD_EXTERNAL_USER))}
              openJobPopup={openMemberEditPopup}
              {...route}
            />
          ))}
        </Switch>
        <ManageJobModal
          dispatch={dispatch}
          forms={forms}
          job={groupForManageJob}
          key={group.id}
          modals={modals}
          onSubmit={(formId, id, members) => dispatch(updateHostedReportMembers(formId, id, members, group.members))}
          userName={user.username}
        />

        <HostedReportModal group={group} mode="edit" />

        <AddExternalUserModal
          dispatch={dispatch}
          forms={forms}
          job={group}
          modals={modals}
          onSubmit={() => dispatch(updateExternalUsersOnHostedReport(group.id))}
        />
      </Container>
    </div>
  )
}

HostedReportGroup.propTypes = propTypes
HostedReportGroup.defaultProps = defaultProps

const mapStateToProps = ({
  hostedReports, forms, modals, app: { user },
},
{ match: { params: { groupId } } }) => {
  return {
    forms,
    modals,
    user,
    groupId,
    group: hostedReports.reportGroups[groupId],
  }
}

export default hot(connect(mapStateToProps)(HostedReportGroup))
