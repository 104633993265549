import React from 'react'
import PropTypes from 'prop-types'
import { Card, Heading } from '@deloitte/gel-library'
import styles from './HeaderCard.scss'

const propTypes = {
  children: PropTypes.node,
  heading: PropTypes.string.isRequired,
  highlightColor: PropTypes.string,
  status: PropTypes.string.isRequired,
  subHeading: PropTypes.string.isRequired,
}

const defaultProps = {
  children: null,
  highlightColor: null,
}

function HeaderCard({
  heading, subHeading, status, children, highlightColor,
}) {
  return (
    <Card noPadding className={styles.base}>
      <div className={styles.topSection} style={{ borderColor: highlightColor }}>
        <Heading level={7}>{heading}</Heading>
        <p>
          <span className={styles.status} style={{ backgroundColor: highlightColor }}>{status}</span>
          {subHeading}
        </p>
      </div>
      <div>
        {children}
      </div>
    </Card>
  )
}

HeaderCard.propTypes = propTypes
HeaderCard.defaultProps = defaultProps

export default HeaderCard
