/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconAppXero = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--appxero',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 68"{...rest}><path className="path1" fill="#02B6E1" d="M58.1 68H9.9C4.4 68 0 63.6 0 58.1V9.9C0 4.4 4.4 0 9.9 0h48.2C63.6 0 68 4.4 68 9.9v48.2c0 5.5-4.4 9.9-9.9 9.9"/><path className="path2" fill="#00A1DE" d="M0 9.9v48.2C0 63.6 4.4 68 9.9 68h48.2L9.9 0C4.4 0 0 4.4 0 9.9"/><path className="path3" fill="#FBFDFE" d="M31 34.7h-4.2c-.4 0-.4.1-.3.4.6 1.7 1.7 2.9 3.5 3.3 1.8.4 3.3-.2 4.4-1.7.2-.3.4-.5.7-.5.4 0 .7.1.9.4.2.4.1.8-.1 1.2-1.2 1.5-2.7 2.3-4.6 2.5-3.1.2-6.1-2.1-6.6-5.2-.5-3.3 1.5-6.4 4.6-7.2 3.6-.9 7.1 1.3 7.7 4.9.2.9-.3 1.7-1.2 1.9H31m-.2-1.8h4c.4 0 .4-.1.3-.4-.6-1.8-2.4-3-4.3-3s-3.7 1.3-4.3 3.1c-.1.3 0 .4.3.4 1.3-.1 2.7-.1 4-.1M49.6 40.3c-3.4 0-6.3-2.8-6.3-6.2 0-3.6 2.7-6.4 6.2-6.4s6.3 2.8 6.4 6.3c0 3.5-2.8 6.3-6.3 6.3m0-10.8c-2.4 0-4.5 2-4.5 4.4 0 2.5 2 4.5 4.5 4.5 2.4 0 4.5-2 4.5-4.4s-2-4.5-4.5-4.5"/><path className="path4" fill="#FBFDFE" d="M23.9 40.3c-.5 0-.7-.2-.9-.4l-4.3-4.3c-.3-.3-.4-.2-.6 0-1.4 1.5-2.9 2.9-4.4 4.4-.5.5-1.2.4-1.5-.1-.2-.4-.1-.8.2-1.1.8-.8 1.7-1.6 2.5-2.5.7-.7 1.3-1.4 2-2 .2-.2.1-.3-.1-.5l-4.2-4.2-.3-.3c-.3-.5-.3-1 .1-1.3.3-.3.8-.3 1.2 0 .2.2.4.3.5.5l4 4c.3.3.4.2.6 0l4.4-4.4c.3-.3.8-.4 1.1-.2.3.2.5.6.4 1-.1.2-.2.4-.4.6l-4.3 4.3c-.2.2-.3.4 0 .6 1.5 1.4 2.9 2.9 4.3 4.3.4.4.5.8.3 1.2-.1.2-.4.3-.6.4M38.7 34v-5.1c0-.5.2-.9.6-1 .4-.1.8 0 1.1.4.1.2.2.1.4 0 .7-.4 1.4-.6 2.2-.5.5 0 .8.4.8.8 0 .5-.3.8-.8.9-.2 0-.5 0-.7.1-1.2.2-1.6.7-1.7 1.9-.1.7-.1 1.5-.1 2.2v5.4c0 .6-.3 1-.9 1-.5 0-.9-.4-.9-1V34"/><path className="path5" fill="#FFF" d="M49.6 35.6c-.9 0-1.6-.7-1.6-1.6 0-.9.7-1.6 1.6-1.5.9 0 1.6.7 1.6 1.6 0 .8-.8 1.5-1.6 1.5"/></IconBase>
  )
}

IconAppXero.propTypes = {
  className: PropTypes.string,
}

export default IconAppXero
/* eslint-enable */
