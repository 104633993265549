import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Colors } from '@deloitte/gel-library'
import { IconInfo, IconError, IconErrorOrange } from 'icons'
import styles from './Messagebar.scss'

const Preloader = () => {
  return (
    <div className={styles.preloader}>
      <div className={styles.spinnerlayer}>
        <div className={classnames(styles.circleclipper, styles.left)}>
          <div className={styles.circleleft} />
        </div>
        <div className={styles.gappatch}>
          <div className={styles.circle} />
        </div>
        <div className={classnames(styles.circleclipper, styles.right)}>
          <div className={styles.circleright} />
        </div>
      </div>
    </div>
  )
}

const Messagebar = ({
  type, children, className, spinning, noBottomMargin,
}) => {
  let Icon
  switch (type) {
    case 'error':
      Icon = (
        <IconError
          height="20"
          width="20"
        />
      )
      break
    case 'warn':
      Icon = (
        <IconErrorOrange
          height="19"
          width="22"
        />
      )
      break
    case 'warn-grey':
      Icon = (
        <IconErrorOrange
          fill={Colors.uiGrey05}
          height="19"
          width="22"
        />
      )
      break
    case 'info':
      Icon = (
        <IconInfo
          fill="#5ABAEA"
          height="20"
          width="20"
        />
      )
      break
    case 'info-grey':
      Icon = (
        <IconInfo
          height="20"
          width="20"
        />
      )
      break
    default:
      Icon = (
        <IconInfo
          fill="#5ABAEA"
          height="20"
          width="20"
        />
      )
  }
  const classes = {
    [styles.noBottomMargin]: noBottomMargin,
  }

  return (
    <section className={classnames(styles.section, classes, styles[type], className)}>
      <div className={styles.icon}>
        {spinning ? <Preloader /> : Icon}
      </div>
      <span>
        { children }
      </span>
    </section>
  )
}

Messagebar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  noBottomMargin: PropTypes.bool,
  spinning: PropTypes.bool,
  type: PropTypes.oneOf(['error', 'warn', 'info', 'info-grey', 'warn-grey']).isRequired,
}

Messagebar.defaultProps = {
  children: null,
  className: null,
  noBottomMargin: false,
  spinning: false,
}

export default Messagebar
