import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import JobDetailSidebar from 'views/JobDetailSidebar'
import { Heading } from '@deloitte/gel-library'
import { fetchActions } from 'actions/job'
import { userPropType } from 'utils/propTypes'
import { getConfig } from 'utils/config'
import LoadingBox from 'components/LoadingBox'
import { fetchClientActions } from 'actions/client'
import { roles } from 'utils/business/jobs'
import { useDispatch } from 'react-redux'
import ActionGroup from 'components/ActionGroup'
import Container from 'components/layout/Grid/Container'
import styles from './Actions.scss'

const propTypes = {
  job: PropTypes.shape({
    _fetchingActionsError: PropTypes.string,
    _isCreatingActions: PropTypes.bool,
    _isFetchedActions: PropTypes.bool,
    _isFetchingActions: PropTypes.bool,
    actions: PropTypes.shape({
      _hasFetchedAllActions: PropTypes.bool,
      currentActions: PropTypes.arrayOf(PropTypes.object),
      potentialActions: PropTypes.arrayOf(PropTypes.object),
      previousActions: PropTypes.arrayOf(PropTypes.object),
    }),
    jobId: PropTypes.string,
    lastExecution: PropTypes.object,
    member: PropTypes.object,
    members: PropTypes.arrayOf(userPropType),
  }).isRequired,
  uploaders: PropTypes.shape({
    uploaders: PropTypes.object,
  }).isRequired,
  user: PropTypes.shape({
    _isFetched: PropTypes.bool,
    isExternal: PropTypes.bool,
  }).isRequired,
  validations: PropTypes.shape({
    validations: PropTypes.object,
  }).isRequired,
}

function Actions({
  user: { isExternal, _isFetched }, job, validations: { validations },
}) {
  const dispatch = useDispatch()

  const {
    jobId,
    actions,
    _isFetchedActions,
    _isFetchingActions,
    _fetchingActionsError,
    member,
  } = job
  const userJobRole = member?.role?.name

  useEffect(() => {
    if (jobId && _isFetched) {
      if (isExternal && userJobRole !== roles.JobExternalEditor) {
        dispatch(fetchClientActions(jobId))
      } else {
        dispatch(fetchActions({ jobId }))
      }
    }
  }, [dispatch, jobId, isExternal, _isFetched, userJobRole])

  const { previousActions } = (actions || {})

  const validationArray = validations
    ? Object.keys(validations).map(k => validations[k])
    : []
  const criticalValidations = validationArray
    .filter(x => x.severity === 'Critical' && x.affectedRows > 0)

  const showPreviousActions = previousActions && previousActions.length > 0 && !isExternal

  const {
    URL: { SUPPORT: supportUrl },
  } = getConfig()

  const canRenderActions = _isFetchedActions && !_fetchingActionsError && actions && actions._hasFetchedAllActions

  return (
    <Container className={styles.base}>
      <div className="row">
        <div className={isExternal ? 'col-xs-12' : 'col-xs-12 col-sm-7 col-lg-9'}>
          <Heading level={8}>
            Archived actions
          </Heading>

          {!_isFetchedActions && _isFetchingActions && <LoadingBox className={styles.loading} />}

          {_fetchingActionsError && <p>There was an error loading the actions for your job. Please try again later.</p>}

          {canRenderActions && showPreviousActions && (
            <ActionGroup
              showHeader
              actions={previousActions}
              job={job}
            />
          )}

          {previousActions && previousActions.length === 0 && (
            <p>Archived actions from prior runs of this job will be found here.</p>
          )}

        </div>
        <div className="col-xs-12 col-sm-5 col-lg-3">
          <JobDetailSidebar
            criticalValidations={criticalValidations}
            job={job}
            supportUrl={supportUrl}
          />
        </div>
      </div>
    </Container>
  )
}

Actions.propTypes = propTypes

export default Actions

