import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TouView from 'views/Tou/TouView'
import { connect } from 'react-redux'
import { acceptTou } from 'actions/app'
import touText from 'static/docs/TOU.md'

class Tou extends Component {
  static propTypes = {
    _isAcceptingTou: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    hasAcceptedTou: PropTypes.bool.isRequired,
  }

  state = {
    tou: '',
  }

  componentDidMount() {
    fetch(touText)
      .then((response) => {
        return response.text()
      })
      .then((tou) => {
        this.setState({ tou })
      })
  }

  handleAccept = () => {
    const { dispatch } = this.props
    dispatch(acceptTou())
  }

  render() {
    const { tou } = this.state
    const { _isAcceptingTou, hasAcceptedTou } = this.props

    return (
      <TouView
        accept={this.handleAccept}
        hasAccepted={hasAcceptedTou}
        isAccepting={_isAcceptingTou}
        tou={tou}
      />
    )
  }
}

const mapStateToProps = ({ app: { _isAcceptingTou, user: { hasAcceptedEula } } }) => {
  return {
    _isAcceptingTou,
    hasAcceptedTou: hasAcceptedEula,
  }
}

export default connect(mapStateToProps)(Tou)
