/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconUpdatePaymentInfo = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--updatepaymentinfo',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104 104"{...rest}><g fill="none" fillRule="evenodd" strokeWidth="2.2" transform="translate(2 2)"><path className="path1" d="M73.27 37.336v22.667c0 3.117-2.115 5.666-4.701 5.666h-37.6c-2.585 0-4.7-2.549-4.7-5.666V37.336c0-3.116 2.115-5.667 4.7-5.667h37.6c2.586 0 4.7 2.55 4.7 5.667zm-.666.664H26.27m46.334 7H26.27m5.464 8h15.394m-15.394 5h7.697" stroke="#86BC25" strokeLinecap="round" strokeLinejoin="round"/><circle cx="50" cy="50" r="50" stroke="#86BD24"/></g></IconBase>
  )
}

IconUpdatePaymentInfo.propTypes = {
  className: PropTypes.string,
}

export default IconUpdatePaymentInfo
/* eslint-enable */
