/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconReport = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--report',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"{...rest}><path className="path1" fill="#58595B" d="M19.9 24H3.3c-.8 0-1.5-.7-1.5-1.5v-21C1.8.7 2.5 0 3.3 0h11.9c.6 0 1.4.3 1.8.8l3.6 3.6c.4.4.8 1.2.8 1.8v12.5h-1.3V6.3c0-.3-.2-.7-.4-.9l-3.6-3.6c-.2-.2-.6-.4-.9-.4H3.3c-.1 0-.1.1-.1.1v21c0 .1.1.1.1.1h16.6c.1 0 .1-.1.1-.1v-4.6h1.3v4.6c.1.8-.5 1.5-1.4 1.5z"/><path className="path2" fill="#58595B" d="M20.5 6.1H16c-.4 0-.7-.3-.7-.7V1.2h1.3v3.6h3.8v1.3zM16.1 9.6h-9c-.2 0-.4-.2-.4-.4s.2-.4.4-.4h9c.2 0 .4.2.4.4s-.1.4-.4.4zM16.1 12.3h-9c-.2 0-.4-.2-.4-.4s.2-.4.4-.4h9c.2 0 .4.2.4.4s-.1.4-.4.4zM13.1 14.9h-6c-.2 0-.4-.2-.4-.4s.2-.4.4-.4h6c.2 0 .4.2.4.4s-.2.4-.4.4z"/></IconBase>
  )
}

IconReport.propTypes = {
  className: PropTypes.string,
}

export default IconReport
/* eslint-enable */
