import { sortBy, every, merge } from 'lodash'
import moment from 'moment'
import { isNumeric } from './utils'

/* eslint-disable */
const regexEmail = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
const regexURL = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/
const regexPhone = /(^0[2|3|7|8]{1}[0-9]{8}$)/
const regexMobile = /(^04\d{2}\d{6}$)/
const regexCard = /^(\d{14,16})$/
const regexCCV = /^(\d{3,4})$/
const regexABN = /^(\d *?){11}$/
const regexText = /^[a-zA-Z\s\d]+$/
const regexTextUnicode = /^[a-zA-Z,\.\'\`\-\u00A0-\u00FF\s]+$/
const regexPostcode = /^\d{4}$/
const regexBSB = /^\d{6}$/
const regexAccountNumber = /^\d{6,10}$/
const regexInteger = /^[-+]?\d+$/
const regexFloating = /^[+-]?([0-9]*[.]?)?[0-9]*$/
const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]
const regexDate = /^\d{4}-\d{2}-\d{2}$/
const regexDeloitteEmail = new RegExp(''
  + '@(?:(?:deloitte\\.)'
  + '|(?:deloittemx\\.com)'
  + '|(?:deloittece\\.com)'
  + '|(?:deloittelegal\\.ca)'
  + '|(?:Constantin\\.co\\.uk)'
  + '|(?:s2g\\.deloitte)'
  + '|(?:Sabaralaw\\.com\\.sg)'
  + '|(?:ctsu\\.pt)'
  + '|(?:taj\\.fr)'
  + '|(?:tohmatsu\\.co\\.jp)'
  + '|(?:Deloittelegal\\.com\\.sg)'
  + '|(?:dgilaw\\.com)'
  + '|(?:Hjplaw-deloitte\\.com)'
  + '|(?:auvenir\\.com))', 'i')

// Min & CHaracters and at least one Alpha Character
const regexPasswordRequiremets = /^(?=.*[0-9])(?=.*[a-zA-Z])([\S\s]){8,}$/

// http://stackoverflow.com/a/15504877/132164
// dd/mm/yy regex that also checks the right number of days per month (incl. leap years)

const regexDDMMYY = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
/* eslint-enable */

/* Test a given regex againt a given value
   Return true | false
*/
const regexTest = (regex, value) => {
  if (value === '') {
    return true
  }
  return regex.test(value)
}

/* Validation object
   Returns an object with default properties or with updated values
*/
const validationObj = ({ error, valid = false, condition }) => {
  return {
    error: valid ? null : (error || 'This field is required'),
    valid,
    condition,
  }
}

/* isEmail
   Returns true if string provided is a valid email
*/
export const isEmail = email => regexEmail.test(email)

/* isDeloitteEmail
   Returns true if string provided is a Deloitte email
*/
export const isDeloitteEmail = email => regexDeloitteEmail.test(email)

/* Validation rules used for validating different form values
   Returns a validation objed with information about the validation.
*/
export default class Validators {
  static required(val = '', error = null) {
    const valid = val !== null && String(val).trim().length > 0

    return validationObj({
      valid,
      error,
    })
  }

  static either(val1, val2, key1, key2, error = null) {
    const valid = !(String(val1) === '' && String(val2) === '')

    return validationObj({
      condition: key2,
      valid,
      error,
    })
  }

  static equalTo(val, val2, key1, key2, error = null) {
    const valid = String(val) === String(val2)

    return validationObj({
      condition: key2,
      valid,
      error,
    })
  }

  static bound(val, val2, key1, key2, error = null) {
    return validationObj({
      condition: key2,
      valid: true,
      error,
    })
  }

  static expirydate(val, val2, key1, key2, error = null, currentKey) {
    if (!val || !val2) {
      return validationObj({
        condition: currentKey === key1 ? key2 : key1,
        valid: true,
        error,
      })
    }

    const valid = Date.now() < new Date(val, parseInt(val2, 10) - 1, 1).getTime()

    return validationObj({
      condition: currentKey === key1 ? key2 : key1,
      valid,
      error,
    })
  }

  static passwordRequirements(val, val2, key1, key2, error = null) {
    const valid = regexTest(regexPasswordRequiremets, val)
      && regexTest(regexPasswordRequiremets, val2)

    return validationObj({
      condition: key2,
      valid,
      error,
    })
  }

  static requiredNumber(val, error = null) {
    const valid = !(val === '' || Number.isNaN(val) || val === null) || val === 0

    return validationObj({
      valid,
      error,
    })
  }

  static isText(val, error = null) {
    const valid = regexTest(regexText, val)

    return validationObj({
      valid,
      error,
    })
  }

  static isTextUnicode(val, error = null) {
    const valid = regexTest(regexTextUnicode, val)
    return validationObj({
      valid,
      error,
    })
  }

  static isPostcode(val, error = null) {
    const valid = regexTest(regexPostcode, val)

    return validationObj({
      valid,
      error,
    })
  }

  static isBSB(val, error = null) {
    const valid = regexTest(regexBSB, val)

    return validationObj({
      valid,
      error,
    })
  }

  static isEmail(val, error = null) {
    const valid = regexTest(regexEmail, val)

    return validationObj({
      valid,
      error,
    })
  }

  static isValidURL(val, error = null) {
    let valid
    if (val.startsWith('/')) {
      valid = true
    } else if (val.startsWith('http')) {
      valid = regexTest(regexURL, val)
    } else {
      valid = false
    }

    return validationObj({
      valid,
      error,
    })
  }

  static isPhone(val = '', error = null) {
    const valid = val.length ? regexTest(regexPhone, val) : true

    return validationObj({
      valid,
      error,
    })
  }

  static isMobile(val = '', error = null) {
    const valid = val.length ? regexTest(regexMobile, val) : true

    return validationObj({
      valid,
      error,
    })
  }

  static dateFormatDMY(val = '', error = null) {
    const valid = regexTest(regexDDMMYY, val)

    return validationObj({
      valid,
      error,
    })
  }

  static dateCheck(val, val2, key1, key2, error = null) {
    const valid = moment(
      `${val2}/${MONTHS[val]}/2017`,
      'D/MMM/YYYY',
      true,
    ).isValid()
    // console.warn(`val: ${val}, val2: ${val2}, key1: ${key1}, key2: ${key2}, ${valid}`)
    return validationObj({
      condition: key2,
      valid: val && val2 ? valid : true,
      error,
    })
  }

  static validateTableCellMinMax(value, columnMeta) {
    const valid = true
    const error = ''

    if (!isNumeric(value)) {
      return validationObj({ valid: false, error: 'Enter a numeric value' })
    }

    if (columnMeta.max && columnMeta.min) {
      if (parseFloat(value) > parseFloat(columnMeta.max) || parseFloat(value) < parseFloat(columnMeta.min)) {
        return validationObj({ valid: false, error: `Enter values between ${columnMeta.min} and ${columnMeta.max}` })
      }
    } else {
      if (columnMeta.min) {
        if (parseFloat(value) < parseFloat(columnMeta.min)) {
          return validationObj({ valid: false, error: `Enter a value greater than or equal to ${columnMeta.min}` })
        }
      }
      if (columnMeta.max) {
        if (parseFloat(value) > parseFloat(columnMeta.max)) {
          return validationObj({ valid: false, error: `Enter a value less than or equal to ${columnMeta.max}` })
        }
      }
    }

    return validationObj({
      valid,
      error,
    })
  }

  static validateTableCell(value, columnMeta) {
    let result = {}
    switch (columnMeta.type) {
      case 'DATE':
        result = Validators.datefield(value)
        break
      case 'NVARCHAR':
        result = Validators.required(value)
        break
      case 'INT':
        result = Validators.intOnly(value, 'Not an integer')
        break
      case 'FLOAT':
        result = Validators.floatOnly(value)
        break
      case 'COMBOBOX':
        result = { valid: true }
        break
      default:
        result = { valid: false }
    }
    if (result.valid) {
      // do the min max check only if min/max exists
      if (columnMeta.min !== null || columnMeta.max !== null) {
        const minMaxCheck = Validators.validateTableCellMinMax(value, columnMeta)
        if (!minMaxCheck.valid) {
          return minMaxCheck
        }
      }
    }
    return result
  }

  // eslint-disable-next-line no-unused-vars
  static tableCheck(val, error = null, options, context) {
    const { schema } = options

    let results = {}

    // Only validate latest change if context is present
    if (context) {
      const { row: rowKey, column: columnName, priorError } = context

      if (rowKey === null || columnName === null) {
        const valid = every(priorError, result => result.valid)
        return validationObj({
          valid,
          error: priorError,
        })
      }

      const rowToValidate = val.find(r => r.key === rowKey)
      const columnMeta = schema.find(c => c.name === columnName)
      const validation = Validators.validateTableCell(rowToValidate[columnName], columnMeta)

      const priorRowError = priorError[rowKey] || {}

      results = {
        ...priorError,
        [rowKey]: {
          ...priorRowError,
          [columnName]: validation,
        },
      }

      results[rowKey].valid = every(results[rowKey], result => result.valid || typeof result.valid === 'undefined')
    } else {
      // validate entire table if no context provided
      val.forEach((row) => {
        const rowValidation = {}
        schema.forEach((col) => {
          rowValidation[col.name] = Validators.validateTableCell(row[col.name], col)
          rowValidation.valid = every(rowValidation, result => result.valid || typeof result.valid === 'undefined')
        })
        results[row.key] = rowValidation
      })
    }

    const valid = every(results, result => result.valid)

    return validationObj({
      valid,
      error: valid ? {} : results,
    })
  }

  static nameCharacters(val, error = null) {
    const valid = regexTest(regexText, val)
    return validationObj({
      valid,
      error,
    })
  }

  static intOnly(val, error = null) {
    const valid = !val || regexTest(regexInteger, val)
    return validationObj({
      valid,
      error,
    })
  }

  static floatOnly(val, error = null) {
    const valid = !val || regexTest(regexFloating, val)
    return validationObj({
      valid,
      error,
    })
  }

  static numberRange(val, error = null, options) {
    if ((!options.min && !options.max) || !val) {
      return validationObj({
        valid: true,
      })
    }

    const valid = val >= options.min && val <= options.max
    return validationObj({
      valid,
      error,
    })
  }

  static datefield(val, error = 'Enter a valid date') {
    const valid = regexTest(regexDate, val)

    if (!valid) {
      return validationObj({ valid, error })
    }

    const date = moment(val, 'YYYY-MM-DD', true)
    if (!date.isValid()) {
      return validationObj({
        valid: date.isValid(),
        error,
      })
    }

    const minimumDate = moment('1900-01-01', 'YYYY-MM-DD', true)
    if (minimumDate > date) {
      return validationObj({
        valid: false,
        error: 'Invalid date.',
      })
    }

    return validationObj({
      valid: true,
      error,
    })
  }

  static stringWithinLength(val, options) {
    if ((!options.min && !options.max) || !val) {
      return validationObj({
        valid: true,
      })
    }

    const min = options.min || 0
    const max = options.max || 1000000000

    const valid = val.length >= min && val.length <= max
    return validationObj({
      valid,
      error: options.min === options.max
        ? `Text must have ${options.min} characters`
        : `Text must have between ${options.min} and ${options.max} characters`,
    })
  }
}

/* Validate a value against its given Validation rules.
   Return an and error object if errors are found.
*/
const _valueCheck = (requiredObj, value, values, context) => {
  let errorObj = {}
  let hasError = false
  const validators = sortBy(requiredObj.validators, 'priority')
  const defaultError = requiredObj.error

  validators.forEach(({
    check, error, fields, options,
  }) => {
    let outcome
    const message = error || defaultError

    if (fields) {
      const keys = fields.split('|')
      const key1 = keys[0]
      const key2 = keys[1]

      outcome = Validators[check](
        values[key1].value,
        values[key2].value,
        key1,
        key2,
        message,
        requiredObj.name,
        options,
        context,
      )
    } else {
      outcome = Validators[check](value, message, options, context)
    }

    errorObj.name = requiredObj.name

    if (!hasError) {
      errorObj = merge(errorObj, outcome)
    }

    if (outcome?.valid === false && !hasError) {
      hasError = true
      errorObj = merge(errorObj, outcome)
    }
  })

  return errorObj
}

export const getSerializedFormState = (values) => {
  const data = {}

  Object.keys(values).forEach((key) => {
    if (key.indexOf('_') !== 0) {
      data[key] = values[key].value
    }
  })

  return data
}

const flattenFields = (arr) => {
  return arr.reduce((flat, toFlatten) => {
    return flat.concat(
      Array.isArray(toFlatten.elements)
        ? flattenFields(toFlatten.elements)
        : toFlatten,
    )
  }, [])
}

export const handleError = (errorObj, stateKey) => ({
  data: {
    focus: false,
    error: errorObj.valid ? '' : errorObj.error,
    valid: errorObj.valid,
  },
  field: stateKey,
})

export const requiredFields = (fields) => {
  return flattenFields(fields).filter(
    field => field.validators && field.validators.length,
  )
}

export const handleErrors = errors => errors.map(errObj => handleError(errObj, errObj.name))

export const getDefaultForm = (fields) => {
  const obj = {}
  fields.map(field => field.name).forEach((name) => {
    obj[name] = {
      value: '',
      error: '',
    }
  })

  return obj
}

export const validateAll = (requiredFieldsParam, values) => {
  const errors = []
  Object.keys(values).forEach((key) => {
    const val = values[key].value

    requiredFieldsParam.forEach((obj) => {
      if (key !== obj.name) {
        return
      }

      if (
        obj.validateDependenceElement
        && obj.validateDependenceValue
        && values[obj.validateDependenceElement].value
        !== obj.validateDependenceValue
      ) {
        return
      }

      const check = _valueCheck(obj, val, values)

      // Add to errors value didnt pass its check
      if (check && !check.valid) {
        errors.push(check)
      }
    })
  })

  return errors
}

export const validateField = (
  requiredFieldsParam,
  name,
  value,
  values,
  context,
) => {
  let errors
  requiredFieldsParam.forEach((obj) => {
    if (name !== obj.name) {
      return
    }
    const check = _valueCheck(obj, value, values, context)
    if (check) {
      errors = check
    }
  })

  return errors
}
