import * as ActionTypes from 'actions/packages'

const initialState = {
  collections: [],
  ids: [],
  packages: {},
  isFetching: false,
  isFetched: false,
  isPackageFetching: false,
  isPackageFetched: false,
}

export default function job(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.PACKAGES_FETCHED:
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        collections: action.collections,
        ids: action.ids,
        packages: action.packages,
      }
    case ActionTypes.PACKAGES_FETCHING:
      return {
        ...state,
        isFetching: true,
      }
    case ActionTypes.PACKAGE_FETCHING:
      return {
        ...state,
        isPackageFetching: true,
        isPackageFetched: false,
      }
    case ActionTypes.PACKAGE_FETCHED:
      return {
        ...state,
        isPackageFetching: false,
        isPackageFetched: true,
        packages: {
          ...state.packages,
          [action.packageId]: {
            ...state.packages[action.packageId],
            ...action.thePackage,
          },
        },
      }
    default:
      return state
  }
}
