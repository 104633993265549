/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconReportAlert = ({ className, ...rest }) => {
  const classes = classnames('icon', 'icon--reportalert', className)

  return (
    <IconBase
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      {...rest}
    >
      <g fill="none" fillRule="evenodd">
        <path className="path1" d="M-3-3h24v24H-3z" />
        <path
          className="path2 fill"
          fill="#6D6D6D"
          fillRule="nonzero"
          d="M16 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM6 14H4V7h2v7zm4 0H8V4h2v10zm4 0h-2v-4h2v4z"
        />
      </g>
    </IconBase>
  )
}

IconReportAlert.propTypes = {
  className: PropTypes.string,
}

export default IconReportAlert
/* eslint-enable */
