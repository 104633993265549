import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { select } from 'd3-selection'
import moment from 'moment'
import * as icons from 'icons'
import formStyles from 'components/Form/Form.scss'
import TextInput from '../TextInput'
import styles from './DateInput.scss'

const dateFormat = 'YYYY-MM-DD'

class DateInput extends Component {
  static propTypes = {
    className: PropTypes.string,
    date: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    focus: PropTypes.bool,
    handleChange: PropTypes.func.isRequired,
    icon: PropTypes.string,
    name: PropTypes.string.isRequired,
    note: PropTypes.string,
    onBlur: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    valid: PropTypes.bool,
  }

  static defaultProps = {
    className: null,
    date: null,
    disabled: false,
    error: '',
    focus: false,
    icon: '',
    note: null,
    valid: true,
  }

  state = {
    day: '',
    month: '',
    year: '',
  }

  constructor(props) {
    super(props)

    this.ref = React.createRef()
  }

  componentDidMount = () => {
    this._updateDateComponents()
  }

  moveToNext = (current) => {
    let selectString
    switch (current) {
      case 'day':
        selectString = '.mm input'
        break
      case 'month':
        selectString = '.yyyy input'
        break
      default:
        selectString = null
    }

    if (selectString) {
      select(this.ref.current).select(selectString).node().focus()
    }
  }

  _updateDateComponents = () => {
    const { date } = this.props
    let day
    let month
    let year

    const parsedDate = moment(date, dateFormat, true)

    if (parsedDate.isValid()) {
      day = parsedDate.date().toString()
      month = (parsedDate.month() + 1).toString()
      year = parsedDate.year().toString()
    }

    this.setState({
      day,
      month,
      year,
    })
  }

  createIcon = (Icon, classes) => {
    return <Icon className={classes} height="16" width="16" />
  }

  _handleBlur = () => {
    const { name, onBlur } = this.props
    const { day, month, year } = this.state

    // TODO: Should add padded 0s to start of day, month and year strings
    // const paddedDay = day ? day.padStart(2, '0') : day
    // const paddedMonth = month ? month.padStart(2, '0') : month

    const date = moment({ year, month, date: day }).format(dateFormat)
    onBlur(name, date)

    this.setState(({ day: oDay, month: oMonth }) => ({
      day: oDay ? oDay.padStart(2, '0') : oDay,
      month: oMonth ? oMonth.padStart(2, '0') : oMonth,
    }))
  }

  _handleFocus = (event, b) => {
    const { name, onFocus } = this.props
    b.target.select()
    onFocus(name)
  }

  _handleChange = (name, value) => {
    const { name: fieldName, handleChange } = this.props
    const { day: displayDay, month: displayMonth, year: displayYear } = this.state

    const day = +displayDay
    const month = +displayMonth - 1
    const year = +displayYear

    const lastCharacter = value.slice(-1)
    if (lastCharacter === '/' || lastCharacter === '-') {
      this.moveToNext(name)
      return
    }

    let date
    let monthValue
    let parsedValue

    switch (name) {
      case 'day':
        date = moment({ year, month, date: value })
        parsedValue = date.date()
        break
      case 'month':
        monthValue = Number.isNaN(value) ? value : +value - 1
        date = moment({ year, month: monthValue, day })
        parsedValue = date.month() + 1
        break
      case 'year':
        date = moment({ year: value, month, day })
        parsedValue = date.year()
        break
      default:
        break
    }

    if (name === 'day' && value.length === 2) {
      this.moveToNext('day')
    }

    if (name === 'month' && value.length === 2) {
      this.moveToNext('month')
    }

    handleChange(fieldName, date.isValid() ? date.format(dateFormat) : '')

    this.setState(
      {
        [name]: Number.isNaN(parsedValue) ? value.replace(/\D/g, '') : parsedValue.toString(),
      },
      // this._handleBlur,
    )
  }

  render() {
    const {
      icon,
      disabled,
      error,
      focus,
      note,
      valid,
      className,
    } = this.props

    const { day, month, year } = this.state

    const displayNote = !valid ? formStyles.isHidden : ''
    const focusIcon = focus ? formStyles.inputIconActive : ''

    return (
      <div
        className={classnames(
          styles.base,
          { [styles.invalid]: !valid },
          className,
        )}
        ref={this.ref}
      >
        <div className={styles.datefield}>
          <TextInput
            className={classnames(styles.dd, {
              [styles.textInputInvalid]: !valid,
            })}
            disabled={disabled}
            error={error}
            handleChange={this._handleChange}
            hint="DD"
            label=""
            maxLength={2}
            name="day"
            onBlur={this._handleBlur}
            onFocus={this._handleFocus}
            showTextBelow={false}
            text={day}
            type="text"
          />
          <TextInput
            className={classnames('mm', styles.mm, {
              [styles.textInputInvalid]: !valid,
            })}
            disabled={disabled}
            error={error}
            handleChange={this._handleChange}
            hint="MM"
            label=""
            maxLength={2}
            name="month"
            onBlur={this._handleBlur}
            onFocus={this._handleFocus}
            showTextBelow={false}
            text={month}
            type="text"
          />
          <TextInput
            className={classnames('yyyy', styles.yyyy, {
              [styles.textInputInvalid]: !valid,
            })}
            disabled={disabled}
            error={error}
            handleChange={this._handleChange}
            hint="YYYY"
            label=""
            maxLength={4}
            name="year"
            onBlur={this._handleBlur}
            onFocus={this._handleFocus}
            showTextBelow={false}
            text={year}
            type="text"
          />
          {icon
            ? this.createIcon(
              icons[icon],
              classnames(formStyles.inputIcon, focusIcon),
            )
            : null}
        </div>
        {note ? (
          <span className={classnames(formStyles.note, displayNote)}>
            {note}
          </span>
        ) : null}
        <span className={styles.error}>{error}</span>
      </div>
    )
  }
}

export default DateInput
