/* eslint-disable jsx-a11y/label-has-for */

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { RadioGroup, RadioButton } from 'react-toolbox/lib/radio'
import styles from './Radio.scss'

const Radio = (props) => {
  const {
    onChange,
    label,
    name,
    options,
    horizontal,
    helperText,
    noPadding,
    ...rest
  } = props

  const _handleChange = (value) => {
    onChange(name, value)
  }

  return (
    <div
      className={classnames(styles.base, {
        [styles.noPadding]: noPadding,
        [styles.horizontal]: horizontal,
      })}
    >
      {label && (
        <label className={styles.label} htmlFor={name}>
          {label}
        </label>
      )}
      <p className={styles.helperText}>{helperText}</p>
      <RadioGroup name={name} {...rest} onChange={_handleChange}>
        {options.map(({ label: labelString, value }) => (
          <RadioButton
            className={styles.radioButton}
            data-test-id={`radioInput-${name}-${value}`}
            key={value}
            label={labelString}
            theme={styles}
            value={value}
          />
        ))}
      </RadioGroup>
    </div>
  )
}

Radio.propTypes = {
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  horizontal: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  noPadding: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ).isRequired,
}

Radio.defaultProps = {
  disabled: false,
  helperText: null,
  horizontal: false,
  label: '',
  noPadding: false,
}

export default Radio
