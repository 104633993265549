import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import SuggestionItem from '../SuggestionItem'
import styles from '../../TagInput.scss'

class Suggestions extends Component {
  static propTypes = {
    handleClick: PropTypes.func.isRequired,
    selectedIndex: PropTypes.number.isRequired,
    show: PropTypes.bool,
    suggestions: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })).isRequired,
  }

  static defaultProps = {
    show: false,
  }

  _handleClick = (tag) => {
    const { handleClick } = this.props

    handleClick(tag)
  }

  suggestionList = () => {
    const { selectedIndex, suggestions, show } = this.props

    const items = suggestions.map((item, i) => {
      const active = i === selectedIndex
      return (
        <SuggestionItem
          active={active}
          handleClick={this._handleClick}
          key={item.value}
          label={item.label}
          subLabel={item.email}
          value={item.value}
        />
      )
    })
    // const min = minQueryLength || 2
    if (items.length === 0 || !show) {
      return null
    }

    return items
  }

  render() {
    const { show } = this.props
    const classes = {
      [styles.showing]: show,
    }

    return (
      <div className={classnames(styles.suggestions, classes)}>
        {this.suggestionList()}
      </div>
    )
  }
}

export default Suggestions
