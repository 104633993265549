import React, { Fragment, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { StickyContainer, Sticky } from 'react-sticky'
import LinesEllipsis from 'react-lines-ellipsis'
import Modal from 'components/Modal'
import Card from 'components/Card'
import CardList from 'components/CardList'
import TagList from 'components/TagList'
import TextArea from 'components/TextArea'
import FilterSelectInput from 'components/FilterSelectInput'
import {
  Heading, Checkbox, Button, Icons,
} from '@deloitte/gel-library'
import ReactMarkdown from 'react-markdown'
import { colorFromName } from 'utils/colours'
import { getIndustryImage } from 'utils/images'
import { sortByFunction } from 'utils/arrays'
import { getDataRequestUrlByPackage } from 'utils/api/file'
import IconCollection from 'icons/IconCollection'
import SolutionImageIcon from 'components/SolutionImageIcon'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import styles from './SolutionView.scss'

const inputTablesPropType = PropTypes.shape({
  description: PropTypes.string,
  displayName: PropTypes.string,
  displayOrder: PropTypes.number,
  name: PropTypes.string,
})

const propTypes = {
  goBack: PropTypes.func.isRequired,
  isDemoRequestSending: PropTypes.bool.isRequired,
  onSubmitDemoRequest: PropTypes.func.isRequired,
  solution: PropTypes.shape({
    analyses: PropTypes.arrayOf(PropTypes.shape({
      description: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
      displayOrder: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })).isRequired,
    catalogue: PropTypes.shape({
      caseStudies: PropTypes.arrayOf(PropTypes.shape({
        description: PropTypes.string.isRequired,
        industry: PropTypes.string,
        outcomes: PropTypes.arrayOf(PropTypes.shape({
          callout: PropTypes.string.isRequired,
          text: PropTypes.string.isRequired,
        })),
        title: PropTypes.string.isRequired,
      })),
      longDescriptionMd: PropTypes.string.isRequired,
    }),
    dataRecipes: PropTypes.arrayOf(PropTypes.shape({
      inputTables: PropTypes.arrayOf(
        PropTypes.shape({ meta: inputTablesPropType }),
      ),
      meta: PropTypes.shape({
        description: PropTypes.string,
        displayName: PropTypes.string,
      }),
      name: PropTypes.string,
    })),
    inputTables: PropTypes.arrayOf(inputTablesPropType).isRequired,
    meta: PropTypes.shape({
      color: PropTypes.string.isRequired,
      demoReportUrl: PropTypes.string,
      displayName: PropTypes.string.isRequired,
      isDemoSolution: PropTypes.bool.isRequired,
      shortDescription: PropTypes.string.isRequired,
      tags: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
    name: PropTypes.string.isRequired,
  }).isRequired,
}

const defaultEnquirySelections = {
  pricing: false,
  dataRequirements: false,
  other: false,
  demoReportUrl: null,
}

const SolutionAnalysisItem = ({ analysis, solutionName }) => (
  <Fragment>
    <div className={styles.analysisItemImage}>
      <img
        alt={`Analysis ${analysis.name}`}
        src={`api/client/packages/${solutionName}/analyses/${analysis.name}/image`}
      />
    </div>
    <div className={styles.analysisItemBody}>
      <strong>{analysis.displayName}</strong>
      <LinesEllipsis basedOn="letters" className={styles.ellipsis} text={analysis.description} />
    </div>
    <div />
  </Fragment>
)

SolutionAnalysisItem.propTypes = {
  analysis: PropTypes.shape({
    description: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    displayOrder: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  solutionName: PropTypes.string.isRequired,
}

const SolutionView = ({
  goBack, onSubmitDemoRequest, solution, isDemoRequestSending,
}) => {
  const {
    analyses,
    dataRecipes,
    meta: {
      shortDescription,
      displayName,
      tags,
      color,
      isDemoSolution,
      demoReportUrl,
    },
    name,
    catalogue: {
      caseStudies,
      longDescriptionMd,
    },
    inputTables,
  } = solution

  const standardDataRecipe = {
    value: 'standard',
    label: <span>Standardised template</span>,
    inputTables: [...inputTables],
  }

  const [showCarousel, setShowCarousel] = useState(false)
  const [showDemoReport, setShowDemoReport] = useState(false)
  const [analysisSelector, setAnalysisSelector] = useState(0)
  const [analysesExpanded, setAnalysisExpanded] = useState(false)
  const [arrangeDemoText, setArrangeDemoText] = useState('')
  const [enquiryCheckboxes, setEnquiryCheckboxes] = useState(defaultEnquirySelections)
  const [selectedDataRecipe, setSelectedDataRecipe] = useState(standardDataRecipe)

  const isExternal = useSelector(state => state.app.user.isExternal)

  const handleDemoSubmit = (enquirySelections, text) => {
    setEnquiryCheckboxes(defaultEnquirySelections)
    setArrangeDemoText('')
    onSubmitDemoRequest(enquirySelections, text)
  }

  const handleRecipeSelectChange = (dataRecipe) => {
    setSelectedDataRecipe(dataRecipe)
  }

  const dataRecipeOptions = [
    standardDataRecipe,
    ...dataRecipes
      .sort(sortByFunction(x => x.meta.displayName))
      .map((recipe) => {
        const newInputTables = [
          ...inputTables,
          ...recipe.inputTables.map(t => t.meta),
        ]

        return {
          value: recipe.name,
          label: (
            <>
              <span>{recipe.meta.displayName}</span>
              <span className={styles.recipeChip}>{recipe.meta.description}</span>
            </>
          ),
          inputTables: newInputTables,
        }
      }),
  ]

  const handleDataRequestForm = () => {
    const recipeName = selectedDataRecipe.value !== standardDataRecipe.value
      ? selectedDataRecipe.value
      : null
    const url = getDataRequestUrlByPackage(solution.name, recipeName, isExternal)
    window.open(url, '_blank')
  }

  const selectedAnalysis = analyses[analysisSelector]
  const solutionColour = colorFromName(color)
  const caseStudyImages = caseStudies.map(cs => getIndustryImage(cs.industry))
  const isStandardRecipeOnly = solution.dataRecipes.length === 0

  return (

    <div className={styles.base}>

      <div className={styles.bannerContainer} style={{ borderColor: solutionColour }}>

        <div className={styles.titleContainer}>
          <div className={styles.titleContent}>
            <a className={styles.backArrow} onClick={goBack}>
              <ArrowBackIcon />
            </a>

            <Heading level={7}>{displayName}</Heading>
            <Heading level={5}>{shortDescription}</Heading>
            <TagList className={styles.pill} style={{ backgroundColor: solutionColour }} tags={tags} />
            <div className={styles.solActionBtns}>
              <Button
                className={styles.viewAnalysesBtn}
                onClick={() => setShowCarousel(true)}
                startIcon={<IconCollection className={styles.collectionIcon} />}
                style={{ backgroundColor: solutionColour, marginRight: 20 }}
              >
                {`VIEW ${analyses.length} ANALYS${analyses.length > 1 ? 'ES' : 'IS'}`}
              </Button>
              {isDemoSolution && (
                <Button
                  mode="secondary"
                  onClick={() => setShowDemoReport(true)}
                  style={{ borderColor: solutionColour, color: solutionColour }}
                >
                  LAUNCH SAMPLE REPORT
                </Button>
              )}
            </div>
          </div>
        </div>

        <div className={styles.imagesContainer}>

          <SolutionImageIcon
            className={styles.imageWrapper}
            solution={solution}
          />
        </div>

      </div>

      <div className={styles.contentWrapper}>

        <div className="row">

          <div className="col-lg-8 col-md-12 col-ms-12">

            <div className={styles.contentColumn}>

              <div className={classnames(styles.about, styles[color])}>
                <ReactMarkdown source={longDescriptionMd} />
              </div>

              <Heading level={7}>Analytic content</Heading>

              <CardList
                contentExtractor={a => <SolutionAnalysisItem analysis={a} solutionName={name} />}
                data={analysesExpanded ? analyses : analyses.slice(0, 5)}
                itemClassName={styles.analysisCardItemContainer}
                keyExtractor={a => a.name}
                nToLoad={analysesExpanded ? 0 : analyses.length - 5}
                onClick={(item) => {
                  setAnalysisSelector(analyses.findIndex(i => i.name === item.name))
                  setShowCarousel(true)
                }}
                onLoadMore={() => setAnalysisExpanded(true)}
              />

              {inputTables.length > 0 && (
                <>
                  <hr />
                  <Heading level={7} style={{ marginBottom: '3rem' }}>Data requirements</Heading>
                  <p>Generate a data request form outlining the data requirements to run your analyses.</p>
                  <div className={styles.dataRecipe}>
                    <FilterSelectInput
                      handleChange={handleRecipeSelectChange}
                      hideDropdownDetails={isStandardRecipeOnly}
                      isDisabled={isStandardRecipeOnly}
                      options={dataRecipeOptions}
                      value={selectedDataRecipe}
                    />
                    <Button onClick={handleDataRequestForm}>
                      Generate
                    </Button>
                  </div>
                  <p>Data files and columns:</p>
                  <ul className={styles.bulletListWithHeadings}>
                    {selectedDataRecipe.inputTables.map((t, index) => (
                      <li key={t.name}>
                        <p className={styles.bulletHeading}>
                          {t.displayName}
                          {(index + 1) > standardDataRecipe.inputTables.length ? '*' : ''}
                        </p>
                        <p>{t.description}</p>
                      </li>
                    ))}
                  </ul>
                </>
              )}

              {caseStudies.length > 0 && (
                <>
                  <hr />
                  <Heading level={7}>Case study</Heading>
                </>
              )}

              {caseStudies.map((cs, index) => (
                <Card noPadding key={cs.name}>
                  <div className={styles.caseStudyCard}>
                    <div
                      className={styles.image}
                      style={{
                        backgroundImage: `url(${caseStudyImages[index]})`,
                      }}
                    />
                    <div className={styles.caseStudyContainer}>
                      <Heading level={7}>{cs.title}</Heading>
                      <div className={styles.caseStudyContent}>
                        <div className={styles.description}>
                          {cs.description}
                        </div>
                        <div className={styles.callouts}>
                          {cs.outcomes.map(o => (
                            <div className={styles.callout} key={o.callout}>
                              <p className={styles.calloutFigure} style={{ color: solutionColour }}>{o.callout}</p>
                              <p className={styles.calloutText}>{o.text}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              ))}

            </div>

          </div>

          <div className={classnames(styles.formColumn, 'col-lg-4 col-md-12 col-ms-12')}>
            <StickyContainer>
              <Sticky topOffset={-36}>

                {({ style }) => (
                  <div style={{ ...style, top: 36 }}>
                    <Card noPadding noShadow className={styles.contactFormContainer}>
                      <div className={styles.contactForm}>

                        <Heading level={6}>Arrange a demo</Heading>
                        <p>
                          Please use the contact form to arrange a demo of the
                          {` ${displayName} `}
                          solution.
                        </p>

                        <section>
                          <p>What’s your enquiry about?</p>
                          <Checkbox
                            checked={enquiryCheckboxes.pricing}
                            handleChange={() => setEnquiryCheckboxes({ ...enquiryCheckboxes, pricing: !enquiryCheckboxes.pricing })}
                            label="Pricing"
                            name="pricing-checkbox"
                          />
                          <Checkbox
                            checked={enquiryCheckboxes.dataRequirements}
                            handleChange={() => setEnquiryCheckboxes({ ...enquiryCheckboxes, dataRequirements: !enquiryCheckboxes.dataRequirements })}
                            label="Data requirements"
                            name="data-requirements-checkbox"
                          />
                          <Checkbox
                            checked={enquiryCheckboxes.other}
                            handleChange={() => setEnquiryCheckboxes({ ...enquiryCheckboxes, other: !enquiryCheckboxes.other })}
                            label="Other"
                            name="other-checkbox"
                          />
                        </section>

                        <section>
                          <p>Write a message (optional)</p>
                          <TextArea
                            className={styles.textInput}
                            maxLength={500}
                            onChange={setArrangeDemoText}
                            placeholder={`Hi, please can I arrange a live demo of the ${displayName} solution? `}
                            value={arrangeDemoText}
                          />
                        </section>

                        <Button
                          className={styles.formButton}
                          disabled={isDemoRequestSending}
                          onClick={() => handleDemoSubmit(enquiryCheckboxes, arrangeDemoText)}
                        >
                          {isDemoRequestSending ? 'Sending' : 'Arrange a demo'}
                        </Button>

                      </div>
                    </Card>
                  </div>

                )}

              </Sticky>
            </StickyContainer>

          </div>

        </div>

      </div>

      <Modal
        active={showDemoReport}
        className={styles.modal}
        onClose={() => setShowDemoReport(false)}
        show={showDemoReport}
      >
        <div className={styles.modalContainer}>

          <iframe
            className={styles.iframe}
            // height={768}
            src={demoReportUrl}
            // style={{
            //   height: 768,
            //   width: 1366,
            // }}
            title="Report"
            // width={1366}
          />

        </div>
      </Modal>

      <Modal
        active={showCarousel}
        className={styles.modal}
        onClose={() => setShowCarousel(false)}
        show={showCarousel}
      >
        <div className={styles.modalContainer}>

          <div className={styles.modalImages}>

            <div
              className={styles.modalChevron}
              onClick={
                () => {
                  if (analysisSelector === 0) {
                    setAnalysisSelector(analyses.length - 1)
                  } else {
                    setAnalysisSelector(analysisSelector - 1)
                  }
                }}
            >
              <Icons.IconChevronLeft height={30} width={30} />
            </div>

            <img
              alt={selectedAnalysis.displayName}
              src={`/api/client/packages/${solution.name}/analyses/${selectedAnalysis.name}/image`}
            />

            <div
              className={styles.modalChevron}
              onClick={
                () => {
                  if (analysisSelector === analyses.length - 1) {
                    setAnalysisSelector(0)
                  } else {
                    setAnalysisSelector(analysisSelector + 1)
                  }
                }}
            >
              <Icons.IconChevronRight height={30} width={30} />
            </div>

          </div>

          <div className={styles.modalSidebar}>
            <p>{`${analysisSelector + 1} of ${analyses.length}`}</p>
            <p><strong>{displayName}</strong></p>
            <Heading>{selectedAnalysis.displayName}</Heading>
            <p>{selectedAnalysis.description}</p>
          </div>

        </div>
      </Modal>

    </div>
  )
}

SolutionView.propTypes = propTypes

export default SolutionView
