import PropTypes from 'prop-types'

export const userPropType = PropTypes.shape({
  firstName: PropTypes.string,
  surname: PropTypes.string,
})

export const priorityPropType = PropTypes.shape({
  description: PropTypes.string,
  id: PropTypes.number,
})

export const statusPropType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
})

export const articlePropType = PropTypes.shape({
  description: PropTypes.string,
  displayOrder: PropTypes.number,
  id: PropTypes.number,
  name: PropTypes.string,
  section: PropTypes.string,
  topic: PropTypes.string,
})
