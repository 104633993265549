import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import IconChevronRight from '@material-ui/icons/ChevronRight'
import styles from './ExpandContainer.scss'

function ExpandContainer({
  children, expanded, setExpanded, heading, className,
}) {
  const tableRef = useRef(null)
  const wrapperRef = useRef(null)

  return (
    <div className={classNames([styles.base, expanded ? styles.expanded : null], className)}>
      <section className={styles.title}>
        <div
          className={classNames(styles.heading, styles.button, styles.expandButton, styles.headerBar)}
          onClick={() => setExpanded(!expanded)}
        >
          {heading}
          <IconChevronRight />
        </div>

      </section>
      <div className={styles.tableWrapper} ref={wrapperRef}>
        <div ref={tableRef}>
          {children }
        </div>
      </div>
    </div>

  )
}

ExpandContainer.propTypes = {
  children: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
  expanded: PropTypes.bool.isRequired,
  heading: PropTypes.object,
  setExpanded: PropTypes.func.isRequired,
}

ExpandContainer.defaultProps = {
  heading: null,
  className: null,
}
export default ExpandContainer

