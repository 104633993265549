import React from 'react'
import PropTypes from 'prop-types'
import deloitteLogo from 'images/deloitte-wordmark-logo.svg'
import styles from './Wordmark.scss'

const propTypes = {
  hideLogo: PropTypes.bool,
  wordmark: PropTypes.string.isRequired,
}

const defaultProps = {
  hideLogo: false,
}

function Wordmark({ wordmark, hideLogo }) {
  if (!wordmark.includes('InsightBox')) {
    return wordmark
  }

  const otherWordmarkWords = wordmark.replace('InsightBox', '')

  return (
    <div className={styles.insightBoxName}>
      {!hideLogo && <img alt="InsightBox" src={deloitteLogo} />}
      <span className={styles.highlight}>Insight</span>
      <span>
        Box
        <span className={styles.otherWords}>
          {otherWordmarkWords}
        </span>
      </span>
    </div>
  )
}

Wordmark.propTypes = propTypes
Wordmark.defaultProps = defaultProps

export default Wordmark
