import React from 'react'
import PropTypes from 'prop-types'
import { Dialog as ToolboxDialog } from 'react-toolbox/lib/dialog'

const theme = require('./theme.scss')

const Dialog = ({
  actions, active, children, className, onClose, title,
}) => {
  return (
    <ToolboxDialog
      actions={actions}
      active={active}
      className={className}
      onEscKeyDown={onClose}
      onOverlayClick={onClose}
      theme={theme}
      title={title}
    >
      {children}
    </ToolboxDialog>
  )
}

Dialog.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
  })),
  active: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
}

Dialog.defaultProps = {
  actions: null,
  active: false,
  children: null,
  className: null,
  title: null,
}

export default Dialog
