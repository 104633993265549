/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconSubscriptionBilling = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--subscriptionbilling',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 123.4 110"{...rest}><g fill="#58595B"><path className="path1" d="M29.8 45.9h34c.8 0 1.5-.7 1.5-1.5s-.7-1.5-1.5-1.5h-34c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5zM29.8 55.8h34c.8 0 1.5-.7 1.5-1.5s-.7-1.5-1.5-1.5h-34c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5zM52.2 62.8H29.8c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5h22.4c.8 0 1.5-.7 1.5-1.5s-.6-1.5-1.5-1.5z"/><path className="path2" d="M113.4 44.9c0-1.8-.7-3.6-2-4.9l-4.4-4.4c-1.3-1.3-3-2-4.9-2-1.8 0-3.6.7-4.9 2L83.7 49.2v-16c0-2.3-1.2-5.3-2.9-6.9L67.3 12.9c-1.6-1.6-4.6-2.9-6.9-2.9h-45c-3 0-5.5 2.5-5.5 5.5v79c0 3 2.5 5.5 5.5 5.5h62.7c3 0 5.5-2.5 5.5-5.5v-17l27.7-27.7c1.3-1.3 2.1-3 2.1-4.9zM66.5 83.7l-3.2-3.2 1.7-5.1 6.7 6.7-5.2 1.6zm8.9-3.6l-8.5-8.5 25.5-25.5 8.5 8.5-25.5 25.5zm-.3-52.4h-9.2v-9.2l9.2 9.2zm3.6 66.8c0 .3-.2.5-.5.5H15.5c-.3 0-.5-.2-.5-.5v-79c0-.3.2-.5.5-.5h45c.2 0 .3 0 .5.1v15.2c0 1.4 1.1 2.5 2.5 2.5h15.2c0 .2.1.3.1.5v21L63.4 69.5c-.6.6-1.2 1.6-1.5 2.4l-5.2 15.9c-.2.7 0 1.5.5 2 .4.4.9.6 1.4.6.2 0 .4 0 .6-.1l15.9-5.2c.8-.3 1.8-.9 2.4-1.5l1.1-1.1v12zM108.5 47l-4.8 4.8-8.5-8.5 4.8-4.8c1.1-1.1 3-1.1 4.1 0l4.4 4.4c.5.5.9 1.3.9 2.1 0 .7-.3 1.4-.9 2z"/></g></IconBase>
  )
}

IconSubscriptionBilling.propTypes = {
  className: PropTypes.string,
}

export default IconSubscriptionBilling
/* eslint-enable */
