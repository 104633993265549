import React from 'react'
import PropTypes from 'prop-types'
import Container from 'components/layout/Grid/Container'
import ProductBanner from 'components/ProductBanner'
import clientHomeImage from 'images/client-home.png'
import IconOpenInNew from '@material-ui/icons/OpenInNew'
import CollectionSolutionView from 'views/CollectionSolutionView'
import styles from './SolutionsView.scss'

function Solutions({
  solutions, collections, goToSolution, user: { isAuOffice },
}) {
  return (
    <>
      <Container noMargin>
        <ProductBanner
          backgroundImageUrl={clientHomeImage}
          blurb={(
            <>
              InsightBox™ is a data analytics app store for Deloitte practitioners.
              <br />
              Empowering you to quickly run high-quality, proven and ready-to-use
              <br />
              solutions without data specialists.
            </>
        )}
          className={styles.clientBanner}
          name={(
            <span>
              Find the best data solutions
              <br />
              for your engagement
            </span>
        )}
        />
      </Container>
      <Container className={styles.solutions}>
        {collections.map((c) => {
          const solnsInThisCollection = solutions.filter(s => s.collection === c.name)
          return (
            <CollectionSolutionView
              collection={c}
              goToSolution={goToSolution}
              solutions={solnsInThisCollection}
            />
          )
        })}
      </Container>
      {isAuOffice && (
        <Container className={styles.myaLink}>
          <p>
            Can&#39;t find the solution or analysis here,
            {' '}
            <a href="https://insightbox-landing.au.deloitte.com/#/tests" rel="noopener noreferrer" target="_blank">
              browse myAnalytics
              <IconOpenInNew fontSize="small" />
            </a>
          </p>
        </Container>
      )}
    </>
  )
}

Solutions.propTypes = {
  collections: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
  })).isRequired,
  goToSolution: PropTypes.func.isRequired,
  solutions: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
  })).isRequired,
  user: PropTypes.shape({
    isAuOffice: PropTypes.bool,
  }).isRequired,
}

export default Solutions

