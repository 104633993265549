import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './Status.scss'

function Status({ status }) {
  return (
    <span className={classNames(styles.base, styles[`status-${status.id}`])}>
      {status.description}
    </span>
  )
}

Status.propTypes = {
  status: PropTypes.shape({
    description: PropTypes.string,
    id: PropTypes.number,
  }),
}

Status.defaultProps = {
  status: {
    description: 'Not started',
    id: 0,
  },
}

export default Status

