/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconDisclosureDown = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--ic_expandmore',
    className,
  )

  return (
    <IconBase className={classes} viewBox="0 0 16 10" xmlns="http://www.w3.org/2000/svg"{...rest}><title>Disclosure triangle pointing down</title><g fill="none" fillRule="evenodd"><path className="path1" fill="#FFF" fillRule="nonzero" d="M13.738.738L8 6.462 2.262.738.5 2.5 8 10l7.5-7.5z"/><path className="path2" d="M-7-10h30v30H-7z"/></g></IconBase>
  )
}

IconDisclosureDown.propTypes = {
  className: PropTypes.string,
}

export default IconDisclosureDown
/* eslint-enable */
