import React from 'react'
import PropTypes from 'prop-types'
import { getConfig } from 'utils/config'
import { Container } from 'components/layout/Grid/'
import styles from './HeaderError.scss'

const propTypes = {
  simple: PropTypes.bool,
  wordmark: PropTypes.string.isRequired,
}

const defaultProps = {
  simple: false,
}

const HeaderError = ({ simple, wordmark }) => {
  return (
    <div className={styles.base}>
      <Container className={styles.container}>
        <span className={styles.logo}>
          {simple ? wordmark : (
            <a
              href={getConfig().URL.HOME}
              rel="noopener noreferrer"
            >
              {wordmark}
            </a>
          )}
          {' '}
        </span>
      </Container>
    </div>
  )
}

HeaderError.propTypes = propTypes
HeaderError.defaultProps = defaultProps

export default HeaderError
