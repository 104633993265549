import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import LinkRenderer from 'components/LinkRenderer'
import styles from './NumberedList.scss'

const propTypes = {
  highlightColor: PropTypes.string,
  highlightNumberOnly: PropTypes.bool,
  listItems: PropTypes.arrayOf(PropTypes.shape({
    body: PropTypes.string,
    title: PropTypes.string,
  })).isRequired,
}

const defaultProps = {
  highlightColor: '$000000',
  highlightNumberOnly: false,
}

const NumberedList = ({ listItems, highlightColor, highlightNumberOnly }) => (
  <ol className={styles.base}>
    {listItems.map((item, index) => (
      <li key={item.title}>
        <div className={styles.number} style={{ backgroundColor: highlightColor }}>
          {index + 1}
        </div>
        <p className={styles.title} style={{ color: highlightNumberOnly ? 'black' : highlightColor }}>{item.title}</p>
        <ReactMarkdown renderers={{ link: LinkRenderer }} source={item.body} />
      </li>
    ))}
  </ol>
)

NumberedList.propTypes = propTypes
NumberedList.defaultProps = defaultProps

export default NumberedList
