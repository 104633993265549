/* eslint-disable react/no-string-refs */
/* eslint-disable react/forbid-prop-types */

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import PopoverWrapper from 'components/PopoverWrapper'
import SearchFilterInput from 'components/SearchFilterInput'
import { CSSTransition } from 'react-transition-group'
import styles from './SearchFilter.scss'
import transitionStyles from './InputTransition.scss'

class SearchFilter extends PopoverWrapper {
  static propTypes = {
    buttonClassName: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    text: PropTypes.string.isRequired,
  }

  static defaultProps = {
    disabled: false,
    options: [],
  }

  _handleSubmit = (value) => {
    this.props.handleSubmit(value)
    this.closePopover()
  }

  _handleButtonClick = () => {
    if (this.props.disabled) { return }
    this.togglePopoverDisplay()
  }

  render() {
    const {
      buttonClassName, className, disabled, options, text,
    } = this.props
    return (
      <div className={classnames(styles.base, className, { [styles.disabled]: disabled })}>
        <button
          className={classnames(styles.button, buttonClassName)}
          onClick={this._handleButtonClick}
          type="button"
        >
          {text}
        </button>
        <CSSTransition
          className={styles.initialState}
          classNames={{ ...transitionStyles }}
          in={this.state.showPopover}
          onEnter={this.bindDocumentClick}
          onExit={this.unbindDocumentClick}
          timeout={300}
        >
          <SearchFilterInput
            focus={this.state.showPopover}
            handleSubmit={this._handleSubmit}
            id="inbox-filter"
            ref="popover"
            suggestions={options}
          />
        </CSSTransition>
      </div>
    )
  }
}
export default SearchFilter
