import * as ActionTypes from 'actions/client'
import { objectFromArray } from 'utils/objects'
import { ACTION_HUB_SUBMITTED_TASK_LABELS } from 'actionHub/redux/state'
import { jobInitialState } from './job'

const initialState = {
  _fetchingJobSummary: false,
  _fetchedJobSummary: false,
  _fetchingJobs: false,
  _fetchedJobs: false,
  _fetchingReportSummary: false,
  _fetchedReportSummary: false,
  _fetchingCustomReports: false,
  _fetchedCustomReports: false,
  _fetchingCustomReport: false,
  _fetchedCustomReport: false,
  _fetchingActions: false,
  _fetchedActions: false,
  jobsByPackage: [],
  jobIds: {},
  jobs: {},
  reportGroupIds: [],
  reportGroups: {},
  customReportCount: 0,
}

export default function client(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.FETCHING_CLIENT_JOB_SUMMARY:
      return {
        ...state,
        _fetchingJobSummary: true,
        _fetchingReportSummary: true,
      }
    case ActionTypes.FETCHED_CLIENT_JOB_SUMMARY:
      return {
        ...state,
        _fetchingJobSummary: false,
        _fetchedJobSummary: true,
        jobsByPackage: action.jobs,
      }
    case ActionTypes.FETCHING_CLIENT_JOBS:
      return {
        ...state,
        _fetchingJobs: true,
      }
    case ActionTypes.FETCHED_CLIENT_JOBS:
      return {
        ...state,
        _fetchingJobs: false,
        _fetchedJobs: true,
        jobs: {
          ...state.jobs,
          ...objectFromArray(
            action.jobs.map(x => ({
              ...x,
              _fetchedJob: true,
            })),
            'jobId',
          ),
        },
        jobIds: {
          ...state.jobIds,
          [action.packageId]: action.jobs.map(x => x.jobId),
        },
      }
    case ActionTypes.FETCHING_CLIENT_JOB:
      return {
        ...state,
        jobs: {
          ...state.jobs,
          [action.jobId]: state.jobs[action.jobId]
            ? {
              ...state.jobs[action.jobId],
              _fetchingJob: true,
            } : {
              ...jobInitialState,
              _fetchingJob: true,
            },
        },
      }
    case ActionTypes.FETCHED_CLIENT_JOB:
      return {
        ...state,
        jobs: {
          ...state.jobs,
          [action.jobId]: {
            ...state.jobs[action.jobId],
            ...action.job,
            members: action.job.jobMembers,
            _fetchedJob: true,
            _fetchingJob: false,
          },
        },
      }
    case ActionTypes.UPDATED_CLIENT_JOB:
      return {
        ...state,
        jobs: {
          ...state.jobs,
          [action.jobId]: {
            ...state.jobs[action.jobId],
            ...action.job,
            _fetchingJob: false,
          },
        },
      }
    case ActionTypes.FETCHED_CLIENT_CUSTOM_REPORT_SUMMARY:
      return {
        ...state,
        _fetchingReportSummary: false,
        _fetchedReportSummary: true,
        customReportCount: action.count,
      }
    case ActionTypes.FETCHING_CLIENT_CUSTOM_REPORTS:
      return {
        ...state,
        _fetchingCustomReports: true,
      }
    case ActionTypes.FETCHED_CLIENT_CUSTOM_REPORTS:
      return {
        ...state,
        _fetchingCustomReports: false,
        _fetchedCustomReports: true,
        _fetchedCustomReport: true,
        reportGroupIds: action.groups.map(g => g.id),
        reportGroups: {
          ...objectFromArray(action.groups, 'id'),
        },
      }
    case ActionTypes.FETCHING_CLIENT_CUSTOM_REPORT:
      return {
        ...state,
        _fetchingCustomReport: true,
      }
    case ActionTypes.FETCHED_CLIENT_CUSTOM_REPORT:
      return {
        ...state,
        _fetchingCustomReport: false,
        _fetchedCustomReport: true,
        reportGroupIds: state.reportGroupIds.indexOf(action.group.id) < 0
          ? [
            ...state.reportGroupIds,
            action.group.id,
          ]
          : [
            ...state.reportGroupIds,
          ],
        reportGroups: {
          ...state.reportGroups,
          [action.group.id]: action.group,
        },
      }
    case ActionTypes.FETCHING_CLIENT_ACTIONS:
      return {
        ...state,
        _fetchingActions: true,
      }
    case ActionTypes.FETCHED_CLIENT_ACTIONS:
      return {
        ...state,
        _fetchingActions: false,
        _fetchedActions: true,
        jobs: {
          ...state.jobs,
          [action.jobId]: {
            ...state.jobs[action.jobId],
            _isFetchedActions: true,
            _fetchingActionsError: '',
            actions: {
              ...action.actions,
              _hasFetchedAllActions: true,
            },
          },
        },
      }
    case ActionTypes.FETCHING_CLIENT_DOWNLOADS:
      return {
        ...state,
        jobs: {
          ...state.jobs,
          [action.jobId]: {
            ...state.jobs[action.jobId],
            _isFetchingDownloads: true,
            _isFetchedDownloads: false,
          },
        },
      }
    case ActionTypes.FETCHED_CLIENT_DOWNLOADS:
      return {
        ...state,
        jobs: {
          ...state.jobs,
          [action.jobId]: {
            ...state.jobs[action.jobId],
            _isFetchingDownloads: false,
            _isFetchedDownloads: true,
            downloads: action.downloads,
          },
        },
      }
    case ActionTypes.FETCHED_CLIENT_DOWNLOAD_UPDATE:
      return {
        ...state,
        jobs: {
          ...state.jobs,
          [action.jobId]: {
            ...state.jobs[action.jobId],
            downloads: state.jobs[action.jobId].downloads.map((d) => {
              return d.name === action.download.name
                ? { ...d, ...action.download }
                : d
            }),
          },
        },
      }
    case ACTION_HUB_SUBMITTED_TASK_LABELS:
      return {
        ...state,
        jobs: {
          ...state.jobs,
          [action.jobId]: {
            ...state.jobs[action.jobId],
            downloads: (state.jobs[action.jobId]?.downloads || [])
              .map(dl => (dl.type === 'action'
                ? { ...dl, status: 'Not started', statusId: 0 }
                : dl)),
          },
        },
      }
    default:
      return state
  }
}
