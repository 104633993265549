/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconPayrollTax = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--payrolltax',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"{...rest}><title>Fill 1</title><g fill="#58595B"><path className="path1" d="M24 16.3c0-.3-.1-.6-.4-.9l-7.2-7.2c-.2-.2-.5-.4-.9-.4-.3 0-.6.1-.9.4L2 21H.7c-.3 0-.6.3-.6.6s.3.6.6.6h20.7c.3 0 .6-.3.6-.6s-.3-.6-.6-.6h-1.6l3.8-3.8c.3-.2.4-.5.4-.9zm-4.1 1L16.1 21H15v-.1l-.2-.2.1-.1c.3-.3.4-.6.4-1s-.1-.7-.4-1c-.3-.3-.6-.4-1-.4s-.7.1-1 .4l-.6.6c-.2.2-.6.2-.8 0-.1-.1-.2-.2-.2-.4 0-.1.1-.3.2-.4l.6-.6c.2-.2.4-.2.6-.1.2.1.5 0 .6-.2.1-.2 0-.5-.2-.6-.5-.2-1.2-.1-1.6.3l-.1.1-.4-.3c-.1-.1-.3-.1-.5 0-.1.1-.1.3 0 .5l.2.2-.1.1c-.3.3-.4.6-.4 1s.1.7.4 1c.3.3.6.4 1 .4s.7-.1 1-.4l.6-.6c.2-.2.6-.2.8 0 .1.1.2.2.2.4 0 .1-.1.3-.2.4l-.6.6c-.2.2-.4.2-.6.1-.2-.1-.5 0-.6.2v.1H5.7l8.9-8.9c.6.1 1.3.1 1.9 0l3.3 3.3c-.1.6-.1 1.3.1 1.9zm3-.9L18.3 21h-1.2l3.4-3.4c.1-.1.1-.2.1-.3-.2-.6-.2-1.3 0-1.9 0-.1 0-.2-.1-.3l-3.6-3.6c-.1-.1-.2-.1-.3-.1-.6.2-1.3.2-1.9 0-.1 0-.2 0-.3.1L5 20.8c-.1.1-.1.1-.1.2H3.6L15.5 9.1c.1-.1.1-.1.2 0l7.2 7.2v.1zM.6 8.6H3v2.9c0 .3.3.6.6.6h5.5c.3 0 .6-.3.6-.6V8.6h2.4c.2 0 .4-.1.5-.3.1-.2 0-.5-.1-.6L6.6 2c-.1-.1-.2-.1-.4-.1s-.3 0-.4.1L.2 7.7c-.2.1-.2.4-.1.6 0 .2.2.3.5.3zm5.6-5.4l4.3 4.3H8.9c-.3 0-.6.3-.6.6V11H4.1V8.1c0-.3-.3-.6-.6-.6H1.9l4.3-4.3z"/></g></IconBase>
  )
}

IconPayrollTax.propTypes = {
  className: PropTypes.string,
}

export default IconPayrollTax
/* eslint-enable */
