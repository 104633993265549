/* Generated by ./script/iconify.js */
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import IconBase from './IconBase'

const IconSimpleCheck = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--ic_check_black_24pxcopy',
    className,
  )

  return (
    <IconBase className={classes} viewBox="0 0 27 21" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <title>C340AE26-C8BF-4BB3-B20A-5ADF95DEA0F3</title>
      <g fill="none" fillRule="evenodd">
        <path className="path1" d="M-5-8h36v36H-5z" />
        <path
          className="path2"
          d="M8.5 16.255L2.245 10l-2.13 2.115L8.5 20.5l18-18L24.385.385z"
          fill="#26890d"
          fillRule="nonzero"
        />
      </g>
    </IconBase>
  )
}

IconSimpleCheck.propTypes = {
  className: PropTypes.string,
}

IconSimpleCheck.defaultProps = {
  className: null,
}

export default IconSimpleCheck
