import { SelectInput } from '@deloitte/gel-library'
import Radio from 'components/Form/components/Radio'

import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ).isRequired,
}

function SelectRadioSwitchInput({ options, onChange, ...props }) {
  if (options.length > 5) {
    return (
      <SelectInput
        onChange={(fieldParam, value) => onChange(fieldParam, value.value)}
        options={options}
        {...props}
      />
    )
  }

  return <Radio onChange={onChange} options={options} {...props} />
}

SelectRadioSwitchInput.propTypes = propTypes

export default SelectRadioSwitchInput
