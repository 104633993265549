/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconAppLedger = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--appledger',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 68"{...rest}><path className="path1" fill="#9AA2AB" d="M58.1 68H9.9C4.4 68 0 63.6 0 58.1V9.9C0 4.4 4.4 0 9.9 0h48.2C63.6 0 68 4.4 68 9.9v48.2c0 5.5-4.4 9.9-9.9 9.9"/><path className="path2" fill="#8A939D" d="M0 9.9V58c0 5.5 4.4 9.9 9.9 9.9h48.2L9.9 0C4.4 0 0 4.4 0 9.9"/><path className="path3" fill="#FFF" d="M48.2 21.7l-5.6-5.6c-.7-.7-1.9-1.1-2.9-1.1H20.8c-1.3 0-2.1.9-2.1 2.2v33.4c0 1.3.9 2.5 2.1 2.5h26.3c1.3 0 2.2-1.2 2.2-2.5V24.7c0-1-.4-2.3-1.1-3zM31.4 42h-4.6c-.3 0-.6-.3-.6-.6 0-.4.3-.6.6-.6h4.6c.3 0 .6.3.6.6.1.3-.2.6-.6.6zm0-4.2h-4.6c-.3 0-.6-.3-.6-.6 0-.4.3-.6.6-.6h4.6c.3 0 .6.3.6.6.1.3-.2.6-.6.6zm7.9 8.5v.6c0 .5-.4.8-.8.8-.5 0-.8-.4-.8-.8v-.6c-1.3 0-2-.7-2.4-1.6-.2-.4 0-.9.4-1.1.4-.2.9 0 1.1.5.1.3.5.6.8.6h1.6c.5 0 .9-.3.9-.8s-.4-.8-.9-.8h-1.6c-1.4 0-2.5-1.2-2.5-2.6 0-1.4.9-2.6 2.6-2.6V37c0-.5.4-.8.8-.8.5 0 .8.4.8.8v.6c.8 0 1.9.7 2.3 1.7.2.4-.1.9-.5 1.1-.4.2-.9 0-1.1-.4-.1-.3-.5-.5-.8-.5h-1.6c-.5 0-.9.3-.9.8s.4.8.9.8h1.6c1.4 0 2.6 1.1 2.6 2.5-.1 1.5-1.3 2.6-2.5 2.7zm1.8-13.1H26.8c-.3 0-.6-.3-.6-.6 0-.4.3-.6.6-.6h14.3c.3 0 .6.3.6.6s-.2.6-.6.6zm0-3.8H26.8c-.3 0-.6-.3-.6-.6s.3-.6.6-.6h14.3c.3 0 .6.3.6.6s-.2.6-.6.6zm1.1-7.2v-3.6l3.6 3.6h-3.6z"/></IconBase>
  )
}

IconAppLedger.propTypes = {
  className: PropTypes.string,
}

export default IconAppLedger
/* eslint-enable */
