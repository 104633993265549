import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import constants from 'constants'
import {
  formInit, formUpdate, formError, formReset, formSchemaUpdateAdd, formSchemaUpdateDelete,
} from 'actions/forms'
import OverlayModal from 'components/OverlayModal'
import { modalCreate, modalHide } from 'actions/modals'
import {
  TextInput, Heading, Button,
} from '@deloitte/gel-library'
import { createHostedReport, editHostedReport } from 'actions/hostedReports'
import SearchInput from 'components/SearchInput'
import { clearEngagementCodes, getEngagementCodeByQuery } from 'actions/engagementCodes'
import { useDispatch, useSelector } from 'react-redux'
import { isNullOrWhiteSpace } from 'utils/strings'
import IconInfo from 'icons/IconInfo'
import Messagebar from 'components/Messagebar'
import TickList from 'components/TickList'
import SubmitButton from 'components/SubmitButton'
import { hot } from 'react-hot-loader/root'
import HostedReportForm from 'components/HostedReportForm/HostedReportForm'
import AddIcon from '@material-ui/icons/Add'
import styles from './HostedReportModal.scss'

const FIELD_NAME = 'name'
const FIELD_DESCRIPTION = 'description'
const FIELD_ENGAGEMENT = 'engagementCode'
const FIELD_REPORT_NAME = 'report-name'
const FIELD_REPORT_DESCRIPTION = 'report-description'
const FIELD_REPORT_URL = 'report-url'

const FORM_SCHEMA = {
  formFields: [
    { name: FIELD_NAME },
    { name: FIELD_DESCRIPTION },
    { name: FIELD_ENGAGEMENT },
    { name: FIELD_REPORT_NAME },
    { name: FIELD_REPORT_DESCRIPTION },
    { name: FIELD_REPORT_URL },
  ],
  data: {

  },
}

const FIELDS_EDITED = {
  [FIELD_NAME]: false,
  [FIELD_DESCRIPTION]: false,
  [FIELD_ENGAGEMENT]: false,
  [FIELD_REPORT_NAME]: false,
  [FIELD_REPORT_DESCRIPTION]: false,
  [FIELD_REPORT_URL]: false,
}

const checklistItems = [
  {
    title: 'Add users to Tableau server',
    body: 'Before adding the report, ensure all users who will view the report have been granted access to the relevant dashboards on Tableau Server or Power BI.',
  },
  {
    title: 'Add the report',
    body: 'Once added to InsightBox™, the report will be visible to the manager and partner of the selected engagement. You can also add additional internal users.',
  },
  {
    title: 'Review content',
    body: 'Ensure all descriptions and report names are appropriate and descriptive for clients. If a job is already shared with clients, any edits will be visible immediately.',
  },
  {
    title: 'Share with client',
    body: 'The engagement manager or partner can invite new or existing client users to view the report.',
  },

]

const propTypes = {
  group: PropTypes.shape({
    description: PropTypes.string,
    engagement: PropTypes.object,
    id: PropTypes.string,
    name: PropTypes.string,
    reports: PropTypes.arrayOf(PropTypes.shape({
      description: PropTypes.string,
      id: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string,
    })),
  }),
  mode: PropTypes.oneOf(['create', 'edit']).isRequired,
}

const defaultProps = {
  group: null,
}

const HostedReportModal = (props) => {
  const { mode, group } = props

  const dispatch = useDispatch()

  const {
    forms,
    modals,
    engagementCodes,
    hostedReports: { _isCreating, _isEditing },
  } = useSelector(state => state)

  const modalId = mode === 'create'
    ? constants.MODAL_CREATE_HOSTED_REPORT
    : constants.MODAL_EDIT_HOSTED_REPORT

  const formId = mode === 'create'
    ? constants.FORM_CREATE_HOSTED_REPORT
    : constants.FORM_EDIT_HOSTED_REPORT
  const [newReports, setNewReports] = useState([])

  const [engagementCodeLabel, setEngagementCodeLabel] = useState('')
  const [clientName, setClientName] = useState('')
  const [searchTimeoutId, setTimeoutId] = useState(-1)
  const [editedFields, setEditedFields] = useState(FIELDS_EDITED)
  const [formSchema, setFormSchema] = useState(FORM_SCHEMA)
  const [newReportIndex, setNewReportIndex] = useState(0)
  const [openLastAccordion, setOpenLastAccordion] = useState(false)
  const edited = Object.keys(editedFields).filter(f => editedFields[f])
  const options = engagementCodes && engagementCodes.options ? engagementCodes.options : []
  const form = forms[formId]
  const modal = modals[modalId]
  const [reports, setReports] = useState([])
  const updateSchema = () => {
    const updatedNewReportIndex = newReportIndex + 1
    const newReportName = `report-name-${updatedNewReportIndex}`
    const newReportUrl = `report-url-${updatedNewReportIndex}`
    const newReportDescription = `report-description-${updatedNewReportIndex}`
    const additionalFormSchema = {
      formFields: [
        { name: newReportName },
        { name: newReportDescription },
        { name: newReportUrl }],
      data: {

      },
    }
    setFormSchema({
      ...formSchema,
      formFields: [
        ...formSchema.formFields,
        ...additionalFormSchema.formFields,
      ]
      ,
    })
    dispatch(formSchemaUpdateAdd(formId, additionalFormSchema))
    setReports([...reports, { name: newReportName, url: newReportUrl, description: newReportDescription }])
    if (mode === 'edit') {
      setNewReports([...newReports, { name: newReportName, url: newReportUrl, description: newReportDescription }])
      setOpenLastAccordion(true)
    }
    setNewReportIndex(updatedNewReportIndex)
  }

  const onDelete = (report) => {
    setReports(reports.filter(fr => fr.name !== report.name))
    dispatch(formSchemaUpdateDelete(formId, Object.values(report)))
    const currentFormFields = formSchema.formFields.filter(ff => !Object.values(report).includes(ff.name))
    setFormSchema({
      ...formSchema,
      formFields: currentFormFields
      ,
    })
    setOpenLastAccordion(false)
  }

  const updateFormValue = (field, value) => {
    if (!editedFields[field]) {
      setEditedFields(state => ({
        ...state,
        [field]: true,
      }))
    }
    dispatch(formUpdate(formId, { field, data: { error: null, value } }))
  }

  useEffect(() => {
    let currFormSchema = { ...FORM_SCHEMA }
    if (mode === 'edit') {
      const existingReports = []
      const formFields = [
        { name: FIELD_NAME },
        { name: FIELD_DESCRIPTION },
        { name: FIELD_ENGAGEMENT },
      ]

      let data = {
        [FIELD_NAME]: group.name,
        [FIELD_DESCRIPTION]: group.description,
        [FIELD_ENGAGEMENT]: group.engagement.code,
      }

      group.reports.forEach((report, index) => {
        let reportName = FIELD_REPORT_NAME
        let reportUrl = FIELD_REPORT_URL
        let reportDescription = FIELD_REPORT_DESCRIPTION
        if (index !== 0) {
          reportName = `${reportName}-${index}`
          reportUrl = `${reportUrl}-${index}`
          reportDescription = `${reportDescription}-${index}`
        }

        const reportId = report.id
        formFields.push({ name: reportName })
        formFields.push({ name: reportUrl })
        formFields.push({ name: reportDescription })

        data = {
          ...data,
          [reportName]: report.name,
          [reportUrl]: report.url,
          [reportDescription]: report.description,
        }
        setEditedFields(state => ({
          ...state,
          [reportName]: true,
          [reportUrl]: true,
          [reportDescription]: true,
        }))
        existingReports.push({
          name: reportName, url: reportUrl, description: reportDescription, id: reportId,
        })
      })

      setReports(existingReports)
      setNewReportIndex(existingReports.length)

      currFormSchema = {
        formFields,
        data,
      }
      setEngagementCodeLabel(`${group.engagement.code} - ${group.engagement.name}`)
      setClientName(group.engagement.clientName)
    } else if (mode === 'create') {
      setReports([{ name: FIELD_REPORT_NAME, url: FIELD_REPORT_URL, description: FIELD_REPORT_DESCRIPTION }])
    }

    dispatch(formInit(formId, currFormSchema))

    return () => {
      dispatch(formReset(formId))
      dispatch(clearEngagementCodes())
    }
  }, [dispatch, formId, group, mode, modal])

  const validateRequiredField = (field, value) => {
    if (isNullOrWhiteSpace(value)) {
      dispatch(formError(formId, field, 'This field is required'))
      return false
    }
    return true
  }

  const validateUrl = (field, url) => {
    if (isNullOrWhiteSpace(url) || !url.startsWith('https')) {
      dispatch(formError(formId, field, 'Must be a valid report provider URL (https)'))
      return false
    }
    const containsAllowedDomain = constants.ALLOWED_REPORT_DOMAINS.some(d => url.includes(d))

    if (!containsAllowedDomain) {
      dispatch(formError(formId, field, 'Must be a valid report provider URL (https)'))
      return false
    }
    return true
  }

  const handleEngagementChange = (value) => {
    if (value.length >= 3) {
      clearTimeout(searchTimeoutId)
      setTimeoutId(setTimeout(() => dispatch(getEngagementCodeByQuery(value)), 400))
    } else {
      dispatch(clearEngagementCodes())
    }
    setEngagementCodeLabel(value)
  }

  const handleEngagementSelect = (option) => {
    setEngagementCodeLabel(option.label)
    updateFormValue(FIELD_ENGAGEMENT, option.value)
    setClientName(options.find(o => o.value === option.value)?.clientName || '')
    dispatch(clearEngagementCodes())
  }

  const validateFields = () => {
    const fields = formSchema.formFields
      .map(f => f.name)
    const requiredFields = fields
      .filter(n => !n.includes('report-url'))
    const urlReqFields = fields
      .filter(n => n.includes('report-url'))
    const validated = requiredFields.map(f => validateRequiredField(f, form[f].value))
    urlReqFields.map(rf => validated.push(validateUrl(rf, form[rf].value)))
    return validated.every(v => v)
  }

  const handleModalClose = () => {
    dispatch(clearEngagementCodes())
    dispatch(formReset(formId))
    setEngagementCodeLabel('')
    setNewReportIndex(0)
  }

  const handleSubmit = () => {
    const valid = validateFields()

    if (!valid) { return }

    const data = {
      name: editedFields[FIELD_NAME] ? form[FIELD_NAME].value : null,
      description: editedFields[FIELD_DESCRIPTION] ? form[FIELD_DESCRIPTION].value : null,
      engagementCode: editedFields[FIELD_ENGAGEMENT] ? form[FIELD_ENGAGEMENT].value : null,
      reports: reports.map(report => (editedFields[report.name] || editedFields[report.url] || editedFields[report.description]
        ? {
          name: editedFields[report.name] ? form[report.name].value : null,
          url: editedFields[report.url] ? form[report.url].value : null,
          description: editedFields[report.description] ? form[report.description].value : null,
          id: mode === 'edit' ? report.id : null,
        }
        : null)),
    }

    if (mode === 'create') {
      dispatch(createHostedReport(data))
    } else {
      dispatch(editHostedReport(group.id, data))
    }
    setOpenLastAccordion(false)
    handleModalClose()
  }

  if (!form) {
    return null
  }

  return (

    <OverlayModal
      base={10}
      disable={false}
      id={modalId}
      init={() => {
        dispatch(clearEngagementCodes())
        dispatch(modalCreate(modalId))
      }}
      modals={modals}
      onClose={() => {
        dispatch(modalHide(modalId))
        handleModalClose()
      }}
      push={4}
    >
      <div className={styles.base}>

        <Heading className={styles.title} level={5}>
          {mode === 'edit' ? 'Edit' : 'Add'}
          {' '}
          a custom report
        </Heading>

        <Messagebar className={styles.accessWarning} type="warn">
          <div>
            <strong>Have you given access on Tableau server?</strong>
            <p>
              Users will be unable to view the report content if they have not been
              granted access to the relevant dashboards on Tableau server. Ensure you have
              added all internal and client users before sharing this custom report.
            </p>
          </div>
        </Messagebar>

        <section>
          <SearchInput
            disabled={mode === 'edit'}
            error={form[FIELD_ENGAGEMENT].error}
            hasSearched={engagementCodes.hasFetched}
            isSearching={engagementCodes.isFetching}
            label="Engagement code"
            noResultsText="Engagement not found in your business unit"
            onBlur={() => {
              validateRequiredField(FIELD_ENGAGEMENT, engagementCodeLabel)
              dispatch(clearEngagementCodes())
            }}
            onChange={handleEngagementChange}
            onSelect={handleEngagementSelect}
            options={options}
            placeholder="Search engagement code or name"
            value={engagementCodeLabel}
          />

          {clientName.length > 0 && (
          <div className={styles.client}>
            {`Client: ${clientName}`}
          </div>
          )}
        </section>
        <div>
          <Heading className={styles.heading} level={7}>Collection details</Heading>
          <div className={styles.reportsContainer}>
            <section>
              <TextInput
                error={form[FIELD_NAME].error}
                label="Collection name"
                name="collection-name"
                onBlur={() => validateRequiredField(FIELD_NAME, form[FIELD_NAME].value)}
                onChange={value => updateFormValue(FIELD_NAME, value)}
                value={form[FIELD_NAME].value}
              />
            </section>

            <section>
              <TextInput
                error={form[FIELD_DESCRIPTION].error}
                label="Collection description"
                name="collection-description"
                onBlur={() => validateRequiredField(FIELD_DESCRIPTION, form[FIELD_DESCRIPTION].value)}
                onChange={value => updateFormValue(FIELD_DESCRIPTION, value)}
                value={form[FIELD_DESCRIPTION].value}
              />
            </section>
          </div>
        </div>

        <div className={styles.reportsSection}>
          <div className={styles.reportsCollection}>
            <Heading className={styles.reportsCollectionheading} level={7}>Reports in collection</Heading>
            {`${reports.length} report${reports.length > 1 ? 's' : ''}`}
          </div>
          <HostedReportForm
            form={form}
            mode={mode}
            onChange={(name, value) => updateFormValue(name, value)}
            onDelete={report => onDelete(report)}
            openLastAccordion={openLastAccordion}
            reports={reports}
            validateRequiredFieldOnBlur={(name, value) => validateRequiredField(name, value)}
            validateUrlOnBlur={(name, value) => validateUrl(name, value)}
          />
        </div>

        <Button className={styles.addReport} onClick={() => updateSchema()} startIcon={<AddIcon />} variant="text">
          <div className={styles.addReportButtonTxt}>ADD REPORT TO COLLECTION</div>
        </Button>

        <div className={styles.warning}>
          <div className={styles.warningHeader}>
            <div className={styles.warningIcon}>
              <IconInfo fill="#6d6d6d" height={20} width={20} />
            </div>
            <p className={styles.warningHeading}>Important information</p>
          </div>
          <div className={styles.warningBody}>
            <p>How do I share my report?</p>

            <TickList listItems={checklistItems} tickClassname={styles.tick} />
          </div>
        </div>

        <div className={styles.buttonContainer}>
          <SubmitButton
            completeText={mode === 'create' ? 'Added' : 'Updated'}
            disabled={mode === 'create' ? false : edited.length === 0}
            onClick={handleSubmit}
            submitting={mode === 'create' ? _isCreating : _isEditing}
            submittingText={mode === 'create' ? 'Adding collection' : 'Updating collection'}
          >
            {mode === 'create' ? 'Add collection' : 'Update collection'}
          </SubmitButton>
        </div>
      </div>

    </OverlayModal>

  )
}

HostedReportModal.propTypes = propTypes
HostedReportModal.defaultProps = defaultProps

export default hot(HostedReportModal)
