/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { includes, find } from 'lodash'
import classnames from 'classnames'
import moment from 'moment'
import {
  reset,
  createJob,
  updateJobParameters,
  checkTimeframe,
  updateJob as update,
} from 'actions/job'
import { getEngagementCodeByQuery, clearEngagementCodes } from 'actions/engagementCodes'
import { formInit, formUpdate, formReset } from 'actions/forms'
import SubmitButton from 'components/SubmitButton'
import TextInput from 'components/Form/components/TextInput'
import Radio from 'components/Form/components/Radio'
import Card from 'components/Card'
import Messagebar from 'components/Messagebar'
import GridSelect from 'components/GridSelect'
import AutoCompleteInput from 'components/Form/components/AutoCompleteInput'
import constants from 'constants'
import { DatePicker, Heading } from '@deloitte/gel-library'
import {
  getDefaultForm,
  requiredFields,
  handleError,
  handleErrors,
  validateAll,
  validateField,
  getSerializedFormState,
} from 'utils/validators'
import { getConfig } from 'utils/config'
import { sortByFunction } from 'utils/arrays'
import { SUPPORT_PAGES } from 'constants/supportPages'
import LinkRenderer from 'components/LinkRenderer'
import styles from './CreateReport.scss'

const noEngagementMatchString = 'Code not found in your business unit'
const engagementErrorString = 'Code not found in your business unit. Please enter a valid engagement code.'

const FORM_ID = constants.FORM_CREATE_JOB
const FORM_SCHEMA = {
  formFields: [
    {
      name: 'name',
      validators: [
        {
          check: 'required',
        },
      ],
      error: 'Enter a job name',
    },
    {
      name: 'engagementCode',
    },
    {
      name: 'clientName',
    },
    {
      name: 'collectionValue',
      validators: [
        {
          check: 'required',
        },
      ],
      error: 'Select your source',
    },
    {
      name: 'packageValue',
      validators: [
        {
          check: 'required',
        },
      ],
      error: 'Select your package',
    },
    {
      name: 'benchmarkingEnabled',
    },
    {
      name: 'dpFrom',
      validators: [
        {
          check: 'required',
        },
      ],
      error: 'Select a date',
    },
    {
      name: 'dpTo',
      validators: [
        {
          check: 'required',
        },
      ],
      error: 'Select a date',
    },
    {
      name: 'dpYE',
      validators: [
        {
          check: 'required',
        },
      ],
      error: 'Select a date',
    },
  ],
  data: {},
}

const INTERNATIONAL_FORM_FIELDS = [
  {
    name: 'customEngagementCode',
    validators: [
      { check: 'required' },
    ],
  },
  {
    name: 'customClientCode',
    validators: [
      { check: 'required' },
    ],
  },
]

const CLIENT_DETAILS_FORM_FIELDS = [
  {
    name: 'isClientJob',
    validators: [
      {
        check: 'required',
      },
    ],
  },
  {
    name: 'executionLimit',
    error: 'Please enter a number (1-100)',

  },
  {
    name: 'jobExpiryDate',
    error: 'Please pick an expiry date',

  },
]

const EXEUCTION_LIMIT_VALIDATORS = [
  {
    check: 'required',
  },
  {
    check: 'numberRange',
    options: {
      min: 1,
      max: 100,
    },
  },
]

const JOB_EXIPRY_VALIDATORS = [
  {
    check: 'required',
  },
]
class CreateReport extends React.Component {
  static propTypes = {
    app: PropTypes.shape({
      user: PropTypes.shape({
        isAuOffice: PropTypes.bool,
        permissions: PropTypes.object,
      }).isRequired,
    }).isRequired,
    clonedJobDetails: PropTypes.object,
    collections: PropTypes.arrayOf(PropTypes.object),
    dispatch: PropTypes.func.isRequired,
    editMode: PropTypes.bool,
    engagementCodes: PropTypes.object.isRequired,
    errorData: PropTypes.object,
    forms: PropTypes.object.isRequired,
    isCloned: PropTypes.bool,
    job: PropTypes.object.isRequired,
    packages: PropTypes.object.isRequired,
  }

  static defaultProps = {
    collections: [],
    errorData: null,
    editMode: null,
    isCloned: false,
    clonedJobDetails: null,
  }

  state = {
    lastQuery: '',
  }

  componentDidMount() {
    const {
      packages: { collections },
      dispatch,
      editMode,
      job,
      isCloned,
      clonedJobDetails,
      app: {
        user: { isAuOffice, permissions: { createClientJob } },
      },
    } = this.props

    const {
      dpFrom,
      dpTo,
      dpYE,
      benchmarkingEnabled,
      name,
      customClientCode,
      customEngagementCode,
      isClientJob,
      executionLimit,
      expiryDate,
    } = job

    if (isAuOffice) {
      const index = FORM_SCHEMA.formFields.findIndex(x => x.name === 'engagementCode')
      const engField = FORM_SCHEMA.formFields[index]
      FORM_SCHEMA.formFields[index] = {
        ...engField,
        validators: [
          {
            check: 'required',
          },
        ],
        error: 'Select an engagement code',
      }
    }

    if (isCloned) {
      FORM_SCHEMA.data = {
        benchmarkingEnabled: clonedJobDetails.benchmarkingEnabled,
        collectionValue: collections.find(x => x.packageIds.some(p => p === clonedJobDetails.packageId))?.name,
        packageValue: clonedJobDetails.packageId,
        isClientJob: 'No',
      }
      dispatch(reset())
    } else
    if (editMode) {
      FORM_SCHEMA.data = {
        benchmarkingEnabled,
        dpFrom: dpFrom && new Date(dpFrom),
        dpTo: dpTo && new Date(dpTo),
        dpYE: dpYE && new Date(dpYE),
        name,
        customClientCode,
        customEngagementCode,
        isClientJob: isClientJob ? 'Yes' : 'No',
        executionLimit,
        jobExpiryDate: expiryDate && new Date(expiryDate),
      }
    } else {
      FORM_SCHEMA.data = {
        benchmarkingEnabled: 'Yes',
      }
      if (createClientJob) {
        FORM_SCHEMA.data = {
          ...FORM_SCHEMA.data,
          isClientJob: 'No',
          hasExecutionLimit: 'No',
        }
      }
      dispatch(reset())
    }

    if (createClientJob) {
      FORM_SCHEMA.formFields = [
        ...FORM_SCHEMA.formFields,
        ...CLIENT_DETAILS_FORM_FIELDS,
      ]
    }

    dispatch(formInit(FORM_ID, { formFields: this.getFormFields(), data: FORM_SCHEMA.data }))
    dispatch(update({ setupErrors: [] }))
  }

  componentWillUnmount() {
    const { dispatch } = this.props
    FORM_SCHEMA.data = {}
    dispatch(formReset(FORM_ID))
    dispatch(clearEngagementCodes())
  }

  getFormFields = () => {
    const {
      app: {
        user: { isAuOffice, permissions: { createClientJob } },
      },
      forms,
    } = this.props

    const form = FORM_ID in forms ? forms[FORM_ID] : null

    let formFields = [...FORM_SCHEMA.formFields]

    if (createClientJob) {
      formFields = [
        ...formFields,
        ...CLIENT_DETAILS_FORM_FIELDS.map((f) => {
          if (form && form.isClientJob.value === 'Yes' && ['executionLimit', 'jobExpiryDate'].includes(f.name)) {
            return {
              ...f,
              validators: f.name === 'executionLimit'
                ? EXEUCTION_LIMIT_VALIDATORS
                : JOB_EXIPRY_VALIDATORS,
            }
          }
          return f
        }),
      ]
    }

    if (!isAuOffice) {
      formFields = [
        ...formFields,
        ...INTERNATIONAL_FORM_FIELDS,
      ]
    }

    return formFields
  }

  handleSuggestion = (name, value, viaClick = false) => {
    const { dispatch } = this.props
    const { lastQuery } = this.state

    if (value.label !== noEngagementMatchString) {
      this.handleChange(name, value)
      const val = typeof value === 'object' ? value.label : value
      if (!viaClick && val.length > 1 && lastQuery !== val) {
        this.setState(() => ({ lastQuery: val }))
        clearTimeout(this.engagementSearchTimeout)
        this.engagementSearchTimeout = setTimeout(() => {
          dispatch(getEngagementCodeByQuery(val))
        }, 500)
      }
    }
  }

  handleBlur = (name) => {
    const { engagementCodes, dispatch, forms } = this.props

    // Create state value obj
    const stateValue = {
      field: name,
      data: {
        focus: false,
      },
    }

    const form = forms[FORM_ID]
    const engagementCodeValue = form.engagementCode.value.value || form.engagementCode.value
    const codes = engagementCodes.options.map(code => code.value)
    if (!includes(codes, engagementCodeValue)) {
      stateValue.data.error = engagementErrorString
      stateValue.data.valid = false
    }

    dispatch(formUpdate(FORM_ID, stateValue))
  }

  handleFocus = (name) => {
    const { dispatch } = this.props

    // Create state value obj
    const stateValue = {
      field: name,
      data: {
        focus: true,
      },
    }

    dispatch(formUpdate(FORM_ID, stateValue))
  }

  handleChange = (field, value) => {
    const { dispatch } = this.props

    const stateValue = {
      data: {
        value,
        error: '',
        valid: true,
      },
      field,
    }

    dispatch(formUpdate(FORM_ID, stateValue))
    dispatch(checkTimeframe())

    if (field === 'engagementCode') {
      this.handleChange('clientName', value)
    }
  }

  handleValidationSelect = (field) => {
    const { forms } = this.props

    this.handleValidation(field, forms[FORM_ID][field].value)
  }

  handleValidationAutoInput = (field) => {
    const { dispatch, engagementCodes, forms } = this.props

    const form = forms[FORM_ID]
    const engagementCodeValue = form.engagementCode.value.value || form.engagementCode.value

    const errorObj = validateField(
      requiredFields(this.getFormFields()),
      field,
      engagementCodeValue,
      forms[FORM_ID],
    )

    if (!errorObj) {
      return
    }

    // If there is an error from the validation pass it to the error handler
    if (!errorObj.valid) {
      dispatch(formUpdate(FORM_ID, handleError(errorObj, field)))
    } else {
      const codes = engagementCodes.options.map(code => code.value)
      if (!includes(codes, engagementCodeValue)) {
        errorObj.error = engagementErrorString
        errorObj.valid = false
        dispatch(formUpdate(FORM_ID, handleError(errorObj, field)))
      } else {
        dispatch(
          formUpdate(FORM_ID, {
            data: {
              error: '',
              valid: true,
              focus: false,
            },
            field,
          }),
        )
      }
    }
  }

  handleValidation = (field, value) => {
    const { dispatch, forms } = this.props

    const errorObj = validateField(
      requiredFields(this.getFormFields()),
      field,
      value,
      forms[FORM_ID],
    )

    // If there is an error from the validation pass it to the error handler
    if (!errorObj.valid) {
      dispatch(formUpdate(FORM_ID, handleError(errorObj, field)))
    } else {
      this.handleChange(field, value)
    }
  }

  handleCreateJob = () => {
    const { forms, dispatch, isCloned } = this.props

    const reqfields = requiredFields(this.getFormFields())
    const errors = validateAll(reqfields, forms[FORM_ID])
    if (errors.length > 0) {
      handleErrors(errors).forEach(errorState => dispatch(formUpdate(FORM_ID, errorState)))
    } else {
      const form = forms[FORM_ID] || getDefaultForm(this.getFormFields())
      const data = getSerializedFormState(form)
      dispatch(createJob(data, isCloned))
    }
  }

  render() {
    const {
      app: { user: { isAuOffice, permissions: { createClientJob, customJobCreate } } },
      job,
      packages: { collections, ids, packages },
      forms,
      engagementCodes,
      dispatch,
      editMode,
      isCloned,
      clonedJobDetails,
    } = this.props
    const form = forms[FORM_ID] || getDefaultForm(this.getFormFields())
    const { URL_BASE } = getConfig() // @Jacob - potential issue?

    let matchingCollection = null
    if (isCloned) {
      matchingCollection = collections.find(x => x.packageIds.some(p => p === clonedJobDetails.packageId))
    } else
    if (editMode) {
      matchingCollection = collections.find(x => x.packageIds.some(p => p === job.packageId))
    }

    const selectedCollection = (editMode || isCloned)
      ? (matchingCollection && matchingCollection.name)
      : form.collectionValue.value

    const collectionOptions = collections.map((x) => {
      const numOfPackages = !customJobCreate
        ? x.packageIds.filter(pid => !packages[pid].meta.isDemoSolution)
          .filter(pid => !(packages[pid].analyses.length === 0 && packages[pid].inputTables.length === 0)).length
        : x.packageIds.filter(pid => !packages[pid].meta.isDemoSolution).length
      return {
        value: x.name,
        label: x.name,
        subLabel: `${numOfPackages} solutions`,
        description: null,
      }
    })

    const allPackageOptions = selectedCollection
      ? ids
        .map(id => packages[id])
        .filter(x => x.collection === selectedCollection && !x.meta.isDemoSolution)
        .sort(sortByFunction(x => x.meta.displayOrder))
        .map(x => ({
          value: x.name,
          label: x.meta.displayName,
          subLabel: `${x.analyses.length} tests`,
          description: x.meta.shortDescription || '',
        }))
      : []

    const packageOptions = !customJobCreate
      ? allPackageOptions
        .filter(x => !(packages[x.value].analyses.length === 0 && packages[x.value].inputTables.length === 0))
      : allPackageOptions

    const submitButtonProps = {
      submitting: job._isSendingJob,
      complete: job._isCompletedJob,
    }

    if (isCloned) {
      submitButtonProps.submittingText = 'COPYING'
      submitButtonProps.completeText = 'COPIED'
    } else if (editMode) {
      submitButtonProps.submittingText = 'SAVING'
      submitButtonProps.completeText = 'SAVED'
    } else {
      submitButtonProps.submittingText = 'CREATING'
      submitButtonProps.completeText = 'CREATED'
    }

    let client = null

    if (form.engagementCode.value) {
      client = find(engagementCodes.options, {
        value: form.engagementCode.value.value,
      })
    }

    const engagementCode = editMode
      ? `${job.engagement.code} - ${job.engagement.name}`
      : form.engagementCode.value.label || form.engagementCode.value

    const handleUpdateJob = () => {
      const data = getSerializedFormState(form)
      dispatch(updateJobParameters(data))
    }

    const today = new Date()
    const minExpiryDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1)
    const heading = () => {
      if (editMode) {
        return 'Update job'
      } if (isCloned) {
        return 'Copy job'
      }
      return 'Create job'
    }

    const getSolVal = isCloned ? clonedJobDetails.packageId : editMode && job.packageId

    return (
      <div className={styles.base}>
        <Heading className={styles.title} level={4}>
          {heading()}
        </Heading>
        {isCloned && (
          <Messagebar type="info">
            You are creating a new job based on a completed job named
            {' '}
            <i>{`'${clonedJobDetails.name}'`}</i>
            {' '}
            for
            {' '}
            <i>{`'${clonedJobDetails.clientName}'`}</i>
            . Previously uploaded data, data errors and reports will not be copied.
          </Messagebar>
        )}
        <Heading level={8}>Job details</Heading>
        <Card id="create_details">
          <div className="row">
            <div className="col-xs-12 col-sm-offset-2 col-sm-8 col-md-offset-3 col-md-6">
              {!editMode && (
                <Messagebar className={styles.azureWarning} type="info">
                  Your data will be hosted on the Australian Deloitte Azure Hub.
                  {' '}
                  <a
                    href={`/#${SUPPORT_PAGES.AZURE}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Learn more
                  </a>
                </Messagebar>
              )}

              <div className={styles.inputForm}>
                <div className={styles.inputElement}>
                  <TextInput
                    className={styles.textInput}
                    error={form.name.error}
                    handleChange={this.handleChange}
                    label="Job name"
                    maxLength={50}
                    name="name"
                    onBlur={this.handleValidation}
                    onFocus={this.handleFocus}
                    text={form.name.value}
                    type="text"
                  />
                </div>
                {getConfig().SANDBOX && (
                  <Messagebar type="info">
                    The selected engagement will not be charged.
                  </Messagebar>
                )}
                {isAuOffice && (
                  <div
                    className={classnames(styles.inputElement, {
                      [styles.inputElementError]: form.engagementCode.error,
                    })}
                  >
                    <AutoCompleteInput
                      hasFetching
                      disabled={editMode}
                      error={form.engagementCode.error}
                      fetching={engagementCodes.isFetching}
                      focus={form.engagementCode.focus}
                      handleChange={this.handleSuggestion}
                      label="Engagement code"
                      minQueryLength={2}
                      name="engagementCode"
                      noMatchedLabel={noEngagementMatchString}
                      onBlur={this.handleValidationAutoInput}
                      onFocus={this.handleFocus}
                      placeholder="Search engagement code or name"
                      suggestions={
                        form.engagementCode.value.length < 1
                          ? []
                          : engagementCodes.options
                      }
                      valid={form.engagementCode.valid}
                      value={engagementCode}
                    />
                    {client && client.clientName && (
                      <div
                        className={classnames(styles.clientSection, {
                          [styles.clientSestionDisable]: editMode,
                        })}
                      >
                        {`Client: ${client.clientName}`}
                        <br />
                        <div className={classnames(styles.clientSectionCharge)}>
                          Your engagement will not be charged until you receive your reports
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {!isAuOffice && (
                  <>
                    <TextInput
                      className={styles.textInput}
                      disabled={editMode}
                      error={form.customEngagementCode.error}
                      handleChange={this.handleChange}
                      label="Engagement code"
                      maxLength={50}
                      name="customEngagementCode"
                      onBlur={this.handleValidation}
                      onFocus={this.handleFocus}
                      text={form.customEngagementCode.value}
                      type="text"
                    />

                    <TextInput
                      className={styles.textInput}
                      disabled={editMode}
                      error={form.customClientCode.error}
                      handleChange={this.handleChange}
                      label="Client code"
                      maxLength={50}
                      name="customClientCode"
                      onBlur={this.handleValidation}
                      onFocus={this.handleFocus}
                      text={form.customClientCode.value}
                      type="text"
                    />
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-offset-2 col-sm-8 col-md-offset-3 col-md-6">
              <div>
                <div className={styles.formElement}>
                  {job.setupErrors.filter(error => error.type === 'warn')
                    .length > 0 && (
                    <Messagebar className={styles.messagebar} type="warn">
                      <ul>
                        {job.setupErrors
                          .filter(error => error.type === 'warn')
                          .map((error, index) => (
                            <li key={index}>{error.text}</li>
                          ))}
                      </ul>
                    </Messagebar>
                  )}
                  {job.setupErrors.filter(error => error.type === 'error')
                    .length > 0 && (
                    <Messagebar className={styles.messagebar} type="error">
                      <ul>
                        {job.setupErrors
                          .filter(error => error.type === 'error')
                          .map((error, index) => (
                            <li key={index}>{error.text}</li>
                          ))}
                      </ul>
                    </Messagebar>
                  )}
                  <label>Period of analysis</label>
                </div>
                <div className="row">
                  <div className="col-xs-6">
                    <div className={styles.datepicker}>
                      <DatePicker
                        data-test-id="dateInput-analysis-period-from"
                        label="From"
                        maxDate={new Date()}
                        maxDateMessage="Date should not be after 'To' date."
                        name="dpFrom"
                        onChange={(date) => { this.handleChange('dpFrom', date) }}
                        value={form.dpFrom.value === '' ? null : form.dpFrom.value}
                      />
                    </div>
                  </div>
                  <div className="col-xs-6">
                    <div className={styles.datepicker}>
                      <DatePicker
                        data-test-id="dateInput-analysis-period-to"
                        label="To"
                        maxDate={new Date()}
                        maxDateMessage="Date should not be after today"
                        minDate={form.dpFrom.value}
                        minDateMessage="Date should not be before 'From' date"
                        name="dpTo"
                        onChange={(date) => { this.handleChange('dpTo', date) }}
                        value={form.dpTo.value === '' ? null : form.dpTo.value}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.clientSection}>
                  {form.dpFrom.value && form.dpTo.value ? (
                    <span>
                      Client data from
                      {' '}
                      <strong>
                        {moment(form.dpFrom.value).format('DD MMM YYYY')}
                        {' – '}
                        {moment(form.dpTo.value).format('DD MMM YYYY')}
                      </strong>
                      {' '}
                      will be examined in the analysis.
                    </span>
                  ) : (
                    'Client data within this timeframe will be examined in the analysis.'
                  )}
                </div>
                <p className={styles.label}>Note: only visible to you and your team for reference purposes</p>

                <div className={styles.formElement}>
                  <label>Client year end date</label>
                </div>
                <div className="row">
                  <div className="col-xs-6">
                    <div className={styles.datepicker}>
                      <DatePicker
                        data-test-id="dateInput-client-year-end"
                        label="Year end"
                        minDate={form.dpFrom.value}
                        minDateMessage="Date should not be before 'From' date"
                        name="dpYE"
                        onChange={(date) => { this.handleChange('dpYE', date) }}
                        value={form.dpYE.value === '' ? null : form.dpYE.value}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>

        {createClientJob && form.isClientJob && (
          <Fragment>
            <Heading level={8}>Client subscription details</Heading>
            <Card id="create_subscription">
              <div className="row">
                <div className="col-xs-12 col-sm-offset-2 col-sm-8 col-md-offset-3 col-md-6">

                  <div className={styles.inputForm}>

                    <Messagebar type="info">
                      A client subscription job allows external users to directly upload and
                      process data. The subscription details should be based on a signed Engagement Letter.
                      {' '}
                      <LinkRenderer href={`${URL_BASE}#${SUPPORT_PAGES.SHARING_SUBSCRIPTION_JOBS}`}>Learn more.</LinkRenderer>
                    </Messagebar>

                    <div className={styles.inputElement}>
                      <Radio
                        horizontal
                        disabled={editMode}
                        label="Are you creating this job for a client on a subscription?"
                        name="isClientJob"
                        onChange={this.handleChange}
                        options={[
                          { label: 'Yes', value: 'Yes' },
                          { label: 'No', value: 'No' },
                        ]}
                        value={form.isClientJob.value}
                      />
                    </div>

                    {form.isClientJob.value === 'Yes' && (
                      <Fragment>

                        <div className={styles.inputElement}>
                          <TextInput
                            className={styles.textInput}
                            disabled={editMode}
                            error={form.executionLimit.error}
                            handleChange={this.handleChange}
                            label="Number of runs available for this subscription (max 100)"
                            name="executionLimit"
                            onBlur={this.handleValidation}
                            onFocus={this.handleFocus}
                            text={`${form.executionLimit.value}`}
                            type="text"
                          />
                        </div>

                        <div className={styles.inputElement}>
                          <label>Job expiry date</label>
                        </div>
                        <div className={styles.inputElement}>
                          <div className={styles.datepicker}>
                            <DatePicker
                              label="The job or its data cannot be accessed after this date"
                              minDate={minExpiryDate}
                              minDateMessage="Date should not be before today"
                              name="jobExpiryDate"
                              onChange={(date) => { this.handleChange('jobExpiryDate', date) }}
                              readOnly={editMode}
                              value={form.jobExpiryDate.value === '' ? null : form.jobExpiryDate.value}
                            />
                          </div>
                        </div>

                      </Fragment>
                    )}

                  </div>

                </div>
              </div>
            </Card>
          </Fragment>
        )}

        <Heading level={8}>Solution selection</Heading>

        <Card id="create_collection" style={{ padding: '2.4rem 5.4rem' }}>
          <p className={styles.sectionHeader}>Select a collection</p>
          {collectionOptions.length > 0 ? (
            <GridSelect
              disabled={editMode || isCloned}
              onChange={val => this.handleChange('collectionValue', val)}
              options={collectionOptions}
              rowLength={3}
              value={selectedCollection}
            />
          ) : (
            <div className={styles.gridPlaceholder}>Loading...</div>
          )}

          <p className={styles.sectionHeader}>Select a solution</p>
          {(form.collectionValue.value || editMode || isCloned) ? (
            <GridSelect
              className={styles.packageGrid}
              disabled={editMode || isCloned}
              onChange={val => this.handleChange('packageValue', val)}
              options={packageOptions}
              value={(editMode || isCloned) ? getSolVal : form.packageValue.value}
            />
          ) : (
            <div className={styles.gridPlaceholder}>
              Select a collection above to view solutions
            </div>
          )}

        </Card>

        <Heading level={8}>Authorise data for benchmarking use</Heading>
        <Card id="create_benchmarking">
          <div className="row">
            <div className="col-xs-12 col-sm-offset-2 col-sm-8 col-md-offset-3 col-md-6">
              <div>
                <Radio
                  horizontal
                  disabled={editMode}
                  helperText="I have confirmed with the engagement partner that the benchmarking clause has not been removed from the engagement letter."
                  label="Would you like to authorise the use of this data for benchmarking purposes?"
                  name="benchmarkingEnabled"
                  onChange={this.handleChange}
                  options={[
                    { label: 'Yes', value: 'Yes' },
                    { label: 'No', value: 'No' },
                  ]}
                  value={form.benchmarkingEnabled.value}
                />
              </div>
            </div>
          </div>
        </Card>
        <div className={styles.footer} id="create_submit">
          <SubmitButton
            {...submitButtonProps}
            disabled={
              job.setupErrors.filter(error => error.type === 'error').length > 0
            }
            onClick={editMode ? handleUpdateJob : this.handleCreateJob}
            type="primary"
          >
            {`${isCloned ? 'COPY JOB' : 'SAVE & CONTINUE'}` }
          </SubmitButton>
        </div>
      </div>
    )
  }
}

export default CreateReport
