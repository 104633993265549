import React from 'react'
import PropTypes from 'prop-types'
import { push } from 'connected-react-router'
import { Container } from 'components/layout/Grid/'
import classnames from 'classnames'
import IconSearch2 from 'icons/IconSearch2'
import IconReports from 'icons/IconReports'
import IconActions from 'icons/IconActions'
import Card from 'components/Card'
import { Button } from '@deloitte/gel-library'
import styles from './JobAnchorBanner.scss'

class JobAnchorBanner extends React.Component {
  static propTypes = {
    actionRef: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    hasDownloads: PropTypes.bool,
    job: PropTypes.shape({
      _fetchedJobDetailsId: PropTypes.string,
    }),
    reportRef: PropTypes.object,
    types: PropTypes.arrayOf(PropTypes.string),
    user: PropTypes.shape({
      isExternal: PropTypes.bool,
    }),
  }

  static defaultProps = {
    user: null,
    actionRef: null,
    hasDownloads: false,
    job: null,
    reportRef: null,
    types: [],
  }

  scrollSmoothHandler = (ref) => {
    return ref.current !== null ? ref.current.scrollIntoView({ behavior: 'smooth' }) : null
  }

  render() {
    const {
      actionRef, dispatch, hasDownloads, types, reportRef,
    } = this.props

    const hasTypes = types.some(t => t)

    const cardDetails = {
      title: {
        WARNINGS: 'Check data quality',
        REPORTS: 'View reports',
        ACTIONS: 'Review actions',
      },
      subtitle: {
        WARNINGS: 'Review and resolve data errors',
        REPORTS: 'Check out your visualised insights',
        ACTIONS: 'Access and document findings',
      },
      icons: {
        WARNINGS: <IconSearch2 className={classnames(styles.dataIcon)} />,
        REPORTS: <IconReports className={classnames(styles.dataIcon)} />,
        ACTIONS: <IconActions className={classnames(styles.dataIcon)} />,
      },
      button: {
        WARNINGS: () => dispatch(push('errors')),
        REPORTS: () => this.scrollSmoothHandler(reportRef),
        ACTIONS: actionRef !== null ? () => this.scrollSmoothHandler(actionRef) : () => dispatch(push('actions')),
      },
    }

    const anchorCards = () => {
      return (
        types.map(type => (
          type && (
          <Card className={classnames(styles.anchorCard)} key={type}>
            {cardDetails.icons[type]}
            <div className={classnames(styles.cardTitle)}>{cardDetails.title[type]}</div>
            <div className={classnames(styles.subTitle)}>{cardDetails.subtitle[type]}</div>
            <div>
              <Button mode="flat" onClick={cardDetails.button[type]}>{`GO TO ${type}`}</Button>
              {/* <Icons.IconArrowDownward className={styles.arrow} height={24} width={24} /> */}
            </div>
          </Card>
          )
        )))
    }

    if (!hasTypes && hasDownloads) {
      return (
        <div className={classnames(styles.base, styles.hasDownloads, styles.blank)} />
      )
    }

    return (
      <div className={classnames(styles.base, hasTypes && hasDownloads ? styles.hasDownloads : null)}>
        <Container className={styles.container}>
          {anchorCards()}
        </Container>
      </div>
    )
  }
}

export default JobAnchorBanner
