export function setCookie(cname, cvalue, exdays) {
  const d = new Date()
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
  const expires = `expires=${d.toUTCString()}`

  document.cookie = `${cname}=${cvalue};${expires};path=/`
}

export function getCookie(cname) {
  const name = `${cname}=`
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';').map(c => c.trim())

  const cookie = ca.find(c => c.indexOf(name) === 0)

  if (typeof cookie === 'undefined') {
    return ''
  }

  return cookie.substring(name.length, cookie.length)
}
