import {
  getRequest, parseError, postRequest, putRequest, deleteRequest,
} from 'utils/api'
import { modalHide } from 'actions/modals'
import constants from 'constants'
import { push } from 'connected-react-router'
import { isEditableMember, roles } from 'utils/business/jobs'
import { showErrorPage, notify } from './app'
import {
  formSubmit, formDestroy, formSubmitClear, formSubmitComplete,
} from './forms'

export const FETCHING_HOSTED_REPORTS = 'FETCHING_HOSTED_REPORTS'
export const FETCHED_HOSTED_REPORTS = 'FETCHED_HOSTED_REPORTS'
export const FETCHING_HOSTED_REPORT = 'FETCHING_HOSTED_REPORT'
export const FETCHED_HOSTED_REPORT = 'FETCHED_HOSTED_REPORT'
export const CREATING_HOSTED_REPORT = 'CREATING_HOSTED_REPORT'
export const CREATED_HOSTED_REPORT = 'CREATED_HOSTED_REPORT'
export const CREATING_HOSTED_REPORT_ERROR = 'CREATING_HOSTED_REPORT_ERROR'
export const UPDATING_HOSTED_REPORT = 'UPDATING_HOSTED_REPORT'
export const UPDATED_HOSTED_REPORT = 'UPDATED_HOSTED_REPORT'
export const UPDATING_HOSTED_REPORT_ERROR = 'UPDATING_HOSTED_REPORT_ERROR'
export const UPDATE_HOSTED_REPORT_USERS = 'UPDATE_HOSTED_REPORT_USERS'
export const REMOVE_HOSTED_REPORT_USER = 'REMOVE_HOSTED_REPORT_USER'
export const ADD_HOSTED_REPORT_USER = 'ADD_HOSTED_REPORT_USER'
export const HOSTED_REPORT_SET_FILTERS = 'HOSTED_REPORT_SET_FILTERS'
export const HOSTED_REPORT_CLEAR_FILTERS = 'HOSTED_REPORT_CLEAR_FILTERS'

export const setFilters = filters => ({
  type: HOSTED_REPORT_SET_FILTERS,
  filters,
})

export const clearFilters = filters => ({
  type: HOSTED_REPORT_CLEAR_FILTERS,
  filters,
})

const fetchingHostedReports = () => ({
  type: FETCHING_HOSTED_REPORTS,
})

const fetchedHostedReports = reportGroups => ({
  type: FETCHED_HOSTED_REPORTS,
  reportGroups,
})

export function fetchHostedReports() {
  return (dispatch) => {
    dispatch(fetchingHostedReports())

    getRequest('/reports/hosted')
      .then(({ data }) => {
        dispatch(fetchedHostedReports(data))
      })
      .catch((error) => {
        dispatch(modalHide(constants.MODAL_CREATE_HOSTED_REPORT))
        const err = parseError(error)
        dispatch(showErrorPage(err))
      })
  }
}

const fetchingHostedReportGroup = id => ({
  type: FETCHING_HOSTED_REPORT,
  id,
})

const fetchedHostedReportGroup = (id, reportGroup) => ({
  type: FETCHED_HOSTED_REPORT,
  id,
  reportGroup,
})

export function fetchHostedReport(id) {
  return (dispatch) => {
    dispatch(fetchingHostedReportGroup(id))

    getRequest(`/reports/hosted/${id}`)
      .then(({ data }) => {
        dispatch(fetchedHostedReportGroup(id, data))
      })
      .catch((error) => {
        const err = parseError(error)
        dispatch(showErrorPage(err))
      })
  }
}

const creatingHostedReport = () => ({
  type: CREATING_HOSTED_REPORT,

})

const createdHostedReport = report => ({
  type: CREATED_HOSTED_REPORT,
  report,
})

const creatingHostedReportError = () => ({
  type: CREATING_HOSTED_REPORT_ERROR,
})

export function createHostedReport(report) {
  return (dispatch) => {
    dispatch(creatingHostedReport())

    postRequest('/reports/hosted', report)
      .then(({ data }) => {
        dispatch(push(`/custom/${data.id}/reports`))
        dispatch(modalHide(constants.MODAL_CREATE_HOSTED_REPORT))
        dispatch(createdHostedReport(data))
      })
      .catch((error) => {
        const err = parseError(error)
        dispatch(creatingHostedReportError())

        if (err.status === constants.ERROR.BAD_REQUEST) {
          dispatch(notify('Error when creating report - check your report URLs are valid', 'accept', 5000))
        }

        dispatch(notify('Unable to create report group', 'accept', 5000))
      })
  }
}

const updatingHostedReport = groupId => ({
  type: UPDATING_HOSTED_REPORT,
  groupId,
})

const updatedHostedReport = (groupId, group) => ({
  type: UPDATED_HOSTED_REPORT,
  groupId,
  group,
})

const updatingHostedReportError = () => ({
  type: UPDATING_HOSTED_REPORT_ERROR,
})

export function editHostedReport(groupId, group) {
  return (dispatch) => {
    dispatch(updatingHostedReport(groupId))

    putRequest(`/reports/hosted/${groupId}`, group)
      .then(({ data }) => {
        dispatch(updatedHostedReport(groupId, data))
        dispatch(modalHide(constants.MODAL_EDIT_HOSTED_REPORT))
      })
      .catch((error) => {
        const err = parseError(error)
        dispatch(updatingHostedReportError())

        if (err.status === constants.ERROR.BAD_REQUEST) {
          dispatch(notify('Error when updating report - check your report URLs are valid', 'accept', 5000))
        }

        dispatch(notify('Unable to modify report group', 'accept', 5000))
      })
  }
}

export const updateHostedReportUsers = (groupId, users) => ({
  type: UPDATE_HOSTED_REPORT_USERS,
  groupId,
  users,
})

const removeHostedReportUser = (groupId, userId) => ({
  type: REMOVE_HOSTED_REPORT_USER,
  groupId,
  userId,
})

export function removeHostedReportMember(group, userId, userDisplayName, callback) {
  return (dispatch) => {
    deleteRequest(`/reports/hosted/${group.id}/user/${userId}`)
      .then(() => {
        dispatch(removeHostedReportUser(group.id, userId))
        dispatch(notify(`You have removed ${userDisplayName} from '${group.name}'.`))
        callback()
      })
      .catch(() => {
        dispatch(notify(`Failed to remove ${userDisplayName}. Please try again later.`))
      })
  }
}

const addHostedReportMember = (groupId, user) => ({
  type: ADD_HOSTED_REPORT_USER,
  groupId,
  user,
})

export function updateHostedReportMembers(formId, groupId, members, existingMembers) {
  return (dispatch) => {
    dispatch(formSubmit(formId))

    const nonEngagementMembers = existingMembers.filter(x => isEditableMember(x.role.name))
    const deletedMembers = nonEngagementMembers.filter(x => !members.some(y => y.value === x.userDetails.email))
    const addedMembers = members.filter(x => !nonEngagementMembers.some(y => y.userDetails.email === x.value))

    // Delete users
    Promise.all(deletedMembers.map(user => deleteRequest(`reports/hosted/${groupId}/user/${user.id}`)
      .then(() => ({ success: true, user }))
      .catch(error => ({ success: false, error }))))
      .then((results) => {
        if (results.some(x => !x.success)) {
          dispatch(notify('Some users could not be removed from the job, please refresh the page and try again, or contact support.'))
        }

        results.filter(x => x.success).forEach((x) => {
          dispatch(removeHostedReportUser(groupId, x.user.id))
        })
      })

    // Create users
    Promise.all(addedMembers.map(user => postRequest(
      'user',
      {
        user: {
          email: user.value,
          isExternal: false,
        },
      },
    ).then(({ data: { user: response } }) => ({ success: true, user, response }))
      .catch(error => ({ success: false, user, error }))))
      .then((results) => {
        if (results.some(x => !x.success)) {
          dispatch(notify('Some users could not be added, please refresh the page and try again, or contact support.'))
        }

        Promise.all(results.filter(x => x.success).map(result => postRequest(
          `reports/hosted/${groupId}/user/${result.response.id}`,
        ).then(({ data }) => ({ success: true, user: result.response, data }))
          .catch(error => ({ success: false, user: result.response, error }))))
          .then((addResults) => {
            if (addResults.some(x => !x.success)) {
              dispatch(notify('Some users could not be added, please refresh the page and try again, or contact support.'))
            }

            addResults.filter(x => x.success).forEach((x) => {
              dispatch(addHostedReportMember(groupId, {
                ...x.user,
                role: roles.HostedReportMember,
              }))
            })

            dispatch(formSubmitComplete(formId))
            setTimeout(() => {
              dispatch(formSubmitClear(formId))
              dispatch(modalHide(constants.MODAL_MANAGE_JOB))
              dispatch(formDestroy(formId))
            }, 1000)
          })
      })
  }
}
