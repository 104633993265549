/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconArchive = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--baselinearchive24px',
    className,
  )

  return (
    <IconBase className={classes} viewBox="0 0 46 46" xmlns="http://www.w3.org/2000/svg"{...rest}><title>Archive</title><g fill="none" fillRule="evenodd"><path className="path1" d="M44.35 6.075l-3.475-4.2A3.629 3.629 0 0 0 38 .5H8c-1.175 0-2.2.525-2.9 1.375l-3.45 4.2A4.895 4.895 0 0 0 .5 9.25V40.5c0 2.75 2.25 5 5 5h35c2.75 0 5-2.25 5-5V9.25c0-1.2-.425-2.325-1.15-3.175zM23 36.75L9.25 23H18v-5h10v5h8.75L23 36.75zM5.8 5.5L7.825 3h30l2.35 2.5H5.8z" fill="#E0E0E0" fillRule="nonzero"/><path className="path2" d="M-7-7h60v60H-7z"/></g></IconBase>
  )
}

IconArchive.propTypes = {
  className: PropTypes.string,
}

export default IconArchive
/* eslint-enable */
