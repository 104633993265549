import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import Spinner from 'components/Spinner'
import styles from './Loading.scss'

const Loading = ({
  inline, pageLoading, message, className,
}) => {
  const classNames = classnames(
    styles.base,
    { [styles.isPageLoader]: pageLoading },
    { [styles.inline]: inline },
    className,
  )

  const iconClassNames = classnames(
    styles.icon,
    { [styles.inlineIcon]: inline },
  )

  return (
    <div className={classNames}>
      <div className={classnames(styles.cell)}>
        <Spinner
          className={iconClassNames}
          size="small"
        />
        {!inline && message}
      </div>
    </div>
  )
}

Loading.propTypes = {
  className: PropTypes.string,
  inline: PropTypes.bool,
  message: PropTypes.string,
  pageLoading: PropTypes.bool,
}

Loading.defaultProps = {
  className: null,
  inline: false,
  message: 'LOADING',
  pageLoading: false,
}

export default Loading
