import React from 'react'
import PropTypes from 'prop-types'
import { TextInput } from '@deloitte/gel-library'
import InsertChartIcon from '@material-ui/icons/InsertChart'
import DeleteIcon from '@material-ui/icons/Delete'
import classNames from 'classnames'
import styles from './ReportForm.scss'

const ReportForm = ({
  onDelete, showReportHeading, showDeleteIcon, report, form, onChange, validateUrlOnBlur, validateRequiredFieldOnBlur,
}) => {
  return (
    <div className={showReportHeading ? styles.reportsContainer : styles.accordianStyle}>
      {showReportHeading && (
      <div className={styles.newReportHeading}>
        <div className={styles.newReport}>
          <InsertChartIcon className={styles.contentIcon} />
          Add new report
        </div>
        <div className={classNames(styles.deleteReport, showDeleteIcon ? styles.hoverState : null)}>
          {showDeleteIcon && <DeleteIcon onClick={() => onDelete(report)} />}
        </div>
      </div>
      )}
      <section>
        <TextInput
          error={form[report.name]?.error}
          label="Report name"
          name={report.name}
          onBlur={() => validateRequiredFieldOnBlur(report.name, form[report.name]?.value)}
          onChange={value => onChange(report.name, value)}
          value={form[report.name]?.value}
        />
      </section>
      <section>
        <TextInput
          error={form[report.url]?.error}
          label="Report URL"
          name={report.url}
          onBlur={() => validateUrlOnBlur(report.url, form[report.url]?.value)}
          onChange={value => onChange(report.url, value)}
          value={form[report.url]?.value}
        />
      </section>
      <section>
        <TextInput
          error={form[report.description]?.error}
          label="Report description"
          name={report.description}
          onBlur={() => validateRequiredFieldOnBlur(report.description, form[report.description]?.value)}
          onChange={value => onChange(report.description, value)}
          value={form[report.description]?.value}
        />
      </section>
    </div>
  )
}

ReportForm.propTypes = {
  form: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  report: PropTypes.object.isRequired,
  showDeleteIcon: PropTypes.bool.isRequired,
  showReportHeading: PropTypes.bool,
  validateRequiredFieldOnBlur: PropTypes.func.isRequired,
  validateUrlOnBlur: PropTypes.func.isRequired,
}

ReportForm.defaultProps = {
  showReportHeading: true,
}

export default ReportForm
