/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconAppAPS = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--appaps',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 68"{...rest}><path className="path1" fill="#00BD9D" d="M58.1 68H9.9C4.4 68 0 63.6 0 58.1V9.9C0 4.4 4.4 0 9.9 0h48.2C63.6 0 68 4.4 68 9.9v48.2c0 5.5-4.4 9.9-9.9 9.9"/><path className="path2" fill="#01A589" d="M0 9.9v48.2C0 63.6 4.4 68 9.9 68h48.3L9.9 0C4.4 0 0 4.4 0 9.9"/><g fill="#FFF"><path className="path3" d="M24.5 41.7L23 37.4h-5.9l-1.5 4.3H13l5.7-15.4h2.7l5.7 15.4h-2.6zm-2.2-6.4l-1.4-4.2c-.1-.3-.2-.7-.4-1.3-.2-.6-.3-1-.4-1.3-.2.9-.5 1.8-.8 2.8l-1.4 4h4.4zM41.1 31c0 1.6-.5 2.8-1.6 3.7-1 .9-2.5 1.3-4.5 1.3h-1.6v5.7h-2.5V26.4h4.4c1.9 0 3.3.4 4.3 1.2 1 .7 1.5 1.9 1.5 3.4zm-7.7 2.9h1.3c1.3 0 2.2-.2 2.8-.7.6-.4.9-1.1.9-2.1 0-.9-.3-1.5-.8-2-.5-.4-1.4-.6-2.5-.6h-1.7v5.4zM54.7 37.5c0 1.4-.5 2.4-1.5 3.2-1 .8-2.3 1.2-4.1 1.2s-3.1-.3-4.3-.8v-2.4c.7.3 1.4.6 2.2.8.8.2 1.5.3 2.2.3 1 0 1.7-.2 2.2-.6.5-.4.7-.9.7-1.5s-.2-1.1-.6-1.5c-.4-.4-1.3-.9-2.7-1.4-1.4-.6-2.4-1.2-3-1.9-.6-.7-.9-1.6-.9-2.6 0-1.3.5-2.3 1.4-3s2.1-1.1 3.7-1.1c1.5 0 2.9.3 4.4 1l-.8 2c-1.4-.6-2.6-.9-3.6-.9-.8 0-1.4.2-1.8.5-.4.4-.6.8-.6 1.4 0 .4.1.7.3 1 .2.3.4.5.8.8.4.3 1.1.6 2.1 1 1.1.5 1.9.9 2.5 1.3.5.4.9.9 1.2 1.4s.2 1.2.2 1.8z"/></g></IconBase>
  )
}

IconAppAPS.propTypes = {
  className: PropTypes.string,
}

export default IconAppAPS
/* eslint-enable */
