import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './Column.scss'

const Column = ({
  base,
  children,
  className,
  flush,
  large,
  layout,
  medium,
  pull,
  push,
  reverse,
  small,
  verticalSpace,
}) => {
  // pattern: span{VALUE}of{LAYOUT}{?}when{BASE|SMALL|MEDIUM|LARGE}
  const layoutPattern = layout ? `of${layout}` : ''
  const basePattern = `span${base}${layoutPattern}`
  const smallPattern = small ? `span${small}${layoutPattern}whenSmall` : null
  const mediumPattern = medium ? `span${medium}${layoutPattern}whenMedium` : null
  const largePattern = large ? `span${large}${layoutPattern}whenLarge` : null

  const baseStyles = classnames(
    styles.base,
    { [styles[basePattern]]: basePattern },
    { [styles[smallPattern]]: smallPattern },
    { [styles[mediumPattern]]: mediumPattern },
    { [styles[largePattern]]: largePattern },
    { [styles[`pull${pull}`]]: pull },
    { [styles[`push${push}`]]: push },
    { [styles.flush]: flush },
    { [styles.reverse]: reverse },
    { [styles.verticalSpace]: verticalSpace },
  )

  return (
    <div className={classnames(baseStyles, className)}>
      {children}
    </div>
  )
}

Column.propTypes = {
  base: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.string,
  flush: PropTypes.bool,
  large: PropTypes.number,
  layout: PropTypes.number,
  medium: PropTypes.number,
  pull: PropTypes.number,
  push: PropTypes.number,
  reverse: PropTypes.bool,
  small: PropTypes.number,
  verticalSpace: PropTypes.bool,
}

Column.defaultProps = {
  base: null,
  children: null,
  className: null,
  flush: false,
  large: null,
  layout: null,
  medium: null,
  pull: null,
  push: null,
  reverse: false,
  small: null,
  verticalSpace: false,
}

export default Column
