/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconGlobe = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--globe',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18"{...rest}><path className="path1" fill="#FFF" d="M9 0C4 0 0 4 0 9s4 9 9 9 9-4 9-9-4-9-9-9zm.8 16.8H8.2c-1-.5-1.8-1.7-2.4-3.5 1 .2 2 .3 3.1.3s2.2-.1 3.1-.3c-.4 1.8-1.2 3-2.2 3.5zm-.8-4c-1.2 0-2.4.1-3.4-.1-.2-.9-.3-1.7-.4-2.7h7.5c0 1-.2 1.8-.4 2.7-.9.2-2.1.1-3.3.1zM8.2 1.2h1.6c1 .5 1.8 1.7 2.4 3.5-1-.2-2-.3-3.1-.3s-2.2.1-3.1.3c.4-1.8 1.3-3 2.2-3.5zm.8 4c1.2 0 2.4.4 3.4.6.2.9.4 2.2.4 3.2H5.2c0-1 .2-2.3.4-3.2 1.1-.2 2.2-.6 3.4-.6zM16.8 9h-3.2c0-1-.1-2.1-.3-2.9 1.7.5 3 1.5 3.5 2.5V9zM4.4 9H1.2v-.6c.5-1 1.7-1.7 3.5-2.3C4.5 7 4.4 8 4.4 9zm-3.2 1h3.2c0 1 .1 1.6.3 2.4-1.7-.5-3-1.2-3.5-2.2V10zm12.5 0h3.2v.1c-.5 1-1.7 2-3.5 2.5.1-.9.2-1.6.3-2.6zm2.7-3.6c-.8-.7-1.9-1.2-3.3-1.5-.4-1.3-.9-2.5-1.5-3.3 2.3.8 4 2.6 4.8 4.8zm-10-4.8c-.6.8-1.1 1.9-1.5 3.3-1.3.3-2.5.9-3.3 1.5.8-2.2 2.6-4 4.8-4.8zm-4.8 10c.8.7 1.9 1.2 3.3 1.5.4 1.3.9 2.5 1.5 3.3-2.2-.8-4-2.6-4.8-4.8zm10 4.8c.7-.8 1.2-1.9 1.5-3.3 1.3-.4 2.5-.9 3.3-1.5-.8 2.2-2.5 4-4.8 4.8z"/><g fill="none"><path className="path2" d="M9.8 16.8H8.2c-1-.5-1.8-1.7-2.4-3.5 1 .2 2 .3 3.1.3s2.2-.1 3.1-.3c-.4 1.8-1.2 3-2.2 3.5zM9 12.8c-1.2 0-2.4.1-3.4-.1-.2-.9-.3-1.7-.4-2.7h7.5c0 1-.2 1.8-.4 2.7-.9.2-2.1.1-3.3.1zM8.2 1.2h1.6c1 .5 1.8 1.7 2.4 3.5-1-.2-2-.3-3.1-.3s-2.2.1-3.1.3c.4-1.8 1.3-3 2.2-3.5zM9 5.2c1.2 0 2.4.4 3.4.6.2.9.4 2.2.4 3.2H5.2c0-1 .2-2.3.4-3.2 1.1-.2 2.2-.6 3.4-.6zM16.8 9h-3.2c0-1-.1-2.1-.3-2.9 1.7.5 3 1.5 3.5 2.5V9zM4.4 9H1.2v-.6c.5-1 1.7-1.7 3.5-2.3C4.5 7 4.4 8 4.4 9zM1.2 10h3.2c0 1 .1 1.6.3 2.4-1.7-.5-3-1.2-3.5-2.2V10zM13.7 10h3.2v.1c-.5 1-1.7 2-3.5 2.5.1-.9.2-1.6.3-2.6zM16.4 6.4c-.8-.7-1.9-1.2-3.3-1.5-.4-1.3-.9-2.5-1.5-3.3 2.3.8 4 2.6 4.8 4.8zM6.4 1.6c-.6.8-1.1 1.9-1.5 3.3-1.3.3-2.5.9-3.3 1.5.8-2.2 2.6-4 4.8-4.8zM1.6 11.6c.8.7 1.9 1.2 3.3 1.5.4 1.3.9 2.5 1.5 3.3-2.2-.8-4-2.6-4.8-4.8zM11.6 16.4c.7-.8 1.2-1.9 1.5-3.3 1.3-.4 2.5-.9 3.3-1.5-.8 2.2-2.5 4-4.8 4.8z"/></g></IconBase>
  )
}

IconGlobe.propTypes = {
  className: PropTypes.string,
}

export default IconGlobe
/* eslint-enable */
