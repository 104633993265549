// @flow

/**
 * getElementLockedStatus
 * @param  {integer} start bound position to detect for lock begin
 * @param  {integer} end bound position to detect for lock finish
 * @return {string || bool} returns to currents bounds the element is colliding with, or false if fixed
 */

export default function getElementLockedStatus(start: number, end: number) {
  const { body } = document
  const html = document.documentElement

  const pageHeight = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight,
  )

  const { scrollTop } = body
  const windowHeight = body.clientHeight
  const contentHeight = pageHeight - end
  const adjustedScroll = scrollTop + windowHeight

  if (start && (scrollTop <= start)) {
    return 'start'
  } if (end && (adjustedScroll >= contentHeight)) {
    return 'end'
  }

  return false
}
