import React from 'react'
import PropTypes from 'prop-types'
import useApp from 'hooks/useApp'
import { HashLink as Link } from 'react-router-hash-link'
import { CONTACT_OPTIONS } from 'views/ContactForm/ContactForm'
import styles from './ExpiryInfoBanner.scss'

const ExpiryInfoBanner = ({ reachedExecutionLimit, hasExpired }) => {
  const { user } = useApp()

  const InfoText = () => {
    if (hasExpired) {
      return (
        'You are viewing an expired job'
      )
    }
    if (reachedExecutionLimit) {
      if (user.isExternal) {
        return (
          <div>
            You can no longer edit this job. To reopen, please
            {' '}
            <Link to={`/support?option=${CONTACT_OPTIONS.ExtensionOfAccess}#contact`}>
              contact our team.
            </Link>
          </div>
        )
      }
    }
    return 'This job has exhausted the number of runs.'
  }

  return (
    <div className={styles.base}>
      <InfoText />
    </div>
  )
}

ExpiryInfoBanner.propTypes = {
  hasExpired: PropTypes.bool,
  reachedExecutionLimit: PropTypes.bool,
}

ExpiryInfoBanner.defaultProps = {
  hasExpired: false,
  reachedExecutionLimit: false,
}

export default ExpiryInfoBanner
