/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconAppAnalyser = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--appanalyser',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 68"{...rest}><path className="path1" fill="#6BC111" d="M58.1 68H9.9C4.4 68 0 63.6 0 58.1V9.9C0 4.4 4.4 0 9.9 0h48.2C63.6 0 68 4.4 68 9.9v48.2c0 5.5-4.4 9.9-9.9 9.9"/><path className="path2" fill="#2EB212" d="M0 9.9V58c0 5.5 4.4 9.9 9.9 9.9h48.2L9.9 0C4.4 0 0 4.4 0 9.9"/><path className="path3" fill="#00A1DE" d="M45 38.4h-8.9c1.9 7.3 8.6 12.8 16.5 12.8v-8.3c-3.3-.1-6.1-1.8-7.6-4.5M52.6 25.2v-8.3c-7.9 0-14.5 5.4-16.5 12.7H45c1.5-2.7 4.4-4.4 7.6-4.4"/><path className="path4" fill="#FFF" d="M23.1 38.4c-1.5 2.6-4.3 4.4-7.6 4.4v8.3c7.9 0 14.5-5.4 16.5-12.8l-8.9.1zM15.5 16.8v8.3c3.2 0 6.1 1.8 7.6 4.4H32c-2-7.3-8.6-12.7-16.5-12.7M11 36.3l-.5-1.5h-2L8 36.3h-.9l2-5.3h.9l2 5.3h-1zm-.7-2.2l-.5-1.4c0-.1-.1-.2-.1-.5-.1-.2-.1-.4-.1-.5-.1.3-.2.6-.3 1l-.5 1.4h1.5zM19.5 36.3h-1l-2.6-4.2v4.1h-.8V31h1l2.6 4.2v-.6-3.5h.8v5.2zM26.6 36.3l-.5-1.5h-2l-.5 1.5h-.9l2-5.3h.9l2 5.3h-1zm-.7-2.2l-.5-1.4c0-.1-.1-.2-.1-.5-.1-.2-.1-.4-.1-.5-.1.3-.2.6-.3 1l-.5 1.4h1.5zM30.7 36.3v-5.2h.9v4.5h2.2v.7zM38.5 33.5l1.2-2.4h1l-1.8 3.2v2H38v-2l-1.7-3.2h.9zM46.9 34.9c0 .5-.2.8-.5 1.1s-.8.4-1.4.4c-.6 0-1.1-.1-1.5-.3v-.8c.2.1.5.2.8.3s.5.1.8.1.6-.1.8-.2c.2-.1.2-.3.2-.5s-.1-.4-.2-.5c-.1-.1-.5-.3-.9-.5-.5-.2-.8-.4-1-.7-.2-.2-.3-.5-.3-.9s.2-.8.5-1c.3-.3.7-.4 1.2-.4s1 .1 1.5.3l-.4.7c-.5-.2-.9-.3-1.2-.3s-.5.1-.6.2c-.1.1-.2.3-.2.5 0 .1 0 .3.1.3l.3.3c.1.1.4.2.7.3.4.2.7.3.8.4.2.1.3.3.4.5 0 .3.1.5.1.7M53.4 36.3h-3v-5.2h3v.7h-2.1v1.4h1.9v.8h-1.9v1.6h2.1zM57.9 34.2v2.1H57v-5.2h1.5c.7 0 1.2.1 1.5.4s.5.6.5 1.1c0 .7-.3 1.1-1 1.4l1.5 2.3h-1l-1.3-2.1h-.8zm0-.7h.6c.4 0 .7-.1.9-.2.2-.1.3-.4.3-.7s-.1-.5-.3-.6-.5-.2-.9-.2h-.6v1.7z"/></IconBase>
  )
}

IconAppAnalyser.propTypes = {
  className: PropTypes.string,
}

export default IconAppAnalyser
/* eslint-enable */
