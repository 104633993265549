/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconBASpreparation = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--baspreparation',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"{...rest}><title>Fill 1</title><path className="path1" fill="#58595B" d="M18.9 21.4H5.1c-.2 0-.4-.2-.4-.4V4.9c0-.2.2-.4.4-.4h2v.8H5.5v15.3h13V5.3h-1.6v-.8h2c.2 0 .4.2.4.4V21c0 .2-.2.4-.4.4z"/><path className="path2" fill="#58595B" d="M16.9 7.1H7.1c-.2 0-.4-.2-.4-.4V3.8c0-.2.2-.4.4-.4h2.1v-.5C9.1 1.3 10.4 0 12 0s2.9 1.3 2.9 2.9v.5H17c.2 0 .4.2.4.4v2.9c-.1.2-.2.4-.5.4zm-9.4-.8h9.1V4.2h-2.1c-.2 0-.4-.2-.4-.4v-.9c0-1.1-.9-2.1-2.1-2.1s-2.1.9-2.1 2.1v.9c0 .2-.2.4-.4.4h-2v2.1zM15.5 11h-7c-.2 0-.4-.2-.4-.4s.2-.4.4-.4h7.1c.2 0 .4.2.4.4-.1.2-.2.4-.5.4zM15.5 13.6h-7c-.2 0-.4-.2-.4-.4s.2-.4.4-.4h7.1c.2 0 .4.2.4.4s-.2.4-.5.4zM12.7 16.3H8.5c-.2 0-.4-.2-.4-.4s.2-.4.4-.4h4.2c.2 0 .4.2.4.4s-.2.4-.4.4z"/><path className="path3" fill="#58595B" d="M20.6 24H3.4c-.8 0-1.5-.7-1.5-1.5V2.6c0-.8.7-1.5 1.5-1.5h6.4v1.3H3.4c-.1 0-.1.1-.1.1v19.9c0 .1.1.1.1.1h17.3c.1 0 .1-.1.1-.1V2.6c0-.1-.1-.1-.1-.1h-6.4V1.2h6.4c.8 0 1.5.7 1.5 1.5v19.9c-.1.7-.8 1.4-1.6 1.4z"/></IconBase>
  )
}

IconBASpreparation.propTypes = {
  className: PropTypes.string,
}

export default IconBASpreparation
/* eslint-enable */
