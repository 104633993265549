import React, { useState } from 'react'
import FormControl from '@material-ui/core/FormControl'
import PropTypes from 'prop-types'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import {
  Divider, MenuItem,
  Chip, IconButton, Menu,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import Card from 'components/Card'
import FolderIcon from '@material-ui/icons/Folder'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import folderIcon from 'images/folderIcon.svg'
import IconFileUpload from 'icons/IconFileUpload'
import { Button } from '@deloitte/gel-library'
import { removeFileGroup, updateFileGroupFilesUploadStatus, updateFileGroups } from 'actions/job'
import fileIcon from 'images/fileIcon.svg'
import classNames from 'classnames'
import { formatDateFromStringShort } from 'utils/dates'
import binIcon from 'images/binIcon.svg'
import FileGroupDialog from 'components/FileGroupDialog'
import { removeFile } from 'actions/file'
import styles from './FileGroup.scss'

const propTypes = {
  fileGroup: PropTypes.object.isRequired,
  handleFileGroupEdit: PropTypes.func.isRequired,
}

const defaultProps = {
}

const theme = createTheme({
  overrides: {
    MuiInput: {
      formControl: {
        'label + &': {
          marginTop: '0px',
        },
        fontFamily: 'Open Sans, Verdana, sans-serif;',
        fontSize: 'inherit',
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: '12px',
      },
    },
  },
})

const MAX_FILE_NAME_DISPLAY_CHAR = 19
const ITEM_HEIGHT = 48

const FileGroup = ({ fileGroup, handleFileGroupEdit }) => {
  const [showDuplicateDialog, setShowDuplicateDialog] = useState(false)
  const [duplicateFileNames, setDuplicateFileNames] = useState([])
  const [showFileGroupDeletionConfirmation, setShowFileGroupDeletionConfirmation] = useState(false)
  const [showFileDeletionConfirmation, setShowFileDeletionConfirmation] = useState(false)
  const [fileToDelete, setFileToDelete] = useState(null)
  const { job: { isCustom, fileGroups, fileGroupFilesUploadStatus }, app: { user: { firstName, surname } } } = useSelector(state => state)
  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const removeFileFromFileGroup = (updatedFileGroups, file) => {
    dispatch(updateFileGroups(updatedFileGroups))
    const newFileGroupFilesUploadStatus = fileGroupFilesUploadStatus.filter(fgfs => fgfs.fileGroup.id !== fileGroup.id && fgfs.file.name !== file.name)
    dispatch(updateFileGroupFilesUploadStatus(newFileGroupFilesUploadStatus))
  }

  const deleteFile = () => {
    const updatedFileGroups = fileGroups.map((fg) => {
      return fg.name === fileGroup.name ? { ...fg, files: fg.files.filter(f => f.name !== fileToDelete.name) } : fg
    })
    if (fileToDelete.fileId) {
      const { name } = fileToDelete
      dispatch(removeFile(fileGroup.id, { name }, () => removeFileFromFileGroup(updatedFileGroups, fileToDelete)))
    } else {
      removeFileFromFileGroup(updatedFileGroups, fileToDelete)
    }
    setShowFileDeletionConfirmation(false)
  }

  const handleFileDeletion = (file) => {
    setShowFileDeletionConfirmation(true)
    setFileToDelete(file)
  }

  const onFileUploadChange = (e) => {
    const { files } = e.target
    const filesArr = Array.prototype.slice.call(files)
    const duplicateFiles = filesArr.filter(file => fileGroup?.files?.map(f => f.name).includes(file.name))
    if (duplicateFiles.length > 0) {
      setDuplicateFileNames(duplicateFiles?.map(df => df.name))
      setShowDuplicateDialog(true)
    }
    const updatedFileGroups = fileGroups.map((fg) => {
      return fg.name === fileGroup.name ? { ...fg, files: fg.files?.concat(filesArr.filter(file => fg.files?.map(fgf => fgf.name).indexOf(file.name) < 0)) } : fg
    })
    dispatch(updateFileGroups(updatedFileGroups))
    const nonDuplicateFiles = filesArr.filter(x => !duplicateFiles.includes(x))
    const newFileGroupFilesUploadStatus = nonDuplicateFiles?.map((nd) => {
      return {
        fileGroup, status: 'pending', file: nd,
      }
    })
    dispatch(updateFileGroupFilesUploadStatus(fileGroupFilesUploadStatus.concat(newFileGroupFilesUploadStatus)))
  }

  const splitLastOccurrence = (str, substring) => {
    const lastIndex = str.lastIndexOf(substring)
    const before = str.slice(0, lastIndex)
    const after = str.slice(lastIndex + 1)
    return [before, after]
  }

  const decorateFileNameText = (fileNameText) => {
    const [before, after] = splitLastOccurrence(fileNameText, '.')
    return before > MAX_FILE_NAME_DISPLAY_CHAR ? `${before.substring(0, MAX_FILE_NAME_DISPLAY_CHAR + 1)}....${after}` : fileNameText
  }

  const formatFileSize = (bytes) => {
    const suffixes = ['B', 'kB', 'MB', 'GB', 'TB']
    const i = Math.floor(Math.log(bytes) / Math.log(1024))
    const size = (bytes / (1024 ** i))
    return `${size === Math.floor(size) ? size : size.toFixed(2)} ${suffixes[i]}`
  }

  const onCloseDuplicateDialog = () => {
    setShowDuplicateDialog(false)
    setDuplicateFileNames([])
  }

  const editFileGroup = () => {
    handleFileGroupEdit()
    handleClose()
  }

  const delFileGroup = () => {
    setShowFileGroupDeletionConfirmation(true)
    handleClose()
  }

  const handleFileGroupDeletion = () => {
    dispatch(removeFileGroup(fileGroup.name, fileGroup.id))
    setShowFileGroupDeletionConfirmation(false)
  }

  const onInputClick = (event) => {
    const element = event.target
    element.value = ''
  }

  const undoFileDeletion = () => {
    setShowFileDeletionConfirmation(false)
    setFileToDelete(null)
  }

  return (
    <div>
      <Card
        className={styles.fileGroupCard}
      >
        <div className={styles.fileGroupHeader}>
          <div className={styles.fileGroupPrimaryHeader}>
            <div className={styles.fileNameDescImg}>
              <FolderIcon
                className={styles.fileGroupFolderIcon}
              />
              <div>
                <div className={styles.fileGroupName}>{fileGroup.name}</div>
                <div className={styles.fileGroupDescription}>{fileGroup.description}</div>
              </div>
            </div>
            <FormControl>
              <MuiThemeProvider theme={theme}>
                <IconButton
                  onClick={handleClick}
                  size="medium"
                >
                  <MoreVertIcon className={styles.morevert} />
                </IconButton>
                <Menu
                  MenuListProps={{
                    'aria-labelledby': 'long-button',
                  }}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: '20ch',
                    },
                  }}
                  anchorEl={anchorEl}
                  id="long-menu"
                  onClose={handleClose}
                  open={open}
                >
                  <MenuItem
                    key="Edit"
                    onClick={() => editFileGroup()}
                    value="Edit"
                  >
                    Edit
                  </MenuItem>
                  <MenuItem
                    key="Delete"
                    onClick={() => delFileGroup()}
                    value="Delete"
                  >
                    Delete
                  </MenuItem>
                </Menu>
              </MuiThemeProvider>
            </FormControl>
          </div>
          {!isCustom && (
          <div>
            <div className={styles.fileGroupTargetData}>Target data</div>
            <div>
              {fileGroup.targetSchemas?.map((ts) => {
                return (
                  <Chip
                    className={styles.targetSchemaChip}
                    icon={(
                      <img
                        alt="Folder Icon"
                        className={styles.targetSchemaFolderIcon}
                        src={folderIcon}
                      />
                  )}
                    key={ts}
                    label={ts}
                  />
                )
              })}
            </div>
          </div>
          )}
        </div>
        <div className={styles.fileGroupInfo}>
          {fileGroup.files === null || fileGroup.files?.length === 0 ? (
            <div className={styles.emptyFileGroup}>
              <IconFileUpload width="54" />
              <div className={styles.noOfFileGroups}>
                0 files uploaded
              </div>
              <div>Only .csv files, tab or semi-colon delimited text (.txt, .tsv) are accepted. File sizes should not exceed 50 GB for each file uploaded.</div>
              <div className="customFileUpload">
                <Button
                  className={styles.addFile}
                  mode="secondary"
                >
                  <label
                    className={styles.fileUploadLabel}
                    htmlFor={`${fileGroup.name}-file-upload`}
                  >
                    + Add file
                  </label>
                  <input
                    multiple
                    accept=".csv,.txt,.tsv,.zip"
                    id={`${fileGroup.name}-file-upload`}
                    name="rawFiles[]"
                    onChange={event => onFileUploadChange(event)}
                    onClick={onInputClick}
                    type="file"
                  />
                </Button>

              </div>
            </div>
          ) : (
            <div>
              {fileGroup.files?.map(f => (
                <Card
                  className={styles.filledFileGroup}
                  key={f.id}
                >
                  <div className={styles.filePrimary}>
                    <img
                      alt="File Icon"
                      src={fileIcon}
                    />
                    <div className={classNames(styles.fileName, styles.fileInfoPrimary)}>{decorateFileNameText(f.name)}</div>
                    <div className={styles.fileInfoPrimary}>
                      Last upload:
                      {` ${formatDateFromStringShort(new Date())}`}
                    </div>
                    <div className={styles.fileInfoPrimary}>
                      Uploaded by:
                      {` ${firstName} ${surname}`}
                    </div>
                  </div>
                  <div className={styles.fileSecondary}>
                    <div style={{ marginTop: '4px' }}>{formatFileSize(f.size)}</div>
                    <Divider className={styles.fileInfoSecondary} orientation="vertical" />
                    <div className={styles.binButton} onClick={() => handleFileDeletion(f)}>
                      <img alt="Bin Icon" src={binIcon} />
                    </div>
                  </div>
                </Card>
              ))}
              <Button
                className={styles.addFile}
                mode="flat"
              >
                <label
                  className={styles.fileUploadLabel}
                  htmlFor={`${fileGroup.name}-additional-file-upload`}
                >
                  + Add file
                </label>
                <input
                  multiple
                  accept=".csv,.txt,.tsv,.zip"
                  id={`${fileGroup.name}-additional-file-upload`}
                  name="additionalRawFiles[]"
                  onChange={event => onFileUploadChange(event)}
                  onClick={onInputClick}
                  type="file"
                />
              </Button>
            </div>
          )}
        </div>
      </Card>
      {showDuplicateDialog && (
        <FileGroupDialog
          actionLabel="DISMISS"
          heading="Duplicate file found"
          onAction={() => onCloseDuplicateDialog()}
          text={`A file with the same name was detected. The file with duplicate name is not uploaded, please change the name and reupload: ${duplicateFileNames.join()}.`}
        />
      )}
      {showFileGroupDeletionConfirmation
        && (
        <FileGroupDialog
          actionLabel="DELETE FILE GROUP"
          dismissLabel="CANCEL"
          heading="Confirm File group deletion."
          onAction={() => handleFileGroupDeletion()}
          onDismiss={() => setShowFileGroupDeletionConfirmation(false)}
          text="All files in this file group will be deleted."
        />
        )}
      {showFileDeletionConfirmation
        && (
        <FileGroupDialog
          actionLabel="DELETE FILE"
          dismissLabel="CANCEL"
          heading="Data preparation request and file groups will be deleted"
          onAction={() => deleteFile()}
          onDismiss={() => undoFileDeletion()}
          text="If you have uploaded folders or files, these will be deleted if you continue with the flow with no data preparation."
        />
        )}
    </div>
  )
}

FileGroup.propTypes = propTypes
FileGroup.defaultProps = defaultProps

export default FileGroup
