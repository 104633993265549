/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconInfoBlack = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--infoblack',
    className,
  )

  return (
    <IconBase className={classes} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"{...rest}><title>B5A6CAE9-86FF-40CF-B52B-F6F2A7BEAB3D</title><g fill="none" fillRule="evenodd"><path className="path1" d="M-2-2h24v24H-2z"/><path className="path2" d="M9 15h2V9H9v6zm1-15C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zM9 7h2V5H9v2z" fill="#555"/></g></IconBase>
  )
}

IconInfoBlack.propTypes = {
  className: PropTypes.string,
}

export default IconInfoBlack
/* eslint-enable */
