import * as ActionTypes from 'actions/engagementCodes'

const initialState = {
  options: [],
  isFetching: false,
  noResults: false,
  hasFetched: false,
}

export default function job(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.ENGAGEMENT_CODES_FETCHING:
      return {
        ...state,
        isFetching: true,
      }

    case ActionTypes.ENGAGEMENT_CODES_FETCHED:
      return {
        ...state,
        isFetching: false,
        hasFetched: true,
        options: action.options,
      }

    case ActionTypes.ENGAGEMENT_CODES_CLEAR:
      return {
        ...initialState,
      }

    default:
      return state
  }
}
