import React from 'react'
import PropTypes from 'prop-types'

import styles from './VisuallyHidden.scss'

const VisuallyHidden = ({ children }) => (
  <span className={styles.default}>
    {children}
  </span>
)

VisuallyHidden.propTypes = {
  children: PropTypes.node,
}

VisuallyHidden.defaultProps = {
  children: null,
}

export default VisuallyHidden
