/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconTaxReturn = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--taxreturn',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"{...rest}><title>Fill 1</title><g fill="#58595B"><path className="path1" d="M22.7 1.8H1.3C.6 1.8 0 2.4 0 3.1v11c0 .7.6 1.3 1.3 1.3h3.8v6.9c0 .3.3.6.6.6h12.6c.3 0 .6-.3.6-.6v-6.9h3.8c.7 0 1.3-.6 1.3-1.3v-11c0-.7-.6-1.3-1.3-1.3zM6.3 21.6V7.3h11.5v14.3H6.3zM22.8 14c0 .1-.1.1-.1.1h-3.8V8.3h1.4c.4 0 .8-.4.8-.8V6.2c0-.4-.4-.8-.8-.8H3.7c-.4 0-.8.4-.8.8v1.3c0 .4.4.8.8.8h1.4v5.8H1.3c-.1 0-.1-.1-.1-.1V3h21.5c.1 0 .1.1.1.1V14z"/><path className="path2" d="M15.6 9.4H8.4c-.2 0-.3.2-.3.3s.2.3.3.3h7.1c.2 0 .3-.2.3-.3s0-.3-.2-.3zM15.6 12.1H8.4c-.2 0-.3.2-.3.3 0 .2.2.3.3.3h7.1c.2 0 .3-.2.3-.3.1-.2 0-.3-.2-.3zM14.5 16.8h-.9c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h.9c.2 0 .4.1.5.3.1.2.4.4.6.3.2-.1.4-.4.3-.6-.2-.5-.7-.9-1.3-.9v-.3c0-.3-.2-.5-.5-.5s-.5.2-.5.5v.3c-.8 0-1.4.6-1.4 1.4 0 .8.6 1.4 1.4 1.4h.9c.3 0 .5.2.5.5s-.2.5-.5.5h-.9c-.2 0-.4-.1-.5-.3-.1-.2-.4-.4-.6-.3-.2.1-.4.4-.3.6.2.5.7.9 1.3.9v.4c0 .3.2.5.5.5s.5-.2.5-.5v-.3c.8 0 1.4-.6 1.4-1.4 0-.9-.7-1.5-1.4-1.5z"/></g></IconBase>
  )
}

IconTaxReturn.propTypes = {
  className: PropTypes.string,
}

export default IconTaxReturn
/* eslint-enable */
