/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconManageEntities = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--manageentities',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104 104"{...rest}><g fill="none" fillRule="evenodd" strokeWidth="2.2" transform="translate(2 2)"><g stroke="#86BC25"><path className="path1" d="M74.334 40.444v21.774c0 2.995-2.085 5.444-4.633 5.444H32.634c-2.55 0-4.634-2.449-4.634-5.444V40.444C28 37.45 30.085 35 32.634 35h37.067c2.548 0 4.633 2.45 4.633 5.444z" strokeLinecap="round" strokeLinejoin="round"/><path className="path2" d="M28.5 51H48m6 0h19.6" strokeLinecap="square"/><path className="path3" d="M42.459 34.67v-1.863c0-2.117 1.753-3.833 3.916-3.833H55.1c2.162 0 3.915 1.716 3.915 3.833v1.863M48 53.75h5.874V48H48z" strokeLinecap="round" strokeLinejoin="round"/></g><circle cx="50" cy="50" r="50" stroke="#86BD24"/></g></IconBase>
  )
}

IconManageEntities.propTypes = {
  className: PropTypes.string,
}

export default IconManageEntities
/* eslint-enable */
