import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Container } from 'components/layout/Grid/'
import constants from 'constants'
import { push } from 'connected-react-router'
import Banner from 'components/Banner'
import RouteWithSubRoutes from 'components/RouteWithSubRoutes'
import { connect } from 'react-redux'
import { withRouter, Switch } from 'react-router-dom'
import { fetch, resume } from 'actions/jobs'
import { progressModel } from 'actions/app'
import {
  fetchJobDetails,
  fetchJobMembers,
  setBenchmarkFlag,
  updateMembers,
} from 'actions/job'
import Loading from 'components/Loading'
import { IconProcessing } from 'icons'
import Heading from 'components/Heading'
import { modalCreate, modalShow, modalHide } from 'actions/modals'
import Radio from 'components/Form/components/Radio'
import DialogSimple from 'components/DialogSimple'
import ManageJobModal from 'components/ManageJobModal'
import { Button } from '@deloitte/gel-library'
import { getConfig } from 'utils/config'
import { OpenInNew } from '@material-ui/icons'
import styles from './Jobs.scss'

class Jobs extends Component {
  static propTypes = {
    app: PropTypes.shape({
      showModal: PropTypes.bool.isRequired,
      showSandboxModal: PropTypes.bool.isRequired,
      user: PropTypes.object,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    forms: PropTypes.object.isRequired,
    job: PropTypes.object.isRequired,
    jobs: PropTypes.object.isRequired,
    modals: PropTypes.object.isRequired,
    routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  }

  state = {
    showDialog: false,
    jobId: '',
    benchmarkingEnabled: 'Yes',
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(fetch())
  }

  hideModal = () => {
    const { dispatch } = this.props
    dispatch(progressModel(false))
  }

  resumeJob = (jobId) => {
    const { dispatch } = this.props
    dispatch(resume(jobId))
  }

  openJobPopup = ({ jobId }) => {
    const { dispatch } = this.props
    dispatch(fetchJobDetails({ jobId }))
    dispatch(fetchJobMembers(jobId))
    dispatch(modalShow(constants.MODAL_MANAGE_JOB))
  }

  openDialog = ({ jobId }) => {
    const { job } = this.props
    this.setState({
      showDialog: true,
      jobId,
      benchmarkingEnabled: job.benchmarkAllowed ? 'Yes' : 'No',
    })
  }

  closeDialog = () => {
    this.setState({
      showDialog: false,
    })
  }

  handleSaveMemberChanges = (formId, id, members) => {
    const { dispatch } = this.props
    dispatch(updateMembers(formId, { jobId: id, members }))
  }

  render() {
    const {
      job,
      jobs,
      routes,
      app: { showModal, user, showSandboxModal },
      modals,
      forms,
      dispatch,
    } = this.props
    const { benchmarkingEnabled, jobId, showDialog } = this.state

    const actionButton = {
      onAction: () => {
        dispatch(push('/createjob'))
      },
      label: 'CREATE JOB',
    }

    let bannerName = `Welcome${user.firstName ? `, ${user.firstName}` : ''}`

    const homeRoutes = routes.slice()
    if (jobs.jobIds.length > 0) {
      bannerName = 'Your jobs'
    }

    const actions = [{ label: 'Okay', onClick: this.hideModal, primary: true }]

    const openInNewTab = () => {
      const { URL: { FEEDBACK } } = getConfig()
      window.open(FEEDBACK, '_blank')
    }

    return (
      <div>
        <Banner
          hideManageTeam
          actions={[actionButton]}
          name={bannerName}
          routes={homeRoutes}
          {...this.props}
        />
        <Container topPadding>
          {!jobs.isFetched ? (
            <Loading pageLoading />
          ) : (
            <Switch>
              {routes.map(route => (
                <RouteWithSubRoutes
                  key={route.path}
                  {...route}
                  dispatch={dispatch}
                  jobs={jobs}
                  openDialog={this.openDialog}
                  openJobPopup={this.openJobPopup}
                  resumeJob={this.resumeJob}
                  showSandboxModal={showSandboxModal}
                  user={user}
                />
              ))}
            </Switch>
          )}
          <DialogSimple
            actions={actions}
            className={styles.runAnalysisModal}
            dismissLabel="Close"
            isOpen={showModal}
            onDismiss={this.hideModal}
          >
            <IconProcessing height="128" width="260" />
            <Heading size="medium">Running your analysis</Heading>
            <p>
              This could take a few minutes to several hours, depending on the size of your data.
              You will receive an email when your job is complete.
            </p>
            <div className={styles.supportLink}>
              <p>
                As you wait, share your experience with us?
                <br />
                <Button mode="flat" onClick={openInNewTab}>
                  {'SHARE FEEDBACK '}
                  <OpenInNew className={styles.openNew} />
                </Button>
              </p>
            </div>
          </DialogSimple>
          <DialogSimple
            actionLabel="confirm"
            dismissLabel="cancel"
            isOpen={showDialog}
            onAction={() => {
              this.closeDialog()
              dispatch(
                setBenchmarkFlag({
                  jobId,
                  benchmarkAllowed: benchmarkingEnabled === 'Yes',
                }),
              )
            }}
            onDismiss={this.closeDialog}
          >
            <Heading noPadding size="h7">
              You can change your benchmarking selection at anytime
            </Heading>
            <p>
              Would you like to authorise the use of this data for benchmarking
              purposes? I have confirmed with the engagement partner that the
              benchmarking clause has not been removed from the engagement
              letter.
            </p>
            <Radio
              horizontal
              noPadding
              name="benchmarkingEnabled"
              onChange={(name, benchmarkFlag) => {
                this.setState({ benchmarkingEnabled: benchmarkFlag })
              }}
              options={[
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ]}
              value={benchmarkingEnabled}
            />
          </DialogSimple>
          {job && job.members && (
            <ManageJobModal
              dispatch={dispatch}
              forms={forms}
              job={job}
              modals={modals}
              onSubmit={this.handleSaveMemberChanges}
              userName={user.username}
            />
          )}
        </Container>
      </div>
    )
  }
}

const mapStateToProps = ({
  job, jobs, app, forms, modals,
}) => ({
  job,
  jobs,
  app,
  forms,
  modals,
})

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    modalCreate: (modalId) => {
      dispatch(modalCreate(modalId))
    },
    modalShow: (modalId) => {
      dispatch(modalShow(modalId))
    },
    modalHide: (modalId) => {
      dispatch(modalHide(modalId))
    },
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Jobs),
)
