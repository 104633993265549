import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Card from 'components/Card'
import IconArrowDown from 'icons/IconArrowDown'
import styles from './Expander.scss'

export default class ExpanderCard extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    classNameExpanded: PropTypes.string,
    expandedLabel: PropTypes.string,
    headerContent: PropTypes.node,
    hiddenLabel: PropTypes.string,
    onExpand: PropTypes.func,
    size: PropTypes.oneOf(['medium', 'large', 'unlimited']),
    startsExpanded: PropTypes.bool,
  }

  static defaultProps = {
    children: null,
    className: null,
    classNameExpanded: null,
    expandedLabel: null,
    headerContent: null,
    hiddenLabel: null,
    onExpand: null,
    size: 'medium',
    startsExpanded: false,
  }

  constructor(props) {
    super(props)

    this.state = {
      expanded: props.startsExpanded,
    }
  }

  handleClick = () => {
    const { onExpand } = this.props
    const { expanded } = this.state
    const newExpandState = !expanded

    if (onExpand) {
      onExpand(newExpandState)
    }

    this.setState({ expanded: newExpandState })
  }

  render() {
    const {
      className, classNameExpanded, headerContent, children, expandedLabel, hiddenLabel, size,
    } = this.props
    const { expanded } = this.state
    const usingExpandLabels = expandedLabel !== null && hiddenLabel !== null

    return (
      <Card
        noPadding
        className={classnames(styles.base,
          className,
          {
            [styles.expanded]: expanded,
            [classNameExpanded]: expanded,
            [styles.usingExpandLabels]: usingExpandLabels,
          })
        }
      >
        <div className={styles.header} onClick={this.handleClick}>
          {headerContent}

          <IconArrowDown className={styles.disclosureArrow} height={8} width={12} />

          {usingExpandLabels && (
            <div className={styles.expandLabel}>
              {expanded ? expandedLabel : hiddenLabel}
            </div>
          )}

        </div>
        <div className={classnames(styles.content, {
          [styles.large]: size === 'large',
          [styles.unlimited]: size === 'unlimited',
        })}
        >
          {children}
        </div>
      </Card>
    )
  }
}
