import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SelectInput from 'components/Form/components/SelectInput'

import styles from './BannerSelect.scss'

class BannerSelect extends Component {
  static propTypes = {
    context: PropTypes.object,
    currentSelection: PropTypes.string.isRequired,
    handleChange: PropTypes.func,
    location: PropTypes.object,
    options: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })).isRequired,
  }

  static contextTypes = {
    router: PropTypes.object,
  }

  static defaultProps = {
    context: null,
    handleChange: null,
    location: null,
  }

  render() {
    const { currentSelection, options, handleChange } = this.props
    const onBlur = () => { return true }
    const selectInputProps = {
      handleChange,
      label: '',
      name: 'bannerSelect',
      onBlur,
      options,
      value: currentSelection,
    }

    return (
      <div className={styles.base}>
        <SelectInput {...selectInputProps} />
      </div>
    )
  }
}

export default BannerSelect
