import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

export default function useUrl() {
  const location = useLocation()

  const search = queryString.parse(location.search)
  const hash = queryString.parse(location.hash)

  return {
    search,
    hash,
  }
}
