/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconReports = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--reports',
    className,
  )

  return (
    <IconBase className={classes} viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg"{...rest}><title>Search</title>
    <path d="M30 5C16.2 5 5 16.2 5 30C5 43.8 16.2 55 30 55C43.8 55 55 43.8 55 30C55 16.2 43.8 5 30 5Z" fill="white"/>
    <path d="M30 5C16.2 5 5 16.2 5 30C5 43.8 16.2 55 30 55C43.8 55 55 43.8 55 30C55 16.2 43.8 5 30 5Z" fill="white"/>
    <path d="M30 4C15.6477 4 4 15.6477 4 30H6C6 16.7523 16.7523 6 30 6V4ZM4 30C4 44.3523 15.6477 56 30 56V54C16.7523 54 6 43.2477 6 30H4ZM30 56C44.3523 56 56 44.3523 56 30H54C54 43.2477 43.2477 54 30 54V56ZM56 30C56 15.6477 44.3523 4 30 4V6C43.2477 6 54 16.7523 54 30H56Z" fill="#26890D"/>
    <path d="M33.9184 28.0818L31 26.7502L33.9184 25.4185L35.25 22.5002L36.5817 25.4185L39.5 26.7502L36.5817 28.0818L35.25 31.0002L33.9184 28.0818ZM19.6667 33.8335L20.9984 30.9152L23.9167 29.5835L20.9984 28.2518L19.6667 25.3335L18.335 28.2518L15.4167 29.5835L18.335 30.9152L19.6667 33.8335ZM26.0417 26.7502L27.5859 23.336L31 21.7918L27.5859 20.2477L26.0417 16.8335L24.4975 20.2477L21.0834 21.7918L24.4975 23.336L26.0417 26.7502ZM20.375 43.0418L28.875 34.5277L34.5417 40.1943L46.5834 26.651L44.5859 24.6535L34.5417 35.9443L28.875 30.2777L18.25 40.9168L20.375 43.0418Z" fill="#26890D"/>
</IconBase>
  )
}

IconReports.propTypes = {
  className: PropTypes.string,
}

export default IconReports
/* eslint-enable */
