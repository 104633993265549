import React from 'react'
import PropTypes from 'prop-types'
import { Button, Heading } from '@deloitte/gel-library'
import Avatar from 'components/Avatar'
import Card from 'components/Card'
import styles from './GenAiCard.scss'

const propTypes = {
  appId: PropTypes.string,
  createdByUser: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    surname: PropTypes.string,
  }),
  handleEditClick: PropTypes.func,
  isCustomApp: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  templateDescription: PropTypes.string.isRequired,
  templateName: PropTypes.string.isRequired,
}

const GenAiCard = ({
  templateName, templateDescription, onClick, isCustomApp, appId, handleEditClick,
  createdByUser,
}) => {
  return (
    <Card className={styles.genAiCard} onClick={onClick}>
      <div className={styles.headingContainer}>
        <div className={styles.titleContainer}>
          <Heading className={styles.heading} level={6}>{templateName}</Heading>
        </div>
        <div className={styles.avatarContainer}>
          {createdByUser && <Avatar className={styles.avatar} size="small" userDetails={createdByUser} />}
        </div>
      </div>
      <div className={styles.descriptionContainer}>
        <p className={styles.description}>
          {templateDescription}
        </p>
      </div>
      <div className={styles.cardFooter}>
        {isCustomApp
          && (
            <Button
              mode="text"
              onClick={e => handleEditClick(appId, e)}
            >
              Edit
            </Button>
          )}
      </div>
    </Card>
  )
}

GenAiCard.propTypes = propTypes
GenAiCard.defaultProps = {
  appId: null,
  handleEditClick: () => { },
  isCustomApp: false,
  createdByUser: {},
}

export default GenAiCard
