import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './TagDescription.scss'

const propTypes = {
  className: PropTypes.object,
  descriptionValue: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onDescriptionValueChange: PropTypes.func.isRequired,
}

const defaultProps = {
  className: null,
}

function TagDescription({
  className, descriptionValue, disabled, onDescriptionValueChange,
}) {
  // TODO: Pull these from solution
  const options = []

  return (
    <div className={classNames(styles.container, className)}>
      <input
        className={classNames(styles.description, !disabled && styles.enabled)}
        disabled={disabled}
        list="html_elements"
        name="web_language"
        onChange={event => onDescriptionValueChange(event.target.value)}
        placeholder="No description"
        value={descriptionValue}
      />
      <datalist className={styles.dropdownList} id="html_elements">
        {options.map((option) => {
          return (
            <option value={option}> </option>
          )
        })}
      </datalist>
    </div>
  )
}

TagDescription.propTypes = propTypes
TagDescription.defaultProps = defaultProps

export default TagDescription
