import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import Option from './Option'

const styles = require('./FileCard.scss')

const menuRenderer = ({
  focusedOption,
  focusOption,
  inputValue,
  instancePrefix,
  onFocus,
  onOptionRef,
  onSelect,
  optionClassName,
  optionRenderer,
  options,
  removeValue,
  selectValue,
  valueArray,
  valueKey,
}) => {
  return options.map((option, i) => {
    const isSelected = valueArray && valueArray.some(x => x[valueKey] === option[valueKey])
    const isFocused = option === focusedOption
    const optionClass = classNames(optionClassName, {
      'Select-option': true,
      'is-selected': isSelected,
      'is-focused': isFocused,
      'is-disabled': option.disabled,
    })

    return (
      <Option
        className={optionClass}
        focusOption={focusOption}
        inputValue={inputValue}
        instancePrefix={instancePrefix}
        isDisabled={option.disabled}
        isFocused={isFocused}
        isSelected={isSelected}
        key={option.value}
        onFocus={onFocus}
        onSelect={onSelect}
        option={option}
        optionIndex={i}
        ref={(ref) => {
          onOptionRef(ref, isFocused)
        }}
        removeValue={removeValue}
        selectValue={selectValue}
      >
        <div className={styles.optionWarpper}>
          {optionRenderer(option, i, inputValue)}
          {option.value !== '(not mapped)' && (
            <span>{option.isMandatory ? 'Mandatory' : 'Optional'}</span>
          )}
        </div>
      </Option>
    )
  })
}

menuRenderer.propTypes = {
  focusedOption: PropTypes.object,
  focusOption: PropTypes.func,
  inputValue: PropTypes.string,
  instancePrefix: PropTypes.string,
  onFocus: PropTypes.func,
  onOptionRef: PropTypes.func,
  onSelect: PropTypes.func,
  optionClassName: PropTypes.string,
  optionComponent: PropTypes.func,
  optionRenderer: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      isMandatory: PropTypes.bool,
    }),
  ),
  removeValue: PropTypes.func,
  selectValue: PropTypes.func,
  valueArray: PropTypes.arrayOf(PropTypes.object),
  valueKey: PropTypes.string,
}

export default menuRenderer
