import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Card from 'components/Card'
import { Heading, Button } from '@deloitte/gel-library'
import { createJobActions, fetchActions } from 'actions/job'
import actionHubTargetImg from 'images/action-hub-target.svg'
import { userPropType } from 'utils/propTypes'
import LoadingBox from 'components/LoadingBox'
import { jobIsProcessing } from 'utils/business/workflow'
import { SUPPORT_PAGES } from 'constants/supportPages'
import { Link } from 'react-router-dom'
import Messagebar from 'components/Messagebar'
import { fetchClientActions } from 'actions/client'
import { roles } from 'utils/business/jobs'
import { useDispatch, useSelector } from 'react-redux'
import ActionGroup from 'components/ActionGroup'
import styles from './CurrentActions.scss'

const propTypes = {
  job: PropTypes.shape({
    _fetchingActionsError: PropTypes.string,
    _isCreatingActions: PropTypes.bool,
    _isFetchedActions: PropTypes.bool,
    _isFetchingActions: PropTypes.bool,
    actions: PropTypes.shape({
      _hasFetchedAllActions: PropTypes.bool,
      currentActions: PropTypes.arrayOf(PropTypes.object),
      potentialActions: PropTypes.arrayOf(PropTypes.object),
      previousActions: PropTypes.arrayOf(PropTypes.object),
    }),
    jobId: PropTypes.string,
    lastExecution: PropTypes.object,
    member: PropTypes.object,
    members: PropTypes.arrayOf(userPropType),
  }).isRequired,
  onEditData: PropTypes.func,
  uploaders: PropTypes.shape({
    uploaders: PropTypes.object,
  }).isRequired,
  user: PropTypes.shape({
    _isFetched: PropTypes.bool,
    isExternal: PropTypes.bool,
  }).isRequired,
  validations: PropTypes.shape({
    validations: PropTypes.object,
  }).isRequired,
}

function CurrentActions({
  user: { isExternal, _isFetched }, onEditData, job,
}) {
  const dispatch = useDispatch()

  const {
    jobId,
    actions,
    _isFetchedActions,
    _isFetchingActions,
    _isCreatingActions,
    _fetchingActionsError,
    lastExecution,
    member,
    members,
  } = job
  const isProcessing = jobIsProcessing(job)
  const currentUser = useSelector(state => state.app.user)

  const userJobRole = (member && member.role?.name)
  || (members && members.find(m => m.id === currentUser.id)?.role?.name)
  || null

  const isActionEditor = !isExternal || (userJobRole === roles.JobExternalEditor || userJobRole === roles.JobExternalActionEditor)

  useEffect(() => {
    if (jobId && _isFetched) {
      if (isExternal && !isActionEditor) {
        dispatch(fetchClientActions(jobId))
      } else {
        dispatch(fetchActions({ jobId }))
      }
    }
  }, [dispatch, jobId, isExternal, _isFetched, isActionEditor])

  const { potentialActions, currentActions } = (actions || {})

  const showCurrentActions = currentActions && currentActions.length > 0

  const showPotentialActions = potentialActions
    && !isProcessing
    && !showCurrentActions
    && lastExecution

  const potentialActionSum = showPotentialActions && potentialActions
    .map(x => x.totalActions)
    .reduce((a, b) => a + b, 0)

  const createActions = () => {
    dispatch(createJobActions({ jobId: job.jobId }))
  }

  const canRenderActions = _isFetchedActions && !_fetchingActionsError && actions && actions._hasFetchedAllActions

  return (
    <div className={styles.base}>
      <Heading level={8}>
        {isExternal ? 'Actions' : 'Insight to action management'}
      </Heading>

      {!_isFetchedActions && _isFetchingActions && <LoadingBox className={styles.loading} />}

      {_fetchingActionsError && <p>There was an error loading the actions for your job. Please try again later.</p>}

      {canRenderActions && (
        <>
          {!isExternal && (
          <Messagebar type="info">
            You can now view your detailed insights and document findings within Action Management.
            {' '}
            <Link to={SUPPORT_PAGES.ACTION_HUB}>Learn more</Link>
          </Messagebar>
          )}

          {showPotentialActions && lastExecution.isCurrentPackageVersion && potentialActionSum > 0 && (
          <Card className={styles.createBox}>
            <img alt="Action Hub" className={styles.icon} src={actionHubTargetImg} />
            <div>
              <Heading className={styles.createHeading} level={8}>Your new actions are ready! Get started by creating them.</Heading>
              <p>
                {`${potentialActionSum} actions available`}
              </p>
            </div>

            <Button
              className={styles.createButton}
              disabled={_isCreatingActions}
              onClick={createActions}
            >
              Create all
            </Button>
          </Card>
          )}

          {showPotentialActions && lastExecution.isCurrentPackageVersion && potentialActionSum === 0 && (
          <Card className={styles.noActionBox}>
            <Heading className={styles.createHeading} level={8}>You have no actions</Heading>
            <p>
              Based on your selected analyses applied to data
              <br />
              processed. Try
              {' '}
              <a onClick={onEditData}>uploading new data</a>
              .
            </p>
          </Card>
          )}

          {showPotentialActions && !lastExecution.isCurrentPackageVersion && (
          <Card className={styles.noActionBox}>
            <Heading className={styles.createHeading} level={8}>This solution has been updated</Heading>
            <p>
              Please
              {' '}
              <a onClick={onEditData}>run this job again</a>
              <br />
              in order to use it with Action Hub.
            </p>
          </Card>
          )}

          {showCurrentActions && (
            <ActionGroup
              actions={currentActions}
              canEdit={isActionEditor}
              job={job}
              showHeader={showPotentialActions && showCurrentActions}
            />
          )}
        </>
      )}

    </div>

  )
}

CurrentActions.defaultProps = {
  onEditData: null,
}
CurrentActions.propTypes = propTypes

export default CurrentActions

