import React, { useState, useEffect, useCallback } from 'react'
import {
  Heading,
  DataTableSimple,
  Button,
  Searchbar,
} from '@deloitte/gel-library'
import constants from 'constants'
import Avatar from 'components/Avatar'
import Card from 'components/Card'
import TagList from 'components/TagList'
import SearchFilter from 'components/SearchFilter'
import { sortByFunction } from 'utils/arrays'
import { formatDateFromStringShort } from 'utils/dates'
import IconAdd from '@material-ui/icons/Add'
import { useDispatch, useSelector } from 'react-redux'
import { modalCreate, modalShow } from 'actions/modals'
import { objectToArray } from 'utils/objects'
import { adminFetchRoles, adminFetchUsers, adminSelectUser } from 'actions/admin'
import BlockSpinner from 'components/BlockSpinner'
import UserPermissionsModal from './UserPermissionsModal'
import styles from './UserManagement.scss'

const userMatchesSearch = (user, query) => {
  const lowerQuery = query.toLowerCase()
  const firstNameMatch = user.details.firstName && user.details.firstName.toLowerCase().includes(lowerQuery)
  const lastNameMatch = user.details.surname && user.details.surname.toLowerCase().includes(lowerQuery)
  const emailMatch = user.details.email && user.details.email.toLowerCase().includes(lowerQuery)
  return firstNameMatch || lastNameMatch || emailMatch
}

const allPermissionsLabel = 'All permissions'

const UserManagement = () => {
  const dispatch = useDispatch()

  const {
    _isFetchingUsers,
    _isFetchedUsers,
    _isFetchingRoles,
    _isFetchedRoles,
    users,
    userIds,
    roles,
  } = useSelector(state => state.admin.userAdmin)

  const modals = useSelector(state => state.modals)

  useEffect(() => {
    if (!_isFetchedRoles) {
      dispatch(adminFetchRoles())
    }
  }, [_isFetchedRoles, dispatch])

  useEffect(() => {
    if (!_isFetchedUsers) {
      dispatch(adminFetchUsers())
    }
  }, [_isFetchedUsers, dispatch])

  const [roleFilter, setRoleFilter] = useState(allPermissionsLabel)
  const [userSearch, setUserSearch] = useState('')
  const MODAL_ID = constants.MODAL_USER_PERMISSIONS

  const loaded = !_isFetchingRoles && !_isFetchingUsers && _isFetchedRoles && _isFetchedUsers

  const handleOpenModal = useCallback((isEdit, user) => {
    if (isEdit) {
      dispatch(adminSelectUser(user))
    }

    if (modals && !modals.modalNames.includes(MODAL_ID)) {
      dispatch(modalCreate(MODAL_ID))
    }

    dispatch(modalShow(constants.MODAL_USER_PERMISSIONS))
  }, [MODAL_ID, dispatch, modals])

  const userTableColumns = [
    {
      heading: 'Name',
      cellExtractor: user => (
        <div className={styles.avatarContainer}>
          <Avatar nameArray={[user.details.firstName, user.details.surname]} size="medium" />
          <div className={styles.avatarDetails}>
            <div>{`${user.details.firstName} ${user.details.surname}`}</div>
            <div className={styles.secondaryDetail}>{user.details.username}</div>
          </div>
        </div>
      ),
      key: 'name',
      className: styles.nameColumn,
    },
    {
      heading: 'Details',
      cellExtractor: user => (
        <div>
          <div>{user.details.title || 'Unknown role'}</div>
          <div className={styles.secondaryDetail}>{`${user.details.businessUnit.description || 'Unknown BU'} | ${user.details.country || 'Unknown country'}`}</div>
        </div>
      ),
      key: 'location',
    },
    {
      heading: 'Last updated',
      cellExtractor: user => (
        <div>
          <div>{formatDateFromStringShort(user.rolesUpdatedAt)}</div>
          <div className={styles.secondaryDetail}>{user.rolesUpdatedBy}</div>
        </div>
      ),
      key: 'lastupdated',
    },
    {
      heading: 'Permissions',
      cellExtractor: user => (
        <TagList tags={loaded
          ? user.roles
            .filter(ur => ur in roles)
            .map(ur => roles[ur].description)
          : []}
        />
      ),
      key: 'permissions',
      className: styles.permissionsColumn,
    },
    {
      heading: '',
      cellExtractor: user => (
        <div className={styles.actionButtons}>
          <Button
            mode="text"
            onClick={() => {
              handleOpenModal(true, user)
            }}
          >
            EDIT
          </Button>
        </div>
      ),
      key: 'actions',
      className: styles.actionColumn,
    },
  ]

  const filteredUsers = loaded
    ? userIds.map(uid => users[uid])
      .filter(u => (u.roles.filter(ur => ur in roles && roles[ur].description === roleFilter).length > 0 || roleFilter === allPermissionsLabel)
        && (userMatchesSearch(u, userSearch) || userSearch.length === 0))
    : []

  const allRoles = [
    {
      id: -1,
      description: allPermissionsLabel,
    },
    ...objectToArray(roles),
  ]

  return (
    <div className={styles.base}>
      <div className={styles.headerContainer}>
        <Heading level={8}>Elevated users</Heading>
        <div className={styles.addUser}>
          <Button
            icon={<IconAdd />}
            onClick={() => {
              handleOpenModal(false)
            }}
          >
            Add elevated user
          </Button>
        </div>
      </div>

      {loaded ? (
        <div className={styles.usersContainer}>

          <div className={styles.filterBar}>
            <Searchbar
              mini
              className={styles.search}
              onSearch={value => setUserSearch(value)}
              placeholder="Search users"
              value={userSearch}
            />
            <div className={styles.filter}>
              <div>Filter by:</div>
              <SearchFilter
                handleSubmit={o => setRoleFilter(o)}
                id="role-filter"
                options={allRoles.map(r => ({ label: r.description, value: r.id }) || [])}
                text={roleFilter}
              />
            </div>
          </div>

          <Card noPadding>
            <DataTableSimple
              columns={userTableColumns}
              data={filteredUsers}
              keyExtractor={u => u.id}
              sortFunction={sortByFunction(u => u.details.firstName || 'z')}
            />
          </Card>
        </div>
      )
        : (
          <BlockSpinner marginTop={100} text="Loading users" />
        )}

      {modals.modalNames.includes(MODAL_ID) && <UserPermissionsModal />}

    </div>
  )
}

export default UserManagement
