import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Banner from 'components/Banner'
import { sendContactForm } from 'actions/support'
import ContactForm from 'views/ContactForm/ContactForm'
import { hot } from 'react-hot-loader/root'
import queryString from 'query-string'

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  forms: PropTypes.object.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  user: PropTypes.shape({
    _isFetched: PropTypes.bool,
    firstName: PropTypes.string,
    isExternal: PropTypes.bool,
  }).isRequired,
}

const Contact = ({
  forms, user,
  dispatch,
  location: { search },
}) => {
  const { jobRefNo } = queryString.parse(search)

  const { isExternal, _isFetched: userIsFetched } = user

  const userWelcome = userIsFetched ? `Hi ${user.firstName}, how can we help?` : ''

  const handleSentContactForm = () => {
    dispatch(sendContactForm())
  }

  return (
    <div>
      <Banner name={userWelcome} />
      <ContactForm
        dispatch={dispatch}
        forms={forms}
        isExternal={isExternal}
        jobRefNo={jobRefNo}
        onSubmit={handleSentContactForm}
      />
    </div>
  )
}

Contact.propTypes = propTypes

const mapStateToProps = ({
  app: { user },
  forms,
}) => {
  return {
    forms,
    user,
  }
}

export default hot(connect(mapStateToProps)(Contact))
