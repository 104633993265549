// Dependencies
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Container } from 'components/layout/Grid/'
import { getConfig } from 'utils/config'
import { Link } from 'react-router-dom'
import { Button } from '@deloitte/gel-library'
import classNames from 'classnames'
import styles from './Footer.scss'

export default class Footer extends Component {
  static propTypes = {
    user: PropTypes.shape({
      isAdmin: PropTypes.bool,
    }).isRequired,
  }

  render() {
    const { user: { isAdmin } } = this.props
    const { VERSION } = getConfig()
    const simpleVersionString = VERSION ? VERSION.split('.').slice(0, 2).join('.') : null
    const versionString = isAdmin ? VERSION : simpleVersionString

    return (
      <footer className={styles.footer} id="footer">
        <Container>
          <p>
            <span>{`© ${new Date().getFullYear()} Deloitte Touche Tohmatsu`}</span>
            |
            <a
              href="https://www2.deloitte.com/au/en/footerlinks1/privacy.html?icid=bottom_privacy"
              rel="noopener noreferrer"
              target="_blank"
            >
              Privacy Policy
            </a>
            <Fragment>
              |
              <Link to="/terms-of-use">
                Terms of Use
              </Link>
            </Fragment>
            <Fragment>
              |
              <a href className={classNames('ot-sdk-show-settings', styles.cookies)} id="ot-sdk-btn">
                Cookie Settings
              </a>
            </Fragment>
            {versionString && (
              <Fragment>
                |
                <span>
                  {`Version ${versionString}`}
                </span>
              </Fragment>
            )}
            <br />
            <br />
            Deloitte refers to one or more of Deloitte Touche Tohmatsu Limited,
            a UK private company limited by guarantee (“DTTL”), its network of member firms,
            and their related entities. DTTL and each of its member firms are legally separate
            and independent entities. DTTL (also referred to as “Deloitte Global”) does not provide
            services to clients. Please see About Deloitte for a detailed description of DTTL and
            its member firms.
          </p>
        </Container>
      </footer>
    )
  }
}
