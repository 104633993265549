import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import { articlePropType } from 'utils/propTypes'
import Container from 'components/layout/Grid/Container'
import LinkRenderer from 'components/LinkRenderer'
import { Heading } from '@deloitte/gel-library'
import { Link } from 'react-router-dom'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { urlifyString } from 'utils/strings'
import styles from './Article.scss'

const propTypes = {
  articles: PropTypes.arrayOf(articlePropType).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      urlSlug: PropTypes.string,
    }),
  }).isRequired,
}

function Article({ articles, match: { params: { urlSlug } } }) {
  const article = articles.find(a => a.urlSlug === urlSlug)

  if (!article) {
    return (
      <Container className={styles.base}>
        <div className="row">
          <div className="col-md-8">
            <div className={styles.notFound}>
              Article not found. Please return to
              {' '}
              <Link to="/support">Support</Link>
            </div>
          </div>
        </div>
      </Container>
    )
  }

  return (
    <Container className={styles.base}>
      <div className="row">
        <div className="col-md-8">
          <div className={styles.breadcrumbs}>
            <Link to="/support">Support</Link>
            <ChevronRightIcon />
            <Link to={`/support/${urlifyString(article.section)}`}>{article.section}</Link>
            <ChevronRightIcon />
            {article.name}
          </div>
          <Heading className={styles.heading} level={5}>{article.name}</Heading>
          <ReactMarkdown
            className={styles.text}
            renderers={{
              link: LinkRenderer,
            }}
            source={article.description}
          />

        </div>
      </div>
    </Container>
  )
}

Article.propTypes = propTypes

export default Article

