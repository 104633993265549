/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconYearEndManagementReporitng = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--yearendmanagementreporitng',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"{...rest}><title>Fill 1</title><g fill="#58595B"><path className="path1" d="M21.5 8.9l-3.4-3.4-2.4-2.4L13.3.7C13 .3 12.2 0 11.6 0H3.2c-.8 0-1.5.7-1.5 1.5v16.3c0 .8.7 1.5 1.5 1.5h.9v.9c0 .8.7 1.5 1.5 1.5h.9v.9c0 .8.7 1.5 1.5 1.5h12.9c.8 0 1.5-.7 1.5-1.5V10.7c-.1-.6-.4-1.4-.9-1.8zM17.8 7L20 9.2h-2.2V7zM3.2 17.9c-.1 0-.1-.1-.1-.1V1.5c0-.1.1-.1.1-.1h8.4c.3 0 .7.2.9.4l.7.7H5.6c-.8-.1-1.5.5-1.5 1.3v14.1h-.9zm2.4 2.4c-.1 0-.1-.1-.1-.1V3.8c0-.1.1-.1.1-.1H14c.3 0 .7.2.9.4l.7.7H7.9c-.8 0-1.5.7-1.5 1.5v14.1h-.8zm15.3 2.2c0 .1-.1.1-.1.1H7.9c-.1 0-.1-.1-.1-.1V6.2c0-.1.1-.1.1-.1h8.5v3.8c0 .4.3.7.7.7h3.8v11.9z"/><path className="path2" d="M17.8 12.7H11c-.2 0-.4.2-.4.4s.2.4.4.4h6.8c.2 0 .4-.2.4-.4s-.2-.4-.4-.4zM17.8 15H11c-.2 0-.4.2-.4.4s.2.4.4.4h6.8c.2 0 .4-.2.4-.4s-.2-.4-.4-.4zM17.8 17.3H11c-.2 0-.4.2-.4.4s.2.4.4.4h6.8c.2 0 .4-.2.4-.4s-.2-.4-.4-.4zM17.8 19.6H11c-.2 0-.4.2-.4.4s.2.4.4.4h6.8c.2 0 .4-.2.4-.4s-.2-.4-.4-.4z"/></g></IconBase>
  )
}

IconYearEndManagementReporitng.propTypes = {
  className: PropTypes.string,
}

export default IconYearEndManagementReporitng
/* eslint-enable */
