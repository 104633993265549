import React from 'react'
import { hot } from 'react-hot-loader/root'
import SolutionsView from 'views/SolutionsView'
import useApp from 'hooks/useApp'
import usePackages from 'hooks/usePackages'

function Solutions() {
  const {
    collections, packages, packageIds, goToSolution,
  } = usePackages()
  const { user } = useApp()

  return (
    <SolutionsView
      collections={collections}
      goToSolution={goToSolution}
      solutions={packageIds.map(id => packages[id])}
      user={user}
    />
  )
}

export default hot(Solutions)
