/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconBookkeeperProcessReview = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--bookkeeperprocessreview',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"{...rest}><title>Fill 1</title><g fill="#58595B"><path className="path1" d="M4.6 9.9h7.9c.2 0 .3-.2.3-.3s-.2-.3-.3-.3H4.6c-.2 0-.3.2-.3.3s.1.3.3.3zM4.6 12.2h7.9c.2 0 .3-.2.3-.3 0-.2-.2-.3-.3-.3H4.6c-.2 0-.3.2-.3.3 0 .1.1.3.3.3zM9.8 13.8H4.6c-.2 0-.3.2-.3.3 0 .2.2.3.3.3h5.2c.2 0 .3-.2.3-.3.1-.1-.1-.3-.3-.3z"/><path className="path2" d="M24 9.7c0-.4-.2-.8-.5-1.1l-1-1c-.3-.4-.7-.6-1.1-.6s-.8.2-1.1.5l-3.1 3.1V6.9c0-.5-.3-1.2-.7-1.6l-3.1-3.1c-.4-.4-1.1-.7-1.6-.7H1.3C.6 1.6 0 2.1 0 2.8v18.3c0 .7.6 1.3 1.3 1.3h14.5c.7 0 1.3-.6 1.3-1.3v-4l6.4-6.4c.4-.2.5-.6.5-1zm-10.9 9l-.8-.8.4-1.2 1.6 1.6-1.2.4zm2.1-.9l-2-2 5.9-5.9 2 2-5.9 5.9zm-.1-12.1H13V3.5l2.1 2.2zm.9 15.5c0 .1-.1.1-.1.1H1.3c-.1 0-.1-.1-.1-.1V2.8c0-.1.1-.1.1-.1h10.5v3.5c0 .3.3.6.6.6h3.5v5l-3.5 3.5c-.1.1-.3.4-.3.6l-1.2 3.7c-.1.2 0 .3.1.5.1.1.2.1.3.1h.1l3.7-1.2.6-.3.3-.3v2.8zm6.9-11.1l-1.1 1.1-2-2 1.1-1.1c.3-.3.7-.3 1 0l1 1c.1.1.2.3.2.5s-.1.4-.2.5z"/></g></IconBase>
  )
}

IconBookkeeperProcessReview.propTypes = {
  className: PropTypes.string,
}

export default IconBookkeeperProcessReview
/* eslint-enable */
