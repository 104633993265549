import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { HashLink as Link } from 'react-router-hash-link'
import IconOpenInNew from '@material-ui/icons/OpenInNew'
import styles from './LinkRenderer.scss'

const propTypes = {
  children: PropTypes.node,
  dispatch: PropTypes.func.isRequired,
  href: PropTypes.string.isRequired,
}

const defaultProps = {
  children: null,
}

function LinkRenderer(props) {
  const {
    href, children, dispatch, ...rest
  } = props

  if (href.startsWith('http')) {
    return (
      <a className={styles.link} href={href} rel="noopener noreferrer" target="_blank" {...rest}>
        {children}
        <IconOpenInNew fontSize="inherit" />
      </a>
    )
  }

  return (
    <Link className={styles.link} to={href} {...rest}>
      {children}
    </Link>
  )
}

LinkRenderer.propTypes = propTypes
LinkRenderer.defaultProps = defaultProps

export default connect()(LinkRenderer)
