import PropTypes from 'prop-types'
import DialogSimple from 'components/DialogSimple'
import IconAlert from 'icons/IconAlert'
import React from 'react'
import {
  Heading,
} from '@deloitte/gel-library'
import styles from './FileGroupDialog.scss'

const FileGroupDialog = (
  {
    actionLabel, dismissLabel, text, onAction, onDismiss, heading,
  },
) => {
  return (
    <DialogSimple
      isOpen
      actionLabel={actionLabel}
      dismissLabel={dismissLabel}
      icon={<IconAlert height={50} width={50} />}
      onAction={onAction}
      onDismiss={onDismiss}
    >
      <div className={styles.base}>
        <Heading level={8}>
          {heading}
        </Heading>
        <p>{text}</p>
      </div>
    </DialogSimple>
  )
}

FileGroupDialog.propTypes = {
  actionLabel: PropTypes.string.isRequired,
  dismissLabel: PropTypes.string,
  heading: PropTypes.string.isRequired,
  onAction: PropTypes.func.isRequired,
  onDismiss: PropTypes.func,
  text: PropTypes.string.isRequired,
}

FileGroupDialog.defaultProps = {
  dismissLabel: null,
  onDismiss: null,
}

export default FileGroupDialog
