import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { IconAdd } from 'icons'
import styles from './ExpandingActionButton.scss'

const ExpandingActionButton = ({ className, icon, actions }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const classes = {
    [styles.expanded]: isExpanded,
  }

  const handleClick = (e) => {
    e.preventDefault()
    setIsExpanded(!isExpanded)
  }

  return (
    <div className={classnames(styles.base, classes, className)}>
      { isExpanded && (
      <ul className={styles.actionList}>
        {actions.map((a, i) => (
          <li
            className={styles.item}
            key={a.label}
            style={{ top: `${(i + 1) * 66}px` }}
          >
            <a
              className={styles.button}
              onClick={() => {
                a.onAction()
                setIsExpanded(false)
              }}
            >
              <span>{a.label}</span>
              {a.icon}
            </a>
          </li>
        ))}

      </ul>
      )}

      <a
        className={styles.button}
        onClick={handleClick}
      >
        {icon}
      </a>
      <div className={styles.overlay} onClick={() => setIsExpanded(false)} />
    </div>
  )
}

ExpandingActionButton.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.node,
    label: PropTypes.string,
    onAction: PropTypes.func,
  })).isRequired,
  className: PropTypes.string,
  icon: PropTypes.node,
}

ExpandingActionButton.defaultProps = {
  className: null,
  icon: <IconAdd
    className={styles.add}
    height="16"
    width="16"
  />,
}

export default ExpandingActionButton
