// Utilities for parsing API response errors

const getNextString = (string) => {
  const index = string.indexOf('.')
  return {
    current: index === -1 ? string : string.substring(0, index),
    next: index === -1 ? null : string.substring(index + 1),
  }
}

const makeObjectFromErrorString = (string, message) => {
  const { current, next } = getNextString(string)

  const arrayRegex = /(.*)\[(\d*)\]/
  const result = current.match(arrayRegex)

  if (result) {
    return {
      name: result[1],
      index: +result[2],
      message: next ? null : message,
      inner: next ? makeObjectFromErrorString(next, message) : null,
    }
  }

  return {
    name: string,
    index: null,
    message: next ? null : message,
    inner: next ? makeObjectFromErrorString(next, message) : null,
  }
}

export const extractResponseParameters = (parameters) => {
  const extractedParams = []
  parameters.forEach(({ name, message }) => {
    extractedParams.push(makeObjectFromErrorString(name, message))
  })
  return extractedParams
}
