/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconYearEndReporting = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--yearendreporting',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18"{...rest}><g fill="#58595B"><path className="path1" d="M16.4 6.7l-2.5-2.5-1.8-1.8L10.3.6C9.9.2 9.4 0 8.9 0H2.6C2 0 1.5.5 1.5 1.1v12.2c0 .6.5 1.1 1.1 1.1h.7v.7c0 .6.5 1.1 1.1 1.1h.7v.7c0 .6.5 1.1 1.1 1.1h9.7c.6 0 1.1-.5 1.1-1.1V8c-.1-.4-.3-1-.6-1.3zm-2.8-1.4l1.6 1.6h-1.6V5.3zm-11 8.1c-.1 0-.1 0 0 0L2.5 1.1c0-.1 0-.1.1-.1h6.3c.2 0 .5.1.7.3l.5.5H4.4c-.6 0-1.1.5-1.1 1.1v10.5h-.7zm1.8 1.8c-.1 0-.1 0 0 0L4.3 2.9c0-.1 0-.1.1-.1h6.3c.2 0 .5.1.7.3l.5.5H6.2c-.6 0-1.1.5-1.1 1.1v10.5h-.7zm11.5 1.7c0 .1 0 .1 0 0l-9.7.1c-.1 0-.1 0-.1-.1V4.7c0-.1 0-.1.1-.1H12.6v2.8c0 .3.2.5.5.5h2.8v9z"/><path className="path2" d="M13.5 9.5h-5c-.2 0-.3.1-.3.3 0 .2.1.3.3.3h5.1c.2 0 .3-.1.3-.3-.1-.2-.2-.3-.4-.3zM13.5 11.2h-5c-.2 0-.3.1-.3.3s.1.3.3.3h5.1c.2 0 .3-.1.3-.3s-.2-.3-.4-.3zM13.5 13h-5c-.2 0-.3.1-.3.3 0 .2.1.3.3.3h5.1c.2 0 .3-.1.3-.3-.1-.2-.2-.3-.4-.3zM13.5 14.7h-5c-.2 0-.3.1-.3.3 0 .2.1.3.3.3h5.1c.2 0 .3-.1.3-.3-.1-.1-.2-.3-.4-.3z"/></g></IconBase>
  )
}

IconYearEndReporting.propTypes = {
  className: PropTypes.string,
}

export default IconYearEndReporting
/* eslint-enable */
