import React, { useState, useEffect } from 'react'
import {
  Button, ChipInput,
} from '@deloitte/gel-library'
import { createTheme } from '@material-ui/core/styles'
import {
  Card, Divider, MenuItem, ThemeProvider,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { searchMembersByQuery, updateTeamMembers } from 'actions/job'
import { useDispatch } from 'react-redux'
import Avatar from 'components/Avatar'
import Spinner from 'components/Spinner'
import classNames from 'classnames'
import styles from './DeloitteMemberCard.scss'

const SUGGESTED_USERS_THRESHOLD = 20
const FETCH_AVATAR_IMAGE_THRESHOLD = 6

const suggestedUsertheme = createTheme({
  overrides: {
    MuiCard: {
      root: {
        overflow: 'scroll',
        position: 'absolute',
        width: '362px',
        top: '120px',
        marginLeft: '16px',
      },
    },
  },
})
const propTypes = {
  closeCard: PropTypes.func.isRequired,
  currentMembers: PropTypes.arrayOf(PropTypes.string).isRequired,
  jobId: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
}

const defaultProps = {

}

const DeloitteMemberCard = ({
  closeCard, currentMembers, jobId, onCancel,
}) => {
  const dispatch = useDispatch()
  const [selectedMembers, setSelectedMembers] = useState([])
  const [searchLabel, setSearchLabel] = useState('')
  const [searchTimeoutId, setTimeoutId] = useState(-1)
  const [suggestedUsers, setSuggestedUsers] = useState([])
  const [isSearching, setIsSearching] = useState(false)

  const handleChange = (value) => {
    if (value.length === 0) {
      setSuggestedUsers([])
    }
    if (value.length >= 3) {
      clearTimeout(searchTimeoutId)
      setIsSearching(true)
      setTimeoutId(setTimeout(() => dispatch(searchMembersByQuery(value, setSuggestedUsers)), 400))
    }
    setIsSearching(true)
    setSearchLabel(value)
  }

  const handleSelect = (displayName, email) => {
    setSelectedMembers([
      ...selectedMembers,
      { displayName, email },
    ])
    setSearchLabel('')
    setIsSearching(false)
    setSuggestedUsers([])
  }

  const suggestedUsersOptions = suggestedUsers.filter(fsu => !currentMembers.includes(fsu.email))

  useEffect(() => {
    const interval = setInterval(() => {
      setIsSearching(false)
    }, 2000)
    return () => clearInterval(interval)
  }, [])

  const handleAddUser = () => {
    dispatch(updateTeamMembers(jobId, { userName: selectedMembers.map(v => v.email).concat(currentMembers).filter(sme => sme !== null) }))
    closeCard()
  }

  const handleDelete = (e) => {
    setSelectedMembers(selectedMembers.filter(v => v.displayName !== e))
  }

  return (
    <div className={suggestedUsersOptions.length > 0 ? styles.suggestedUsers : null}>
      <div className={classNames(styles.typography)}>
        <div className={classNames(styles.heading, styles.inlinePadding)}>
          Add a Deloitte user
        </div>
        <div className={styles.inlinePadding}>
          <ChipInput
            className={styles.textField}
            inputRef={(component) => { if (component) { component.focus() } }}
            inputValue={searchLabel}
            onDelete={e => handleDelete(e)}
            onUpdateInput={event => handleChange(event.target.value)}
            placeholder="Type name"
            value={selectedMembers.map(v => v.displayName)}
          />
          {isSearching && <Spinner className={styles.loadingSpinner} size="small" />}
        </div>
        <Divider />
        <div className={classNames(styles.flex, styles.inlinePadding)}>
          <div>
            <Button mode="flat" onClick={() => onCancel()}>
              CANCEL
            </Button>
          </div>
          <div>
            <Button disabled={selectedMembers.length === 0} mode="flat" onClick={() => handleAddUser()}>
              ADD USER
            </Button>
          </div>
        </div>
      </div>
      {suggestedUsersOptions.length > 0 && (
        <div>
          <ThemeProvider theme={suggestedUsertheme}>
            <Card className={styles.card}>
              {suggestedUsersOptions.slice(0, SUGGESTED_USERS_THRESHOLD).map((suo, i) => (
                <MenuItem
                  key={suo.email}
                  onClick={() => handleSelect(suo.displayName, suo.email)}
                >
                  <div className={styles.suggestedUsersList}>
                    <div>
                      <Avatar fetchImage={i < FETCH_AVATAR_IMAGE_THRESHOLD} userDetails={suo} />
                    </div>
                    <div className={styles.suggestedUserName}>
                      <div>
                        {suo.displayName}
                      </div>
                      <div className={styles.email}>
                        {suo.email}
                      </div>
                    </div>
                  </div>
                </MenuItem>
              ))}
            </Card>
          </ThemeProvider>
        </div>
      )}
    </div>
  )
}

DeloitteMemberCard.propTypes = propTypes
DeloitteMemberCard.defaultProps = defaultProps

export default DeloitteMemberCard
