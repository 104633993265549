import React from 'react'
import PropTypes from 'prop-types'
import Container from 'components/layout/Grid/Container'
import { Heading, Button } from '@deloitte/gel-library'
import ReactMarkdown from 'react-markdown'
import { signOut } from 'utils/api'
import useApp from 'hooks/useApp'
import styles from './TouView.scss'
import TouLinkRenderer from './TouLinkRenderer'

const propTypes = {
  accept: PropTypes.func.isRequired,
  hasAccepted: PropTypes.bool.isRequired,
  isAccepting: PropTypes.bool.isRequired,
  tou: PropTypes.string.isRequired,
}

function TouView({
  tou, accept, hasAccepted, isAccepting,
}) {
  const { user: { isExternal } } = useApp()

  const requestAcceptance = isExternal && !hasAccepted

  return (
    <Container className={styles.base}>
      <div className="row">
        <div className="col-md-offset-2 col-md-8">
          <Heading level={4}>InsightBox™ Terms of Use</Heading>
          <p className={styles.intro}>
            These Terms of Use apply to your use of InsightBox™.
            {' '}
            {requestAcceptance && (
              <>
                Please review and
                accept these terms to proceed.
              </>
            )}
          </p>

          <div className={styles.touContainer}>
            <ReactMarkdown renderers={{ link: TouLinkRenderer }} source={tou} />
          </div>

          {requestAcceptance ? (
            <div className={styles.buttonRow}>
              <Button
                disabled={isAccepting}
                mode="flat"
                onClick={signOut}
              >
                Back
              </Button>
              <Button disabled={isAccepting} onClick={accept}>
                Agree
              </Button>
            </div>
          ) : <div className={styles.buttonRow} />}
        </div>
      </div>
    </Container>
  )
}

TouView.propTypes = propTypes

export default TouView
