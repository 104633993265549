import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  fetchCurrentActions, fetchJobDetails, fetchReport,
} from 'actions/job'
import ReportView from 'views/Report'
import { push } from 'connected-react-router'
import Loading from 'components/Loading'

const propTypes = {
  analysis: PropTypes.shape({
    id: PropTypes.string,
  }),
  dispatch: PropTypes.func.isRequired,
  fetchJobDetails: PropTypes.func,
  job: PropTypes.shape({
    jobId: PropTypes.string,
  }),
  jobId: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      analysisId: PropTypes.string,
    }),
  }).isRequired,
  report: PropTypes.object,
}

const defaultProps = {
  analysis: null,
  fetchJobDetails: null,
  job: null,
  jobId: null,
  report: null,
}

const Report = (props) => {
  const {
    dispatch,
    job,
    report,
    jobId,
    match: { params: { analysisId } },
  } = props

  useEffect(() => {
    dispatch(fetchJobDetails({ jobId }))
    dispatch(fetchCurrentActions({ jobId }))
  }, [dispatch, jobId])

  useEffect(() => {
    dispatch(fetchReport({ jobId, analysisId }))
  }, [dispatch, jobId, analysisId])

  const handleGoToReport = (destinationAnalysisId) => {
    dispatch(push(`${destinationAnalysisId}`))
  }

  const handleGoToAction = (actionId) => {
    dispatch(push(`/action/${actionId}`))
  }

  if (!job || !report || !report.viewUrl) {
    return <Loading pageLoading />
  }

  return (
    <ReportView
      goToAction={handleGoToAction}
      goToReport={handleGoToReport}
      returnLink={`/job/${job.jobId}/details`}
      {...props}
    />
  )
}

Report.propTypes = propTypes
Report.defaultProps = defaultProps

const mapStateToProps = (
  { job, reportPage, app: { user: { isExternal } } },
  {
    match: {
      params: { jobId, analysisId },
    },
  },
) => {
  if (!job.analyses || !job.reports) {
    return {
      jobId, job, analysis: null, report: null,
    }
  }

  return {
    jobId,
    job,
    analysis: job.analyses.find(a => a.id === analysisId),
    ...reportPage,
    report: job.reports.find(r => r.analysisId === analysisId),
    isExternal,
  }
}

export default connect(
  mapStateToProps,
)(Report)
