export const MODAL_CREATE = 'MODAL_CREATE'
export const MODAL_SHOW = 'MODAL_SHOW'
export const MODAL_HIDE = 'MODAL_HIDE'

export const modalCreate = id => ({
  id,
  type: MODAL_CREATE,
})

export const modalShow = id => ({
  id,
  type: MODAL_SHOW,
})

export const modalHide = id => ({
  id,
  type: MODAL_HIDE,
})
