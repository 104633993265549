/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconTestsRun = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--testsrun',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"{...rest}><defs><path className="path1" d="M0 .078h47.922V48H0z"/></defs><g fill="none" fillRule="evenodd"><mask fill="#fff"><use xlinkHref="#a"/></mask><path className="path2" fill="#86BD24" d="M23.961 2.075c12.111 0 21.964 9.853 21.964 21.964 0 12.111-9.853 21.964-21.964 21.964-12.111 0-21.964-9.853-21.964-21.964 0-12.111 9.853-21.964 21.964-21.964m0-1.997C10.728.078 0 10.806 0 24.038 0 37.273 10.728 48 23.96 48c13.233 0 23.961-10.728 23.961-23.961 0-13.233-10.728-23.961-23.96-23.961" mask="url(#b)"/><path className="path3" fill="#86BD24" d="M38.807 15.238a.996.996 0 0 0-1.408-.094l-3.336 2.918v-4.164a.998.998 0 0 0-.999-.998H12.098a.998.998 0 0 0-.998.998v20.966c0 .553.447.999.998.999h20.966a.998.998 0 0 0 .999-.998V20.716l4.65-4.07a.999.999 0 0 0 .094-1.409zm-6.741 18.628h-18.97v-18.97h18.97v4.914l-9.872 8.637-4.337-5.204a.999.999 0 0 0-1.534 1.279l4.992 5.99.016.018a.994.994 0 0 0 .751.34c.233 0 .468-.08.658-.246l9.326-8.16v11.402z"/></g></IconBase>
  )
}

IconTestsRun.propTypes = {
  className: PropTypes.string,
}

export default IconTestsRun
/* eslint-enable */
