import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Button, Heading } from '@deloitte/gel-library'
import uploadingData from 'images/data-uploading.svg'
import Spinner from 'components/Spinner'
import IconSimpleCheck from 'icons/IconSimpleCheck'
import DialogSimple from 'components/DialogSimple'
import { connect, useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { launchWorkbench } from 'actions/job'
import styles from './DataInPrepPopUp.scss'

const DataInPrepPopUp = () => {
  const { job: { jobId, dataPreparationRequest, isDataReady }, app: { user: { permissions: { workbenchAccess } } } } = useSelector(state => state)
  const dispatch = useDispatch()

  return (
    <DialogSimple
      hideFooter
      className={styles.base}
      isOpen={dataPreparationRequest?.status === 'Requested'}
      onDismiss={() => {}}
    >
      <img alt="Cloud with data uploading to it" height={180} src={uploadingData} width={180} />
      <Heading level={6}>Data preparation request submitted</Heading>
      <p>
        The Support team will be in touch to discuss the request. Check your inbox for correspondence.
        <br />
        Return to here once the data preparation has been completed and you have been notified by the Support Team. If changes to the data provided or to the request is required, please reach out to the Support Team via email.
      </p>

      <div className={styles.progressSpinners}>
        <div className={styles.circle}>
          {dataPreparationRequest?.status === 'Requested' ? <Spinner className={styles.spinner} colour="blue" /> : <IconSimpleCheck className={styles.tick} />}
        </div>
      </div>

      <p className={classnames({ [styles.active]: dataPreparationRequest?.status === 'Requested' })}>
        {dataPreparationRequest?.status === 'Requested' ? 'Data preparation in progress!' : 'done'}
      </p>
      <Button mode="flat" onClick={() => dispatch(push('/jobs/engagements'))}>Back to Jobs</Button>
      <div>
        { workbenchAccess && !isDataReady && (
        <Button
          className={styles.launch}
          mode="secondary"
          onClick={() => dispatch(launchWorkbench(jobId))}
        >
          Launch Workbench
        </Button>
        )}
      </div>
    </DialogSimple>
  )
}

export default DataInPrepPopUp
