import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { setFilters, clearFilters } from 'actions/jobs'
import FilterBar from './FilterBar'

class JobFilters extends Component {
  static propTypes = {
    className: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
    jobs: PropTypes.shape({
      clients: PropTypes.arrayOf(PropTypes.string),
      filters: PropTypes.object,
      packages: PropTypes.arrayOf(PropTypes.string),
      status: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
  }

  static contextTypes = {
    user: PropTypes.object,
  }

  static defaultProps = {
    className: null,
  }

  clearFilters = () => {
    const { dispatch } = this.props
    dispatch(clearFilters())
  }

  filterHandler = (type) => {
    const { dispatch } = this.props
    return (value) => {
      dispatch(setFilters({
        type,
        value,
      }))
    }
  }

  render() {
    const { className, jobs } = this.props

    return (
      <FilterBar
        className={className}
        clearFilters={this.clearFilters}
        clients={jobs.clients}
        filterHandler={this.filterHandler}
        filters={jobs.filters}
        packages={jobs.packages}
        statuses={jobs.status}
      />
    )
  }
}

function mapStateToProps({ jobs }) {
  return {
    jobs,
  }
}

export default connect(mapStateToProps)(JobFilters)
