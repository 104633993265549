/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Table, TableHead, TableRow, TableCell,
} from 'react-toolbox/lib/table'
import Card from 'components/Card'
import { Button } from '@deloitte/gel-library'
import classnames from 'classnames'
import { IconErrorOrange, IconArrowDown, IconArrowUp } from 'icons'
import { fetchErrors } from 'actions/validations'
import Loading from 'components/Loading'
import { useDispatch } from 'react-redux'
import styles from './ErrorCard.scss'

const propTypes = {
  _isFetching: PropTypes.bool.isRequired,
  action: PropTypes.string,
  affectedRows: PropTypes.number.isRequired,
  columnMapping: PropTypes.arrayOf(
    PropTypes.shape({
      columnKey: PropTypes.string,
      columnName: PropTypes.string,
    }),
  ).isRequired,
  columnName: PropTypes.string,
  description: PropTypes.string,
  errors: PropTypes.array,
  importance: PropTypes.string,
  jobId: PropTypes.string.isRequired,
  page: PropTypes.number,
  severity: PropTypes.string.isRequired,
  title: PropTypes.string,
  validationName: PropTypes.string.isRequired,
}
const defaultProps = {
  action: '',
  columnName: null,
  description: '',
  errors: [],
  importance: '',
  page: 0,
  title: '',
}

const ErrorCard = ({
  jobId, page, validationName, columnMapping,
  errors,
  title,
  description,
  importance,
  action,
  columnName,
  affectedRows,
  _isFetching,
  severity,
}) => {
  const [active, setActive] = useState(false)

  const dispatch = useDispatch()

  const loadMore = () => {
    const nextPage = page + 1

    if (validationName) {
      dispatch(fetchErrors(jobId, validationName, nextPage))
    }
  }

  const expand = () => {
    setActive(!active)
    loadMore()
  }

  const isCritical = severity === 'Critical'

  const panelHeader = (
    <div className={styles.fileCardHeader}>
      <div>
        <IconErrorOrange height="19" width="22" />
        <span className={styles.title}>{title}</span>
      </div>
      <p>
        {`${description}${
          description.slice(-1) === '.' ? '' : '.'
        } ${importance}`}
      </p>
      <p>
        <strong>How to fix? </strong>
        {action}
      </p>
      <div className={styles.showErrorContainer}>
        <span
          className={styles.showerrors}
          onClick={expand}
        >
          {'Show issues'}
          {active ? <IconArrowUp width="12" /> : <IconArrowDown width="12" />}
        </span>
      </div>
    </div>
  )

  if (errors.length === 0) {
    return <Card noPadding className={isCritical ? styles.critical : null}>{panelHeader}</Card>
  }

  const mappedFilenamesPresent = columnMapping.some(
    c => c.fileColumnName !== null,
  )

  return (
    <Card noPadding className={isCritical ? styles.critical : null}>
      {panelHeader}
      <div className={classnames(styles.base, { [styles.active]: active })}>
        <div className={styles.responsiveTable}>
          <Table className={styles.table} selectable={false}>
            <TableHead>
              <TableCell colSpan={columnMapping.length}>
                {mappedFilenamesPresent && (
                <span className={styles.tableLabel}>
                  Mapped column names
                </span>
                )}
              </TableCell>
            </TableHead>
            {mappedFilenamesPresent && (
            <TableHead>
              {columnMapping.map(column => (
                <TableCell
                  className={styles.tableHeaderAbove}
                  key={column.columnKey}
                >
                  {column.fileColumnName}
                  {' '}
                </TableCell>
              ))}
            </TableHead>
            )}
            <TableHead>
              {columnMapping.map(column => (
                <TableCell
                  className={classnames(styles.tableHeaderBelow, {
                    [styles.tableHeaderBelowError]:
                        columnName === column.columnName,
                  })}
                  key={column.columnKey}
                >
                  {column.columnName}
                  {' '}
                </TableCell>
              ))}
            </TableHead>
            {errors.map((error, idx) => (
              <TableRow className={styles.tableRow} key={idx}>
                {columnMapping.map(column => (
                  <TableCell
                    className={classnames({
                      [styles.tableCellError]:
                          columnName === column.columnName,
                    })}
                    key={column.columnKey}
                  >
                    <p>{error[column.columnKey]}</p>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </Table>
          <div className={styles.tableFooter}>
            {_isFetching && <Loading inline />}
            {!_isFetching
                && affectedRows > errors.length && (
                  <Button mode="flat" onClick={loadMore}>
                    load more
                  </Button>
            )}
          </div>
        </div>
      </div>
    </Card>
  )
}

ErrorCard.propTypes = propTypes
ErrorCard.defaultProps = defaultProps

export default ErrorCard
