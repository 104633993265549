import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import IconBase from './IconBase'

const IconAvatarUnknown = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--avatarunknown',
    className,
  )

  return (
    <IconBase className={classes} viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <title>Group</title>
      <g fill="none" fillRule="evenodd">
        <circle className="fill" cx="18" cy="18" fill="#CCC" r="18" />
        <circle cx="18" cy="18" fill="#FFF" r="12" />
        <path
          className="path1 fill"
          // eslint-disable-next-line max-len
          d="M18 3C9.72 3 3 9.72 3 18c0 8.28 6.72 15 15 15 8.28 0 15-6.72 15-15 0-8.28-6.72-15-15-15zm0 4.5c2.49 0 4.5 2.01 4.5 4.5s-2.01 4.5-4.5 4.5-4.5-2.01-4.5-4.5 2.01-4.5 4.5-4.5zm0 21.3a10.8 10.8 0 0 1-9-4.83c.045-2.985 6-4.62 9-4.62 2.985 0 8.955 1.635 9 4.62a10.8 10.8 0 0 1-9 4.83z"
          fill="#CCC"
          fillRule="nonzero"
        />
      </g>
    </IconBase>
  )
}

IconAvatarUnknown.propTypes = {
  className: PropTypes.string,
}

IconAvatarUnknown.defaultProps = {
  className: null,
}

export default IconAvatarUnknown
