/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconHome = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--home',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"{...rest}><path className="path1" fill="#58595B" d="M19.6 6.7h-1.2V2.2c0-.1-.1-.1-.1-.1h-1.9c-.1 0-.1.1-.1.1v1.9H15V2.2c0-.8.6-1.4 1.4-1.4h1.9c.8 0 1.4.6 1.4 1.4v4.5zM19.8 23.2H4.2c-.8 0-1.4-.6-1.4-1.4v-9.6H4v9.6c0 .1.1.1.1.1h15.6c.1 0 .1-.1.1-.1v-9.6H21v9.6c.2.8-.5 1.4-1.2 1.4z"/><path className="path2" fill="#58595B" d="M2.5 13.3c-.4 0-.8-.2-1.1-.5L.2 11.3c-.5-.6-.4-1.5.2-1.9L11 1.2c.5-.4 1.4-.4 1.9 0l10.5 8.2c.3.2.5.5.5.9s-.1.7-.3 1l-1.2 1.5c-.2.3-.5.5-.9.5s-.7-.1-1-.3l-8.4-6.6c-.1-.1-.3-.1-.4 0L3.4 13c-.3.2-.6.3-.9.3zM12 2.1c-.1 0-.2 0-.2.1L1.3 10.4c-.1 0-.1.1 0 .2L2.4 12c0 .1.1.1.2 0L11 5.5c.5-.4 1.4-.4 1.9 0l8.4 6.6h.2l1.2-1.5v-.1-.1L12.2 2.2s-.1-.1-.2-.1zM15.6 22.6h-.2v-8.1c0-.3-.3-.6-.6-.6H9.3c-.3 0-.6.3-.6.6v8.1h-.3v-8.1c0-.5.4-.9.9-.9h5.4c.5 0 .9.4.9.9v8.1z"/><path className="path3" fill="#58595B" d="M16.1 22.6h-1.2v-8.1c0-.1-.1-.1-.1-.1H9.3c-.1 0-.1.1-.1.1v8.1H7.9v-8.1c0-.8.6-1.4 1.4-1.4h5.4c.8 0 1.4.6 1.4 1.4v8.1z"/></IconBase>
  )
}

IconHome.propTypes = {
  className: PropTypes.string,
}

export default IconHome
/* eslint-enable */
