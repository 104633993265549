/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconSubmit = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--submit',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37 37" enableBackground="new 0 0 37 37"{...rest}><path className="path1" fill="none" stroke="#000" strokeWidth="3" strokeLinejoin="round" strokeMiterlimit="10" d="M30.5 6.5c6.6 6.6 6.6 17.4 0 24-6.6 6.6-17.4 6.6-24 0-6.6-6.6-6.6-17.4 0-24 6.6-6.7 17.4-6.7 24 0z"/><path className="path2" fill="none" stroke="#000" strokeWidth="3" strokeLinejoin="round" strokeMiterlimit="10" d="M11.6 20l4.3 4.2 10.5-10.4"/></IconBase>
  )
}

IconSubmit.propTypes = {
  className: PropTypes.string,
}

export default IconSubmit
/* eslint-enable */
