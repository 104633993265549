import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Switch } from 'react-router-dom'
import { fetchClientCustomReport } from 'actions/client'
import Loading from 'components/Loading'
import Banner from 'components/Banner'
import constants from 'constants'
import RouteWithSubRoutes from 'components/RouteWithSubRoutes'
import Container from 'components/layout/Grid/Container'

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  fetchedReport: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      groupId: PropTypes.string,
    }),
  }).isRequired,
  reportGroup: PropTypes.object,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
}

const defaultProps = {
  reportGroup: null,
}

const ClientCustomGroup = (props) => {
  const {
    dispatch, routes, reportGroup, fetchedReport, match: { params: { groupId } },
  } = props

  useEffect(() => {
    if (!fetchedReport) {
      dispatch(fetchClientCustomReport(groupId))
    }
  }, [dispatch, fetchedReport, groupId])

  const loaded = reportGroup && fetchedReport

  return (
    <div>
      <Banner
        backTitle="Custom Reports"
        defaultBack={`/insights/${constants.CUSTOM_REPORT_PACKAGE}`}
        name={loaded ? reportGroup.name : ''}
        routes={routes}
      />
      <Container topPadding>
        {loaded
          ? (
            <Switch>
              {routes.map(route => (
                <RouteWithSubRoutes
                  key={route.path}
                  {...route}
                  dispatch={dispatch}
                  members={reportGroup.members}
                  reportGroup={reportGroup}
                />
              ))}
            </Switch>
          )
          : (
            <Loading pageLoading />
          )
        }
      </Container>

    </div>
  )
}

const mapStateToProps = (
  { app: { user }, client: { reportGroups, _fetchedCustomReport } },
  { match: { params: { groupId } } },
) => {
  return {
    user,
    reportGroup: reportGroups[groupId],
    groupId,
    fetchedReport: _fetchedCustomReport,
  }
}

ClientCustomGroup.propTypes = propTypes
ClientCustomGroup.defaultProps = defaultProps

export default connect(mapStateToProps)(ClientCustomGroup)
