import { format } from 'd3-format'

export function formatNumber(value) {
  if (value < 100) { return `${value}` }

  const d3Format = format('0.2s')(value)
  return d3Format
    .replace('M', 'm')
    .replace('G', 'b')
}

export function commaFormatNumber(value) {
  return format(',')(value)
}

export function formatMoney(value) {
  return value ? `$${formatNumber(value)}` : ''
}

export function formatMoneyNoRounding(value, currencySymbol = '$') {
  const number = typeof value === 'number'
    ? value
    : +value.replace(/[^\d.-]/g, '')
  return `${number < 0 ? '-' : ''}${currencySymbol}${Math.abs(number).toLocaleString()}`
}

export function range(startsAt, length) {
  return [...Array(length).keys()].map(x => x + startsAt)
}

export function formatPercent(value, decimals) {
  return format(`.${decimals}%`)(value)
}
