/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconLedgerConversion = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--ledgerconversion',
    className,
  )

  return (
    <IconBase className={classes} viewBox="0 0 23 21" xmlns="http://www.w3.org/2000/svg"{...rest}><title>Ledger conversion</title><path className="path1" d="M16.093 20.611a.573.573 0 0 1-.572-.572v-2.36H8.365a.572.572 0 1 1 0-1.146h7.729c.316 0 .572.257.572.573v1.55l4.951-4.95-4.95-4.951v1.55a.572.572 0 0 1-.573.573H7.478v2.361a.572.572 0 0 1-.977.405L.168 7.31a.573.573 0 0 1 0-.81L6.5.169a.572.572 0 0 1 .977.405v2.36h7.156a.572.572 0 1 1 0 1.146H6.906a.573.573 0 0 1-.573-.573V1.955l-4.95 4.951 4.95 4.95v-1.55c0-.317.256-.573.573-.573h8.615v-2.36a.572.572 0 0 1 .978-.406l6.333 6.334a.573.573 0 0 1 0 .81L16.5 20.442a.573.573 0 0 1-.406.168" fill="#58595B" fillRule="evenodd"/></IconBase>
  )
}

IconLedgerConversion.propTypes = {
  className: PropTypes.string,
}

export default IconLedgerConversion
/* eslint-enable */
