import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './ActionState.scss'

const ActionState = ({ actionState }) => {
  return (
    <span className={classNames(styles.base, styles[`action-${actionState.id}`])}>
      {actionState.description}
    </span>
  )
}

ActionState.propTypes = {
  actionState: PropTypes.shape({
    description: PropTypes.string,
    id: PropTypes.number,
  }),
}

ActionState.defaultProps = {
  actionState: {
    description: 'Unassigned',
    id: 0,
  },
}

export default ActionState
