import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import LinesEllipsis from 'react-lines-ellipsis'
import { sortByFunction } from 'utils/arrays'
import CardList from 'components/CardList'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import usePackages from 'hooks/usePackages'
import styles from './ClientReportList.scss'

function ClientReportList({
  job,
}) {
  const dispatch = useDispatch()
  const { getSolution } = usePackages()
  const solution = getSolution(job.packageId)

  if (!solution) {
    return null
  }

  const solutionAnalysisIds = solution.analyses.map(sa => sa.name)

  const analyses = job.analyses ? job.analyses
    .filter(a => a.hasData)
    .sort(sortByFunction(x => x.displayOrder))
    .filter(a => solutionAnalysisIds.includes(a.id))
    : []

  return (
    <CardList
      noItemPadding
      className={styles.reportCard}
      contentExtractor={(a) => {
        const analysis = solution.analyses
          .find(x => x.name === a.id)

        return (
          <Fragment key={a.name}>
            <div className={styles.iconContainer}>
              <img
                alt={a.displayName}
                key={a.name}
                src={`/api/client/packages/${solution.name}/analyses/${a.id}/image`}
              />
            </div>
            <div className={styles.textContent}>
              <p className={styles.heading}>
                {a.name}

              </p>
              <div className={styles.description}>
                <LinesEllipsis text={analysis.description} />
              </div>
            </div>
          </Fragment>
        )
      }}
      data={analyses}
      disabled={job.hasExpired}
      itemClassName={styles.reportCardItem}
      keyExtractor={a => a.id}
      onClick={a => dispatch(push(`/insights/${job.packageId}/report/${job.jobId}/${a.id}`))}
    />
  )
}

ClientReportList.propTypes = {
  job: PropTypes.shape({
    analyses: PropTypes.arrayOf(PropTypes.object),
    hasExpired: PropTypes.bool,
    jobId: PropTypes.string,
    packageId: PropTypes.string,
  }).isRequired,
}

ClientReportList.defaultProps = {
}

export default ClientReportList

