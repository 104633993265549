import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import WarningIcon from '@material-ui/icons/Warning'
import styles from './WarningText.scss'

const WarningText = ({ text, className }) => (
  <span className={classnames(styles.base, className)}>
    <WarningIcon className={styles.icon} fontSize="small" />
    <span>
      {text}
    </span>
  </span>
)

WarningText.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
}

WarningText.defaultProps = {
  className: null,
}

export default WarningText
