import PropTypes from 'prop-types'
import DialogSimple from 'components/DialogSimple'
import IconAlert from 'icons/IconAlert'
import React from 'react'
import {
  Heading,
} from '@deloitte/gel-library'
import styles from './ConfirmDeleteDialog.scss'

const ConfirmDeleteDialog = (
  {
    userToDelete, job, closeDeleteDialog, confirmDeleteDialog,
  },
) => {
  const { userDetails } = userToDelete
  return (
    <DialogSimple
      isOpen
      actionLabel="Remove"
      dismissLabel="Cancel"
      icon={<IconAlert height={50} width={50} />}
      onAction={confirmDeleteDialog}
      onDismiss={closeDeleteDialog}
    >
      <div className={styles.base}>
        <Heading level={8}>
          {`Remove ‘${userDetails.firstName} ${userDetails.surname}’ from ‘${job.name}’?`}
        </Heading>
        <p>Once removed, they will no longer have access to this job.</p>
      </div>
    </DialogSimple>
  )
}

ConfirmDeleteDialog.propTypes = {
  closeDeleteDialog: PropTypes.func.isRequired,
  confirmDeleteDialog: PropTypes.func.isRequired,
  job: PropTypes.object.isRequired,
  userToDelete: PropTypes.object.isRequired,
}

ConfirmDeleteDialog.defaultProps = {

}

export default ConfirmDeleteDialog
