import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Heading, Card, Button, Select } from '@deloitte/gel-library'
import FolderIcon from '@material-ui/icons/Folder'
import { useDispatch, useSelector } from 'react-redux'
import fileIcon from 'images/fileIcon.svg'
import { Divider } from '@material-ui/core'
import binIcon from 'images/binIcon.svg'
import FileGroupDialog from 'components/FileGroupDialog'
import { updateSupportingFiles, updateSupportingFilesStatus, fetchSupportingData } from 'actions/job'
import FileGroupDataProcessingModal from 'components/FileGroupDataProcessingModal'
import { uploadSupportingFiles, removeSupportingFile } from 'actions/file'
import { formatDateFromStringShort } from 'utils/dates'
import styles from './SupportingDocuments.scss'

const propTypes = {
  analysisNames: PropTypes.arrayOf(PropTypes.string).isRequired,
}

const MAX_FILE_NAME_DISPLAY_CHAR = 19

const splitLastOccurrence = (str, substring) => {
  const lastIndex = str.lastIndexOf(substring)
  const before = str.slice(0, lastIndex)
  const after = str.slice(lastIndex + 1)
  return [before, after]
}

const decorateFileNameText = (fileNameText) => {
  if (!fileNameText) {
    return ''
  }
  const [before, after] = splitLastOccurrence(fileNameText, '.')
  return before > MAX_FILE_NAME_DISPLAY_CHAR ? `${before.substring(0, MAX_FILE_NAME_DISPLAY_CHAR + 1)}....${after}` : fileNameText
}

const formatFileSize = (bytes) => {
  const suffixes = ['B', 'kB', 'MB', 'GB', 'TB']
  const i = Math.floor(Math.log(bytes) / Math.log(1024))
  const size = (bytes / (1024 ** i))
  return `${size === Math.floor(size) ? size : size.toFixed(2)} ${suffixes[i]}`
}

function SupportingDocuments({
  analysisNames,
}) {
  const {
    job: {
      jobId, packageId, packageValue, supportingFileStatus, supportingFiles, _isUploadingFiles,
    }, packages, app: { user: { isExternal } },
  } = useSelector(state => state)
  const dispatch = useDispatch()
  const [showDuplicateDialog, setShowDuplicateDialog] = useState(false)
  const [duplicateFileNames, setDuplicateFileNames] = useState([])
  const [showFileDeletionConfirmation, setShowFileDeletionConfirmation] = useState(false)
  const [fileToDelete, setFileToDelete] = useState(null)

  const onInputClick = (event) => {
    const element = event.target
    element.value = ''
  }

  const deleteFile = () => {
    const data = {
      name: fileToDelete.name,
      tablename: fileToDelete.tablename,
    }

    // only dispatch when file has been uploaded
    const removeFilePromise = supportingFileStatus.find(file => file.name === fileToDelete.name).status === 'complete'
      ? dispatch(removeSupportingFile(data, jobId, isExternal))
      : Promise.resolve()

    removeFilePromise
      .then(() => {
        const newSupportFiles = supportingFiles.filter(file => file.name !== fileToDelete.name)
        const newFileStatus = supportingFileStatus.filter(file => file.name !== fileToDelete.name)

        dispatch(updateSupportingFiles(newSupportFiles))
        dispatch(updateSupportingFilesStatus(newFileStatus))
      })
      .catch((error) => {
        console.error('Failed to remove supporting file:', error)
      })
      .finally(() => {
        setShowFileDeletionConfirmation(false)
        setFileToDelete(null)
      })
  }

  const undoFileDeletion = () => {
    setShowFileDeletionConfirmation(false)
    setFileToDelete(null)
  }

  const handleFileDeletion = (file) => {
    setShowFileDeletionConfirmation(true)
    setFileToDelete(file)
  }

  const onCloseDuplicateDialog = () => {
    setShowDuplicateDialog(false)
    setDuplicateFileNames([])
  }
  const uploadAllFiles = () => {
    const filesToUpload = supportingFileStatus.filter(f => f.status !== 'complete')
    const formsData = filesToUpload.map((filesToUploadItem) => {
      const file = supportingFiles.find(f => f.name === filesToUploadItem.name)
      if (!file) {
        return null
      }
      const formData = new FormData()

      formData.append('file', file, file.name)
      formData.append('tableCode', filesToUploadItem.tablename)
      formData.append('jobId', jobId)
      formData.append('qqfilename', file.name)
      formData.append('locationRef', 'supporting_files')
      return formData
    }).filter(formData => formData !== null)

    if (formsData.length > 0) {
      dispatch(uploadSupportingFiles(formsData, isExternal))
        .then(() => {
          const updatedFileStatus = supportingFileStatus.map((file) => {
            if (filesToUpload.find(f => f.name === file.name)) {
              return { ...file, status: 'complete' }
            }
            return file
          })

          dispatch(updateSupportingFilesStatus(updatedFileStatus))
        })
        .catch((error) => {
          console.error('Error uploading files:', error)
        })
    }
  }

  useEffect(() => {
    dispatch(fetchSupportingData(jobId, isExternal))
  }, [])

  const updateFileAnalysisName = (fileName, newAnalysisName) => {
    const updatedFiles = supportingFileStatus.map((file) => {
      return file.name === fileName
        ? { ...file, tablename: newAnalysisName }
        : file
    })
    dispatch(updateSupportingFilesStatus(updatedFiles))
  }

  const addFile = (e) => {
    const filesArr = Array.from(e.target.files)
    const duplicateFiles = filesArr.filter(file => supportingFiles.map(f => f.name).includes(file.name))

    if (duplicateFiles.length > 0) {
      setDuplicateFileNames(duplicateFiles.map(df => df.name))
      setShowDuplicateDialog(true)
    } else {
      const newFilesWithStatus = filesArr.map(file => ({
        name: file.name, size: file.size, status: 'pending', tablename: analysisNames[0], date: new Date(),
      }))
      const updatedSupportingFiles = [...supportingFiles, ...filesArr]
      const updatedSupportingFilesStatus = [...supportingFileStatus, ...newFilesWithStatus]
      dispatch(updateSupportingFiles(updatedSupportingFiles))
      dispatch(updateSupportingFilesStatus(updatedSupportingFilesStatus))
    }
  }

  const effectivePackageKey = packageId || packageValue
  const additionalFeatures = packages.packages[effectivePackageKey]?.meta?.additionalFeatures ?? {};
  const supportFileUploaderDescription = packages.packages[effectivePackageKey]?.meta?.additionalFeatures?.['support_file_uploader_description'] ?? false
  let supportFileFormats = '*';
  let supportFileDes = 'Any file type is accepted';

  if ('support_file_formats' in additionalFeatures) {
    supportFileFormats = additionalFeatures?.['support_file_formats'];
    supportFileDes = `${supportFileFormats} files are accepted`;
  }

  return (
    <>
      <Heading className={styles.subtitle} level={4}>Supporting documents</Heading>
      <p>
        {supportFileUploaderDescription}
      </p>
      <Card>
        {supportingFiles.length === 0 ? (
          <section className={styles.sectionGrey}>
            <div className="row">
              <div className="col-xs-12 col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8">
                <div className={styles.emptyFileGroup}>
                  <FolderIcon
                    className={styles.folderIcon}
                    fontSize="large"
                  />
                  <div className={styles.noOfFileGroups}>
                    {supportingFiles.length}
                    {' '}
                    file uploaded
                  </div>

                  <div style={{ marginBottom: '16px' }}>
                    <p>
                     {supportFileDes}
                     , up to 10MB each.
                    </p>
                  </div>
                  <div>
                    <Button className={styles.addFile} mode="secondary">
                      <label
                        className={styles.fileUploadLabel}
                        htmlFor={`${jobId}-file-upload`}
                        style={{ cursor: 'pointer' }}
                      >
                        + Add file
                      </label>
                      <input
                        multiple
                        accept={supportFileFormats}
                        id={`${jobId}-file-upload`}
                        name="rawFiles[]"
                        onChange={event => addFile(event)}
                        onClick={onInputClick}
                        style={{ display: 'none' }}
                        type="file"
                      />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )
          : (
            <section>
              <div>
                <div>
                  {
                    supportingFileStatus?.map(f => (
                      <Card
                        className={styles.fileCard}
                        key={f.name}
                      >
                        <div style={{ display: 'flex' }}>
                          <div className={styles.filePrimary}>
                            <img
                              alt="File Icon"
                              src={fileIcon}
                              style={{
                                width: '31px',
                              }}
                            />
                            <div className={styles.fileInfoPrimary} style={{ color: '#009BD7' }}>{decorateFileNameText(f.name)}</div>
                            <div className={styles.fileInfoPrimary}>
                              Last upload:
                              {` ${formatDateFromStringShort(f.date)}`}
                            </div>
                            {analysisNames.length > 0 && (
                              <Select
                                className={styles.dropdown}
                                defaultValue={f.tablename || analysisNames[0]}
                                id="supporting_file_selection"
                                label="supporting_file_selection"
                                onChange={event => updateFileAnalysisName(f.name, event.target.value)}
                                options={analysisNames.map(name => ({ value: name, label: name }))}
                              />
                            )}
                          </div>
                          <div className={styles.fileSecondary}>
                            <div style={{ marginTop: '4px' }}>{formatFileSize(f.size)}</div>
                            <Divider className={styles.fileInfoSecondary} orientation="vertical" />
                            <div className={styles.binButton} onClick={() => handleFileDeletion(f)}>
                              <img alt="Bin Icon" src={binIcon} />
                            </div>
                          </div>
                        </div>
                      </Card>
                    ))
                  }
                </div>

                <div style={{ textAlign: 'left', margin: '24px 0 24px 0' }}>
                  <Button
                    mode="secondary"
                    style={
                      {
                        border: 'none',
                        marginTop: '2px',
                      }
                    }
                  >
                    <label
                      className={styles.fileUploadLabel}
                      htmlFor={`${jobId}-file-upload`}
                      style={{ cursor: 'pointer' }}
                    >
                      + Add file
                    </label>
                    <input
                      multiple
                      accept={supportFileFormats}
                      id={`${jobId}-file-upload`}
                      name="rawFiles[]"
                      onChange={event => addFile(event)}
                      onClick={onInputClick}
                      style={{ display: 'none' }}
                      type="file"
                    />
                  </Button>
                </div>

                <Divider className={styles.fileInfoSecondary} orientation="horizontal" />

                <div className={styles.uploadFileBtn}>
                  {
                    console.log('file status', supportingFileStatus)
                  }
                  <Button
                    disabled={supportingFiles.length === 0 || !supportingFileStatus.some(f => f.status !== 'complete')}
                    mode="secondary"
                    onClick={() => uploadAllFiles()}
                  >
                    UPLOAD ALL FILES
                  </Button>
                </div>
              </div>
            </section>
          )
        }
      </Card>
      {showDuplicateDialog && (
        <FileGroupDialog
          actionLabel="DISMISS"
          heading="Duplicate file found"
          onAction={() => onCloseDuplicateDialog()}
          text={`A file with the same name was detected. The file with duplicate name is not uploaded, please change the name and reupload: ${duplicateFileNames.join()}.`}
        />
      )}
      {showFileDeletionConfirmation
        && (
          <FileGroupDialog
            actionLabel="DELETE FILE"
            dismissLabel="CANCEL"
            heading="Confirm the deletion?"
            onAction={() => deleteFile()}
            onDismiss={() => undoFileDeletion()}
            text=""
          />
        )}
      {_isUploadingFiles && (
        <FileGroupDataProcessingModal
          heading="Uploading your supporting files"
          spinning={_isUploadingFiles}
          spinningCompleteText="Upload complete"
          spinningText={`Uploading ${supportingFileStatus.filter(f => f.status !== 'complete').length} data files…`}
        />
      )}
    </>
  )
}

SupportingDocuments.propTypes = propTypes

export default (SupportingDocuments)
