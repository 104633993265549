/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconAppMYOB = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--appmyob',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 68"{...rest}><path className="path1" fill="#5C247B" d="M58.1 68H9.9C4.4 68 0 63.6 0 58.1V9.9C0 4.4 4.4 0 9.9 0h48.2C63.6 0 68 4.4 68 9.9v48.2c0 5.5-4.4 9.9-9.9 9.9"/><path className="path2" fill="#472060" d="M0 9.9v48.2C0 63.6 4.4 68 9.9 68h48.2L9.9 0C4.4 0 0 4.4 0 9.9"/><g fill="#FFF"><path className="path3" d="M54.2 35h-.9v2.3h.9c.9 0 1.6-.5 1.6-1.2.1-.6-.7-1.1-1.6-1.1zM41.8 30.7c-1.4 0-2.1 1.5-2.1 3.3s.8 3.3 2.1 3.3 2.1-1.5 2.1-3.3c0-1.8-.7-3.3-2.1-3.3zM55.7 31.8c0-.6-.7-1.2-1.6-1.2h-.7V33h.7c.9 0 1.6-.5 1.6-1.2z"/><path className="path4" d="M14.2 23.4C8.5 23.4 3.9 28.2 3.9 34v10.6h50c5.7 0 10.3-4.8 10.3-10.6V23.4h-50zM22 39.7c-1.5 0-2.4-.9-2.5-2.2l-.3-6-1.8 8.2h-4.1L12 31.9l-.9 7.8h-3l1.1-11.3h6.4l.6 7.1 1.5-7.1h5.5l.8 11.3h-2zm10.1 0h-4.3v-4.4L24 28.4h2.9c.9 0 1.8.3 2.3 1.2l1.6 3.1 2.2-4.3h3.2l-4.1 7v4.3zm9.6.3c-3.6 0-6.5-2.6-6.5-5.9 0-3.3 2.3-5.9 6.5-5.9s6.5 2.6 6.5 5.9c-.1 3.3-2.9 5.9-6.5 5.9zM60 36.4c-.1 2.5-3.9 3.5-6.8 3.5-3-.1-3.8-.1-3.8-.1V28.4s1.7-.2 3.9-.1c2.2 0 6.4.1 6.6 2.9.1 1.5-.9 2.2-2.1 2.6.9.2 2.2 1.2 2.2 2.6z"/></g><g fill="#5C247B"><path className="path5" d="M62.5 26.4c0-.4-.1-.7-.4-.9-.2-.2-.5-.4-.9-.4-.3 0-.7.1-.9.4-.1.2-.3.5-.3.9s.1.7.4.9c.2.2.5.4.9.4.3 0 .7-.1.9-.4.2-.2.3-.6.3-.9zm-.1 0c0 .3-.1.6-.3.8-.2.2-.5.3-.8.3-.3 0-.6-.1-.8-.3-.2-.2-.3-.5-.3-.8 0-.3.1-.6.3-.8.2-.2.5-.3.8-.3.3 0 .6.1.8.3.2.2.3.5.3.8z"/><path className="path6" d="M61.7 26.6c0-.1-.1-.1-.1-.2 0 0-.1 0-.1-.1.1 0 .2-.1.3-.1.1-.1.1-.1.1-.2s0-.1-.1-.2c0-.1-.1-.1-.1-.1H60.8V27h.2v-.6h.4s.1 0 .1.1c0 0 .1.1.1.2l.2.3h.2l-.3-.4zm-.4-.4H61v-.4h.4c.1 0 .2 0 .2.1 0 0 .1.1.1.2v.1l-.1.1c-.1-.1-.2-.1-.3-.1z"/></g></IconBase>
  )
}

IconAppMYOB.propTypes = {
  className: PropTypes.string,
}

export default IconAppMYOB
/* eslint-enable */
