import { useEffect } from 'react'

const useScript = (url, options = {}) => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = url
    script.async = true
    Object.entries(options).forEach(([k, v]) => { script.setAttribute(k, v) })
    document.head.appendChild(script)
    return () => {
      document.head.removeChild(script)
    }
  }, [url])
}

export default useScript
