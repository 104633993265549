const useImportActions = () => {
  const updateActionIds = (action, connectedOrPreviousActions) => {
    const actionSetIdsToImport = connectedOrPreviousActions
      .filter(copa => (
        (action.actionSet.analysisName !== null)
          ? copa.actionSet.analysisName === action.actionSet.analysisName
          : copa.actionSet.actionName === action.actionSet.actionName
      ))
      .map(fa => fa.actionSet.id)

    return actionSetIdsToImport
  }

  return {
    updateActionIds,
  }
}

export default useImportActions
