import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { NavLink as Link } from 'react-router-dom'
import { canCreateCustomReport, isAdmin, isAssetDeveloper, isSupport } from 'utils/permissions'
import { useDispatch } from 'react-redux'
import { resetCloningState } from 'actions/job'
import styles from './NavLinks.scss'

const linkPropTypes = {
  handleLoseData: PropTypes.func.isRequired,
  link: PropTypes.shape({
    label: PropTypes.string,
    to: PropTypes.string,
  }).isRequired,
}

const propTypes = {
  simple: PropTypes.bool,
  user: PropTypes.object.isRequired,
}

const defaultProps = {
  simple: false,
}

const NavLink = ({ link, handleLoseData }) => {
  const isActive = (match, location) => {
    if (!match) { return false }
    if (match.isExact) { return true }

    return location.pathname.startsWith(match.url) && match.path.length > 1
  }

  const getLink = (toLink) => {
    switch (toLink.label) {
      case 'JOBS':
        return `${link.to}/engagements`
      case 'ADMIN':
        return `${link.to}/jobs`
      default:
        return link.to
    }
  }
  const dispatch = useDispatch()

  const _handleLoseData = (e) => {
    if (link.label === 'HOME') {
      e.preventDefault()
      e.stopPropagation()
      handleLoseData('/home')
    }
    dispatch(resetCloningState())
  }

  if (link.to.startsWith('http')) {
    return (
      <a
        className={styles.navLink}
        href={link.to}
        rel="noopener noreferrer"
        target="_blank"
      >
        {link.label}
      </a>
    )
  }

  return (
    <Link
      activeClassName={styles.isActive}
      className={styles.navLink}
      isActive={isActive}
      to={getLink(link)}
    >
      <span onClick={_handleLoseData}>
        {link.label}
      </span>
    </Link>
  )
}

NavLink.propTypes = linkPropTypes

const NavLinks = ({
  simple, user, ...rest
}) => {
  const navLinks = []

  if (user.isExternal) {
    navLinks.push({
      label: 'HOME',
      to: '/home',
    })
  } else {
    navLinks.push({
      label: simple ? 'BACK TO HOME' : 'HOME',
      to: '/home',
    })

    if (!simple) {
      navLinks.push({
        label: 'JOBS',
        to: '/jobs',
      })

      if (canCreateCustomReport(user)) {
        navLinks.push({
          label: 'REPORTS',
          to: '/custom',
        })
      }

      if (isAssetDeveloper(user)) {
        navLinks.push({
          label: 'GEN-AI',
          to: '/gen-ai',
        })
      }

      navLinks.push({
        label: 'SOLUTIONS',
        to: '/solutions',
      })

      if (isAdmin(user) || isSupport(user)) {
        navLinks.push({
          label: 'ADMIN',
          to: '/admin',
        })
      }
    }
  }

  return (
    <div className={classnames(styles.navLinks, { [styles.simple]: simple })}>
      {navLinks.map(link => (
        <NavLink
          key={link.label}
          link={link}
          {...rest}
        />
      ))}
    </div>
  )
}

NavLinks.propTypes = propTypes
NavLinks.defaultProps = defaultProps

export default NavLinks
