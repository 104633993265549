import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './LoadingBox.scss'

function LoadingBox({ className }) {
  return (
    <div className={classNames(styles.base, className)} />
  )
}

LoadingBox.propTypes = {
  className: PropTypes.string,
}

LoadingBox.defaultProps = {
  className: null,
}

export default LoadingBox

