/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconBusinessReviewMeeting = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--businessreviewmeeting',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"{...rest}><title>Fill 1</title><g fill="#58595B"><path className="path1" d="M7.9 10.7h8.2c.3 0 .6-.3.6-.6 0-1.2 0-3.7-2.5-4.3.2-.4.3-.8.4-1.3.1-.8-.1-1.5-.6-2s-1.1-.8-1.9-.8c-.8 0-1.5.3-2 .8s-.7 1.2-.6 2c.1.5.2.9.4 1.3-2.5.5-2.5 3-2.5 4.3-.1.4.2.6.5.6zm2.7-3.8h.4c.2 0 .5-.2.5-.4.1-.2 0-.5-.2-.6-.4-.3-.6-.8-.7-1.5-.1-.4 0-.8.3-1.1.2-.3.6-.4 1.1-.4.5 0 .8.1 1.1.4.2.3.4.7.3 1.1-.1.6-.3 1.2-.7 1.5-.2.1-.3.4-.2.6.1.2.3.4.5.4h.3c1.5.1 2.1.8 2.2 2.6h-7c.1-1.8.7-2.4 2.1-2.6zM6.8 17.4c.2-.4.3-.8.4-1.3.1-.8-.1-1.5-.6-2s-1.1-.8-1.9-.8c-.8 0-1.5.3-2 .8s-.7 1.2-.6 2c.1.5.2.9.4 1.3-2.5.5-2.5 3-2.5 4.2 0 .3.3.6.6.6h8.2c.3 0 .6-.3.6-.6-.1-1.2-.1-3.7-2.6-4.2zm-5.7 3.7c.1-1.9.7-2.5 2.1-2.6h.3c.2 0 .5-.2.5-.4.1-.2 0-.5-.2-.6-.4-.3-.6-.8-.7-1.5-.1-.4 0-.8.3-1.1.2-.3.6-.4 1.1-.4.5 0 .8.1 1.1.4.4.2.5.6.4 1.1-.1.6-.3 1.2-.7 1.5-.2.1-.3.4-.2.6.1.2.3.4.5.4H6c1.5.1 2.1.8 2.2 2.6H1.1zM21.5 17.4c.2-.4.3-.8.4-1.3.1-.8-.1-1.5-.6-2s-1.1-.8-1.9-.8c-.8 0-1.5.3-2 .8s-.7 1.2-.6 2c.1.5.2.9.4 1.3-2.5.5-2.5 3-2.5 4.3 0 .3.3.6.6.6h8.2c.3 0 .6-.3.6-.6-.1-1.3-.1-3.8-2.6-4.3zm-5.7 3.7c.1-1.9.7-2.5 2.2-2.6h.3c.2 0 .5-.2.5-.4.1-.2 0-.5-.2-.6-.3-.4-.6-.9-.6-1.5-.1-.4 0-.8.3-1.1.2-.3.6-.4 1.1-.4.5 0 .8.1 1.1.4.2.3.4.7.3 1.1-.1.6-.3 1.2-.7 1.5-.2.1-.3.4-.2.6.1.2.3.4.5.4h.3c1.5.1 2.1.8 2.1 2.6h-7zM14.6 16.1l-2.1-1.2v-2.4c0-.3-.3-.6-.6-.6s-.6.3-.6.6v2.4l-2.1 1.2c-.3.2-.4.5-.2.8.1.2.3.3.5.3.1 0 .2 0 .3-.1l2.1-1.2 2.1 1.2c.1.1.2.1.3.1.2 0 .4-.1.5-.3.2-.3.1-.6-.2-.8z"/></g></IconBase>
  )
}

IconBusinessReviewMeeting.propTypes = {
  className: PropTypes.string,
}

export default IconBusinessReviewMeeting
/* eslint-enable */
