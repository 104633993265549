import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import LoadingView from 'views/Home/LoadingView'
import Home from './Home'
import ClientHome from './ClientHome'

const propTypes = {
  _isFetched: PropTypes.bool.isRequired,
  isExternal: PropTypes.bool.isRequired,
}

const HomeSwitcher = ({ _isFetched, isExternal }) => {
  if (!_isFetched) {
    return <LoadingView />
  }

  return isExternal
    ? <ClientHome />
    : <Home />
}

HomeSwitcher.propTypes = propTypes

const mapStateToProps = ({
  app: { user: { _isFetched, isExternal } },
}) => ({
  _isFetched,
  isExternal,
})

export default connect(mapStateToProps)(HomeSwitcher)
