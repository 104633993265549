/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconSearch2 = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--search2',
    className,
  )

  return (
    <IconBase className={classes} viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg"{...rest}><title>Search</title>
    <path className="path1" d="M30 5C16.2 5 5 16.2 5 30C5 43.8 16.2 55 30 55C43.8 55 55 43.8 55 30C55 16.2 43.8 5 30 5Z" fill="white"/>
    <path className="path2" d="M30 5C16.2 5 5 16.2 5 30C5 43.8 16.2 55 30 55C43.8 55 55 43.8 55 30C55 16.2 43.8 5 30 5Z" fill="white"/>
    <path className="path3" d="M30 4C15.6477 4 4 15.6477 4 30H6C6 16.7523 16.7523 6 30 6V4ZM4 30C4 44.3523 15.6477 56 30 56V54C16.7523 54 6 43.2477 6 30H4ZM30 56C44.3523 56 56 44.3523 56 30H54C54 43.2477 43.2477 54 30 54V56ZM56 30C56 15.6477 44.3523 4 30 4V6C43.2477 6 54 16.7523 54 30H56Z" fill="#26890D"/>
    <path className="path4" d="M34.7163 32.8765C35.6025 31.4667 36.1261 29.8354 36.1261 28.063C36.1261 23.0482 32.0779 19 27.063 19C22.0482 19 18 23.0482 18 28.063C18 33.0779 22.0482 37.1261 27.0429 37.1261C28.8152 37.1261 30.4667 36.6025 31.8564 35.7163L38.1401 42L41 39.1401L34.7163 32.8765ZM27.063 33.0981C24.2837 33.0981 22.028 30.8424 22.028 28.063C22.028 25.2837 24.2837 23.028 27.063 23.028C29.8424 23.028 32.0981 25.2837 32.0981 28.063C32.0981 30.8424 29.8424 33.0981 27.063 33.0981Z" fill="#26890D"/>
</IconBase>
  )
}

IconSearch2.propTypes = {
  className: PropTypes.string,
}

export default IconSearch2
/* eslint-enable */
