import React from 'react'
import ExpanderCard from 'views/Home/components/Expander/Expander'
import TickList from 'components/TickList'
import { Heading } from '@deloitte/gel-library'
import speechBubble from 'images/speech-bubble.svg'
import NumberedList from 'components/NumberedList'
import { getConfig } from 'utils/config'
import { setCookie, getCookie } from 'utils/cookies'
import { dataTipsDismissedCookieName } from 'constants/cookies'
import { SUPPORT_PAGES } from 'constants/supportPages'
import { useSelector } from 'react-redux'
import { INPUT_DATA_TEXT } from 'constants/text'
import styles from './DataTips.scss'

const handleExpand = (isExpanded) => {
  if (!isExpanded) {
    setCookie(dataTipsDismissedCookieName, true, 365)
  }
}

const timesaverTips = (isExternal) => {
  return [
    {
      title: `${isExternal ? INPUT_DATA_TEXT.CLIENT_TIME_SAVER_TIP_1 : INPUT_DATA_TEXT.DTT_TIME_SAVER_TIP_1} are different to what’s specified in the Data Request Form?`,
      body: '**Leave them as is!** During the upload step, you will be able to choose which columns to “map”, and we will automatically rename these columns for you.',
    },
    {
      title: `${isExternal ? INPUT_DATA_TEXT.CLIENT_TIME_SAVER_TIP_2_AND_3 : INPUT_DATA_TEXT.DTT_TIME_SAVER_TIP_2} more columns than required?`,
      body: '**Don’t worry about deleting these!** Simply select a column for all of the required (mandatory) columns and any extra ‘(not mapped)’ columns will be ignored by InsightBox.',
    },
    {
      title: `${isExternal ? INPUT_DATA_TEXT.CLIENT_TIME_SAVER_TIP_2_AND_3 : INPUT_DATA_TEXT.DTT_TIME_SAVER_TIP_3} data in multiple files?`,
      body: '**No need to consolidate them into one file!** '
      + `You can upload multiple files at once. If ${
        isExternal ? INPUT_DATA_TEXT.CLIENT_TIME_SAVER_TIP_2_AND_3.toLowerCase() : INPUT_DATA_TEXT.DTT_TIME_SAVER_TIP_3_SOLN
      } one file per month, you can upload all 12 files rather than combining them into one first.`,
    },
    {
      title: `${isExternal ? INPUT_DATA_TEXT.CLIENT_TIME_SAVER_TIP_4 : INPUT_DATA_TEXT.DTT_TIME_SAVER_TIP_4} find that file??`,
      body: '**If it’s optional, you can exclude these files from your job and still produce a solution!**'
      + ` Excluding optional files will alter the analysis in your solution, ${
        isExternal ? INPUT_DATA_TEXT.CLIENT_TIME_SAVER_TIP_4_SOLN : INPUT_DATA_TEXT.DTT_TIME_SAVER_TIP_4_SOLN
      }`,
    },
  ]
}

const DataTips = () => {
  const { isExternal } = useSelector(state => state.app.user)
  const {
    URL: { APP },
  } = getConfig()

  const csvConvertTipLines = isExternal
    ? [INPUT_DATA_TEXT.CLIENT_SUBMIT_CHECKLIST_CONVERT_CSV_1, INPUT_DATA_TEXT.CLIENT_SUBMIT_CHECKLIST_CONVERT_CSV_2]
    : [INPUT_DATA_TEXT.DTT_SUBMIT_CHECKLIST_CONVERT_CSV_1, INPUT_DATA_TEXT.DTT_SUBMIT_CHECKLIST_CONVERT_CSV_2]

  const dataTipsDismissed = getCookie(dataTipsDismissedCookieName)

  const submitChecklist = [
    {
      title: 'Check all mandatory columns',
      body: 'All required data and columns are available for submission',
    },
    {
      title: 'Convert all Excel files into CSV',
      body: `${csvConvertTipLines[0]} Note that InsightBox
          only accepts CSV files, ${csvConvertTipLines[1]}, you will
          need to convert these to CSV format in Excel.
          [Learn how](${APP}/#${SUPPORT_PAGES.EXCEL_TO_CSV})`,
    },
    {
      title: 'Check input formats',
      body: 'Ensure that all numeric fields contain numbers, and all date/times in your data are in an acceptable format.',
    },
  ]

  return (
    <div className={styles.base}>
      <ExpanderCard
        classNameContent={styles.expanderContent}
        expandedLabel="Hide tips & tricks"
        headerContent={(
          <div className={styles.tipHeader}>
            <img alt="Speech bubble icon" src={speechBubble} />
            <Heading level={7}>Things to consider before uploading your data</Heading>
          </div>
        )}
        hiddenLabel="Show tips & tricks"
        onExpand={handleExpand}
        size="large"
        startsExpanded={!dataTipsDismissed}
      >
        <div className="row">
          <div className="col-md-6">
            <div className={styles.column}>
              <Heading level={7}>Have you done the following items?</Heading>
              <p>Before submitting your data, ensure that you have covered the following:</p>
              <TickList listItems={submitChecklist} />
            </div>
          </div>
          <div className="col-md-6">
            <div className={styles.column}>
              <div className={styles.highlightBox}>
                <Heading level={7}>Timesaver tips</Heading>
                <NumberedList highlightColor="#00A5DC" listItems={timesaverTips(isExternal)} />
              </div>
            </div>
          </div>
        </div>
      </ExpanderCard>
    </div>
  )
}

export default DataTips
