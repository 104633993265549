import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@deloitte/gel-library'
import AddIcon from '@material-ui/icons/Add'
import TextField from '@material-ui/core/TextField'
import SearchIcon from '@material-ui/icons/Search'
import classNames from 'classnames'
import useAction from 'actionHub/hooks/useAction'
import { useSelector } from 'react-redux'
import { isEqual } from 'lodash'
import styles from './TickmarkGenerator.scss'
import Tickmark from '../Tickmark'

function TickmarkGenerator({
  onClose, actionSetId, selectedTaskIds, onApply,
}) {
  const { tagsList } = useSelector(state => state.actionHub.tags)
  const { tasks } = useSelector(state => state.actionHub)

  const [search, setSearch] = useState('')
  const [searchOnFocus, setSearchOnFocus] = useState(false)
  const [interimActiveTickmarkArray, setInterimActiveTickmarkArray] = useState(null)
  const [anythingChanged, setAnythingChanged] = useState(false)
  const [preActiveTickmarks, setPreActiveTickmarks] = useState([])
  const [activeTickmark, setActiveTickmark] = useState(null)
  const [isMultiple, setisMultiple] = useState(false)
  const [newTickmark, setNewTickmark] = useState(null)
  const [filteredTags, setFilteredTags] = useState(tagsList)

  const {
    createTag, setUpdatedTag, deleteTagAssociation,
  } = useAction(actionSetId)

  const getNextKey = (tagName) => {
    const key = tagName.toUpperCase()
    if (key === 'Z' || key === 'z') {
      return String.fromCharCode(key.charCodeAt() - 25) + String.fromCharCode(key.charCodeAt() - 25)
    }
    const lastChar = key.slice(-1)
    const sub = key.slice(0, -1)
    if (lastChar === 'Z' || lastChar === 'z') {
      return getNextKey(sub) + String.fromCharCode(lastChar.charCodeAt() - 25)
    }
    return sub + String.fromCharCode(lastChar.charCodeAt() + 1)
  }

  const save = () => {
    if (activeTickmark === null) {
      if (interimActiveTickmarkArray !== null) {
        deleteTagAssociation(Array.from(selectedTaskIds))
      }
    } else {
      if (newTickmark?.name === activeTickmark?.name) {
        if (interimActiveTickmarkArray !== null) {
          deleteTagAssociation(interimActiveTickmarkArray)
        }
        // deleteTagAssociation()
        createTag(selectedTaskIds, activeTickmark.name, null)
      } else {
        setUpdatedTag(selectedTaskIds, null, null, activeTickmark.id, null)
      }
    }
    onApply()
  }

  const cancel = () => {
    setActiveTickmark(null)
    setNewTickmark(null)
    setisMultiple(false)
    setPreActiveTickmarks([])
    setAnythingChanged(false)
    onClose()
  }

  const remove = () => {
    if (preActiveTickmarks.length > 0) {
      let arrayOfActiveTickmarks = []
      if (isMultiple) {
        arrayOfActiveTickmarks = activeTickmark.tags
      } else {
        arrayOfActiveTickmarks = new Array(activeTickmark)
      }
      if (isEqual(arrayOfActiveTickmarks, preActiveTickmarks)) {
        setInterimActiveTickmarkArray(arrayOfActiveTickmarks)
        setAnythingChanged(true)
      }
    } else {
      setAnythingChanged(false)
    }
    setNewTickmark(null)
    setActiveTickmark(null)
  }

  const tickmarkSelected = (tickmark) => {
    setisMultiple(false)
    setActiveTickmark(tickmark)
    setAnythingChanged(true)
    setInterimActiveTickmarkArray(null)
  }

  const getNewTagName = (tagName) => {
    const tagNames = filteredTags.map(ft => ft.name)
    if (tagNames.includes(tagName)) {
      return getNewTagName(getNextKey(tagName))
    }
    return tagName
  }

  const generateTickmark = () => {
    if (activeTickmark) {
      setInterimActiveTickmarkArray(new Array(activeTickmark))
    }
    setAnythingChanged(true)
    const tick = {
      name: getNewTagName('A'),
      description: '',
    }
    setisMultiple(false)
    setNewTickmark(tick)
    setActiveTickmark(tick)
  }
  useEffect(() => {
    const filteredTagList = tagsList
      .filter(t => t?.name.toLowerCase().includes(search.toLowerCase()) || t?.description.toLowerCase().includes(search.toLowerCase()))
    setFilteredTags(filteredTagList)
  }, [tagsList, search])

  useEffect(() => {
    const selectedTasksTagIds = selectedTaskIds.map(sti => tasks[sti]).map(st => st.tagId)
    const associatedTags = new Set(tagsList.filter(tl => selectedTasksTagIds.includes(tl.id)))

    setPreActiveTickmarks(Array.from(associatedTags))
    if (associatedTags.size > 0) {
      if (associatedTags.size > 1) {
        setisMultiple(true)
        const tick = {
          name: 'MULTIPLE',
          description: ' ',
          tags: Array.from(associatedTags),
        }
        setActiveTickmark(tick)
      } else {
        const [first] = associatedTags
        setActiveTickmark(first)
      }
    }
  }, [actionSetId, selectedTaskIds, tagsList, tasks])

  return (
    <div className={styles.generator}>
      <Button className={styles.generateButton} mode="secondary" onClick={() => generateTickmark()} variant="outlined">
        <AddIcon />
        Generate Tickmark
      </Button>
      <hr className={styles.hr} />
      {activeTickmark !== null && (
        <div className={styles.tickmark}>
          <Tickmark active multiple={isMultiple} onRemove={() => remove()} tickmark={activeTickmark} />
        </div>
      )}
      <div className={classNames(styles.search, tagsList.length === 0 ? styles.disable : null)}>
        <TextField
          className={styles.searchField}
          id="standard-search"
          onBlur={() => setSearchOnFocus(false)}
          onChange={event => setSearch(event.target.value)}
          onFocus={() => setSearchOnFocus(true)}
          placeholder="Search tickmarks"
        />
        <SearchIcon
          className={classNames(styles.magnifyingGlass, searchOnFocus ? styles.focusedIcon : styles.blurredIcon)}
        />
      </div>
      <div className={classNames([styles.tickmarkContainer, filteredTags.length === 0 ? styles.emptyContainer : null])}>
        {filteredTags.length === 0 && <div className={styles.noneTickmarkText}>0 Tickmarks exist</div>}
        {filteredTags.map((tickmark) => {
          return (
            <div key={tickmark.id}>
              {tickmark?.name !== activeTickmark?.name
                && (
                  <Tickmark onTickmarkSelected={() => tickmarkSelected(tickmark)} tickmark={tickmark} />
                )}
            </div>
          )
        })}
      </div>
      <hr className={styles.hr} />
      <div className={styles.buttons}>
        <Button mode="flat" onClick={() => cancel()}>CANCEL</Button>
        <Button disabled={!anythingChanged} mode="flat" onClick={() => save()}>APPLY</Button>
      </div>
    </div>
  )
}

TickmarkGenerator.propTypes = {
  actionSetId: PropTypes.string.isRequired,
  onApply: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedTaskIds: PropTypes.arrayOf(PropTypes.number).isRequired,
}

TickmarkGenerator.defaultProps = {
}

export default TickmarkGenerator

