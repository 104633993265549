/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconGreentickInWhite = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--greentickinwhite',
    className,
  )

  return (
    <IconBase className={classes} viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg"{...rest}><title>64253346-173E-4A85-B6D6-E07573E52950</title><g fill="none" fillRule="evenodd"><ellipse fill="none" cx="20" cy="20" rx="20" ry="20"/><path className="path1" stroke="#86BD24" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" d="M13.333 22.617l3.753 4.05L28 15.76"/></g></IconBase>
  )
}

IconGreentickInWhite.propTypes = {
  className: PropTypes.string,
}

export default IconGreentickInWhite
/* eslint-enable */
