import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Banner from 'components/Banner'
import Articles from 'views/Articles'
import { fetchArticles, sendContactForm } from 'actions/support'
import Loading from 'components/Loading'
import { Heading } from '@deloitte/gel-library'
import ContactForm from 'views/ContactForm/ContactForm'
import { urlifyString } from 'utils/strings'
import { push } from 'connected-react-router'
import { Redirect, Route, Switch } from 'react-router-dom'
import Article from 'views/Article'
import { hot } from 'react-hot-loader/root'

const propTypes = {
  _fetchArticleError: PropTypes.bool.isRequired,
  _fetchedArticles: PropTypes.bool.isRequired,
  _fetchingArticles: PropTypes.bool.isRequired,
  articles: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
  })).isRequired,
  dispatch: PropTypes.func.isRequired,
  forms: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  sections: PropTypes.arrayOf(PropTypes.string).isRequired,
  user: PropTypes.shape({
    _isFetched: PropTypes.bool,
    firstName: PropTypes.string,
    isExternal: PropTypes.bool,
  }).isRequired,
}

const Support = ({
  location: { pathname },
  articles, sections,
  forms, user,
  _fetchedArticles, _fetchArticleError, _fetchingArticles,
  dispatch,
}) => {
  const { isExternal, _isFetched: userIsFetched } = user

  const userWelcome = userIsFetched ? `Hi ${user.firstName}, how can we help?` : ''

  useEffect(() => {
    if (userIsFetched) {
      dispatch(fetchArticles(isExternal))
    }
  }, [dispatch, userIsFetched, isExternal])

  const handleSentContactForm = () => {
    dispatch(sendContactForm())
  }

  const goToArticle = (article) => {
    dispatch(push(`/support/${urlifyString(article.section)}/${article.urlSlug}`))
  }

  const routes = isExternal ? [] : sections.map(s => ({
    name: s,
    path: `/support/${urlifyString(s)}`,
  }))

  const pageTitle = isExternal
    ? 'Frequently asked questions'
    : userWelcome

  const isLoading = !_fetchedArticles || _fetchingArticles || _fetchArticleError || !userIsFetched

  if (_fetchedArticles && !isExternal && sections.length > 0 && pathname === '/support') {
    return <Redirect to={`/support/${urlifyString(sections[0])}`} />
  }

  return (
    <div>
      <Switch>
        <Route exact path="/support/:articleName">
          <Banner name={pageTitle} routes={routes} />
        </Route>
        <Route>
          <Banner name={pageTitle} />
        </Route>
      </Switch>

      {isLoading && <Loading pageLoading />}

      {!isLoading && isExternal
        ? <Articles isExternal articles={articles} />
        : null}

      {!isLoading && !isExternal
        ? (
          <Switch>
            {routes.map(r => (
              <Route
                  exact
                  key={r.path}
                  path={r.path}
                  render={() => (
                    <Articles
                      articles={articles.filter(x => x.section === r.name)}
                      goToArticle={goToArticle}
                      isExternal={false}
                    />
                  )}
              />
            ))}
          </Switch>
        )
        : null}

      {!isLoading && !isExternal && (
        <Route
          exact
          path="/support/:sectionName/:urlSlug"
          render={props => <Article articles={articles} {...props} />}
        />
      )}

      {!_fetchedArticles && _fetchArticleError && (
      <div style={{ textAlign: 'center' }}>
        <Heading level={6}>Could not load FAQs</Heading>
      </div>
      )}

      <Route exact path="/support/:sectionName">
        <ContactForm
          onSupportPage
          dispatch={dispatch}
          forms={forms}
          isExternal={isExternal}
          onSubmit={handleSentContactForm}
        />
      </Route>

      <Route exact path="/support">
        <ContactForm
          onSupportPage
          dispatch={dispatch}
          forms={forms}
          isExternal={isExternal}
          onSubmit={handleSentContactForm}
        />
      </Route>

    </div>
  )
}

Support.propTypes = propTypes

const mapStateToProps = ({
  app: { user },
  forms,
  support,
}) => {
  return {
    ...support,
    forms,
    user,
  }
}

export default hot(connect(mapStateToProps)(Support))
