import React from 'react'

function IconPriorityHigh() {
  return (
    <svg fill="none" height="18" viewBox="0 0 12 18" width="12" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 8.56329L10.59 13.1533L12 11.7433L6 5.74329L0 11.7433L1.41 13.1533L6 8.56329Z" fill="#E76C5E" />
      <path d="M6 2.97332L10.59 7.56332L12 6.15332L6 0.15332L0 6.15332L1.41 7.56332L6 2.97332Z" fill="#E76C5E" />
      <path d="M7 7.36475H5V17.8468H7V7.36475Z" fill="#E76C5E" />
    </svg>
  )
}

export default IconPriorityHigh

