import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button, Heading } from '@deloitte/gel-library'
import SolutionCard from 'views/Home/components/SolutionCard'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import styles from './CollectionSolutionView.scss'

const TOGGLE_VISIBLE_SOLUTION_THRESHOLD = 4

function CollectionSolutionView({
  solutions, collection, goToSolution,
}) {
  const [showAllSolns, setShowAllSolns] = useState(false)
  const heightRef = useRef()

  const handleScroll = (ref) => {
    window.scrollTo({
      top: ref.offsetTop,
      left: 0,
      behavior: 'smooth',
    })
  }

  const handleOnClick = () => {
    if (showAllSolns) {
      handleScroll(heightRef.current)
    }
    setShowAllSolns(!showAllSolns)
  }

  return (
    <div key={collection.name} ref={heightRef}>
      <div className={styles.collectionHeading}>
        <Heading id="heading" level={4}>{`${collection.name} collection `}</Heading>
        <div className={styles.collectionCount}>
          {`${solutions.length} Solution${solutions.length > 1 ? 's' : ''}`}
        </div>
      </div>
      <div className={styles.solutionsDisplay}>
        <div className={classNames('row', styles.solutionList)}>
          {solutions.slice(0, showAllSolns ? solutions.length : TOGGLE_VISIBLE_SOLUTION_THRESHOLD).map(s => (
            <SolutionCard
              className={styles.card}
              key={s.name}
              onClick={() => goToSolution(s.name)}
              solution={s}
            />
          ))}
        </div>
        {solutions.length > TOGGLE_VISIBLE_SOLUTION_THRESHOLD && (
        <Button
          className={styles.toggleVisibleSolutionsListBtn}
          endIcon={!showAllSolns ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          mode="secondary"
          onClick={() => handleOnClick()}
        >
          {`SHOW ${showAllSolns ? 'LESS' : 'ALL'}`}
        </Button>
        )}
      </div>
    </div>
  )
}

CollectionSolutionView.propTypes = {
  collection: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
  })).isRequired,
  goToSolution: PropTypes.func.isRequired,
  solutions: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
  })).isRequired,
  user: PropTypes.shape({
    isAuOffice: PropTypes.bool,
  }).isRequired,
}

export default CollectionSolutionView

