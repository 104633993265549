import { notify } from 'actions/app'
import axios from 'axios'
import { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getConfig } from 'utils/config'
import { getApps } from '../redux/actions/actionCreators'

const useGenAiHooks = () => {
  const dispatch = useDispatch()
  const { URL_BASE: baseUrl } = getConfig()
  const aiApps = useSelector(state => state.genAiApps.aiApps)
  const hasFetched = useRef(false)

  const getSelectedApp = useCallback((selectedAppId, collection) => {
    const selectedAppCollection = aiApps[collection.toLowerCase()] || {}
    const selectedApp = selectedAppCollection[selectedAppId] || {}

    return selectedApp
  }, [aiApps])

  useEffect(() => {
    if (Object.keys(aiApps).length === 0 && !hasFetched.current) {
      dispatch(getApps())
      hasFetched.current = true
    }
  }, [aiApps, dispatch])

  const openCustomApp = useCallback(async (selectedAppId, collection) => {
    const selectedApp = getSelectedApp(selectedAppId, collection)

    if (Object.keys(selectedApp).length > 0) {
      const appConfigDto = {
        isRedirect: true,
        userInfo: selectedApp.fullAppInfo.userInfo,
        appConfig: selectedApp.fullAppInfo.appConfig,
        promptConfig: selectedApp.fullAppInfo.promptConfig,
        knowledgeBase: selectedApp.fullAppInfo.knowledgeBase,
        advancedModelSettings: selectedApp.fullAppInfo.advancedModelSettings,
      }
      const url = `${baseUrl}/gen-app/create`
      try {
        axios.post(url, appConfigDto)
        const redirectUrl = `${baseUrl}/gen-app/main?app_id=${selectedAppId}`
        window.open(redirectUrl, '_self')
      } catch (error) {
        dispatch(notify('Error loading app'))
      }
    } else {
      dispatch(notify('Unable to load app'))
    }
  }, [baseUrl, dispatch, getSelectedApp])

  return { openCustomApp }
}

export default useGenAiHooks
