import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import styles from './TagList.scss'

const TagList = (props) => {
  const {
    tags,
    className,
    style,
  } = props

  const tagPills = tags.map(tag => (
    <span
      className={classNames(styles.pill, className == null ? styles.pillDefaultStyle : className)}
      key={tag}
      style={style}
    >
      {tag}
    </span>
  ))

  return (
    <div>
      {tagPills}
    </div>
  )
}

TagList.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  tags: PropTypes.arrayOf(PropTypes.string),
}

TagList.defaultProps = {
  tags: [],
  className: styles.pillDefaultStyle,
  style: null,
}

export default TagList
