import React from 'react'
import PropTypes from 'prop-types'
import DataItem from 'actionHub/components/DataItem'
import {
  Button,
  Heading,
} from '@deloitte/gel-library'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import Loading from 'components/Loading'
import styles from './CaseDescription.scss'

const propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    displayName: PropTypes.string,
    idColumn: PropTypes.bool,
    name: PropTypes.string,
  })).isRequired,
  isFullHeight: PropTypes.bool,
  onEditDetailsClick: PropTypes.func.isRequired,
  task: PropTypes.shape({
    taskData: PropTypes.object,
  }).isRequired,
}

const defaultProps = {
  isFullHeight: false,
}

function CaseDescription({
  task, columns, isFullHeight, onEditDetailsClick,
}) {
  const normalColumns = columns.filter(x => !x.idColumn)
  const { _isUpdatingDetails } = useSelector(state => state.actionHub)
  const { packageName } = useSelector(state => state.job)
  const isControlsAdvantage = packageName.toLowerCase() === 'ControlsAdvantage'.toLowerCase()

  return (
    <>
      <div className={classNames(
        styles.content,
        styles.description,
        isFullHeight ? styles.fullHeight : null,
      )}
      >
        <div className={styles.header}>
          <Heading level={7}>Details</Heading>
          {isControlsAdvantage && (
            <Button
              className={styles.editButton}
              mode="secondary"
              onClick={onEditDetailsClick}
            >
              EDIT
            </Button>
          )}
        </div>
        {_isUpdatingDetails
          ? (
            <div className={styles.loadingIndicator}>
              <Loading pageLoading />
            </div>
          )
          : (
            <table>
              <tbody>
                {task.taskData && normalColumns.map(c => (
                  <tr className={styles.descriptionRow} key={c.name}>
                    <td className={styles.label}>{c.displayName}</td>
                    <td>
                      <DataItem column={c} value={task.taskData[c.name]} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )
      }

      </div>
    </>
  )
}

CaseDescription.propTypes = propTypes
CaseDescription.defaultProps = defaultProps

export default CaseDescription
