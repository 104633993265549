import React, { Component } from 'react'
import PropTypes from 'prop-types'
import constants from 'constants'
import { Container } from 'components/layout/Grid/'
import Banner from 'components/Banner'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { fetch as fetchJobs, resume } from 'actions/jobs'
import { fetchPackages } from 'actions/packages'
import { modalShow } from 'actions/modals'
import Loading from 'components/Loading'
import AzureDashboard from 'views/Home/AzureDashboard'
import { push } from 'connected-react-router'
import { getJobsFromIds } from 'reducers/jobs'
import IconShareReport from 'icons/IconShareReport'
import IconCreateJob from 'icons/IconCreateJob'
import { canCreateCustomReport } from 'utils/permissions'

class Home extends Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    fetch: PropTypes.func.isRequired,
    jobs: PropTypes.object.isRequired,
  }

  componentDidMount() {
    const { dispatch, fetch } = this.props
    fetch()
    dispatch(fetchPackages())
  }

  resumeJob = (jobId) => {
    const { dispatch } = this.props
    dispatch(resume(jobId))
  }

  render() {
    const {
      jobs,
      app: { user },
      dispatch,
    } = this.props
    const { firstName } = user

    const bannerName = jobs.jobs.length === 0
      ? `Welcome${firstName ? `, ${firstName}` : ''}`
      : `Welcome back${firstName ? `, ${firstName}` : ''}`

    const actionButtons = [{
      icon: <IconCreateJob size={24} />,
      label: 'Create job',
      onAction: () => {
        dispatch(push('/createjob'))
      },
    }]

    if (canCreateCustomReport(user)) {
      actionButtons.push({
        icon: <IconShareReport size={24} />,
        label: 'Add collection',
        onAction: () => dispatch(modalShow(constants.MODAL_CREATE_HOSTED_REPORT)),
      })
    }

    return (
      <div>
        <Banner
          actions={actionButtons}
          name={bannerName}
          {...this.props}
        />
        <Container noMargin topPadding>
          {!jobs.isFetched ? (
            <Loading pageLoading />
          ) : (
            <AzureDashboard resumeJob={this.resumeJob} {...this.props} />
          )}
        </Container>
      </div>
    )
  }
}

const mapStateToProps = ({
  jobs, packages, app, activity,
}) => ({
  jobs: {
    ...jobs,
    jobs: getJobsFromIds(jobs),
  },
  packages,
  app,
  activity,
})

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    fetch: () => {
      dispatch(fetchJobs())
    },
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Home),
)
