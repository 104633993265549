export const FORM_INIT = 'FORM_INIT'
export const FORM_SCHEMA_UPDATE_ADD = 'FORM_SCHEMA_UPDATE_ADD'
export const FORM_SCHEMA_UPDATE_DELETE = 'FORM_SCHEMA_UPDATE_DELETE'
export const FROM_DESTROY = 'FROM_DESTROY'
export const FORM_UPDATE = 'FORM_UPDATE'
export const FORM_ERROR = 'FORM_ERROR'
export const FORM_SUBMIT = 'FORM_SUBMIT'
export const FORM_SUBMIT_COMPLETE = 'FORM_SUBMIT_COMPLETE'
export const FORM_SUBMIT_CLEAR = 'FORM_SUBMIT_CLEAR'
export const FORM_RESET = 'FORM_RESET'
export const FORM_HAS_COMPLETED = 'FORM_HAS_COMPLETED'
export const FORM_ADD_TAG = 'FORM_ADD_TAG'
export const FORM_DELETE_TAG = 'FORM_DELETE_TAG'
export const FORM_CLEAR_FIELD = 'FORM_CLEAR_FIELD'
export const FORM_ENABLE_SUBMIT = 'FORM_ENABLE_SUBMIT'
export const FORM_OPTIONS_COMPLETE = 'FORM_OPTIONS_COMPLETE'
export const FORM_OPTIONS_UPDATE = 'FORM_OPTIONS_UPDATE'
export const FORM_UPDATE_ARRAY_MEMBER = 'FORM_UPDATE_ARRAY_MEMBER'

export const formInit = (formName, formData) => ({
  type: FORM_INIT,
  formName,
  formData,
})

export const formSchemaUpdateAdd = (formName, formData) => ({
  type: FORM_SCHEMA_UPDATE_ADD,
  formName,
  formData,
})

export const formSchemaUpdateDelete = (formName, formFieldsToDelete) => ({
  type: FORM_SCHEMA_UPDATE_DELETE,
  formName,
  formFieldsToDelete,
})

export const formDestroy = formName => ({
  type: FROM_DESTROY,
  formName,
})

export const formClearField = (formName, fieldName) => ({
  type: FORM_CLEAR_FIELD,
  formName,
  fieldName,
})

export const formUpdate = (formName, formData) => ({
  type: FORM_UPDATE,
  formName,
  formData,
})

export const formUpdateArrayMember = (formName, fieldName, value, keyProperty) => ({
  type: FORM_UPDATE_ARRAY_MEMBER,
  formName,
  fieldName,
  value,
  keyProperty,
})

export const formError = (formName, fieldName, error) => ({
  type: FORM_ERROR,
  formName,
  fieldName,
  error,
})

export const formSubmit = formName => ({
  type: FORM_SUBMIT,
  formName,
})

export const formEnableSubmit = (formName, isEnabled) => ({
  type: FORM_ENABLE_SUBMIT,
  formName,
  isEnabled,
})

export const formHasCompleted = (formName, result) => ({
  type: FORM_HAS_COMPLETED,
  formName,
  result,
})

export const formSubmitComplete = formName => ({
  type: FORM_SUBMIT_COMPLETE,
  formName,
})

export const formSubmitClear = formName => ({
  type: FORM_SUBMIT_CLEAR,
  formName,
})

export const formReset = formName => ({
  type: FORM_RESET,
  formName,
})

export const formAddTag = (formName, formData) => ({
  type: FORM_ADD_TAG,
  formName,
  formData,
})

export const formDeleteTag = (formName, fieldName, index) => ({
  type: FORM_DELETE_TAG,
  formName,
  fieldName,
  index,
})

export const formOptionsUpdate = (formName, fieldName) => ({
  type: FORM_OPTIONS_UPDATE,
  formName,
  fieldName,
})

export const formOptionsComplete = (formName, fieldName, options, query) => ({
  type: FORM_OPTIONS_COMPLETE,
  formName,
  fieldName,
  options,
  lastQuery: query,
})

export function formComplete(formName, result) {
  return (dispatch) => {
    dispatch(formSubmitComplete(formName, result))

    setTimeout(() => {
      dispatch(formHasCompleted(formName, result))
    }, 2000)

    setTimeout(() => {
      dispatch(formSubmitClear(formName))
    }, 8000)
  }
}

export function getFormOptions(formName, fieldName, query, request) {
  return (dispatch) => {
    dispatch(formOptionsUpdate(formName, fieldName))
    dispatch(
      request(query, (result) => {
        dispatch(formOptionsComplete(formName, fieldName, result, query))
      }),
    )
  }
}
