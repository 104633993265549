/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconDocumentGrey = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--documentgrey',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 20"{...rest}><g fill="none" fillRule="evenodd"><path className="path1" fill="#bdbdbd" fillRule="nonzero" d="M2 0C.9 0 .01.9.01 2L0 18c0 1.1.89 2 1.99 2H14c1.1 0 2-.9 2-2V6l-6-6H2zm7 7V1.5L14.5 7H9z"/><path className="path2" d="M-4-2h24v24H-4z"/></g></IconBase>
  )
}

IconDocumentGrey.propTypes = {
  className: PropTypes.string,
}

export default IconDocumentGrey
/* eslint-enable */
