import React from 'react'
import { push } from 'connected-react-router'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Element } from 'react-scroll'
import { Heading } from '@deloitte/gel-library'
import GenAiCard from 'features/genAi/components/GenAiCard'
import { YOURGENAIAPPS } from 'features/genAi/data'
import styles from './GenAiView.scss'

const propTypes = {
  appCollections: PropTypes.arrayOf(PropTypes.shape({
    collectionName: PropTypes.string,
    templates: PropTypes.arrayOf(PropTypes.shape({
      collection: PropTypes.string,
      description: PropTypes.string,
      key: PropTypes.string,
      name: PropTypes.string,
    })),
  })).isRequired,
  onClick: PropTypes.func.isRequired,
}

const GenAiView = ({ appCollections, onClick }) => {
  const dispatch = useDispatch()
  const handleEditClick = (appId, e) => {
    e.stopPropagation()
    dispatch(push(`/editapp/${appId}`))
  }

  return (
    <>
      {appCollections && appCollections.map(item => (
        <Element id={item.collectionName} key={item.collectionName}>
          <Heading className={styles.collectionHeading} level={6}>{`${item.collectionName.charAt(0).toUpperCase() + item.collectionName.slice(1)}`}</Heading>
          <div className={styles.collectionRow}>
            {item.templates.map(template => (
              <GenAiCard
                appId={template.appId}
                createdByUser={template.createdByUser}
                handleEditClick={handleEditClick}
                isCustomApp={item.collectionName === YOURGENAIAPPS}
                key={template.key}
                onClick={() => onClick(template.appId, template.collection)}
                templateDescription={template.description}
                templateName={template.name}
              />
            ))}
          </div>
        </Element>
      ))}
    </>
  )
}

GenAiView.propTypes = propTypes

export default GenAiView
