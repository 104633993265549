/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconCalendar = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--calendar',
    className,
  )

  return (
    <IconBase className={classes} viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg"{...rest}><title>Calendar</title><g fill="#888" fillRule="evenodd"><path className="path1" d="M.994 19.338h18.333v-12.5H.994v12.5zm2.5-16.667v1.25c0 .23.186.417.416.417h2.5c.23 0 .417-.186.417-.417v-1.25h6.667v1.25c0 .23.186.417.416.417h2.5c.23 0 .417-.186.417-.417v-1.25h2.5v3.333H.994V2.671h2.5zm.833.833h1.667v-2.5H4.327v2.5zm10 0h1.667v-2.5h-1.667v2.5zm5.417-1.666h-2.917V.588A.417.417 0 0 0 16.41.17h-2.5a.417.417 0 0 0-.416.417v1.25H6.827V.588A.417.417 0 0 0 6.41.17h-2.5a.417.417 0 0 0-.416.417v1.25H.577a.417.417 0 0 0-.417.416v17.5c0 .23.187.417.417.417h19.167c.23 0 .416-.186.416-.417v-17.5a.417.417 0 0 0-.416-.416z"/><path className="path2" d="M10.577 12.671h3.75v-2.5h-3.75v2.5zm0 3.333h3.75v-2.5h-3.75v2.5zm-4.583 0h3.75v-2.5h-3.75v2.5zm0-3.333h3.75v-2.5h-3.75v2.5zm12.083-2.5a.417.417 0 1 0 0-.833H15.16v-1.25a.417.417 0 1 0-.833 0v1.25h-3.75v-1.25a.417.417 0 1 0-.833 0v1.25h-3.75v-1.25a.417.417 0 1 0-.834 0v1.25H2.244a.417.417 0 1 0 0 .833H5.16v2.5H2.244a.417.417 0 1 0 0 .833H5.16v2.5H2.244a.417.417 0 1 0 0 .834H5.16v1.25a.417.417 0 1 0 .834 0v-1.25h3.75v1.25a.417.417 0 1 0 .833 0v-1.25h3.75v1.25a.417.417 0 1 0 .833 0v-1.25h2.917a.417.417 0 1 0 0-.834H15.16v-2.5h2.917a.417.417 0 1 0 0-.833H15.16v-2.5h2.917z"/></g></IconBase>
  )
}

IconCalendar.propTypes = {
  className: PropTypes.string,
}

export default IconCalendar
/* eslint-enable */
