import React from 'react'
import Messagebar from 'components/Messagebar'

const ImportActionsInfoText = () => {
  return (
    <Messagebar type="info">
      <div>
        Only actions that are matched with the same data inputs will be imported into the selected job.
        It will not override the action as a whole, but replace values which have been filled in the previous job.
      </div>
    </Messagebar>
  )
}

export default ImportActionsInfoText
