/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconPurchaseProcessing = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--purchaseprocessing',
    className,
  )

  return (
    <IconBase className={classes} viewBox="0 0 22 23" xmlns="http://www.w3.org/2000/svg"{...rest}><title>Purchase processing Icon</title><defs><path className="path1" d="M0 0h21.28v22.147H0V0z"/></defs><g fill="none" fillRule="evenodd"><mask fill="#fff"><use xlinkHref="#a"/></mask><path className="path2" d="M1.356 8.12H5.04c.06 0 .049.247.049.311v12.486H1.157V8.43c0-.064.138-.31.2-.31zM8.79 1.587c0-.065.023-.356.085-.356h3.683c.061 0 .164.291.164.356v19.33H8.79V1.587zm7.402 9.343c0-.065.083-.348.144-.348h3.684c.061 0 .104.283.104.348v9.988h-3.932v-9.988zm3.828-1.578h-3.684c-.7 0-1.3.834-1.3 1.578v9.988h-1.157V1.586c0-.744-.621-1.586-1.32-1.586H8.874c-.7 0-1.242.842-1.242 1.586v19.33H6.245V8.432c0-.744-.505-1.54-1.205-1.54H1.356C.656 6.89 0 7.686 0 8.43v13.337c0 .34.346.38.665.38h20.046c.32 0 .57-.04.57-.38V10.93c0-.744-.561-1.578-1.261-1.578z" fill="#58595B" mask="url(#b)"/></g></IconBase>
  )
}

IconPurchaseProcessing.propTypes = {
  className: PropTypes.string,
}

export default IconPurchaseProcessing
/* eslint-enable */
