/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconIASPrep = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--iasprep',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"{...rest}><title>Fill 1</title><path className="path1" fill="#58595B" d="M22.7 5.1H1.3C.6 5.1 0 4.6 0 3.9V2.8c0-.7.6-1.3 1.3-1.3h21.4c.7 0 1.3.6 1.3 1.3v1.1c0 .7-.6 1.2-1.3 1.2zM1.3 2.6c-.1 0-.1.1-.1.1v1.1c0 .1 0 .2.1.2h21.4c.1 0 .1-.1.1-.1V2.8c0-.1-.1-.1-.1-.1H1.3z"/><path className="path2" fill="#58595B" d="M21.3 19.3H2.7c-.7 0-1.3-.6-1.3-1.3V4.6h1.2V18c0 .1.1.1.1.1h18.7c.1 0 .1-.1.1-.1V4.6h1.2V18c-.1.7-.7 1.3-1.4 1.3z"/><path className="path3" fill="#58595B" d="M11.9 22.5c-1.2 0-2.1-.9-2.1-2.1 0-1.2.9-2.1 2.1-2.1s2.1.9 2.1 2.1c0 1.2-1 2.1-2.1 2.1zm0-3c-.5 0-.9.4-.9.9s.4.9.9.9.9-.4.9-.9-.4-.9-.9-.9zM19.5 16h-8.1c-.2 0-.3-.2-.3-.3s.2-.3.3-.3h8.1c.2 0 .3.2.3.3s-.1.3-.3.3zM9.3 8.7h-4c-.2 0-.3-.2-.3-.3s.1-.4.3-.4h4c.2 0 .3.2.3.3s-.1.4-.3.4zM9.3 11h-4c-.2 0-.3-.2-.3-.3s.2-.3.3-.3h4c.2 0 .3.2.3.3s-.1.3-.3.3zM9.3 13.4h-4c-.2 0-.3-.2-.3-.3 0-.2.2-.3.3-.3h4c.2 0 .3.2.3.3 0 .1-.1.3-.3.3z"/><path className="path4" fill="#58595B" d="M11.8 11.9H13v3.7h-1.2zM13.9 10h1.2v5.5h-1.2zM15.9 13.2h1.2v2.2h-1.2zM17.9 7.9h1.2v7.7h-1.2z"/></IconBase>
  )
}

IconIASPrep.propTypes = {
  className: PropTypes.string,
}

export default IconIASPrep
/* eslint-enable */
