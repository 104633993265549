import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Heading, Button, Dialog } from '@deloitte/gel-library'
import Card from 'components/Card'
import { fetchValidationsSummary, fetchDataValidations } from 'actions/validations'
import { goToInputdata } from 'actions/job'
import { push } from 'connected-react-router'
import DataValidationResults from 'views/DataValidationResults'
import errorImage from 'images/data-check-errors.svg'
import noErrorImage from 'images/data-check-no-errors.svg'
import IconErrorOrange from 'icons/IconErrorOrange'
import Spinner from 'components/Spinner'
import { useDispatch } from 'react-redux'
import { hot } from 'react-hot-loader/root'
import styles from './DataQuality.scss'

const VALIDATION_TYPE_DATA = 1

const propTypes = {
  job: PropTypes.shape({
    jobId: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      jobId: PropTypes.string,
    }),
  }).isRequired,
  validations: PropTypes.shape({
    _isFetchingValidationSummary: PropTypes.bool,
    validations: PropTypes.object,
  }).isRequired,
}

const DataQuality = ({
  match: { params: { jobId } },
  validations: { validations, _isFetchingValidationSummary },

}) => {
  const dispatch = useDispatch()

  const [modalVisible, setModalVisible] = useState(false)

  useEffect(() => {
    if (jobId) {
      dispatch(fetchValidationsSummary(jobId))
    }
  }, [dispatch, jobId])

  const handleBack = () => {
    dispatch(goToInputdata({ jobId }))
  }

  const moveToNextStep = () => {
    setModalVisible(false)
    dispatch(push(`/create/${jobId}/review`))
  }

  const handleContinue = () => {
    const validationArray = validations ? Object.keys(validations).map(k => validations[k]) : []
    const dataValidations = validationArray.filter(x => x.validationType === VALIDATION_TYPE_DATA && x.affectedRows > 0)

    const hasDataErrors = dataValidations.length > 0

    if (hasDataErrors) {
      setModalVisible(true)
    } else {
      moveToNextStep()
    }
  }

  const handleDataFetch = (file) => {
    const page = (file.errors.length / file.pageSize) + 1

    dispatch(fetchDataValidations(jobId, file.fileId, page))
  }

  const validationArray = validations ? Object.keys(validations).map(k => validations[k]) : []
  const dataValidationList = validationArray.filter(x => x.validationType === VALIDATION_TYPE_DATA)
  const dataValidationsWithErrors = dataValidationList.filter(x => x.affectedRows > 0)

  const hasDataErrors = dataValidationsWithErrors.length > 0

  let headingText = 'You’re all set! No errors found in your data'
  if (hasDataErrors) {
    headingText = dataValidationsWithErrors.length === dataValidationList.length
      ? `Uh oh… all ${dataValidationList.length} files contain errors`
      : `Uh oh… ${dataValidationsWithErrors.length} of ${dataValidationList.length} files contain errors`
  }

  const descriptionText = hasDataErrors
    ? 'Data errors may skew the results of your analysis. Please resolve any problems and re-upload the affected files before continuing.'
    : 'Your data contains sufficient information for the analysis. You may safely continue to the next step.'

  const titleImage = hasDataErrors ? errorImage : noErrorImage

  if (_isFetchingValidationSummary) {
    return (
      <div style={{ margin: 'auto', width: '70px', paddingTop: '200px' }}>
        <Spinner size="large" />
      </div>
    )
  }

  return (
    <div className={styles.base}>
      <Heading level={4}>Data quality check</Heading>
      <Card noPadding>
        <div className={styles.titleArea}>
          <img alt="Status illustration" className={styles.titleImage} src={titleImage} />
          <Heading level={5}>{headingText}</Heading>
          <p className={styles.titleDescription}>{descriptionText}</p>
        </div>
      </Card>

      <DataValidationResults dataValidations={dataValidationsWithErrors} onFetch={handleDataFetch} />

      <div className={styles.buttonRow}>
        <Button mode="flat" onClick={handleBack}>
          BACK
        </Button>
        <Button onClick={handleContinue}>
          CONTINUE
        </Button>
      </div>

      <Dialog
        actionLabel="Review errors"
        active={modalVisible}
        dismissLabel="Ignore and continue"
        mode="confirmation"
        onAction={() => setModalVisible(false)}
        onDismiss={moveToNextStep}
      >
        <div className={styles.confirmationModal}>
          <IconErrorOrange />
          <div>
            <Heading level={7}>
              You have unresolved errors in your data
            </Heading>
            <p>
              Your data contains errors that require your attention.
              Critical issues will produce unreliable results and data warnings may skew analysis.
              Please review before continuing.
            </p>
          </div>
        </div>
      </Dialog>
    </div>
  )
}

DataQuality.propTypes = propTypes

export default hot(DataQuality)
