/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconComplianceService = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--complianceservice',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 123.4 110"{...rest}><g fill="#58595B"><path className="path1" d="M106.5 10H79.6c-3 0-5.5 2.5-5.5 5.5v19.3c0 3 2.5 5.5 5.5 5.5H91v15l-3.1-3.1c-.8-.8-2-.8-2.8 0-.8.8-.8 2 0 2.8l6.6 6.6c.4.4.9.6 1.4.6.5 0 1-.2 1.4-.6L101 55c.8-.8.8-2 0-2.8-.8-.8-2-.8-2.8 0l-3.1 3.1v-15h11.4c3 0 5.5-2.5 5.5-5.5V15.5c0-3-2.4-5.5-5.5-5.5zm.5 24.8c0 .3-.2.5-.5.5H79.6c-.3 0-.5-.2-.5-.5V15.5c0-.3.2-.5.5-.5h26.9c.3 0 .5.2.5.5v19.3zM106.5 69.7H79.6c-3 0-5.5 2.5-5.5 5.5v19.3c0 3 2.5 5.5 5.5 5.5h26.9c3 0 5.5-2.5 5.5-5.5V75.2c0-3-2.4-5.5-5.5-5.5zm.5 24.8c0 .3-.2.5-.5.5H79.6c-.3 0-.5-.2-.5-.5V75.2c0-.3.2-.5.5-.5h26.9c.3 0 .5.2.5.5v19.3zM54.7 33.1c.4.4.9.6 1.4.6s1-.2 1.4-.6l6.6-6.6c.4-.4.6-.9.6-1.4s-.2-1-.6-1.4l-6.6-6.6c-.8-.8-2-.8-2.8 0-.8.8-.8 2 0 2.8l3.1 3.1H38.3c-5.5 0-10 4.5-10 10v6H16.8c-3 0-5.5 2.5-5.5 5.5V64c0 3 2.5 5.5 5.5 5.5h11.4v6c0 5.5 4.5 10 10 10h19.5l-3.1 3.1c-.8.8-.8 2 0 2.8.4.4.9.6 1.4.6s1-.2 1.4-.6l6.6-6.6c.8-.8.8-2 0-2.8l-6.6-6.6c-.8-.8-2-.8-2.8 0s-.8 2 0 2.8l3.1 3.1H38.3c-3.3 0-6-2.7-6-6v-6h11.4c3 0 5.5-2.5 5.5-5.5V44.7c0-3-2.5-5.5-5.5-5.5H32.3v-6c0-3.3 2.7-6 6-6h19.5l-3.1 3.1c-.8.8-.8 2 0 2.8zM44.3 44.7V64c0 .3-.2.5-.5.5h-27c-.3 0-.5-.2-.5-.5V44.7c0-.3.2-.5.5-.5h26.9c.3 0 .6.2.6.5z"/><path className="path2" d="M24.2 52.2h12.1c.8 0 1.5-.7 1.5-1.5s-.7-1.5-1.5-1.5H24.2c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5zM36.3 56.5H24.2c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5h12.1c.8 0 1.5-.7 1.5-1.5s-.6-1.5-1.5-1.5zM99.1 20H87c-.8 0-1.5.7-1.5 1.5S86.2 23 87 23h12.1c.8 0 1.5-.7 1.5-1.5S100 20 99.1 20zM99.1 27.3H87c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5h12.1c.8 0 1.5-.7 1.5-1.5s-.7-1.5-1.5-1.5zM99.1 79.7H87c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5h12.1c.8 0 1.5-.7 1.5-1.5s-.6-1.5-1.5-1.5zM99.1 87H87c-.8 0-1.5.7-1.5 1.5S86.2 90 87 90h12.1c.8 0 1.5-.7 1.5-1.5s-.7-1.5-1.5-1.5z"/></g></IconBase>
  )
}

IconComplianceService.propTypes = {
  className: PropTypes.string,
}

export default IconComplianceService
/* eslint-enable */
