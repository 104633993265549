import { uploadFile, commitSupportingFile, deleteSupportingFile, uploadSupportingFile } from 'utils/api/file'
import { deleteFile } from 'utils/api/fileGroup'
import { notify } from './app'
import {
  endUploadingFiles, finishedSpinningVisualisation, startedSpinningVisualisation, startedUploadingFiles, updatedDataPrepReqStatus, updateFileGroupFileUploadStatus, uploadFileToFileGroup, updateSupportingFilesStatus,
} from './job'

export function uploadFileGroupFiles(formsData) {
  return (dispatch, getState) => {
    const { job: { fileGroups, jobId } } = getState()
    dispatch(startedUploadingFiles())
    dispatch(startedSpinningVisualisation())
    Promise.all(
      formsData.map((formData) => {
        const fileGroupInConcern = fileGroups.find(fg => fg.name === formData.get('tableCode'))
        return uploadFile(formData, jobId)
          .then(() => {
            dispatch(uploadFileToFileGroup(fileGroups.find(fg => fg.name === formData.get('tableCode')), { name: formData.get('qqfilename'), size: fileGroupInConcern?.files.find(f => f?.name === formData.get('qqfilename')).size }))
            dispatch(updateFileGroupFileUploadStatus({ file: formData.get('file'), fileGroup: fileGroupInConcern, status: 'complete' }))
            dispatch(updatedDataPrepReqStatus('Modified'))
            dispatch(notify('Successfully uploaded file ', formData.get('file').name))
          })
          .catch((e) => {
            dispatch(notify('Failed to upload file', e))
            dispatch(updateFileGroupFileUploadStatus({ file: formData.get('file'), fileGroup: fileGroupInConcern, status: 'failed' }))
          })
      }),
    )
      .then(() => {
      })
      .catch(() => {
      })
      .finally(() => {
        dispatch(endUploadingFiles())
        setTimeout(() => { dispatch(finishedSpinningVisualisation()) }, 2000)
      })
  }
}

export function uploadSupportingFiles(formsData, isExternal) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => { // Added to ensure this function returns a Promise
      const { job: { supportingFiles, supportingFileStatus, jobId, myaId } } = getState();
      dispatch(startedUploadingFiles());
      dispatch(startedSpinningVisualisation());

      const uploadPromises = formsData.map((formData) => {
        const fileName = formData.get('file').name;
        return uploadSupportingFile(formData, myaId, isExternal)
          .then(() => {
            const commitData = {
              name: fileName,
              tablename: formData.get('tableCode'),
              size: formData.get('file').size,
            };

            return commitSupportingFile(commitData, jobId, isExternal)
              .then(() => {
                dispatch(notify(`Successfully uploaded file: ${fileName}`, fileName));
                return { name: fileName, status: 'complete' };
              })
              .catch((e) => {
                dispatch(notify(`Failed to commit file: ${fileName}`, e));
                return { name: fileName, status: 'failed' };
              });
          })
          .catch((e) => {
            dispatch(notify(`Failed to upload file: ${fileName}`, e));
            return { name: fileName, status: 'failed' };
          });
      });

      Promise.all(uploadPromises)
        .then((results) => {
          const updatedFilesWithStatus = supportingFileStatus.map((fileStatus) => {
            const result = results.find(r => r.name === fileStatus.name);
            return result ? { ...fileStatus, status: result.status } : fileStatus;
          });
          dispatch(updateSupportingFilesStatus(updatedFilesWithStatus));
          resolve();
        })
        .catch((error) => {
          console.error('Error in Promise.all:', error);
          reject(error);
        })
        .finally(() => {
          dispatch(endUploadingFiles());
          setTimeout(() => { dispatch(finishedSpinningVisualisation()) }, 2000);
        });
    });
  };
}

export function removeFile(fileGroupId, { name }, callback) {
  return (dispatch, getState) => {
    deleteFile(fileGroupId, { name })
      .then(() => {
        callback()
        dispatch(updatedDataPrepReqStatus('Modified'))
      })
      .catch((err) => {
        dispatch(notify('Failed to delete the file'))
      })
  }
}

export function removeSupportingFile(formData, jobID, isExternal) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      deleteSupportingFile(formData, jobID, isExternal)
        .then(() => {
          dispatch(notify(`File: ${formData.name} deleted.`));
          resolve();
        })
        .catch((err) => {
          dispatch(notify(`Failed to delete ${formData.name}.`));
          reject(err);
        });
    });
  };
}
