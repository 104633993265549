/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconInfoTooltip = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--infotooltip',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 17"{...rest}><g fill="none" fillRule="evenodd"><path className="path1" stroke="#888" d="M8.13 16c3.939 0 7.131-3.358 7.131-7.5 0-4.142-3.192-7.5-7.13-7.5C4.192 1 1 4.358 1 8.5 1 12.642 4.192 16 8.13 16z"/><text fill="#888" font-family="OpenSans-Semibold, Open Sans" font-size="11.409" font-weight="500"><tspan x="6.54" y="13">i</tspan></text></g></IconBase>
  )
}

IconInfoTooltip.propTypes = {
  className: PropTypes.string,
}

export default IconInfoTooltip
/* eslint-enable */
