/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconAppYammer = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--appyammer',
    className,
  )

  return (
    <IconBase className={classes} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 67.85"{...rest}><title>Yammer</title><path className="path1" d="M58.08 67.86H9.93A9.91 9.91 0 0 1 0 57.93v-48A9.91 9.91 0 0 1 9.93 0h48.15A9.91 9.91 0 0 1 68 9.93v48a9.91 9.91 0 0 1-9.92 9.9" fill="#5aa8bf" fillRule="evenodd"/><path className="path2" d="M0 9.93v48a9.91 9.91 0 0 0 9.92 9.9h48.15L9.92 0A9.91 9.91 0 0 0 0 9.93" fill="#3e8c9e" fillRule="evenodd"/><path className="path3" d="M42.38 34.93a69.37 69.37 0 0 0 9.94 1.18 2.07 2.07 0 1 0 0-4.13 69.51 69.51 0 0 0-9.94 1.18c-.58.12-2 .27-2 .89s1.43.77 2 .88M15.42 16.53a2 2 0 0 0-1.56.83 2 2 0 0 0-.21 1.86s2.6 6.71 5.35 13.57c1.31 3.34 2.64 6.7 3.7 9.38l-.87 2.18c-.21.48-.28.69-.47 1.09a3.21 3.21 0 0 1-2.39 1.77c-1.58.12-3.28.23-3.28.23a2 2 0 1 0 .25 3.9 19.07 19.07 0 0 0 4-.28c3-.78 4.5-2.76 5.51-5.24.35-.87.82-2 1.36-3.39 1.08-2.71 2.45-6.19 3.8-9.63 2.7-6.88 5.31-13.57 5.31-13.57a2 2 0 1 0-3.64-1.42s-2.61 6.7-5.31 13.57l-2.16 5.49-2.21-5.51c-2.7-6.87-5.31-13.57-5.31-13.57a2 2 0 0 0-1.75-1.27h-.12zM38.61 41.33a69.22 69.22 0 0 0 7.9 6.14 2.07 2.07 0 1 0 2.14-3.54 69.32 69.32 0 0 0-9.12-4.12c-.56-.2-1.86-.81-2.18-.28s.83 1.4 1.26 1.8M38.61 26.85a69.22 69.22 0 0 1 7.9-6.14 2.07 2.07 0 1 1 2.14 3.54 69.32 69.32 0 0 1-9.12 4.12c-.56.2-1.86.81-2.18.28s.83-1.39 1.26-1.79" fill="#fff" fillRule="evenodd"/></IconBase>
  )
}

IconAppYammer.propTypes = {
  className: PropTypes.string,
}

export default IconAppYammer
/* eslint-enable */
