/* eslint-disable react/style-prop-object */

import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Button, Heading } from '@deloitte/gel-library'
import { Container } from 'components/layout/Grid/'
import deloitteLogo from 'images/deloitte-logo-on-white.svg'
import Wordmark from 'components/Wordmark'
import { getConfig } from 'utils/config'
import styles from './Landing.scss'

const propTypes = {
  signIn: PropTypes.func.isRequired,
}

function Landing({ signIn }) {
  const { SUPPORT_EMAIL: email } = getConfig()

  return (
    <Container noMargin topPadding className={styles.base}>
      <Helmet>
        <body style="background-color: white;" />
      </Helmet>
      <div className={styles.content}>
        <div className={styles.header}>
          <img
            alt="Branding"
            className={styles.image}
            src={deloitteLogo}
          />
          <Wordmark hideLogo wordmark="InsightBox" />
        </div>
        <div className={styles.center}>
          <Heading className={styles.heading} level={4}>
            Welcome to InsightBox™
          </Heading>
          <div className={styles.buttonGroup}>
            <Button onClick={signIn}>Sign in</Button>
          </div>

          <p>
            Having difficulty?
            {' '}
            <a href={`mailto:${email}?subject=InsightBox support`}>Contact us</a>
          </p>
        </div>
      </div>
    </Container>
  )
}

Landing.propTypes = propTypes

export default Landing
