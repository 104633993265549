/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconAddJob = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--addjob',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28"{...rest}><g fill="none" fillRule="evenodd"><path className="path1" fill="#86BD24" fillRule="nonzero" d="M28 16H16v12h-4V16H0v-4h12V0h4v12h12z"/><path className="path2" d="M-10-10h48v48h-48z"/></g></IconBase>
  )
}

IconAddJob.propTypes = {
  className: PropTypes.string,
}

export default IconAddJob
/* eslint-enable */
