import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import IconBase from './IconBase'

const IconClock = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--ic_processingclock_blue',
    className,
  )

  return (
    <IconBase className={classes} viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <title>ic_access_time_black_24px copy</title>
      <g fill="none" fillRule="evenodd">
        <path
          className="path1"
          d="M24.975 0C11.175 0 0 11.2 0 25s11.175 25 24.975 25C38.8 50 50 38.8 50 25S38.8 0 24.975 0zM25 45C13.95 45 5 36.05 5 25S13.95 5 25 5s20 8.95 20 20-8.95 20-20 20z"
          fill="#009BD7"
          fillRule="nonzero"
        />
        <path className="path2" d="M-5-5h60v60H-5z" />
        <path className="path3" d="M26.25 12.5H22.5v15l13.125 7.875L37.5 32.3l-11.25-6.675z" fill="#009BD7" fillRule="nonzero" />
      </g>
    </IconBase>
  )
}

IconClock.propTypes = {
  className: PropTypes.string,
}

IconClock.defaultProps = {
  className: null,
}

export default IconClock
