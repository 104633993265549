import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import pbi from 'powerbi-client'

/**
 * Custom Power BI component that uses Microsoft's client API
 * Some tips from
 * - https://community.powerbi.com/t5/Developer/powerbi-embedded-embed-token-setting-access-level/td-p/531582
 * - https://www.dev6.com/react/embedding-microsoft-power-bi-into-react/
 */

export default class PowerBIReport extends Component {
  static propTypes = {
    accessToken: PropTypes.string.isRequired,
    embedUrl: PropTypes.string.isRequired,
    filterPaneEnabled: PropTypes.bool,
    height: PropTypes.number.isRequired,
    mode: PropTypes.oneOf(['View', 'Edit']),
    navContentPaneEnabled: PropTypes.bool,
    onEmbedded: PropTypes.func,
    reportId: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
  }

  static defaultProps = {
    filterPaneEnabled: true,
    mode: 'View',
    navContentPaneEnabled: true,
    onEmbedded: null,
  }

  constructor(props) {
    super(props)
    this.component = null
    this.rootElement = createRef()

    this.state = {
      rendered: false,
    }

    this.powerbi = new pbi.service.Service(
      pbi.factories.hpmFactory,
      pbi.factories.wpmpFactory,
      pbi.factories.routerFactory,
    )
  }

  componentDidMount() {
    this.handleUpdate()
  }

  componentDidUpdate() {
    this.handleUpdate()
  }

  componentWillUnmount() {
    this.reset()
  }

  handleUpdate = () => {
    const { rendered } = this.state

    const config = this.getConfigFromProps()

    if (this.validateConfig(config) && !rendered) {
      this.embed(config)
    }
  }

  getConfigFromProps = () => {
    const {
      mode,
      filterPaneEnabled,
      navContentPaneEnabled,
      reportId,
      embedUrl,
      accessToken,
    } = this.props

    let setViewMode
    if (mode === 'Edit') {
      setViewMode = pbi.models.ViewMode.Edit
    } else {
      setViewMode = pbi.models.ViewMode.View
    }

    return {
      accessToken,
      embedUrl,
      type: 'report',
      tokenType: pbi.models.TokenType.Embed,
      viewMode: setViewMode,
      permissions: pbi.models.Permissions.All,
      id: reportId,
      settings: {
        filterPaneEnabled,
        navContentPaneEnabled,
      },
    }
  }

  embed = (config) => {
    const { onEmbedded } = this.props
    const _this = this
    this.component = this.powerbi.embed(this.rootElement.current, config)
    if (onEmbedded) {
      onEmbedded(this.component)
    }

    this.component.on(
      'rendered',
      () => {
        if (_this.state.rendered === false) { _this.setState({ rendered: true }) }
      },
      _this,
    )
    return this.component
  }

  reset = () => {
    this.powerbi.reset(this.rootElement.current)
    this.component = null
  }

  validateConfig = (config) => {
    const errors = pbi.models.validateReportLoad(config)
    return !errors
  }

  render() {
    const { height, width } = this.props

    return (
      <div
        ref={this.rootElement}
        style={{ height, width }}
      />
    )
  }
}
