/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconAppDAIS = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--appdais',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 68"{...rest}><path className="path1" fill="#0A3A9D" d="M58.1 68H9.9C4.4 68 0 63.6 0 58.1V9.9C0 4.4 4.4 0 9.9 0h48.2C63.6 0 68 4.4 68 9.9v48.2c0 5.5-4.4 9.9-9.9 9.9"/><path className="path2" fill="#022F8A" d="M0 9.9V58c0 5.5 4.4 9.9 9.9 9.9h48.2L9.9 0C4.4 0 0 4.4 0 9.9"/><g fill="#FFF"><path className="path3" d="M24.7 34c0 2.3-.6 4.1-1.9 5.3s-3.1 1.8-5.5 1.8h-3.9V27.2h4.3c2.2 0 3.9.6 5.2 1.8 1.2 1.2 1.8 2.8 1.8 5zm-2.4.1c0-3.3-1.6-5-4.7-5h-2v10.1h1.6c3.4 0 5.1-1.7 5.1-5.1zM36.4 41.1L35 37.2h-5.3l-1.4 3.9h-2.4l5.2-14h2.5l5.2 14h-2.4zm-2-5.8l-1.3-3.8c-.1-.3-.2-.7-.4-1.2s-.3-.9-.3-1.2c-.2.8-.4 1.6-.8 2.6l-1.3 3.6h4.1zM40.6 41.1V27.2h2.3v13.9h-2.3zM54.6 37.3c0 1.2-.4 2.2-1.3 2.9-.9.7-2.1 1.1-3.7 1.1s-2.9-.2-3.9-.7v-2.2c.6.3 1.3.5 2 .7.7.2 1.4.3 2 .3.9 0 1.6-.2 2-.5.4-.3.6-.8.6-1.4 0-.5-.2-1-.6-1.3s-1.2-.8-2.4-1.3c-1.3-.5-2.2-1.1-2.7-1.8s-.8-1.5-.8-2.4c0-1.2.4-2.1 1.2-2.7.8-.7 1.9-1 3.3-1 1.3 0 2.7.3 4 .9l-.7 1.9c-1.2-.5-2.3-.8-3.3-.8-.7 0-1.3.2-1.7.5-.4.3-.6.7-.6 1.3 0 .4.1.7.2.9s.4.5.8.7c.3.2 1 .5 1.9.9 1 .4 1.8.8 2.3 1.2s.8.8 1 1.2.4 1 .4 1.6z"/></g></IconBase>
  )
}

IconAppDAIS.propTypes = {
  className: PropTypes.string,
}

export default IconAppDAIS
/* eslint-enable */
