import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './CardFooter.scss'

const CardFooter = ({
  children, className, right, left,
}) => {
  const leftStyle = left ? styles.left : {}
  return (
    <div className={`${classnames(styles.cardFooter, right ? styles.right : leftStyle)} ${className}`}>
      {children}
    </div>
  )
}

CardFooter.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  left: PropTypes.bool,
  right: PropTypes.bool,
}

CardFooter.defaultProps = {
  children: null,
  className: null,
  left: false,
  right: false,
}

export default CardFooter
