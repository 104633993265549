/* eslint-disable react/no-string-refs */
/* eslint-disable react/no-find-dom-node */

import { Component } from 'react'
import { findDOMNode } from 'react-dom'
import events from 'react-toolbox/lib/utils/events'

/*
 * A base class for Popover trigger components to extend from.
 *
 * Provides subclassed components with the state and methods to
 * track the visibility of a "popover" component.
 *
 * It also provides methods to bind/unbind root/document clicks to
 * close said popover (clicking outside the popover to close).
 *
 * A subclassed component needs to assign a "popover" ref attr to
 * the designated popover component.
 *
 * This is a base class rather than a HOC due to the requirement
 * for this to share refs to reference the popover DOM nodes.
 *
 * See ProfileButton.js for implementation.
 */

class PopoverWrapper extends Component {
  state = {
    showPopover: false,
  }

  togglePopoverDisplay = () => {
    this.setState(prevState => ({ showPopover: !prevState.showPopover }))
  }

  closePopover = () => {
    this.setState(() => ({ showPopover: false }))
  }

  bindDocumentClick = () => {
    events.addEventsToDocument({
      click: this.handleDocumentClick,
    })
  }

  unbindDocumentClick = () => {
    events.removeEventsFromDocument({
      click: this.handleDocumentClick,
    })
  }

  handleDocumentClick = (event) => {
    const { showPopover } = this.state

    const clickedOutsidePopover = !events.targetIsDescendant(event, findDOMNode(this.refs.popover))
    if (showPopover && clickedOutsidePopover) {
      this.setState(() => ({ showPopover: false }))
    }
  }
}

export default PopoverWrapper
