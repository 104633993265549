import React from 'react'
import PropTypes from 'prop-types'
import { group } from 'd3-array'
import { sortByFunction } from 'utils/arrays'
import CardList from 'components/CardList'
import ActionItem from 'views/ActionItem/ActionItem'
import { useDispatch } from 'react-redux'
import { formatDateTimeFromStringShort } from 'utils/dates'
import { push } from 'connected-react-router'
import styles from './ActionGroup.scss'

const propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object),
  canEdit: PropTypes.bool.isRequired,
  job: PropTypes.shape({
    hasExpired: PropTypes.bool,
    members: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  showHeader: PropTypes.bool,
}

const defaultProps = {
  showHeader: false,
}

function ActionGroup({
  job, actions, showHeader, canEdit,
}) {
  const dispatch = useDispatch()

  const groupedActions = Array.from(
    group(actions, a => a.execution.id),
    ([key, value]) => ({ key, value }),
  ).sort(sortByFunction(x => x.value[0].execution.executionDate, true))

  return groupedActions.map((g) => {
    const firstAction = g.value[0]
    const creator = job?.members?.find(m => m.userDetails.email === firstAction.actionSet.createdBy)
    const creatorDetails = creator ? creator.userDetails : {}
    return (
      <CardList
        action={g}
        className={styles.base}
        contentExtractor={a => (
          <ActionItem
            action={a}
            dispatch={dispatch}
            key={a.actionSet.id}
          />
        )}
        data={g.value}
        header={showHeader ? (
          <div className={styles.actionHeader}>
            <p>
              Previous run
              {' '}
              {formatDateTimeFromStringShort(firstAction.execution.executionDate)}
            </p>
            <p>
              <strong>
                Created by
                {` ${creatorDetails.firstName} ${creatorDetails.surname}`}
              </strong>
            </p>
          </div>

        ) : null}
        key={g.key}
        keyExtractor={a => a.actionSet.id}
        onClick={a => dispatch(push(`/action/${a.actionSet.id}`))}
        showImportActionButton={canEdit && !showHeader && !job.hasExpired}
      />
    )
  })
}

ActionGroup.propTypes = propTypes
ActionGroup.defaultProps = defaultProps

export default ActionGroup

