import React, { Fragment, createRef } from 'react'
import PropTypes from 'prop-types'
import JobAnchorBanner from 'components/JobAnchorBanner'
import { push } from 'connected-react-router'
import { setSeverityFilter } from 'actions/validations'
import classnames from 'classnames'
import { Button, Heading } from '@deloitte/gel-library'
import Card from 'components/Card'
import Messagebar from 'components/Messagebar'
import {
  IconArchive,
  IconInfoGrey,
  IconReportAlert,
} from 'icons'
import {
  getDataDownload,
} from 'actions/job'
import { getConfig } from 'utils/config'
import { sortByFunction } from 'utils/arrays'
import { isTacTClient } from 'utils/permissions'
import { jobExecutionFailed, jobIsProcessing } from 'utils/business/workflow'
import { formatNumber } from 'utils/numbers'
import IconClock from 'icons/IconClock'
import { jobGetMemberGroups } from 'utils/business/jobs'
import JobDetailSidebar from 'views/JobDetailSidebar'
import { Link } from 'react-router-dom'
import brokenJob from 'images/broken-job.PNG'
import ClientReportList from 'components/ClientReportList'
import Container from 'components/layout/Grid/Container'
import { hot } from 'react-hot-loader/root'
import ReActivateInfoCard from 'components/ReActivateInfoCard'
import styles from './Details.scss'
import SummaryDownload from './components/SummaryDownload'
import ClientAccess from './components/ClientAccess'
import Download from './components/Download'
import CurrentActions from './CurrentActions'


class Details extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    job: PropTypes.shape({
      _isDownloadingJobData: PropTypes.bool,
      _isFetchedActions: PropTypes.bool,
      _isFetchedReports: PropTypes.bool,
      _isFetchingReports: PropTypes.bool,
      _isFetchReportError: PropTypes.bool,
      _isReportRepublishRequested: PropTypes.bool,
      actions: PropTypes.shape({
        currentActions: PropTypes.arrayOf(PropTypes.object),
        potentialActions: PropTypes.arrayOf(PropTypes.object),
        previousActions: PropTypes.arrayOf(PropTypes.object),
      }),
      analyses: PropTypes.arrayOf(PropTypes.object),
      benchmarkingEnabled: PropTypes.string.isRequired,
      clientName: PropTypes.string,
      closed: PropTypes.bool,
      createdBy: PropTypes.shape({
        firstName: PropTypes.string,
        surname: PropTypes.string,
      }),
      createdDate: PropTypes.object,
      dataDownloadFilename: PropTypes.string,
      dataDownloadKey: PropTypes.string,
      dataDownloadName: PropTypes.string,
      downloads: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
      })).isRequired,
      dpFrom: PropTypes.object,
      dpTo: PropTypes.object,
      engagement: PropTypes.shape({
        active: PropTypes.bool,
        name: PropTypes.string,
      }),
      engagementCode: PropTypes.string,
      engagementManager: PropTypes.string,
      engagementPartner: PropTypes.string,
      hasActions: PropTypes.bool,
      hasExpired: PropTypes.bool,
      jobId: PropTypes.string,
      jobStatusDesc: PropTypes.string,
      lastExecution: PropTypes.object,
      lastExecutionDate: PropTypes.object,
      member: PropTypes.object,
      members: PropTypes.arrayOf(PropTypes.object),
      myaId: PropTypes.number,
      name: PropTypes.string,
      packageName: PropTypes.string,
      reports: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.object,
    }).isRequired,
    openJobPopup: PropTypes.func.isRequired,
    showClientAccess: PropTypes.bool.isRequired,
    uploaders: PropTypes.shape({
      uploaders: PropTypes.object,
    }).isRequired,
    user: PropTypes.shape({
      _isFetched: PropTypes.bool,
      isExternal: PropTypes.bool.isRequired,
    }).isRequired,
    validations: PropTypes.shape({
      validations: PropTypes.object,
    }).isRequired,
  }

  openMemberModal = (e) => {
    const { openJobPopup } = this.props

    e.preventDefault()
    const {
      match: {
        params: { jobId },
      },
    } = this.props
    openJobPopup({ jobId })
  }

  downloadData = () => {
    const {
      dispatch,
      match: {
        params: { jobId },
      },
    } = this.props
    dispatch(getDataDownload({ jobId }))
  }

  LoadingCards = () => {
    return [1, 2, 3].map(key => (
      <div
        className={classnames(styles.card, styles.loadPlaceholder)}
        key={key}
      >
        <div />
      </div>
    ))
  }

  ReportsList = () => {
    const {
      dispatch,
      job,
      user: { isExternal },
      validations: {
        validations,
      },
    } = this.props

    const {
      _isFetchReportError,
      _isReportRepublishRequested,
      analyses,
      jobId,
      jobStatusDesc,
      myaId,
      reports,
    } = job

    const hasCriticalValidations = Object.keys(validations).some(
      k => validations[k].affectedRows > 0 && validations[k].severity === 'Critical',
    )

    if (jobIsProcessing({ jobStatusDesc })) {
      if (_isReportRepublishRequested) {
        return (
          <Card className={styles.reportErrorCard}>
            <IconArchive height={45} width={45} />
            <div>
              <Heading className={styles.fadedHeading} level={7}>
                Restoring reports…
              </Heading>
              <p>
                This process takes about 15 minutes, but may take up to a few hours depending on volume.
                <br />
                You will be notified via email when the reports are available.
              </p>
            </div>
          </Card>
        )
      }

      if (jobExecutionFailed({ jobStatusDesc })) {
        return (
          <Card className={styles.reportErrorCard}>
            <img alt="Broken job" src={brokenJob} />
            <Heading level={6}>
              Uh oh... Something went wrong!
            </Heading>
            <p>
              Your reports have failed to process. Please get in touch
              <br />
              with our support team so we can help you fix the issue.
            </p>
            <Link className={styles.buttonLink} to={`/contact?jobRefNo=${myaId}`}>
              <Button>Get in touch</Button>
            </Link>
          </Card>
        )
      }

      return (
        <Card className={styles.reportErrorCard}>
          <IconClock height={50} width={50} />
          <Heading className={styles.processingHeading} level={7}>
            Running your analysis…
          </Heading>
          <p>This process could take a few minutes to several hours.</p>
          <p>You will receive an email when your job is complete.</p>
        </Card>
      )
    }

    if (_isFetchReportError) {
      return (
        <Card className={styles.reportErrorCard}>
          <IconInfoGrey height={50} width={50} />
          <Heading level={7}>Unable to retrieve reports</Heading>
          Tableau Server is currently unavailable. Please try again later.
        </Card>
      )
    }

    if (isExternal) {
      return (
        <ClientReportList job={job} />
      )
    }

    const matchedReports = reports
      .map(r => ({
        ...r,
        analysis: analyses.find(a => a.id === r.analysisId),
      }))
      .filter(r => r.analysis)
      .sort(sortByFunction(r => r.analysis.displayOrder))

    return matchedReports.map(report => (
      <div
        className={classnames(styles.card, {
          [styles.critical]: hasCriticalValidations,
        })}
        key={report.analysisId}
      >
        <Card noPadding>
          <IconReportAlert size="18" />
          <p className={styles.cardTitle}>{report.analysis.name}</p>
          <div className={styles.cardAction}>
            <Button
              className={styles.cardCTA}
              mode="flat"
              onClick={() => dispatch(push(`/report/${jobId}/${report.analysis.id}`))}
            >
              View
            </Button>
          </div>
        </Card>
      </div>
    ))
  }

  render() {
    const {
      showClientAccess,
      match: {
        params: { jobId },
      },
      job,
      job: {
        _isDownloadingJobData,
        _isFetchedReports,
        dataDownloadFilename,
        dataDownloadName,
        dataDownloadKey,
        downloads,
        members,
        lastExecution,
        hasActions,
      },
      user,
      validations: { validations },
      dispatch,
      canAccessActionHub,
    } = this.props

    const {
      currentActions,
      potentialActions,
    } = job.actions || {}

    const {
      URL: { SUPPORT: supportUrl },
    } = getConfig()

    const { externalTeam } = jobGetMemberGroups(members)

    const validationArray = Object.keys(validations).map(k => validations[k])
    const criticalValidations = validationArray
      .filter(x => x.severity === 'Critical' && x.affectedRows > 0)

    const numSampleValidations = 3
    const sampleCriticalValidations = criticalValidations.slice(0, numSampleValidations)

    const nonCriticalValidations = validationArray
      .filter(x => x.severity !== 'Critical' && x.affectedRows > 0)
    const nonCriticalValidationRowCount = nonCriticalValidations
      .map(x => x.affectedRows)
      .reduce((a, b) => a + b, 0)
    const moreNonCriticalsThanListed = nonCriticalValidations
      .some(x => x.affectedRows === 1000)

    const hasDownloads = downloads.length > 0
    const isProcessing = jobIsProcessing(job)

    const showCurrentActions = currentActions && currentActions.length > 0

    const showPotentialActions = potentialActions
      && !isProcessing
      && !showCurrentActions
      && lastExecution

    const potentialActionSum = showPotentialActions && potentialActions
      .map(x => x.totalActions)
      .reduce((a, b) => a + b, 0)

    const isWarning = (criticalValidations.length > 0 || nonCriticalValidations.length > 0)
    const isAction = (showPotentialActions === null || lastExecution.isCurrentPackageVersion === null || potentialActionSum == null)
      ? false : !(showPotentialActions && lastExecution.isCurrentPackageVersion && potentialActionSum === 0)

    const reportRef = createRef()
    const actionsRef = createRef()

    const types = [
      isWarning ? 'WARNINGS' : null,
      isWarning || isAction ? 'REPORTS' : null,
      isAction ? 'ACTIONS' : null,
    ]

    const isTactClient = isTacTClient(user.isExternal, this.props?.job?.packageId) || false

    return (
      <div>
        {!isProcessing && (
          <JobAnchorBanner
            actionRef={actionsRef}
            dispatch={dispatch}
            hasDownloads={hasDownloads}
            job={job}
            reportRef={reportRef}
            types={types}
            user={user}
            validations={validations}
          />
        )}
        <Container className={styles.base}>
          <div className="row">
            {!isTactClient
            && (
              <div className={classnames('col-xs-12 col-sm-12 col-lg-12', styles.Download)}>
                {downloads.length > 0 && !isProcessing && (
                  <Fragment>
                    {downloads.map(d => (
                      <Download
                        download={d}
                        jobId={jobId}
                        key={d.name}
                      />
                    ))}
                  </Fragment>
                )}
                {dataDownloadName && (
                  <Fragment>
                    <SummaryDownload
                      downloadDisabled={_isDownloadingJobData}
                      downloadFunc={this.downloadData}
                      filename={dataDownloadFilename}
                      name={dataDownloadName}
                      password={dataDownloadKey}
                    />
                  </Fragment>
                )}
              </div>
            )
            }
          </div>

          <div className="row">
            <div className="col-xs-12 col-sm-7 col-lg-9">
              {(job?.hasExpired && user.isExternal) && (
                <ReActivateInfoCard />
              )}
              {(job?.hasExpired && user.isExternal) ? <></> : criticalValidations.length > 0 && (
                <Messagebar
                  className={classnames(styles.messagebar, styles.critical)}
                  type="error"
                >
                  <span>
                    <p className={styles.messageHeader}>Results are unreliable due to critical data issues. Do not use results until critical issues are resolved.</p>
                    <ul>
                      {sampleCriticalValidations.map(x => (
                        <li key={x.validationName}>{x.title}</li>
                      ))}
                    </ul>
                    {criticalValidations.length > numSampleValidations && <span>{`+${criticalValidations.length - numSampleValidations} more`}</span>}
                  </span>
                  <Button
                    className={styles.link}
                    mode="flat"
                    onClick={() => {
                      dispatch(setSeverityFilter('Critical'))
                      dispatch(push(`/job/${jobId}/errors`))
                    }}
                  >
                    View critical issues
                  </Button>
                </Messagebar>
              )}

              {(job?.hasExpired && user.isExternal) ? <></> : nonCriticalValidations.length > 0 && (
                <Messagebar
                  className={styles.messagebar}
                  type="warn"
                >
                  <span>
                    <p className={styles.messageHeader}>Results may be skewed due to data warnings</p>
                    <p>
                      {formatNumber(nonCriticalValidationRowCount)}
                      {moreNonCriticalsThanListed ? '+ ' : ' '}
                      rows require your attention. Please investigate before ignoring as these may skew analysis.
                    </p>
                  </span>
                  <Button
                    className={styles.link}
                    mode="flat"
                    onClick={() => {
                      dispatch(setSeverityFilter('Normal'))
                      dispatch(push(`/job/${jobId}/errors`))
                    }}
                  >
                    View warnings
                  </Button>
                </Messagebar>
              )}
              <div className={isWarning ? styles.content : null} ref={reportRef}>
                <Heading level={8}>Reports</Heading>
                <div className={styles.cards}>
                  {!_isFetchedReports ? (
                    <this.LoadingCards />
                  ) : (
                    <this.ReportsList />
                  )}
                </div>
              </div>

              {(canAccessActionHub && hasActions && !isTacTClient(user.isExternal, this.props.job.packageId) && !isProcessing && !(job.hasExpired && user.isExternal)) && (
                <div className={styles.content} ref={actionsRef}>
                  <CurrentActions
                    job={job}
                    uploaders={{}}
                    user={user}
                    validations={validations}
                  />
                </div>
              )}
            </div>

            <div className="col-xs-12 col-sm-5 col-lg-3">
              <JobDetailSidebar
                criticalValidations={criticalValidations}
                job={job}
                supportUrl={supportUrl}
              />
              {showClientAccess && externalTeam && (
                <ClientAccess
                  externalTeam={externalTeam}
                />
              )}

            </div>
          </div>
        </Container>
      </div>
    )
  }
}

export default hot(Details)
