import React, { useEffect, useState } from 'react'
import { reActivateJob } from 'actions/job'
import {
  Checkbox, DatePicker,
} from '@deloitte/gel-library'
import { useDispatch, useSelector } from 'react-redux'
import { modalCreate, modalHide } from 'actions/modals'
import { RE_ACTIVATE_JOB_MODAL } from 'constants/forms'
import DialogSimple from 'components/DialogSimple'
import PropTypes from 'prop-types'
import {
  TextField,
} from '@material-ui/core'
import {
  getDateFromNYearsInFuture, getDifferenceInDays, isValidDate, makeDate,
} from 'utils/dates'
import styles from './ReActivateJobModal.scss'

const MAX_NUMBER = 100

const propTypes = {
  originalExpiryDate: PropTypes.instanceOf(Date).isRequired,
  originalNoOfRuns: PropTypes.number.isRequired,
}

const ReActivateJobModal = ({ originalNoOfRuns, originalExpiryDate }) => {
  const dispatch = useDispatch()
  const [containsErrors, setContainsErrors] = useState(false)
  const modals = useSelector(state => state.modals)
  const show = RE_ACTIVATE_JOB_MODAL in modals && modals[RE_ACTIVATE_JOB_MODAL].show

  useEffect(() => {
    dispatch(modalCreate(RE_ACTIVATE_JOB_MODAL))
  }, [dispatch])
  const { jobId } = useSelector(state => state.job)
  const [noOfRuns, setNoOfRuns] = useState(originalNoOfRuns)
  const [showExpiryHelperText, setShowExpiryHelperText] = useState(true)
  const [expiryDate, setExpiryDate] = useState(originalExpiryDate)
  const [checked, setChecked] = useState(false)
  const [errorMessageForNoOfRuns, setErrorMessageForNoOfRuns] = useState('')

  const differenceInNoOfRuns = noOfRuns - originalNoOfRuns
  const differenceInExpiryDates = getDifferenceInDays(new Date(originalExpiryDate), new Date(expiryDate))
  const isRunsUpdated = differenceInNoOfRuns !== 0
  const isExpiryUpdated = differenceInExpiryDates !== 0
  const isDirty = isRunsUpdated || isExpiryUpdated

  useEffect(() => {
    if (new Date(expiryDate) > getDateFromNYearsInFuture(1) || new Date(expiryDate) < new Date(originalExpiryDate) || !isValidDate(new Date(expiryDate))) {
      setShowExpiryHelperText(false)
      setContainsErrors(true)
    } else {
      setShowExpiryHelperText(true)
      setContainsErrors(false)
    }
  }, [expiryDate, originalExpiryDate])

  useEffect(() => {
    if (noOfRuns > MAX_NUMBER) {
      setErrorMessageForNoOfRuns(
        'Maximum number of runs allowed is 100',
      )
      setContainsErrors(true)
    } else if (noOfRuns < originalNoOfRuns) {
      setErrorMessageForNoOfRuns(
        `Minimum number of runs allowed is ${originalNoOfRuns}`,
      )
      setContainsErrors(true)
    } else {
      setErrorMessageForNoOfRuns(
        `Add ${differenceInNoOfRuns} additional runs`,
      )
      setContainsErrors(false)
    }
  }, [noOfRuns, originalNoOfRuns, differenceInNoOfRuns])

  let noOfRunsHelperTextStyle = null

  if (!isRunsUpdated) {
    noOfRunsHelperTextStyle = styles.unChangedHelperText
  }
  if (!(noOfRuns > MAX_NUMBER || noOfRuns < originalNoOfRuns)) {
    noOfRunsHelperTextStyle = styles.validHelperText
  }

  const expiryDateHelperTextStyle = isExpiryUpdated
    ? styles.validExpiryDateHelperText
    : styles.unchangedExpiryDateHelperText

  const minDate = originalExpiryDate < new Date() ? new Date() : originalExpiryDate

  return (
    <DialogSimple
      actionLabel="confirm"
      className={styles.popupAdditionalClassName}
      disabled={!checked ? true : (containsErrors || !isDirty)}
      dismissLabel="cancel"
      isOpen={show}
      onAction={() => {
        dispatch(modalHide(RE_ACTIVATE_JOB_MODAL))
        setChecked(false)
        dispatch(
          reActivateJob({
            jobId,
            executionLimit: isRunsUpdated ? noOfRuns : null,
            expiryDate: isExpiryUpdated ? makeDate(expiryDate) : null,
          }),
        )
      }}
      onDismiss={() => {
        dispatch(modalHide(RE_ACTIVATE_JOB_MODAL))
        setNoOfRuns(originalNoOfRuns)
        setExpiryDate(originalExpiryDate)
        setChecked(false)
      }}
    >
      <div className={styles.heading}>Reactivate job</div>
      <div className={styles.subHeading}>Number of runs</div>
      <div className={styles.secondaryText}>
        Number of runs added for this subscroption (max 100)
      </div>
      <TextField
        fullWidth
        FormHelperTextProps={{
          className: noOfRunsHelperTextStyle,
        }}
        InputProps={{ inputProps: { min: originalNoOfRuns, max: MAX_NUMBER } }}
        className={styles.textField}
        defaultValue={originalNoOfRuns}
        error={noOfRuns > MAX_NUMBER || noOfRuns < originalNoOfRuns}
        helperText={errorMessageForNoOfRuns}
        onChange={e => setNoOfRuns(parseInt(e.target.value, 10))}
        type="number"
        value={noOfRuns}
      />
      <div className={styles.subHeading}>Job expiry date</div>
      <div className={styles.secondaryText}>
        The job or its data cannot be accessed after this date
      </div>
      <DatePicker
        maxDate={getDateFromNYearsInFuture(1)}
        maxDateMessage="Date cannot be more than 1 year from today."
        minDate={minDate}
        minDateMessage="Date cannot be in the past, or before the original expiry date."
        onChange={setExpiryDate}
        value={expiryDate}
      />
      {showExpiryHelperText
        && (
        <div className={expiryDateHelperTextStyle}>
          Extend for
          {' '}
          {differenceInExpiryDates}
          {' '}
          days
        </div>
        )}
      <div className={styles.infoText}>
        Before confirming the changes, please confirm the impact of any variations to the contract or license agreement with your client.
      </div>
      <Checkbox
        checked={checked}
        className={styles.checkBox}
        handleChange={() => setChecked(!checked)}
        label="I confirm the changes"
      />
    </DialogSimple>
  )
}
ReActivateJobModal.propTypes = propTypes

export default ReActivateJobModal
