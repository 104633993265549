import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { hot } from 'react-hot-loader/root'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import CreateReport from 'views/Create/CreateReport'
import { fetchPackages } from 'actions/packages'
import { getWorkflowSteps } from 'utils/business/workflow'
import ProgressHeader from 'views/Create/ProgressHeader'
import Spinner from 'components/Spinner'

class CreateJob extends Component {
  static propTypes = {
    app: PropTypes.shape({
      user: PropTypes.shape({
        _isFetched: PropTypes.bool,
      }),
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        isCloned: PropTypes.string,
      }),
    }),
  }

    static defaultProps = {
      match: null,
    }

    componentDidMount() {
      const { dispatch } = this.props
      dispatch(fetchPackages())
    }

    render() {
      const {
        app: { user: { _isFetched } },
        match: { params: { isCloned } },
      } = this.props
      const steps = getWorkflowSteps()
      if (isCloned && isCloned === 'true') {
        steps.find(step => step.id === 1).label = 'Copy job'
      }

      return (
        <div>
          <ProgressHeader
            currentStep={1}
            maximumStepAccess={1}
            steps={steps}
          >

            {_isFetched
              ? (
                <CreateReport
                  isCloned={isCloned === 'true'}
                  {...this.props}
                />
              )
              : (
                <div style={{ margin: 'auto', width: '70px', paddingTop: '200px' }}>
                  <Spinner size="large" />
                </div>
              )
          }

          </ProgressHeader>

        </div>
      )
    }
}

const mapStateToProps = state => ({
  job: state.job,
  packages: state.packages,
  engagementCodes: state.engagementCodes,
  forms: state.forms,
  tests: state.tests,
  app: state.app,
  clonedJobDetails: state.clonedJobDetails,
})

export default hot(withRouter(connect(mapStateToProps)(CreateJob)))
