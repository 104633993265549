import map from 'lodash/map'
import union from 'lodash/union'
import sortBy from 'lodash/sortBy'
import DOMPurify from 'dompurify'

export function optionsFormat(
  options,
  format = ['label:name', 'value:id', 'email:email'],
) {
  return options.map((option) => {
    const opt = {}
    format.forEach((i) => {
      const keys = i.split(':')
      const optionKey = keys[0]
      const valueKey = `${keys[1]}`
      opt[optionKey] = option[valueKey]
    })
    return opt
  })
}

export function flattenFields(arr) {
  return arr.reduce((flat, toFlatten) => {
    return flat.concat(
      Array.isArray(toFlatten.elements)
        ? flattenFields(toFlatten.elements)
        : toFlatten,
    )
  }, [])
}

export function getRequiredFields(fields) {
  return flattenFields(fields).filter(
    field => field.validators && field.validators.length,
  )
}

// Because selects (or "pick-lists" as they call them in Salesforce), are
// a flat list of strings -- not an array of labels + values
export function optionsFromValues(values) {
  return map(union(sortBy(values)), value => ({
    label: value,
    value,
  }))
}

export function sanitizeInput(input, removeLinks = false) {
  return removeLinks ? DOMPurify.sanitize(input, { FORBID_TAGS: ['a'] }) : DOMPurify.sanitize(input)
}

export function sanitizeObject(obj) {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key, sanitizeInput(value)]),
  )
}
