import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import IconBase from './IconBase'

const IconAddUser = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--adduser',
    className,
  )

  return (
    <IconBase className={classes} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <title>ic_person_add_black_24px (1)</title>
      <g fill="none" fillRule="evenodd">
        <path className="path1" d="M0 0h24v24H0z" />
        <path
          className="path2 fill"
          d="M15 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm-9-2V7H4v3H1v2h3v3h2v-3h3v-2H6zm9 4c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
          fill="#26890D"
          fillRule="nonzero"
        />
      </g>
    </IconBase>
  )
}

IconAddUser.propTypes = {
  className: PropTypes.string,
}

IconAddUser.defaultProps = {
  className: null,
}

export default IconAddUser
