/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconFacebook = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--facebook',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 13"{...rest}><path className="path1" fill="#888" fillRule="evenodd" d="M5.057.458c-1.886 0-3.105 1.16-3.105 2.953v1.362H.264c-.146 0-.264.11-.264.245V6.99c0 .135.118.244.264.244h1.688v4.978c0 .136.118.245.264.245H4.42c.146 0 .264-.11.264-.245V7.235h1.974c.145 0 .264-.11.264-.244V5.018a.236.236 0 0 0-.077-.173.275.275 0 0 0-.187-.072H4.683V3.619c0-.555.142-.837.922-.837h1.131c.146 0 .264-.11.264-.245V.705C7 .57 6.882.461 6.736.461L5.057.458z"/></IconBase>
  )
}

IconFacebook.propTypes = {
  className: PropTypes.string,
}

export default IconFacebook
/* eslint-enable */
