import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import IconBase from './IconBase'

const IconChevron = ({
  className, title, left, ...rest
}) => {
  const classes = classnames('icon', 'icon--chevronright', className)

  return (
    <IconBase
      className={classes}
      style={
        left
          ? { transformOrigin: 'center', transform: 'rotate(180deg)' }
          : {}
      }
      viewBox="0 0 10 16"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <title>{title}</title>
      <g fill="none" fillRule="evenodd">
        <path
          className="path2"
          d="M2.5.5L.738 2.262 6.462 8 .738 13.738 2.5 15.5 10 8z"
          fill="#6D6D6D"
          fillRule="nonzero"
        />
      </g>
    </IconBase>
  )
}

IconChevron.propTypes = {
  className: PropTypes.string,
  left: PropTypes.bool,
  title: PropTypes.string,
}

IconChevron.defaultProps = {
  className: null,
  left: false,
}

IconChevron.defaultProps = {
  title: 'Next',
  left: false,
}

export default IconChevron
