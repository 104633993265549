/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconAppQuickbooks = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--appquickbooks',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 68"{...rest}><path className="path1" fill="#43A813" d="M58.1 68H9.9C4.4 68 0 63.6 0 58.1V9.9C0 4.4 4.4 0 9.9 0h48.2C63.6 0 68 4.4 68 9.9v48.2c0 5.5-4.4 9.9-9.9 9.9"/><path className="path2" fill="#28A038" d="M0 9.9v48.2C0 63.6 4.4 68 9.9 68h48.2L9.9 0C4.4 0 0 4.4 0 9.9"/><path className="path3" fill="#E7F3F7" d="M23.8 20.9c-7.2 0-13 5.8-13 13s5.8 13 13 13H25v-4.6h-1.2c-4.7 0-8.4-3.8-8.4-8.4 0-4.7 3.8-8.4 8.4-8.4h4.1v27.8h4.7V20.9h-8.8zM35.5 14.7v32.4h8.8c7.2 0 13-5.8 13-13s-5.8-13-13-13H43v4.6h1.2c4.7 0 8.4 3.8 8.4 8.4 0 4.7-3.8 8.4-8.4 8.4h-4.1V14.7h-4.6z"/></IconBase>
  )
}

IconAppQuickbooks.propTypes = {
  className: PropTypes.string,
}

export default IconAppQuickbooks
/* eslint-enable */
