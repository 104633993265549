/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconProfile = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--profile',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"{...rest}><path className="path1" fill="#58595B" d="M22.4 24H1.6c-.4 0-.7-.3-.7-.7 0-5.1.9-9.9 7.5-10.3.2 0 .4.1.5.2l3 3 3-3c.1-.1.3-.2.5-.2 6.6.4 7.5 5.2 7.5 10.3.2.4-.1.7-.5.7zM2.3 22.6h19.5c-.1-4.9-1.2-7.9-6-8.3l-3.3 3.3c-.1.1-.3.2-.5.2s-.3-.1-.5-.2l-3.3-3.3c-4.8.5-5.9 3.4-5.9 8.3zM12 12.7c-2.8 0-5.1-2.5-5.5-6.2-.2-1.9.2-3.5 1.3-4.7C8.8.7 10.3 0 12 0c1.7 0 3.2.6 4.2 1.7 1.1 1.2 1.5 2.9 1.3 4.7-.4 3.8-2.7 6.3-5.5 6.3zm.1-11.3s-.1 0 0 0c-1.5 0-2.6.5-3.3 1.3-.8.9-1.1 2.1-1 3.6.4 3.5 2.4 5.1 4.2 5.1 1.8 0 3.8-1.6 4.2-5.1.2-1.5-.2-2.8-1-3.7-.7-.8-1.8-1.2-3.1-1.2z"/></IconBase>
  )
}

IconProfile.propTypes = {
  className: PropTypes.string,
}

export default IconProfile
/* eslint-enable */
