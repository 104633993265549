/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconActions = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--actions',
    className,
  )

  return (
    <IconBase className={classes} viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg"{...rest}><title>Search</title>
    <path d="M30 5C16.2 5 5 16.2 5 30C5 43.8 16.2 55 30 55C43.8 55 55 43.8 55 30C55 16.2 43.8 5 30 5Z" fill="white"/>
    <path d="M30 5C16.2 5 5 16.2 5 30C5 43.8 16.2 55 30 55C43.8 55 55 43.8 55 30C55 16.2 43.8 5 30 5Z" fill="white"/>
    <path d="M30 4C15.6477 4 4 15.6477 4 30H6C6 16.7523 16.7523 6 30 6V4ZM4 30C4 44.3523 15.6477 56 30 56V54C16.7523 54 6 43.2477 6 30H4ZM30 56C44.3523 56 56 44.3523 56 30H54C54 43.2477 43.2477 54 30 54V56ZM56 30C56 15.6477 44.3523 4 30 4V6C43.2477 6 54 16.7523 54 30H56Z" fill="#26890D"/>
    <path d="M32.4166 21.7502L30.9583 18.8335H19.2916V43.6252H22.2083V33.4168H29.5L30.9583 36.3335H41.1666V21.7502H32.4166ZM38.25 33.4168H32.4166L30.9583 30.5002H22.2083V21.7502H29.5L30.9583 24.6668H38.25V33.4168Z" fill="#26890D"/>
</IconBase>
  )
}

IconActions.propTypes = {
  className: PropTypes.string,
}

export default IconActions
/* eslint-enable */
