import * as ActionTypes from 'actions/jobs'
import uniqBy from 'lodash/uniqBy'
import union from 'lodash/union'
import { objectFromArray } from 'utils/objects'
import { sortByFunction } from 'utils/arrays'
import { jobInitialState } from './job'

const initialState = {
  engagements: [],
  jobs: {},
  jobIds: [],
  filters: {
    status: '',
    client: '',
    package: '',
    search: '',
  },
  status: [],
  clients: [],
  packages: [],
  isFetching: false,
  isFetched: false,
  _isAdminJobsLoaded: false,
}

export const getJobsFromIds = state => state.jobIds.map(x => state.jobs[x])

export default function jobs(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.JOBS_FETCHED: {
      const sortedJobs = action.jobs.sort(sortByFunction(x => new Date(x.lastModifiedDate), true))

      return {
        ...state,
        isFetching: false,
        isFetched: true,
        jobs: objectFromArray(
          action.jobs.map(j => ({ ...jobInitialState, ...j })),
          'jobId',
        ),
        jobIds: action.jobs.map(j => j.jobId),
        status: union(action.jobs.map(job => job.progressStatus)),
        clients: union(action.jobs.map(job => job.clientName)).filter(
          name => name,
        ),
        engagements: uniqBy(sortedJobs.map(job => job.engagement), x => x.code)
          .map(e => ({
            ...e,
            jobs: sortedJobs.filter(x => x.engagementCode === e.code)
              .map(x => x.jobId),
          })),
        packages: union(action.jobs.map(job => job.packageName)),
        _isAdminJobsLoaded: action.isAdmin,
      }
    }
    case ActionTypes.JOBS_FETCHING:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case ActionTypes.JOBS_UPDATE_JOB: {
      if (!state.isFetched) {
        return state
      }
      const { job } = action

      return {
        ...state,
        jobs: {
          ...state.jobs,
          [job.jobId]: {
            ...state.jobs[job.jobId],
            ...job,
          },
        },
      }
    }
    case ActionTypes.JOBS_SET_FILTERS: {
      const { type, value } = action.filters
      return Object.assign({}, state, {
        ...state,
        filters: {
          ...state.filters,
          [type]: value,
        },
      })
    }
    case ActionTypes.JOBS_CLEAR_FILTERS:
      return {
        ...state,
        filters: initialState.filters,
      }
    case ActionTypes.JOBS_CLEAR_JOBS:
      return {
        ...initialState,
      }
    case ActionTypes.JOBS_ADMIN_RESET_END:
      return {
        ...state,
        jobs: {
          ...state.jobs,
          [action.jobId]: {
            ...state.jobs[action.jobId],
            jobStatusDesc: 'NoData',
          },
        },
      }
    default:
      return state
  }
}
