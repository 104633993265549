/* eslint-disable react/forbid-prop-types */

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Heading from 'components/Heading'
import Card from 'components/Card'
import BarChart from 'components/BarChart'
import InfoTooltip from 'components/InfoTooltip'
import RankTable from 'components/RankTable'
import { formatNumber, formatMoney } from 'utils/numbers'
import styles from './Activity.scss'

class ActivityView extends React.Component {
  static propTypes = {
    _isFetched: PropTypes.bool,
    businessUnit: PropTypes.string,
    clients: PropTypes.number,
    dataProcessed: PropTypes.number,
    engagements: PropTypes.number,
    locations: PropTypes.array,
    savings: PropTypes.number,
    testran: PropTypes.number,
    totalNumberOfUsers: PropTypes.number,
    users: PropTypes.array,
  }

  static defaultProps = {
    _isFetched: false,
    businessUnit: null,
    clients: null,
    dataProcessed: null,
    engagements: null,
    locations: [],
    savings: null,
    testran: null,
    totalNumberOfUsers: null,
    users: [],
  }

  render() {
    const {
      businessUnit, locations, users, clients, dataProcessed, engagements, savings, testran, totalNumberOfUsers, _isFetched,
    } = this.props
    return (
      <div className={styles.base}>
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <Heading size="h8">
              {businessUnit ? `${businessUnit} activity` : 'Activity'}
            </Heading>
            <Card noPadding>
              <section className={styles.card}>
                {clients ? (
                  <div>
                    <Heading
                      noPadding
                      size="h3"
                    >
                      {formatNumber(clients)}
                    </Heading>
                    <p>
                      Clients
                    </p>
                  </div>
                ) : <div />}
              </section>
              <section className={styles.card}>
                {engagements ? (
                  <div>
                    <Heading
                      noPadding
                      size="h3"
                    >
                      {formatNumber(engagements)}
                    </Heading>
                    <p>
                      Engagements
                    </p>
                  </div>
                ) : <div />}
              </section>
              <section className={styles.card}>
                {dataProcessed ? (
                  <div>
                    <Heading
                      noPadding
                      size="h3"
                    >
                      {formatNumber(dataProcessed)}
                    </Heading>
                    <p>
                      Data processed
                    </p>
                  </div>
                ) : <div />}
              </section>
              <section className={styles.card}>
                {testran ? (
                  <div>
                    <Heading
                      noPadding
                      size="h3"
                    >
                      {formatNumber(testran)}
                    </Heading>
                    <p>
                      Tests run
                    </p>
                  </div>
                ) : <div />}
              </section>
              <section className={styles.card}>
                {savings ? (
                  <div>
                    <Heading
                      noPadding
                      size="h3"
                    >
                      {formatMoney(savings)}
                    </Heading>
                    <p>
                      Efficiency savings
                      {' '}
                      <InfoTooltip tip="Based on total number of tests run" />
                    </p>
                  </div>
                ) : <div />}
              </section>
            </Card>
          </div>
        </div>
        <div className={classnames('row', styles.bottomRow)}>
          <div className="col-xs-12 col-sm-6 col-md-6">
            <Card className={styles.chartCard}>
              <h4>
                Number of engagements by location
              </h4>
              {/* don't render bar chart until we received data from server */}
              {_isFetched && (
              <BarChart
                data={locations}
                height={402}
                xProperty="locationName"
                yProperty="numberOfEngagements"
              />
              )}
            </Card>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-6">
            <Card
              noPadding
              className={styles.rankCard}
            >
              <RankTable
                data={users}
                total={totalNumberOfUsers}
              />
            </Card>
          </div>
        </div>
      </div>
    )
  }
}

export default ActivityView
