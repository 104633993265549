/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconEfficiencySavings = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--efficiencysavings',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"{...rest}><defs><path className="path1" d="M0 0h47.922v47.922H0z"/></defs><g fill="none" fillRule="evenodd"><path className="path2" fill="#86BD24" d="M31.077 29.966c0-3.513-2.61-6.422-5.99-6.909v-9.95a5.009 5.009 0 0 1 3.327 2.385.998.998 0 1 0 1.729-.999 7.015 7.015 0 0 0-5.056-3.42V9.999a.998.998 0 0 0-1.997 0v1.079c-3.38.487-5.99 3.395-5.99 6.909 0 3.513 2.61 6.422 5.99 6.909v9.95a4.992 4.992 0 0 1-3.709-3.212.998.998 0 1 0-1.882.666 6.997 6.997 0 0 0 5.591 4.577v2.076a.998.998 0 0 0 1.997 0v-2.077c3.38-.487 5.99-3.395 5.99-6.909m-11.98-11.98a5 5 0 0 1 3.993-4.891v9.781a5 5 0 0 1-3.994-4.89m5.99 16.871v-9.782a5 5 0 0 1 3.995 4.891 5 5 0 0 1-3.994 4.891"/><mask fill="#fff"><use xlinkHref="#a"/></mask><path className="path3" fill="#86BD24" d="M23.961 1.997c12.112 0 21.965 9.853 21.965 21.964 0 12.111-9.853 21.965-21.965 21.965-12.111 0-21.964-9.854-21.964-21.965S11.85 1.997 23.961 1.997m0-1.997C10.728 0 0 10.728 0 23.961c0 13.233 10.728 23.961 23.961 23.961 13.234 0 23.962-10.728 23.962-23.96C47.923 10.727 37.195 0 23.96 0" mask="url(#b)"/></g></IconBase>
  )
}

IconEfficiencySavings.propTypes = {
  className: PropTypes.string,
}

export default IconEfficiencySavings
/* eslint-enable */
