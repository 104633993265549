import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/styles'
import styles from './TagNarrative.scss'

const propTypes = {
  disabled: PropTypes.bool.isRequired,
  onNarrativeValueChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
}

const CHARACTER_LIMIT = 500

const WhiteTextField = withStyles({
  root: {
    '& .MuiInputBase-input': {
      color: '#555555',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#63666A',
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#26890D',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#26890D',
    },
    '& .MuiInput-underline.Mui-disabled:before': {
      borderBottomStyle: 'solid',
    },
  },
})(TextField)

function TagNarrative({ value, disabled, onNarrativeValueChange }) {
  return (
    <div className={styles.narrative}>
      <div className={styles.heading}>
        Tickmark narrative
      </div>
      <WhiteTextField
        multiline
        className={styles.textFeild}
        disabled={disabled}
        inputProps={{
          maxLength: CHARACTER_LIMIT,
        }}
        onChange={event => onNarrativeValueChange(event.target.value)}
        placeholder="No narrative"
        rows={5}
        value={value}
      />
    </div>
  )
}

TagNarrative.propTypes = propTypes

export default TagNarrative
