import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import * as icons from 'icons'
import Input from 'react-toolbox/lib/input'

import styles from 'components/Form/Form.scss'
import theme from './theme.scss'
import themeNoTextBelow from './themeNoTextBelow.scss'

const TextInput = (props) => {
  const {
    icon,
    hint,
    disabled,
    error,
    handleChange,
    label,
    focus,
    name,
    note,
    onBlur,
    onFocus,
    showTextBelow,
    type,
    text,
    valid,
    maxLength,
    className,
  } = props
  const displayNote = !valid ? styles.isHidden : ''
  const focusIcon = focus ? styles.inputIconActive : ''

  const createIcon = (Icon, classes) => {
    return <Icon className={classes} height="16" width="16" />
  }

  const _handleBlur = (e) => {
    onBlur(name, e.target.value)
  }

  const _handleFocus = (e) => {
    onFocus(name, e)
  }

  const _handleChange = (value, e) => {
    handleChange(name, e.target.value)
  }

  return (
    <div className={className}>
      <Input
        data-test-id={`textInput-${name}`}
        disabled={disabled}
        error={error}
        hint={hint}
        label={label}
        maxLength={maxLength}
        name={name}
        onBlur={_handleBlur}
        onChange={_handleChange}
        onFocus={_handleFocus}
        theme={showTextBelow ? theme : themeNoTextBelow}
        type={type}
        value={text}
      />
      {icon
        ? createIcon(icons[icon], classnames(styles.inputIcon, focusIcon))
        : null}
      {note ? (
        <span className={classnames(styles.note, displayNote)}>{note}</span>
      ) : null}
    </div>
  )
}

TextInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  focus: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  hint: PropTypes.node,
  icon: PropTypes.string,
  label: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  name: PropTypes.string.isRequired,
  note: PropTypes.string,
  onBlur: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  showTextBelow: PropTypes.bool,
  text: PropTypes.string || PropTypes.number,
  type: PropTypes.string,
  valid: PropTypes.bool,
}

TextInput.defaultProps = {
  className: null,
  disabled: false,
  error: '',
  focus: false,
  hint: null,
  icon: '',
  maxLength: null,
  note: null,
  showTextBelow: true,
  text: '',
  type: 'text',
  valid: true,
}

export default TextInput
