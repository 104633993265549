import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Select as MuiSelect, MenuItem, Chip, Slider,
} from '@material-ui/core'
import Label from 'components/Label'
import { useDispatch, useSelector } from 'react-redux'
import { putApp, updateAppadvancedSettingsForm, updateModels } from 'features/genAi/redux/actions/actionCreators'
import Expander from 'views/Home/components/Expander'
import { Button, TextInput } from '@deloitte/gel-library'
import Checkbox from 'components/Form/components/Checkbox'
import styles from './GenAIAppCreationForms.scss'

const AppAdvancedModelForm = () => {
  const dispatch = useDispatch()
  const initialState = useSelector(state => state.genAi.aiApp.advancedModelSettings)
  const modelTypes = useSelector(state => state.genAi.modelTypes)
  const [localFormData, setLocalFormData] = useState(initialState)

  const defaultType = modelTypes.reduce((acc, modelType) => {
    if (modelType.isDefaultType) {
      return modelType
    }
    return acc
  }, '')

  useEffect(() => {
    if (modelTypes.length === 0) {
      updateModels()
    }
  }, [modelTypes])

  useEffect(() => {
    setLocalFormData(initialState)
  }, [initialState])

  const handleAllowModelChange = useCallback((modelName, value) => {
    setLocalFormData(prevState => ({
      ...prevState,
      modelSettings: prevState.modelSettings.map((model) => {
        if (model.modelDeployment === modelName) {
          return {
            ...model,
            allowModelChange: value,
          }
        }
        return model
      }),
    }))
  }, [])

  const handleAdvancedSettingChange = (modelName, paramName, propName, value) => {
    setLocalFormData((prevState) => {
      return {
        ...prevState,
        modelSettings: prevState.modelSettings.map((model) => {
          if (model.modelDeployment === modelName) { // match correct model to update
            return {
              ...model,
              advancedSettings: model.advancedSettings.map((advancedSetting) => {
                if (advancedSetting.parameterName === paramName) { // match correct setting to update
                  return {
                    ...advancedSetting,
                    [propName]: value,
                  }
                }
                return advancedSetting
              }),
            }
          }
          return model
        }),
      }
    })
  }

  const handleDropDownChange = useCallback((event, name) => {
    setLocalFormData(prevState => ({ ...prevState, [name]: event.target.value }))
  }, [])

  const handleSectionSave = (event) => {
    event.preventDefault()
    event.stopPropagation()
    dispatch(updateAppadvancedSettingsForm(localFormData))
    dispatch(putApp())
  }

  const menuItems = useMemo(() => modelTypes.map((modelType) => {
    const allowChange = localFormData?.modelSettings?.find(model => model.modelDeployment === modelType.deploymentName)?.allowModelChange ?? null
    const isEnabled = allowChange && (modelType.isDefaultType || modelType.name === 'gpt-4-1106-preview')
    return (
      <MenuItem disabled={!isEnabled} key={`ddl_${modelType.id}`} value={modelType.deploymentName}>
        {modelType.labelName}
      </MenuItem>
    );
  }), [modelTypes, localFormData])

  return (
    <Expander
      className={styles.expander}
      classNameExpanded={styles.expanderExpanded}
      headerContent={(
        <div className={styles.headerBar}>
          <div className={styles.headerTitle}>
            <span className={styles.title}>Define model</span>
            <Chip label="Optional" />
          </div>
          <div className={styles.saveSectionBtn}>
            <Button mode="flat" onClick={handleSectionSave}>Save</Button>
          </div>
        </div>
      )}
      key="appAdvancedModelSettings"
      size="unlimited"
    >
      <div className={styles.expandContentContainer}>
        <p>
          Model selection and parameter configuration
        </p>
        <div className={styles.form}>
          <div className={styles.fieldRow}>
            <div className={styles.label}>
              <Label id="lblModelDeployment">Set default model</Label>
            </div>
            <div className={styles.fieldContainer}>
              {defaultType && modelTypes
                ? (
                  <MuiSelect
                    autoWidth
                    className={styles.dropdown}
                    defaultValue={localFormData && localFormData.modelDeployment ? localFormData.modelDeployment : defaultType.deploymentName}
                    id="modelDeployment"
                    label="Model"
                    labelId="lblModelDeployment"
                    onChange={event => handleDropDownChange(event, 'modelDeployment')}
                    value={localFormData && localFormData.modelDeployment ? localFormData.modelDeployment : defaultType.deploymentName}
                  >
                    {menuItems}
                  </MuiSelect>
                ) : null}
            </div>
          </div>
          <div className={styles.fieldRow}>
            <div className={styles.checkboxLabel}>
              <Label>Allow model change in app</Label>
            </div>
            <div className={styles.fieldContainerModels}>
              {localFormData.modelSettings && localFormData.modelSettings.map((modelSetting) => {
                const isModelSelected = localFormData.modelDeployment === modelSetting.modelDeployment
                const isDisabled = isModelSelected || modelSetting.modelDeployment === null
                return (
                  <Checkbox
                    checked={!isDisabled ? modelSetting.allowModelChange : !!isModelSelected}
                    className={styles.checkboxText}
                    disabled={isDisabled}
                    handleChange={() => handleAllowModelChange(modelSetting.modelDeployment, !modelSetting.allowModelChange)}
                    key={`chk_${modelSetting.modelName}`}
                    label={modelSetting.modelLabel}
                    name={modelSetting.modelName}
                  />
                )
              })}
            </div>
          </div>
          {localFormData.modelSettings && localFormData.modelDeployment
            && localFormData.modelSettings.find(model => model.modelDeployment === localFormData.modelDeployment).advancedSettings.map((advancedSetting) => {
              if (advancedSetting.renderConfig.renderType === 'slider') {
                return (
                  <React.Fragment key={`fragment_${advancedSetting.parameterName}`}>
                    <div className={styles.fieldRow} key={`field_${advancedSetting.parameterName}`}>
                      <div className={styles.label} key={`div_lbl_${advancedSetting.parameterName}`}>
                        <Label key={`lbl_${advancedSetting.parameterName}`}>{advancedSetting.parameterLabel}</Label>
                      </div>
                      <div className={styles.fieldContainerModels} key={`div_fieldContainer_${advancedSetting.parameterName}`}>
                        <Slider
                          marks
                          aria-labelledby="discrete-slider"
                          className={styles.field}
                          defaultValue={parseFloat(advancedSetting.defaultValue)}
                          getAriaValueText={updatedValue => `${updatedValue}`}
                          key={`slider_${advancedSetting.parameterName}`}
                          max={1.0}
                          min={0.0}
                          name={advancedSetting.parameterName}
                          onChange={(_, updatedValue) => handleAdvancedSettingChange(localFormData.modelDeployment, advancedSetting.parameterName, 'value', updatedValue)}
                          step={0.1}
                          value={advancedSetting.value === '' ? parseFloat(advancedSetting.defaultValue) : parseFloat(advancedSetting.value)}
                          valueLabelDisplay="on"
                        />
                        <div className={styles.modelDescription}>
                          {advancedSetting.description}
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )
              }
              return (
                <React.Fragment key={`fragment_${advancedSetting.parameterName}`}>
                  <div className={styles.fieldRow} key={`field_${advancedSetting.parameterName}`}>
                    <div className={styles.label} key={`div_lbl_${advancedSetting.parameterName}`}>
                      <Label key={`lbl_${advancedSetting.parameterName}`}>{advancedSetting.parameterLabel}</Label>
                    </div>
                    <div className={styles.fieldContainerModels} key={`div_fieldContainer_${advancedSetting.parameterName}`}>
                      <TextInput
                        InputProps={styles.underline}
                        className={styles.field}
                        id={advancedSetting.parameterName}
                        key={`txt_${advancedSetting.parameterName}`}
                        name={advancedSetting.parameterName}
                        onChange={value => handleAdvancedSettingChange(localFormData.modelDeployment, advancedSetting.parameterName, 'value', value)}
                        placeholder="Token limit"
                        value={localFormData && advancedSetting.value ? advancedSetting.value : ''}
                      />
                      <div className={styles.modelDescription}>
                        {advancedSetting.description}
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )
            })}

          <div className={`${styles.fieldRow} ${styles.checkboxRow}`}>
            <div className={styles.checkboxLabel}>
              <Label>Show parameters in app</Label>
            </div>
            <div className={styles.fieldContainerModels}>
              {localFormData.modelSettings && localFormData.modelSettings[0].advancedSettings.map(advancedSetting => (
                <Checkbox
                  checked={advancedSetting.allowParamChange}
                  className={styles.checkboxText}
                  handleChange={() => handleAdvancedSettingChange('gpt-35-turbo', advancedSetting.parameterName, 'allowParamChange', !advancedSetting.allowParamChange)}
                  key={`chk_${advancedSetting.parameterName}`}
                  label={advancedSetting.parameterLabel}
                  name={advancedSetting.parameterLabel}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Expander>
  )
}

export default AppAdvancedModelForm
