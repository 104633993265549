import React from 'react'
import PropTypes from 'prop-types'
import { Heading } from '@deloitte/gel-library'
import { getDistinct } from 'utils/arrays'
import Card from 'components/Card'
import DynamicTable from 'components/DynamicTable'
import Loading from 'components/Loading'
import Expander from 'views/Home/components/Expander'
import IconFileError from 'icons/IconFileError'
import styles from './DataValidationResults.scss'

const propTypes = {
  dataValidations: PropTypes.arrayOf(PropTypes.shape({
    table: PropTypes.string,
  })),
  onFetch: PropTypes.func.isRequired,
}

const defaultProps = {
  dataValidations: [],
}

const getCheckForDataType = (type) => {
  if (type.endsWith('number')) {
    return 'a number'
  }
  return type
}

const getWarningForColumn = (column) => {
  if (column.typeErrorCount > 0) {
    return `${column.typeErrorCount} rows found that are not ${getCheckForDataType(column.dataType)}`
  }

  if (column.blankErrorCount > 0) {
    return `${column.blankErrorCount} blank rows found`
  }

  return null
}

const LINE_NUMBER_COLUMN = '_LINE_NUMBER'
const RECIPE_ERROR_COLUMN = '_RECIPE_ERROR'
const REPAIRED_COLUMN_SUFFIX = '_REPAIRED'
const BLANK_ERROR_SUFFIX = '_BLANK_ERROR'
const BIT_ERROR_SUFFIX = '_BIT_ERROR'
const DATE_ERROR_SUFFIX = '_DATE_ERROR'
const NUM_ERROR_SUFFIX = '_NUM_ERROR'
const STR_ERROR_SUFFIX = '_STR_ERROR'
const INT_ERROR_SUFFIX = '_INT_ERROR'

const dataValidationSuffixes = [
  BLANK_ERROR_SUFFIX,
  BIT_ERROR_SUFFIX,
  DATE_ERROR_SUFFIX,
  NUM_ERROR_SUFFIX,
  STR_ERROR_SUFFIX,
  INT_ERROR_SUFFIX,
]

const dataValidationDescriptions = {
  [BLANK_ERROR_SUFFIX]: 'Value is empty.',
  [BIT_ERROR_SUFFIX]: 'Value is not 0 or 1.',
  [DATE_ERROR_SUFFIX]: 'Value is not a date.',
  [NUM_ERROR_SUFFIX]: 'Value is not a number.',
  [STR_ERROR_SUFFIX]: 'Value is too long.',
  [INT_ERROR_SUFFIX]: 'Value is not a whole number.',
}

const processErrors = (file) => {
  const headerNames = file.columnMapping.map(x => x.columnKey)
  const meta = {}
  const data = []
  let previousLineNumber = 0

  if (!file.errors) { return {} }

  file.errors.forEach((x) => {
    const datum = { _lineNumber: x[LINE_NUMBER_COLUMN] }
    const rowMeta = {}
    if (x[LINE_NUMBER_COLUMN] !== previousLineNumber + 1) {
      const isSequential = x[LINE_NUMBER_COLUMN] - previousLineNumber === 2
      let message = `No errors between row ${previousLineNumber} and ${x[LINE_NUMBER_COLUMN]}`

      if (previousLineNumber === 0) {
        message = isSequential ? 'No errors in row 1' : `No errors up to row ${x[LINE_NUMBER_COLUMN]}`
      } else {
        message = isSequential ? `No errors in row ${previousLineNumber + 1}` : message
      }

      data.push({
        _lineNumber: previousLineNumber + 1,
        _fillerMessage: message,
      })
    }

    headerNames.forEach((header) => {
      datum[header] = x[header]

      dataValidationSuffixes.forEach((suffix) => {
        if (x[`${header}${suffix}`]) {
          rowMeta[header] = {
            className: styles.warning,
            title: suffix.endsWith(BLANK_ERROR_SUFFIX)
              ? 'Unexpectedly blank data'
              : `${dataValidationDescriptions[suffix]} Interpreted as '${x[`${header}${REPAIRED_COLUMN_SUFFIX}`]}'`,
          }
        }
      })
    })

    // check for recipe errors
    if (x[RECIPE_ERROR_COLUMN] && x[RECIPE_ERROR_COLUMN] !== 0) {
      const recipeError = file.recipeValidations.find(rv => rv.key === x[RECIPE_ERROR_COLUMN])

      recipeError.columnNames.forEach((col) => {
        rowMeta[col] = {
          className: styles.warning,
          title: rowMeta[col] ? (
            <div>
              {recipeError.meta.description}
              <br />
              <br />
              {rowMeta[col].title}
            </div>
          ) : recipeError.meta.description,
        }
      })
    }

    meta[x[LINE_NUMBER_COLUMN]] = rowMeta
    data.push(datum)
    previousLineNumber = x[LINE_NUMBER_COLUMN]
  })

  const headers = file.columnMapping
    .map(x => ({
      key: x.columnKey,
      heading: x.columnName,
      subHeading: x.dataType,
      metaHeading: x.fileColumnName,
      warning: getWarningForColumn(x),
    }))

  return {
    data,
    headers,
    meta,
  }
}

function DataValidationResults({ dataValidations, onFetch }) {
  const distinctTables = getDistinct(dataValidations.map(x => x.table))

  if (distinctTables.length === 0) {
    return null
  }

  return (
    <div>
      <Heading level={7}>Input data errors</Heading>
      <p>
        Rows found in your data that do not satisfy the data type requirements specified
        for the column (e.g. rows containing blank entries in columns where entries are
        required, non-numeric entries in numeric columns, etc).
      </p>
      {distinctTables.map((table) => {
        const files = dataValidations.filter(x => x.table === table)
        return (
          <div className={styles.base} key={table}>
            <Card noPadding className={styles.header}>
              {table}
            </Card>
            {files.map((f) => {
              const processedErrors = processErrors(f)
              const remainingRows = Math.min(100, f.affectedRows - f.errors.length)

              return (
                <Expander
                  className={styles.expander}
                  classNameExpanded={styles.expanderExpanded}
                  headerContent={(
                    <div className={styles.fileInfo}>
                      <div className={styles.file}>
                        <IconFileError height={24} width={24} />
                        {f.columnName}
                      </div>
                      <div className={styles.warning}>{`${f.affectedRows} rows with warnings`}</div>
                    </div>
                )}
                  key={f.validationName}
                  onExpand={(isExpanded) => { if (isExpanded) { onFetch(f) } }}
                >
                  {f._isFetching && f.errors.length === 0
                    ? <Loading pageLoading />
                    : (
                      <DynamicTable
                          showLineNumbers
                          showMetaRow
                          className={styles.table}
                          data={processedErrors.data}
                          headers={processedErrors.headers}
                          meta={processedErrors.meta}
                      />
                    )
                }

                  <div className={styles.footer}>
                    {`Showing ${f.errors.length} of ${f.affectedRows} rows with errors`}
                    {f.affectedRows > f.errors.length && (
                      <a onClick={() => onFetch(f)}>
                        {`Load next ${remainingRows} rows`}
                      </a>
                    )}
                  </div>
                </Expander>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

DataValidationResults.propTypes = propTypes
DataValidationResults.defaultProps = defaultProps

export default DataValidationResults
