/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconCollection = ({ className, height, width, ...rest }) => {
  const classes = classnames('icon', 'icon--collection', className)

  return (
    <IconBase
      className={classes}
      height={height}
      width={width}
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <title>Collection</title>
      <g fill="none" fillRule="evenodd">
        <path className="path1" d="M-1-1h24v24H-1z" />
        <path
          className="fillpath"
          d="M2 4H0v16c0 1.1.9 2 2 2h16v-2H2V4zm18-4H6C4.9 0 4 .9 4 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2zm0 16H6V2h14v14z"
          fill="#BDBDBD"
          fillRule="nonzero"
        />
      </g>
    </IconBase>
  )
}

IconCollection.propTypes = {
  className: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
}

IconCollection.defaultProps = {
  height: 22,
  width: 22,
}

export default IconCollection
/* eslint-enable */
