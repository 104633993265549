import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Button as ToolboxButton } from 'react-toolbox/lib/button'

import styles from './Button.scss'
import theme from './theme.scss'

const Button = ({
  children, className, disabled, type, mini, flat, buttonType, ...rest
}) => {
  const toolBoxProps = {
    className: classnames(styles.base, {
      [styles.disabled]: disabled,
      [styles.flat]: flat === true,
      [styles.mini]: mini === true,
      [styles.primary]: type === 'primary',
      [styles.secondary]: type === 'secondary',
      [styles.secondaryGrey]: type === 'secondaryGrey',
    }, className),
    disabled,
    flat: flat === true,
    primary: type === 'primary',
    raised: flat !== true,
  }

  return (
    <ToolboxButton
      {...toolBoxProps}
      {...rest}
      theme={theme}
      type={buttonType}
    >
      {children}
    </ToolboxButton>
  )
}

Button.propTypes = {
  buttonType: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  disabled: PropTypes.bool,
  flat: PropTypes.bool,
  mini: PropTypes.bool,
  type: PropTypes.string,
}

Button.defaultProps = {
  buttonType: 'button',
  children: null,
  className: null,
  disabled: false,
  flat: false,
  mini: false,
  type: 'primary',
}

export default Button
