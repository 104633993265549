import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import ToolboxProgressBar from 'react-toolbox/lib/progress_bar'
import ErrorOutlinedIcon from '@material-ui/icons/ErrorOutlined'
import styles from './ProgressBar.scss'

const propTypes = {
  completeChildren: PropTypes.node,
  completeText: PropTypes.string.isRequired,
  isCompact: PropTypes.bool,
  statusCount: PropTypes.number.isRequired,
  statuses: PropTypes.objectOf(PropTypes.shape({
    description: PropTypes.string,
    value: PropTypes.number,
  })).isRequired,
  statusKey: PropTypes.string.isRequired,
}

const defaultProps = {
  completeChildren: null,
  isCompact: false,
}

function ProgressBar({
  completeChildren,
  completeText,
  isCompact,
  statusCount,
  statuses,
  statusKey,
}) {
  const status = statuses[statusKey]
  const progress = statusKey === 'ReportGenerationFailed' ? 100 : status.value / statusCount * 100

  const isComplete = status.value === statusCount

  const renderText = () => {
    switch (statusKey) {
      case 'ReportGenerationFailed':
        return (
          <div className={styles.failedReports}>
            <ErrorOutlinedIcon className={styles.failedReportdiv} />
            Reports failed
          </div>
        )
      default:
        return `${status.description}`
    }
  }

  return (
    <div className={classnames(styles.base,
      {
        [styles.complete]: isComplete,
        [styles.compact]: isCompact,
      })}
    >
      <div className={styles.description}>
        {isComplete ? completeText : renderText()}
      </div>
      <ToolboxProgressBar
        className={statusKey === 'ReportGenerationFailed' ? styles.redProgressBar : styles.progressBar}
        mode="determinate"
        type="linear"
        value={progress}
      />
      <div className={styles.completeChildren}>
        {isComplete ? completeChildren : null}
      </div>
    </div>
  )
}

ProgressBar.propTypes = propTypes
ProgressBar.defaultProps = defaultProps

export default ProgressBar
