import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  adminFetchSolutionsAccess, adminFetchSolutionRoles, adminEditSolutionAccess,
} from 'actions/admin'
import { modalCreate, modalShow } from 'actions/modals'
import {
  DataTableSimple, Heading, Searchbar, Button,
} from '@deloitte/gel-library'
import constants from 'constants'
import { hot } from 'react-hot-loader/root'
import { formatDateFromStringShort } from 'utils/dates'
import Card from 'components/Card'
import TagList from 'components/TagList'
import SearchFilter from 'components/SearchFilter'
import BlockSpinner from 'components/BlockSpinner'
import { deleteSolutionCache } from 'utils/api/admin'
import styles from './SolutionSettings.scss'
import SolutionSettingsModal from './SolutionSettingsModal'

const getDistinctSolutionRoleDescriptions = solution => (Array.from(new Set(solution.roles
  .map(r => r.description)
  .concat(
    solution.analyses
      .map(a => a.roles)
      .reduce((prev, cur) => prev.concat(cur), [])
      .map(r => r.description),
  )))
)

const ALL_ROLES_FILTER = 'All roles'

const SolutionSettings = () => {
  const dispatch = useDispatch()

  const {
    solutions, solutionNames, _isFetching: _isFetchingSolutions, _isFetched: _isFetchedSolutions,
  } = useSelector(state => state.admin.allSolutionsAccess)

  useEffect(() => {
    if (!_isFetchedSolutions) {
      dispatch(adminFetchSolutionsAccess())
      dispatch(adminFetchSolutionRoles())
      dispatch(modalCreate(constants.MODAL_SOLUTION_SETTINGS))
    }
  }, [_isFetchedSolutions, dispatch])

  const selectedSolution = useSelector(state => state.admin.solutionAccess)

  const {
    roles, roleIds, _isFetched: _isFetchedRoles, _isFetching: _isFetchingRoles,
  } = useSelector(state => state.admin.assignableRoles)

  const userEmail = useSelector(state => state.app.user.email)

  const loaded = _isFetchedSolutions && !_isFetchingSolutions && _isFetchedRoles && !_isFetchingRoles

  const columns = [
    {
      heading: 'Solution',
      cellExtractor: solution => (
        <div>
          <div>{solution.meta.displayName}</div>
          <div className={styles.tableSecondary}>{`${solution.analyses.length} analyses`}</div>
        </div>
      ),
      key: 'solution',
      className: styles.solutionColumn,
    },
    {
      heading: 'Collection',
      cellExtractor: solution => solution.collection,
      key: 'collection',
      className: styles.collectionColumn,
    },
    {
      heading: 'Last updated',
      cellExtractor: (solution) => {
        const lastUpdatedAnalysisRole = solution.analyses
          .map(a => a.roles)
          .reduce((prev, current) => prev.concat(current), [])
          .reduce((prev, current) => (prev.updatedAt > current.updatedAt
            ? prev : current), { updatedAt: null })

        const lastUpdatedSolutionRole = solution.roles
          .reduce((prev, current) => (prev.updatedAt > current.updatedAt ? prev : current), { updatedAt: null })

        const lastUpdated = lastUpdatedAnalysisRole.updatedAt > lastUpdatedSolutionRole.updatedAt
          ? lastUpdatedAnalysisRole
          : lastUpdatedSolutionRole

        return (
          <div>
            <div>{formatDateFromStringShort(lastUpdated.updatedAt)}</div>
            <div className={styles.tableSecondary}>{lastUpdated.updatedBy === '[USER]' ? userEmail : lastUpdated.updatedBy }</div>
          </div>
        )
      },
      key: 'updated',
    },
    {
      heading: 'Accessible by',
      cellExtractor: (solution) => {
        const allRoles = loaded
          ? getDistinctSolutionRoleDescriptions(solution)
          : []
        return (
          allRoles.length === 0
            ? <p>No access</p>
            : <TagList tags={[...new Set(allRoles)]} />
        )
      },
      key: 'access',
    },
    {
      heading: '',
      cellExtractor: solution => (
        <div>
          <Button
              mode="text"
              onClick={() => {
                dispatch(adminEditSolutionAccess(solution.name))
                dispatch(modalShow(constants.MODAL_SOLUTION_SETTINGS))
              }
          }
          >
            Edit
          </Button>
        </div>
      ),
      key: 'edit',
      className: styles.editColumn,
    },
  ]

  const roleOptions = {
    ...roles,
    [-1]: {
      id: -1,
      description: ALL_ROLES_FILTER,
    },
  }

  const [solutionSearch, setSolutionSearch] = useState('')
  const [roleFilter, setRoleFilter] = useState(ALL_ROLES_FILTER)

  const filteredSolutions = solutionNames
    .map(n => solutions[n])
    .filter(s => s.meta.displayName.toLowerCase().includes(solutionSearch)
      && ((s.roles && s.roles.length > 0 && getDistinctSolutionRoleDescriptions(s).indexOf(roleFilter) >= 0) || roleFilter === ALL_ROLES_FILTER))

  return (
    <div className={styles.base}>
      <Heading level={8}>Solution access management</Heading>

      <Button onClick={() => deleteSolutionCache()}>Clear package cache</Button>

      {loaded
        ? (
          <div>
            <div className={styles.filterBar}>
              <Searchbar
                mini
                className={styles.search}
                onSearch={value => setSolutionSearch(value.toLowerCase())}
                placeholder="Search solutions"
                value={solutionSearch}
              />
              <div className={styles.filter}>
                <div>Filter by:</div>
                <SearchFilter
                  handleSubmit={o => setRoleFilter(o)}
                  id="role-search-filter"
                  options={[-1].concat(roleIds).map(id => ({ label: roleOptions[id].description, value: `${id}` }))}
                  text={roleFilter}
                />
              </div>
            </div>
            <Card noPadding>
              <DataTableSimple
                className={styles.table}
                columns={columns}
                data={filteredSolutions}
                keyExtractor={s => s.name}
                sortFunction={s => s.name}
              />
            </Card>
          </div>
        ) : (
          <BlockSpinner message="Loading solutions" />
        )}

      {loaded && selectedSolution.name && <SolutionSettingsModal />}

    </div>
  )
}

export default hot(SolutionSettings)
