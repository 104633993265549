import React from 'react'
import PropTypes from 'prop-types'
import { NavLink, withRouter } from 'react-router-dom'
import { Container } from 'components/layout/Grid/'
import Heading from 'components/Heading'
import ActionButton from 'components/ActionButton'
import BannerSelect from 'components/BannerSelect'
import { IconArrowBackward } from 'icons'
import classnames from 'classnames'
import { Button } from '@deloitte/gel-library'
import ExpandingActionButton from 'components/ExpandingActionButton'
import ManageTeam from 'components/ManageTeam'
import ExpiryInfoBanner from 'components/ExpiryInfoBanner'
import styles from './Banner.scss'

class Banner extends React.Component {
  static propTypes = {
    actions: PropTypes.arrayOf(PropTypes.shape({
      icon: PropTypes.node,
      label: PropTypes.string,
      onAction: PropTypes.func,
    })),
    app: PropTypes.shape({
      user: PropTypes.shape({
        _isFetched: PropTypes.bool,
        isExternal: PropTypes.bool,
      }),
    }),
    backTitle: PropTypes.string,
    bannerSelectProps: PropTypes.object,
    defaultBack: PropTypes.string,
    editLabel: PropTypes.string,
    hideManageTeam: PropTypes.bool,
    history: PropTypes.object.isRequired,
    job: PropTypes.shape({
      _fetchedJobDetailsId: PropTypes.string,
      executionLimit: PropTypes.number,
      hasExecutionLimit: PropTypes.bool,
      hasExpired: PropTypes.bool,
      noOfRuns: PropTypes.number,
    }),
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
    match: PropTypes.shape({
      url: PropTypes.string,
    }).isRequired,
    name: PropTypes.string.isRequired,
    onEdit: PropTypes.func,
    routes: PropTypes.arrayOf(PropTypes.shape({
      path: PropTypes.string,
    })),
    showLeftIcon: PropTypes.bool,
    subtitle: PropTypes.node,
    tabNotificationCounts: PropTypes.object,
    titleRight: PropTypes.node,
    user: PropTypes.object,
  }

  static defaultProps = {
    actions: null,
    app: null,
    backTitle: null,
    bannerSelectProps: null,
    defaultBack: null,
    hideManageTeam: false,
    editLabel: 'Edit',
    job: null,
    onEdit: null,
    routes: [],
    subtitle: null,
    titleRight: null,
    tabNotificationCounts: {},
    user: null,
    showLeftIcon: true,
  }

  componentDidMount() {
    const { routes } = this.props
    if (routes && routes.length > 0) { this.checkRouteValidity() }
  }

  goBack = () => {
    const { history, defaultBack } = this.props
    if (defaultBack) {
      history.push(defaultBack)
    } else if (history.length > 1) {
      history.goBack()
    }
  }

  handleTabChange = (index) => {
    const { routes, history } = this.props
    history.push(routes[index].path)
  }

  checkRouteValidity() {
    const { location: { pathname }, routes, match } = this.props
    if (pathname === match.url || (`${pathname}/`) === match.url) {
      this.handleTabChange(0)
    } else {
      routes.forEach((route, index) => {
        if (pathname === route.path) { this.handleTabChange(index) }
      })
    }
  }

  render() {
    const {
      actions,
      backTitle,
      bannerSelectProps,
      defaultBack,
      hideManageTeam,
      editLabel,
      history,
      name,
      onEdit,
      routes = [],
      showLeftIcon,
      subtitle,
      tabNotificationCounts,
      titleRight,
      job,
      app,
    } = this.props
    const reachedExecutionLimit = job?.hasExecutionLimit && job?.noOfRuns >= job?.executionLimit
    const filteredRoutes = routes.filter(r => r.name !== 'Data issues')
    const routesToUse = (job?.hasExpired && app?.user?.isExternal) ? filteredRoutes : routes
    const showExpiredJobBanner = reachedExecutionLimit || job?.hasExpired

    const navItems = routesToUse.map((item) => {
      const pathComponents = item.path.split('/')
      const relativeLink = pathComponents[pathComponents.length - 1]
      const tabCount = tabNotificationCounts[item.path]

      const blocked = item.name && item.name.includes('(0)')
      return (
        <NavLink
          activeClassName={styles.isActive}
          className={classnames(styles.navItem, blocked ? styles.blockedTab : '')}
          key={item.path}
          onClick={(e) => { if (blocked) { e.preventDefault() } }}
          to={relativeLink}
        >
          {item.name}
          {tabCount !== 0 && tabCount && <span>{` (${tabCount})`}</span>}
          <div className={styles.highlight} />
        </NavLink>
      )
    })

    const bannerTitle = (() => {
      if (name && !bannerSelectProps) {
        return (
          <Heading
            noPadding
            elementType="h1"
            size="h4"
          >
            {name}
          </Heading>
        )
      } if (bannerSelectProps) {
        bannerSelectProps.history = history
        return <BannerSelect {...bannerSelectProps} />
      }
      return null
    })()

    return (
      <div className={classnames(
        styles.base,
        showExpiredJobBanner ? styles.expiredJobPadding : null,
        backTitle ? styles.backTitlePresent : null,
        subtitle ? styles.subtitlePresent : null,
      )}
      >
        <Container className={styles.container}>
          <div>
            {backTitle && (
            <a className={styles.backTitle} onClick={this.goBack}>
              {backTitle}
            </a>
            )}
            <div className={styles.titleContainer}>
              <div className={styles.title}>
                {
                (defaultBack && history.length)
                && (
                <a
                  className={styles.back}
                  onClick={this.goBack}
                >
                  <IconArrowBackward
                    className={styles.icon}
                    size="16"
                  />
                </a>
                )
              }
                {bannerTitle}
              </div>
              { titleRight }
            </div>
            {subtitle && (
            <p className={styles.subtitle}>
              {subtitle}
            </p>
            )}
            {navItems.length > 0 && (
            <nav className={styles.navItemList}>
              {navItems}
            </nav>
            )}
            {actions && actions.length > 1 && (
              <ExpandingActionButton
                actions={actions}
              />
            )}
            {actions && actions.length === 1 && (
              <ActionButton
                handleAction={actions[0].onAction}
                showLeftIcon={showLeftIcon}
                tooltip={actions[0].label}
              />
            )}
          </div>
          {onEdit && (
          <div>
            <Button
              className={styles.editButton}
              onClick={onEdit}
            >
              {editLabel}
            </Button>
          </div>
          )}

          {!hideManageTeam
            && app?.user?._isFetched
            && !app?.user?.isExternal
            && job
            && job._fetchedJobDetailsId !== null
            && <ManageTeam onDark />}

        </Container>
        {showExpiredJobBanner && (
          <ExpiryInfoBanner
            hasExpired={job?.hasExpired}
            reachedExecutionLimit={reachedExecutionLimit}
          />
        )}
      </div>
    )
  }
}

export default withRouter(Banner)
