import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Switch } from 'react-router-dom'
import { fetchClientPackage } from 'actions/packages'
import { fetchClientActions, fetchClientJob } from 'actions/client'
import Loading from 'components/Loading'
import Banner from 'components/Banner'
import RouteWithSubRoutes from 'components/RouteWithSubRoutes'
import Container from 'components/layout/Grid/Container'
import { canAccessActionHub } from 'utils/permissions'
import { roles, userHasJobRole } from 'utils/business/jobs'

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  job: PropTypes.shape({
    _fetchedJob: PropTypes.bool,
    actions: PropTypes.object,
    hasActions: PropTypes.bool,
    hasExpired: PropTypes.bool,
    jobMembers: PropTypes.arrayOf(PropTypes.object),
    name: PropTypes.string,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      jobId: PropTypes.string,
      packageId: PropTypes.string,
    }),
  }).isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  solution: PropTypes.object,
  user: PropTypes.object.isRequired,
}

const defaultProps = {
  solution: null,
  job: null,
}

const ClientJob = (props) => {
  const {
    solution, dispatch,
    user, job, routes,
    match: { params: { jobId, packageId } },
  } = props

  useEffect(() => {
    dispatch(fetchClientJob(jobId))
    if (solution === null) {
      dispatch(fetchClientPackage(packageId))
    }
  }, [dispatch, jobId, packageId, solution])

  useEffect(() => {
    if (jobId) {
      dispatch(fetchClientActions(jobId))
    }
  }, [dispatch, jobId])

  const canAccessActionsOnJob = canAccessActionHub(user)
      && (userHasJobRole(user, job, roles.JobExternalActionReader) || userHasJobRole(user, job, roles.JobExternalActionEditor))

  const showActionsTab = (job?.hasExpired && user?.isExternal) ? false : (canAccessActionsOnJob && job.hasActions && job.actions && job.actions.currentActions.length > 0)

  const filteredRoutes = routes
    .filter(r => r.path !== '/insights/:packageId/:jobId/actions' || showActionsTab)

  return (
    <div>
      <Banner
        backTitle={solution && solution.meta.displayName}
        defaultBack={`/insights/${packageId}`}
        job={job}
        name={(job && job.name) ? job.name : ''}
        routes={filteredRoutes}
      />
      <div style={{ paddingTop: '36px' }}>
        <Container bottomMargin noMargin>
          {job && job._fetchedJob && solution
            ? (
              <Switch>
                {routes.map(route => (
                  <RouteWithSubRoutes
                    key={route.path}
                    user={user}
                    {...route}
                    dispatch={dispatch}
                    job={job}
                    members={job.jobMembers}
                    onEditData={() => {}}
                      // unify with non-client Actions view
                    solution={solution}
                    uploaders={{ validations: {} }}
                    validations={{ uploaders: {} }}
                  />
                ))}
              </Switch>
            )
            : (
              <Loading pageLoading />
            )
          }
        </Container>
      </div>

    </div>
  )
}

const mapStateToProps = (
  { app: { user }, client: { jobs }, packages: { packages } },
  { match: { params: { packageId, jobId } } },
) => {
  return {
    user,
    solution: packages[packageId],
    job: jobs[jobId],
  }
}

ClientJob.propTypes = propTypes
ClientJob.defaultProps = defaultProps

export default connect(mapStateToProps)(ClientJob)
