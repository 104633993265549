import React from 'react'

function IconPriorityMedium() {
  return (
    <svg fill="none" height="18" viewBox="0 0 13 18" width="13" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.11122 2.97332L10.7437 7.56332L12.1668 6.15332L6.11122 0.15332L0.0556641 6.15332L1.47872 7.56332L6.11122 2.97332Z" fill="#F6A623" />
      <path d="M7.12057 2.13977H5.10205V17.8467H7.12057V2.13977Z" fill="#F6A623" />
    </svg>
  )
}

export default IconPriorityMedium

