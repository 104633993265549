import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Button, Heading } from '@deloitte/gel-library'
import { goToInputdata } from 'actions/job'
import { fetchDataValidations, setSeverityFilter } from 'actions/validations'
import Card from 'components/Card'
import DataValidationResults from 'views/DataValidationResults'
import speechBubble from 'images/speech-bubble.svg'
import { hot } from 'react-hot-loader/root'
import { useDispatch } from 'react-redux'
import { jobIsProcessing } from 'utils/business/workflow'
import WarningIcon from '@material-ui/icons/Warning'
import Container from 'components/layout/Grid/Container'
import ErrorCard from './components/ErrorCard'
import styles from './Errors.scss'

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  job: PropTypes.shape({
    canExecute: PropTypes.bool,
    closed: PropTypes.bool,
    engagement: PropTypes.shape({
      active: PropTypes.bool,
    }),
    isClientJob: PropTypes.bool,
    jobId: PropTypes.string,
  }).isRequired,
  validations: PropTypes.shape({
    filterSeverity: PropTypes.string,
    validations: PropTypes.object,
  }).isRequired,
}

const Errors = ({
  validations: { validations, filterSeverity },
  job,
}) => {
  const dispatch = useDispatch()
  const { jobId } = job

  const handleFetchDataValidationResults = (file) => {
    const page = (file.errors.length / file.pageSize) + 1

    dispatch(fetchDataValidations(jobId, file.fileId, page))
  }

  const handleReUploadClick = () => {
    dispatch(goToInputdata({ jobId }))
  }

  const validationsArray = Object.keys(validations)
    .map(k => validations[k])
    .filter(v => v.affectedRows > 0)
  const numCriticalErrors = validationsArray.filter(
    x => x.severity === 'Critical',
  ).length

  const inputErrors = validationsArray
    .filter(x => x.validationType === 1)
    .filter(x => filterSeverity === null || x.severity === filterSeverity)

  const businessErrors = validationsArray
    .filter(x => x.validationType === 2)
    .filter(x => filterSeverity === null || x.severity === filterSeverity)

  const filters = [
    {
      name: 'All data issues',
      count: validationsArray.length,
      filter: null,
    },
    {
      name: 'Critical issues',
      count: numCriticalErrors,
      filter: 'Critical',
    },
    {
      name: 'Warnings',
      count: validationsArray.length - numCriticalErrors,
      filter: 'Normal',
    },
  ]

  return (
    <Container className={styles.base}>
      <Card className={styles.editDataBanner}>
        <img alt="Speech bubble icon" src={speechBubble} />
        <div>
          <Heading level={7}>How do I resolve data issues?</Heading>
          <p>
            Data issues may skew the results of your analysis. For best results, please resolve any problems,
            reupload the affected files, and re-run the analysis.
          </p>
          {!job.canExecute && (
            <p className={styles.noExecuteWarning}>
              <WarningIcon fontSize="small" />
              {`This job cannot be executed right now - check job status${job.isClientJob ? ', expiry or run limitations' : ''}`}
            </p>
          )}
        </div>
        <Button
          disabled={(job && jobIsProcessing(job)) || !job.engagement.active || job.closed || !job.canExecute}
          mode="secondary"
          onClick={handleReUploadClick}
        >
          Re-upload data
        </Button>

      </Card>

      <div className={styles.filters}>
        {filters.map(x => (
          <Card
            className={classnames(styles.filter, {
              [styles.selected]: x.filter === filterSeverity,
              [styles.critical]: x.filter === 'Critical',
              [styles.nonCritical]: x.filter === 'Normal',
            })}
            key={x.filter}
            onClick={() => dispatch(setSeverityFilter(x.filter))}
          >
            <div>{x.name}</div>
            <div className={styles.count}>{x.count}</div>
          </Card>
        ))}
      </div>

      <DataValidationResults
        dataValidations={inputErrors}
        onFetch={handleFetchDataValidationResults}
      />

      {businessErrors.length > 0 && (
      <Heading level={7}>Business validation issues</Heading>
      )}
      {businessErrors.map(error => (
        <ErrorCard
          jobId={jobId}
          key={error.validationName}
          {...error}
        />
      ))}
    </Container>
  )
}

Errors.propTypes = propTypes

export default hot(Errors)
