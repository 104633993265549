import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button } from '@deloitte/gel-library'
import IconAddUser from 'icons/IconAddUser'
import { isEmail } from 'utils/validators'
import styles from './EmailInput.scss'

const propTypes = {
  error: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.string,
}

const defaultProps = {
  error: null,
  value: '',
}

function EmailInput({
  label, name, value, onChange, onSubmit, error,
}) {
  const [focussed, setFocussed] = useState(false)

  return (
    <div className={styles.root}>
      <label htmlFor={name}>{label}</label>
      <div className={classNames(styles.base, focussed ? styles.focus : null)}>
        <input
          id={name}
          name={name}
          onBlur={() => setFocussed(false)}
          onChange={x => onChange(x.target.value)}
          onFocus={() => setFocussed(true)}
          value={value}
        />
        <Button
          disabled={!isEmail(value)}
          icon={IconAddUser}
          mode="flat"
          onClick={() => onSubmit(value)}
        >
          Add user
        </Button>
      </div>
      <div className={styles.error}>
        {error}
      </div>
    </div>

  )
}

EmailInput.propTypes = propTypes
EmailInput.defaultProps = defaultProps

export default EmailInput
