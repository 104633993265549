import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Button } from '@deloitte/gel-library'
import Spinner from 'components/Spinner'
import { IconClose } from '../../icons'
import styles from './DialogSimple.scss'

class DialogSimple extends Component {
  static propTypes = {
    actionLabel: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    dismissLabel: PropTypes.string,
    hideFooter: PropTypes.bool,
    icon: PropTypes.node,
    isLoading: PropTypes.bool,
    isOpen: PropTypes.bool,
    onAction: PropTypes.func,
    onDismiss: PropTypes.func.isRequired,
  }

  static defaultProps = {
    actionLabel: null,
    children: null,
    className: null,
    disabled: false,
    dismissLabel: 'Dismiss',
    hideFooter: false,
    icon: null,
    isOpen: null,
    isLoading: false,
    onAction: null,
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeypress, false)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeypress, false)
  }

  handleKeypress = (event) => {
    const { onDismiss } = this.props

    if (event.keyCode === 27) {
      onDismiss()
    }
  }

  render() {
    const {
      actionLabel, children, className, disabled, dismissLabel, icon, onAction, onDismiss, isOpen, isLoading, hideFooter,
    } = this.props

    return (
      <div className={classnames(
        styles.base,
        { [styles.isOpen]: isOpen },
        icon ? styles.hasIcon : null,
      )}
      >
        <div className={classnames(styles.dialog, className)}>
          {icon && (
            <div className={styles.icon}>
              {icon}
            </div>
          )}
          <div className={styles.content}>
            {children}
            <button
              className={styles.dismiss}
              onClick={onDismiss}
              type="button"
            >
              <span className={styles.dismissIcon}>
                <IconClose size={14} />
              </span>
            </button>
          </div>
          {!hideFooter && (
          <footer className={styles.footer}>
              {dismissLabel && onDismiss && (
              <Button
                disabled={isLoading}
                mode={actionLabel ? 'flat-secondary' : 'flat'}
                onClick={onDismiss}
              >
                {dismissLabel}
              </Button>
              )}
            {actionLabel && onAction && (
            <Button
              className={isLoading ? styles.spinnerButton : null}
              disabled={disabled || isLoading}
              mode="flat"
              onClick={onAction}
            >
              { isLoading ? <Spinner className={styles.spinner} /> : actionLabel }
            </Button>
            )}
          </footer>
          )}
        </div>
        <div className={styles.overlay} />
      </div>
    )
  }
}

export default DialogSimple
