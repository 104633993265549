import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
}

function LinkRenderer(props) {
  const { href, children } = props

  return (
    <a rel="noopener noreferrer" target="_blank" to={href} {...props}>
      {children}
    </a>
  )
}

LinkRenderer.propTypes = propTypes

export default LinkRenderer
