import React from 'react'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { createHashHistory } from 'history'
import RouteWithSubRoutes from 'components/RouteWithSubRoutes'
import getRoutes from 'routes'
import configureStore from '../store/configureStore'

const history = createHashHistory({ queryKey: false })

const store = configureStore(history)

const Root = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <div>
        {getRoutes().map(route => (
          <RouteWithSubRoutes
            key={route.path}
            {...route}
          />
        ))}
      </div>
    </ConnectedRouter>
  </Provider>
)

export default Root
