import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Heading } from '@deloitte/gel-library'
import { useDispatch, useSelector } from 'react-redux'
import { modalHide } from 'actions/modals'
import Animate from 'components/Animate'
import Container from 'components/layout/Grid/Container'
import OverlayModal from 'components/OverlayModal'
import SelectTemplateView from '../views/SelectTemplateView/SelectTemplateView'
import {
  generateApp,
  initialiseAppCreation,
  resetSelectedTemplate,
  updateSelectedTemplate,
} from '../redux/actions/actionCreators'
import styles from './SelectTemplateModal.scss'

const propTypes = {
  modalId: PropTypes.string.isRequired,
}

const SelectTemplateModal = ({ modalId }) => {
  const dispatch = useDispatch()
  const modals = useSelector(state => state.modals)
  const isTemplateSelected = useSelector(state => !!state.genAi.selectedTemplate)

  const clearSelectedTemplate = (event) => {
    if (!event.target.closest('.actionArea') && !event.target.closest(`.${styles.buttonContainer}`)) {
      dispatch(resetSelectedTemplate())
    }
  }

  const handleOnClose = () => {
    dispatch(resetSelectedTemplate())
    document.removeEventListener('mousedown', clearSelectedTemplate)
    dispatch(modalHide(modalId))
  }

  const handleSubmit = () => {
    dispatch(initialiseAppCreation())
    dispatch(generateApp())
    document.removeEventListener('mousedown', clearSelectedTemplate)
  }

  const onClick = (template) => {
    dispatch(updateSelectedTemplate(template))
  }

  useEffect(() => {
    if (modals[modalId].show === true) {
      document.addEventListener('mousedown', clearSelectedTemplate)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modals[modalId].show])

  return (
    <OverlayModal
      base={20}
      disable={false}
      id={modalId}
      init={() => { }}
      modals={modals}
      onClose={handleOnClose}
      push={0}
    >
      <Animate name="fade">
        <Container noMargin className={styles.base}>
          <div className={styles.contentWrapper}>
            <div className={styles.titleSection}>
              <Heading className={styles.title} level={5}>
                Create custom GPT app
              </Heading>
              <p>
                Get started with a range of generative AI app templates or create your custom app.
                <br />
                Personalise prompts, knowledge and configure the AI model.
              </p>
            </div>
            <div className={styles.appContent}>
              <SelectTemplateView onClick={onClick} />
            </div>
          </div>
        </Container>
      </Animate>
      <div className={styles.buttonContainer}>
        <Button
          disabled={!isTemplateSelected}
          id="btnCreateApp"
          mode="primary"
          onClick={handleSubmit}
        >
          CREATE APP
        </Button>
      </div>
    </OverlayModal>
  )
}

SelectTemplateModal.propTypes = propTypes

export default SelectTemplateModal
