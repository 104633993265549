import { getConfig } from 'utils/config'

export const staticFiles = {
  practitionerFaq: { name: 'InsightBox FAQs.pdf', external: false },
  handlingClientData: { name: 'Handling Client Data for Analytics.pdf', external: false },
  dataFormatGuidelines: { name: 'Data format guidelines.pdf', external: false },
  openSourceNotice: { name: 'Open Source Notice.pdf', external: true },
}

export const getStaticFilePath = (file) => {
  const fileKeys = Object.keys(file)
  if (!fileKeys.includes('name') || !fileKeys.includes('external')) {
    return null
  }

  const { API_BASE } = getConfig()
  const basePath = `${API_BASE}${file.external ? '/client' : ''}/file/static/`

  return `${basePath}${file.name}`
}
