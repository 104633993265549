import React, { useEffect, useState } from 'react'
import {
  Heading, DataTableSimple, Card, Button, Select, TextField,
} from '@deloitte/gel-library'
import IconAdd from '@material-ui/icons/Add'
import IconClose from '@material-ui/icons/Close'
import IconDelete from '@material-ui/icons/Delete'
import { useDispatch, useSelector } from 'react-redux'
import { adminFetchBusinessUnits, adminFetchSystemAccess, adminUpdateSystemAccess } from 'actions/admin'
import { formatDateFromStringShort } from 'utils/dates'
import { hot } from 'react-hot-loader/root'
import { sortByFunction } from 'utils/arrays'
import DialogSimple from 'components/DialogSimple'
import BlockSpinner from 'components/BlockSpinner'
import styles from './AppSettings.scss'

const SystemSettings = () => {
  const dispatch = useDispatch()

  const {
    _isFetchingAccess,
    _isFetchedAccess,
    _isFetchingBus,
    _isFetchedBus,
    access,
    businessUnits,
    businessUnitIds,
  } = useSelector(state => state.admin.systemAccess)

  const userEmail = useSelector(state => state.app.user.email)

  const [showAddForm, setShowAddForm] = useState(false)
  const [selectedAccess, setSelectedAccess] = useState()
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState()
  const [showDeleteDialogue, setShowDeleteDialogue] = useState(false)
  const [showAddDialogue, setShowAddDialogue] = useState(false)
  const [countryInput, setCountryInput] = useState('')

  useEffect(() => {
    if (!_isFetchedBus) {
      dispatch(adminFetchBusinessUnits())
    }
  }, [_isFetchedBus, dispatch])

  useEffect(() => {
    if (!_isFetchedAccess) {
      dispatch(adminFetchSystemAccess())
    }
  }, [_isFetchedAccess, dispatch])

  useEffect(() => {
    setSelectedBusinessUnit(businessUnitIds.length > 0 ? businessUnits[businessUnitIds[0]] : null)
  }, [businessUnits, businessUnitIds, setSelectedBusinessUnit])

  const loaded = !_isFetchingAccess && !_isFetchingBus && _isFetchedBus && _isFetchedAccess

  const columns = loaded
    ? [
      {
        heading: 'Country',
        cellExtractor: row => row.country,
        key: 'country',
      },
      {
        heading: 'Business unit',
        cellExtractor: row => (Number.isInteger(row.businessUnitEnum)
          ? businessUnits[row.businessUnitEnum].description
          : row.businessUnit),
        key: 'bussinesUnit',
      },
      {
        heading: 'Last updated',
        cellExtractor: row => Number.isInteger(row.businessUnitEnum) && (
          <div>
            <div>{formatDateFromStringShort(row.updatedDate) || '-'}</div>
            <div className={styles.secondary}>{row.updatedBy || 'System'}</div>
          </div>
        ),
        key: 'updated',
      },
      {
        heading: '',
        cellExtractor: row => (
          <div>
            {Number.isInteger(row.businessUnitEnum)
              ? (
                <IconDelete
                  className={styles.icon}
                  onClick={() => {
                    setSelectedAccess(row)
                    setShowDeleteDialogue(true)
                  }}
                />
              ) : (
                <IconClose
                  className={styles.icon}
                  onClick={() => setShowAddForm(false)}
                />
              )
          }
          </div>
        ),
        key: 'actions',
        className: styles.deleteColumn,
      },
    ] : []

  const formRow = {
    country: (
      <div className={styles.countryInput}>
        <TextField
          onChange={(_, value) => setCountryInput(value)}
          value={countryInput || ''}
        />
      </div>
    ),
    businessUnit: (
      <Select
        onChange={({ target }) => setSelectedBusinessUnit(businessUnits[target.value])}
        options={businessUnitIds
          .map(id => businessUnits[id])
          .map(bu => ({ value: bu.id, label: bu.description }))
            }
        value={selectedBusinessUnit && selectedBusinessUnit.id}
      />
    ),
    businessUnitEnum: 'form',
    updatedDate: null,
    updatedBy: null,

  }

  const allRows = showAddForm
    ? access.concat([formRow])
    : access

  return (
    <div className={styles.base}>
      <Heading level={8}>System access</Heading>

      {loaded
        ? (
          <Card noPadding>
            <DataTableSimple
              className={styles.table}
              columns={columns}
              data={allRows}
              keyExtractor={row => `${row.country}-${row.businessUnit}`}
              sortFunction={sortByFunction(row => row.country)}
            />
            <div className={styles.addRow}>
              {showAddForm ? (
                <Button
                  mode="text"
                  onClick={() => {
                    setSelectedAccess({
                      country: countryInput,
                      businessUnit: selectedBusinessUnit.name,
                      businessUnitEnum: selectedBusinessUnit.id,
                      updatedBy: userEmail,
                      updatedDate: new Date().toISOString(),
                    })
                    setShowAddDialogue(true)
                  }}
                >
                  Save
                </Button>
              ) : (
                <Button
                  icon={<IconAdd />}
                  mode="text"
                  onClick={() => setShowAddForm(true)}
                >
                  Add
                </Button>
              )}
            </div>
          </Card>
        ) : (
          <BlockSpinner text="Loading system access" />
        )}

      {loaded && selectedAccess && (
      <DialogSimple
        actionLabel={showAddDialogue ? 'create' : 'delete'}
        dismissLabel="cancel"
        isOpen={showDeleteDialogue || showAddDialogue}
        onAction={() => {
          dispatch(adminUpdateSystemAccess(selectedAccess, showDeleteDialogue))
          setShowDeleteDialogue(false)
          setShowAddDialogue(false)
          setShowAddForm(false)
        }}
        onDismiss={() => {
          setShowDeleteDialogue(false)
          setShowAddDialogue(false)
          setShowAddForm(false)
        }}
      >
        <Heading level={7}>{`Are you sure you want to ${showAddDialogue ? 'add' : 'remove'} access?`}</Heading>
        <p>
          {`By ${showAddDialogue ? 'adding' : 'removing'} this system access entry 
          any users that match the pattern below will ${showAddDialogue ? '' : 'no longer'} 
          be able to access the application. This could be a large number of users - please 
          check this is the correct entry to ${showAddDialogue ? 'create' : 'delete'}.`}
        </p>

        <table className={styles.accessTable}>
          <tbody>
            <tr>
              <td><strong>Country</strong></td>
              <td>{selectedAccess.country}</td>
            </tr>
            <tr>
              <td><strong>Business unit</strong></td>
              <td>{businessUnits[selectedAccess.businessUnitEnum].description}</td>
            </tr>
          </tbody>
        </table>
      </DialogSimple>
      )}
    </div>
  )
}

export default hot(SystemSettings)
