/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  VictoryChart, VictoryBar, VictoryAxis, VictoryContainer, Bar,
} from 'victory'
import sizeMe from 'react-sizeme'
import orderBy from 'lodash/orderBy'
import { formatNumber } from 'utils/numbers'
import { isNullOrUndefined } from 'utils/utils'
import theme from './chartTheme'

// Custom bar to ensure the bar is always at least 1px tall
function CustomBar({ y, y0, ...rest }) {
  let newY = -1

  if (!isNullOrUndefined(y) && !isNullOrUndefined(y0)) {
    newY = y - y0 === 0 ? y - 1 : y
  }

  return (
    <Bar
      y={newY}
      y0={y0}
      {...rest}
    />
  )
}

CustomBar.propTypes = {
  y: PropTypes.number,
  y0: PropTypes.number,
}

class BarChart extends Component {
  static propTypes = {
    data: PropTypes.array,
    height: PropTypes.number,
    size: PropTypes.shape({ width: PropTypes.number }).isRequired,
    width: PropTypes.number,
    xProperty: PropTypes.string.isRequired,
    yProperty: PropTypes.string.isRequired,
  }

  static defaultProps = {
    data: null,
    height: null,
    width: null,
  }

  render() {
    const {
      data,
      height,
      width,
      size,
      xProperty,
      yProperty,
    } = this.props

    const { width: containerWidth } = size

    if (data === null) {
      return null
    }

    const sortedData = orderBy(data, yProperty, 'desc')

    return (
      <div>
        {data && (
          <VictoryChart
            containerComponent={<VictoryContainer responsive={false} />}
            domainPadding={{ x: 72 }}
            height={height}
            padding={{
              top: 30, bottom: 30, left: 0, right: 0,
            }}
            theme={theme}
            width={width || containerWidth}
          >
            <VictoryAxis dependentAxis />
            <VictoryAxis />
            <VictoryBar
              barRatio={0.75}
              data={sortedData}
              dataComponent={<CustomBar />}
              labels={({ datum }) => formatNumber(datum[yProperty])}
              x={xProperty}
              y={yProperty}
            />
          </VictoryChart>
        )}
      </div>
    )
  }
}

export default sizeMe({
  monitorWidth: true,
})(BarChart)
