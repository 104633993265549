import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Label from 'components/Label'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import { sortByFunction } from 'utils/arrays'
import {
  formatDateShort, formatDateTimeFromStringShort,
} from 'utils/dates'
import usePackages from 'hooks/usePackages'
import styles from './ReportSidebar.scss'

const propTypes = {
  analysis: PropTypes.shape({
    createdBy: PropTypes.string,
    createdDate: PropTypes.string,
    description: PropTypes.string,
    displayName: PropTypes.string,
    name: PropTypes.string,
    parameters: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  isCustomReport: PropTypes.bool,
  job: PropTypes.shape({
    analyses: PropTypes.arrayOf(PropTypes.object),
    dpFrom: PropTypes.string,
    dpTo: PropTypes.string,
    dpYE: PropTypes.string,
    packageId: PropTypes.string,
  }).isRequired,
  onToggleVisible: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
}

const defaultProps = {
  isCustomReport: false,
}

const FormatValue = ({ parameter }) => {
  switch (parameter.type) {
    case 'BIT':
      return <p>{parameter.value ? 'Yes' : 'No'}</p>
    case 'TABLE': {
      return (
        <ul>
          {parameter.value.map(x => (
            <li key={x.key}>{parameter.schema.map(y => x[y.name]).join(', ')}</li>
          ))}
        </ul>
      )
    }
    default:
      return <p>{parameter.value}</p>
  }
}

FormatValue.propTypes = {
  parameter: PropTypes.shape({
    schema: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
    })),
    type: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array,
    ]),
  }).isRequired,
}

function ReportSidebar({
  visible, job, analysis, onToggleVisible, isCustomReport,
}) {
  const { packages } = usePackages()
  return (
    <div className={classnames(styles.base, { [styles.visible]: visible })}>
      <div className={styles.wrapper}>
        <a className={styles.expandButton} onClick={onToggleVisible}>
          <ChevronLeftIcon color="secondary" />
        </a>
        {!isCustomReport ? (job.dpYE && job.dpFrom && (
        <div className={styles.card}>

          <Label>Analysis period</Label>
          <p>{`${formatDateShort(job.dpFrom)} - ${formatDateShort(job.dpTo)}`}</p>
          <Label>Year end</Label>
          <p>{formatDateShort(job.dpYE)}</p>
          {packages[job.packageId]?.meta.showParametersOnTableauSidebar && analysis.parameters && (
          <div>
            <p className={styles.sectionHeader}>Test parameters</p>
            {analysis.parameters
              .sort(sortByFunction(x => x.displayOrder))
              .map(p => (
                <div key={p.id}>
                  <Label>{p.name}</Label>
                  <FormatValue parameter={p} />
                </div>
              ))}
          </div>
          )}
        </div>
        )) : (
          <div className={styles.card}>
            <Label>Analysis Description</Label>
            <p>{analysis.description}</p>
            <Label>Created By</Label>
            <p>{analysis.createdBy}</p>
            <p>{formatDateTimeFromStringShort(analysis.createdDate)}</p>
          </div>
        )}

      </div>

    </div>
  )
}

ReportSidebar.propTypes = propTypes
ReportSidebar.defaultProps = defaultProps

export default ReportSidebar
