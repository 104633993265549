import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { IconSubmit } from 'icons'
import { Button } from '@deloitte/gel-library'
import styles from './SubmitButton.scss'

const SubmitButton = ({
  children,
  complete,
  completeText,
  disabled,
  submitting,
  submittingText,
  type,
  className,
  icon,
  ...rest
}) => {
  const iconClasses = {
    [styles.submitting]: submitting,
    [styles.complete]: complete,
  }

  const buttonClasses = {
    [styles.disabled]: complete,
  }

  const disabledState = (disabled || submitting || complete)

  let pos = styles.defaultPos
  if (complete) {
    pos = styles.completePos
  } else if (submitting) {
    pos = styles.submittingPos
  }

  return (
    <Button
      className={classnames(styles.base, buttonClasses, className)}
      disabled={disabledState}
      icon={complete || submitting ? <IconSubmit className={classnames(styles.icon, iconClasses)} /> : icon}
      type={type}
      {...rest}
    >
      <span className={classnames(styles.text, pos)}>
        <span className={classnames(styles.defaultText, (!submitting && !complete) && styles.textActive)}>
          {children}
        </span>
        <span className={classnames(styles.completeText, (complete) && styles.textActive)}>
          {completeText}
        </span>
        <span className={classnames(styles.submittingText, (submitting) && styles.textActive)}>
          {submittingText}
        </span>
      </span>
    </Button>
  )
}

SubmitButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  complete: PropTypes.bool,
  completeText: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  submitting: PropTypes.bool,
  submittingText: PropTypes.string,
  type: PropTypes.string,
}

SubmitButton.defaultProps = {
  children: null,
  className: null,
  complete: false,
  completeText: 'Complete',
  disabled: false,
  icon: null,
  submitting: false,
  submittingText: 'Sending',
  type: 'primary',
}

export default SubmitButton
