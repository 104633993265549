import React from 'react'
import PropTypes from 'prop-types'
import placeholderImage from 'images/solutionPlaceholder.png'

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  solution: PropTypes.shape({
    analyses: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
    })),
    meta: PropTypes.shape({
      featuredAnalyses: PropTypes.arrayOf(PropTypes.string),
    }),
    name: PropTypes.string,
  }).isRequired,
  style: PropTypes.object,
}

const defaultProps = {
  children: null,
  className: null,
  style: null,
}

function SolutionImageIcon({
  solution, children, className, style,
}) {
  const { analyses, meta: { featuredAnalyses } } = solution

  const imageAnalyses = featuredAnalyses
    .map(x => analyses.find(a => a.name === x))
    .filter(x => x)
    .slice(0, 2)
    .reverse()

  return (
    <div className={className} style={style}>
      {imageAnalyses.length > 0
        ? imageAnalyses.map(a => (
          <img
            alt={a.displayName}
            key={a.name}
            src={`/api/client/packages/${solution.name}/analyses/${a.name}/image`}
          />
        ))
        : (
          <img
            alt={solution.name}
            src={placeholderImage}
          />
        )
      }

      {children}
    </div>
  )
}

SolutionImageIcon.propTypes = propTypes
SolutionImageIcon.defaultProps = defaultProps

export default SolutionImageIcon
