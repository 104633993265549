import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { fetchPackages } from 'actions/packages'
import { push } from 'connected-react-router'

export default function usePackages() {
  const dispatch = useDispatch()
  const isFetched = useSelector(state => state.packages.isFetched)
  const isFetching = useSelector(state => state.packages.isFetching)
  const collections = useSelector(state => state.packages.collections)
  const packageIds = useSelector(state => state.packages.ids)
  const packages = useSelector(state => state.packages.packages)

  useEffect(() => {
    if (!isFetched && !isFetching) {
      dispatch(fetchPackages())
    }
  }, [dispatch, isFetched, isFetching])

  const goToSolution = (solutionName) => {
    dispatch(push(`/solution/${solutionName}`))
  }

  const getSolution = (solutionName) => {
    return packages[solutionName]
  }

  return {
    collections,
    packages,
    packageIds,
    goToSolution,
    getSolution,
    isFetched,
    isFetching,
  }
}
