/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconAmex = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--amex',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200.4 73.4"{...rest}><path className="path1" fill="#0078A9" d="M37.2 39.3h11.5l-5.8-15.1zM128.3 25.3v6.9h18.6V40h-18.6v7.5h20.8l9.7-11.1-9.2-11.1z"/><path className="path2" fill="#0078A9" d="M175.6 18.1h7.7l-.1 4.1-13 15 13 15.4v3.5h-8l-10.5-12.6L153.8 56h-33.6V18.1h34.2l10.5 12.4 10.7-12.4zM99.2 18h14.1v37.9h-8.7V26.2L92.3 55.9h-7.5L72.5 26.2v29.7H55.2L52 47.4H34.3L31 55.9h-9.2L37 18h12.6L64 53.9V18h13.9L89 43.7 99.2 18zm-5.1-7.5l-5.5 13.7-5.7-13.7H57v6.2l-2.9-6.2H32L10.4 63.3h25.8l3.3-8.4h7.3l3.3 8.4h28.6v-6.4l2.5 6.5H96l2.5-6.6v6.5h18.3v.1h40.6l7.2-8.2 6.8 8.2h18.9V10.5h-18.5l-7 8.1-6.6-8.1H94.1z"/></IconBase>
  )
}

IconAmex.propTypes = {
  className: PropTypes.string,
}

export default IconAmex
/* eslint-enable */
