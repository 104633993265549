/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconVisa = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--visa',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200.4 73.4"{...rest}><path className="path1" fill="#00589F" d="M74.7 62.1l8.9-52.2h14.2l-8.9 52.2zM140.5 11.2c-2.8-1.1-7.2-2.2-12.8-2.2-14.1 0-24 7.1-24 17.2-.1 7.5 7.1 11.7 12.5 14.2 5.5 2.6 7.4 4.2 7.4 6.5 0 3.5-4.4 5.1-8.5 5.1-5.7 0-8.7-.8-13.4-2.7l-1.8-.8-2 11.7c3.3 1.5 9.5 2.7 15.9 2.8 15 0 24.7-7 24.8-17.8.1-5.9-3.7-10.5-11.9-14.2-5-2.4-8-4-8-6.5 0-2.2 2.6-4.5 8.1-4.5 4.7-.1 8 .9 10.6 2l1.3.6 1.8-11.4M177 10h-11c-3.4 0-6 .9-7.5 4.3l-21.1 47.8h14.9s2.4-6.4 3-7.8h18.2c.4 1.8 1.7 7.8 1.7 7.8h13.2L177 10m-17.5 33.6c1.2-3 5.7-14.6 5.7-14.6-.1.1 1.2-3 1.9-5l1 4.5s2.7 12.5 3.3 15.1h-11.9zM62.8 9.9l-14 35.6-1.5-7.2C44.8 30 36.7 20.9 27.7 16.4l12.7 45.7h15.1L77.8 9.9h-15"/><path className="path2" fill="#F9A51A" d="M35.9 9.9H13l-.2 1.1c17.9 4.3 29.7 14.8 34.6 27.3l-5-24C41.5 11 39 10 35.9 9.9"/></IconBase>
  )
}

IconVisa.propTypes = {
  className: PropTypes.string,
}

export default IconVisa
/* eslint-enable */
