/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconLock = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--lock',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18"{...rest}><path className="path1" fill="#58595B" d="M13.9 6.8V4.9C13.9 2.2 11.7 0 9 0S4.1 2.2 4.1 4.9v1.9c-1.1 0-2 .9-2 2V16c0 1.1.9 2 2 2h9.8c1.1 0 1.9-.9 1.9-2V8.8c0-1.1-.8-1.9-1.9-2zM5.8 4.9c0-1.8 1.5-3.2 3.2-3.2 1.8 0 3.2 1.5 3.2 3.2v1.9H5.8V4.9zM14.2 16c0 .2-.2.3-.3.3H4.2c-.2 0-.3-.2-.3-.3V8.8c0-.1.1-.3.2-.3h9.8c.2 0 .3.2.3.3V16z"/></IconBase>
  )
}

IconLock.propTypes = {
  className: PropTypes.string,
}

export default IconLock
/* eslint-enable */
