import axios from 'axios'
import 'react-hot-loader'
import React, { Fragment } from 'react'
import { render } from 'react-dom'
import 'react-toolbox/lib/colors.css'
import './styles/base.scss'
import { ThemeProvider } from '@material-ui/core/styles'
import { theme, GelThemeProvider } from '@deloitte/gel-library'
import { initAppInsights } from 'utils/appInsights'
import Root from './containers/Root'
import { setConfig } from './utils/config'
import { init as initAPI } from './utils/api'

function renderApp() {
  axios.get(`${window.location.origin}/config.json`)
    .then(({ data: config }) => {
      setConfig(config)
      initAppInsights(config.AI_INSTRUMENTATION_KEY)
      initAPI()
      render(
        <Fragment>
          <ThemeProvider theme={theme}>
            <GelThemeProvider>
              <Root />
            </GelThemeProvider>
          </ThemeProvider>
        </Fragment>,
        document.getElementById('root'),
      )
    })
}

renderApp()

if (module.hot) {
  module.hot.accept('./containers/Root.js', renderApp())
}
