/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconLinkedIn = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--linkedin',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"{...rest}><path className="path1" fill="#888" fillRule="evenodd" d="M.153 12h2.572V4.263H.153V12zM1.456.533C.576.533 0 1.111 0 1.87c0 .742.56 1.337 1.422 1.337h.017c.897 0 1.455-.594 1.455-1.337C2.877 1.11 2.336.533 1.456.533zM12 7.563V12H9.428V7.86c0-1.04-.372-1.749-1.303-1.749-.71 0-1.134.478-1.32.941-.067.165-.085.396-.085.627V12H4.147s.035-7.01 0-7.737H6.72V5.36l-.017.025h.017V5.36c.342-.527.952-1.279 2.318-1.279C10.731 4.081 12 5.187 12 7.564z"/></IconBase>
  )
}

IconLinkedIn.propTypes = {
  className: PropTypes.string,
}

export default IconLinkedIn
/* eslint-enable */
