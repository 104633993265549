import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Heading, Button, TextField } from '@deloitte/gel-library'
import constants from 'constants'
import SelectInput from 'components/Form/components/SelectInput'
import TextArea from 'components/TextArea'
import {
  formInit, formUpdate, formReset, formError,
} from 'actions/forms'
import Label from 'components/Label'
import { isNullOrWhiteSpace } from 'utils/strings'
import useUrl from 'hooks/useUrl'
import styles from './ContactForm.scss'

export const CONTACT_OPTIONS = {
  GeneralEnquiry: 'General enquiry',
  DataPreparationService: 'Data preparation service',
  Access: 'Access',
  ReportAProblem: 'Report a problem',
  Feedback: 'Feedback',
  ExtensionOfAccess: 'Extension of access',
  Other: 'Other',
}

const selectOptions = Object.entries(CONTACT_OPTIONS)
  .map(([, value]) => ({ value, label: value }))

const formFieldNames = {
  subject: 'subject',
  message: 'message',
  jobReference: 'jobReference',
}

const FORM_ID = constants.FORM_SUPPORT_CONTACT

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  forms: PropTypes.object.isRequired,
  isExternal: PropTypes.bool.isRequired,
  jobRefNo: PropTypes.number,
  onSubmit: PropTypes.func,
  onSupportPage: PropTypes.bool,
}

const defaultProps = {
  onSubmit: () => {},
  onSupportPage: false,
  jobRefNo: null,
}

function ContactForm({
  dispatch, forms, isExternal, onSubmit, onSupportPage, jobRefNo,
}) {
  const { search } = useUrl()

  useEffect(() => {
    const initialOption = search.option
      ? selectOptions.find(o => o.value === search.option)
      : selectOptions[0]

    const formSchema = {
      formFields: [
        {
          name: formFieldNames.subject,
        },
        {
          name: formFieldNames.message,
        },
        {
          name: formFieldNames.jobReference,
        },
      ],
      data: {
        subject: initialOption.value,
        message: '',
        jobReference: jobRefNo || '',
      },
    }

    dispatch(formInit(FORM_ID, formSchema))
  }, [dispatch, jobRefNo, search.option])

  const updateForm = (field, value) => {
    dispatch(formUpdate(FORM_ID, {
      field,
      data: { value },
    }))
  }

  const errorForm = (field, error) => {
    dispatch(formError(FORM_ID, field, error))
  }

  const handleSubmit = () => {
    onSubmit()
    dispatch(formReset(FORM_ID))
  }

  const validateJobReference = () => {
    const { jobReference } = forms[FORM_ID]

    if (!isNullOrWhiteSpace(jobReference.value) && !jobReference.value.match('^[0-9]+$')) {
      errorForm(formFieldNames.jobReference, 'Job reference should be a number')
    } else {
      errorForm(formFieldNames.jobReference, '')
    }
  }

  if (!forms[FORM_ID]) {
    return (
      <div className={styles.base}>
        <div className={styles.content}> </div>
      </div>
    )
  }

  const { subject, message, jobReference } = forms[FORM_ID]
  const selectedSubject = selectOptions.find(x => x.value === subject.value)
  return (
    <div className={styles.base} id="contact">
      <div className={styles.content}>
        <div className="row">
          <div className="col-md-4 col-sm-12">
            <Heading level={4}>{onSupportPage ? 'Still have questions? Contact us' : 'Contact us'}</Heading>
            <p>
              Fill out the contact form below to get in touch with us. Please
              provide as much information as possible for us to help you with your enquiry.
              {' '}
            </p>
          </div>
          <div className="col-md-offset-1 col-md-7 col-sm-12">
            <SelectInput
              className={styles.select}
              handleChange={updateForm}
              label="Choose a subject"
              name={formFieldNames.subject}
              options={selectOptions}
              placeholder={selectedSubject && selectedSubject.label}
              value={selectedSubject}
            />
            {!isExternal && (
              <>
                <br />
                <Label>
                  Job Reference No.
                </Label>

                <TextField
                  fullWidth
                  error={jobReference.error}
                  name={formFieldNames.jobReference}
                  onBlur={validateJobReference}
                  onChange={updateForm}
                  value={jobReference.value}
                />
              </>
            )}
            <TextArea
              className={styles.textarea}
              maxLength={1000}
              onChange={value => updateForm(formFieldNames.message, value)}
              placeholder="Write a message"
              value={message.value}
            />
            <Button
              disabled={message.value === ''}
              onClick={handleSubmit}
            >
              Send
            </Button>
          </div>
        </div>
      </div>

    </div>
  )
}

ContactForm.propTypes = propTypes
ContactForm.defaultProps = defaultProps

export default ContactForm
