/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconPayroll = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--payroll',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"{...rest}><title>Fill 1</title><g fill="#58595B"><path className="path1" d="M23.6 7.9L16.1.4C15.8.2 15.4 0 15 0s-.8.2-1 .4L.4 14c-.2.3-.4.6-.4 1s.2.8.4 1l7.5 7.5c.3.4.7.5 1.1.5.4 0 .7-.1 1-.4L23.6 10c.2-.3.4-.6.4-1s-.2-.8-.4-1.1zm-1 1.2L9.1 22.6c-.1.1-.1.1-.2 0l-7.5-7.5V15v-.1L14.9 1.4h.2l7.5 7.5c.1 0 .1 0 0 .2.1-.1.1-.1 0 0z"/><path className="path2" d="M18.9 8.7c0-.5-.2-.9-.5-1.3-.3-.3-.8-.5-1.3-.5s-.9.2-1.3.5l-.8.9c-.3.3-.8.3-1.1 0-.3-.3-.3-.8 0-1.1l.8-.8c.2-.2.5-.3.8-.2.3.1.6 0 .7-.3.1-.3 0-.6-.3-.7-.7-.3-1.5-.2-2 .4l-.1.1-.4-.4c-.2-.2-.4-.2-.6 0-.2.2-.2.4 0 .6l.4.4-.1.1c-.7.7-.7 1.9 0 2.6.7.7 1.9.7 2.6 0l.8-.8c.3-.3.8-.3 1.1 0 .1.1.2.3.2.5s-.1.4-.2.5l-.8.8c-.2.2-.5.3-.8.2-.3-.1-.6 0-.7.3s0 .6.3.7c.2.1.5.2.7.2.5 0 .9-.2 1.3-.5l.1-.1.4.4c.1.1.2.1.3.1.1 0 .2 0 .3-.1.2-.2.2-.4 0-.6l-.4-.4.1-.1c.3-.4.5-.9.5-1.4zM10.4 10.1c-.2-.2-.4-.2-.6 0l-5.1 5.1c-.2.2-.2.4 0 .6.1.1.2.1.3.1.1 0 .2 0 .3-.1l5.1-5.1c.2-.2.2-.5 0-.6zM12.2 11.8c-.2-.2-.4-.2-.6 0l-5.1 5.1c-.2.2-.2.4 0 .6.1.1.2.1.3.1.1 0 .2 0 .3-.1l5.1-5.1c.1-.2.1-.4 0-.6zM11.7 15.2l-3.5 3.5c-.2.2-.2.4 0 .6.1.1.2.1.3.1.1 0 .2 0 .3-.1l3.5-3.5c.2-.2.2-.4 0-.6-.2-.2-.4-.2-.6 0z"/></g></IconBase>
  )
}

IconPayroll.propTypes = {
  className: PropTypes.string,
}

export default IconPayroll
/* eslint-enable */
