import { getDataValidationResults, getValidationResults, getValidationSummary } from 'utils/api/job'
import { isExternalFromState } from 'utils/users'

export const VALIDATION_UPDATE_VALIDATIONS = 'VALIDATION_UPDATE_VALIDATIONS'
export const VALIDATION_FETCHING_VALIDATION_SUMMARY = 'VALIDATION_FETCHING_VALIDATION_SUMMARY'
export const VALIDATION_FETCHED = 'VALIDATION_FETCHED'
export const VALIDATION_FETCHING = 'VALIDATION_FETCHING'
export const VALIDATION_RESET = 'VALIDATION_RESET'
export const VALIDATION_SET_SEVERITY_FILTER = 'VALIDATION_SET_SEVERITY_FILTER'

export const updateValidations = (jobId, validations) => ({
  type: VALIDATION_UPDATE_VALIDATIONS,
  validations,
  jobId,
})

export const fetchingValidationSummary = jobId => ({
  type: VALIDATION_FETCHING_VALIDATION_SUMMARY,
  jobId,
})

export function fetchValidationsSummary(jobId) {
  return (dispatch, getState) => {
    dispatch(fetchingValidationSummary(jobId))
    getValidationSummary(jobId, isExternalFromState(getState()))
      .then(({ data }) => {
        dispatch(updateValidations(jobId, data))
      })
  }
}

export const fetchingErrors = validationName => ({
  type: VALIDATION_FETCHING,
  validationName,
})

export const fetchedErrors = (validationName, { columnMapping, data }, page) => ({
  type: VALIDATION_FETCHED,
  validationName,
  columnMapping,
  page,
  validationErrors: data,
})

export const resetErrors = () => ({
  type: VALIDATION_RESET,
})

export const setSeverityFilter = severityFilter => ({
  type: VALIDATION_SET_SEVERITY_FILTER,
  severityFilter,
})

export function fetchErrors(jobId, validationName, page) {
  return (dispatch, getState) => {
    dispatch(fetchingErrors(validationName))
    getValidationResults(jobId, validationName, page, isExternalFromState(getState()))
      .then(
        ({ data }) => {
          dispatch(fetchedErrors(validationName, data, page))
        },
      )
  }
}

export function fetchDataValidations(jobId, fileId, page) {
  return (dispatch, getState) => {
    dispatch(fetchingErrors(`VAL_DATA_${fileId}`))
    getDataValidationResults(jobId, fileId, page, isExternalFromState(getState()))
      .then(
        ({ data }) => {
          dispatch(fetchedErrors(`VAL_DATA_${fileId}`, data, page))
        },
      )
  }
}
