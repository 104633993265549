import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Icons } from '@deloitte/gel-library'
import { formatDateFromStringShort } from 'utils/dates'
import classnames from 'classnames'
import styles from './CustomReport.scss'

const propTypes = {
  report: PropTypes.object.isRequired,
}

function CustomReport({ report }) {
  return (
    <Fragment>
      <div className={classnames(styles.cusrepSubSec, styles.nameNDesc)}>
        <div className={styles.cusrepHeadings}>{report.name}</div>
        <div className={styles.cusrepValues}>{report.description}</div>
      </div>
      <div className={styles.cusrepSubSec}>
        <div className={styles.cusrepSubHeadings}>
          Updated
          {' '}
          {formatDateFromStringShort(report.updatedDate)}
        </div>
        <div className={styles.cusrepValues}>{report.updatedBy}</div>
      </div>
      <div className={styles.cusrepIcon}>
        <Icons.IconChevronRight height={24} width={24} />
      </div>
    </Fragment>
  )
}

CustomReport.propTypes = propTypes

export default CustomReport
