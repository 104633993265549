import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { hot } from 'react-hot-loader/root'
import { fetchHostedReport } from 'actions/hostedReports'
import Loading from 'components/Loading'
import ReportView from 'views/Report'
import { fetchClientCustomReport } from 'actions/client'
import { push } from 'connected-react-router'

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  group: PropTypes.object,
  groupId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  report: PropTypes.object,
  user: PropTypes.object,
}

const defaultProps = {
  group: null,
  report: null,
  user: null,
}

function HostedReport({
  dispatch, groupId, report, group, history, user: { _isFetched, isExternal },
}) {
  useEffect(() => {
    if (!report && _isFetched) {
      if (isExternal) {
        dispatch(fetchClientCustomReport(groupId))
      } else {
        dispatch(fetchHostedReport(groupId))
      }
    }
  }, [groupId, report, _isFetched, isExternal, dispatch])

  if (!report || !group) {
    return <Loading pageLoading />
  }

  return (
    <ReportView
      isCustomReport
      analysis={report}
      goToReport={reportId => dispatch(push(`${reportId}`))}
      history={history}
      job={{ ...group, analyses: group.reports.map(r => ({ ...r, hasData: true })) }}
      report={{ viewUrl: report.url, type: 'hosted' }}
      returnLink={isExternal ? `/custom/insights/${groupId}/reports` : `/custom/${groupId}/reports`}
    />
  )
}

HostedReport.propTypes = propTypes
HostedReport.defaultProps = defaultProps

const mapStateToProps = (
  {
    hostedReports: { reportGroups },
    app: { user },
    app: { user: { isExternal } },
    client: { reportGroups: clientGroups },
  },
  { match: { params: { groupId, reportId } } },
) => {
  const groups = isExternal ? clientGroups : reportGroups
  const report = groups[groupId] && groups[groupId].reports && groups[groupId].reports.find(x => x.id === reportId)
  const group = groups[groupId]

  return {
    groupId,
    reportId,
    group,
    report,
    user,
  }
}

export default hot(connect(mapStateToProps)(HostedReport))
