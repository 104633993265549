import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Colors } from '@deloitte/gel-library'
import IconBase from './IconBase'

const IconFileError = ({ className, errorType, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--fileerror',
    className,
  )

  const isError = errorType === 'error'

  const alertColor = isError
    ? Colors.supportRed01
    : Colors.supportOrange01

  return (
    <IconBase className={classes} viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <title>{isError ? 'File error' : 'File warning'}</title>
      <defs>
        <path d="M2,0 C0.9,0 0.01,0.9 0.01,2 L0,18 C0,19.1 0.89,20 1.99,20 L14,20 C15.1,20 16,19.1 16,18 L16,6 L10,0 L2,0 Z M9,7 L9,1.5 L14.5,7 L9,7 Z" id="path-1" />
      </defs>
      <g fill="none" fillRule="evenodd" id="Designs" stroke="none" strokeWidth="1">
        <g id="Data-quality-check---Errors-detected" transform="translate(-107.000000, -1178.000000)">
          <g id="Group-4" transform="translate(103.000000, 1176.000000)">
            <g id="Icon-/-File">
              <g id="ic_file-copy">
                <g id="Colour-/-Support-blue-02" transform="translate(4.000000, 2.000000)">
                  <mask fill="white" id="mask-2">
                    <use xlinkHref="#path-1" />
                  </mask>
                  <use fill="#BDBDBD" fillRule="nonzero" id="Mask" xlinkHref="#path-1" />
                  <g fill="#BDBDBD" fillRule="nonzero" id="Colour-/-UI-Grey-05" mask="url(#mask-2)">
                    <g transform="translate(-10.000000, -8.000000)">
                      <rect height="36" width="36" x="0" y="0" />
                    </g>
                  </g>
                </g>
                <polygon id="Shape" points="0 0 24 0 24 24 0 24" />
              </g>
            </g>
            <g id="ic_warning_black_24px-(1)-copy-5" transform="translate(9.000000, 9.000000)">
              <polygon id="Path" points="0 0 18 0 18 18 0 18" />
              <rect fill="#FFFFFF" height="9" width="4" x="7" y="6" />
              <path
                d="M0.75,15.75 L17.25,15.75 L9,1.5 L0.75,15.75 Z M9.75,13.5 L8.25,13.5 L8.25,12 L9.75,12 L9.75,13.5 Z M9.75,10.5 L8.25,10.5 L8.25,7.5 L9.75,7.5 L9.75,10.5 Z"
                fill={alertColor}
                fillRule="nonzero"
                id="Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </IconBase>
  )
}

IconFileError.propTypes = {
  className: PropTypes.string,
  errorType: PropTypes.oneOf(['warning', 'error']),
}

IconFileError.defaultProps = {
  className: null,
  errorType: 'warning',
}

export default IconFileError
