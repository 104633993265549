import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { hot } from 'react-hot-loader/root'
import constants from 'constants'
import Loading from 'components/Loading'
import RouteWithSubRoutes from 'components/RouteWithSubRoutes'
import { connect, useDispatch } from 'react-redux'
import { withRouter, Switch } from 'react-router-dom'
import { fetchCurrentActions, fetchJobDetails } from 'actions/job'
import { redirect } from 'actions/app'
import { modalCreate, modalShow, modalHide } from 'actions/modals'
import ProgressHeader from 'views/Create/ProgressHeader'
import { getWorkflowSteps, jobStatuses } from 'utils/business/workflow'
import ClientJobWarningModal from 'components/ClientJobWarningModal'
import usePackages from 'hooks/usePackages'

const propTypes = {
  app: PropTypes.object.isRequired,
  dialogs: PropTypes.object.isRequired,
  engagementCodes: PropTypes.object.isRequired,
  forms: PropTypes.object.isRequired,
  job: PropTypes.shape({
    _fetchedJobDetailsId: PropTypes.string,
    _fetchingJobDetailsId: PropTypes.string,
    _isFetchingJob: PropTypes.bool,
    executions: PropTypes.arrayOf(PropTypes.object),
    files: PropTypes.arrayOf(PropTypes.shape({
      isDataCurrent: PropTypes.bool,
      isRequiredColumnsPresent: PropTypes.bool,
    })),
    isClientJob: PropTypes.bool,
    jobStatusDesc: PropTypes.string,
    members: PropTypes.arrayOf(PropTypes.object),
    packageId: PropTypes.string,
    packageName: PropTypes.string,
    step: PropTypes.number,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
  modals: PropTypes.object.isRequired,
  packages: PropTypes.object.isRequired,
  routes: PropTypes.arrayOf(PropTypes.shape({
    path: PropTypes.string,
  })).isRequired,
  uploaders: PropTypes.object.isRequired,
  validations: PropTypes.object.isRequired,
}

const Create = (props) => {
  const dispatch = useDispatch()

  const {
    routes,
    location: { pathname },
    match: { params: { jobId } },
    app,
    job,
    packages,
    engagementCodes,
    forms,
    modals,
    dialogs,
    uploaders,
    validations,
  } = props

  const { user: { isExternal, _isFetched } } = app
  const {
    _fetchingJobDetailsId, _fetchedJobDetailsId, files, jobStatusDesc,
  } = job

  useEffect(() => {
    if (_isFetched && _fetchedJobDetailsId !== jobId && (_fetchingJobDetailsId === null || _fetchingJobDetailsId !== jobId)) {
      dispatch(fetchJobDetails({ jobId, includeColumns: true }))
      dispatch(fetchCurrentActions({ jobId }))
    }
  }, [dispatch, jobId, _isFetched, _fetchingJobDetailsId, _fetchedJobDetailsId])

  const { isFetched: isPackagesFetched } = usePackages()

  const handleUploaderModal = (command) => {
    const { [`modal${command}`]: action } = props
    action(constants.MODAL_UPLOADER)
  }

  const isControlsAdvantage = job.packageName.toLowerCase() === 'ControlsAdvantage'.toLowerCase()
  const steps = getWorkflowSteps(jobId, isExternal, isControlsAdvantage)

  const isDataValidForAnalysis = files.every(t => t.isDataCurrent && t.isRequiredColumnsPresent)
  const hasExecuted = job.executions && job.executions.length > 0
  const jobStep = jobStatuses[jobStatusDesc]?.stepId
  const maxStepWithValidData = hasExecuted ? 6 : jobStep

  const maximumStepAccess = isDataValidForAnalysis
    ? maxStepWithValidData
    : Math.min(job.step, jobStep)

  const _handleLoseData = (path) => {
    return () => {
      dispatch(redirect(path))
    }
  }

  const currentStep = steps.find(step => pathname.includes(step.to))
  const stepsWithDispatch = steps
    .map(x => (x.toAction ? { ...x, onClick: () => dispatch(x.toAction()) } : x))

  const { isClientJob, members } = job

  const showAddExternalModal = (isClientJob
    && maximumStepAccess >= 3
    && !members.some(m => m.userDetails.isExternal)
  )

  const [dismissExternalModal, setDismissExternalModal] = useState(false)

  return (
    <div>
      {job._isFetchingJob || !_isFetched || !isPackagesFetched
        ? <Loading pageLoading />
        : (
          <ProgressHeader
              currentStep={currentStep.id}
              handleLoseData={_handleLoseData}
              isExternal={isExternal}
              job={job}
              maximumStepAccess={maximumStepAccess}
              steps={stepsWithDispatch}
          >
            <Switch>
              {routes.map(route => (
                <RouteWithSubRoutes
                  editMode
                  app={app}
                  dialogs={dialogs}
                  dispatch={dispatch}
                  engagementCodes={engagementCodes}
                  forms={forms}
                  handleUploaderModal={handleUploaderModal}
                  job={job}
                  key={route.path || 'notfound'}
                  maximumStepAccess={maximumStepAccess}
                  modals={modals}
                  packages={packages}
                  uploaders={uploaders}
                  validations={validations}
                  {...route}
                />
              ))}
            </Switch>
            <ClientJobWarningModal
              isOpen={showAddExternalModal && !dismissExternalModal}
              onDismiss={() => setDismissExternalModal(true)}
            />
          </ProgressHeader>
        )}
    </div>
  )
}

Create.propTypes = propTypes

const mapStateToProps = state => ({
  job: state.job,
  packages: state.packages,
  engagementCodes: state.engagementCodes,
  tests: state.tests,
  forms: state.forms,
  modals: state.modals,
  dialogs: state.dialogs,
  uploaders: state.uploaders,
  app: state.app,
  validations: state.validations,
})

const mapDispatchToProps = (dispatch) => {
  return {
    modalCreate: (modalId) => {
      dispatch(modalCreate(modalId))
    },
    modalShow: (modalId) => {
      dispatch(modalShow(modalId))
    },
    modalHide: (modalId) => {
      dispatch(modalHide(modalId))
    },
  }
}

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(Create)))
