export const TIMEOUTS = [
  {
    name: 'fade',
    timeouts: {
      enterTimeout: 500, // delay 100, duration 400
      leaveTimeout: 400, // duration 400
      appearTimeout: 500, // delay 100, duration 400
    },
  },
  {
    name: 'shake',
    timeouts: {
      enterTimeout: 600, // delay 100, duration 500
      leaveTimeout: 300, // duration 300
      appearTimeout: 600, // delay 100, duration 500
    },
  },
  {
    name: 'slideIn',
    timeouts: {
      enterTimeout: 500, // delay 100, duration 400
      leaveTimeout: 400, // duration 400
      appearTimeout: 500, // delay 100, duration 400
    },
  },
  {
    name: 'slideInFromRight',
    timeouts: {
      enterTimeout: 500, // delay 100, duration 400
      leaveTimeout: 400, // duration 400
      appearTimeout: 500, // delay 100, duration 400
    },
  },
]
