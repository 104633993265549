/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconASIC = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--asic',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"{...rest}><title>Fill 1</title><g fill="#58595B"><path className="path1" d="M4.3 9.8h7.3c.2 0 .4-.2.4-.4s-.2-.4-.4-.4H4.3c-.2 0-.4.2-.4.4s.2.4.4.4zM9.5 11.5H4.3c-.2 0-.4.2-.4.4s.2.4.4.4h5.2c.2 0 .4-.2.4-.4-.1-.3-.2-.4-.4-.4zM8.5 13.9H4.3c-.2 0-.4.2-.4.4s.2.4.4.4h4.2c.2 0 .4-.2.4-.4 0-.3-.2-.4-.4-.4z"/><path className="path2" d="M17.7 9.1V6.8c0-.6-.3-1.3-.7-1.7l-3.2-3.2c-.4-.4-1.1-.7-1.7-.7H1.3C.6 1.2 0 1.8 0 2.5v19c0 .7.6 1.3 1.3 1.3h15.8c3.8 0 6.9-3.1 6.9-6.9 0-3.5-2.8-6.5-6.3-6.8zm-2-3.7h-2.2V3.2l2.2 2.2zM1.3 21.6c-.1 0-.1-.1-.1-.1v-19c0-.1.1-.1.1-.1h10.9V6c0 .3.3.6.6.6h3.7v2.5c-3.5.3-6.3 3.3-6.3 6.8 0 2.3 1.2 4.4 3 5.7H1.3zm15.8 0c-3.1 0-5.7-2.5-5.7-5.7s2.5-5.7 5.7-5.7 5.7 2.5 5.7 5.7-2.5 5.7-5.7 5.7z"/><path className="path3" d="M19.1 15.1v-.8c0-1.1-.9-1.9-1.9-1.9-1.1 0-1.9.9-1.9 1.9v.8c-.4 0-.8.4-.8.8v2.8c0 .4.4.8.8.8H19c.4 0 .8-.4.8-.8v-2.8c0-.4-.3-.8-.7-.8zm-2-2c.7 0 1.2.6 1.2 1.2v.7h-2.5v-.7c.1-.6.7-1.2 1.3-1.2zm2.1 5.6c0 .1 0 .1-.1.1h-3.8c-.1 0-.1 0-.1-.1v-2.8c0-.1 0-.1.1-.1H19c.1 0 .1 0 .1.1v2.8z"/></g></IconBase>
  )
}

IconASIC.propTypes = {
  className: PropTypes.string,
}

export default IconASIC
/* eslint-enable */
