import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import SearchIcon from '@material-ui/icons/Search'
import { green } from '@material-ui/core/colors'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import DoneIcon from '@material-ui/icons/Done'
import styles from './TickmarkHolder.scss'

function TickmarkHolder({ tags, clicked }) {
  const useStyles = makeStyles({
    underline: {
      '&&&:before': {
        width: 215,
      },
      '&&:after': {
        backgroundColor: green[50],
        width: 215,
      },
    },
  })
  const classes = useStyles()
  const searchValue = ''
  const [search, setSearch] = useState(searchValue)
  const searchTextRef = useRef(null)
  const [searchOnFocus, setSearchOnFocus] = useState(false)

  const filteredTags = Object.values(tags.tagsList)
    .filter(t => t?.name.toLowerCase().includes(search.toLowerCase()) || t?.description.toLowerCase().includes(search.toLowerCase()))

  const url = window.location.href
  const tagIdFromUrl = url.indexOf('tag') > -1
  const urlParams = tagIdFromUrl && url.split('/')
  const urlTagId = tagIdFromUrl && urlParams[urlParams.length - 1]

  return (
    <>
      <div className={styles.search}>
        <TextField
          InputProps={{ classes }}
          id="standard-search"
          onBlur={() => setSearchOnFocus(false)}
          onChange={event => setSearch(event.target.value)}
          onFocus={() => setSearchOnFocus(true)}
          placeholder="Search tickmarks"
          ref={searchTextRef}
        />
        <SearchIcon className={classNames(styles.magnifyingGlass, searchOnFocus ? styles.focusedIcon : styles.blurredIcon)} />
      </div>
      <div className={classNames(
        styles.tagsContainer,
        filteredTags.length === 0 ? styles.emptyContainer : null,
      )}
      >
        {filteredTags.length === 0 && <div className={styles.noneTickmarkText}>0 Tickmarks created</div>}
        {filteredTags.map((tag) => {
          return (
            <div
              className={urlTagId === tag.id ? styles.selectedTag : null}
              key={tag.id}
            >
              <div className={styles.flexContainer} onClick={() => clicked(tag)}>
                <div className={styles.tagName}>
                  {tag.name}
                </div>
                <div className={styles.tagDescription}>
                  {tag.description || 'No description'}
                </div>
                <div className={styles.count}>
                  {tag.taskCount}
                </div>
                {!tag.complete && (
                  <div className={classNames(styles.circle, urlTagId === tag.id ? styles.selectedCircle : null)} />
                )}
                {tag.complete && (
                  <DoneIcon className={styles.tick} />
                )}
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

TickmarkHolder.propTypes = {
  clicked: PropTypes.func.isRequired,
  tags: PropTypes.object.isRequired,
}

TickmarkHolder.defaultProps = {
}

export default TickmarkHolder

