import omit from 'lodash/omit'
import moment from 'moment'
import constants from 'constants'
import * as ActionTypes from 'actions/uploaders'

const initialState = {
  current: null,
  uploaders: {},
  status: constants.UPLOAD_STATUS.NORMAL,
}

export default function job(state = initialState, action) {
  const { file, id } = action
  const uploaderId = id || state.current
  switch (action.type) {
    case ActionTypes.UPLOADER_UPDATE_UPLOADERS:
      return Object.assign({}, state, {
        uploaders: action.uploaders,
      })
    case ActionTypes.UPLOADER_SET_CURRENT:
      return Object.assign({}, state, {
        current: action.uploaderIndex,
      })
    case ActionTypes.UPLOADER_ADD_FILE:
      return Object.assign({}, state, {
        uploaders: {
          ...state.uploaders,
          [uploaderId]: {
            ...state.uploaders[uploaderId],
            files: {
              ...state.uploaders[uploaderId].files,
              [file.fileId]: {
                ...state.uploaders[uploaderId].files[file.fileId],
                ...file,
              },
            },
          },
        },
      })
    case ActionTypes.UPLOADER_DELETE_FILE:
      return Object.assign({}, state, {
        uploaders: {
          ...state.uploaders,
          [uploaderId]: {
            ...state.uploaders[uploaderId],
            files: omit(state.uploaders[uploaderId].files, [file.fileId]),
          },
        },
      })
    case ActionTypes.UPLOADER_ADD_FILEERROR: {
      const { error } = action
      if (
        state.uploaders[uploaderId].errors.filter(
          err => err.name === error.name,
        ).length > 0
      ) {
        return state
      }
      return Object.assign({}, state, {
        uploaders: {
          ...state.uploaders,
          [uploaderId]: {
            ...state.uploaders[uploaderId],
            errors: [...state.uploaders[uploaderId].errors.slice(0), error],
          },
        },
      })
    }
    case ActionTypes.UPLOADER_RESET_FILEERROR:
      return Object.assign({}, state, {
        uploaders: {
          ...state.uploaders,
          [state.current]: {
            ...state.uploaders[state.current],
            errors: [],
          },
        },
      })
    case ActionTypes.UPLOADER_REMOVE_FILEERROR: {
      const { index } = action
      return Object.assign({}, state, {
        uploaders: {
          ...state.uploaders,
          [state.current]: {
            ...state.uploaders[state.current],
            errors: [
              ...state.uploaders[state.current].errors.slice(0, index),
              ...state.uploaders[state.current].errors.slice(index + 1),
            ],
          },
        },
      })
    }
    case ActionTypes.UPLOADER_UPDATE_STATUS: {
      const { status } = action
      return Object.assign({}, state, {
        status,
      })
    }
    case ActionTypes.UPLOADER_UPDATE_FILE: {
      const uploader = state.uploaders[action.id]
      const fileToUpdate = uploader.files[action.fileId]
      return {
        ...state,
        uploaders: {
          ...state.uploaders,
          [action.id]: {
            ...uploader,
            lastUploaded:
              action.status === 'saved'
                ? moment()
                : uploader.lastUploaded,
            rowCount: null,
            files: {
              ...uploader.files,
              [action.fileId]: {
                ...fileToUpdate,
                status: action.status,
              },
            },
          },
        },
      }
    }
    default:
      return state
  }
}
