import React from 'react'
import PropTypes from 'prop-types'
import {
  Table, TableHead, TableRow, TableCell,
} from 'react-toolbox/lib/table'
import { scaleLinear } from 'd3-scale'
import { max } from 'd3-array'
import Avatar from 'components/Avatar'
import styles from './RankTable.scss'
import theme from './tableTheme.scss'

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    isCurrentUser: PropTypes.bool,
  })),
  total: PropTypes.number,
}

const defaultProps = {
  data: null,
  total: null,
}

function UserCell({ username, firstName, surname }) {
  return (
    <div className={styles.userCell}>
      <Avatar
        className={styles.avatar}
        nameArray={[firstName, surname]}
      />
      <div className={styles.name}>
        {`${firstName} ${surname}`}
      </div>
      <div className={styles.username}>
        {username}
      </div>
    </div>
  )
}

UserCell.propTypes = {
  firstName: PropTypes.string.isRequired,
  surname: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
}

function EngagementCell({ isMe, numberOfEngagements, barWidth }) {
  return (
    <div className={(isMe && styles.me).toString()}>
      <div className={styles.barContainer}>
        <div
          className={styles.bar}
          style={{ width: `${barWidth}%` }}
        />
      </div>
      <div className={styles.count}>
        {numberOfEngagements}
      </div>
    </div>
  )
}

EngagementCell.propTypes = {
  barWidth: PropTypes.number.isRequired,
  isMe: PropTypes.bool.isRequired,
  numberOfEngagements: PropTypes.number.isRequired,
}

function RankTable(props) {
  const { data, total } = props

  const scale = scaleLinear()
    .domain([0, max(data, d => d.numberOfEngagements)])
    .range([0, 100])

  return (
    <Table
      selectable={false}
      theme={theme}
    >
      <TableHead>
        <TableCell
          className={styles.centerCell}
          theme={theme}
        >
          Rank
        </TableCell>
        <TableCell theme={theme}>
          User
        </TableCell>
        <TableCell theme={theme}>
          Number of engagements
        </TableCell>
      </TableHead>
      {data && data.map(user => (
        <TableRow
          className={user.isCurrentUser ? styles.meRow : null}
          key={user.user.username}
          theme={theme}
        >
          <TableCell className={styles.rank}>
            {user.isCurrentUser
              ? (
                <div>
                  <div className={styles.meRank}>
                    {user.rank}
                  </div>
                  <div className={styles.ofTotal}>
                    {`of ${total}`}
                  </div>
                </div>
              )
              : (
                <div>
                  {user.rank}
                </div>
              )}
          </TableCell>
          <TableCell theme={theme}>
            <UserCell
              firstName={user.user.firstName}
              surname={user.user.surname}
              username={user.user.username}
            />
          </TableCell>
          <TableCell>
            <EngagementCell
              barWidth={scale(user.numberOfEngagements)}
              isMe={user.isCurrentUser}
              numberOfEngagements={user.numberOfEngagements}
            />
          </TableCell>
        </TableRow>
      ))}
    </Table>
  )
}

RankTable.propTypes = propTypes
RankTable.defaultProps = defaultProps

export default RankTable
