export const isNullOrWhiteSpace = (value) => {
  if (value === null) { return true }

  if (typeof (value) !== 'string') { return false }

  return value.match(/^\s*$/)
}

export const urlifyString = (value) => {
  return value.toLowerCase().replace(/[^a-zA-Z ]/g, '').replace(/ /g, '-')
}
