import { makeDate } from 'utils/dates'
import { isNullOrUndefined } from 'utils/utils'
import {
  NO_TEST_STATUSES,
  DATA_PROCESSED_STATUSES,
  COMPLETE_STATUSES,
  jobStatuses,
} from './workflow'

const DEFAULT_ENGAGEMENT_CODE = 'AAA00000-00-00'
export const DEFAULT_RECIPE_NAME = '__NO_RECIPE'

export const isDefaultEngagement = code => code === DEFAULT_ENGAGEMENT_CODE

export const roles = {
  JobPartner: 'JobPartner',
  JobManager: 'JobOwner',
  JobMember: 'JobMember',
  JobExternalReader: 'JobExternalReader',
  JobExternalEditor: 'JobExternalEditor',
  JobExternalActionReader: 'JobExternalActionReader',
  JobExternalActionEditor: 'JobExternalActionEditor',
  HostedReportPartner: 'HostedReportPartner',
  HostedReportManager: 'HostedReportManager',
  HostedReportMember: 'HostedReportMember',
  HostedReportExternalReader: 'HostedReportExternalReader',
  ParticipatingPartner: 'ParticipatingPartner',
  ParticipatingManager: 'ParticipatingManager',
}

export const downloadStatuses = {
  notStarted: 'Not started',
  requesting: 'Requesting',
  downloading: 'Downloading',
  complete: 'Complete',
  failed: 'Failed',
}

export const decorateJob = job => ({
  ...job,
  clientName: job.engagement.clientName,
  benchmarkingEnabled: job.benchmarkAllowed ? 'Yes' : 'No',
  reportCount: 0,
  dpFrom: makeDate(job.periodStartDate),
  dpTo: makeDate(job.periodEndDate),
  dpYE: makeDate(job.clientYearEnd),
  lastExecutionDate: makeDate(job.lastExecutionDate),
  createdDate: makeDate(job.createdDate),
  expiryDate: makeDate(job.expiryDate),
  editable: job.canEdit && !job.closed && job.engagement.active,
  runnable: job.canEdit && !job.closed && job.engagement.active
      && (DATA_PROCESSED_STATUSES.includes(job.jobStatusDesc)
      || COMPLETE_STATUSES.includes(job.jobStatusDesc)),
  progressStatus: job.closed ? 'Closed' : jobStatuses[job.jobStatusDesc]?.description,
  step: jobStatuses[job.jobStatusDesc]?.stepId,
  testsSelected: !NO_TEST_STATUSES.includes(job.jobStatusDesc),
  hasClientMembers: job.members?.some(m => m.userDetails.isExternal) || false,
})

export const jobHasErrors = (job) => {
  return job.dataValidation === 'Minor Issues' || job.dataValidation === 'Major Issues'
}

export const jobHasCriticalErrors = (job) => {
  return job.dataValidation === 'Major Issues'
}

const authorizedMembers = [
  roles.JobPartner,
  roles.JobManager,
  roles.ParticipatingPartner,
  roles.ParticipatingManager,
  roles.HostedReportPartner,
  roles.HostedReportManager,
]

const editableMembers = [
  roles.JobMember,
  roles.HostedReportMember,
]

export const isManagerOrPartnerOnJob = (user, members) => {
  if (!user || !members) { return false }

  const userJobMembership = members.find(x => x.userDetails.email === user.email)
  return userJobMembership && userJobMembership.role.name && authorizedMembers.includes(userJobMembership.role.name)
}

export const jobGetMemberGroups = members => ({
  deloitteTeam: members
    ?.filter(m => !m.userDetails.isExternal),
  externalTeam: members
    ?.filter(m => m.userDetails.isExternal),
})

export const processJobMembers = (userId, members) => {
  const partner = members.filter(member => member.role.name === 'JobPartner')
  const engagementPartner = partner.length > 0
    ? `${partner[0].userDetails.firstName} ${partner[0].userDetails.surname}`
    : ''
  const manager = members.filter(member => member.role.name === 'JobOwner')
  const engagementManager = manager.length > 0
    ? `${manager[0].userDetails.firstName} ${manager[0].userDetails.surname}`
    : ''
  const jobMembers = members
  const membersForJob = jobMembers.length > 0
    ? jobMembers.map(member => ({
      ...member,
      value: member.userDetails.username,
      label: `${member.userDetails.firstName} ${
        member.userDetails.surname
      }`,
    }))
    : []

  return {
    engagementPartner,
    engagementManager,
    members: membersForJob,
    member: membersForJob.find(m => m.id === userId),
  }
}

export const jobHasClientMembers = job => job?.members?.some(m => m.userDetails.isExternal) || false
export const getJobPartnerAndManagerDetails = job => ({
  partner: job?.members?.find(m => m.role.name === roles.JobPartner),
  manager: job?.members?.find(m => m.role.name === roles.JobManager),
  participatingPartner: job?.members?.find(m => m.role.name === roles.ParticipatingPartner),
  ParticipatingManagermanager: job?.members?.find(m => m.role.name === roles.ParticipatingManager),
})

export const isManagerRole = role => [
  roles.JobManager,
  roles.HostedReportManager,
].includes(role)

export const isPartnerRole = role => [
  roles.JobPartner,
  roles.HostedReportPartner,
].includes(role)

export const isParticipatingPartner = role => [
  roles.ParticipatingPartner,
].includes(role)

export const isParticipatingManager = role => [
  roles.ParticipatingManager,
].includes(role)

export const isManagerOrPartnerRole = role => isManagerRole(role) || isPartnerRole(role)

export const isParticipatingManagerOrPartnerRole = role => isParticipatingManager(role) || isParticipatingPartner(role)

export const isEditableMember = role => editableMembers.includes(role)

const roleCheckingFunctions = [
  isPartnerRole,
  isManagerRole,
  isParticipatingPartner,
  isParticipatingManager,
]

export const deloitteTeamSort = (x, y) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const checkRole of roleCheckingFunctions) {
    if (checkRole(x.role.name) !== checkRole(y.role.name)) {
      return checkRole(x.role.name) ? -1 : 1
    }
  }

  // If roles are the same, sort by surname
  if (x.surname < y.surname) {
    return -1
  } if (x.surname > y.surname) {
    return 1
  }
  return 0
}

export const isRecipeSelected = (recipeName) => {
  return !isNullOrUndefined(recipeName) && recipeName !== DEFAULT_RECIPE_NAME
}

export const userHasJobRole = (user, job, role) => {
  if (!job || !user || !role) { return false }
  if (!user.id) { return false }
  if (!(role in roles)) { return false }
  if (!job.members) { return false }

  const jobMember = job.members.find(m => m.id === user.id)
  if (!jobMember) { return false }

  return jobMember.role.name === role
}

export function getAllParameters(job) {
  const { analyses, packageParameters } = job

  const allAnalysesParameters = analyses.flatMap(a => a.parameters)
    .filter(p => p)
    .map(ap => ({
      meta: { parameter: ap },
      value: ap.value,
    }))
  const allPackageParameters = packageParameters.map(ap => ({
    meta: { parameter: ap },
    value: ap.value,
  }))
  const allParameters = allAnalysesParameters.concat(allPackageParameters)
  return allParameters
}

export function getPackageParameters(job) {
  const { packageParameters } = job
  const allPackageParameters = packageParameters.map(ap => ({
    meta: { parameter: ap },
    value: ap.value,
  }))
  return allPackageParameters
}
