import reduce from 'lodash/reduce'
import constants from '../constants'

const { UI_AVATAR_COLOURS, UI_AVATAR_COLOURS_DICT } = constants

function getStringCode(str) {
  const code = reduce(str, (sum, char) => (
    sum + char.charCodeAt(0)
  ), 0)
  return Math.abs(code)
}

export function colourFromString(str) {
  const index = getStringCode(str) % UI_AVATAR_COLOURS.length

  return UI_AVATAR_COLOURS[index]
}

export function colorFromName(name) {
  return (name && UI_AVATAR_COLOURS_DICT[name]) || UI_AVATAR_COLOURS[0]
}
