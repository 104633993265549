import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import VisuallyHidden from 'components/VisuallyHidden'

import styles from './ButtonIcon.scss'

const ButtonIcon = ({
  children, className, label, onClick, ...rest
}) => (
  <button
    className={classnames([styles.base], className)}
    onClick={onClick}
    type="button"
    {...rest}
  >
    <VisuallyHidden>
      {label}
    </VisuallyHidden>
    {children}
  </button>
)

ButtonIcon.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

ButtonIcon.defaultProps = {
  children: null,
  className: null,
  onClick: () => {},
}

export default ButtonIcon
