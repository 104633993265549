/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconManagementReporting = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--managementreporting',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"{...rest}><title>Fill 1</title><g fill="#58595B"><path className="path1" d="M3.8 18.7H2.4c-.2 0-.4.2-.4.4s.2.4.4.4h1.4c.2 0 .4-.2.4-.4s-.2-.4-.4-.4zM13.9 6.5h-1.7c-.2 0-.4.2-.4.4s.2.4.4.4h.8l-3.5 3.5-1.9-1.9c-.1-.1-.2-.1-.3-.1s-.2 0-.3.1l-2.3 2.3c-.1.1-.1.4 0 .5.1.1.2.1.3.1.1 0 .2 0 .3-.1l2-2 1.9 1.9c.1.1.2.1.3.1.1 0 .2 0 .3-.1l3.8-3.8v.8c0 .2.2.4.4.4s.4-.2.4-.4V6.9c-.1-.2-.3-.4-.5-.4z"/><path className="path2" d="M18.9 10.4V3.6c0-.6-.5-1.2-1.2-1.2H1.2C.5 2.4 0 2.9 0 3.6v11.1c0 .6.5 1.2 1.2 1.2h11.6V16.8H1.2c-.6 0-1.2.5-1.2 1.2v2.6c0 .6.5 1.2 1.2 1.2h17.2c3.1 0 5.6-2.5 5.6-5.6 0-3.2-2.2-5.6-5.1-5.8zM1.2 14.7c-.1 0-.1 0-.1-.1v-11c0-.1 0-.1.1-.1h16.5c.1 0 .1 0 .1.1v6.8c-.4 0-.7.1-1.1.2V4.9c0-.2-.1-.3-.3-.3h-14c-.2 0-.3.1-.3.3v8.5c0 .2.1.3.3.3h10.8c-.2.3-.3.7-.4 1.1H1.2zM13.6 13H2.8V5.2h13.3v5.6c-1 .5-1.9 1.2-2.5 2.2zM1.2 20.5c-.1 0-.1 0-.1-.1v-2.6c0-.1 0-.1.1-.1H13c.4 1.1 1.1 2.1 2.1 2.8H1.2zm17.2 0c-2.5 0-4.6-2.1-4.6-4.6 0-2.5 2.1-4.6 4.6-4.6 2.5 0 4.6 2.1 4.6 4.6-.1 2.6-2.1 4.6-4.6 4.6z"/><path className="path3" d="M18.8 15.5h-1c-.4 0-.6-.3-.6-.6 0-.4.3-.6.6-.6h1c.3 0 .5.2.6.4.1.2.3.3.6.2.2-.1.3-.3.2-.6-.2-.6-.8-1-1.4-1h-.1v-.7c0-.2-.2-.4-.4-.4s-.4.2-.4.4v.7h-.1c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5h1c.4 0 .6.3.6.6 0 .4-.3.6-.6.6h-1c-.3 0-.5-.2-.6-.4-.1-.2-.3-.3-.6-.2-.2.1-.3.3-.2.6.2.6.8 1 1.4 1h.1v.7c0 .2.2.4.4.4s.4-.2.4-.4v-.7h.1c.8 0 1.5-.7 1.5-1.5s-.6-1.5-1.5-1.5z"/></g></IconBase>
  )
}

IconManagementReporting.propTypes = {
  className: PropTypes.string,
}

export default IconManagementReporting
/* eslint-enable */
