/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment'
import Container from 'components/layout/Grid/Container'
import {
  Heading, Button, DataTableSimple, Select,
} from '@deloitte/gel-library'
import Card from 'components/Card'
import Avatar from 'components/Avatar'
import {
  jobGetMemberGroups,
  deloitteTeamSort,
  isPartnerRole,
  isManagerRole,
  isManagerOrPartnerOnJob,
} from 'utils/business/jobs'
import IconAvatarUnknown from 'icons/IconAvatarUnknown'
import DialogSimple from 'components/DialogSimple'
import InfoIcon from '@material-ui/icons/Info'
import IconAlert from 'icons/IconAlert'
import { sortByFunction } from 'utils/arrays'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'

import { modifyUserJobrole } from 'actions/job'
import {
  modalCreate, modalHide, modalShow,
} from 'actions/modals'
import { MODAL_MODIFY_USER_JOB_ROLE } from 'constants/forms'
import styles from './Team.scss'

const propTypes = {
  canAddExternalUser: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  job: PropTypes.shape({
    _isModifyingUserRole: PropTypes.bool,
    jobId: PropTypes.string,
    members: PropTypes.arrayOf(PropTypes.object),
    myaId: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  modals: PropTypes.object.isRequired,
  onRemoveMember: PropTypes.func.isRequired,
  openExternalUserModal: PropTypes.func.isRequired,
  openJobPopup: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
    jobAssignableRoles: PropTypes.arrayOf(PropTypes.object),
    role: PropTypes.object,
  }).isRequired,
}

const defaultProps = {
  canAddExternalUser: false,
}

const TeamMember = ({ user: { userDetails: details } }) => (
  <Fragment>
    <div>{`${details.firstName} ${details.surname}`}</div>
  </Fragment>
)

TeamMember.propTypes = {
  user: PropTypes.shape({
    userDetails: PropTypes.shape({
      firstName: PropTypes.string,
      surname: PropTypes.string,
    }),
  }).isRequired,
}

const isPending = user => user.userDetails.isExternal && !user.userDetails.hasAcceptedEula

const columnHeaders = [
  {
    heading: 'Name',
    className: styles.name,
    cellExtractor: user => (
      <div className={classNames(styles.avatarContainer, isPending(user) ? styles.pending : null)}>
        {isPending(user)
          ? <IconAvatarUnknown height={36} width={36} />
          : <Avatar nameArray={[user.userDetails.firstName, user.userDetails.surname]} size="medium" />}

        <div>
          <div>{`${user.userDetails.firstName} ${user.userDetails.surname}`}</div>
          {isPending(user) && <div className={styles.subheading}>Request sent</div>}
          {isPartnerRole(user.role.name) && <div className={styles.subheading}>Engagement Partner</div>}
          {isManagerRole(user.role.name) && <div className={styles.subheading}>Engagement Manager</div>}
        </div>
      </div>
    ),
    key: 'name',
  },
  {
    heading: 'Date joined', className: styles.phone, cellExtractor: x => moment(x.userDetails.joinDate).format('DD MMM YYYY'), key: 'joinDate',
  },
  {
    heading: 'Contact no.',
    className: styles.phone,
    cellExtractor: user => <div className={isPending(user) ? styles.pending : null}>{user.userDetails.phone || 'Not available'}</div>,
    key: 'phone',
  },
  {
    heading: 'Email address',
    className: styles.email,
    cellExtractor: user => <div className={isPending(user) ? styles.pending : null}>{user.userDetails.email}</div>,
    key: 'email',
  },
]

function Team({
  canAddExternalUser,
  job,
  openExternalUserModal,
  onRemoveMember,
  openJobPopup,
  user: appUser,
  dispatch,
  modals,
}) {
  const [memberToModify, setMemberToModify] = useState({
    member: null,
    targetRole: null,
  })

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [userToDelete, setUserToDelete] = useState(null)

  useEffect(() => {
    dispatch(modalCreate(MODAL_MODIFY_USER_JOB_ROLE))
  }, [dispatch])

  const presentDeleteDialog = (user) => {
    setUserToDelete(user)
    setShowDeleteDialog(true)
  }

  const cancelDeleteDialog = () => {
    setUserToDelete(null)
    setShowDeleteDialog(false)
  }

  const confirmDeleteDialog = () => {
    onRemoveMember(userToDelete.id, `${userToDelete.userDetails.firstName} ${userToDelete.userDetails.surname}`, () => {
      setUserToDelete(null)
      setShowDeleteDialog(false)
    })
  }

  const presentPermissionsDialogue = (member, targetRoleId) => {
    setMemberToModify({
      member,
      targetRole: member.jobAssignableRoles.find(jr => jr.id === targetRoleId),
    })
    dispatch(modalShow(MODAL_MODIFY_USER_JOB_ROLE))
  }

  const confirmPermissionsDialogue = (jobId, userId, roleId) => {
    dispatch(modifyUserJobrole(jobId, userId, roleId))
  }

  const { deloitteTeam, externalTeam } = jobGetMemberGroups(job.members)

  const deleteColumn = {
    className: styles.deleteColumn,
    heading: '',
    cellExtractor: user => (
      <Button
        disabled={userToDelete && userToDelete.id === user.id}
        icon={<DeleteIcon />}
        mode="tertiary"
        onClick={() => presentDeleteDialog(user)}
      >
        Remove
      </Button>
    ),
    key: 'delete',
  }

  const modifyRoleColumn = {
    className: styles.modifyRoleColumn,
    heading: 'Job permissions',
    cellExtractor: user => (
      <Select
        disabled={job._isModifyingUserRole}
        key={user.id}
        onChange={event => presentPermissionsDialogue(user, event.target.value)}
        options={user.jobAssignableRoles.map(r => ({ value: r.id, label: r.description }))}
        value={user.role.id}
      />
    ),
    key: 'permissions',
  }

  const externalTeamColumns = appUser && (isManagerOrPartnerOnJob(appUser, job.members) || appUser.isAdmin)
    ? [
      ...columnHeaders.filter(x => x.key !== 'phone'),
      modifyRoleColumn,
      deleteColumn,
    ] : [
      ...columnHeaders.filter(x => x.key !== 'phone'),
      deleteColumn,
    ]

  return (
    <Container noMargin>
      <div className={styles.internalTeamHeader}>
        <Heading className={styles.firstHeading} level={8}>Deloitte team members</Heading>
        <Button mode="flat" onClick={() => openJobPopup({ jobId: job.jobId })}>Add / edit team members</Button>
      </div>

      <Card noPadding>
        <DataTableSimple
          columns={columnHeaders.filter(x => x.key !== 'joinDate')}
          data={deloitteTeam}
          keyExtractor={x => x.userDetails.email}
          sortFunction={deloitteTeamSort}
          spacing="large"
        />
      </Card>

      {externalTeam.length > 0 && (
        <Fragment>
          <div className={styles.externalTeamHeader}>
            <Heading level={8}>Client users</Heading>
            {canAddExternalUser && (
              <Button icon={<AddIcon />} mode="flat" onClick={openExternalUserModal}>
                Add new client user
              </Button>
            )}
          </div>

          <Card noPadding>
            <DataTableSimple
              columns={externalTeamColumns}
              data={externalTeam}
              keyExtractor={x => x.userDetails.email}
              sortFunction={sortByFunction(x => x.userDetails.firstName)}
              spacing="large"
            />
          </Card>
        </Fragment>
      )}

      <DialogSimple
        actionLabel="Remove"
        dismissLabel="Cancel"
        icon={<IconAlert height={50} width={50} />}
        isOpen={showDeleteDialog}
        onAction={confirmDeleteDialog}
        onDismiss={cancelDeleteDialog}
      >
        {userToDelete && (
        <Fragment>
          <Heading level={8}>
            {`Remove ‘${userToDelete.userDetails.firstName} ${userToDelete.userDetails.surname}’ from ‘${job.name}’?`}
          </Heading>
          <p>Once removed, they will no longer have access to this job.</p>
        </Fragment>
        )}
      </DialogSimple>

      {memberToModify && memberToModify.targetRole && memberToModify.member && (
        <DialogSimple
          actionLabel="Apply"
          className={styles.warningIcon}
          dismissLabel="Cancel"
          icon={<InfoIcon className={styles.icon} fontSize="large" />}
          isLoading={job._isModifyingUserRole}
          isOpen={modals[MODAL_MODIFY_USER_JOB_ROLE] && modals[MODAL_MODIFY_USER_JOB_ROLE].show}
          onAction={() => confirmPermissionsDialogue(job.jobId, memberToModify.member.id, memberToModify.targetRole.id)}
          onDismiss={() => dispatch(modalHide(MODAL_MODIFY_USER_JOB_ROLE))}
        >
          <Heading level={7}>
            {`Are you sure you want to make ${memberToModify.member.userDetails.displayName} a ‘${memberToModify.targetRole.description}’ on ${job.name}?`}
          </Heading>
          <p>
            {'Modifying a user\'s role will change the permissions they have on this job. '}
            {`As a ‘${memberToModify.targetRole.description}’ ${memberToModify.member.userDetails.firstName} will be able to;`}
          </p>
          <ul>
            {memberToModify.targetRole.permissions.map(p => <li key={p.id}>{p.description}</li>)}
          </ul>
        </DialogSimple>
      )}

    </Container>

  )
}

Team.propTypes = propTypes
Team.defaultProps = defaultProps

export default Team
