import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Avatar from 'components/Avatar'
import styles from '../../TagInput.scss'

const SuggestionItem = (props) => {
  const {
    active, handleClick, label, value, subLabel,
  } = props

  const classes = {
    [styles.active]: active,
  }

  const _handleClick = () => {
    handleClick({
      label,
      value,
    })
  }

  return (
    <div className={classnames(styles.option, classes)} onClick={_handleClick} onMouseDown={_handleClick}>
      <Avatar className={styles.avatar} nameArray={label.split(' ')} size="medium" />
      <div className={styles.labelContainer}>
        <div className={styles.optionText}>{label}</div>
        {subLabel && <div className={styles.optionSubLabel}>{subLabel}</div>}
      </div>
    </div>
  )
}

SuggestionItem.propTypes = {
  active: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  subLabel: PropTypes.string,
  value: PropTypes.string.isRequired,
}

SuggestionItem.defaultProps = {
  active: false,
  subLabel: null,
}

export default SuggestionItem
