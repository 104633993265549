/* Generated by ./script/iconify.js */
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import IconBase from './IconBase'

const IconMultiFiles = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--multifiles',
    className,
  )

  return (
    <IconBase className={classes} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <title>Files</title>
      <g fill="none" fillRule="evenodd">
        <path className="path1" d="M0 0h24v24H0z" />
        <path
          className="path2 fill"
          d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-1 9H9V9h10v2zm-4 4H9v-2h6v2zm4-8H9V5h10v2z"
          fill="#BDBDBD"
          fillRule="nonzero"
        />
      </g>
    </IconBase>
  )
}

IconMultiFiles.propTypes = {
  className: PropTypes.string,
}

IconMultiFiles.defaultProps = {
  className: null,
}

export default IconMultiFiles
