import React from 'react'
import PropTypes from 'prop-types'
import { Button, Icons } from '@deloitte/gel-library'
import styles from './InfoSection.scss'

const propTypes = {
  buttonOnClick: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  description: PropTypes.string,
  heading: PropTypes.string,
  icon: PropTypes.string.isRequired,
  iconSize: PropTypes.oneOf(['small', 'medium', 'large']),
}

const InfoSection = ({
  buttonText, description, heading, icon, buttonOnClick, iconSize,
}) => {
  const hasHeading = heading && heading.trim().length > 0
  const hasDescription = description && description.trim().length > 0
  return (
    <div className={styles.centerContent}>
      <div className={styles.marketingBox}>
        <div className={styles.column}>
          <img
            alt={heading}
            className={`${styles.icon} ${styles[iconSize]}`}
            src={icon}
          />
        </div>
        <div className={styles.column}>
          {hasHeading ? <h3 className={styles.marketingHeading}>{heading}</h3> : null}
          {hasDescription ? <p className={styles.description}>{description}</p> : null}
        </div>
        <div className={styles.column}>
          <Button
            className={styles.createButton}
            mode="secondary"
            onClick={buttonOnClick}
          >
            {buttonText}
            <Icons.IconChevronRight className={styles.chevronIcon} height={24} width={24} />
          </Button>
        </div>
      </div>
    </div>
  )
}

InfoSection.propTypes = propTypes
InfoSection.defaultProps = {
  description: '',
  heading: '',
  iconSize: 'large',
}

export default InfoSection
