import React from 'react'
import PropTypes from 'prop-types'
import ModeCommentIcon from '@material-ui/icons/ModeComment'
import Comments from 'actionHub/components/Comments'
import TabPanel from 'components/TabPanel'
import { Tabs } from '@deloitte/gel-library'
import { Divider } from '@material-ui/core'
import styles from './CommentsSection.scss'

const propTypes = {
  comments: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  members: PropTypes.arrayOf(PropTypes.object).isRequired,
  objectName: PropTypes.string,
  onDelete: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  // showNestedComments: PropTypes.bool,
  user: PropTypes.object.isRequired,
}

const defaultProps = {
  comments: [],
  disabled: false,
  onDelete: null,
  // showNestedComments: false,
  objectName: 'task',
}

function CommentsSection({
  comments, disabled, members, onSubmit, user,
  // showNestedComments,
  onDelete, objectName,
}) {
  const [tabValue, setTabValue] = React.useState(0)

  const handleChange = (newValue) => {
    setTabValue(newValue)
  }

  return (
    <div className={styles.base}>
      <>
        <Tabs
          className={styles.tabs}
          onTabChange={value => handleChange(value)}
          tabIcons={[<ModeCommentIcon className={styles.commentIcon} />]}
          // out of scope for this sprint
          // tabLabels={['Comments', 'Nested Comments']}
          tabLabels={['Comments']}
          value={tabValue}
        />
      </>
      <Divider />
      <>
        <TabPanel index={0} value={tabValue}>
          <Comments
            comments={comments}
            disabled={disabled}
            members={members}
            objectName={objectName}
            onDelete={onDelete}
            onSubmit={onSubmit}
            user={user}
          />
        </TabPanel>
      </>
    </div>
  )
}

CommentsSection.propTypes = propTypes
CommentsSection.defaultProps = defaultProps

export default CommentsSection
