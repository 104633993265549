import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import actionHub from 'actionHub/redux/state'
import genAi from 'features/genAi/redux/reducers/genAiApp'
import genAiApps from 'features/genAi/redux/reducers/genAiApps'
import activity from './activity'
import app from './app'
import client from './client'
import clonedJobDetails from './clonedJobDetails'
import hostedReports from './hostedReports'
import dialogs from './dialogs'
import engagementCodes from './engagementCodes'
import forms from './forms'
import job from './job'
import jobs from './jobs'
import modals from './modals'
import packages from './packages'
import support from './support'
import uploaders from './uploaders'
import validations from './validations'
import admin from './admin'

export const createRootReducer = history => combineReducers({
  actionHub,
  activity,
  admin,
  app,
  client,
  clonedJobDetails,
  dialogs,
  engagementCodes,
  forms,
  genAi,
  genAiApps,
  hostedReports,
  job,
  jobs,
  modals,
  packages,
  router: connectRouter(history),
  support,
  uploaders,
  validations,
})
