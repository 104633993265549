import {
  MODAL_CREATE,
  MODAL_HIDE,
  MODAL_SHOW,
} from 'actions/modals'

const initialState = {
  _isShowing: false,
  modalNames: [],
}

export default function modals(state = initialState, action) {
  switch (action.type) {
    case MODAL_CREATE:
      return {
        ...state,
        [action.id]: {
          show: false,
        },
        modalNames: [
          ...state.modalNames,
          action.id,
        ],
      }

    case MODAL_HIDE: {
      return {
        ...state,
        _isShowing: state.modalNames.filter(n => n !== action.id).map(n => state[n]).some(m => m.show),
        [action.id]: {
          show: false,
        },
      }
    }

    case MODAL_SHOW:
      return {
        ...state,
        _isShowing: true,
        [action.id]: {
          show: true,
        },
      }
    default:
      return state
  }
}
