/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconSGC = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--sgc',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 123.4 110"{...rest}><path className="path1" fill="#58595B" d="M89.9 70.9c-4.4 0-8.3 1.9-11 4.9L49.3 60.1c.3-1.1.5-2.3.5-3.6 0-1.2-.2-2.4-.5-3.6L79 37.2c2.7 3 6.6 4.9 11 4.9 8.2 0 14.8-6.7 14.8-14.8 0-8.2-6.6-14.8-14.8-14.8-8.2 0-14.8 6.6-14.8 14.8 0 1.2.2 2.4.5 3.6L45.9 46.6c-2.7-3-6.6-4.9-11-4.9-8.2 0-14.8 6.6-14.8 14.8 0 8.2 6.6 14.8 14.8 14.8 4.4 0 8.3-1.9 11-4.9l29.7 15.7c-.3 1.1-.5 2.3-.5 3.6 0 8.2 6.6 14.8 14.8 14.8 8.2 0 14.8-6.6 14.8-14.8 0-8.2-6.6-14.8-14.8-14.8zm0-51.3c4.2 0 7.7 3.4 7.7 7.7 0 4.2-3.4 7.7-7.7 7.7-4.2 0-7.7-3.4-7.7-7.7 0-4.3 3.5-7.7 7.7-7.7zm-55 44.5c-4.2 0-7.7-3.4-7.7-7.7 0-4.2 3.4-7.7 7.7-7.7 4.2 0 7.7 3.4 7.7 7.7 0 4.3-3.5 7.7-7.7 7.7zm55 29.2c-4.2 0-7.7-3.4-7.7-7.7 0-4.2 3.4-7.7 7.7-7.7 4.2 0 7.7 3.4 7.7 7.7 0 4.3-3.5 7.7-7.7 7.7z"/></IconBase>
  )
}

IconSGC.propTypes = {
  className: PropTypes.string,
}

export default IconSGC
/* eslint-enable */
