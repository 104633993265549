import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Heading } from '@deloitte/gel-library'
import { adminFetchActiveJobs } from 'actions/admin'
import useAdmin from 'hooks/useAdmin'
import ReportTable from 'views/Home/components/ReportTable'
import { adminResetJob, adminRetryJob } from 'actions/jobs'
import { getConfig } from 'utils/config'

function StatusView() {
  const dispatch = useDispatch()
  const { activeJobs } = useAdmin()
  const { API_BASE } = getConfig()

  useEffect(() => {
    dispatch(adminFetchActiveJobs())
  }, [dispatch])

  const menuItems = [
    { key: 'viewJobDetails', name: 'View raw job details' },
    { key: 'viewErrorSummary', name: 'View raw validation summary info' },
    { key: 'viewValidationLog', name: 'View the last data validation log' },
  ]

  const dataValidationMenuItems = [
    ...menuItems,
    { key: 'resetJob', name: 'Reset job to data input step' },
  ]

  const executionMenuItems = [
    ...menuItems,
    { key: 'viewExecutionLog', name: 'View the last execution log' },
  ]

  const handleMenuClick = ({ jobId, lastExecutionId }, menu) => {
    switch (menu) {
      case 'resetJob':
        dispatch(adminResetJob({ jobId }))
        break
      case 'retryJob':
        dispatch(adminRetryJob({ jobId }))
        break
      case 'viewExecutionLog': {
        window.open(`${API_BASE}/admin/job/${jobId}/execution/${lastExecutionId}/log`)
        break
      }
      case 'viewValidationLog':
        window.open(`${API_BASE}/admin/job/${jobId}/validation/log`)
        break
      case 'viewJobDetails':
        window.open(`${API_BASE}/admin/job/${jobId}`)
        break
      case 'viewErrorSummary':
        window.open(`${API_BASE}/admin/job/${jobId}/validation`)
        break
      default:
        console.error(`Unexpected menu option: ${menu}`)
    }
  }

  const dataValidatingJobs = activeJobs.filter(j => j.jobStatusDesc === 'DataValidationRequested')
  const executingJobs = activeJobs.filter(j => j.jobStatusDesc !== 'DataValidationRequested')

  return (
    <div>
      <Heading level={7}>
        Jobs in data validation (
        {dataValidatingJobs.length}
        )
      </Heading>
      <ReportTable
        isAdminView
        data={dataValidatingJobs}
        handleMenuClick={handleMenuClick}
        menuItems={dataValidationMenuItems}
        showHeader={false}
      />
      <Heading level={7}>
        Jobs being executed (
        {executingJobs.length}
        )
      </Heading>
      <ReportTable
        isAdminView
        data={executingJobs}
        handleMenuClick={handleMenuClick}
        menuItems={executionMenuItems}
        showHeader={false}
      />
    </div>
  )
}

StatusView.propTypes = {

}

export default StatusView

