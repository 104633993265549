import React from 'react'
import PropTypes from 'prop-types'
import Heading from 'components/Heading'
import Card from 'components/Card'
import { jobStatuses } from 'utils/business/workflow'
import ProgressBar from 'components/ProgressBar'
import WarningText from 'components/WarningText'
import moment from 'moment'
import { jobHasCriticalErrors } from 'utils/business/jobs'
import { useDispatch } from 'react-redux'
import { resetCloningState } from 'actions/job'
import styles from './JobCard.scss'

const propTypes = {
  handleCardClick: PropTypes.func,
  job: PropTypes.shape({
    analyses: PropTypes.arrayOf(PropTypes.object),
    dataValidation: PropTypes.string,
    jobStatusDesc: PropTypes.string,
    lastModifiedDate: PropTypes.string,
    name: PropTypes.string,
    packageName: PropTypes.string,
  }).isRequired,
}

const defaultProps = {
  handleCardClick: () => {},
}

const JobCard = (props) => {
  const dispatch = useDispatch()

  const { job, handleCardClick } = props
  const handleClick = () => {
    handleCardClick(job)
    dispatch(resetCloningState())
  }

  const lastModified = moment(job.lastModifiedDate)
  const noOfReportsReady = job.analyses.filter(a => a.hasData).length

  return (
    <Card
      className={styles.card}
      onClick={handleClick}
    >
      <Heading noPadding className={styles.heading} size="h7">{job.name}</Heading>

      {job.packageName}

      <div>
        <ProgressBar
          isCompact
          completeChildren={jobHasCriticalErrors(job) ? <WarningText text="" /> : null}
          completeText={`${noOfReportsReady} report${noOfReportsReady > 1 ? 's' : ''} ready`}
          statusCount={5}
          statusKey={job.jobStatusDesc}
          statuses={jobStatuses}
        />
      </div>

      <div className={styles.cardFooter}>
        <p>
          {`Last updated ${lastModified.format('DD MMM YYYY')}`}
        </p>
      </div>
    </Card>
  )
}

JobCard.propTypes = propTypes
JobCard.defaultProps = defaultProps

export default JobCard
