import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { push } from 'connected-react-router'
import { Heading, Button } from '@deloitte/gel-library'
import { IconAddJob } from 'icons'
import IconOpenInNew from '@material-ui/icons/OpenInNew'
import { resume } from 'actions/jobs'
import { getCookie } from 'utils/cookies'
import { isAssetDeveloper } from 'utils/permissions'
import { hasDismissedOnboardingCookieName } from 'constants/cookies'
import Carousel from 'components/Carousel/Carousel'
import { Link } from 'react-router-dom'
import { getConfig } from 'utils/config'
import { resetCloningState } from 'actions/job'
import ideaBoxImg from 'images/idea-box.svg'
import AIBot from 'features/aiAssistant/views/AiBot'
import InfoSection from 'components/InfoSection/InfoSection'
import styles from './AzureDashboard.scss'
import JobCard from './components/JobCard'
import NoReport from './NoReport'
import SolutionCard from './components/SolutionCard'

const VISIBLE_CARDS_THRESHOLD = 3

const propTypes = {
  app: PropTypes.shape({
    user: PropTypes.shape({
      isAuOffice: PropTypes.bool,
      permissions: PropTypes.object,
    }),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  jobs: PropTypes.object.isRequired,
  packages: PropTypes.shape({
    ids: PropTypes.arrayOf(PropTypes.string),
    packages: PropTypes.object,
  }).isRequired,
}

const AzureDashboard = (props) => {
  const {
    dispatch,
    jobs: { jobs },
    packages: { ids, packages: solutions },
    app: { user, user: { isAuOffice } },
  } = props

  const handleJobCardClick = job => (
    dispatch(resume(job))
  )

  const handleViewJobsClick = () => (
    dispatch(push('/jobs'))
  )

  const handleFaqClick = () => {
    dispatch(push('/support'))
  }

  const handlePackageClick = (packageName) => {
    dispatch(push(`/solution/${packageName}`))
  }

  const handleViewSolutionsClick = () => {
    dispatch(push('/solutions'))
  }

  const orderedJobs = jobs
    .sort((a, b) => a.lastModifiedDate - b.lastModifiedDate)
    .slice(0, VISIBLE_CARDS_THRESHOLD)

  const hasDismissedOnboarding = getCookie(hasDismissedOnboardingCookieName) || false
  const { URL: { MYA, DATASERVICES } } = getConfig()

  const onCreateJobClick = () => {
    dispatch(resetCloningState())
    dispatch(push('/createjob'))
  }

  const openInNewTab = () => {
    window.open(DATASERVICES, '_blank')
  }

  return (
    <div className={styles.base}>

      {isAssetDeveloper(user) ? (
        <section>
          <AIBot />
        </section>
      ) : <></>
      }

      {jobs.length === 0 && (
        <NoReport showOnboarding={!hasDismissedOnboarding} />
      )}

      {jobs.length > 0 && (
        <section>
          <div className={styles.centerContent}>
            <div className={styles.jobsHeading}>
              <div>
                <Heading level={8}>Your recent jobs</Heading>
              </div>
              {jobs.length > 0 && (
                <Button mode="text" onClick={handleViewJobsClick}>
                  {`View all ${jobs.length === 1 ? '' : jobs.length} jobs`}
                </Button>
              )}
            </div>

            <div className="row">

              <div className={classnames(styles.jobCard, 'col-md-3', 'col-sm-4', 'col-xs-6')} onClick={() => { onCreateJobClick() }}>
                <div className={styles.createCard}>
                  <div>
                    <IconAddJob size={28} />
                    <p>Create new job</p>
                  </div>
                </div>
              </div>

              {orderedJobs.map((job) => {
                return (
                  <div className={classnames(styles.jobCard, 'col-md-3', 'col-sm-4', 'col-xs-6')} key={job.jobId}>
                    <JobCard handleCardClick={handleJobCardClick} job={job} />
                  </div>
                )
              })}
            </div>
          </div>
        </section>
      )}

      {isAuOffice
        && (
          <div className={styles.infoSection}>
            <InfoSection
              buttonOnClick={openInNewTab}
              buttonText="LEARN MORE"
              description="Learn more about the data collection and preparation service from our support team."
              heading="Need help preparing your data?"
              icon={ideaBoxImg}
            />
          </div>
        )}

      <section>
        <div className={styles.centerContent}>
          <div className={styles.jobsHeading}>
            <div>
              <Heading level={8}>Explore our solutions</Heading>
            </div>
            <Button mode="text" onClick={handleViewSolutionsClick}>
              View all solutions
            </Button>
          </div>

          <div className={classnames('row', styles.packageRow)}>
            <Carousel
              components={ids.map((id) => {
                const solution = solutions[id]
                return (
                  <SolutionCard
                    key={solution.name}
                    onClick={() => handlePackageClick(solution.name)}
                    solution={solution}
                  />
                )
              })}
              numVisible={4}
            />
          </div>
        </div>
      </section>

      <section className={classnames(styles.supportSection, isAuOffice ? styles.light : null)}>
        <Heading level={4}>Have any questions?</Heading>
        <p>Refer to our frequently asked questions for information on using the app, preparing your data, and other general enquiries.</p>
        <Button onClick={handleFaqClick}>View FAQs</Button>
        <p className={styles.small}>
          Couldn’t find the information you were looking for?
          <br />
          <Link to="/contact">Contact us</Link>
        </p>
      </section>

      {isAuOffice && (
        <section className={styles.backToMya}>
          <p>
            Need to go back to myAnalytics?
            <a href={MYA} rel="noopener noreferrer" target="_blank">
              Go to app
              <IconOpenInNew fontSize="small" />
            </a>
          </p>
        </section>
      )}

    </div>
  )
}

AzureDashboard.propTypes = propTypes

export default AzureDashboard
