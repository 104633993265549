/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconInfo = ({ className, fill, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--info',
    className,
  )

  return (
    <IconBase className={classes} viewBox="0 0 31 30" xmlns="http://www.w3.org/2000/svg"{...rest}><title>Group 3</title><g fill="none" fillRule="evenodd"><ellipse fill={fill} cx="15.029" cy="15" rx="15.029" ry="15"/><g fill="#FFF"><path className="path1" d="M13.667 20.667h2.666v-7.334h-2.666zM16.777 9.389a1.89 1.89 0 1 0-3.778 0 1.89 1.89 0 0 0 3.778 0"/></g></g></IconBase>
  )
}

IconInfo.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
}

IconInfo.defaultProps = {
  fill: "#BDBDBD"
}

export default IconInfo
/* eslint-enable */
