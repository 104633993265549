/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconArrowDown = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--arrowdown',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 8"{...rest}><g fill="none" fillRule="evenodd"><path className="path1 fillpath" fill="#BDBDBD" fillRule="nonzero" d="M10.59.59L6 5.17 1.41.59 0 2l6 6 6-6z"/><path className="path2" d="M-6-8h24v24H-6z"/></g></IconBase>
  )
}

IconArrowDown.propTypes = {
  className: PropTypes.string,
}

export default IconArrowDown
/* eslint-enable */
