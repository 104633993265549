const normaliseString = (string) => {
  return string
    .toLowerCase()
    .replace(/_/g, '')
    .replace(/ /g, '')
}

/**
 * Matches column names from CSVs against possible column names from InsightBox
 *
 * @param {string[]} variableOptions from package or data recipe
 * @param {string} column from file uploaded
 * @returns {bool}
 */
export const matchColumnName = (variableOptions, column) => {
  return variableOptions.map(normaliseString).includes(normaliseString(column))
}
