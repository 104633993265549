import React from 'react'
import PropTypes from 'prop-types'
import Button from 'react-toolbox/lib/button'
import Tooltip from 'react-toolbox/lib/tooltip'
import styles from './InfoTooltip.scss'
import tooltipIcon from './tooltip.svg'

const TooltipButton = Tooltip(Button)

const InfoTooltip = ({ tip }) => {
  return (
    <TooltipButton
      flat
      mini
      neutral={false}
      ripple={false}
      theme={styles}
      tooltip={tip}
      tooltipPosition="bottom"
    >
      <img
        alt={tip}
        height="17"
        src={tooltipIcon}
        width="16"
      />
    </TooltipButton>
  )
}

InfoTooltip.propTypes = {
  tip: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
}

InfoTooltip.defaultProps = {
  tip: '',
}

export default InfoTooltip
