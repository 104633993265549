import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'
import { createRootReducer } from 'reducers'

const configureStore = (history) => {
  const middlewares = [routerMiddleware(history), thunk]

  const enhancers = [applyMiddleware(...middlewares)]

  const finalCreateStore = compose(...enhancers)(createStore)
  const store = finalCreateStore(createRootReducer(history))

  return store
}

export default configureStore
