import React,
{
  Fragment,
  useCallback,
  useEffect,
  useState,
} from 'react'
import Container from 'components/layout/Grid/Container'
import ProductBanner from 'components/ProductBanner'
import genAiBannerImage from 'images/genAi-banner.png'
import { Sticky, StickyContainer } from 'react-sticky'
import { Link } from 'react-scroll'
import { getCookie, setCookie } from 'utils/cookies'
import { genAiGettingStarted } from 'constants/cookies'
import { useDispatch, useSelector } from 'react-redux'
import constants from 'constants'
import { modalCreate, modalShow } from 'actions/modals'
import iconMultipleStars from 'images/iconMultipleStars.svg'
import InfoSection from 'components/InfoSection/InfoSection'
// import Loading from 'components/Loading'
import GenAiInfoPanel from '../components/GenAiInfoPanel'
import GenAiView from '../views/GenAiView/GenAiView'
import SelectTemplateModal from './SelectTemplateModal'
import { getApps } from '../redux/actions/actionCreators'
import { YOURGENAIAPPS } from '../data'
import useGenAiHooks from '../hooks/useGenAiHooks'
import styles from './GenAiLanding.scss'

const GenAiLanding = () => {
  const dispatch = useDispatch()
  const { openCustomApp } = useGenAiHooks()

  const modals = useSelector(state => state.modals)
  const aiApps = useSelector(state => state.genAiApps.aiApps)
  // const isFetching = useSelector(state => state.genAiApps.isFetching)
  const MODAL_ID = constants.MODAL_GEN_AI_TEMPLATE_SELECT
  const [appCollections, setAppCollections] = useState([])

  useEffect(() => {
    dispatch(getApps())
    if (modals && !modals.modalNames.includes(MODAL_ID)) {
      dispatch(modalCreate(MODAL_ID))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (Object.keys(aiApps || {}).length > 0) {
      const appCards = Object.entries(aiApps).map(([key, ids]) => ({
        collectionName: key,
        templates: Object.entries(ids).map(([idKey, app]) => ({
          ...app.fullAppInfo.appConfig,
          key: idKey,
          name: app.fullAppInfo.appConfig.name || 'Undefined',
          createdByUser: {
            email: app.fullAppInfo.createdBy,
            isExternal: false,
            firstName: app.fullAppInfo.userInfo ? app.fullAppInfo.userInfo.name.split(' ')[0] : '',
            surname: app.fullAppInfo.userInfo ? app.fullAppInfo.userInfo.name.split(' ')[1] : '',
          },
        })),
      }))
      const yourAppsIndex = appCards.findIndex(app => app.collectionName === YOURGENAIAPPS)
      if (yourAppsIndex > 0) {
        const [item] = appCards.splice(yourAppsIndex, 1)
        appCards.unshift(item)
      }
      setAppCollections(appCards)
    }
  }, [aiApps])

  const handleExpand = (isExpanded) => {
    if (!isExpanded) {
      setCookie(genAiGettingStarted, true, 365)
    }
  }
  const handleCreateApp = useCallback(() => {
    dispatch(modalShow(MODAL_ID))
  }, [MODAL_ID, dispatch])

  const dataTipsDismissed = getCookie(genAiGettingStarted)

  return (
    // isFetching
    //   ? <Loading pageLoading />
    //   : (
    <>
      <Container noMargin className={styles.base}>
        <ProductBanner
          backgroundImageUrl={genAiBannerImage}
          blurb={(
            <Fragment>
              Get started with ready-to-use generative AI apps to draft content,
              <br />
              summarise information and answer questions, or build your custom
              <br />
              GPT apps within a secure Deloitte environment.
            </Fragment>
          )}
          className={styles.genAiBanner}
          name={(
            <span>
              GPT Assistant
            </span>
          )}
          subtitle="Builder and marketplace of AI-powered assistances"
        />

        <div className={styles.contentWrapper}>
          <GenAiInfoPanel dataTipsDismissed={!!dataTipsDismissed} handleExpand={handleExpand} />
          <div className={styles.stickyContentContainer}>
            <StickyContainer className={styles.leftContainer}>
              <Sticky className={styles.menuList}>
                {({ style }) => (
                  <ul className={styles.collectionMenu} style={{ ...style, top: 60 }}>
                    {appCollections && appCollections.map(a => (
                      <li key={`menu-${a.collectionName}`}>
                        <Link
                          smooth
                          spy
                          activeClass={styles.active}
                          duration={400}
                          offset={-72}
                          to={a.collectionName.toLowerCase()}
                        >
                          {a.collectionName.charAt(0).toUpperCase() + a.collectionName.slice(1)}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </Sticky>
            </StickyContainer>
            <div className={styles.appContent}>
              <div className={styles.centerContent}>
                <InfoSection
                  buttonOnClick={handleCreateApp}
                  buttonText="Get started"
                  description="Use templates and existing AI models to rapidly create and publish your own GPT assistant without coding."
                  heading="Create a custom GPT assistant."
                  icon={iconMultipleStars}
                  iconSize="small"
                />
                {appCollections ? <GenAiView appCollections={appCollections} onClick={openCustomApp} /> : null}
              </div>
            </div>
          </div>
        </div>
      </Container>
      {modals.modalNames.includes(MODAL_ID)
        && (
          <SelectTemplateModal modalId={MODAL_ID} />
        )}
    </>
    // )
  )
}

export default GenAiLanding
