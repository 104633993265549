import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { DataTableSimple } from '@deloitte/gel-library'
import useInfiniteScroll from 'hooks/useInfiniteScroll'
import DataItem from 'actionHub/components/DataItem'
import IconChevronRight from '@material-ui/icons/ChevronRight'
import MessageIcon from '@material-ui/icons/Message'
import styles from './DataList.scss'

function DataList({
  children,
  data, columns, onClick, onLoadMore, meta, expanded, setExpanded,
  dataCount,
}) {
  const spacerColumn = {
    cellExtractor: () => (''),
    heading: '',
    key: '_spacer',
  }

  const tableColumns = children === null && [
    spacerColumn,
    ...columns.map(x => ({
      cellExtractor: row => (
        <DataItem
          column={x}
          row={row}
          value={row[x.name]}
        />
      ),
      heading: x.displayName,
      key: x.name,
    })),
    {
      cellExtractor: row => (
        <div>
          <MessageIcon color="disabled" />
          {row._commentCount}
        </div>
      ),
      heading: '',
      key: '_commentCount',
      className: styles.commentColumn,
    },
  ]

  const tableRef = useRef(null)
  const wrapperRef = useRef(null)

  useInfiniteScroll(wrapperRef, tableRef, onLoadMore)

  const isDataLoaded = data.length !== 0

  return (
    <div className={classNames([styles.base, expanded ? styles.expanded : null])}>
      <section className={styles.title}>
        <div className={styles.headerBar}>
          <span>
            {`${dataCount} ${dataCount === 1 ? meta.dataDisplayNameSingular : meta.dataDisplayNamePlural}`}
          </span>
          <div style={{ flex: 1 }} />
          <div>
            <a
              className={classNames(styles.button, styles.expandButton)}
              onClick={() => setExpanded(!expanded)}
            >
              <IconChevronRight />
            </a>
          </div>
          {/* <div>
            <a
              className={styles.button}
              onClick={() => setExpanded(!expanded)}
            >
              <IconZoomOutMap />
            </a>
          </div> */}
        </div>

      </section>
      {isDataLoaded && (
        <div className={styles.tableWrapper} ref={wrapperRef}>
          <div ref={tableRef}>
            {children || (
            <DataTableSimple
              clickable
              chevronClassName={styles.chevron}
              className={styles.table}
              columns={tableColumns}
              data={data}
              keyExtractor={x => x._id}
              onRowClick={x => onClick(x)}
            />
            )}
          </div>
        </div>
      )}
    </div>
  )
}

DataList.propTypes = {
  children: PropTypes.object,
  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  dataCount: PropTypes.number.isRequired,
  expanded: PropTypes.bool.isRequired,
  meta: PropTypes.shape({
    dataDisplayNamePlural: PropTypes.string,
    dataDisplayNameSingular: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func,
  onLoadMore: PropTypes.func.isRequired,
  setExpanded: PropTypes.func.isRequired,
}
DataList.defaultProps = {
  children: null,
  columns: null,
  onClick: null,
}
export default DataList

