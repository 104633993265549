/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconFixedAssetRegisterMaintenance = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--fixedassetregistermaintenance',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"{...rest}><title>Fill 1</title><g fill="#58595B"><path className="path1" d="M21.2 0H3.7c-.8 0-1.4.7-1.4 1.5V2h-.6c-.4 0-.7.3-.7.7s.3.7.7.7h.6v1.3h-.6c-.4 0-.7.3-.7.6s.3.7.7.7h.6v1.3h-.6c-.4 0-.7.3-.7.7s.3.7.7.7h.6V10h-.6c-.4 0-.7.3-.7.7s.3.7.7.7h.6v1.3h-.6c-.4 0-.7.3-.7.6s.3.7.7.7h.6v1.3h-.6c-.4 0-.7.3-.7.7 0 .4.3.7.7.7h.6V18h-.6c-.4 0-.7.3-.7.7s.3.7.7.7h.6v1.3h-.6c-.4 0-.7.3-.7.6s.3.7.7.7h.6v.5c0 .8.7 1.5 1.5 1.5h17.5c.8 0 1.5-.7 1.5-1.5v-21C22.7.7 22 0 21.2 0zm.2 22.5c0 .1-.1.1-.1.1H3.7c-.1 0-.1-.1-.1-.1V22h.5c.4 0 .7-.3.7-.7s-.3-.7-.7-.7h-.5v-1.3h.5c.4 0 .7-.3.7-.7s-.3-.6-.7-.6h-.5v-1.3h.5c.4 0 .7-.3.7-.7 0-.4-.3-.7-.7-.7h-.5V14h.5c.4 0 .7-.3.7-.7s-.3-.7-.7-.7h-.5v-1.3h.5c.4 0 .7-.3.7-.7s-.3-.6-.7-.6h-.5V8.7h.5c.4 0 .7-.3.7-.7s-.3-.7-.7-.7h-.5V6h.5c.4 0 .7-.3.7-.7s-.3-.6-.7-.6h-.5V3.3h.5c.4 0 .7-.3.7-.7S4.5 2 4.1 2h-.5v-.5c0-.1.1-.1.1-.1h17.5c.1 0 .1.1.1.1v21z"/><path className="path2" d="M19 3.2h-8.9c-.2 0-.4.2-.4.4v5.5c0 .2.2.4.4.4H19c.2 0 .4-.2.4-.4V3.6c0-.2-.2-.4-.4-.4zm-.4 5.5h-8.1V4h8.1v4.7z"/><path className="path3" d="M12.1 5.9H17c.2 0 .4-.2.4-.4s-.2-.4-.4-.4h-4.8c-.2 0-.4.2-.4.4-.1.2.1.4.3.4zM12.1 7.7H17c.2 0 .4-.2.4-.4s-.2-.4-.4-.4h-4.8c-.2 0-.4.2-.4.4s.1.4.3.4z"/></g></IconBase>
  )
}

IconFixedAssetRegisterMaintenance.propTypes = {
  className: PropTypes.string,
}

export default IconFixedAssetRegisterMaintenance
/* eslint-enable */
