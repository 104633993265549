import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import ButtonIcon from 'components/ButtonIcon'
import { IconClose } from 'icons'
import { Column, Container } from 'components/layout/Grid'
import styles from './OverlayModal.scss'

class OverlayModal extends Component {
  static propTypes = {
    base: PropTypes.number,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    className: PropTypes.string,
    disable: PropTypes.bool,
    forceClose: PropTypes.bool,
    id: PropTypes.string.isRequired,
    init: PropTypes.func.isRequired,
    modals: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    push: PropTypes.number,
    show: PropTypes.bool,
  }

  static defaultProps = {
    base: 10,
    children: null,
    className: null,
    disable: false,
    forceClose: false,
    modals: null,
    push: 4,
    show: false,
  }

  componentDidMount() {
    const { init } = this.props
    init()
  }

  componentDidUpdate() {
    const { forceClose } = this.props
    if (forceClose) {
      this._close()
    }
  }

  _close = () => {
    const { onClose } = this.props
    onClose()
  }

  render() {
    const {
      children, className, disable, modals, show, id, push = 4, base = 10,
    } = this.props
    // eslint-disable-next-line no-mixed-operators
    const showModal = modals && modals[id] && modals[id].show || show
    // eslint-disable-next-line no-mixed-operators
    const showing = modals && modals._isShowing || show
    const displayModal = (showModal) ? styles.isOpen : ''

    // Document class adjust
    if (showModal) {
      document.body.classList.add(styles.modalIsOpen)
    } else if (!showing) {
      document.body.classList.remove(styles.modalIsOpen)
    }

    return (
      <div>
        { showModal
        && (
        <div className={classnames(styles.modal, displayModal, className)}>
          <Container>
            <Column
              base={base}
              className={styles.content}
              push={push}
            >
              {children}
            </Column>
          </Container>
          { !disable
          && (
          <div>
            <ButtonIcon
              className={styles.close}
              label="Close window"
              onClick={this._close}
            >
              <IconClose
                height="26"
                width="26"
              />
            </ButtonIcon>
          </div>
          )
          }
        </div>
        )
        }
      </div>
    )
  }
}

export default OverlayModal
