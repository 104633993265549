/* eslint-disable react/no-array-index-key */

import React, { createRef } from 'react'
import moment from 'moment'
import FileSaver from 'file-saver'
import Papa from 'papaparse'
import languageEncoding from 'detect-file-encoding-and-language'
import PropTypes from 'prop-types'
import uniq from 'lodash/uniq'
import findIndex from 'lodash/findIndex'
import Button from 'components/Button'
import classnames from 'classnames'
import Dialog from 'components/Dialog'
import Preloader from 'components/Preloader'
import Card from 'components/Card'
import InfoTooltip from 'components/InfoTooltip'
import SelectInput from 'components/Form/components/SelectInput'
import { IconButton } from 'react-toolbox/lib/button'
import Tooltip from 'react-toolbox/lib/tooltip'
import {
  IconDeleteBin,
  IconDeleteBinRed,
  IconDocumentGrey,
  IconDocument,
  IconDocumentRed,
  IconGreenTickCircle,
  IconArrowDown,
  IconArrowUp,
} from 'icons'
import {
  Table, TableHead, TableRow, TableCell,
} from 'react-toolbox/lib/table'
import { matchColumnName } from 'utils/business/columns'
import { setTimeout } from 'timers'
import { SUPPORT_PAGES } from 'constants/supportPages'
import { getStaticFilePath, staticFiles } from 'utils/business/static'
import { keepAlive } from 'utils/api/app'
import defaultMenuRenderer from './defaultMenuRenderer'
import Option from './Option'

const formats = require('./dateFormats.json')
const styles = require('./FileCard.scss')

const TooltipIconButton = Tooltip(IconButton)
const Pipe = () => {
  return <div className={styles.pipe} />
}

const notMapped = '(not mapped)'
const undetectableDelimiterError = 'UndetectableDelimiter'

const ENCODING_MAP = {
  'Shift-JIS': 'shift_jis',
}

class FileCard extends React.Component {
  columnMeasureRef = createRef()

  static propTypes = {
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        columnName: PropTypes.string,
        dataType: PropTypes.string,
      }),
    ),
    file: PropTypes.object.isRequired,
    handleErrors: PropTypes.func,
    removeError: PropTypes.func,
    removeFile: PropTypes.func,
    updateFile: PropTypes.func,
    uploader: PropTypes.object,
  }

  static defaultProps = {
    columns: null,
    handleErrors: () => {},
    removeFile: () => {},
    removeError: () => {},
    updateFile: () => {},
    uploader: null,
  }

  state = {
    active: true,
    showDialog: false,
    cancelled: false,
    maxColumnWidth: 120,
  }

  componentDidMount() {
    const {
      file: { status },
    } = this.props
    if (status === 'submitted') {
      this.parse()
    }

    keepAlive()

    // Set column widths if we've got columns
    if (this.columnMeasureRef.current) {
      const maxColumnWidth = Array.from(this.columnMeasureRef.current.childNodes)
        .map(x => x.getBoundingClientRect().width)
        .reduce((a, b) => Math.max(a, b))

      this.setState({ maxColumnWidth })
    }
  }

  closeDialog = () => {
    this.setState({
      showDialog: false,
    })
  }

  openDialog = () => {
    this.setState({
      showDialog: true,
    })
  }

  saveErrors = ({ e, name, errors }) => {
    e.preventDefault()
    const blob = new Blob(errors.map(error => JSON.stringify(error)), {
      type: 'text/plain;charset=utf-8',
    })
    FileSaver.saveAs(blob, `${name}_errors.txt`)
  }

  cancel = (e) => {
    if (e) {
      e.preventDefault()
    }
    this.setState({ cancelled: true })
  }

  validateColumns = (headers) => {
    const errors = []

    // Empty column names
    const emptyHeaders = headers
      .map((header, index) => ({
        empty: header.trim() === '',
        index: index + 1,
      }))
      .filter(x => x.empty)

    if (emptyHeaders.length > 0) {
      const identifyingString = emptyHeaders.length === 1
        ? `has a blank name in column ${emptyHeaders[0].index}.`
        : `has blank names in columns ${emptyHeaders
          .map(x => x.index)
          .join(', ')}.`
      errors.push(
        `Column names may not be blank. This file ${identifyingString}`,
      )
    }

    // Duplicate columns
    const uniqHeaders = uniq(headers)
    if (uniqHeaders.length < headers.length) {
      const duplicateHeaders = []
      headers.forEach((column, index) => {
        if (
          duplicateHeaders.indexOf(column) === -1
          && headers.indexOf(column, index + 1) > -1
        ) {
          duplicateHeaders.push(column)
        }
      })
      errors.push(
        `Duplicate column names have been found (${duplicateHeaders.join(
          ', ',
        )}). Ensure each column is uniquely named.`,
      )
    }

    return errors.join(' ')
  }

  validateMandatoryColumns = (autoMatch) => {
    const { file, columns } = this.props

    const mandatoryColumns = columns
      .filter(column => column.isMandatory)
    const headers = autoMatch
      ? file.headers
      : file.tableHeaders.map(header => header.value)

    const missedHeaders = []
    mandatoryColumns.forEach((mandatoryColumn) => {
      if (
        findIndex(headers, header => matchColumnName([mandatoryColumn.columnName, mandatoryColumn.variableCode, ...mandatoryColumn.aliases], header))
        < 0
      ) {
        missedHeaders.push(mandatoryColumn.columnName)
      }
    })

    if (missedHeaders.length > 0) {
      const missing = missedHeaders.map(header => (
        <li key={header}>{header}</li>
      ))
      const error = {
        invalid: true,
        message: (
          <div>
            <p>
              The following columns could not be found in your data. Map the
              column names in your files to one of the mandatory columns
            </p>
            <ul>{missing}</ul>
          </div>
        ),
        columnIndex: -1,
        rowIndex: -1,
      }

      return error
    }
    return { invalid: false }
  }

  detectDateFormat = (value) => {
    let dateFormat = 'UNKNOWN'

    formats.some((format) => {
      if (moment(value.trim(), format, true).isValid()) {
        dateFormat = format
        return true
      }
      return false
    })

    return dateFormat
  }

  validateDateColumn = (autoMatch) => {
    const { columns, file } = this.props

    const result = {
      invalid: false,
      rowIndexes: [],
      columnIndexes: [],
      message: '',
      dateFormats: {},
    }

    const invalidDateColumns = []

    columns
      .filter(
        column => column.dataType === 'DATE' || column.dataType === 'DATETIME',
      )
      .forEach((dateColumn) => {
        let columnIndex
        if (autoMatch) {
          columnIndex = findIndex(
            file.headers,
            header => dateColumn.columnName.toLowerCase() === header.toLowerCase(),
          )
        } else {
          columnIndex = findIndex(
            file.tableHeaders.map(header => header.value),
            header => dateColumn.columnName.toLowerCase() === header.toLowerCase(),
          )
        }

        if (columnIndex > -1) {
          // Auto-matched column
          let dateFormat

          file.fullRows.forEach((row, index) => {
            const dateValue = row[columnIndex]
            const saveError = () => {
              // Save invalid column name
              invalidDateColumns.push(dateColumn.columnName)
              // Save row index
              result.rowIndexes.push(index)
              // Save column index
              result.columnIndexes.push(columnIndex)
            }
            if (dateValue) {
              dateFormat = this.detectDateFormat(dateValue)
              if (dateFormat === 'UNKNOWN') {
                saveError()
              } else {
                result.dateFormats[dateColumn.columnName] = dateFormat
              }
            }
          })
        }
      })

    if (invalidDateColumns.length > 0) {
      result.invalid = true
      result.message = (
        <p>
          Unsupported and/or mixed date formats have been found in the columns
          highlighted in red. You can find more information within the tooltip
          to help you fix your data.
          {' '}
          <a
            href={getStaticFilePath(staticFiles.dataFormatGuidelines)}
            rel="noopener noreferrer"
            target="_blank"
          >
            Learn more
          </a>
        </p>
      )
    }
    return result
  }

  parse = async (config) => {
    const {
      file: { fileId, name, showHeader },
      uploader,
      handleErrors,
      updateFile,
      removeFile,
      columns,
    } = this.props
    const { cancelled } = this.state

    const results = {
      data: [],
      errors: [],
    }

    updateFile({
      fileId,
      status: 'parsing',
      name,
      showHeader,
    })

    const file = uploader.methods.getFile(fileId)

    const complete = () => {
      const errCnt = results.errors.filter(
        e => e.code !== undetectableDelimiterError,
      ).length
      if (errCnt > 0) {
        const error = (
          <span>
            No delimiters or unsupported ones have been found. Please delete this file and select another file with the following delimiters - commas ( , ) or pipe ( | ) characters.
            {' '}
            <a
              href={`/#${SUPPORT_PAGES.DELIMITERS_AND_TEXT_QUALIFIERS}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              Learn more
            </a>
            .
          </span>
        )

        handleErrors({ name, fileId }, error)
      } else {
        const headers = results.data.shift()

        updateFile({
          fileId,
          headers,
          truncated: results.meta.truncated,
          fullRows: results.data,
          delimiter: results.meta.delimiter,
        })
        const rows = results.data.slice(0, 5)
        const error = this.validateColumns(headers)

        if (error) {
          handleErrors({ name, fileId }, error)
        } else {
          // Create mapped column headers based on matching logic in matchColumnName
          const tableHeaders = []
          headers.forEach((header) => {
            const columnIndex = findIndex(columns, column => matchColumnName([column.columnName, column.variableCode, ...column.aliases], header))
            const rawColumnName = columns[columnIndex]?.columnName
            // Don't map a column that's already mapped
            const existingMap = tableHeaders.find(x => x.value === rawColumnName)

            tableHeaders.push({
              header,
              value: columnIndex > -1 && !existingMap ? rawColumnName : notMapped,
            })
          })

          // Check mandatory columns
          const errorMandatory = this.validateMandatoryColumns(true)

          if (errorMandatory.invalid) {
            // Mandatory check falied
            updateFile({
              status: 'parsed',
              fileId,
              name,
              rows,
              tableHeaders,
              error: [
                {
                  message: errorMandatory.message,
                },
              ],
            })
          } else {
            const dateValidationResult = this.validateDateColumn(true)
            if (dateValidationResult.invalid) {
              // Mandatory check passed but date check failed
              updateFile({
                status: 'parsed',
                fileId,
                name,
                rows,
                tableHeaders,
                error: [dateValidationResult],
              })
            } else {
              const tableHeadersWithDateFormats = tableHeaders
                .map(h => ({
                  ...h,
                  dateFormat: dateValidationResult.dateFormats[h.value] || null,
                }))

              // Both passed
              updateFile({
                status: 'parsed',
                fileId,
                name,
                rows,
                tableHeaders: tableHeadersWithDateFormats,
                error: [],
              })
            }
          }
        }
      }
    }

    const step = (stepResults, parser) => {
      if (cancelled) {
        parser.abort()
        removeFile({ fileId })
      }

      if (results.data.length < 6) {
        results.data.push(stepResults.data)
      }

      if (!results.meta) {
        results.meta = stepResults.meta
      }

      if (
        stepResults.errors
        && stepResults.errors.filter(
          e => e.code !== undetectableDelimiterError,
        ).length > 0
      ) {
        results.errors = stepResults.errors
        parser.abort()
      }
    }

    const detectedLanguage = await languageEncoding(file.slice(0, 1048576))
    const encoding = ENCODING_MAP[detectedLanguage.encoding] || 'utf_8'

    const options = Object.assign(
      {},
      {
        complete,
        skipEmptyLines: true,
        step,
        worker: true,
        preview: 100,
        encoding,
      },
      config,
    )

    if (file) {
      Papa.parse(file, options)
    }
  }

  handleChange = (index, value) => {
    const { file, updateFile } = this.props

    // Get a shadow copy of headers
    const tableHeaders = file.tableHeaders.slice()
    const header = tableHeaders[index]
    // If nothing changed do nothing
    if (header.value === value) {
      return
    }

    updateFile({
      fileId: file.fileId,
      status: 'validating',
    })

    // Set the new value
    header.value = value

    updateFile({
      fileId: file.fileId,
      tableHeaders,
    })

    setTimeout(() => {
      const { columns } = this.props
      // Check mandatory columns
      const errorMandatory = this.validateMandatoryColumns()

      if (errorMandatory.invalid) {
        // Mandatory check falied
        updateFile({
          fileId: file.fileId,
          status: 'parsed',
          error: [
            {
              message: errorMandatory.message,
            },
          ],
        })
      } else {
        const isDateColumn = columns.filter(
          column => (column.dataType === 'DATE' || column.dataType === 'DATETIME')
            && column.columnName.toLowerCase() === value.toLowerCase(),
        )

        if (isDateColumn.length === 1) {
          const dateValidationResult = this.validateDateColumn()

          const tableHeadersWithDateFormats = file.tableHeaders
            .map(h => ({
              ...h,
              dateFormat: dateValidationResult.dateFormats[h.value] || null,
            }))

          if (dateValidationResult.invalid) {
            // Mandatory check passed but date check failed
            updateFile({
              fileId: file.fileId,
              status: 'parsed',
              error: [dateValidationResult],
            })
          } else {
            // Both passed
            updateFile({
              fileId: file.fileId,
              status: 'parsed',
              error: [],
              tableHeaders: tableHeadersWithDateFormats,
            })
          }
        } else {
          updateFile({
            fileId: file.fileId,
            status: 'parsed',
            error: [],
          })
        }
      }
    }, 1)
  }

  renderTableHeaderDropdown = (dateError) => {
    const {
      file: { tableHeaders, status },
      columns,
    } = this.props
    const { maxColumnWidth } = this.state

    const stStyle = {
      container: provided => ({
        ...provided,
        minWidth: '120px',
        width: `${maxColumnWidth + 16 + 12}px`,
      }),
    }

    const mappedOptions = tableHeaders
      .map(x => x.value)
      .filter(x => x !== notMapped)
    const unmappedOptions = [
      {
        value: notMapped,
        label: notMapped,
      },
    ]
    unmappedOptions.push(
      ...columns.filter(x => !mappedOptions.includes(x.columnName)).map(x => ({
        label: x.columnName,
        value: x.columnName,
        isMandatory: x.isMandatory,
      })),
    )

    return (
      <TableHead>
        {tableHeaders.map((header, idx) => {
          const options = unmappedOptions.slice()
          if (header.value !== notMapped) {
            const selectedOption = columns.find(
              x => x.columnName === header.value,
            )
            options.push({
              label: selectedOption.columnName,
              value: selectedOption.columnName,
              isMandatory: selectedOption.isMandatory,
            })
          }

          const hasError = dateError && dateError.columnIndexes.indexOf(idx) > -1
          return (
            <TableCell className={styles.tableHeaderAbove} key={header.header}>
              <SelectInput
                className={classnames(styles.selectInput, {
                  [styles.selectInputError]: hasError,
                  [styles.selectInputWarning]: header.value === notMapped,
                })}
                classNamePrefix="react-select"
                components={{ Option }}
                disabled={status === 'validating'}
                handleChange={this.handleChange}
                menuRenderer={defaultMenuRenderer}
                name={`${idx}`}
                options={options}
                placeholder={header.value}
                searchable={false}
                styles={stStyle}
                value={header.value}
              />
            </TableCell>
          )
        })}
      </TableHead>
    )
  }

  renderTableHeaderLabel = (dateError) => {
    const {
      file: { tableHeaders, fullRows },
    } = this.props

    return (
      <TableHead>
        {tableHeaders.map((header, idx) => {
          const hasError = dateError && dateError.columnIndexes.indexOf(idx) > -1
          let tip
          if (hasError) {
            tip = (
              <p>
                This column has
                {`${dateError.rowIndexes.length} `}
                {dateError.rowIndexes.length > 1 ? 'entries' : 'entry'}
                {' with unsupported format and/or multiple formats.'}
                <br />
                <br />
                {`As an example, "${
                  fullRows[dateError.rowIndexes[0]][dateError.columnIndexes[0]]
                }" is an unsupported format found in row ${dateError
                  .rowIndexes[0] + 1}`}
              </p>
            )
          }
          return (
            <TableCell className={styles.tableHeaderBelow} key={header.header}>
              <span className={classnames({ [styles.cellError]: hasError })}>
                {header.header}
              </span>
              &nbsp;
              {hasError && <InfoTooltip tip={tip} />}
            </TableCell>
          )
        })}
      </TableHead>
    )
  }

  renderFileCard = () => {
    const { file, removeFile, columns } = this.props
    const { active, showDialog } = this.state

    const dialogPropsDelete = {
      actions: [
        { label: 'CANCEL', onClick: this.closeDialog },
        {
          label: 'DELETE',
          onClick: () => {
            removeFile(file)
            this.closeDialog()
          },
          primary: true,
        },
      ],
      className: styles.dialog,
      active: showDialog,
      onClose: this.closeDialog,
    }

    const isValidating = file.status === 'validating'

    const hasError = file.error && file.error.length > 0

    const hasDateError = hasError
      && file.error.some(error => error.rowIndexes && error.rowIndexes.length > 0)

    const dateError = hasDateError && file.error[file.error.length - 1]

    const tableRows = file.rows.slice(0, 5) || []

    const panelHeader = (
      <div className={styles.fileCardHeader}>
        <div className={styles.headerLeft}>
          {hasError && <IconDocumentRed height={16} width={16} />}
          {!hasError && <IconDocument height={16} width={16} />}
          <span className={classnames({}, { [styles.labelRed]: hasError })}>
            {file.name}
          </span>
        </div>
        <div className={styles.headerRight}>
          {isValidating && (
            <div>
              <span className={styles.labelBlue}>Validating file...</span>
              <Preloader color="#009bd7" size="24" />
            </div>
          )}
          {!isValidating
            && !hasError && (
              <div className={styles.iconAlign}>
                <span className={styles.labelGreen}>Verified</span>
                <IconGreenTickCircle height="20" width="20" />
              </div>
          )}
          {!isValidating && !hasError && <Pipe />}
          {!isValidating && (
            <div className={styles.iconWrapper} onClick={this.openDialog}>
              <TooltipIconButton
                className="icon-button"
                icon={
                  hasError ? (
                    <IconDeleteBinRed width="14" />
                  ) : (
                    <IconDeleteBin width="14" />
                  )
                }
                theme={styles}
                tooltip="Remove"
              />
            </div>
          )}
          {!isValidating && (
            <div className={styles.iconWrapper}>
              <TooltipIconButton
                className="icon-button"
                icon={
                  active ? (
                    <IconArrowUp width="12" />
                  ) : (
                    <IconArrowDown width="12" />
                  )
                }
                onClick={() => this.setState({ active: !active })}
                theme={styles}
                tooltip={active ? 'Collapse' : 'Expand'}
              />
            </div>
          )}
        </div>
        <div className={styles.columnMeasurer} ref={this.columnMeasureRef}>
          {columns.map(x => <span key={x.variableCode}>{x.columnName}</span>)}
        </div>
      </div>
    )

    return (
      <Card noPadding>
        {panelHeader}
        <div className={classnames(styles.base, { [styles.active]: active })}>
          {file.error
            && file.error.length > 0 && (
              <div className={styles.errorMessage}>
                {file.error.map((error, index) => (
                  <div key={index}>{error.message}</div>
                ))}
              </div>
          )}
          <div className={styles.responsiveTable}>
            <Table className={styles.table} selectable={false}>
              <TableHead>
                <TableCell colSpan={file.tableHeaders.length}>
                  <span className={styles.tableLabel}>
                    Map mandatory fields
                  </span>
                </TableCell>
              </TableHead>
              {this.renderTableHeaderDropdown(dateError)}
              {this.renderTableHeaderLabel(dateError)}
              {tableRows.map((item, idx) => (
                <TableRow className={styles.tableRow} key={idx}>
                  {item.map((cell, index) => (
                    <TableCell key={index}>
                      <p
                        className={classnames(styles.cell, {
                          [styles.cellError]:
                          dateError
                          && dateError.columnIndexes.indexOf(index) > -1
                          && dateError.rowIndexes.indexOf(idx) > -1,
                        })}
                      >
                        {cell}
                      </p>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </Table>
          </div>
        </div>
        <Dialog {...dialogPropsDelete}>
          <p>Are you sure you want to delete this file?</p>
        </Dialog>
      </Card>
    )
  }

  renderFileCardParsing = () => {
    const { file } = this.props
    return (
      <Card noPadding>
        <div className={styles.fileCardHeader}>
          <div className={styles.headerLeft}>
            <IconDocumentGrey height={16} width={16} />
            <span>{file.name}</span>
          </div>
          <div className={styles.headerRight}>
            <div>
              <span className={styles.labelBlue}>Checking file...</span>
              <Preloader color="#009bd7" size="24" />
            </div>
            <Pipe />
            <Button
              flat
              label="cancel"
              onClick={this.cancel}
              type="secondaryGrey"
            />
          </div>
        </div>
      </Card>
    )
  }

  renderFileCardError = () => {
    const { file, removeError } = this.props
    return (
      <Card noPadding>
        <div className={styles.fileCardHeader}>
          <div className={styles.headerLeft}>
            <IconDocumentRed height={16} width={16} />
            <span className={styles.labelRed}>{file.name}</span>
          </div>
          <div className={styles.headerRight}>
            <div className={styles.iconWrapper} onClick={removeError}>
              <TooltipIconButton
                className="icon-button"
                icon={<IconDeleteBinRed width="14" />}
                theme={styles}
                tooltip="Remove"
              />
            </div>
          </div>
        </div>
        <div className={styles.errorMessage}>{file.error}</div>
      </Card>
    )
  }

  render() {
    const { file } = this.props

    switch (file.status) {
      case 'parsing':
        return this.renderFileCardParsing()
      case 'parsed':
        return this.renderFileCard()
      case 'error':
        return this.renderFileCardError()
      case 'uploaded':
        return <div />
      default:
        return this.renderFileCard()
    }
  }
}

export default FileCard
