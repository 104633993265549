import * as ActionTypes from 'actions/support'
import { uniq } from 'lodash'
import { sortByFunction } from 'utils/arrays'

const initialState = {
  searchText: '',
  visible: false,
  articles: [],
  sections: [],
  _fetchingArticles: false,
  _fetchedArticles: false,
  _fetchArticleError: false,
  _contactFormSentDialogVisible: false,
  _isContactFormSending: false,
}

const support = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SUPPORT_SHOW_SUPPORT_BOX:
      return {
        ...state,
        visible: true,
      }
    case ActionTypes.SUPPORT_HIDE_SUPPORT_BOX:
      return {
        ...state,
        visible: false,
      }
    case ActionTypes.SUPPORT_UPDATE_SEARCH_TEXT:
      return {
        ...state,
        searchText: action.payload,
      }
    case ActionTypes.SUPPORT_FETCHING_ARTICLES:
      return {
        ...state,
        _fetchingArticles: true,
      }
    case ActionTypes.SUPPORT_FETCHED_ARTICLES:
      return {
        ...state,
        _fetchingArticles: false,
        _fetchedArticles: true,
        _fetchArticleError: false,
        articles: action.articles,
        sections: uniq(action.articles.sort(sortByFunction(x => x.displayOrder)).map(x => x.section)),
      }
    case ActionTypes.SUPPORT_FETCH_ARTICLE_ERROR:
      return {
        ...state,
        _fetchingArticles: false,
        _fetchedArticles: false,
        _fetchArticleError: true,
      }
    case ActionTypes.SUPPORT_CONTACT_FORM_SENDING:
      return {
        ...state,
        _isContactFormSending: true,
      }
    case ActionTypes.SUPPORT_CONTACT_FORM_SENT:
      return {
        ...state,
        _contactFormSentDialogVisible: true,
        _isContactFormSending: false,
      }
    case ActionTypes.SUPPORT_CONTACT_FORM_SEND_FAILED:
      return {
        ...state,
        _isContactFormSending: false,
      }
    case ActionTypes.SUPPORT_CONTACT_FORM_DIALOG_DISMISSED:
      return {
        ...state,
        _contactFormSentDialogVisible: false,
      }
    default:
      return state
  }
}

export default support
