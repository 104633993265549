import React from 'react'
import PropTypes from 'prop-types'
import {
  IconGreentickInWhite,
} from 'icons'
import ReactMarkdown from 'react-markdown'
import LinkRenderer from 'components/LinkRenderer'
import styles from './TickList.scss'

const TickList = (props) => {
  const { listItems } = props

  return (
    <div>
      {listItems.map(item => (
        <div className={styles.listItem} key={item.title}>
          <div>
            <IconGreentickInWhite className={styles.tick} />
          </div>
          <div>
            <strong>{item.title}</strong>
            <ReactMarkdown renderers={{ link: LinkRenderer }} source={item.body} />
          </div>
        </div>
      ))}
    </div>
  )
}

TickList.propTypes = {
  listItems: PropTypes.arrayOf(PropTypes.shape({
    body: PropTypes.node,
    title: PropTypes.string,
  })).isRequired,
}

export default TickList
