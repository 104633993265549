import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Container } from 'components/layout/Grid/'
import Banner from 'components/Banner'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { fetch as fetchAction } from 'actions/activity'
import ActivityView from 'views/Activity/Activity'

class Activity extends Component {
  static propTypes = {
    activity: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    fetch: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const { fetch } = this.props
    fetch()
  }

  render() {
    const { activity, dispatch } = this.props
    const actionButton = {
      handleAction: () => { dispatch(push('/createjob')) },
      tooltip: 'CREATE JOB',
    }
    return (
      <div>
        <Banner
          actionButton={actionButton}
          name="Usage activity"
        />
        <Container topPadding>
          <ActivityView
            {...activity}
          />
        </Container>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  jobs: state.jobs,
  app: state.app,
  activity: state.activity,
})

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    fetch: () => {
      dispatch(fetchAction())
    },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Activity))
