/* Generated by ./script/iconify.js */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import classnames from 'classnames'

const IconOther = ({ className, ...rest }) => {
  const classes = classnames(
    'icon',
    'icon--other',
    className,
  )

  return (
    <IconBase className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 123.4 110"{...rest}><g fill="#58595B"><path className="path1" d="M92 18.7H72.6c-1.1-5-5.6-8.7-10.9-8.7s-9.7 3.7-10.9 8.7H31.3c-3 0-5.5 2.5-5.5 5.5v70.3c0 3 2.5 5.5 5.5 5.5h42.2c1.8 0 3.9-.9 5.2-2.1l16.7-16.7c1.2-1.2 2.1-3.4 2.1-5.2V24.2c0-3.1-2.4-5.5-5.5-5.5zM61.7 15c3.4 0 6.2 2.8 6.2 6.2 0 3.4-2.8 6.2-6.2 6.2s-6.2-2.8-6.2-6.2c0-3.4 2.8-6.2 6.2-6.2zM30.8 94.5V24.2c0-.3.2-.5.5-.5h19.5c1.1 5 5.6 8.7 10.9 8.7s9.7-3.7 10.9-8.7H92c.3 0 .5.2.5.5v51.3h-14c-3 0-5.5 2.5-5.5 5.5v14H31.3c-.2 0-.5-.2-.5-.5zm47.2-3V81c0-.3.2-.5.5-.5H89l-11 11z"/><path className="path2" d="M43.8 44.5h35.9c.8 0 1.5-.7 1.5-1.5s-.7-1.5-1.5-1.5H43.8c-.8 0-1.5.7-1.5 1.5s.6 1.5 1.5 1.5zM43.8 56.8h35.9c.8 0 1.5-.7 1.5-1.5s-.7-1.5-1.5-1.5H43.8c-.8 0-1.5.7-1.5 1.5s.6 1.5 1.5 1.5zM63.7 66.1H43.8c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5h19.9c.8 0 1.5-.7 1.5-1.5s-.7-1.5-1.5-1.5z"/></g></IconBase>
  )
}

IconOther.propTypes = {
  className: PropTypes.string,
}

export default IconOther
/* eslint-enable */
