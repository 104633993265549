import React,
{
  Fragment, useEffect, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Heading, ToggleInput } from '@deloitte/gel-library'
import constants from 'constants'
import Animate from 'components/Animate'
import OverlayModal from 'components/OverlayModal'
import { modalHide } from 'actions/modals'
import SearchInput from 'components/SearchInput'
import Card from 'components/Card'
import SubmitButton from 'components/SubmitButton'
import {
  adminClearUserSearch,
  adminSearchUsers,
  adminSelectUser,
  adminToggleUserPermission,
  adminUpdateUserPermissions,
  adminUpdatedUserPermissions,
} from 'actions/admin'
import TextArea from 'components/TextArea'
import Label from 'components/Label'
import styles from './UserPermissionsModal.scss'
import UserResult from './UserResult'

const MODAL_ID = constants.MODAL_USER_PERMISSIONS

const UserPermissionsModal = () => {
  const dispatch = useDispatch()
  const modals = useSelector(state => state.modals)

  const {
    _isSearchingUsers,
    _isSearchedUsers,
    _isUpdatingPermissions,
    selectedUser,
    selectedUserRoles,
    selectedUserNotes,
    roles,
    roleIds,
    searchResults,
  } = useSelector(state => state.admin.userAdmin)

  const [userSearch, setUserSearch] = useState('')
  const [timeoutId, setTimeoutId] = useState(-1)
  const [localUserNotes, setLocalUserNotes] = useState(selectedUserNotes)

  useEffect(() => {
    if (selectedUserNotes !== null) {
      setLocalUserNotes(selectedUserNotes)
    } else {
      setLocalUserNotes('')
    }
  }, [selectedUserNotes])

  const handleOnClose = () => {
    dispatch(adminUpdatedUserPermissions())
    dispatch(modalHide(MODAL_ID))
  }

  const mapUserToOption = (user) => {
    return {
      value: user.id,
      label: (<UserResult onClose={handleOnClose} user={user} />),
    }
  }

  const handleSearch = (query) => {
    if (query.length >= 3) {
      clearTimeout(timeoutId)
      setTimeoutId(setTimeout(() => dispatch(adminSearchUsers(query)), 400))
    } else {
      dispatch(adminClearUserSearch())
    }
    setUserSearch(query)
  }

  return (
    <OverlayModal
      base={10}
      disable={false}
      id={MODAL_ID}
      init={() => {}}
      modals={modals}
      onClose={handleOnClose}
      push={4}
    >
      <Animate name="fade">
        <div className={styles.base}>

          <Heading className={styles.title} level={5}>Add/edit elevated user</Heading>

          <SearchInput
            hasSearched={_isSearchedUsers}
            isSearching={_isSearchingUsers}
            label="Search users"
            noResultsText="No users found"
            onBlur={() => dispatch(adminClearUserSearch())}
            onChange={handleSearch}
            onSelect={(option) => {
              dispatch(adminSelectUser(searchResults.find(u => u.id === option.value)))
              setUserSearch('')
            }}
            options={searchResults.map(u => mapUserToOption(u))}
            selectedClassname={styles.selected}
            suggestionClassname={styles.suggestion}
            value={userSearch}
          />

          {selectedUser && (
            <Fragment>
              <Card noPadding className={styles.userCard}>
                <div className={styles.userCardHeader}>
                  <UserResult
                    onClose={() => dispatch(adminSelectUser(null))}
                    user={selectedUser}
                  />
                </div>
                <div className={styles.permissions}>
                  <div className={styles.elevatedUserNotesContainer} id="elevatedUserNotes">
                    <Label>
                      Permissions justification
                    </Label>
                    <TextArea
                      multiline
                      className={styles.elevatedUserNotes}
                      maxLength={1000}
                      onChange={value => setLocalUserNotes(value)}
                      placeholder="Enter justification for permissions"
                      value={localUserNotes}
                    />
                  </div>
                  {roleIds && roleIds.map(id => (
                    <div className={styles.permissionContainer} key={id}>

                      <div className={styles.permissionDescription}>
                        <div>{roles[id].description}</div>
                      </div>

                      <div>
                        <ToggleInput
                          checked={selectedUserRoles[id].modifiedValue}
                          labelFalse=""
                          labelTrue=""
                          onChange={() => dispatch(adminToggleUserPermission(id))}
                        />
                        <p className={styles.permissionsDetail}>
                          {roles[id].permissions.map(({ description }, i) => (i === 0
                            ? description[0].toUpperCase() + description.substring(1)
                            : description.toLowerCase())).join(', ')}
                        </p>
                      </div>

                    </div>
                  ))}
                </div>
              </Card>

              <div className={styles.save}>
                <SubmitButton
                  disabled={
                    !selectedUserRoles
                    || (roleIds.map(id => selectedUserRoles[id])
                      .every(sr => sr.modifiedValue === sr.originalValue) && selectedUserNotes === localUserNotes)
                  }
                  onClick={() => {
                    dispatch(adminUpdateUserPermissions(selectedUser, selectedUserRoles, localUserNotes))
                  }}
                  submitting={_isUpdatingPermissions}
                  submittingText="Applying"
                >
                  Apply permissions
                </SubmitButton>
              </div>
            </Fragment>
          )}

        </div>

      </Animate>
    </OverlayModal>
  )
}

export default UserPermissionsModal
