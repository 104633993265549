import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import IconChevron from 'icons/IconChevron'
import { range } from 'utils/numbers'
import styles from './Carousel.scss'

const propTypes = {
  components: PropTypes.arrayOf(PropTypes.node).isRequired,
  numVisible: PropTypes.number,
}

const defaultProps = {
  numVisible: 2,
}

function Carousel({ components, numVisible }) {
  const [page, setPage] = useState(1)

  const numPages = Math.ceil(components.length / numVisible)
  const pages = range(1, numPages)

  return (
    <div
      className={styles.base}
    >
      <div className={styles.clipper}>
        <div
          className={styles.carousel}
          style={{
            left: `-${(page - 1) * 100}%`,
          }}
        >
          {components.map(c => c)}
        </div>
      </div>

      {numPages > 1 && (
        <div className={styles.pageControl}>
          {pages.map(p => (
            <span className={p === page ? styles.active : null} key={p} />
          ))}
        </div>
      )}
      <div className={styles.pageButtons}>
        <a
          className={classNames(
            styles.round,
            styles.previousButton,
            page === 1 ? styles.hidden : null,
          )}
          onClick={() => setPage(Math.max(1, page - 1))}
        >
          <IconChevron left height={15} />
        </a>
        <a
          className={classNames(
            styles.round,
            styles.nextButton,
            page === numPages ? styles.hidden : null,
          )}
          onClick={() => setPage(Math.min(numPages, page + 1))}
        >
          <IconChevron height={15} />
        </a>
      </div>
    </div>
  )
}

Carousel.propTypes = propTypes
Carousel.defaultProps = defaultProps

export default Carousel
