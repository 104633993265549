import mapKeys from 'lodash/mapKeys'
import * as ActionTypes from 'actions/validations'

const initialState = {
  _isFetchingValidationSummary: false,
  jobId: null,
  filterSeverity: null,
  validations: {},
  validationCount: null,
}

export default function job(state = initialState, action) {
  const { validationName } = action
  switch (action.type) {
    case ActionTypes.VALIDATION_FETCHING_VALIDATION_SUMMARY:
      return {
        ...(state.jobId === action.jobId ? state : initialState),
        jobId: action.jobId,
        _isFetchingValidationSummary: true,
        validationCount: null,
      }
    case ActionTypes.VALIDATION_UPDATE_VALIDATIONS: {
      const validations = mapKeys(
        action.validations.map(validation => ({
          _isFetching: false,
          errors: [],
          columnMapping: [],
          ...validation,
        })),
        validation => validation.validationName,
      )
      return {
        ...state,
        jobId: action.jobId,
        _isFetchingValidationSummary: false,
        validations,
        validationCount: action.validations.filter(x => x.affectedRows > 0).length,
      }
    }
    case ActionTypes.VALIDATION_FETCHING:
      return {
        ...state,
        validations: {
          ...state.validations,
          [validationName]: {
            ...state.validations[validationName],
            _isFetching: true,
          },
        },
      }
    case ActionTypes.VALIDATION_FETCHED: {
      const { columnMapping, validationErrors, page } = action
      return {
        ...state,
        validations: {
          ...state.validations,
          [validationName]: {
            ...state.validations[validationName],
            _isFetching: false,
            columnMapping,
            errors: [
              ...state.validations[validationName].errors.slice(0),
              ...validationErrors,
            ],
            page,
          },
        },
      }
    }
    case ActionTypes.VALIDATION_RESET:
      return {
        ...state,
        validations: {},
      }
    case ActionTypes.VALIDATION_SET_SEVERITY_FILTER:
      return {
        ...state,
        filterSeverity: action.severityFilter,
      }
    default:
      return state
  }
}
