import CreateReport from 'views/Create/CreateReport'
import Details from 'views/Job/Details'
import Errors from 'views/Job/Errors'
import App from 'containers/App'
import Activity from 'containers/Activity'
import Jobs from 'containers/Jobs'
import NotFound from 'containers/NotFound'
import SigningIn from 'containers/SigningIn'
import Create from 'containers/Create'
import CreateJob from 'containers/CreateJob'
import ErrorPage from 'containers/ErrorPage'
import HostedReportGroupList from 'containers/HostedReportGroupList'
import HostedReportGroup from 'containers/HostedReportGroup'
import InputData from 'views/Create/InputData'
import SelectTests from 'views/Create/SelectTests'
import Getdata from 'views/Create/Getdata'
import Review from 'views/Create/Review'
import NoReport from 'views/Home/NoReport'
import EngagementView from 'views/EngagementView'
import Job from 'containers/Job'
import Report from 'containers/Report'
import DataQuality from 'views/Create/DataQuality'
import Admin from 'containers/Admin'
import HomeSwitcher from 'containers/HomeSwitcher'
import Solution from 'containers/Solution'
import Insights from 'containers/Insights'
import ClientJob from 'containers/ClientJob'
import ClientReports from 'views/ClientJob/ClientReports'
import ClientTeam from 'views/ClientJob/ClientTeam'
import ClientData from 'views/ClientJob/ClientData'
import Support from 'containers/Support'
import Contact from 'containers/Contact'
import Tou from 'containers/Tou'
import ClientReport from 'containers/ClientReport'
import Team from 'views/Job/Team'
import HostedReports from 'views/HostedReportGroup/HostedReports'
import HostedReport from 'containers/HostedReport'
import ClientCustomReports from 'views/ClientJob/ClientCustomReports'
import ClientCustomGroup from 'containers/ClientCustomGroup'
import ActionHome from 'actionHub/containers/ActionHome'
import Task from 'actionHub/containers/Task'
import Tag from 'actionHub/containers/Tag'
import ActionTasks from 'actionHub/containers/ActionTasks'
import Actions from 'views/Job/Actions'
import Solutions from 'containers/Solutions'
import UserManagement from 'views/Admin/UserManagement'
import AppSettings from 'views/Admin/AppSettings'
import SolutionSettings from 'views/Admin/SolutionSettings'
import DataSourceSettings from 'views/Admin/DataSourceSettings'
import BannerManagement from 'views/Admin/BannerManagement'
import StatusView from 'views/Admin/StatusView'
import { isAdmin, isSupport } from 'utils/permissions'
import { ViewJobDetails } from 'views/Create/ViewJobDetails'
import GenAiLanding from 'features/genAi/containers/GenAiLanding.jsx'
import EditApp from 'features/genAi/containers/EditApp.jsx'

const routes = [
  {
    path: '/',
    component: App,
    routes: [
      {
        path: '/action/:actionSetId',
        component: ActionHome,
        routes: [
          {
            path: '/action/:actionSetId/task/:taskId',
            component: Task,
            routes: [],
          },
          {
            path: '/action/:actionSetId/tag/:tagId',
            component: Tag,
            routes: [],
          },
          {
            path: '/action/:actionSetId',
            component: ActionTasks,
            routes: [],
          },
        ],
      },
      {
        path: '/gen-ai',
        component: GenAiLanding,
        routes: [],
      },
      {
        path: '/editApp/:appId',
        component: EditApp,
        routes: [],
      },
      {
        path: '/home',
        component: HomeSwitcher,
        routes: [],
      },
      {
        path: '/terms-of-use',
        component: Tou,
        routes: [],
      },
      {
        path: '/activity',
        component: Activity,
        routes: [],
      },
      {
        path: '/solutions',
        component: Solutions,
        routes: [],
      },
      {
        path: '/solution/:packageId',
        component: Solution,
        routes: [],
      },
      {
        path: '/insights/:packageId/report/:jobId/:analysisId',
        component: ClientReport,
        routes: [],
      },
      {
        path: '/insights/:packageId/:jobId',
        component: ClientJob,
        routes: [
          {
            path: '/insights/:packageId/:jobId/reports',
            component: ClientReports,
            name: 'Dashboard',
          },
          {
            path: '/insights/:packageId/:jobId/data',
            component: ClientData,
            name: 'Data',
          },
          {
            path: '/insights/:packageId/:jobId/team',
            component: ClientTeam,
            name: 'Team',
          },
        ],
      },
      {
        path: '/custom/insights/:groupId',
        component: ClientCustomGroup,
        routes: [
          {
            path: '/custom/insights/:groupId/reports',
            component: ClientCustomReports,
            name: 'Reports',
          },
          {
            path: '/custom/insights/:groupId/team',
            component: ClientTeam,
            name: 'Team',
          },
        ],
      },
      {
        path: '/insights/:packageId',
        component: Insights,
        routes: [],
      },
      {
        path: '/jobs',
        component: Jobs,
        routes: [
          {
            path: '/jobs/engagements',
            component: EngagementView,
          },
          {
            path: '/jobs/nojob',
            component: NoReport,
          },
        ],
      },
      {
        path: '/job/:engagementCode/:packageName/details',
        component: Job,
        routes: [],
      },
      {
        path: '/job/:jobId',
        component: Job,
        routes: [
          {
            path: '/job/:jobId/details',
            component: Details,
            name: 'Dashboard',
          },
          {
            path: '/job/:jobId/errors',
            component: Errors,
            name: 'Data issues',
          },
          {
            path: '/job/:jobId/actions',
            component: Actions,
            name: 'Archived actions',
          },
          {
            path: '/job/:jobId/team',
            component: ClientTeam,
            name: 'Team',
          },
        ],
      },
      {
        path: '/report/:jobId/:analysisId',
        component: Report,
        routes: [],
      },
      {
        path: '/createjob/:isCloned?',
        component: CreateJob,
      },
      {
        path: '/create/:jobId',
        component: Create,
        routes: [
          {
            path: '/create/:jobId/details',
            component: ViewJobDetails,
          },
          {
            path: '/create/:jobId/edit',
            component: CreateReport,
          },
          {
            path: '/create/:jobId/selecttests',
            component: SelectTests,
          },
          {
            path: '/create/:jobId/getdata',
            component: Getdata,
          },
          {
            path: '/create/:jobId/inputdata',
            component: InputData,
          },
          {
            path: '/create/:jobId/review',
            component: Review,
          },
          {
            path: '/create/:jobId/dataquality',
            component: DataQuality,
          },
          {
            component: NotFound,
          },
        ],
      },
      {
        path: '/admin',
        component: Admin,
        routes: [
          {
            path: '/admin/jobs',
            component: EngagementView,
            name: 'Broken jobs',
            hasPermission: isSupport,
          },
          {
            path: '/admin/status',
            component: StatusView,
            name: 'Status',
            hasPermission: isSupport,
          },
          {
            path: '/admin/users',
            component: UserManagement,
            name: 'Elevated users',
            hasPermission: isAdmin,
          },
          {
            path: '/admin/system',
            component: AppSettings,
            name: 'App settings',
            hasPermission: isAdmin,
          },
          {
            path: '/admin/solution',
            component: SolutionSettings,
            name: 'Solutions',
            hasPermission: isAdmin,
          },
          {
            path: '/admin/datasource',
            component: DataSourceSettings,
            name: 'Data sources',
            hasPermission: isAdmin,
          },
          {
            path: '/admin/banners',
            component: BannerManagement,
            name: 'Banners',
            hasPermission: isAdmin,
          },
        ],
      },
      {
        path: '/support',
        component: Support,
        routes: [],
      },
      {
        path: '/contact',
        component: Contact,
        routes: [],
      },
      {
        path: '/custom/:groupId/report/:reportId',
        component: HostedReport,
        routes: [],
      },
      {
        path: '/custom/:groupId',
        component: HostedReportGroup,
        routes: [
          {
            path: '/custom/:groupId/reports',
            component: HostedReports,
            name: 'Reports',
          },
          {
            path: '/custom/:groupId/team',
            component: Team,
            name: 'Team',
          },
        ],
      },
      {
        path: '/custom',
        component: HostedReportGroupList,
        routes: [],
      },

      {
        path: '/signin',
        component: SigningIn,
      },
      {
        path: '/error',
        component: ErrorPage,
      },
      {
        component: NotFound,
      },
    ],
  },
]

export default function getRoutes() {
  return routes
}
