import React, { useMemo, useState } from 'react'
import { Chip } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import Expander from 'views/Home/components/Expander'
import { setCurrentUploader } from 'actions/uploaders'
import GenUploader from 'features/genAi/components/GenAiUploader'
import { useParams } from 'react-router-dom'
import { getConfig } from 'utils/config'
import styles from './GenAIAppCreationForms.scss'

const UPLOADER_INDEX = 'GenAIKnowledgeBaseFileUploader'

const AppKnowledgeBaseForm = () => {
  const dispatch = useDispatch()
  const initialState = useSelector(state => state.genAi.aiApp.knowledgeBase)
  const userEmail = useSelector(state => state.app.user.email)
  const [localFormData, setLocalFormData] = useState(initialState)
  const [items, setItems] = useState([])
  const { appId } = useParams()
  const { URL_BASE } = getConfig()
  const ENDPOINT = `${URL_BASE}/gen-ai/knowledge/upload/`

  // const handleFileDrop = (event) => {
  //   event.preventDefault()
  //   const file = event.dataTransfer.files[0]
  //   setItems(prevItems => [...prevItems, { type: 'file', name: file.name }])
  // }

  // const handleWebsiteAdd = (value) => {
  //   setLocalFormData(prevState => ({ ...prevState, websites: [...prevState.websites, value] }))
  // }

  // const handleFileAdd = (value) => {
  //   setLocalFormData(prevState => ({ ...prevState, files: [value] }))
  // }

  useMemo(() => {
    dispatch(setCurrentUploader(UPLOADER_INDEX))
  }, [dispatch])

  // const handleSectionSave = (event) => {
  //   event.preventDefault()
  //   event.stopPropagation()
  //   dispatch(updateAppKnowledgeBaseForm(localFormData))
  // }

  return (
    <Expander
      className={styles.expander.unlimited}
      classNameExpanded={styles.expanderExpanded}
      headerContent={(
        <div className={styles.headerBar}>
          <div className={styles.headerTitle}>
            <span className={styles.title}>Add knowledge </span>
            <Chip label="Optional" />
          </div>
        </div>
      )}
      key="appPrompts"
      size="unlimited"
    >
      <div className={styles.expandContentContainer}>
        {appId && userEmail && (
          <GenUploader appId={appId} endpoint={ENDPOINT} email={userEmail} />
        )}
      </div>
    </Expander>
  )
}

export default AppKnowledgeBaseForm
