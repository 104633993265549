import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { colourFromString } from 'utils/colours'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAvatar } from 'actions/app'
import styles from './Avatar.scss'

// TODO: add icon option

const Avatar = ({
  fetchImage, className, size, nameArray, initials, userDetails, inlineStyle,
}) => {
  const classes = classnames(
    styles.base,
    styles[size],
    className,
  )

  const dispatch = useDispatch()
  const { email, isExternal } = userDetails

  const avatars = useSelector(state => state.app.avatars)
  const avatarImageUrl = email && email in avatars ? avatars[email].blobUrl : null
  const fetchedAvatar = email && email in avatars ? avatars[email].fetched : false

  useEffect(() => {
    if (fetchImage && email && !isExternal && !fetchedAvatar) {
      dispatch(fetchAvatar(email))
    }
  }, [dispatch, email, isExternal, fetchedAvatar, fetchImage])

  const derivedNameArray = userDetails.firstName
    ? [userDetails.firstName, userDetails.surname]
    : nameArray

  const text = initials || derivedNameArray
    .map(word => (word ? word.substr(0, 1) : ''))
    .join('')
    .substr(0, 2)

  const color = colourFromString(text)
  let style = { backgroundColor: color }

  if (!avatarImageUrl && inlineStyle) {
    const s = inlineStyle
    s.backgroundColor = color
    style = s
  }

  return (
    <div
      className={classes}
      style={style}
      title={`${userDetails.firstName} ${userDetails.surname}`}
    >
      <img
        alt="user-avatar"
        className={classnames(styles.userImage, !avatarImageUrl ? styles.hidden : '')}
        src={avatarImageUrl}
      />
      {!avatarImageUrl && (
        <span className={styles.letter}>
          {text}
        </span>
      )}
    </div>
  )
}

Avatar.propTypes = {
  className: PropTypes.string,
  fetchImage: PropTypes.bool,
  initials: PropTypes.string,
  inlineStyle: PropTypes.object,
  nameArray: PropTypes.arrayOf(PropTypes.string),
  size: PropTypes.oneOf(['tiny', 'small', 'medium', 'large', 'mega', 'massive']),
  userDetails: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    isExternal: PropTypes.bool,
    surname: PropTypes.string,
  }),
}

Avatar.defaultProps = {
  className: null,
  fetchImage: true,
  inlineStyle: null,
  initials: null,
  nameArray: [],
  size: 'medium',
  userDetails: {},
}

export default Avatar
