import React from 'react'
import PropTypes from 'prop-types'
import LibCheckbox from 'react-toolbox/lib/checkbox'
import theme from './Checkbox.scss'

const Checkbox = (props) => {
  const {
    disabled,
    handleChange,
    label,
    name,
    checked,
    className,
  } = props

  const _handleChange = (value, e) => {
    handleChange(name, e.target.checked)
  }

  // eslint-disable-next-line react/no-danger
  const spanLabel = <span dangerouslySetInnerHTML={{ __html: label }} />

  return (
    <div>
      <LibCheckbox
        checked={checked}
        className={className}
        data-test-id={`checkboxInput-${name}`}
        disabled={disabled}
        label={spanLabel}
        name={name}
        onChange={_handleChange}
        theme={theme}
      />
    </div>
  )
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

Checkbox.defaultProps = {
  className: null,
  disabled: false,
  checked: false,
}

export default Checkbox
