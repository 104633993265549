const fontFamily = 'Open Sans, sans-serif'

const labelFill = '#888888'
const gridFill = '#f0f0f0'
const brandGreen = '#86bd24'

const baseLabelStyles = {
  fontFamily,
  stroke: 'transparent',
  fontSize: '14px',
  // lineHeight: 1.71,
}

const axisLabelStyles = {
  ...baseLabelStyles,
  padding: 12,
  fill: labelFill,
}

const dataLabelStyles = {
  ...baseLabelStyles,
  fill: brandGreen,
  fontWeight: 600,
  padding: 6,
}

export default {
  axis: {
    width: 500,
    height: 400,
    padding: 25,
  },
  independentAxis: {
    style: {
      axis: {
        fill: 'transparent',
        strokeWidth: 1,
        stroke: gridFill,
      },
      grid: {
        display: 'none',
      },
      ticks: {
        display: 'none',
      },
      tickLabels: axisLabelStyles,
    },
  },
  dependentAxis: {
    style: {
      axis: {
        display: 'none',
      },
      grid: {
        strokeWidth: 1,
        stroke: gridFill,
      },
      ticks: {
        display: 'none',
      },
      tickLabels: {
        display: 'none',
      },
    },
  },
  bar: {
    style: {
      data: {
        fill: brandGreen,
        stroke: 'transparent',
        shapeRendering: 'crispedges',
      },
      labels: dataLabelStyles,
    },
  },
}
