import merge from 'lodash/merge'

import {
  FORM_ADD_TAG,
  FORM_CLEAR_FIELD,
  FORM_DELETE_TAG,
  FORM_ERROR,
  FORM_HAS_COMPLETED,
  FORM_INIT,
  FORM_SCHEMA_UPDATE_ADD,
  FROM_DESTROY,
  FORM_RESET,
  FORM_SUBMIT,
  FORM_SUBMIT_CLEAR,
  FORM_SUBMIT_COMPLETE,
  FORM_UPDATE,
  FORM_ENABLE_SUBMIT,
  FORM_OPTIONS_UPDATE,
  FORM_OPTIONS_COMPLETE,
  FORM_UPDATE_ARRAY_MEMBER,
  FORM_SCHEMA_UPDATE_DELETE,
} from 'actions/forms'
import { removeByKey } from '../utils/objects'

const initialState = {}

const getValue = (field) => {
  if (field.type === 'switch' || field.type === 'checkbox') {
    return field.value || false
  }
  if (field.multi === true || field.type === 'tagInput') {
    return []
  }
  return ''
}

const formStateObj = (data, field) => {
  const val = getValue(field)
  const obj = {
    error: '',
    focus: false,
    meta: field.meta,
    valid: true,
    value: typeof data[field.name] === 'undefined' ? val : data[field.name],
  }

  // If field needs to make requests we add extra states to form field.
  if (field.requestOptions) {
    obj.options = field.options || []
    obj._isFetching = false
  }

  return obj
}

const buildAndSetStateTree = (formFields, data) => {
  const form = {
    _hasCompleted: false,
    _isComplete: false,
    _isPosting: false,
    _submitEnabled: true,
  }

  const buildEls = (field) => {
    if (field.elements) {
      field.elements.forEach(buildEls)
    } else {
      form[field.name] = formStateObj(data, field)
    }
  }

  formFields.forEach(buildEls)

  return form
}

export default function forms(state = initialState, action) {
  const errors = {}
  if (action.errors) {
    action.errors.forEach((obj) => {
      errors[obj.field] = {
        ...state[action.formName][obj.field],
        error: obj.error,
        valid: false,
      }
    })
  }

  switch (action.type) {
    case FORM_INIT:
      return Object.assign({}, state, {
        [action.formName]: buildAndSetStateTree(
          action.formData.formFields,
          action.formData.data,
        ),
      })

    case FROM_DESTROY:
      return removeByKey(state, action.formName)

    case FORM_UPDATE:
      return {
        ...state,
        [action.formName]: {
          ...state[action.formName],
          [action.formData.field]: {
            ...state[action.formName][action.formData.field],
            ...action.formData.data,
          },
        },
      }

    case FORM_SCHEMA_UPDATE_ADD:
      return {
        ...state,
        [action.formName]: {
          ...state[action.formName],
          ...(buildAndSetStateTree(
            action.formData.formFields,
            action.formData.data,
          )),
        },
      }

    case FORM_SCHEMA_UPDATE_DELETE:
      const form = state[action.formName]
      action.formFieldsToDelete.map(field => delete form[field])
      return {
        ...state,
      }

    case FORM_CLEAR_FIELD: {
      let val = ''
      if (Array.isArray(state[action.formName][action.fieldName].value)) {
        const len = state[action.formName][action.fieldName].value.length
        val = state[action.formName][action.fieldName].value
        val.splice(0, len)
      }

      return merge({}, state, {
        [action.formName]: {
          [action.fieldName]: {
            value: val,
          },
        },
      })
    }
    case FORM_ADD_TAG: {
      const currValue = state[action.formName][action.formData.field].value
      currValue.push({
        id: currValue.length + 1,
        ...action.formData.data.value,
      })
      return merge({}, state, {
        [action.formName]: {
          [action.formData.field]: {
            ...action.formData.data,
            value: currValue,
          },
        },
      })
    }

    case FORM_UPDATE_ARRAY_MEMBER: {
      const currValue = state[action.formName][action.fieldName].value

      const newValue = currValue.map(x => (
        x[action.keyProperty].toLowerCase() === action.value[action.keyProperty].toLowerCase()
          ? ({ ...x, ...action.value })
          : x))

      return {
        ...state,
        [action.formName]: {
          ...state[action.formName],
          [action.fieldName]: {
            ...state[action.formName][action.fieldName],
            value: newValue,
          },
        },
      }
    }

    case FORM_OPTIONS_UPDATE: {
      return merge({}, state, {
        [action.formName]: {
          [action.fieldName]: {
            _isFetching: true,
          },
        },
      })
    }

    case FORM_OPTIONS_COMPLETE: {
      return {
        ...state,
        [action.formName]: {
          ...state[action.formName],
          [action.fieldName]: {
            ...state[action.formName][action.fieldName],
            lastQuery: action.lastQuery,
            options: action.options,
            _isFetching: false,
          },
        },
      }
    }

    case FORM_DELETE_TAG: {
      const tags = state[action.formName][action.fieldName].value
      tags.splice(action.index, 1)
      return merge({}, state, {
        [action.formName]: {
          [action.fieldName]: {
            value: tags,
          },
        },
      })
    }

    case FORM_ERROR:
      return {
        ...state,
        [action.formName]: {
          ...state[action.formName],
          [action.fieldName]: {
            ...state[action.formName][action.fieldName],
            error: action.error,
          },
        },
      }

    case FORM_SUBMIT:
      return merge({}, state, {
        [action.formName]: {
          _hasCompleted: false,
          _isComplete: false,
          _isPosting: true,
        },
      })

    case FORM_ENABLE_SUBMIT:
      return merge({}, state, {
        [action.formName]: {
          _submitEnabled: action.isEnabled,
        },
      })

    case FORM_HAS_COMPLETED:
      return merge({}, state, {
        [action.formName]: {
          _hasCompleted: true,
          _isComplete: true,
          _isPosting: false,
          _result: action.result,
        },
      })

    case FORM_SUBMIT_COMPLETE:
      return merge({}, state, {
        [action.formName]: {
          _isComplete: true,
          _isPosting: false,
        },
      })

    case FORM_SUBMIT_CLEAR:
      return merge({}, state, {
        [action.formName]: {
          _isComplete: false,
          _isPosting: false,
          _result: {},
        },
      })

    case FORM_RESET: {
      const resetKeys = state[action.formName]
      Object.keys(state[action.formName]).forEach((key) => {
        if (key.indexOf('_') < 0) {
          if (Array.isArray(resetKeys[key].value)) {
            const len = resetKeys[key].value.length
            resetKeys[key].value.splice(0, len)
          } else {
            resetKeys[key].value = ''
          }
        }
      })
      return merge({}, state, {
        [action.formName]: {
          ...resetKeys,
          _hasCompleted: false,
          _isComplete: false,
          _isPosting: false,
          _result: {},
        },
      })
    }

    default:
      return state
  }
}
