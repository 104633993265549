import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Avatar from 'components/Avatar'
import { IconClose } from 'icons'

import styles from './Chip.scss'

const Chip = (props) => {
  const {
    handleClick, handleDelete, handleDeleteTitle, hideAvatar, text,
  } = props
  const classNames = classnames(
    styles.base,
    { [styles.noAvatar]: hideAvatar },
    { [styles.noHandler]: !handleDelete },
  )
  const textClassNames = classnames(
    styles.text,
    { [styles.noFeatures]: hideAvatar && !handleDelete },
  )

  const onClick = () => {
    if (handleClick) { handleClick() }
  }

  return (
    <div
      className={classNames}
      onClick={onClick}
      title={text}
    >
      {!hideAvatar
        && (
        <Avatar
          className={styles.avatar}
          size="small"
          title={text}
        />
        )
      }
      <span className={textClassNames}>
        {text}
      </span>
      {handleDelete
        && (
        <a
          className={styles.remove}
          onClick={handleDelete}
          title={handleDeleteTitle}
        >
          <IconClose />
        </a>
        )
      }
    </div>
  )
}

Chip.propTypes = {
  handleClick: PropTypes.func,
  handleDelete: PropTypes.func,
  handleDeleteTitle: PropTypes.string,
  hideAvatar: PropTypes.bool,
  text: PropTypes.string.isRequired,
}

Chip.defaultProps = {
  handleClick: () => {},
  handleDelete: () => {},
  handleDeleteTitle: '',
  hideAvatar: false,
}

export default Chip
