import { merge } from 'utils/arrays'

import * as ActionTypes from 'actions/activity'

const initialState = {
  _isFetching: false,
  _isFetched: false,
  locations: [
    { locationName: 'NSW', numberOfEngagements: 0 },
    { locationName: 'VIC', numberOfEngagements: 0 },
    { locationName: 'WA', numberOfEngagements: 0 },
    { locationName: 'QLD', numberOfEngagements: 0 },
    { locationName: 'SA', numberOfEngagements: 0 },
    { locationName: 'TAS', numberOfEngagements: 0 },
    { locationName: 'National', numberOfEngagements: 0 },
  ],
  users: [],
  clients: null,
  engagements: null,
  dataProcessed: null,
  testran: null,
  savings: null,
  businessUnit: null,
  totalNumberOfUsers: null,
}

export default function job(state = initialState, action) {
  const { data } = action
  switch (action.type) {
    case ActionTypes.ACTIVITY_FETCHING:
      return Object.assign({}, state, {
        ...state,
        _isFetching: true,
      })
    case ActionTypes.ACTIVITY_FETCHED:
      return Object.assign({}, state, {
        ...state,
        _isFetching: false,
        _isFetched: true,
        ...data,
        locations: merge(state.locations, data.locations, 'locationName'),
      })
    default:
      return state
  }
}
