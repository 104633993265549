import React from 'react'
import PropTypes, { object } from 'prop-types'
import { userPropType, priorityPropType } from 'utils/propTypes'
import { sortByFunction } from 'utils/arrays'
import Priority from 'actionHub/components/Priority'
import User from 'components/User'
import { formatDateTimeFromStringShort } from 'utils/dates'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Tooltip from '@material-ui/core/Tooltip'
import { DropdownMenu } from '@deloitte/gel-library'

import classNames from 'classnames'
import { useSelector } from 'react-redux'
import ActionState from 'actionHub/components/ActionState'
import styles from './DetailedViewHeader.scss'

const propTypes = {
  actionStates: PropTypes.arrayOf(object),
  allowTags: PropTypes.bool.isRequired,
  deleteTagAssociation: PropTypes.func,
  disabled: PropTypes.bool,
  headerType: PropTypes.oneOf(['caseView', 'tickmarkView']).isRequired,
  members: PropTypes.arrayOf(userPropType),
  priorities: PropTypes.arrayOf(priorityPropType),
  saveTagName: PropTypes.func,
  setTaskAssignee: PropTypes.func,
  setTaskPriority: PropTypes.func,
  setTasksActionState: PropTypes.func,
  setTaskTag: PropTypes.func,
  tagName: PropTypes.string,
  tagUpdatedBy: PropTypes.string,
  task: PropTypes.shape({
    _id: PropTypes.number,
    _isLabelsModified: PropTypes.bool,
    assignedTo: PropTypes.string,
    labels: PropTypes.arrayOf(PropTypes.object),
    priorityId: PropTypes.number,
    statusId: PropTypes.number,
    tagId: PropTypes.string,
    taskActionId: PropTypes.number,
    taskColumns: PropTypes.arrayOf(PropTypes.object),
    taskData: PropTypes.object,
    updatedBy: PropTypes.string,
    updatedDate: PropTypes.string,
  }),
  taskTitle: PropTypes.string,
  timestamp: PropTypes.string,
  updateTagName: PropTypes.func,
}

const defaultProps = {
  actionStates: [],
  deleteTagAssociation: null,
  disabled: false,
  setTasksActionState: null,
  setTaskPriority: null,
  setTaskAssignee: null,
  members: [],
  task: null,
  taskTitle: null,
  priorities: [],
  tagName: '',
  tagUpdatedBy: '',
  updateTagName: null,
  timestamp: null,
  saveTagName: null,
  setTaskTag: null,
}

function DetailedViewHeader({
  actionStates, saveTagName, deleteTagAssociation, setTaskTag, task, setTaskPriority, setTasksActionState, setTaskAssignee, taskTitle, tagName,
  priorities, members, timestamp, headerType, tagUpdatedBy, updateTagName, disabled, allowTags,
}) {
  const closeView = () => {
    window.history.back()
  }

  const { tagsList } = useSelector(state => state.actionHub.tags)
  const priorityOptions = priorities
    .concat().sort(sortByFunction(p => p.value, true))
    .map(p => ({
      value: p.id,
      label: <Priority priority={p} />,
    }))

  const actions = actionStates.map(as => ({
    key: `${as.id}`,
    label: as.id === 0 ? 'No Action' : as.description,
    onClick: () => {
      const taskIds = [task._id]
      setTasksActionState(taskIds, as.id)
    },
  }))

  const memberOptions = members.map(x => ({
    value: x.id,
    label: <User user={x} />,
  }))

  const people = members.map(m => ({
    key: m.id,
    label: <User user={m} />,
    onClick: () => setTaskAssignee(m.id),
  }))

  const prioritiesArray = priorities
    .concat().sort(sortByFunction(p => p.value, true))
    .map(p => ({
      key: `${p.id}`,
      label: <Priority priority={p} />,
      onClick: () => setTaskPriority(p.id),
    }))

  const tagNamesArray = tagsList.concat().sort(sortByFunction(p => p.name))
    .map(p => ({
      key: p.id,
      label: <div className={styles.activeTagName}>{p.name}</div>,
      onClick: () => {
        const taskIds = []
        taskIds.push(task._id)
        setTaskTag(taskIds, p.name, p.description, p.id, p.narrative)
      },
    }))

  const unAssigned = {
    key: '',
    label: 'Unassigned',
    onClick: () => setTaskAssignee(null),
  }

  const unTagged = {
    key: '',
    label: 'Remove tag',
    onClick: () => {
      const taskIds = []
      taskIds.push(task._id)
      deleteTagAssociation(taskIds)
    },
  }

  people.push(unAssigned)
  tagNamesArray.push(unTagged)

  const assigneeValue = (!task?.assignedTo
      || !memberOptions.some(x => x.value === task.assignedTo))
    ? ''
    : task.assignedTo

  const priorityValue = priorityOptions?.length > 0 && (task.priorityId === 0 || task.priorityId)
    ? task.priorityId
    : ''

  const updatedUser = members.find(m => m.userDetails.email === task.updatedBy)

  const editName = (value) => {
    updateTagName(value)
  }

  const getTagName = () => {
    const currentTagName = tagsList.find(tag => tag.id === task.tagId)?.name
    return currentTagName ? (
      <div className={styles.activeTagName}>
        {currentTagName}
      </div>
    ) : (
      <div className={styles.unassignedTagName}>
        Assign tag
      </div>
    )
  }

  const getAssignee = () => {
    const assignee = people.find(person => person.key === assigneeValue)
    return assignee?.key ? assignee.label : 'Assign user'
  }

  const getPriority = () => {
    const priority = prioritiesArray.find(p => p.key === `${priorityValue}`)
    return priority?.label
  }

  const getActionState = () => {
    return task.taskActionId ? <ActionState actionState={actionStates.find(s => s.id === task.taskActionId)} /> : 'Assign action'
  }

  return (
    <div className={styles.flexContainer}>
      {headerType === 'caseView'
      && (
      <div className={classNames(styles.flexContainer, styles.flexSpace)}>
        <div className={classNames(styles.flexContainer, styles.flexStart, styles.tagSubDiv)}>
          <div className={classNames(styles.details, styles.backArrow)}>
            <ArrowBackIcon onClick={() => closeView()} />
          </div>
          <Tooltip arrow interactive title={taskTitle}>
            <div className={classNames(styles.details, styles.taskTitle)} id="taskTitle">
              {taskTitle}
            </div>
          </Tooltip>
          {allowTags && (
            <div className={classNames(styles.details, styles.flexContainer, styles.flexStart)}>
              <DropdownMenu disabled={disabled} displayText={getTagName()} options={tagNamesArray} />
            </div>
          )}
          <div className={classNames(styles.details, styles.flexContainer, styles.flexStart)}>
            <DropdownMenu disabled={disabled} displayText={getPriority()} options={prioritiesArray} />
          </div>
          <div className={classNames(styles.details, styles.flexContainer, styles.flexStart)}>
            <DropdownMenu disabled={disabled} displayText={getActionState()} options={actions} />
          </div>
          <div className={classNames(styles.details, styles.flexContainer, styles.flexStart)}>
            <DropdownMenu disabled={disabled} displayText={getAssignee()} options={people} />
          </div>
        </div>
        <div className={styles.details}>
          {updatedUser && `Last updated by ${updatedUser.userDetails.firstName} ${updatedUser.userDetails.surname} ${formatDateTimeFromStringShort(timestamp)}`}
        </div>
      </div>
      )
      }
      {headerType === 'tickmarkView'
      && (
      <div className={classNames(styles.flexContainer, styles.flexSpace)}>
        <div className={styles.tagSubDiv}>
          <input
            className={styles.tagName}
            disabled={disabled}
            onBlur={e => saveTagName(e.target.value)}
            onChange={e => editName(e.target.value)}
            size={tagName.length ?? 1}
            value={tagName}
          />

        </div>
        <div className={classNames(styles.flexContainer, styles.tagSubDiv, styles.flexEnd)}>
          <div className={styles.details}>{`Last updated by ${tagUpdatedBy} ${formatDateTimeFromStringShort(timestamp)}`}</div>
          {/* <div className={styles.details}><MoreVertIcon /></div> */}
        </div>
      </div>
      )}
    </div>
  )
}

DetailedViewHeader.propTypes = propTypes
DetailedViewHeader.defaultProps = defaultProps

export default DetailedViewHeader
