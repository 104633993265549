import { FETCHED_APPS, FETCHING_APPS, UPSERT_APP_DETAILS } from '../actions/actionTypes'

const initialState = {
  isFetching: false,
  isFetched: false,
  aiApps: [],
}

export default function genAiApps(state = initialState, action) {
  switch (action.type) {
    case FETCHING_APPS:
      return {
        ...state,
        isFetching: action.payload,
        isFetched: false,
      }

    case FETCHED_APPS:
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        aiApps: action.payload,
      }

    case UPSERT_APP_DETAILS:
      return {
        ...state,
        aiApps: {
          ...state.aiApps,
          [action.collection]: {
            ...state.aiApps[action.collection],
            [action.appId]: {
              ...state.aiApps[action.collection][action.appId],
              fullAppInfo: {
                ...action.payload,
              },
            },
          },
        },
      }

    default:
      return state
  }
}
