export const META_BASE = {
  HREF: 'https://connect.deloitteprivate.com/',
}

export const META_DESCRIPTION = 'Deloitte Private Digital Solutions Portal'

export const META_TITLE = {
  DEFAULT: 'Solutions by Deloitte - Welcome',
  TEMPLATE: 'Solutions by Deloitte - %s',
}
